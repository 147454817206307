import './styles.css'

import React from 'react'
import moment from 'moment'
import classnames from 'classnames'

import Notification from '../../js/notification'
import NotificationDropdown from './notification_dropdown'

class SingleNotification extends React.Component {
  state = {}

  toggleMore = (notification, ev) => {
    ev && ev.stopPropagation()
    this.setState({
      moreId: this.state.moreId || !notification ? null : notification.id
    })
  }

  markAsRead = (notification, callback, ev) => {
    ev && ev.stopPropagation()
    if (!notification.read_at) {
      Notification.read(
        notification.id,
        this.props.app.getCommonParams(),
        callback
      )
    } else {
      if (typeof callback === 'function') {
        callback()
      }
    }
  }

  markAsHidden = (notification, callback, ev) => {
    ev && ev.stopPropagation()
    if (this.props.isFloater) {
      if (this.props.onClose) {
        this.props.onClose(notification, callback)
      } else {
        if (typeof callback === 'function') {
          callback()
        }
      }
    } else {
      Notification.hide(
        notification.id,
        this.props.app.getCommonParams(),
        () => {
          this.props.removeFloater(notification)
          if (typeof callback === 'function') {
            callback()
          }
        }
      )
    }
  }

  open = notification => {
    const doIt = () => {
      this.markAsRead(notification, () => {
        this.openAction(notification)
      })
    }

    if (this.props.inList) {
      this.props.app.setPanel(null, null, () => {
        doIt()
      })
    } else {
      doIt()
    }
  }

  openAction = notification => {
    if (this.props.onClick) {
      this.props.onClick(notification)
    }
    switch (notification.type) {
      case 'comment_mention':
        this.props.app.setCommentNode(notification.node_id, {
          highlight: notification.comment_id,
          orgId: notification.org_id,
          force: true
        })
        break
      case 'node_mention':
        this.props.app.setRoot(notification.node_id)
        break
      case 'org_request_approved':
        break
      case 'added_to_share':
        break
      default:
    }
  }

  notificationImage = notification => {
    var src
    switch (notification.id) {
      case '237b7d1b-3fb9-4c0d-b222-095fb01a6f5a':
        src =
          'https://s3.amazonaws.com/uifaces/faces/twitter/marcogomes/128.jpg'
        break
      case '9faf08a9-951b-434f-b5e1-597a875ce8f8':
        src =
          'https://s3-eu-west-1.amazonaws.com/notebase-uploads/files/XzdclcCRQEWRSaLCrV0t_logo-dark-bg.png'
        break
      case '3d4e2df8-5ad4-4fae-b8d3-922e28d9b85d':
        src = 'https://s3.amazonaws.com/uifaces/faces/twitter/mantia/128.jpg'
        break
      default:
        src = 'https://s3.amazonaws.com/uifaces/faces/twitter/jonohunt/128.jpg'
    }
    return src
  }

  notificationIcon = notification => {
    var str
    switch (notification.type) {
      case 'comment_mention':
        str = 'comment-o'
        break
      case 'node_mention':
        str = 'at'
        break
      case 'org_request_approved':
        str = 'check'
        break
      case 'added_to_share':
        str = 'share-alt'
        break
      default:
        str = 'info-circle'
    }
    return (
      <i
        className={
          'fa fa-fw fa-' +
          str +
          ' notification-type-' +
          notification.type.replace(/_/g, '-')
        }
      />
    )
  }

  render () {
    var _this = this
    var notification = this.props.notification
    var notificationClasses = classnames({
      'single-notification': true,
      'is-read': notification.read_at
    })
    var more
    if (this.state.moreId === notification.id) {
      more = (
        <NotificationDropdown
          app={_this.props.app}
          notification={notification}
          onClickOutside={_this.toggleMore}
        />
      )
    }
    return (
      <div
        key={'notification-' + notification.id}
        className={notificationClasses}
        onClick={() => _this.open(notification)}
      >
        <div className='single-controls'>
          {Boolean(
            !this.props.inList && !this.props.isFloater && !notification.read_at
          ) && (
            <i
              onClick={() => _this.markAsRead(notification, null)}
              title='Mark as Read'
              className={'fa fa-fw fa-check-circle-o'}
            />
          )}
          {Boolean(!this.props.inList) && (
            <i
              onClick={() => _this.markAsHidden(notification, null)}
              title='Remove'
              className={'fa fa-fw fa-close'}
            />
          )}
          {Boolean(this.props.inList) && (
            <i
              onClick={() => _this.toggleMore(notification)}
              title='More actions'
              className={'more-toggle fa fa-fw fa-chevron-down'}
            />
          )}
        </div>
        <div className='notification-content'>
          <div
            className='notification-img'
            style={{
              backgroundImage:
                'url(' + _this.notificationImage(notification) + ')'
            }}
          />
          <div>
            <div
              className='notification-body'
              dangerouslySetInnerHTML={{ __html: notification.body }}
            />
            <div className='notification-meta'>
              <span className='notification-icon'>
                {_this.notificationIcon(notification)}
              </span>
              <span className='notification-time'>
                {moment(notification.created_at).fromNow()}
              </span>
              {Boolean(this.props.inList && notification.read_at) && (
                <span
                  className='read-info'
                  title={'Read ' + moment(notification.read_at).fromNow()}
                >
                  <i className='fa fa-fw fa-dot-circle-o' />
                </span>
              )}
              {Boolean(this.props.inList && notification.hidden_at) && (
                <span
                  className='hidden-info'
                  title={'Hidden ' + moment(notification.read_at).fromNow()}
                >
                  <i className='fa fa-fw fa-eye-slash' />
                </span>
              )}
            </div>
          </div>
          {more}
        </div>
      </div>
    )
  }
}

module.exports = SingleNotification
