import './styles.css'

import React from 'react'
import $ from 'jquery'
import ReactTooltip from 'react-tooltip'

import Notifications from './notifications'
import InboxIndicator from './inbox_indicator'
import UserProfile from './user_profile'
import Avatar from '../avatar'

import Tabs from './tabs'
import BrowseControls from './browse_controls'
import Org from '../../js/org'

class Header extends React.Component {
  state = {
    searchActive: false,
    wasBlurred: false
  }

  shouldComponentUpdate (nextProps) {
    if (this.props.app && this.props.shouldComponentTypeUpdate) {
      return this.props.app.shouldComponentTypeUpdate(nextProps)
    } else {
      return true
    }
  }

  toggleInbox = () => {
    this.props.setLastUserAction()
    this.props.saveUserSettings(
      { show_inbox: !this.props.user.settings.show_inbox },
      this.props.app.getCommonParams()
    )
  }

  toggleMenu = () => {
    const { app } = this.props
    const last = app.state.sidebarStack[app.state.sidebarStack.length - 1]
    if (last && last[0] === 'menu') {
      app.popSidebar()
    } else {
      app.pushSidebar(['menu'])
    }
  }

  toggleSearch = ev => {
    if (ev.target.id !== 'search' && !ev.target.className.match(/\bclose\b/)) {
      if (!this.state.wasBlurred) {
        if (!this.state.searchActive) {
          this.setState({ searchActive: true, wasBlurred: false }, function () {
            $('#header #search').focus()
          })
        } else {
          this.setState({ searchActive: false, wasBlurred: false })
        }
      } else {
        this.setState({ wasBlurred: false })
      }
    }
  }

  handleSearchInput = ev => {
    // var searchResults = this.props.search(event.target.value, this.props.app.state.entities.nodes, this.props.rootNode);
    clearTimeout(this.searchTimeout)
    var str = ev.target.value
    this.searchTimeout = setTimeout(
      function () {
        if (str === '' || str.length > 2) {
          this.props.app.setAppState({ searchstring: str })
        }
      }.bind(this),
      300
    )
  }

  handleSearchBlur = ev => {
    var changes = { wasBlurred: true }
    if (ev.target.value === '') {
      changes.searchActive = false
    }
    this.setState(changes, function () {
      setTimeout(
        function () {
          this.setState({ wasBlurred: false })
        }.bind(this),
        350
      )
    })
  }

  handleSearchChange = ev => {
    if (ev.target.value !== '') {
      this.setState({ searchActive: true })
    }
  }

  resetSearch = () => {
    this.props.app.setAppState(
      { searchstring: null, deActivateSearch: false, activateSearch: false },
      () => {
        $('#header #search')
          .val('')
          .focus()
        setTimeout(function () {}, 10)
      }
    )
  }

  save = () => {
    this.props.doPending()
  }

  toggleHeaderMenu = () => {
    this.props.app.setPanel('user-menu')
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps (nextProps) {
    if (
      nextProps.app.state.activateSearch ||
      (nextProps.searchstring &&
        nextProps.searchstring !== '' &&
        !this.state.searchActive)
    ) {
      this.setState({ searchActive: true, wasBlurred: false })
    } else if (nextProps.app.state.deActivateSearch) {
      $('#search').val('')
      this.setState({ searchActive: false, wasBlurred: false })
    }
  }

  render () {
    let addUsers
    if (this.props.org) {
      let users = []
      const orgUsers = Org.users(
        this.props.org,
        this.props.app.getCommonParams()
      )
      if (orgUsers && orgUsers.length) {
        orgUsers.forEach(u => {
          if (u.id !== this.props.user.id) {
            const avatar = { backgroundImage: `url('${u.gravatar}')` }
            users.push(
              <li key={`user-id-${u.id}`} className='person' style={avatar}>
                <Avatar app={this.props.app} user={u} size='medium' />
              </li>
            )
          }
        })
        users = users.slice(0, 3)
      }
      addUsers = (
        <div id='add-users'>
          <ul>
            {users}
            <li
              className='add'
              data-tip
              data-for='add-user-to-team'
              onClick={() =>
                this.props.app.setPanel('organizations-page', {
                  data: { user: this.props.user },
                  title: 'Manage teams',
                  activeSection: 'manage-users-section'
                })
              }
            >
              <i className='fa fa-plus' />
            </li>
          </ul>
          <ReactTooltip
            id='add-user-to-team'
            type='dark'
            effect='solid'
            place='bottom'
            data-delay-show='1000'
          >
            <span>Add user to team</span>
          </ReactTooltip>
        </div>
      )
    }

    var undoButtons, search
    if (this.props.user) {
      undoButtons = (
        <div id='header-buttons'>
          <button
            title='Undo'
            disabled={!this.props.hasUndo()}
            onClick={this.props.doUndo}
            className='btn'
          >
            <span className='octicon octicon-mail-reply' />
          </button>
          <button
            title='Redo'
            disabled={!this.props.hasRedo()}
            onClick={this.props.doRedo}
            className='btn'
          >
            <span className='octicon octicon-mail-reply flipped' />
          </button>
        </div>
      )
      var closeIcon
      if (this.props.searchstring) {
        closeIcon = (
          <i className='fa fa-fw fa-close' onClick={this.resetSearch} />
        )
      }
      var searchString = this.props.searchstring || ''
      search = (
        <form>
          <span className='icon'>
            <i className='fa fa-fw fa-search' />
          </span>
          <div className='search-input-div'>
            <input
              id='search'
              type='text'
              defaultValue={searchString}
              onKeyUp={this.handleSearchInput}
              onBlur={this.handleSearchBlur}
            />
            {closeIcon}
          </div>
        </form>
      )
    }
    /* eslint-disable */
    var sections = [
      <div key="logo" className="logo">
        <a href="/">N</a>
      </div>
    ]
    /* eslint-enable */
    if (NOTEBASE_IS_DESKTOP) {
      sections.push(
        <div key='browse-controls' className='browse-controls-container'>
          <BrowseControls
            app={this.props.app}
            user={this.props.user}
            org={this.props.org}
          />
        </div>
      )
    }
    if (this.props.user || this.props.share) {
      sections.push(
        <div
          key='search'
          className='search'
          onClick={this.toggleSearch}
          title='Search'
        >
          {search}
        </div>
      )
      // sections.push(
      //   <div key="quick-switcher" className="header-button" onClick={this.props.app.toggleSwitcher} title="Open the Quick Switcher">
      //     <i className="fa fa-fw fa-bolt"></i>
      //   </div>
      // );
    }
    if (this.props.user) {
      sections.push(
        <div key='tabs' className='tabs'>
          <Tabs
            app={this.props.app}
            user={this.props.user}
            org={this.props.org}
          />
        </div>
      )
      sections.push(
        <div key='undo' className='undo'>
          {undoButtons}
        </div>
      )
      sections.push(
        <div key='ctrl' className='ctrl'>
          {addUsers}
          <Notifications
            app={this.props.app}
            notifications={this.props.app.state.entities.notifications}
            user={this.props.user}
            org={this.props.org}
          />

          <InboxIndicator
            app={this.props.app}
            user={this.props.user}
            org={this.props.org}
          />

          <div id='menu-toggler' onClick={this.toggleMenu}>
            <i className='fa fa-fw fa-bars' />
          </div>

          <UserProfile
            app={this.props.app}
            setPanel={this.props.app.setPanel}
            user={this.props.user}
            org={this.props.org}
          />
          {/* <span className={dotClasses} title={this.props.indicators.save.text} onClick={this.save}></span> */}
          {/* <div className="header-menu header-button" onClick={this.toggleHeaderMenu}>
            <i className="fa fa-fw fa-bars"></i>
          </div> */}
        </div>
      )
    }
    var headerClasses = this.state.searchActive ? 'search' : ''
    return (
      <div id='header' className={headerClasses}>
        <div className='header-wrapper'>{sections}</div>
      </div>
    )
  }
}

module.exports = Header
