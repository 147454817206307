var update = require('react-addons-update')

var Tab = {
  add: function (tab, params, callback) {
    const doAction = actionParams => {
      const newParams = Object.assign({}, actionParams)
      const tabs = actionParams.updatedState.entities
        ? actionParams.updatedState.entities.tabs
        : actionParams.state.entities.tabs
      var next = tabs.find(t => t.prev_id === tab.prev_id)
      var newTabs
      if (next) {
        var newNext = update(next, { $merge: { prev_id: tab.id } })
        var withoutNext = tabs.filter(t => t.id !== next.id)
        newTabs = withoutNext.concat([tab, newNext])
      } else {
        newTabs = tabs.concat([tab])
      }

      const undoData = {
        undo: [{ obj: 'Tab', cmd: 'remove', args: [tab.id] }],
        redo: [{ obj: 'Tab', cmd: 'add', args: [tab] }]
      }
      if (!actionParams._isUndoOperation && !actionParams._skipUndoAdd) {
        actionParams.undo.add(undoData, true, actionParams._skipUndo)
      }

      const newEntities = Object.assign(
        {},
        actionParams.updatedState.entities || actionParams.state.entities
      )
      newEntities.tabs = newTabs
      const result = { entities: newEntities }

      newParams.updatedState = Object.assign(
        {},
        actionParams.updatedState,
        result
      )
      newParams.undoData = actionParams.undoData || {}
      newParams.undoData.undo = (newParams.undoData.undo || []).concat(
        undoData.undo
      )
      newParams.undoData.redo = (newParams.undoData.redo || []).concat(
        undoData.redo
      )

      if (actionParams._skipSetState) {
        if (typeof callback === 'function') {
          callback(newParams)
        }
      } else {
        actionParams.setAppState(
          newParams.updatedState,
          newParams.stateParams,
          () => {
            if (typeof callback === 'function') {
              callback(newParams)
            }
          }
        )
      }
    }

    if (params._skipFlushUndo) {
      doAction(params)
    } else {
      params.flushTextUndo(params, ret => {
        doAction(ret)
      })
    }
  },

  remove: function (tabId, params, callback) {
    const doAction = actionParams => {
      const newParams = Object.assign({}, actionParams)
      const tabs = actionParams.updatedState.entities
        ? actionParams.updatedState.entities.tabs
        : actionParams.state.entities.tabs
      var tab = Tab.get(tabId, tabs)
      var next = tabs.find(t => t.prev_id === tab.id)
      var newTabs
      if (next) {
        var newNext = update(next, { $merge: { prev_id: tab.prev_id } })
        var withoutNext = tabs.filter(t => t.id !== next.id && t.id !== tabId)
        newTabs = withoutNext.concat([newNext])
      } else {
        newTabs = tabs.concat([tab])
        newTabs = newTabs.filter(t => t.id !== tabId)
      }

      const undoData = {
        undo: [{ obj: 'Tab', cmd: 'add', args: [tab] }],
        redo: [{ obj: 'Tab', cmd: 'remove', args: [tab.id] }]
      }

      if (!actionParams._isUndoOperation && !actionParams._skipUndoAdd) {
        actionParams.undo.add(undoData, true, actionParams._skipUndo)
      }

      const newEntities = Object.assign(
        {},
        actionParams.updatedState.entities || actionParams.state.entities
      )
      newEntities.tabs = newTabs
      const result = { entities: newEntities }

      newParams.updatedState = Object.assign(
        {},
        actionParams.updatedState,
        result
      )
      newParams.undoData = actionParams.undoData || {}
      newParams.undoData.undo = (newParams.undoData.undo || []).concat(
        undoData.undo
      )
      newParams.undoData.redo = (newParams.undoData.redo || []).concat(
        undoData.redo
      )

      if (actionParams._skipSetState) {
        if (typeof callback === 'function') {
          callback(newParams)
        }
      } else {
        actionParams.setAppState(
          newParams.updatedState,
          newParams.stateParams,
          () => {
            if (typeof callback === 'function') {
              callback(newParams)
            }
          }
        )
      }
    }

    if (params._skipFlushUndo) {
      doAction(params)
    } else {
      params.flushTextUndo(params, ret => {
        doAction(ret)
      })
    }
  },

  modify: function (tabOrId, data, params, callback) {
    const doAction = actionParams => {
      const newParams = Object.assign({}, actionParams)
      const tabs = actionParams.updatedState.entities
        ? actionParams.updatedState.entities.tabs
        : actionParams.state.entities.tabs
      var tab = Tab.get(tabOrId, tabs)
      var newTab = update(tab, { $merge: { updated_at: new Date() } })
      const oldData = {}
      for (var key in data) {
        var keys = key.split('.')
        var obj = newTab
        for (var i = 0; i <= keys.length - 1; i++) {
          if (i === keys.length - 1) {
            obj[keys[i]] = data[key]
          }

          oldData[key] = tab[key]
          obj = obj[keys[i]]
        }
      }
      var trimmedTabs = tabs.filter(t => t.id !== newTab.id)
      var newTabs = trimmedTabs.concat([newTab])

      const undoData = {
        undo: [{ obj: 'Tab', cmd: 'modify', args: [tab.id, oldData] }],
        redo: [{ obj: 'Tab', cmd: 'modify', args: [tab.id, data] }]
      }

      if (tab.virtual) {
        window.localStorage.setItem('tab-' + tab.id, JSON.stringify(newTab))
      }

      if (!actionParams._isUndoOperation && !actionParams._skipUndoAdd) {
        actionParams.undo.add(undoData, !tab.virtual, actionParams._skipUndo)
      }

      const newEntities = Object.assign(
        {},
        actionParams.updatedState.entities || actionParams.state.entities
      )
      newEntities.tabs = newTabs
      const result = { entities: newEntities }

      newParams.updatedState = Object.assign(
        {},
        actionParams.updatedState,
        result
      )
      newParams.undoData = actionParams.undoData || {}
      newParams.undoData.undo = (newParams.undoData.undo || []).concat(
        undoData.undo
      )
      newParams.undoData.redo = (newParams.undoData.redo || []).concat(
        undoData.redo
      )

      if (actionParams._skipSetState) {
        if (typeof callback === 'function') {
          callback(newParams)
        }
      } else {
        actionParams.setAppState(
          newParams.updatedState,
          newParams.stateParams,
          () => {
            if (typeof callback === 'function') {
              callback(newParams)
            }
          }
        )
      }
    }

    if (params._skipFlushUndo) {
      doAction(params)
    } else {
      params.flushTextUndo(params, ret => {
        doAction(ret)
      })
    }
  },

  move: function (tabOrId, newPreviousSiblingId, params, callback) {
    const doAction = actionParams => {
      const newParams = Object.assign({}, actionParams)
      const tabs = actionParams.updatedState.entities
        ? actionParams.updatedState.entities.tabs
        : actionParams.state.entities.tabs
      var tab = Tab.get(tabOrId, tabs)
      var localUpdatedTabs = []
      var next = Tab.tabThatPointsTo(tab.id, tabs, params.org())
      if (next) {
        // console.log('settings prev_id for next "' +  next.id + '" to "' + tab.id + '"s prev_id, which is ' + tab.prev_id);
        localUpdatedTabs.push(
          update(next, { $merge: { prev_id: tab.prev_id } })
        )
      }
      // console.log('settings prev_id for "' + tab.id + '" to ' + newPreviousSiblingId);
      var newTab = update(tab, { $merge: { prev_id: newPreviousSiblingId } })
      localUpdatedTabs.push(newTab)
      var prev = Tab.tabThatPointsTo(newPreviousSiblingId, tabs, params.org())
      if (prev) {
        // console.log('settings prev_id for prev "' +  prev.id + '" to "' + tab.id + '"s id, which is ' + tab.id);
        localUpdatedTabs.push(update(prev, { $merge: { prev_id: tab.id } }))
      }
      var updatedIds = localUpdatedTabs.map(t => t.id)
      var trimmedTabs = tabs.filter(
        n => updatedIds.concat([tab.id]).indexOf(n.id) === -1
      )
      var newTabs = trimmedTabs.concat(localUpdatedTabs)

      const undoData = {
        undo: [{ obj: 'Tab', cmd: 'move', args: [tab.id, tab.prev_id] }],
        redo: [
          { obj: 'Tab', cmd: 'move', args: [tab.id, newPreviousSiblingId] }
        ]
      }

      if (!actionParams._isUndoOperation && !actionParams._skipUndoAdd) {
        actionParams.undo.add(undoData, true)
      }

      const newEntities = Object.assign(
        {},
        actionParams.updatedState.entities || actionParams.state.entities
      )
      newEntities.tabs = newTabs
      const result = { entities: newEntities }

      newParams.updatedState = Object.assign(
        {},
        actionParams.updatedState,
        result
      )
      newParams.undoData = actionParams.undoData || {}
      newParams.undoData.undo = (newParams.undoData.undo || []).concat(
        undoData.undo
      )
      newParams.undoData.redo = (newParams.undoData.redo || []).concat(
        undoData.redo
      )

      if (actionParams._skipSetState) {
        if (typeof callback === 'function') {
          callback(newParams)
        }
      } else {
        actionParams.setAppState(
          newParams.updatedState,
          newParams.stateParams,
          () => {
            if (typeof callback === 'function') {
              callback(newParams)
            }
          }
        )
      }
    }

    if (params._skipFlushUndo) {
      doAction(params)
    } else {
      params.flushTextUndo(params, ret => {
        doAction(ret)
      })
    }
  },

  expand: function (tabOrId, listOrIdOrNode, params, callback) {
    const doAction = actionParams => {
      const newParams = Object.assign({}, actionParams)
      const tabs = actionParams.updatedState.entities
        ? actionParams.updatedState.entities.tabs
        : actionParams.state.entities.tabs
      var tabId = typeof tabOrId === 'string' ? tabOrId : tabOrId.id
      var tab = Tab.get(tabOrId, tabs)
      var newTab = tab
      var nodeIds
      var idsToExpand = []
      var currExpanded = tab.expanded_nodes || []
      if (
        typeof listOrIdOrNode === 'object' &&
        listOrIdOrNode instanceof Array
      ) {
        nodeIds = listOrIdOrNode
      } else {
        nodeIds =
          typeof listOrIdOrNode === 'string'
            ? [listOrIdOrNode]
            : [listOrIdOrNode.id]
      }
      nodeIds.forEach(nodeId => {
        var idx = currExpanded.indexOf(nodeId)
        if (idx === -1) {
          idsToExpand.push(nodeId)
        }
      })
      newTab = update(tab, { $merge: { updated_at: new Date() } })
      newTab.expanded_nodes = currExpanded.concat(idsToExpand)
      var trimmedTabs = tabs.filter(t => t.id !== tab.id)
      var newTabs = trimmedTabs.concat([newTab])
      // setAppState({tabs: newTabs, tab: newTab});
      if (!params.user()) {
        // window.sessionStorage.setItem('expanded_nodes', JSON.stringify(newTab.expanded_nodes));
        window.localStorage.setItem('tab-' + tabId, JSON.stringify(newTab))
      }

      const undoData = {
        undo: [{ obj: 'Tab', cmd: 'collapse', args: [tab.id, listOrIdOrNode] }],
        redo: [{ obj: 'Tab', cmd: 'expand', args: [tab.id, listOrIdOrNode] }]
      }

      if (!actionParams._isUndoOperation && !actionParams._skipUndoAdd) {
        actionParams.undo.add(undoData, !tab.virtual)
      }

      const newEntities = Object.assign(
        {},
        actionParams.updatedState.entities || actionParams.state.entities
      )
      newEntities.tabs = newTabs
      const result = { entities: newEntities }

      newParams.updatedState = Object.assign(
        {},
        actionParams.updatedState,
        result
      )
      newParams.undoData = actionParams.undoData || {}
      newParams.undoData.undo = (newParams.undoData.undo || []).concat(
        undoData.undo
      )
      newParams.undoData.redo = (newParams.undoData.redo || []).concat(
        undoData.redo
      )

      if (actionParams._skipSetState) {
        if (typeof callback === 'function') {
          callback(newParams)
        }
      } else {
        actionParams.setAppState(
          newParams.updatedState,
          newParams.stateParams,
          () => {
            if (typeof callback === 'function') {
              callback(newParams)
            }
          }
        )
      }
    }

    if (params._skipFlushUndo) {
      doAction(params)
    } else {
      params.flushTextUndo({ nodeId: listOrIdOrNode }, params, ret => {
        // console.log('[modify] flush callback firing...');
        doAction(ret)
      })
    }
  },

  collapse: function (tabOrId, listOrIdOrNode, params, callback) {
    const doAction = actionParams => {
      const newParams = Object.assign({}, actionParams)
      const tabs = actionParams.updatedState.entities
        ? actionParams.updatedState.entities.tabs
        : actionParams.state.entities.tabs
      var tabId = typeof tabOrId === 'string' ? tabOrId : tabOrId.id
      var tab = Tab.get(tabOrId, tabs)
      var newTab = tab
      var nodeIds
      var idsToCollapse = []
      var currExpanded = tab.expanded_nodes || []
      if (
        typeof listOrIdOrNode === 'object' &&
        listOrIdOrNode instanceof Array
      ) {
        nodeIds = listOrIdOrNode
      } else {
        nodeIds =
          typeof listOrIdOrNode === 'string'
            ? [listOrIdOrNode]
            : [listOrIdOrNode.id]
      }
      nodeIds.forEach(nodeId => {
        var idx = currExpanded.indexOf(nodeId)
        if (idx > -1) {
          idsToCollapse.push(nodeId)
        }
      })
      newTab = update(tab, { $merge: { updated_at: new Date() } })
      newTab.expanded_nodes = currExpanded.filter(
        id => idsToCollapse.indexOf(id) === -1
      )
      var trimmedTabs = tabs.filter(t => t.id !== tab.id)
      var newTabs = trimmedTabs.concat([newTab])
      // setAppState({tabs: newTabs, tab: newTab});
      // user.expanded_nodes.splice(idx, 1);
      if (!params.user()) {
        // window.sessionStorage.setItem('expanded_nodes', JSON.stringify(newTab.expanded_nodes));
        window.localStorage.setItem('tab-' + tabId, JSON.stringify(newTab))
      }

      const undoData = {
        undo: [{ obj: 'Tab', cmd: 'expand', args: [tab.id, listOrIdOrNode] }],
        redo: [{ obj: 'Tab', cmd: 'collapse', args: [tab.id, listOrIdOrNode] }]
      }

      if (!actionParams._isUndoOperation && !actionParams._skipUndoAdd) {
        actionParams.undo.add(undoData, !tab.virtual)
      }

      const newEntities = Object.assign(
        {},
        actionParams.updatedState.entities || actionParams.state.entities
      )
      newEntities.tabs = newTabs
      const result = { entities: newEntities }

      newParams.updatedState = Object.assign(
        {},
        actionParams.updatedState,
        result
      )
      newParams.undoData = actionParams.undoData || {}
      newParams.undoData.undo = (newParams.undoData.undo || []).concat(
        undoData.undo
      )
      newParams.undoData.redo = (newParams.undoData.redo || []).concat(
        undoData.redo
      )

      if (actionParams._skipSetState) {
        if (typeof callback === 'function') {
          callback(newParams)
        }
      } else {
        actionParams.setAppState(
          newParams.updatedState,
          newParams.stateParams,
          () => {
            if (typeof callback === 'function') {
              callback(newParams)
            }
          }
        )
      }
    }

    if (params._skipFlushUndo) {
      doAction(params)
    } else {
      params.flushTextUndo({ nodeId: listOrIdOrNode }, params, ret => {
        // console.log('[modify] flush callback firing...');
        doAction(ret)
      })
    }
  },

  boardCollapse: function (tabOrId, listOrIdOrNode, params, callback) {
    const doAction = actionParams => {
      const newParams = Object.assign({}, actionParams)
      const tabs = actionParams.updatedState.entities
        ? actionParams.updatedState.entities.tabs
        : actionParams.state.entities.tabs
      var tabId = typeof tabOrId === 'string' ? tabOrId : tabOrId.id
      var tab = Tab.get(tabOrId, tabs)
      var newTab = tab
      var nodeIds
      var idsToCollapse = []
      var currCollapsed = tab.collapsed_board_nodes || []
      if (
        typeof listOrIdOrNode === 'object' &&
        listOrIdOrNode instanceof Array
      ) {
        nodeIds = listOrIdOrNode
      } else {
        nodeIds =
          typeof listOrIdOrNode === 'string'
            ? [listOrIdOrNode]
            : [listOrIdOrNode.id]
      }
      nodeIds.forEach(nodeId => {
        var idx = currCollapsed.indexOf(nodeId)
        if (idx === -1) {
          idsToCollapse.push(nodeId)
        }
      })
      newTab = update(tab, { $merge: { updated_at: new Date() } })
      newTab.collapsed_board_nodes = currCollapsed.concat(idsToCollapse)
      var trimmedTabs = tabs.filter(t => t.id !== tab.id)
      var newTabs = trimmedTabs.concat([newTab])
      // setAppState({tabs: newTabs, tab: newTab});
      if (!params.user()) {
        // window.sessionStorage.setItem('collapsed_board_nodes', JSON.stringify(newTab.collapsed_board_nodes));
        window.localStorage.setItem('tab-' + tabId, JSON.stringify(newTab))
      }

      const undoData = {
        undo: [
          { obj: 'Tab', cmd: 'boardExpand', args: [tab.id, listOrIdOrNode] }
        ],
        redo: [
          { obj: 'Tab', cmd: 'boardCollapse', args: [tab.id, listOrIdOrNode] }
        ]
      }

      if (!actionParams._isUndoOperation && !actionParams._skipUndoAdd) {
        actionParams.undo.add(undoData, !tab.virtual)
      }

      const newEntities = Object.assign(
        {},
        actionParams.updatedState.entities || actionParams.state.entities
      )
      newEntities.tabs = newTabs
      const result = { entities: newEntities }

      newParams.updatedState = Object.assign(
        {},
        actionParams.updatedState,
        result
      )
      newParams.undoData = actionParams.undoData || {}
      newParams.undoData.undo = (newParams.undoData.undo || []).concat(
        undoData.undo
      )
      newParams.undoData.redo = (newParams.undoData.redo || []).concat(
        undoData.redo
      )

      if (actionParams._skipSetState) {
        if (typeof callback === 'function') {
          callback(newParams)
        }
      } else {
        actionParams.setAppState(
          newParams.updatedState,
          newParams.stateParams,
          () => {
            if (typeof callback === 'function') {
              callback(newParams)
            }
          }
        )
      }
    }

    if (params._skipFlushUndo) {
      doAction(params)
    } else {
      params.flushTextUndo({ nodeId: listOrIdOrNode }, params, ret => {
        // console.log('[modify] flush callback firing...');
        doAction(ret)
      })
    }
  },

  boardExpand: function (tabOrId, listOrIdOrNode, params, callback) {
    const doAction = actionParams => {
      const newParams = Object.assign({}, actionParams)
      const tabs = actionParams.updatedState.entities
        ? actionParams.updatedState.entities.tabs
        : actionParams.state.entities.tabs
      var tabId = typeof tabOrId === 'string' ? tabOrId : tabOrId.id
      var tab = Tab.get(tabOrId, tabs)
      var newTab = tab
      var nodeIds
      var idsToExpand = []
      var currCollapsed = tab.collapsed_board_nodes || []
      if (
        typeof listOrIdOrNode === 'object' &&
        listOrIdOrNode instanceof Array
      ) {
        nodeIds = listOrIdOrNode
      } else {
        nodeIds =
          typeof listOrIdOrNode === 'string'
            ? [listOrIdOrNode]
            : [listOrIdOrNode.id]
      }
      nodeIds.forEach(nodeId => {
        var idx = currCollapsed.indexOf(nodeId)
        if (idx !== -1) {
          idsToExpand.push(nodeId)
        }
      })
      newTab = update(tab, { $merge: { updated_at: new Date() } })
      newTab.collapsed_board_nodes = currCollapsed.filter(
        id => idsToExpand.indexOf(id) === -1
      )
      var trimmedTabs = tabs.filter(t => t.id !== tab.id)
      var newTabs = trimmedTabs.concat([newTab])
      // setAppState({tabs: newTabs, tab: newTab});
      // user.collapsed_board_nodes.splice(idx, 1);
      if (!params.user) {
        // window.sessionStorage.setItem('collapsed_board_nodes', JSON.stringify(newTab.collapsed_board_nodes));
        window.localStorage.setItem('tab-' + tabId, JSON.stringify(newTab))
      }

      const undoData = {
        undo: [
          { obj: 'Tab', cmd: 'boardCollapse', args: [tab.id, listOrIdOrNode] }
        ],
        redo: [
          { obj: 'Tab', cmd: 'boardExpand', args: [tab.id, listOrIdOrNode] }
        ]
      }

      if (!actionParams._isUndoOperation && !actionParams._skipUndoAdd) {
        actionParams.undo.add(undoData, !tab.virtual)
      }

      const newEntities = Object.assign(
        {},
        actionParams.updatedState.entities || actionParams.state.entities
      )
      newEntities.tabs = newTabs
      const result = { entities: newEntities }

      newParams.updatedState = Object.assign(
        {},
        actionParams.updatedState,
        result
      )
      newParams.undoData = actionParams.undoData || {}
      newParams.undoData.undo = (newParams.undoData.undo || []).concat(
        undoData.undo
      )
      newParams.undoData.redo = (newParams.undoData.redo || []).concat(
        undoData.redo
      )

      if (actionParams._skipSetState) {
        if (typeof callback === 'function') {
          callback(newParams)
        }
      } else {
        actionParams.setAppState(
          newParams.updatedState,
          newParams.stateParams,
          () => {
            if (typeof callback === 'function') {
              callback(newParams)
            }
          }
        )
      }
    }

    if (params._skipFlushUndo) {
      doAction(params)
    } else {
      params.flushTextUndo({ nodeId: listOrIdOrNode }, params, ret => {
        // console.log('[modify] flush callback firing...');
        doAction(ret)
      })
    }
  },

  get: function (tabOrId, tabs) {
    if (typeof tabOrId === 'string') {
      const tabId = tabOrId
      if (/^share-/.test(tabId)) {
        var m = tabId.match(/^share-(.*)/)
        var localTab = window.localStorage.getItem('tab-' + tabId)
        return localTab
          ? JSON.parse(localTab)
          : { virtual: true, id: tabId, share_id: m[1], expanded_nodes: [] }
      } else {
        return tabs.find(t => t.id === tabOrId)
      }
    } else {
      return tabOrId
    }
  },

  tabThatPointsTo: function (tabOrId, tabs, org) {
    if (tabOrId) {
      var id = typeof tabOrId === 'string' ? tabOrId : tabOrId.id
      return tabs.find(t => t.prev_id === id)
    } else {
      return tabs.find(
        t => !t.prev_id && ((!org && !t.org_id) || (org && t.org_id === org.id))
      )
    }
  }
}

module.exports = Tab
