import './styles.css'

import React from 'react'
import $ from 'jquery'
import _ from 'lodash'
import ReactTooltip from 'react-tooltip'

import Node from '../../js/node'
import Misc from '../../js/misc'

class NodeContextMenu extends React.Component {
  state = {
    settingDueDate: false,
    expanded: false
  }

  shouldComponentUpdate (nextProps) {
    if (!nextProps.activeNodeOnly) {
      return true
    } else {
      return false
    }
  }

  openShareOverlay = () => {
    var node = this.props.node || this.props.rootNode
    this.props.app.setPanel('share-overlay', {
      data: { node: node },
      title: 'Share "' + Misc.truncate(node.title, 30) + '"'
    })
  }

  openEmbeddedOverlay = () => {
    var node = this.props.node || this.props.rootNode
    var resolvedNode = Node.getResolved(
      node,
      this.props.app.state.entities.nodes
    )
    this.props.app.setPanel('embedded-overlay', {
      data: { node: node },
      title: 'Embed info for "' + Misc.truncate(resolvedNode.title, 30) + '"'
    })
  }

  openSettingsOverlay = () => {
    var node = this.props.node || this.props.rootNode
    node = Node.getResolved(node, this.props.app.state.entities.nodes)
    this.props.app.setPanel(`${node.type}-settings-overlay`, {
      data: { node: node },
      title: 'Settings for "' + Misc.truncate(node.title, 30) + '"'
    })
  }

  openPermissionsOverlay = () => {
    if (this.props.app.featureEnabled('permissions')) {
      var node = this.props.node || this.props.rootNode
      node = Node.getResolved(node, this.props.app.state.entities.nodes)
      this.props.app.setPanel('permissions-overlay', {
        data: { node: node },
        title: 'Permissions for "' + Misc.truncate(node.title, 30) + '"'
      })
    }
  }

  openExportOverlay = () => {
    var node = this.props.node || this.props.rootNode
    this.props.app.setPanel('export-overlay', {
      data: { node: node },
      title: 'Export data'
    })
  }

  openImportOverlay = () => {
    var node = this.props.node || this.props.rootNode
    this.props.app.setPanel('import-overlay', {
      data: { node: node },
      title: 'Import data'
    })
  }

  openDuplicateOverlay = () => {
    var node = this.props.node || this.props.rootNode
    this.props.app.setPanel('duplicate-overlay', { data: { node } })
  }

  cutTree = () => {
    var node = this.props.node || this.props.rootNode
    this.props.app.setAppState({
      cutNodeId: node.id,
      updatedNodes: [node],
      floater: null
    })
  }

  copyTree = () => {
    var node = this.props.node || this.props.rootNode
    this.props.app.setAppState({
      copyNodeId: node.id,
      updatedNodes: [node],
      floater: null
    })
  }

  pasteTree = () => {
    var dst = this.props.node || this.props.rootNode
    var srcId =
      this.props.app.state.cutNodeId || this.props.app.state.copyNodeId
    var src = Node.get(srcId, this.props.app.state.entities.nodes)
    if (src) {
      const commonParams = this.props.app.getCommonParams()
      commonParams.updatedState = {
        cutNodeId: null,
        copyNodeId: null,
        floater: null
      }
      if (this.props.app.state.cutNodeId) {
        Node.move(src, dst.id, null, commonParams)
      } else {
        Node.duplicate(src.id, dst.id, null, null, commonParams)
      }
      this.close()
    }
  }

  cancelCutCopy = () => {
    this.props.app.setAppState({ cutNodeId: null, copyNodeId: null })
    this.close()
  }

  convertToSite = () => {
    var node = this.props.node || this.props.rootNode
    var newNode = { ...node }
    newNode.settings = { ...node.settings }
    newNode.type = 'site'
    this.props.app.setPanel('site-settings-overlay', {
      data: { node: newNode }
    })
    this.close()
    if (window.Intercom) {
      window.Intercom('trackEvent', 'converted-node-to-site')
    }
    // if (this.props.app.featureEnabled('sites')) {
    // }
  }

  convertToBookmark = () => {
    var node = this.props.node || this.props.rootNode
    var newNode = { ...node }
    newNode.settings = { ...node.settings }
    newNode.type = 'bookmark'
    var url = node.title.match(/(https?:\/\/[^\s]+)/)
    if (url && url[1] && !node.url) {
      newNode.url = url[1]
    }
    this.props.app.setPanel('bookmark-settings-overlay', {
      data: { node: newNode }
    })
    this.close()
    if (window.Intercom) {
      window.Intercom('trackEvent', 'converted-node-to-bookmark')
    }
  }

  convertToType = type => {
    if (type === 'site') {
      this.convertToSite()
    } else if (type === 'bookmark') {
      this.convertToBookmark()
    } else {
      var node = this.props.node || this.props.rootNode
      var changes = { type: type }
      if (node.type === 'bookmark') {
        changes.title = node.title + ': ' + node.url
      }
      var commonParams = this.props.app.getCommonParams()
      commonParams.updatedState = { overlay: null }
      Node.modify(node.id, changes, commonParams)
      this.close()
    }
  }

  deleteNode = () => {
    var node = this.props.node || this.props.rootNode
    this.props.app.deleteNode(node)
    this.close()
  }

  duplicateNode = () => {
    var node = this.props.node || this.props.rootNode
    Node.duplicate(
      node.id,
      node.parent_id,
      node.id,
      null,
      this.props.app.getCommonParams()
    )
    this.close()
  }

  setMode = mode => {
    var node = this.props.node || this.props.rootNode
    Node.modify(node.id, { mode: mode }, this.props.app.getCommonParams())
    this.close()
  }

  toggleDescription = () => {
    var node = this.props.node || this.props.rootNode
    this.props.app.toggleDescription(node, true)
    // if (this.props.app.state.descriptionNode === node.id) {
    //   this.props.app.setAppState({descriptionNode: null}, () => {
    //     Node.focus(node.id, null, _this.props.app.getCommonParams());
    //   });
    // } else {
    //   this.props.app.setAppState({descriptionNode: node.id}, () => {
    //     window._app.original_description = node.description || '';
    //     var li = $('li.node[data-node-id="' + node.id + '"]');
    //     li.find('.description[contenteditable]').focus();
    //   });
    // }
    this.close()
  }

  setBookmarksNode = () => {
    var node = this.props.node || this.props.rootNode
    var newSettings = {
      bookmarks_node: node.id
    }
    this.props.app.saveUserSettings(
      newSettings,
      this.props.app.getCommonParams()
    )
    if (window.Intercom) {
      window.Intercom('trackEvent', 'set-bookmarks-node')
    }
  }

  unsetBookmarksNode = () => {
    var newSettings = {
      bookmarks_node: null
    }
    this.props.app.saveUserSettings(
      newSettings,
      this.props.app.getCommonParams()
    )
  }

  openInNewTab = () => {
    var node = this.props.node || this.props.rootNode
    this.props.app.addTab({ node_id: node.id })
    this.close()
  }

  browseRevisions = () => {
    var node = this.props.node || this.props.rootNode
    this.props.app.setPanel('revisions-overlay', {
      data: { node: node },
      title: 'Revisions for "' + Misc.truncate(node.title, 30) + '"'
    })
    // this.props.app.openRevisionBrowser(node)
    this.close()
  }

  toggleCompleted = () => {
    var node = this.props.node || this.props.rootNode
    this.props.app.toggleCompleted(node)
    this.close()
  }

  toggleEmphasis = () => {
    var node = this.props.node || this.props.rootNode
    this.props.app.toggleEmphasis(node)
    this.close()
  }

  convertTreeToDocument = () => {
    var node = this.props.node || this.props.rootNode
    this.props.app.convertTreeToDocument(node)
    this.close()
  }

  toggleAssignTo = () => {
    var node = this.props.node || this.props.rootNode
    this.props.app.setAppState(
      { assignNode: node.id, updatedNodes: [node] },
      () => {
        // $('#due-date-input').focus();
        // this.refs.dueDate.focus();
      }
    )
    this.close()
  }

  clearAssignedTo = () => {
    var node = this.props.node || this.props.rootNode
    this.props.app.setAssignedTo(node, null)
    this.close()
  }

  toggleDueDate = () => {
    var node = this.props.node || this.props.rootNode
    this.props.app.setAppState(
      { dueNode: node.id, updatedNodes: [node] },
      () => {
        $('#due-date-input').focus()
        // this.refs.dueDate.focus();
      }
    )
    this.close()
  }

  toggleFave = () => {
    var node = this.props.node || this.props.rootNode
    this.props.app.toggleFave(node)
    this.close()
  }

  clearDueDate = () => {
    var node = this.props.node || this.props.rootNode
    this.props.app.setDueDate(node, null)
    this.close()
  }

  close = () => {
    this.props.app.setState({ floater: null })
  }

  render () {
    let top, fave
    const icons = []
    const modeButtons = []
    const editButtons = []
    const miscButtons = []
    const importExportButtons = []
    const permissionButtons = []
    var app = this.props.app
    var user = this.props.user
    var node = this.props.node || this.props.rootNode
    var org = this.props.org
    var sections = {
      main: ['Main', []],
      settings: ['Settings', []],
      edit: ['Edit', []],
      modify: ['Modify', []],
      todo: ['TODO Actions', []],
      notes: ['Document Actions', []],
      misc: ['Misc.', []]
    }

    if (node) {
      var readOnly, $self
      if (this.props.node) {
        // console.log('huttete');
        $self = $(Misc.nodeEl(node))
        readOnly =
          $self.closest('li.node.read-only').length ||
          $self.closest('#body-container.read-only').length
      } else if (this.props.classes) {
        readOnly = /\bread-only\b/.test(this.props.classes)
      }
      // console.log('readOnly now', readOnly);
      const share = Node.getShare(
        node,
        app.state.entities.shares,
        app.state.userId,
        app.state.orgId
      )
      fave = this.props.app.state.entities.faves.find(f => {
        return f.node_id === node.id
      })

      const types = []
      types.push(
        <li key={node.type}>
          {this.props.app.nodeIcon(node)}{' '}
          {this.props.app.nodeTypeText(node.type)}
        </li>
      )
      this.props.app.validNodeTypes().forEach(t => {
        if (t !== node.type) {
          types.push(
            <li key={t} onClick={() => this.convertToType(t)}>
              {this.props.app.nodeIcon(t)} {this.props.app.nodeTypeText(t)}
            </li>
          )
        }
      })
      if (!readOnly) {
        icons.push(
          <div key='type' data-tip data-for='type' className='sticky'>
            <div>
              {this.props.app.nodeIcon(node)}
              <span>
                <i className='fa fa-fw fa-angle-down' />
              </span>
              <ul>{types}</ul>
            </div>
          </div>
        )
        icons.push(
          <div
            key='share'
            className={`share ${share ? 'active' : ''}`}
            data-tip
            data-for={share ? 'shared' : 'share'}
            onClick={this.openShareOverlay}
          >
            <i className='fa fa-fw fa-share-alt' />
          </div>
        )
        miscButtons.push(
          <div key='duplicate' onClick={this.duplicateNode}>
            <i className='fa fa-fw fa-clone' /> Duplicate
          </div>
        )
      }
      if (user) {
        miscButtons.push(
          <div key='tab' onClick={this.openInNewTab}>
            <i className='fa fa-fw fa-plus-square-o' /> Open in new tab
          </div>
        )
        const faveIcon = fave ? 'fa-star is-fave' : 'fa-star-o'
        icons.push(
          <div
            key='fave'
            data-tip
            data-for='fave'
            className={fave ? 'active' : ''}
            onClick={this.toggleFave}
          >
            <i className={'fa fa-fw ' + faveIcon} />
          </div>
        )
        icons.push(
          <div
            key='settings'
            data-tip
            data-for='settings'
            onClick={this.openSettingsOverlay}
          >
            <i className='fa fa-fw fa-gear' />
          </div>
        )
      }
      if (!readOnly || node.remote_id) {
        icons.push(
          <div
            key='delete'
            className='danger'
            data-tip
            data-for='delete'
            onClick={this.deleteNode}
          >
            <i className='fa fa-fw fa-trash' />
          </div>
        )
      }

      if (!readOnly && this.props.app.featureEnabled('board')) {
        modeButtons.push(
          <div
            key='list-mode'
            className={node.mode ? 'big' : 'active big'}
            onClick={() => this.setMode(null)}
          >
            <i className='fa fa-fw fa-navicon' /> List
          </div>
        )
        modeButtons.push(
          <div
            key='board-mode'
            className={node.mode === 'board' ? 'active big' : 'big'}
            onClick={() => this.setMode('board')}
          >
            <i className='fa fa-fw fa-navicon fa-rotate-90' /> Board
          </div>
        )
      }

      if (!readOnly) {
        editButtons.push(
          <div key='cut' onClick={this.cutTree}>
            <i className='fa fa-fw fa-cut' /> Cut
          </div>
        )
      }
      editButtons.push(
        <div key='copy' onClick={this.copyTree}>
          <i className='fa fa-fw fa-copy' /> Copy
        </div>
      )
      editButtons.push(
        <div
          key='paste'
          className={
            this.props.app.state.cutNodeId || this.props.app.state.copyNodeId
              ? ''
              : 'disabled'
          }
          onClick={this.pasteTree}
        >
          <i className='fa fa-fw fa-paste' /> Paste
        </div>
      )
      if (this.props.app.state.cutNodeId || this.props.app.state.copyNodeId) {
        editButtons.push(
          <div
            key='cancelcc'
            onClick={this.cancelCutCopy}
            data-tip
            data-for='cancelcc'
          >
            <i className='fa fa-fw fa-ban' />
          </div>
        )
      }

      importExportButtons.push(
        <div key='export' onClick={this.openExportOverlay}>
          <i className='fa fa-fw fa-sign-out' /> Export…
        </div>
      )
      importExportButtons.push(
        <div
          key='import'
          onClick={this.openImportOverlay}
          className={readOnly ? 'disabled' : ''}
        >
          <i className='fa fa-fw fa-sign-in' /> Import…
        </div>
      )

      if (!readOnly) {
        if (this.props.app.featureEnabled('permissions') && org) {
          permissionButtons.push(
            <div key='permissions' onClick={this.openPermissionsOverlay}>
              <i className='fa fa-fw fa-lock' /> Permissions...
            </div>
          )
        }
        if (
          ($self &&
            $self.length &&
            ($self.hasClass('children') || $self.hasClass('expanded'))) ||
          (this.props.classes && /\bchildren\b/.test(this.props.classes))
        ) {
          sections.misc[1].push(
            <li
              key='convert-tree-to-document'
              onClick={this.convertTreeToDocument}
            >
              Convert tree to document
            </li>
          )
        }
      }

      top = (
        <ul className='top'>
          {Boolean(icons.length) && <li className='selector main'>{icons}</li>}
          {Boolean(modeButtons.length) && (
            <li className='selector'>{modeButtons}</li>
          )}
          {Boolean(editButtons.length) && (
            <li className='selector'>{editButtons}</li>
          )}
          {Boolean(miscButtons.length) && (
            <li className='selector'>{miscButtons}</li>
          )}
          {Boolean(importExportButtons.length) && (
            <li className='selector'>{importExportButtons}</li>
          )}
          {Boolean(permissionButtons.length) && (
            <li className='selector'>{permissionButtons}</li>
          )}
        </ul>
      )

      if (!readOnly) {
        var completeText = node.completed
          ? 'Mark as Uncompleted'
          : 'Mark as Completed'
        var emphasisText = node.emphasized
          ? 'Turn Off Emphasis'
          : 'Turn On Emphasis'
        sections.modify[1].push(
          <li key='complete' onClick={this.toggleCompleted}>
            {completeText}
          </li>
        )
        sections.modify[1].push(
          <li key='description' onClick={this.toggleDescription}>
            Add description
          </li>
        )
        sections.modify[1].push(
          <li key='emphasis' onClick={this.toggleEmphasis}>
            {emphasisText}
          </li>
        )
        if (user && user.settings) {
          if (user.settings.bookmarks_node === node.id) {
            sections.misc[1].push(
              <li key='unset-bookmarks' onClick={this.unsetBookmarksNode}>
                Unset as Bookmarks Home
              </li>
            )
          } else if (node.type !== 'site') {
            sections.misc[1].push(
              <li key='set-bookmarks' onClick={this.setBookmarksNode}>
                Set as Bookmarks Home
              </li>
            )
          }
        }
        if (user && node.body !== null) {
          sections.misc[1].push(
            <li key='browse-revisions' onClick={this.browseRevisions}>
              View previous versions...
            </li>
          )
        }
      }
      // if (user) {
      //   if (!readOnly) {
      //     if (user) {
      //       if (this.props.app.featureEnabled('assign_to', user)) {
      //         if (node.assigned_to) {
      //           sections.todo[1].push(<li key="clear-assigned-to" onClick={this.clearAssignedTo}>Clear assigned user</li>);
      //         } else {
      //           sections.todo[1].push(<li key="assign-to" onClick={this.toggleAssignTo}>Assign to user...</li>);
      //         }
      //       }
      //     }
      //     if (this.props.app.featureEnabled('due_nodes', user)) {
      //       if (node.due_at) {
      //         sections.todo[1].push(<li key="clear-due-date" onClick={this.clearDueDate}>Remove due date</li>);
      //       } else {
      //         sections.todo[1].push(<li key="due-date" onClick={this.toggleDueDate}>Set due date...</li>);
      //       }
      //     }
      //   }
      // }
    }

    var id
    if (
      node &&
      this.props.user &&
      this.props.user.settings &&
      this.props.user.settings._show_index
    ) {
      id = <div className='dev-id'>{node.id}</div>
    }
    var menuItems = []
    var main = sections.main[1]
    if (main.length) {
      main.forEach(function (item) {
        menuItems.push(item)
      })
      menuItems.push(<li className='sep' key='sep' />)
    }
    var keys = _.keys(sections)
    // keys.forEach(function(key) {
    //   if (key !== 'main') {
    //     var section = sections[key];
    //     if (section[1].length === 1) {
    //       menuItems.push(section[1][0]);
    //     }
    //   }
    // });
    keys.forEach(function (key) {
      if (key !== 'main') {
        var section = sections[key]
        var sectionItems = []
        // if (section[1].length > 1 || key === 'actions') {
        if (section[1].length > 0 || key === 'actions') {
          section[1].forEach(function (item) {
            sectionItems.push(item)
          })
          menuItems.push(
            <li className='section' key={key}>
              <div>{section[0]}</div>
              <ul>{sectionItems}</ul>
            </li>
          )
        }
      }
    })

    const tooltipDelay = 250
    return (
      <div className='context-menu'>
        {id}
        {top}
        <ul>{menuItems}</ul>

        <ReactTooltip
          type='dark'
          effect='solid'
          delayShow={tooltipDelay}
          id='type'
        >
          <span>Change type</span>
        </ReactTooltip>
        <ReactTooltip
          type='dark'
          effect='solid'
          delayShow={tooltipDelay}
          id='share'
        >
          <span>Share</span>
        </ReactTooltip>
        <ReactTooltip
          type='dark'
          effect='solid'
          delayShow={tooltipDelay}
          id='shared'
        >
          <span>Shared</span>
        </ReactTooltip>
        <ReactTooltip
          type='dark'
          effect='solid'
          delayShow={tooltipDelay}
          id='embedded'
        >
          <span>Embedded</span>
        </ReactTooltip>
        <ReactTooltip
          type='dark'
          effect='solid'
          delayShow={tooltipDelay}
          id='settings'
        >
          <span>Settings</span>
        </ReactTooltip>
        <ReactTooltip
          type='dark'
          effect='solid'
          delayShow={tooltipDelay}
          id='delete'
        >
          <span>Delete</span>
        </ReactTooltip>
        <ReactTooltip
          type='dark'
          effect='solid'
          delayShow={tooltipDelay}
          id='cancelcc'
        >
          <span>Cancel cut/copy</span>
        </ReactTooltip>
        <ReactTooltip
          type='dark'
          effect='solid'
          delayShow={tooltipDelay}
          id='fave'
        >
          <span>{fave ? 'Faved' : 'Add to Favorites'}</span>
        </ReactTooltip>
      </div>
    )
  }
}

module.exports = NodeContextMenu
