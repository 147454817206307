import React from 'react'

class LoginForm extends React.Component {
  state = {}

  onSubmit = ev => {
    ev.preventDefault()
    var data = {
      username: this.username.value,
      password: this.password.value
    }
    this.props.onSubmit(data)
  }

  render () {
    var buttonText = this.props.buttonText || 'Login'
    var username = this.props.readonlyUsername || this.props.username
    return (
      <form onSubmit={this.onSubmit}>
        <div>
          <input
            type='text'
            ref={username => {
              this.username = username
            }}
            placeholder='Username'
            className='space-bottom'
            defaultValue={username}
            readOnly={Boolean(this.props.readonlyUsername)}
            required
          />
        </div>
        <div>
          <input
            type='password'
            ref={password => {
              this.password = password
            }}
            placeholder='Password'
            required
          />
        </div>
        <div className='notification'>{this.props.message}</div>
        <div>
          <input type='submit' value={buttonText} className='btn dark' />
          <a href='' onClick={this.props.forgotPassword} className='small-link'>
            I forgot my password
          </a>
        </div>
      </form>
    )
  }
}

module.exports = LoginForm

// <form onSubmit={this.onSubmit}>
//   <input type="text" ref="username" name="user" placeholder="Username" defaultValue={username} readOnly={Boolean(this.props.readonlyUsername)} />
//   <input type="password" ref="password" name="pass" placeholder="Password" />
//   <input type="submit" value={buttonText} className="btn dark" />
// </form>
