import './styles.css'

import React from 'react'
import _ from 'lodash'
import _update from 'react-addons-update'

// Menu
import UserMenu from './menus/user_menu'

// Overlays
import ListSettingsOverlay from './overlays/list_settings_overlay'
import NoteSettingsOverlay from './overlays/note_settings_overlay'
import ProjectSettingsOverlay from './overlays/project_settings_overlay'
import SiteSettingsOverlay from './overlays/site_settings_overlay'
import BookmarkSettingsOverlay from './overlays/bookmark_settings_overlay'
import TabSettingsOverlay from './overlays/tab_settings_overlay'
import PermissionsOverlay from './overlays/permissions'
import ShareOverlay from './overlays/share_overlay'
import SharesOverlay from './overlays/shares_overlay'
import EmbeddedOverlay from './overlays/embedded_overlay'
import PublishOverlay from './overlays/publish_overlay'
import ExportOverlay from './overlays/export_overlay'
import ImportOverlay from './overlays/import_overlay'
import InviteBetaOverlay from './overlays/invite_beta_overlay'
import OrgsOverlay from './overlays/orgs_overlay'
import CreateOrgOVerlay from './overlays/create_org_overlay'
import DueListSettingsOverlay from './overlays/due_list_settings_overlay'
import FeedbackOverlay from './overlays/feedback_overlay'
import RevisionsOverlay from './overlays/revisions_overlay'

// Pages
import UserSettingsPage from './pages/user_settings_page'
import OrganizationsPage from './pages/organizations_page'
import NotificationsPage from './pages/notifications_page'

// Panels . sort by Menu, overlay, page
const panels = {
  // Menus
  'user-menu': UserMenu,
  // Overlays
  'list-settings-overlay': ListSettingsOverlay,
  'meeting-settings-overlay': ListSettingsOverlay,
  'trip-settings-overlay': ListSettingsOverlay,
  'publication-settings-overlay': ListSettingsOverlay,
  'person-settings-overlay': ListSettingsOverlay,
  'company-settings-overlay': ListSettingsOverlay,
  'todo-settings-overlay': ListSettingsOverlay,
  'note-settings-overlay': NoteSettingsOverlay,
  'project-settings-overlay': ProjectSettingsOverlay,
  'site-settings-overlay': SiteSettingsOverlay,
  'bookmark-settings-overlay': BookmarkSettingsOverlay,
  'tab-settings-overlay': TabSettingsOverlay,
  'permissions-overlay': PermissionsOverlay,
  'share-overlay': ShareOverlay,
  'shares-overlay': SharesOverlay,
  'embedded-overlay': EmbeddedOverlay,
  'publish-overlay': PublishOverlay,
  'export-overlay': ExportOverlay,
  'import-overlay': ImportOverlay,
  'invite-beta-overlay': InviteBetaOverlay,
  'orgs-overlay': OrgsOverlay,
  'create-org': CreateOrgOVerlay,
  'due-list-settings-overlay': DueListSettingsOverlay,
  'feedback-overlay': FeedbackOverlay,
  'revisions-overlay': RevisionsOverlay,
  // Pages
  'user-settings-page': UserSettingsPage,
  'organizations-page': OrganizationsPage,
  'notifications-page': NotificationsPage
}

class Panels extends React.Component {
  constructor (props) {
    super(props)
    var state = {
      activeTab: props.panel && props.panel.opts ? props.panel.opts.tab : null,
      activeVtab:
        props.panel && props.panel.opts ? props.panel.opts.vtab : null,
      sections: {}
    }
    this.state = state
  }

  shouldComponentUpdate (nextProps) {
    if (this.props.app && this.props.shouldComponentTypeUpdate) {
      return this.props.app.shouldComponentTypeUpdate(nextProps)
    } else {
      return true
    }
  }

  dismissImportError = () => {
    this.props.app.setState({ importError: null, importing: false }, () => {
      this.props.app.setPanel(null)
    })
  }

  selectTab = name => {
    var _this = this
    return function (ev) {
      _this.setState({
        activeTab: _this.props.panel.name + '-' + name,
        activeTabId: name
      })
      if (ev) {
        ev.preventDefault()
      }
    }
  }

  selectVtab = (name, tabId) => {
    var _this = this
    return function (ev) {
      var newState = {
        activeVtab: _this.props.panel.name + '-' + name,
        activeVtabId: name
      }
      if (tabId) {
        newState.activeTab = _this.props.panel.name + '-' + tabId
        newState.activeTabId = tabId
      }
      _this.setState(newState)
      if (ev) {
        ev.preventDefault()
      }
    }
  }

  toggleSection = name => {
    var _this = this
    var tab = this.state.activeTab || this.props.panel.name + '-_'
    return function () {
      var obj = {}
      var newVal
      if (
        _this.state.sections[tab] &&
        _this.state.sections[tab].indexOf(name) !== -1
      ) {
        newVal = _this.state.sections[tab].filter(s => s !== name)
      } else {
        if (_this.state.sections[tab]) {
          newVal = _this.state.sections[tab].concat([name])
        } else {
          newVal = [name]
        }
      }

      obj[tab] = newVal
      var newState = {
        sections: _update(_this.state.sections, { $merge: obj })
      }
      _this.setState(newState)
    }
  }

  activeTabClass = (name, defaultTab) => {
    return (this.state.activeTab ||
      this.props.panel.name + '-' + defaultTab) ===
      this.props.panel.name + '-' + name
      ? 'active'
      : ''
  }

  activeVtabClass = (name, defaultTab) => {
    return (this.state.activeVtab ||
      this.props.panel.name + '-' + defaultTab) ===
      this.props.panel.name + '-' + name
      ? 'active'
      : ''
  }

  openSectionClass = (name, panelState) => {
    var tab = this.state.activeTab || this.props.panel.name + '-_'
    if (panelState && panelState.error && panelState.error.field) {
      var parts = panelState.error.field.split('.')
      var errorTab = parts[0]
      var errorField = parts[1]

      // XXX: This is pretty hacky...
      if (
        (tab === 'node-settings-_' || tab === 'node-settings-settings') &&
        errorTab === 'settings' &&
        (errorField === 'sub_domain' || errorField === 'custom_domain')
      ) {
        return 'open'
      }
    } else {
      return this.state.sections[tab] &&
        this.state.sections[tab].indexOf(name) !== -1
        ? 'open'
        : ''
    }
  }

  closePanel = ev => {
    this.props.app.setPanel(null)
    ev.preventDefault()
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps (nextProps) {
    var newState = {}
    if (!this.props.panel && nextProps.panel) {
      newState.activeTab = nextProps.panel.name + '-settings'
    }
    // if (!this.state.activeVtabId && nextProps.overlay && nextProps.overlay.type === 'orgs' && this.props.overlay !== nextProps.overlay && nextProps.app.state.orgs && nextProps.app.state.orgs.length) {
    if (
      nextProps.panel &&
      nextProps.panel.name === 'organizations-page' &&
      this.props.panel !== nextProps.panel &&
      nextProps.app.state.entities.orgs &&
      nextProps.app.state.entities.orgs.length
    ) {
      var panelOpts = nextProps.panel.opts
      var defaultOrg =
        panelOpts.data.org ||
        _.sortBy(nextProps.app.state.entities.orgs, 'name')[0]
      newState.activeTab = nextProps.panel.name + '-users'
      newState.activeVtab = nextProps.panel.name + '-' + defaultOrg.id
      newState.activeVtabId = defaultOrg.id
    }
    this.setState(newState)
  }

  renderOverlay = component => {
    var working
    if (this.props.app.state.importing) {
      working = (
        <div className='working'>
          <div className='working-indicator'>
            <div className='rect1' />
            <div className='rect2' />
            <div className='rect3' />
            <div className='rect4' />
            <div className='rect5' />
          </div>
          <h4>Importing...</h4>
          <p>
            This could take several minutes, depending on the number of items to
            be imported. Please be patient.
          </p>
          <p id='import-progress'>Calculating size...</p>
        </div>
      )
    }
    if (this.props.app.state.importError) {
      working = (
        <div className='working error'>
          <h4>Import failed</h4>
          <p>The import job failed, probably due to bad data.</p>
          <p>
            We are automatically notified of failed imports, but if you are
            consistently having issues with some particular data that you
            believe to be valid, you might want to contact us at{' '}
            <a href='mailto:support@notebase.io'>support@notebase.io</a>.
          </p>
          <p>
            If you do contact us, please attach (or link to) the data you are
            trying to import.
          </p>
          <p>
            <button className='button' onClick={this.dismissImportError}>
              OK, got it
            </button>
          </p>
        </div>
      )
    }
    return (
      <div id='overlay'>
        <div id='overlay-box'>
          <div onClick={this.closePanel} className='close-button'>
            <i className='fa fa-fw fa-close' />
          </div>
          {working}
          <header>
            <h4>
              {this.props.panel.opts
                ? this.props.panel.opts.title
                : '(no title)'}
            </h4>
            {/*
              <p>Cute little description to motivate the user!</p>
            */}
          </header>
          {component}
        </div>
      </div>
    )
  }

  renderPage = component => {
    return (
      <div id='panel-page'>
        <div className='panel-page-center'>{component}</div>
      </div>
    )
  }

  render () {
    var content

    if (this.props.panel) {
      var type = 'overlay'
      if (['user-menu'].indexOf(this.props.panel.name) !== -1) {
        type = 'menu'
      } else if (
        [
          'user-settings-page',
          'notifications-page',
          'organizations-page'
        ].indexOf(this.props.panel.name) !== -1
      ) {
        type = 'page'
      }

      var re = new RegExp('^' + this.props.panel.name + '-')
      var activeTab = this.state.activeTab
        ? this.state.activeTab.replace(re, '')
        : null
      var activeVtab = this.state.activeVtab
        ? this.state.activeVtab.replace(re, '')
        : null

      var panelOpts = this.props.panel.opts || {}

      var commonOpts = {
        app: this.props.app,
        activeSection: panelOpts.activeSection,

        activeTab: activeTab,
        activeTabId: this.state.activeTabId,
        selectTab: this.selectTab,
        activeTabClass: this.activeTabClass,
        activeVtab: activeVtab,
        activeVtabId: this.state.activeVtabId,
        selectVtab: this.selectVtab,
        activeVtabClass: this.activeVtabClass,
        sections: this.state.sections,
        toggleSection: this.toggleSection,
        openSectionClass: this.openSectionClass,
        node: this.props.node,
        replaceNodes: this.props.replaceNodes,
        panel: this.props.panel,
        setPanel: this.props.setPanel,
        setAppState: this.props.setAppState,
        setLastUserAction: this.props.setLastUserAction,
        saveUserSettings: this.props.saveUserSettings,
        getCommonParams: this.props.getCommonParams,
        user: this.props.user,
        org: this.props.org,
        tab: this.props.tab,
        rootNode: this.props.rootNode,
        vtab: this.props.vtab,
        vtabs: this.props.vtabs,
        authToken: this.props.authToken
      }

      if (type === 'menu') {
        content = React.createFactory(panels[this.props.panel.name])(commonOpts)
      } else if (type === 'overlay') {
        const component = React.createFactory(panels[this.props.panel.name])(
          commonOpts
        )
        content = this.renderOverlay(component)
      } else if (type === 'page') {
        const component = React.createFactory(panels[this.props.panel.name])(
          commonOpts
        )
        content = this.renderPage(component)
      }
    }

    return <div id='panels'>{content}</div>
  }
}

module.exports = Panels
