import React from 'react'

import Heading from '../sections/heading'
// import Misc from '../../../js/misc';

import './styles.css'

export default class Background extends React.Component {
  render () {
    const { app } = this.props
    return (
      <div className='body full-height background'>
        <Heading {...this.props} name='Background' isMain />
        <div className='modes'>
          <div
            className='large'
            onClick={() => app.pushSidebar(['bg-scope', { type: 'unsplash' }])}
          >
            <img src='https://storage.googleapis.com/notebase-files/other-logos/unsplash.jpg' />
            <button>Set Unsplash Background</button>
          </div>
          <div
            className='large'
            onClick={() => app.pushSidebar(['bg-scope', { type: 'custom' }])}
          >
            <img src='https://storage.googleapis.com/notebase-files/illustrations/upload-background.jpg' />
            <button>Upload Your Own Background</button>
          </div>
          <div onClick={() => app.pushSidebar(['bg-scope', { type: 'unset' }])}>
            <button className='unset'>Unset Background</button>
          </div>
        </div>
      </div>
    )
  }
}
