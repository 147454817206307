/* eslint-disable react/jsx-indent */
import React from 'react'
import $ from 'jquery'

import Heading from '../sections/heading'

import './styles.css'

class MarkdownHelp extends React.Component {
  doScroll = (id, ev) => {
    var mdHelp = $(this.mdHelp)
    var el = mdHelp.find('h4[data-toc-id="' + id + '"]')
    // mdHelp.scrollTo(el, {offsetTop: 170, duration: 200, force: true});
    mdHelp.scrollTo(el, {
      duration: 200,
      force: true,
      usePosition: true,
      offsetTop: 0
    })
    ev.preventDefault()
  }

  render () {
    /* eslint-disable indent */
    return (
      <div className='body full-height md-help-container' ref={mdHelp => {
        this.mdHelp = mdHelp
      }}>
        <Heading {...this.props} name='MarkDown Help' isMain />
        <div className='md-help tag-content'>
          <div className='md-toc'>
            <ul className='left'>
              <li>
                <a href='' onClick={ev => this.doScroll('md-phrase-em', ev)}>
                  Phrase emphasis
                </a>
              </li>
              <li>
                <a href='' onClick={ev => this.doScroll('md-links', ev)}>
                  Links
                </a>
              </li>
              <li>
                <a href='' onClick={ev => this.doScroll('md-images', ev)}>
                  Images
                </a>
              </li>
              <li>
                <a href='' onClick={ev => this.doScroll('md-headings', ev)}>
                  Headings
                </a>
              </li>
              <li>
                <a href='' onClick={ev => this.doScroll('md-lists', ev)}>
                  Lists
                </a>
              </li>
            </ul>
            <ul className='right'>
              <li>
                <a href='' onClick={ev => this.doScroll('md-blockquotes', ev)}>
                  Blockquotes
                </a>
              </li>
              <li>
                <a href='' onClick={ev => this.doScroll('md-inline-code', ev)}>
                  Inline Code
                </a>
              </li>
              <li>
                <a href='' onClick={ev => this.doScroll('md-code-blocks', ev)}>
                  Code Blocks
                </a>
              </li>
              <li>
                <a href='' onClick={ev => this.doScroll('md-hrs', ev)}>
                  Horizontal Rules
                </a>
              </li>
              <li>
                <a href='' onClick={ev => this.doScroll('md-brs', ev)}>
                  Manual line breaks
                </a>
              </li>
            </ul>
          </div>
          <div className='note-body'>
            <h4 data-toc-id='md-phrase-em'>Phrase Emphasis</h4>
            <pre>
              <code>
                *italic* **bold**{'\n'}
                _italic_ __bold__
              </code>
            </pre>

            <h4 data-toc-id='md-links'>Links</h4>
            <p>Inline:</p>

            <pre>
              <code>
                An [example](http://url.com/ {'"'}Title{'"'})
              </code>
            </pre>

            <p>Reference-style labels (titles are optional):</p>

            <pre>
              <code>
                An [example][id]. Then, anywhere{'\n'}
                else in the doc, define the link:{'\n'}
                {'\n'}
                [id]: http://example.com/ {'"'}Title{'"'}
              </code>
            </pre>

            <h4 data-toc-id='md-images'>Images</h4>
            <p>Inline (titles are optional):</p>

            <pre>
              <code>
                ![alt text](/path/img.jpg {'"'}Title{'"'})
              </code>
            </pre>

            <p>Reference-style:</p>
            <pre>
              <code>
                ![alt text][id]{'\n'}
                {'\n'}
                [id]: /url/to/img.jpg {'"'}Title{'"'}
              </code>
            </pre>

            <h4 data-toc-id='md-headings'>Headings</h4>
            <p>Setext-style:</p>

            <pre>
              <code>
                Heading 1{'\n'}
                ========{'\n'}
                {'\n'}
                Heading 2{'\n'}
                --------{'\n'}
              </code>
            </pre>

            <p>atx-style (closing #s are optional):</p>
            <pre>
              <code>
                # Heading 1 #{'\n'}
                ## Heading 2 ##{'\n'}
                ###### Header 6{'\n'}
              </code>
            </pre>

            <h4 data-toc-id='md-lists'>Lists</h4>
            <p>Ordered, without paragraphs:</p>
            <pre>
              <code>
                1. Foo{'\n'}
                2. Bar{'\n'}
              </code>
            </pre>

            <p>Unordered, with paragraphs:</p>
            <pre>
              <code>
                * A list item.{'\n'}
                {'    '}With multiple paragraphs.{'\n'}
                {'\n'}
                * Bar{'\n'}
              </code>
            </pre>

            <p>You can nest them:</p>
            <pre>
              <code>
                * Abacus{'\n'}
                {'    '}* answer{'\n'}
                * Bubbles{'\n'}
                {'    '}1. bunk{'\n'}
                {'    '}2. bupkis{'\n'}
                {'    '}
                {'    '}* BELITTLER{'\n'}
                {'    '}3. burper{'\n'}
                * Cunning{'\n'}
              </code>
            </pre>

            <h4 data-toc-id='md-blockquotes'>Blockquotes</h4>
            <pre>
              <code>
                &gt; Email-style angle brackets{'\n'}
                &gt; are used for blockquotes.{'\n'}
                {'\n'}
                &gt; &gt; And, they can be nested.{'\n'}
                {'\n'}
                &gt; #### Headers in blockquotes{'\n'}
                &gt;{'\n'}
                &gt; * You can quote a list.{'\n'}
                &gt; * Etc.{'\n'}
              </code>
            </pre>

            <h4 data-toc-id='md-inline-code'>Inline Code</h4>
            <pre>
              <code>
                Inline `&lt;code&gt;` spans are delimited{'\n'}
                by backticks.{'\n'}
                {'\n'}
                You can include literal backticks{'\n'}
                like `` `this` ``.{'\n'}
              </code>
            </pre>

            <h4 data-toc-id='md-code-blocks'>Code Blocks</h4>
            <p>
              Indent every line of a code block by at least 4 spaces or 1 tab.
            </p>

            <pre>
              <code>
                This is a normal paragraph.{'\n'}
                {'\n'}
                {'    '}This is a preformatted{'\n'}
                {'    '}code block.{'\n'}
              </code>
            </pre>

            <h4 data-toc-id='md-hrs'>Horizontal Rules</h4>
            <p>Three or more dashes or asterisks:</p>

            <pre>
              <code>
                ---{'\n'}
                {'\n'}
                * * *{'\n'}
                {'\n'}
                - - - -{'\n'}
              </code>
            </pre>

            <h4 data-toc-id='md-brs'>Manual Line Breaks</h4>
            <p>End a line with two or more spaces:</p>

            <pre>
              <code>
                Roses are red,{'  \n'}
                Violets are blue.{'  \n'}
              </code>
            </pre>
            <blockquote>
              Based on the{' '}
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://github.com/fletcher/MultiMarkdown/blob/master/Documentation/Markdown%20Syntax.md'
              >
                Markdown syntax guide
              </a>, by Fletcher T. Penney.
            </blockquote>
          </div>
        </div>
      </div>
    )
    /* eslint-enable indent */
  }
}

module.exports = MarkdownHelp
