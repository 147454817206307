import './styles.css'

import React from 'react'
import moment from 'moment'

import Heading from '../heading'
import SingleNode from '../single_node'
import Avatar from '../../../avatar'

import User from '../../../../js/user'
import NodeLink from '../../../../js/node_link'

class NodeInfo extends React.Component {
  state = {}

  // handleClick: function(node) {
  //   this.props.app.setRoot(node.id);
  // },

  toggleDatePicker = ev => {
    const target = ev.target
    const node = this.getNode()
    this.props.app.toggleFloater(target, 'date-picker', {
      mode: 'node-due',
      nodeId: node.id
    })
  }

  handleDeleteLink = (link, ev) => {
    NodeLink.remove(link.id, this.props.app.getCommonParams())
    if (ev && ev.preventDefault) {
      ev.preventDefault()
      ev.stopPropagation()
    }
  }

  getNode = () => {
    if (this.props.activeNode) {
      return this.props.activeNode
    } else {
      return this.props.rootNode
    }
  }

  render () {
    let details = false
    const node = this.getNode()

    if (node) {
      const user = User.get(
        node.created_by || node.user_id,
        this.props.app.state.entities.users
      )
      var inboundList, outboundList
      var inboundLinks = this.props.app.state.entities.node_links.filter(n => {
        return n.dst === node.id
      })
      if (inboundLinks.length) {
        inboundList = (
          <div className='links-list'>
            <div className='sub-heading'>
              <h4>Linked From</h4>
            </div>
            {inboundLinks.map(link => {
              const linkNode = this.props.app.state.entities.nodes.find(n => {
                return n.id === link.src
              })
              return (
                <SingleNode
                  key={'inbound-' + linkNode.id}
                  app={this.props.app}
                  node={linkNode}
                  mode='icon'
                  parent={this}
                  link={link}
                  onDeleteLink={this.handleDeleteLink}
                />
              )
            })}
          </div>
        )
      }

      var outboundLinks = this.props.app.state.entities.node_links.filter(n => {
        return n.src === node.id
      })
      if (outboundLinks.length) {
        outboundList = (
          <div className='links-list'>
            <div className='sub-heading'>
              <h4>Links To</h4>
            </div>
            {outboundLinks.map(link => {
              const linkNode = this.props.app.state.entities.nodes.find(n => {
                return n.id === link.dst
              })
              return (
                <SingleNode
                  key={'inbound-' + linkNode.id}
                  app={this.props.app}
                  node={linkNode}
                  mode='icon'
                  parent={this}
                  link={link}
                  onDeleteLink={this.handleDeleteLink}
                  onClick={this.handleClick}
                />
              )
            })}
          </div>
        )
      }

      const avatarOrIcon = user ? (
        <Avatar app={this.props.app} user={user} size='tiny' />
      ) : (
        <i className='fa fa-fw fa-calendar' />
      )
      const attribution = user ? `by ${user.first_name} ${user.last_name}` : ''
      var heading = <h4>Item Details: {node.title}</h4>
      details = (
        <div id='node-info-sidebar' className='panel'>
          {!this.props.noHeading && (
            <Heading {...this.props}>{heading}</Heading>
          )}
          <div className='details text'>
            <div>
              {avatarOrIcon}{' '}
              <span>
                Created {moment(node.created_at).fromNow()} {attribution}
              </span>
            </div>
            {Boolean(
              node.updated_at && node.updated_at > node.created_at + 120
            ) && (
              <div>
                <i className='fa fa-fw fa-clock-o' />{' '}
                <span>Updated {moment(node.updated_at).fromNow()}</span>
              </div>
            )}
            <div onClick={this.toggleDatePicker} className='due-date'>
              {!node.due_at && <span className='soft-link'>No due date</span>}
              {node.due_at && <i className='fa fa-fw fa-calendar-check-o' />}
              {node.due_at && (
                <span className='soft-link'>
                  Due {moment(node.due_at).fromNow()}
                </span>
              )}
            </div>
          </div>
          {outboundList}
          {inboundList}
        </div>
      )
    }

    return details
  }
}

module.exports = NodeInfo
