import React from 'react'

import Heading from '../sections/heading'
import LookAndFeel from '../sections/look_and_feel'
import LabelsSection from '../sections/labels_section'
// import Misc from '../../../js/misc';

import './styles.css'

export default class Menu extends React.Component {
  render () {
    const { app } = this.props
    return (
      <div className='body full-height menu'>
        <Heading {...this.props} name='Menu' isMain />
        <LookAndFeel app={app} />
        <LabelsSection app={app} />
      </div>
    )
  }
}
