import React from 'react'
import Cookies from 'cookies-js'
import Misc from '../../../../../js/misc'

class SettingsSection extends React.Component {
  constructor (props) {
    super(props)
    var state = {
      error: null
    }
    this.state = state
    this.errorFor = Misc.errorFor.bind(this, this)
  }

  changePassword = ev => {
    var _this = this
    var oldPwd = this.oldPassword.value
    var newPwd = this.newPassword.value
    var confirmation = this.confirmNewPassword.value
    if (newPwd === confirmation) {
      var data = { old: oldPwd, new: newPwd }
      this.setState({ error: null })
      Misc.jx(this, 'change_password', data, function (res) {
        // eslint-disable-line no-unused-vars
        // TODO: Show global success message
        _this.setState({ changePassword: false })
      })
    } else {
      this.setState({ error: 'New password and confirmation do not match.' })
    }

    ev.preventDefault()
  }

  deleteAccount = ev => {
    var _this = this
    this.props.app.getConfirmation(
      function () {
        Misc.jx(_this, 'delete_account', null, function (res) {
          // eslint-disable-line no-unused-vars
          window.sessionStorage.removeItem('authToken')
          window.localStorage.removeItem('authToken')
          Cookies.expire('authToken')
          _this.props.app.disconnectSocket()
          _this.props.app.setAppState({
            overlay: null,
            accountDeleted: true,
            user: null,
            authToken: null
          })
        })
      },
      null,
      'Are you absolutely sure that you want to permanently delete your account and all your notes? This action cannot be undone.'
    )
    ev.preventDefault()
  }

  handleDropboxChange = () => {
    var _this = this
    var el = this.dropboxBackup
    if (el.checked) {
      window._app.dropbox.authenticate(function (error, client) {
        if (error) {
          // Replace with a call to your own error-handling code.
          //
          // Don't forget to return from the callback, so you don't execute the code
          // that assumes everything went well.
          // return showError(error);
          return console.error(error) // eslint-disable-line no-console
        }

        // Replace with a call to your own application code.
        //
        // The user authorized your app, and everything went well.
        // client is a Dropbox.Client instance that you can use to make API calls.
        console.log('SUCCESS with Dropbox :)') // eslint-disable-line no-console
        var newSettings = {
          dropbox_backup_enabled: true,
          dropbox_token: client._credentials.token,
          dropbox_uid: client._credentials.uid
        }
        _this.props.saveUserSettings(
          newSettings,
          _this.props.app.getCommonParams()
        )

        // doSomethingCool(client);
      })
    } else {
      for (var key in localStorage) {
        if (/^dropbox-auth:/.test(key)) {
          localStorage.removeItem(key)
        }
      }

      var newSettings = {
        dropbox_backup_enabled: false,
        dropbox_token: null,
        dropbox_uid: null
      }
      _this.props.saveUserSettings(
        newSettings,
        _this.props.app.getCommonParams()
      )
    }
  }

  saveSettings = () => {
    var newSettings = {
      hide_completed: this.hideCompleted.checked,
      hide_widgets: this.hideWidgets.checked
    }

    this.props.saveUserSettings(newSettings, this.props.app.getCommonParams())
  }

  render () {
    var content = <div>Settings section</div>

    if (this.props) {
      content = (
        <div className='form'>
          <div className='row'>
            <label>
              <input
                type='checkbox'
                ref={dropboxBackup => {
                  this.dropboxBackup = dropboxBackup
                }}
                checked={this.props.user.settings.dropbox_backup_enabled}
                onChange={this.handleDropboxChange}
              />{' '}
              Save daily backup to Dropbox{' '}
            </label>
            <div className='small-description'>
              We <strong>strongly</strong> recommend that you enable this
              option.
            </div>
          </div>
          <div className='row spacer' />
          <div className='row'>
            <label>
              <input
                type='checkbox'
                ref={hideCompleted => {
                  this.hideCompleted = hideCompleted
                }}
                checked={this.props.user.settings.hide_completed}
                onChange={this.saveSettings}
              />{' '}
              Hide completed items by default
            </label>
            <div className='small-description'>
              Note that this can be overridden for any tab.
            </div>
          </div>
          <div className='row spacer' />
          {this.props.app.featureEnabled('due_nodes') && (
            <div className='row'>
              <label>
                <input
                  type='checkbox'
                  ref={hideWidgets => {
                    this.hideWidgets = hideWidgets
                  }}
                  checked={this.props.user.settings.hide_widgets}
                  onChange={this.saveSettings}
                />Hide Due Date and Assigned To in main list
              </label>
              <div className='small-description'>
                Note that this can be overridden for any tab. Due Dates and
                Assigned To are always visible in the TODO List.
              </div>
            </div>
          )}
          <div className='row spacer' />
          <div className='row'>
            {this.errorFor()}
            <form onSubmit={this.changePassword}>
              <div className='row'>
                <label>Old password</label>
                <input
                  ref={oldPassword => {
                    this.oldPassword = oldPassword
                  }}
                  type='password'
                />
              </div>
              <div className='row'>
                <label>New password</label>
                <input
                  ref={newPassword => {
                    this.newPassword = newPassword
                  }}
                  type='password'
                />
              </div>
              <div className='row'>
                <label>Confirm new password</label>
                <input
                  ref={confirmNewPassword => {
                    this.confirmNewPassword = confirmNewPassword
                  }}
                  type='password'
                />
              </div>
              <input className='submit' type='submit' value='Change password' />
            </form>
          </div>
          <div className='row'>
            <label>Delete account</label>
            <div className='small-description'>
              <p>
                You can permanently delete your account if you want. This will
                delete all list items, notes, bookmarks, sites etc. Shared items
                will no longer be available to those you have shared them with.
                Deleting your account cannot be undone.{' '}
              </p>
              <p>
                Note that deleting your account will <em>not</em> delete any
                backups in your Dropbox account.
              </p>
              <p>
                <button className='button delete' onClick={this.deleteAccount}>
                  Delete account
                </button>
              </p>
            </div>
          </div>
        </div>
      )
    }

    return content
  }
}

module.exports = SettingsSection
