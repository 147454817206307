var uuid = require('node-uuid')

var Fave = {

  debug: false,

  add: function (fave, params, callback) {
    const doAction = (actionParams) => {
      const newParams = Object.assign({}, actionParams)
      const faves = actionParams.updatedState.entities ? actionParams.updatedState.entities.faves : actionParams.state.entities.faves

      const newFave = Fave.new(fave, actionParams)
      const newFaves = faves.concat([newFave])

      const undoData = {
        undo: [{obj: 'Fave', cmd: 'remove', args: [fave.id]}],
        redo: [{obj: 'Fave', cmd: 'add', args: [fave]}]
      }

      if (!actionParams._isUndoOperation && !actionParams._skipUndoAdd) {
        actionParams.undo.add(undoData, true)
      }

      const newEntities = Object.assign({}, (actionParams.updatedState.entities || actionParams.state.entities))
      newEntities.faves = newFaves
      const result = {
        entities: newEntities,
        updatedNodes: [newFave.node_id]
      }

      newParams.updatedState = Object.assign({}, actionParams.updatedState, result)
      newParams.undoData = actionParams.undoData || {}
      newParams.undoData.undo = (newParams.undoData.undo || []).concat(undoData.undo)
      newParams.undoData.redo = (newParams.undoData.redo || []).concat(undoData.redo)

      if (actionParams._skipSetState) {
        if (typeof callback === 'function') {
          callback(newParams)
        }
      } else {
        actionParams.setAppState(newParams.updatedState, newParams.stateParams, () => {
          if (typeof callback === 'function') {
            callback(newParams)
          }
        })
      }
    }

    if (params._skipFlushUndo) {
      doAction(params)
    } else {
      params.flushTextUndo(params, ret => {
        doAction(ret)
      })
    }
  },

  remove: function (faveId, params, callback) {
    const doAction = (actionParams) => {
      const newParams = Object.assign({}, actionParams)
      const faves = actionParams.updatedState.entities ? actionParams.updatedState.entities.faves : actionParams.state.entities.faves
      const fave = Fave.get(faveId, faves)
      const newFaves = faves.filter(f => { return f.id !== faveId })

      const undoData = {
        undo: [{obj: 'Fave', cmd: 'add', args: [fave]}],
        redo: [{obj: 'Fave', cmd: 'remove', args: [fave.id]}]
      }

      if (!actionParams._isUndoOperation && !actionParams._skipUndoAdd) {
        actionParams.undo.add(undoData, true)
      }

      const newEntities = Object.assign({}, (actionParams.updatedState.entities || actionParams.state.entities))
      newEntities.faves = newFaves
      const result = {
        entities: newEntities,
        updatedNodes: [fave.node_id]
      }

      newParams.updatedState = Object.assign({}, actionParams.updatedState, result)
      newParams.undoData = actionParams.undoData || {}
      newParams.undoData.undo = (newParams.undoData.undo || []).concat(undoData.undo)
      newParams.undoData.redo = (newParams.undoData.redo || []).concat(undoData.redo)

      if (actionParams._skipSetState) {
        if (typeof callback === 'function') {
          callback(newParams)
        }
      } else {
        actionParams.setAppState(newParams.updatedState, newParams.stateParams, () => {
          if (typeof callback === 'function') {
            callback(newParams)
          }
        })
      }
    }

    if (params._skipFlushUndo) {
      doAction(params)
    } else {
      params.flushTextUndo(params, ret => {
        doAction(ret)
      })
    }
  },

  get: function (entityOrId, entities) {
    if (typeof entityOrId === 'string') {
      return entities.find(f => { return f.id === entityOrId })
    } else {
      return entityOrId
    }
  },

  new: function (data) {
    var time = new Date().toISOString()
    if (typeof data === 'undefined') data = {}
    data.id = uuid.v4()
    data.created_at = time
    data.updated_at = time
    return data
  }

}

module.exports = Fave
