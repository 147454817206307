import './styles.css'

import React from 'react'
import moment from 'moment'

import SetPlaceholder from '../../set_placeholder'

export default class EventEnd extends React.Component {
  render () {
    const { app, node } = this.props
    const data = node.data || {}
    return (
      <div
        onClick={ev =>
          app.toggleFloater(ev.target, 'date-picker', {
            mode: 'node-data-date-field',
            field: 'event_end',
            nodeId: node.id
          })
        }
      >
        {data.event_end && (
          <div className='actual-data'>
            <div className='data-date'>
              {moment(data.event_end).format('YYYY-MM-DD')}
            </div>
          </div>
        )}
        {!data.event_end && <SetPlaceholder />}
      </div>
    )
  }
}
