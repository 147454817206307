import React from 'react'
import createReactClass from 'create-react-class'
import _ from 'lodash'

import Node from '../../../js/node'
import Misc from '../../../js/misc'

const ListSettingsOverlay = createReactClass({
  displayName: 'ListSettingsOverlay',

  getInitialState: function () {
    this.node = this.props.panel.opts.data.node
    this.nodes = this.props.app.state.entities.nodes
    this.site = Node.site(this.node, this.nodes)
    var state = this.node.settings
    state.error = null
    return state
  },

  UNSAFE_componentWillMount: function () {
    this.handleFormFieldChange = Misc.handleFormFieldChange.bind(this, this)
    this.errorFor = Misc.errorFor.bind(this, this)
  },

  save: function () {
    Node.modify(
      this.props.panel.opts.data.node.id,
      { settings: _.omit(this.state, ['error']) },
      this.props.app.getCommonParams(),
      () => {
        this.setState({ error: null })
        this.props.app.setPanel(null)
      }
    )
  },

  render: function () {
    return (
      <div id='node-settings'>
        {this.errorFor()}
        <div className='row'>
          <div className='row-content'>
            <div className='input-label-wrapper'>
              <input
                type='checkbox'
                data-name='emphasized'
                checked={this.state.emphasized}
                value={this.state.emphasized}
                onChange={this.handleFormFieldChange}
                id='emphasized-in-ui'
              />
              <label htmlFor='emphasized-in-ui'>Emphasize in the UI</label>
            </div>
          </div>
        </div>
        <div className='buttons'>
          <button onClick={this.save} className='button default'>
            Save
          </button>
        </div>
      </div>
    )
  }
})

module.exports = ListSettingsOverlay
