import React from 'react'
import { Socket } from 'phoenix'
import $ from 'jquery'
import _ from 'lodash'
import S from 'string'
import strftime from 'strftime'
import superagent from 'superagent'
import platform from 'platform'
import hljs from 'highlight.js'
import Markdownit from 'markdown-it'
import markdownitClassy from 'markdown-it-classy'

import constants from '..//lib/constants'

var objs = {
  Node: require('./node'),
  Comment: require('./comment'),
  Like: require('./like'),
  User: require('./user'),
  Tab: require('./tab'),
  Org: require('./org'),
  OrgUser: require('./org_user'),
  Notification: require('./notification')
}

var Misc = {
  shortId: function (opts) {
    opts = opts || {}
    var ALPHABET = opts.alphabet || '23456789abdegjkmnpqrvwxyz'
    var ID_LENGTH = opts.length || 12

    var rtn = ''
    for (var i = 0; i < ID_LENGTH; i++) {
      rtn += ALPHABET.charAt(Math.floor(Math.random() * ALPHABET.length))
    }
    return rtn
  },

  jx: function (ctx, optsOrCmd, data, successCallback, errorCallback) {
    var opts
    if (typeof optsOrCmd === 'object') {
      opts = optsOrCmd
    } else {
      opts = {
        cmd: optsOrCmd,
        data: data,
        success: successCallback,
        error: errorCallback
      }
    }

    opts.context = opts.context || ''

    opts.success =
      opts.success ||
      function (res) {
        // eslint-disable-line no-unused-vars
        // do nothing
      }
    opts.error =
      opts.error ||
      function (res) {
        // eslint-disable-line no-unused-vars
        var error = res.body && res.body.error ? res.body.error : res.text
        ctx.setState({ error: error })
        console.log(
          'An error occurred while communicating with the server: ' + res.text
        ) // eslint-disable-line no-console
      }
    var token = ctx.props.authToken
    if (!token) {
      if (ctx.props.app && ctx.props.app.state.authToken) {
        token = ctx.props.app.state.authToken
      } else if (ctx.state.authToken) {
        token = ctx.state.authToken
      }
    }

    var defaultHeaders = token ? { Authorization: 'Bearer ' + token } : {}
    var matches = window.location.pathname.match(/\/shared\/([^/]+)/)
    if (matches && matches[1]) {
      defaultHeaders['X-Notebase-Secret-ID'] = matches[1]
    }

    opts.headers = opts.headers || defaultHeaders

    superagent
      .post(NOTEBASE_URL_PREFIX + '/_' + opts.context + '/' + opts.cmd)
      .send(opts.data)
      .set(opts.headers)
      .end(function (err, res) {
        if (err || !res.ok || res.body.error) {
          opts.error(res)
        } else {
          opts.success(res)
        }
      })
  },

  jxa: function (ctx, cmd, data, successCallback, errorCallback) {
    var opts = {
      context: 'admin',
      cmd: cmd,
      data: data,
      success: successCallback,
      error: errorCallback
    }
    Misc.jx(ctx, opts)
  },

  addOnce: function (collection, data) {
    collection = collection || []
    var newCollection = collection.slice(0)
    data = _.flatten([data])
    data.forEach(function (record) {
      var existing = collection.find(function (r) {
        return r.id === record.id
      })
      if (!existing) {
        newCollection = newCollection.concat([record])
      }
    })
    return newCollection
  },

  timeFor: function (time, format) {
    if (time) {
      format = format || '%B %d, %Y %H:%M:%S'
      var t = strftime(format, new Date(time))
      return t
    }
  },

  nodeEl: function (idOrElOrNode) {
    var type = 'id'
    if (
      idOrElOrNode.hasOwnProperty('nodeType') ||
      idOrElOrNode instanceof Element ||
      idOrElOrNode.hasOwnProperty('_reactInternalComponent')
    ) {
      type = 'el'
    } else if (idOrElOrNode instanceof NodeList) {
      idOrElOrNode = idOrElOrNode[0]
      type = 'el'
    } else if (idOrElOrNode.hasOwnProperty('selector')) {
      type = 'jquery'
    } else if (typeof idOrElOrNode === 'object') {
      type = 'node'
    }
    var id =
      type === 'id'
        ? idOrElOrNode
        : type === 'el' || type === 'jquery'
          ? null
          : idOrElOrNode.id
    var el = id
      ? $('.node[data-node-id="' + id + '"]')
      : type === 'el'
        ? $(idOrElOrNode)
        : idOrElOrNode
    var li
    if (el && el.length) {
      li = el.closest('.node')
    }
    return li && li.length ? li.get(0) : null
  },

  nextNodeEl: function (idOrEl, noChildren) {
    var el = $(Misc.nodeEl(idOrEl))
    var li = el.closest('.node:visible')
    if (noChildren) {
      var ret = []
      var next
      var origLevel = parseInt(li.attr('data-level'))
      next = li
      while (next.length) {
        next = next.next('.node')
        if (next.length) {
          if (parseInt(next.attr('data-level')) <= origLevel) {
            ret = next
            break
          }
        }
      }
      return ret
    } else {
      return li.next('.node')
    }
  },

  xnextNodeEl: function (idOrEl, noChildren) {
    var next
    var el = $(Misc.nodeEl(idOrEl))
    var lis = $('ul.nodes li.node:visible')
    var li = el.closest('li.node:visible')
    if (noChildren) {
      next = li.nextAll('li.node:visible:first')
      if (next.length) {
        return next
      } else {
        var parent = li.parent().closest('li.node:visible')
        while (parent.length) {
          next = parent.nextAll('li.node:visible:first')
          if (next.length) {
            break
          } else {
            parent = parent.parent().closest('li.node:visible')
          }
        }
        if (next.length) {
          return next
        } else {
          // Hack to support if (nextEl.length)
          return []
        }
      }
    } else {
      var idx = lis.index(li)
      next = lis[idx + 1]
      if (next) {
        return $(next)
      } else {
        // Hack to support if (nextEl.length)
        return []
      }
    }
  },

  oldNextNodeEl: function (idOrEl) {
    var el = $(Misc.nodeEl(idOrEl))
    var next = el.find('ul:visible:first li.node:visible:first')
    if (!next.length) next = el.nextAll('.node:visible:first')
    if (!next.length) {
      var lists = $('ul.nodes')
      var list = el.closest('ul.nodes')
      var idx = lists.index(list)
      var i = 1
      var nextList = $(lists[idx + 1])
      while (!nextList.length && i < lists.length) {
        nextList = $(lists[idx + i])
        i++
      }

      next = nextList.find('li.node:visible:first')
    }

    if (!next.length) {
      var prev = el.parent().closest('li.node')
      while (prev.length && !prev.next().length) {
        prev = prev.parent().closest('li.node')
      }

      next = prev.nextAll(':visible:first')
    }

    return next
  },

  prevNodeEl: function (idOrEl) {
    var li = $(Misc.nodeEl(idOrEl))
    return li.prev('.node')
  },

  xprevNodeEl: function (idOrEl) {
    var li = $(Misc.nodeEl(idOrEl))
    var inbox = $('#app-wrapper.inbox-open')
    var selector = inbox.length ? 'ul.nodes' : '#nodes'
    selector += ' li.node:visible'
    var lis = $(selector)
    var idx = lis.index(li)
    var prev = lis[idx - 1]
    if (prev) {
      return $(prev)
    } else {
      // Hack to support if (prevEl.length)
      return []
    }
  },

  parentLi: function (el) {
    return el.prevAll(
      'li.node[data-level="' +
        (parseInt(el.attr('data-level')) - 1) +
        '"]:first'
    )
  },

  childLis: function (el) {
    return el.nextAll(
      'li.node[data-path^="' + el.attr('data-path') + '"]:first'
    )
  },

  prevSibling (idOrEl) {
    var li = $(Misc.nodeEl(idOrEl))
    var prev = li.prev('li.node')
    if (prev.attr('data-level') === li.attr('data-level')) {
      return prev
    } else {
      return []
    }
  },

  nextSibling (idOrEl) {
    var li = $(Misc.nodeEl(idOrEl))
    var next = li.next('li.node')
    if (next.attr('data-level') === li.attr('data-level')) {
      return next
    } else {
      return []
    }
  },

  indentNodeTarget: function (el) {
    var prev = el.prev('li.node')
    var level = parseInt(el.attr('data-level'))
    if (!prev.length || parseInt(prev.attr('data-level')) < level) {
      return []
    }
    return el
      .prevAll('li.node[data-level="' + level + '"]:first')
      .not('.remote')
    // var ret, prev;
    // var origLevel = parseInt(el.attr('data-level'));
    // prev = el;
    // while (true) {
    //   prev = prev.prev('li.node');
    //   if (prev.length) {
    //     if (parseInt(prev.attr('data-level')) === origLevel + 1) {
    //       ret = prev;
    //       break;
    //     }
    //   } else {
    //     break;
    //   }
    // }
    // return ret;
  },

  outdentNodeTarget: function (el) {
    return el
      .prevAll(
        'li.node[data-level="' +
          (parseInt(el.attr('data-level')) - 1) +
          '"]:first'
      )
      .not('.remote')
  },

  oldPrevNodeEl: function (idOrEl) {
    var el = $(Misc.nodeEl(idOrEl))
    var prev
    var prevLi = el.prevAll('.node:visible:first')
    if (prevLi.length) {
      prev = prevLi.find('ul:visible li.node:visible:last')
      if (!prev.length) {
        prev = prevLi
      }
    } else {
      prev = el.parent().closest('li.node')
    }

    if (!prev.length) {
      var lists = $('#app-wrapper.inbox-open').length
        ? $('ul.nodes')
        : $('ul.nodes').not('.inbox')
      var list = el.closest('ul.nodes')
      var idx = lists.index(list)
      var i = lists.length
      var prevList = $(lists[idx - 1])
      while (!prevList.length && i <= 0) {
        prevList = $(lists[idx - i])
        i--
      }

      prev = prevList.find('> li.node:visible:last')
      if (prev.length) {
        var children = prev.find('ul:visible:last li.node:visible:last')
        if (children.length) {
          prev = children
        }
      }
    }

    return prev
  },

  xecuteCommands: function (json, params) {
    var newParams
    if (json && json.length) {
      json = _.compact(json)
      if (typeof json === 'string') json = JSON.parse(json)
      var updateNodes = false
      var updateUser = false
      newParams = _.omit(params, 'replaceNodes')
      json.forEach(el => {
        var allArgs = el.args.concat(newParams)

        // console.log('EXECUTING ' + el.obj + '.' + [el.cmd], allArgs);
        var ret = window[el.obj][el.cmd].apply(window[el.obj], allArgs)

        // TODO: Move focus away from Node, to avoid this silly exception
        if (el.obj === 'Node' && el.cmd !== 'focus') {
          updateNodes = true
          newParams.nodes = ret instanceof Array ? ret : ret.nodes
          if (el.cmd === 'duplicate') {
            updateUser = true
            newParams.user = ret.user
          }
        }
      })
      if (updateNodes) {
        params.replaceNodes(newParams.nodes)
      }

      if (updateUser) {
        params.setAppState({ user: newParams.user })
      }
    }
  },

  executeCommands: function (json, params, callback, i) {
    // console.log('params in executeCommands', params);
    // console.log('Executing json:', JSON.stringify(json));
    params = _.extend(params, { _skipSetState: true, _skipUndoAdd: true })
    i = i || 0
    if (i === 0) {
      if (typeof json === 'string') json = JSON.parse(json)
      json = _.compact(json)
    }

    if (!json || i === json.length) {
      params.app.setAppState(
        params.updatedState,
        { noop: !_.keys(params.updatedState).length },
        () => {
          if (typeof callback === 'function') {
            // console.log('calling home...');
            callback(params)
          }
        }
      )
    } else {
      var el = json[i]
      var allArgs = el.args.concat([
        params,
        function (ret) {
          // recurse here
          Misc.executeCommands(json, ret, callback, i + 1)
        }
      ])

      // console.log('EXECUTING ' + el.obj + '.' + [el.cmd], allArgs);
      objs[el.obj][el.cmd].apply(window[el.obj], allArgs)

      // console.log('recursing [' + i + ']:', json);
    }
  },

  platform: function () {
    switch (platform.os.family) {
      case 'Mac OS X':
      case 'OS X':
      case 'Darwin':
        return 'mac'
      case 'Windows':
        return 'windows'
      case 'Linux':
        return 'linux'
      default:
        return 'unknown'
    }
  },

  dropAccept: function (droppable, draggable) {
    var dropEl = $(droppable).closest('.node')
    var dragEl = draggable.closest('.node')
    if (
      dropEl.closest('.node[data-node-id=' + dragEl.data('node-id') + ']')
        .length ||
      dropEl.closest('.node.remote').length
    ) {
      // console.log('"' + dropEl.data('node-id') + '" CANNOT accept "' + dragEl.data('node-id') + '" :-(');
      return false
    } else {
      // console.log('"' + dropEl.data('node-id') + '" CAN accept "' + dragEl.data('node-id') + '" :-)');
      return true
    }
  },

  setActiveNode: function (node) {
    setTimeout(function () {
      var li = node ? Node.element(node.id) : $('li.node:visible:first')
      var body = $('#note-body')
      if (node || (!body.length || body.html().length < 200)) {
        var div = li.find(
          '> .indented > .indent-wrapper > .node-content > div.title[contenteditable]'
        )
        if (!div.is(':focus')) {
          div.focus()
        }
      }
    }, 1)
  },

  dueStr: function (date) {
    var now = new Date()
    var isCurrentYear = now.getFullYear() === date.getFullYear()
    var fmt = isCurrentYear ? '%b %e' : '%b %e, %Y'
    var dueStr = strftime(fmt, date)
    return dueStr
  },

  slugify: function (text) {
    return S(text).slugify().s
  },

  truncate: function (text, len, notSmart) {
    if (notSmart) {
      return text.length > len ? text.substring(0, len) + '\u2026' : text
    } else {
      return S(text).truncate(len).s
    }
  },

  fileThumbUrl: function (file) {
    // var url = 'https://s3-eu-west-1.amazonaws.com/' + file.container + '/';
    // var key = file.thumb_key ? file.thumb_key : file.key;
    // key = key.replace(/ /g, '%20');
    // url += key;
    return file.url + '/convert?w=200&h=200'

    // return url;
  },

  fileUrl: function (file) {
    var key = file.key.replace(/ /g, '%20')
    let host
    if (file.container === 'notebase-uploads') {
      host = 's3-eu-west-1.amazonaws.com'
    } else if (file.container === 'notebase-user-uploads') {
      host = 's3.us-east-2.amazonaws.com'
    }
    return 'https://' + host + '/' + file.container + '/' + key
  },

  fileType: function (fileOrMimetype) {
    var mimetype =
      typeof fileOrMimetype === 'object'
        ? fileOrMimetype.mimetype
        : fileOrMimetype
    if (/^image\//.test(mimetype)) {
      return 'image'
    } else if (/^text\//.test(mimetype)) {
      switch (mimetype) {
        case 'text/javascript':
        case 'text/html':
        case 'text/css':
          return 'code'
        default:
          return 'text'
      }
    } else if (/^application\/(x-)?pdf$/.test(mimetype)) {
      return 'pdf'
    } else {
      return 'unknown'
    }
  },

  isImage: function (fileOrMimetype) {
    return Misc.fileType(fileOrMimetype) === 'image'
  },

  fileSize: function (bytes) {
    var _1K = Math.pow(1024, 2)
    var _1M = Math.pow(1024, 3)
    var _1G = Math.pow(1024, 4)
    var _1T = Math.pow(1024, 5)
    if (bytes < 1024) {
      return bytes + ' bytes'
    } else if (bytes < _1K) {
      return parseInt(bytes / 1024) + ' KB'
    } else if (bytes < _1M) {
      return parseInt(bytes / _1K) + ' MB'
    } else if (bytes < _1G) {
      return parseInt(bytes / _1M) + ' GB'
    } else if (bytes < _1T) {
      return parseInt(bytes / _1G) + ' TB'
    }
  },

  parseMarkdownTabs: function (mdIn) {
    // console.log(mdIn);
    try {
      var tabs = { 1: [] }
      var i = 1
      var firstPassReplacer = function (
        match,
        type,
        end,
        label,
        offset,
        string
      ) {
        // eslint-disable-line no-unused-vars
        var restStr = ''
        if (type === 'endtabs') {
          i++
          tabs[i] = []
          restStr = ':' + i + '='
        } else {
          restStr = i + '=' + label
          tabs[i].push(label)
        }

        // console.log(i + ':' + match + ' (' + type + '/' + label + ')');

        var ret = '\n\n~' + type + restStr + '~\n\n'
        return ret
      }
      var md = mdIn.replace(
        /<p>~((end)?tab[s:])([^~]*)~<\/p>/gm,
        firstPassReplacer
      )

      // console.log(md);
      // console.log(tabs);
      var j = 0
      var secondPassReplacer = function (
        match,
        type,
        end,
        label,
        offset,
        string
      ) {
        // eslint-disable-line no-unused-vars
        // console.log('match', match);
        // console.log('type', type);
        // console.log('end', end);
        // console.log('label', label);
        // console.log('offset', offset);
        var index
        var ret = ''
        var active

        // console.log(type);
        if (type === 'endtabs:') {
          ret = '</div></div>'
          j = 0
        } else {
          ret = ''

          // console.log('label', label);
          index = parseInt(label.split('=')[0])
          if (j === 0) {
            ret +=
              '<div class="md-tab-container"><div class="md-tab-links"><ul>'
            var n = 0
            tabs[index].forEach(t => {
              var active = n === 0 ? 'active' : ''
              ret +=
                '<li class="' +
                active +
                '"><a href="#' +
                index +
                '-' +
                encodeURIComponent(t) +
                '" class="md-tab-link">' +
                t +
                '</a></li>'
              n++
            })
            ret += '</ul></div>'
          }

          active = j === 0 ? 'active' : ''
          ret += j === 0 ? '' : '</div>'

          // console.log('index', index);
          // console.log('j', j);
          ret +=
            '<div class="md-tab-data ' +
            active +
            '"><a name="' +
            index +
            '-' +
            encodeURIComponent(tabs[index][j]) +
            '"></a>'
          j++
        }

        // console.log(index + ':' + match + ' (' + type + '/' + label + ')');

        return ret
      }

      // return md.replace(/\n~((end)?tabs?\:?)([^~]*)~\n/gm, secondPassReplacer);
      return md.replace(/\n~((end)?tabs?:)(\d+=[^~]*)~\n/gm, secondPassReplacer)
    } catch (e) {
      // console.log(mdIn);
      // console.error(e);
      return mdIn
    }
  },

  parseMarkdownLinks: function (markdown, node, nodes) {
    var site
    if (node && nodes) {
      site = Node.site(node, nodes)
    }

    var wikiReplacer = function (match, linkText, offset, string) {
      // eslint-disable-line no-unused-vars
      var text
      var parts = linkText.split('|')
      var page = S(parts[0]).slugify().s
      if (parts.length === 2) {
        text = parts[1]
      } else {
        text = S(parts[0].replace(/-/, ' ')).capitalize().s
      }

      var missingClass = ''
      if (node && nodes) {
        // var slugNode = Node.getFromSlug(slug, nodes, site);
        var slugNode = Node.getFromSlug(page, nodes, site)
        missingClass = slugNode ? '' : ' missing'
      }

      return (
        '<a href="' +
        page +
        '" class="md-internal-link' +
        missingClass +
        '">' +
        text +
        '</a>'
      )
    }
    return markdown.replace(/\[\[(.+?)\]\]/g, wikiReplacer)
  },

  parseInternalMarkdownLinks: function (markdown) {
    var nbReplacer = function (match, before, id, after, offset, string) {
      // eslint-disable-line no-unused-vars
      return before + '](/n/' + id + after
    }
    return markdown.replace(/([^\]])\]\([Nn][Bb]:([\w-_]+)(\)| )/g, nbReplacer)
  },

  parseMarkdown: function (markdown, options) {
    markdown = markdown || ''
    options = options || {}
    var method = options.inline ? 'renderInline' : 'render'
    var re = new RegExp('h(ttps?:)' + constants.EMBED_REGEX, 'gi')
    markdown = markdown.replace(re, constants.EMBED_REGEX_REPLACEMENT)
    markdown = markdown.replace(/(\n~(end)?tab[s:][^~]*~\n)/gm, '\n\n$1\n\n')
    markdown = markdown.replace(
      /(~)((end)?tab[s:][^~]*)(~)/g,
      '%%%-nb-%%%$2%%%-nb-%%%'
    )
    markdown = markdown.replace(/\n~more~\n/gm, '')

    // marked.setOptions({
    //   sanitize: true,
    //   highlight: function (code) {
    //     return '<pre>' + code + '</pre>';
    //   }
    // });
    // return this.parseMarkdownMedia(this.parseMarkdownLinks(this.parseMarkdownTabs(marked(markdown)), options.node, options.nodes));
    var md = new Markdownit({
      html: false,

      // Enable HTML tags in source
      xhtmlOut: false,

      // Use '/' to close single tags (<br />)
      breaks: false,

      // Convert '\n' in paragraphs into <br>
      langPrefix: 'language-',

      // CSS language prefix for fenced blocks
      linkify: true,

      // autoconvert URL-like texts to links
      typographer: true,

      // Enable smartypants and other sweet transforms

      // Highlighter function. Should return escaped HTML,
      // or '' if input not changed
      highlight: function (str, lang) {
        if (lang && hljs.getLanguage(lang)) {
          try {
            return hljs.highlight(lang, str).value
          } catch (__) {
            // do nothing
          }
        }

        // try {
        //   return hljs.highlightAuto(str).value;
        // } catch (__) {}

        return ''

        // use external default escaping
      }
    })
    md.use(markdownitClassy)
    // console.log(markdown);
    markdown = Misc.parseInternalMarkdownLinks(markdown)
    var rendered = md[method](markdown)
    rendered = rendered.replace(/%%%-nb-%%%/g, '~')
    return Misc.parseMarkdownMedia(
      Misc.parseMarkdownLinks(
        Misc.parseMarkdownTabs(rendered),
        options.node,
        options.nodes
      )
    )
  },

  parseMarkdownMedia: function (text, urlOnly) {
    var replacer = function (match, url, offset, string) {
      // eslint-disable-line no-unused-vars
      try {
        var id, str
        var firstLetter = urlOnly ? 'h' : 'x'
        var re = new RegExp(firstLetter + 'ttps?:' + constants.EMBED_REGEX, 'i')

        // console.log(re);
        var parts = url.match(re)
        if (parts) {
          // console.log(parts);
          var provider = parts[2]
          var path = parts[4]
          if (/youtube/i.test(provider)) {
            id = path.match(/[?&]v=([^?&]+)/)
            if (id && id[1]) {
              str =
                '<iframe width="800" height="450" src="//www.youtube.com/embed/' +
                id[1] +
                '" frameborder="0" allowfullscreen></iframe>'
            }
          } else if (/vimeo/i.test(provider)) {
            id = path.match(/^(\d+)/)
            if (id && id[1]) {
              str =
                '<iframe src="//player.vimeo.com/video/' +
                id[1] +
                '" width="800" height="450" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>'
            }
          } else if (/soundcloud/i.test(provider)) {
            id = path.match(/^([^/]+\/[^/]+)/)
            if (id && id[1]) {
              str =
                '<iframe width="100%" height="450" scrolling="no" frameborder="no" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/65192558&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false&amp;visual=true"></iframe>'
            }
          } else if (/jsfiddle/i.test(provider)) {
            id = path.match(/^([^/]+\/[^/]+)/)
            if (id && id[1]) {
              str =
                '<iframe width="100%" height="300" src="http://jsfiddle.net/' +
                id[1] +
                '/embedded/" allowfullscreen="allowfullscreen" frameborder="0"></iframe>'
            }
          } else if (/gist\.github/i.test(provider)) {
            str =
              '<script src="' + url.replace(/^xttp/i, 'http') + '.js"></script>'
          }
        }

        if (str) {
          return str
        } else {
          if (urlOnly) {
            return url
          } else {
            return '<p>' + url.replace(/^xttp/, 'http') + '</p>'
          }
        }
      } catch (e) {
        console.log(text) // eslint-disable-line no-console
        console.error(e) // eslint-disable-line no-console
        return text
      }
    }
    if (urlOnly) {
      return replacer(null, text)
    } else {
      // console.log(text);
      // return text.replace(/<p>(xttps?:\/\/.*)<\/p>/gi, replacer);
      return text.replace(/<p>(xttp[^<]*)<\/p>/gi, replacer)
      // return text.replace(/<p><a [^>]*>(xttp[^<]*)<\/a><\/p>/gi, replacer);
    }
  },

  fixGists: function () {
    // console.log('FIXING gists...');

    // find all gist scripts inside the ajax container
    var $gists = $('#body-container').find(
      'script[src^="https://gist.github.com/"]'
    )

    // if gist embeds are found
    if ($gists.length) {
      // update each gist
      $gists.each(function () {
        // we need to store $this for the callback
        var $this = $(this)

        // load gist as json instead with a jsonp request
        // $.getJSON($this.attr('src').replace(/#.*/, '') + '.json?callback=?', function(data) {
        $.getJSON(
          $this.attr('src').replace(/.js$/, '.json') + '?callback=?',
          function (data) {
            // replace script with gist html
            $this.replaceWith($(data.div))

            // load the stylesheet, but only once…
            // _this.addStylesheetOnce('https://gist.github.com/' + data.stylesheet);
            Misc.addStylesheetOnce(data.stylesheet)
          }
        )
      })
    }
  },

  // orgTabId: function(orgOrId, user) {
  //   if (orgOrId) {
  //     var orgId = typeof orgOrId === 'string' ? orgOrId : orgOrId.id;
  //     if (user && user.settings.orgTabs) {
  //       return user.settings.orgTabs[orgId];
  //     }
  //   }
  // },

  addStylesheetOnce: function (url) {
    var $head = $('head')
    if ($head.find('link[rel="stylesheet"][href="' + url + '"]').length < 1) {
      $head.append(
        '<link rel="stylesheet" href="' + url + '" type="text/css" />'
      )
    }
  },

  removeStylesheet: function (url) {
    $('head')
      .find('link[rel="stylesheet"][href="' + url + '"]')
      .remove()
  },

  getQueryParam: function (name) {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]') // eslint-disable-line quotes
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)') // eslint-disable-line quotes
    var results = regex.exec(window.location.search)
    return results === null
      ? ''
      : decodeURIComponent(results[1].replace(/\+/g, ' '))
  },

  hideCompleted: function (user, tab) {
    if (tab && tab.settings && tab.settings.hasOwnProperty('hide_completed')) {
      return tab.settings.hide_completed
    } else {
      if (user) {
        return user && user.settings.hide_completed
      } else {
        return false
      }
    }
  },

  hideWidgets: function (user, tab) {
    if (tab && tab.settings && tab.settings.hasOwnProperty('hide_widgets')) {
      return tab.settings.hide_widgets
    } else {
      if (user) {
        return user && user.settings.hide_widgets
      } else {
        return false
      }
    }
  },

  // transform: function(a, b) {
  //   // set the stage so ramjet copies the right styles...
  //   a.classList.remove('hidden');

  //   ramjet.transform(b, a, {
  //     done: function() {
  //       // this function is called as soon as the transition completes
  //       a.classList.remove('hidden');
  //     }
  //   });

  //   // ...then hide the original elements for the duration of the transition
  //   a.classList.add('hidden');
  //   b.classList.add('hidden');
  // },

  scrollIfNeeded: function (src, dst, direction) {
    var threshold = 50
    var headerHeight = $('#header').height()
    var viewportHeight = $(window).height() - headerHeight
    var moveBy = viewportHeight / 2
    var scroll = $('body').scrollTop()
    var dstPos = dst.offset().top
    var relPos = dstPos - scroll - headerHeight
    var needsScrolling =
      (direction === 'up' && relPos < threshold) ||
      (direction === 'down' && relPos > viewportHeight - threshold)
    // console.log('relPos:', relPos);
    // console.log('> ', viewportHeight - threshold);
    if (needsScrolling) {
      var newScroll = direction === 'up' ? scroll - moveBy : scroll + moveBy
      $('body').scrollTop(newScroll)
    }
  },

  // animateSvgOverlay: function(topOrSelector, leftOrOpts, width, height) {
  //   var top, left, opts, $el;
  //   if (topOrSelector) {
  //     if (typeof topOrSelector === 'string') {
  //       opts = leftOrOpts || {};
  //       $el = $(topOrSelector);
  //       top = $el.offset().top;
  //       left = $el.offset().left;
  //       width = $el.width() + (opts.hasOwnProperty('extraWidth') ? opts.extraWidth : 0);
  //       height = $el.height() + (opts.hasOwnProperty('extraHeight') ? opts.extraHeight : 0);
  //     } else {
  //       top = topOrSelector;
  //       left = leftOrOpts;
  //     }
  //   } else {
  //     var bWidth = $(window).width();
  //     var bHeight = $(window).height();
  //     top = Math.round(bHeight/2);
  //     left = Math.round(bWidth/2);
  //     width = 1;
  //     height = 1;
  //   }
  //   // M100 100v100h100v-100z
  //   var newPath = 'M' + left + ' ' + top + 'v' + height + 'h' + width + 'v-' + height + 'z';
  //   if (false && window._app.svgWasAdded) {
  //     var ani = document.createElementNS('http://www.w3.org/2000/svg', 'animate');
  //     ani.setAttribute('attributeName', 'd');
  //     ani.setAttribute('attributeType', 'XML');
  //     ani.setAttribute('from', window._app.svgPathPrefix + window._app.oldSvgPath);
  //     ani.setAttribute('to', window._app.svgPathPrefix + newPath);
  //     var diff = ((new Date().getTime()) - window._app.svgWasAdded.getTime());
  //     ani.setAttribute('begin', diff + 'ms');
  //     ani.setAttribute('dur', '200ms');
  //     ani.setAttribute('fill', 'freeze');
  //     window._app.svgpath.appendChild(ani);
  //   } else {
  //     window._app.svgpath.setAttribute('d', window._app.svgPathPrefix + newPath);
  //   }
  //   window._app.oldSvgPath = newPath;
  // },

  highlightFilter: function (filter, str) {
    var ret = str
    if (
      str &&
      str.length &&
      filter &&
      filter.length &&
      filter !== '.' &&
      filter !== '/' &&
      filter !== '..'
    ) {
      // Spaces? Where we're going, we don't need spaces
      var trimmedFilter = filter.replace(/^[onlbdsp] /, '').replace(/\s/g, '')
      var i = trimmedFilter.length
      var word, diff, j, m, re, existing
      var matches = []
      var finder = function (m, word, e) {
        return e[0] <= m.index && e[1] >= m.index + word.length
      }
      // Go through all permutations of the filter, reducing the length by 1 for each iteration
      while (i > 0) {
        diff = trimmedFilter.length - i
        j = 0
        // Extract every part of the filter that match the current length, by moving one char forward for each iteration
        while (j <= diff) {
          word = trimmedFilter.substr(j, trimmedFilter.length - diff)
          re = new RegExp(word, 'i')
          m = str.match(re)
          if (m) {
            // existing = _.xfind(matches, function(e) { return e[0] <= m.index && e[1] >= m.index + word.length; });
            // existing = _.xfind(matches, finder.bind(Misc, m, word));
            existing = matches.find(finder.bind(Misc, m, word))
            if (!existing) {
              matches.push([m.index, m.index + word.length])
              // Removee the current match from the filter (by replacing them with _), to avoid matching the same set of chars again
              trimmedFilter =
                trimmedFilter.substr(0, j) +
                Array(word.length + 1).join('_') +
                trimmedFilter.substr(j + word.length)
            }
          }
          // Bail from the inner loop if we have just dummy chars
          if (/^_+$/.test(trimmedFilter)) {
            break
          }
          j++
        }
        // Bail from the outer loop if we have just dummy chars
        if (/^_+$/.test(trimmedFilter)) {
          break
        }
        i--
      }
      if (matches.length) {
        // Construct the highlighted str by piecing together the matched parts with the non-matched parts
        var sortedMatches = _.sortBy(matches, function (match) {
          return match[0]
        })
        var start = 0
        var rest
        ret = ''
        sortedMatches.forEach(function (match) {
          ret += str.substr(start, match[0] - start)
          ret +=
            '<span class="hl">' +
            str.substr(match[0], match[1] - match[0]) +
            '</span>'
          rest = str.substr(match[1])
          start = match[1]
        })
        ret += rest
      } else {
        ret = str
      }
    }
    return ret
  },

  handleFormFieldChange: function (component, ev, data, callback) {
    var el = $(ev.target)
    var prop = el.data('name')
    var objKey = el.data('obj')
    var value = data
      ? data.value || data
      : el.is('[type="checkbox"]')
        ? ev.target.checked
        : el.val()
    var newState = {}
    if (objKey) {
      var obj = component.state[objKey]
      obj[prop] = value
      newState[objKey] = obj
    } else {
      newState[prop] = value
    }
    // console.log('xxx', newState);
    component.setState(newState, function () {
      if (typeof callback === 'function') {
        callback()
      }
    })
  },

  errorFor: function (component, fieldName) {
    var errorForField
    if (
      component.props.app.state.errorFields &&
      component.props.app.state.errorFields.length
    ) {
      if (fieldName) {
        errorForField = component.props.app.state.errorFields.find(f => {
          return f.name === fieldName
        })
        if (errorForField) {
          return <div className='error'>{errorForField.message}</div>
        }
      } else {
        errorForField = component.props.app.state.errorFields.find(f => {
          return !f.name
        })
        if (errorForField) {
          console.log(errorForField) // eslint-disable-line no-console
          var msg = errorForField.message || component.state.error
          return <div className='error'>{msg}</div>
        }
      }
    }
  },

  setBackgroundImage: function (app, scope, image, color) {
    if (scope === 'node') {
      const node = app.rootNode()
      const newSettings = {
        ...(node.settings || {}),
        bgImage: image,
        bgImageColor: color
      }
      objs.Node.modify(node, { settings: newSettings }, app.getCommonParams())
    } else if (scope === 'tab') {
      const tab = app.tab()
      const newSettings = {
        ...(tab.settings || {}),
        bgImage: image,
        bgImageColor: color
      }
      objs.Tab.modify(tab, { settings: newSettings }, app.getCommonParams())
    } else if (scope === 'org') {
      if (app.state.orgId) {
        const orgUser = app.orgUser()
        const newSettings = {
          ...(orgUser.settings || {}),
          bgImage: image,
          bgImageColor: color
        }
        objs.OrgUser.modify(
          orgUser,
          { settings: newSettings },
          app.getCommonParams()
        )
      } else {
        const user = app.user()
        const newSettings = {
          ...(user.settings || {}),
          bgImage: image,
          bgImageColor: color
        }
        objs.User.modify(user, { settings: newSettings }, app.getCommonParams())
      }
    } else if (scope === 'global') {
      const user = app.user()
      const newSettings = {
        ...(user.settings || {}),
        globalBgImage: image,
        globalBgImageColor: color
      }
      objs.User.modify(user, { settings: newSettings }, app.getCommonParams())
    }
  },

  isFilterEmpty: function (filter) {
    // No filter, same as empty
    if (!filter) return true

    // No keys, same as empty
    if (!Object.keys(filter).length) return true

    // One of the array keys has a non-zero length, filter is not empty
    const arrays = ['users', 'labels', 'types']
    if (arrays.filter(a => filter[a] && filter[a].length).length) return false

    // Other than the array keys (which are all empty if we reach this point),
    // there are no keys, so same as empty filter
    const otherKeys = Object.keys(filter).filter(k => arrays.indexOf(k) === -1)
    if (!Object.keys(otherKeys).length) return true

    // Remaining keys (which are presumably boolean) are all false,
    // so same as empty
    if (!otherKeys.filter(k => filter[k]).length) return true

    // I don't think it's possible to reach this point, but let's return false
    // just as a precaution
    return false
  },

  setupWebSockets: (app, cb) => {
    const wsData = {
      params: {}
    }
    if (app.state.wsToken) {
      wsData.params.token = app.state.wsToken
    }
    if (app.state.secretShareId) {
      wsData.params.secret_share_id = app.state.secretShareId
    }
    window._socket = new Socket(
      `${window.NOTEBASE_WS_URL_PREFIX}/socket`,
      wsData
    )
    app.socket = window._socket
    app.socket.connect()
    app.socket.onError(() => {
      console.error('Web Socket error!') // eslint-disable-line no-console
      app.setState({ error: 'disconnected' })
    })
    app.socket.onClose(() => {
      console.error('Web Socket was closed') // eslint-disable-line no-console
      app.setState({ error: 'disconnected' })
    }) // eslint-disable-line no-console

    window._publicChannel = app.socket.channel('public', {})

    window._publicChannel.on('msg', payload => {
      Misc.handleWsMessage(app, payload, 'public')
    })

    window._publicChannel
      .join()
      .receive('ok', resp => {
        console.log('Joined public channel successfully', resp) // eslint-disable-line no-console
        app.setState({ error: null })
      })
      .receive('error', resp =>
        console.log('Unable to join public channel', resp)
      ) // eslint-disable-line no-console
      .receive('timeout', () =>
        console.log('Networking issue with public channel. Still waiting...')
      ) // eslint-disable-line no-console

    window._sessionChannel = app.socket.channel(
      `session:${window._app.session_id}`,
      {}
    )

    window._sessionChannel.on('msg', payload => {
      Misc.handleWsMessage(app, payload, 'session')
    })

    window._sessionChannel
      .join()
      .receive('ok', resp => {
        console.log('Joined session channel successfully', resp) // eslint-disable-line no-console
        app.setState({ error: null })
      })
      .receive('error', resp =>
        console.log('Unable to join session channel', resp)
      ) // eslint-disable-line no-console
      .receive('timeout', () =>
        console.log('Networking issue with session channel. Still waiting...')
      ) // eslint-disable-line no-console

    if (typeof cb === 'function') {
      cb()
    }
  },

  ensureSocketUp: (app, cb) => {
    if (app.socket) {
      cb()
    } else {
      Misc.setupWebSockets(app, cb)
    }
  },

  handleWsMessage: (app, msg, context) => {
    // eslint-disable-line no-unused-vars
    console.log(`Handling [${context}] ws message:`, msg) // eslint-disable-line no-console
    switch (msg.type) {
      case 'x':
        app.incomingCommand(msg.payload)
        break
      case 'locked':
        app.handleLocked(msg.payload)
        break
      case 'unlock':
        app.handleUnlock(msg.payload)
        break
      case 'sync':
        app.setAppState(msg.payload)
        break
      case 'import-done':
        // var newNodes = msg.payload.added.concat(msg.payload.updated);
        // var updatedIds = _.xmap(msg.payload.updated, 'id');
        // var minusUpdated = _.xreject(_this.state.entities.nodes, function(n) { return updatedIds.indexOf(n.id) !== -1; });
        // var tweakedNodes = minusUpdated.concat(newNodes);
        // var newEntities = Object.assign({}, _this.state.entities);
        // newEntities.nodes = _this.decorateNodesWithAccessLevel(_this.state.entities, tweakedNodes, _this.org(), _this.user());
        // _this.setAppState({error: null, entities: newEntities, updatedNodes: newNodes, importError: null, importing: false});
        // _this.setPanel(null);
        break
      case 'import-error':
        app.setAppState({ error: null, importError: true, importing: false })
        break
      case 'import-lines':
        if (app.state.importing) {
          $('#import-progress').text(
            'Evaluating 0 / ' + msg.payload.count + ' lines'
          )
        }
        app.importLines = msg.payload.count
        break
      case 'import-line-progress':
        if (app.state.importing) {
          $('#import-progress').text(
            'Evaluating ' +
              msg.payload.lines +
              ' / ' +
              app.importLines +
              ' lines'
          )
        }
        break
      case 'import-nodes':
        if (app.state.importing) {
          $('#import-progress').text(
            'Importing 0 / ' + msg.payload.count + ' nodes'
          )
        }
        app.importNodes = msg.payload.count
        break
      case 'import-node-progress':
        if (app.state.importing) {
          $('#import-progress').text(
            'Importing ' +
              msg.payload.nodes +
              ' / ' +
              app.importNodes +
              ' nodes'
          )
        }
        break
      default:
        console.warn('Unknown WS msg:', msg) // eslint-disable-line no-console
    }
    //   let transforms;
    //   if (msg.type === 'account_balance') {
    //     const account = app.state.entities.accounts.find(a => { return a.id === msg.id; });
    //     if (account) {
    //       const updatedAccount = {...account, balance: msg.balance};
    //       transforms = {
    //         accounts: [{op: 'replace', data: [updatedAccount]}],
    //       };
    //     }
    //   } else if (msg.type === 'new_account') {
    //     transforms = {
    //       accounts: [{op: 'replace', data: [msg.account]}],
    //     };
    //   } else if (msg.type === 'hold_amount') {
    //     const hold = app.state.entities.holds.find(h => { return h.id === msg.id; });
    //     if (hold) {
    //       const updatedHold = {...hold, amount: msg.amount};
    //       transforms = {
    //         holds: [{op: 'replace', data: [updatedHold]}],
    //       };
    //     }
    //   } else if (msg.type === 'new_hold') {
    //     transforms = {
    //       holds: [{op: 'replace', data: [msg.hold]}],
    //     };
    //   }

    //   if (transforms) {
    //     Helpers.applyTransforms(app, transforms, 'entities');
    //   }
  },

  joinUserChannel: app => {
    Misc.ensureSocketUp(app, () => {
      window._userChannel = app.socket.channel(`user:${app.state.wsUserId}`, {})
      app.userChannel = window._userChannel
      app.userChannel.on('msg', payload => {
        Misc.handleWsMessage(app, payload, 'user')
      })
      app.userChannel
        .join()
        .receive('ok', resp =>
          console.log('Joined user channel successfully', resp)
        ) // eslint-disable-line no-console
        .receive('error', resp =>
          console.log('Unable to join user channel', resp)
        ) // eslint-disable-line no-console
        .receive('timeout', () =>
          console.log('Networking issue with user channel. Still waiting...')
        ) // eslint-disable-line no-console
    })
  },

  joinOrgChannel: app => {
    Misc.ensureSocketUp(app, () => {
      app.orgChannel = app.socket.channel(`org:${app.state.wsOrgId}`, {})
      app.orgChannel.on('msg', payload => {
        Misc.handleWsMessage(app, payload, 'org')
      })
      app.orgChannel
        .join()
        .receive('ok', resp =>
          console.log('Joined org channel successfully', resp)
        ) // eslint-disable-line no-console
        .receive('error', resp =>
          console.log('Unable to join org channel', resp)
        ) // eslint-disable-line no-console
        .receive('timeout', () =>
          console.log('Networking issue with org channel. Still waiting...')
        ) // eslint-disable-line no-console
    })
  },

  positionFloater: (floater, targetEl) => {
    targetEl = $(targetEl)
    var wrapper = $('#app-wrapper')
    var floaterEl = $('#floater')
    var offset = targetEl.offset()
    if (offset.left - floaterEl.width() < 0) {
      floaterEl.css('left', offset.left + 15)
    } else {
      floaterEl.css('left', offset.left - floaterEl.width() - 5)
    }
    floaterEl.css('top', offset.top + wrapper.scrollTop())
  }
}

window.Misc = Misc
module.exports = Misc
