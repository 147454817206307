import React from 'react'

class ThemesSection extends React.Component {
  componentDidMount () {}

  render () {
    var content = <div />

    if (this.props) {
      content = <div className='form'>Themes section</div>
    }

    return content
  }
}

module.exports = ThemesSection
