var urlRegex = require('url-regex')
var S = require('string')
var $ = require('jquery')

var Node = require('./node')
var Misc = require('./misc')
var Text = require('./text')

const moveKeys = ['a', 'e', 'b', 'f']

module.exports = {
  debug: true,

  linkRegex: new RegExp('([\\s():,]|^)([@#])([\\wøæåØÆÅäëöÄËÖïÏüÜ]+)', 'gi'),
  linkReplacer: function (match, pre, symbol, username, offset, string) {
    // eslint-disable-line no-unused-vars
    return (
      pre + '<span class="searchlink auto">' + symbol + username + '</span>'
    )
  },

  // urlRegex: /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()[\]{};:'".,<>?«»“”‘’]))/i,
  urlRegex: urlRegex(),
  urlReplacer: function (match, url, username, offset, string) {
    // eslint-disable-line no-unused-vars
    var tweakedUrl = /^https?:/.test(match) ? match : 'http://' + match
    return (
      '<a class="link auto" href="' +
      tweakedUrl +
      '" target="_blank">' +
      match +
      '</a>'
    )
  },

  validReadOnlyKey: function (ev) {
    if ([37, 38, 39, 40].indexOf(ev.keyCode) !== -1) {
      // left, up, right, down arrow
      return true
    }

    if (ev.ctrlKey && [65, 66, 69, 70, 78, 80].indexOf(ev.keyCode) !== -1) {
      return true
    }

    if (ev.metaKey && [82].indexOf(ev.keyCode) !== -1) {
      return true
    }

    return false
  },

  handleTitleKeyDown: function (ev, node, params) {
    if (
      !$(ev.target)
        .closest('li.node')
        .hasClass('remote') &&
      $(ev.target).closest('li.node.read-only').length
    ) {
      if (!this.validReadOnlyKey(ev)) {
        ev.preventDefault()
      }
    } else {
      if (ev.keyCode === 13) {
        // ENTER
        this.handleTitleEnter(ev, node, params)
      } else if (ev.keyCode === 8) {
        // BACKSPACE
        this.handleTitleBackspace(ev, node, params)
      } else if (ev.keyCode === 9) {
        // TAB KEY
        this.handleTitleTab(ev, node, params)
      }
    }
  },

  handleTitleKeyUp: function (ev, node, params) {
    var div
    var el = $(ev.target).closest('li')
    if (el.length) {
      div = el.find(
        '> .indented > .indent-wrapper > .node-content > div > div > div.title[contenteditable]'
      )
    } else {
      el = $(ev.target)[0]
      div = el
    }

    // var richTextEnabled = params.app.featureEnabled('rich_titles')
    if (div.length) {
      // console.log('div in keyUp:', div.text());
      // var text
      // if (richTextEnabled) {
      //   text = div.html()
      // } else {
      //   text = S(div.text()).escapeHTML().s
      // }
      var text = S(div.text()).escapeHTML().s
      if (params.searchstring) {
        var re = new RegExp('(' + params.searchstring + ')', 'i')
        text = text.replace(re, '<em class="search">$1</em>')
      }

      // var urlText = text.replace(urlRegex, urlReplacer).replace(/\s$/, '&nbsp;').replace(/;$/, '');
      var urlText = text.replace(
        module.exports.urlRegex,
        module.exports.urlReplacer
      )
      var linkText = urlText.replace(
        module.exports.linkRegex,
        module.exports.linkReplacer
      )

      // XXX: This sometimes causes the cursor to backtrack one or two chars,
      // if you type something exactly when the undo timeout kick in
      if (linkText !== text) {
        Text.saveSelection(div.get(0))
        div.html(linkText.replace(/\s$/, '&nbsp;'))
        Text.restoreSelection(div.get(0))
      }

      // var title
      // if (richTextEnabled) {
      //   if (params.app.state.rawTitleNodeId === node.id) {
      //     title = params._parseMarkdown(div.text(), { inline: true })
      //   } else {
      //     title = div.html()
      //   }
      // } else {
      //   title = div.text()
      // }
      var title = div.text()

      params.setTitle(title)
      clearTimeout(window._app.titleUndoTimeout)
      // console.log('setting undo timeout in handleTitleKeyUp');
      if (ev.ctrlKey && moveKeys.indexOf(ev.key) !== -1) {
        Text.saveSelection(div.get(0))
      }
      window._app.cancelNextUndoTimeout = false
      window._app.titleUndoTimeout = setTimeout(function () {
        if (!window._app.cancelNextUndoTimeout) {
          // Text.saveSelection(div.get(0))
          Node.setTextUndo(node, 'title', title, params, () => {
            if (linkText !== text) {
              Text.restoreSelection(div.get(0))
            }
          })
        }
        window._app.cancelNextUndoTimeout = false
      }, 1000)
    }
  },

  handleHeadingKeyDown: function (ev, node, params) {
    if ($('#body-container.read-only').length) {
      if (!this.validReadOnlyKey(ev)) {
        ev.preventDefault()
        ev.stopPropagation()
      }
    } else {
      if (ev.keyCode === 13) {
        // ENTER
        this.handleHeadingEnter(ev, node, params)
      } else if (
        ev.keyCode === 9 ||
        ev.keyCode === 40 ||
        (ev.ctrlKey && ev.keyCode === 78)
      ) {
        // TAB KEY or DOWN ARROW or CTRL+n
        this.handleHeadingTabOrDown(ev, node, params)
      }
    }
  },

  handleHeadingKeyUp: function (ev, node, params) {
    if ($('#body-container.read-only').length && !this.validReadOnlyKey(ev)) {
      ev.preventDefault()
      ev.stopPropagation()
    } else {
      var div = $(ev.target).closest('.node-heading > span')
      Text.saveSelection(div.get(0))
      var richTextEnabled = params.app.featureEnabled('rich_titles')
      var text
      if (richTextEnabled) {
        text = div.html()
      } else {
        text = S(div.text()).escapeHTML().s
      }
      if (params.searchstring) {
        var re = new RegExp('(' + params.searchstring + ')', 'i')
        text = text.replace(re, '<em class="search">$1</em>')
      }

      var urlText = text
        .replace(module.exports.urlRegex, module.exports.urlReplacer)
        .replace(/\s$/, '&nbsp;')
        .replace(/;$/, '')
      var linkText = urlText
        .replace(module.exports.linkRegex, module.exports.linkReplacer)
        .replace(/\s$/, '&nbsp;')
      div.html(linkText)
      Text.restoreSelection(div.get(0))

      const heading = div.html()
      params.setTitle(heading)
      clearTimeout(window._app.titleUndoTimeout)
      window._app.cancelNextUndoTimeout = false
      window._app.titleUndoTimeout = setTimeout(function () {
        if (!window._app.cancelNextUndoTimeout) {
          Text.saveSelection(div.get(0))
          Node.setTextUndo(node, 'title', heading, params)
        }
        window._app.cancelNextUndoTimeout = false
      }, 1000)
    }
  },

  handleDescriptionKeyDown: function (ev, node, params) {
    if (
      !$(ev.target)
        .closest('li.node')
        .hasClass('remote') &&
      $(ev.target).closest('li.node.read-only').length
    ) {
      if (!this.validReadOnlyKey(ev)) {
        ev.preventDefault()
      }
    } else {
      if (ev.keyCode === 13) {
        // ENTER
        this.handleDescriptionTabAndEnter(ev, node, params)
      } else if (ev.keyCode === 9) {
        // TAB KEY
        this.handleDescriptionTabAndEnter(ev, node, params)
      }
    }
  },

  handleDescriptionKeyUp: function (ev, node, params) {
    var div = $(ev.target).closest('.description')
    Text.saveSelection(div.get(0))
    var text = S(div.text()).escapeHTML().s
    if (params.searchstring) {
      var re = new RegExp('(' + params.searchstring + ')', 'i')
      text = text.replace(re, '<em class="search">$1</em>')
    }

    var urlText = text
      .replace(module.exports.urlRegex, module.exports.urlReplacer)
      .replace(/\s$/, '&nbsp;')
      .replace(/;$/, '')
    var linkText = urlText
      .replace(module.exports.linkRegex, module.exports.linkReplacer)
      .replace(/\s$/, '&nbsp;')
    div.html(linkText)
    Text.restoreSelection(div.get(0))

    const description = div.text()
    params.setDescription(description)
    clearTimeout(window._app.descriptionUndoTimeout)
    window._app.cancelNextUndoTimeout = false
    window._app.descriptionUndoTimeout = setTimeout(function () {
      if (!window._app.cancelNextUndoTimeout) {
        Text.saveSelection(div.get(0))
        Node.setTextUndo(node, 'description', description, params)
      }
      window._app.cancelNextUndoTimeout = false
    }, 1000)
  },

  /// ///////////////////
  //       TITLE      //
  /// ///////////////////

  handleTitleEnter: function (ev, node, params) {
    var parentId,
      newNode,
      childLi,
      div,
      beforeLength,
      pre,
      post,
      html,
      next,
      currLevel,
      nextLevel

    // var li = $(ev.target).closest('li');
    var li = $(Misc.nodeEl(node))
    var openCtrl = $('#floater.context-menu:visible')
    if (openCtrl.length) {
      // passthrough
    } else {
      if (li.length) {
        var richTextEnabled = params.app.featureEnabled('rich_titles')
        // Make sure the node where the cursor is at is also refreshed
        params.updatedState = params.updatedState || {}
        const updatedNodes = (
          params.updatedState.updatedNodes ||
          params.state.updatedNodes ||
          []
        ).slice()
        updatedNodes.push(node)
        params.updatedState.updatedNodes = updatedNodes

        currLevel = parseInt(li.attr('data-level'))
        next = li.next()
        if (next.length) {
          nextLevel = parseInt(next.attr('data-level'))
          if (nextLevel > currLevel) {
            childLi = next
          }
        }

        div = li.find(
          '> .indented > .indent-wrapper > .node-content > div > div > div.title[contenteditable]'
        )
        Text.saveSelection(div.get(0))
        var posName = 'start'
        if (richTextEnabled) {
          posName = 'htmlPos'
          html = div.html()
          pre = html.substring(0, Text.savedSelection.htmlPos)
          post = html.substring(Text.savedSelection.htmlPos)
        } else {
          posName = 'end'
          html = div.text()
          pre = html.substring(0, Text.savedSelection.start)
          post = html.substring(Text.savedSelection.end)
        }
        beforeLength = html.trim().length

        if (ev.ctrlKey) {
          params.toggleCompleted(node, true)
        } else if (ev.metaKey) {
          if (!$(ev.target).closest('li.node.read-only').length) {
            li = $(ev.target).closest('li.node')
            div = li.find(
              '> .indented > .indent-wrapper > .node-content > div > div > div.title[contenteditable]'
            )
            // var savedSelection = {start: 0};
            Text.saveSelection(div.get(0))
            var newSettings = { ...node.settings }
            newSettings.emphasized = !node.settings.emphasized
            Node.modify(node, { settings: newSettings }, params)
          }
        } else if (ev.shiftKey) {
          // Passthrough to app handler
          // noAction = true;
        } else {
          li.removeClass('active')
          // END OF THE LINE
          if (Text.savedSelection[posName] === beforeLength) {
            if (childLi && childLi.length) {
              // Add to child list, as first element
              parentId = li.data('node-id')
              newNode = Node.new(
                {
                  parent_id: node.id,
                  prev_id: null,
                  user_id: node.user_id,
                  org_id: node.org_id
                },
                params
              )
              if (this.debug) {
                console.log('add [' + newNode.id + '] to [' + parentId + ']')
              } // eslint-disable-line no-console
              Node.add(newNode, params)
            } else {
              // Add below current element
              parentId = node.parent_id
              newNode = Node.new(
                {
                  parent_id: node.parent_id,
                  prev_id: node.id,
                  user_id: node.user_id,
                  org_id: node.org_id
                },
                params
              )
              if (this.debug) {
                console.log('add [(empty)] to [' + node.parent_id + ']')
              } // eslint-disable-line no-console
              Node.add(newNode, params)
            }
          } else {
            // NOT END OF TEXT
            newNode = Node.new(
              {
                parent_id: node.parent_id,
                prev_id: node.prev_id,
                title: pre,
                user_id: node.user_id,
                org_id: node.org_id
              },
              params
            )
            if (this.debug) {
              console.log(
                'modify [' +
                  node.id +
                  '] from [' +
                  node.title +
                  '] to [' +
                  post.trim() +
                  ']'
              )
            } // eslint-disable-line no-console
            if (this.debug) {
              console.log(
                'add [' +
                  newNode.id +
                  '] to [' +
                  newNode.parent_id +
                  ':' +
                  newNode.prev_id +
                  ']'
              )
            } // eslint-disable-line no-console
            // oldHtml = node.title;

            Node.splitNodeAtCursor(
              node,
              Text.savedSelection[posName],
              pre,
              post,
              params
            )
          }
        }

        // window._app.original_title = newTitle;
        ev.preventDefault()
      }
    }
  },

  handleTitleBackspace: function (ev, node, params) {
    if (!(ev.shiftKey && ev.ctrlKey)) {
      const li = $(ev.target).closest('li')

      const div = li.find(
        '> .indented > .indent-wrapper > .node-content > div > div > div.title[contenteditable]'
      )
      Text.saveSelection(div.get(0))

      // Make sure the cursor is at the start of the line
      if (
        Text.savedSelection.start === 0 &&
        (!Text.savedSelection.end || Text.savedSelection.end === 0)
      ) {
        const newTitle = div.text()

        // Empty node, just delete it and leave everything else in place
        if (
          newTitle === '' &&
          node.type === 'list' &&
          !node.body &&
          !node.url &&
          !(li.hasClass('children') || li.hasClass('expanded'))
        ) {
          const prev = Misc.prevNodeEl(node.id)
          const next = Misc.nextNodeEl(node.id)
          const focusNode = prev.length ? prev : next.length ? next : null
          const focusId = focusNode ? focusNode.data('node-id') : null
          let focusLength
          if (focusNode) {
            focusLength = focusNode.text().length
          }
          Node.remove(
            node,
            Object.assign({}, params, { _skipFocus: true }),
            removeParams => {
              if (focusId) {
                const nodes = removeParams.updatedState.entities
                  ? removeParams.updatedState.entities.nodes
                  : removeParams.state.entities.nodes
                var focusNode = Node.get(focusId, nodes)
                Node.focus(focusNode.id, focusLength)
              }
            }
          )
        } else {
          // Non-empty node, try to be smart when deleting
          Node.mergeNodesAtCursor(node, params)
        }
      }
    }
  },

  handleTitleTab: function (ev, node, params) {
    var parentLi,
      children,
      ul,
      div,
      prev,
      toId,
      grandParentLi,
      target,
      index,
      newPreviousSiblingId

    var li = $(Misc.nodeEl(node))

    var rootNode = params.rootNode()

    // var li = $(ev.target).closest('li');
    if (!li.closest('ul.inbox').length) {
      ul = li.closest('ul')
      if (typeof index === 'undefined') {
        index = ul.find('> li').index(li)
      }

      div = li.find(
        '> .indented > .indent-wrapper > .node-content > div > div > div.title[contenteditable]'
      )

      Text.saveSelection(div.get(0))

      parentLi = Misc.parentLi(li)
      if (ev.shiftKey) {
        target = Misc.outdentNodeTarget(li)
        if (target.length) {
          grandParentLi = Misc.parentLi(parentLi)
          toId = Node.resolvedParentId(
            grandParentLi.data('node-id'),
            params.state.entities.nodes,
            rootNode
          )
          newPreviousSiblingId = parentLi.data('node-id')
          if (this.debug) {
            console.log(
              'move [' +
                node.id +
                '] from [' +
                node.parent_id +
                '] to [' +
                toId +
                ':' +
                newPreviousSiblingId +
                ']'
            )
          } // eslint-disable-line no-console
          Node.outdentNodeAtCursor(node, toId, newPreviousSiblingId, params)
        }
      } else if (!ev.shiftKey && !ev.altKey && !ev.metaKey) {
        target = Misc.indentNodeTarget(li)
        if (target.length) {
          if (this.debug) console.log('prev', prev) // eslint-disable-line no-console
          toId = Node.resolvedParentId(
            target.data('node-id'),
            params.state.entities.nodes,
            rootNode
          )
          children = Node.children(toId, params.entities.nodes)
          newPreviousSiblingId = children.length
            ? Node.lastSibling(children).id
            : null
          if (this.debug) {
            console.log(
              'move [' +
                node.id +
                '] from [' +
                node.parent_id +
                '] to [' +
                toId +
                ':' +
                newPreviousSiblingId +
                ']'
            )
          } // eslint-disable-line no-console
          Node.indentNodeAtCursor(node, toId, newPreviousSiblingId, params)
        }
      }

      // XXX: This is not clean. Use activeNode instead?
      setTimeout(function () {
        $(Misc.nodeEl(node)).addClass('active')
      }, 10)
      ev.preventDefault()
    }
  },

  /// ///////////////////
  //      HEADING     //
  /// ///////////////////

  handleHeadingEnter: function (ev, node, params) {
    if (ev.ctrlKey) {
      var newValue = !node.completed
      var parentNode = Node.get(node.parent_id, params.state.entities.nodes)
      Node.modify(node.id, { completed: newValue }, params, () => {
        // if (parentNode) {
        //   undoCommands.push({obj: 'Node', cmd: 'setRoot', args: [node.id], persist: false});
        // }

        setTimeout(function () {
          if (parentNode && newValue) {
            // params.setAppState({rootNode: parentNode});
            params.setRoot(parentNode)
          }
        }, 1500)
      })
    } else {
      var first = $('li.node')
      if (first.length) {
        Node.flushTextUndo(params, () => {
          Node.focus(first.data('node-id'), null, params)
        })
      }
    }
    ev.preventDefault()
  },

  handleHeadingTabOrDown: function (ev, node, params) {
    const selector =
      node.mode === 'board' ? '.nodes .node.kb-card' : '.nodes .node'
    var first = $(selector)
    if (first.length) {
      if (node.mode === 'board') {
        ev.target.blur()
      }
      Node.flushTextUndo(
        Object.assign({}, params, { _skipFocus: true }),
        () => {
          Node.focus(first.data('node-id'), null, params)
        }
      )
    }
    ev.preventDefault()
  },

  /// ///////////////////
  //    DESCRIPTION   //
  /// ///////////////////

  handleDescriptionTabAndEnter: function (ev, node, params) {
    if (!ev.shiftKey) {
      var next = Misc.nextNodeEl(node.id)
      if (next.length) {
        const commonParams = Object.assign({}, params, { _skipFocus: true })
        Node.flushTextUndo(commonParams, flushParams => {
          const nodes = flushParams.updatedState.entities
            ? flushParams.updatedState.entities.nodes
            : flushParams.state.entities.nodes
          const updatedNode = Node.get(node.id, nodes)
          params.setAppState(
            { descriptionNode: null, updatedNodes: [updatedNode] },
            () => {
              Node.focus(next.data('node-id'))
            }
          )
        })
      }
      ev.preventDefault()
    }
  }
}
