import React from 'react'

class ManageOrganizationsSection extends React.Component {
  state = {}

  componentDidMount () {}

  render () {
    var content = <div>Manage Organizations</div>

    return content
  }
}

module.exports = ManageOrganizationsSection
