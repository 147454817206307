module.exports = [
  '#ff6800',
  '#fcba00',
  '#7bdcb6',
  '#00d084',
  '#8dd1fc',
  '#0793e3',
  '#abb8c3',
  '#ec134c',
  '#f78ca7',
  '#9802ef'
]
