import React from 'react'
import NodeInfo from '../sections/node_info'
import NodeLabels from '../sections/node_labels'
import NodeUsers from '../sections/node_users'
import NodeFiles from '../sections/node_files'
import Heading from '../sections/heading'
import Node from '../../../js/node'
import Misc from '../../../js/misc'

import './styles.css'

export default class Summary extends React.Component {
  render () {
    const { app, data, orgNodes } = this.props
    const node = Node.get(data.nodeId, orgNodes)
    const coverObj = app.state.entities.node_files.find(
      e => e.node_id === node.id && e.is_cover
    )
    const cover = coverObj
      ? app.state.entities.files.find(e => e.id === coverObj.file_id)
      : null
    return (
      <div className='body full-height summary'>
        <Heading {...this.props} name='Summary' isMain />
        <div className='panel padded title-and-desc'>
          {cover && (
            <div className='cover-img'>
              <i className='fa fa-fw fa-external-link-square' />
              <a
                href={Misc.fileUrl(cover)}
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={Misc.fileUrl(cover)} />
              </a>
            </div>
          )}
          <h3>{node.title}</h3>
          {node.description && (
            <div className='description'>{node.description}</div>
          )}
        </div>
        <NodeInfo {...this.props} activeNode={node} noHeading />
        <NodeLabels {...this.props} node={node} noHeading mode={data.mode} />
        <NodeUsers {...this.props} node={node} noHeading mode={data.mode} />
        <NodeFiles {...this.props} node={node} />
      </div>
    )
  }
}
