import './styles.css'

import React from 'react'

import SetPlaceholder from '../../set_placeholder'
import Node from '../../../../../../js/node'

export default class Location extends React.Component {
  handleLocationPick = suggestion => {
    const { app, node } = this.props
    const oldData = node.data || {}
    let location
    if (typeof suggestion === 'object') {
      location = { name: suggestion.description, coords: suggestion.location }
    } else {
      location = { name: suggestion }
    }
    const data = { ...oldData, location }
    Node.modify(node.id, { data }, app.getCommonParams())
  }

  render () {
    const { app, node } = this.props
    const location = (node.data || {}).location || {}
    const toggleFloater = ev =>
      app.toggleFloater(ev.target, 'location-picker', {
        onPick: this.handleLocationPick
      })

    return (
      <div className={`location ${location.coords ? 'with-coords' : ''}`}>
        {location.name && (
          <div className='actual-data'>
            {Boolean(true && location.coords) && (
              <a
                href={`https://www.google.com/maps/search/?api=1&query=${
                  location.coords.lat
                },${location.coords.lng}`}
                target='_blank'
              >
                <img
                  src={`https://maps.googleapis.com/maps/api/staticmap?center=${
                    location.coords.lat
                  },${location.coords.lng}&zoom=13&size=130x60&maptype=roadmap
    &key=${app.state.config.mapsApiKey}`}
                />
              </a>
            )}
            <div className='label' onClick={toggleFloater}>
              <span>{location.name}</span>
            </div>
          </div>
        )}
        {!location.name && <SetPlaceholder onClick={toggleFloater} />}
      </div>
    )
  }
}
