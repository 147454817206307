import React from 'react'

export default class FeedbackOverlay extends React.Component {
  state = {}

  close = () => {
    this.props.app.setPanel(null)
  }

  render () {
    return (
      <div id='export'>
        <div className='row'>
          <div className='row-heading'>
            <label>Tell us what you think</label>
          </div>
          <div className='row-content'>
            <p>Yeah, please do.</p>
          </div>
        </div>

        <div className='buttons'>
          <button onClick={this.close} className='button default'>
            Close
          </button>
        </div>
      </div>
    )
  }
}
