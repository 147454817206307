import React from 'react'

export default class BrowseControls extends React.Component {
  render () {
    return (
      <div className='browse-controls'>
        <div title='Back' onClick={() => window.history.back()}>
          <i className='fa fa-fw fa-caret-left' />
        </div>
        <div title='Forward' onClick={() => window.history.forward()}>
          <i className='fa fa-fw fa-caret-right' />
        </div>
      </div>
    )
  }
}
