import './styles.css'

import React from 'react'
import _ from 'lodash'

import Heading from '../heading'
import SingleNode from '../single_node'

class Navigator extends React.Component {
  setType = (type, ev) => {
    this.props.app.saveUserSettings(
      { navigatorType: type },
      this.props.app.getCommonParams()
    )
  }

  render () {
    const { app } = this.props
    // if (this.props.user && this.props.tab && this.props.tab.root_id) {
    const excludedTypes = ['list', 'site']
    const nodeTypes = app
      .validNodeTypes()
      .filter(t => excludedTypes.indexOf(t) === -1)
    if (this.props.user) {
      const type = this.props.user.settings.navigatorType || 'fave'
      // const eligibleRe = new RegExp(this.props.tab.root_id.replace(/\-/g, '_'));
      // const eligibleNodes = this.props.orgNodes.filter(n => { return eligibleRe.test(n.path); });
      const eligibleNodes = this.props.orgNodes
      let faveNodeIds = []
      if (type === 'fave') {
        const orgId = this.props.app.orgId()
        const filteredFaves = this.props.app.state.entities.faves.filter(f => {
          return orgId ? f.org_id === orgId : !f.org_id
        })
        faveNodeIds = filteredFaves.map(f => {
          return f.node_id
        })
      }
      if (type === 'board') {
        const orgId = this.props.app.orgId()
        const filteredFaves = this.props.app.state.entities.faves.filter(f => {
          return orgId ? f.org_id === orgId : !f.org_id
        })
        faveNodeIds = filteredFaves.map(f => {
          return f.node_id
        })
      }
      const typeNodes = eligibleNodes.filter(n => {
        if (type === 'fave') {
          return faveNodeIds.indexOf(n.id) !== -1
        } else if (type === 'board') {
          return n.mode === 'board'
        } else {
          return n.type === type
        }
      })
      const sortedNodes = _.sortBy(typeNodes, 'created_at').reverse()
      const items = sortedNodes.length ? (
        sortedNodes.slice(0, 10).map(n => {
          return (
            <SingleNode
              key={'nav-' + n.id}
              app={this.props.app}
              mode='icon'
              node={n}
            />
          )
        })
      ) : (
        <div className='no-items'>Nothing found.</div>
      )
      return (
        <div className='panel'>
          <div id='navigator'>
            <Heading {...this.props} name='Navigator' />
            <div className='navigator-nav'>
              <span
                className={type === 'fave' ? 'active' : ''}
                onClick={() => this.setType('fave')}
              >
                <i className='icon fave fa fa-fw fa-star' />
              </span>
              <span
                className={type === 'board' ? 'active' : ''}
                onClick={() => this.setType('board')}
              >
                {app.nodeIcon({ type: 'list', mode: 'board' })}
              </span>
              {nodeTypes.map(t => (
                <span
                  key={t}
                  className={type === t ? 'active' : ''}
                  onClick={() => this.setType(t)}
                >
                  {app.nodeIcon(t)}
                </span>
              ))}
            </div>
            <div className='navigator-list'>{items}</div>
          </div>
        </div>
      )
    } else {
      return false
    }
  }
}

module.exports = Navigator
