import React from 'react'
import Geosuggest from 'react-geosuggest'

import './styles.css'

export default class LocationPicker extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      locationStr: '',
      locationSuggestion: null
    }
  }

  componentDidMount () {
    this.geo.focus()
  }

  handleLocationSelect = suggestion => {
    const { app } = this.props
    if (
      app.state.floater &&
      app.state.floater.data &&
      app.state.floater.data.onPick
    ) {
      app.state.floater.data.onPick(
        this.state.locationSuggestion || this.state.locationStr
      )
    }
    app.setState({ floater: null })
  }

  handleLocationUpdateSuggests = (suggestions, activeSuggestion) => {
    this.setState({ locationSuggestion: activeSuggestion })
  }

  render () {
    return (
      <Geosuggest
        minLength={2}
        onActivateSuggest={locationSuggestion =>
          this.setState({ locationSuggestion })
        }
        onChange={locationStr => this.setState({ locationStr })}
        onSuggestSelect={this.handleLocationSelect}
        onUpdateSuggests={this.handleLocationUpdateSuggests}
        ref={geo => {
          this.geo = geo
        }}
      />
    )
  }
}
