import './styles.css'

// var ReactRenderVisualizer = require('react-render-visualizer');

import React from 'react'
import $ from 'jquery'
import moment from 'moment'
import classNames from 'classnames'

import constants from '../..//lib/constants'

import NodeItem from '../node_item'
import Bullet from '../bullet'
import NodeIndicators from '../node_indicators'
import NodeMeta from '../node_meta'
import Stripes from '../stripes'

import Node from '../../js/node'

var placeholder = document.createElement('div')
placeholder.className = 'drop-handle'

class TreeNode extends React.Component {
  // mixins: [ReactRenderVisualizer],
  state = {
    visible: true,
    topHalf: false,
    ctxOpen: false
  }

  isCollapsed = () => {
    return (
      !this.props.app.state.searchstring &&
      (!this.props.tab ||
        !this.props.tab.expanded_nodes ||
        this.props.tab.expanded_nodes.indexOf(this.props.node.id) === -1)
    )
  }

  toggleChildren = () => {
    this.props.app.toggleChildren(this.props.node)
    // var li = this.refs.li;
    // this.props.app.toggleChildren(this.props.node, function() {
    //   $(li).addClass('hovered');
    // });
  }

  handleClick = ev => {
    clearTimeout(this.contextTimer)
    var li = $(ev.target).closest('li.node')
    this.props.app.setActiveNodeEl(li)
    ev.stopPropagation()
  }

  topHalf = ev => {
    var halfHeight = ev.target.offsetHeight / 2
    var height = halfHeight - halfHeight / 2
    var diff = ev.pageY - $(ev.target).offset().top
    return diff < height
  }

  shouldComponentUpdate (nextProps, nextState) {
    // var logNode = '445a7bc8-7229-4cf0-bd3b-be8373b90581';
    var logNode = 'db04ac12-eaf6-4866-9a40-1a9d2c7b0367'

    // if (this.props.node.id === logNode) {
    //   console.log('lognode stuff1:', nextProps.app.state.selectedInboxItems);
    //   console.log('lognode stuff2:', this.props.selectedInboxItems);
    // }
    if (nextProps.updateAllNodes) {
      if (this.props.node.id === logNode) {
        console.log('should2') // eslint-disable-line no-console
      }
      return true
    }

    if (
      nextProps.node.inbox &&
      nextProps.selectedInboxItems !== this.props.electedInboxItems
    ) {
      return true
    }

    if (nextState.ctxOpen !== this.state.ctxOpen) {
      //   if (this.props.node.id === logNode) {
      //     console.log('should2');
      //   }
      return true
    }

    if (nextProps.tmpVisible !== this.props.tmpVisible) {
      if (this.props.node.id === logNode) {
        console.log('should3') // eslint-disable-line no-console
      }

      return true
    }

    if (nextProps.sharedShares !== this.props.sharedShares) {
      if (this.props.node.id === logNode) {
        console.log('should4') // eslint-disable-line no-console
      }

      return true
    }

    if (nextProps.draggedOver !== this.props.draggedOver) {
      if (this.props.node.id === logNode) {
        console.log('should5') // eslint-disable-line no-console
      }

      return true
    }

    // if (nextProps.nodes !== this.props.nodes || nextProps.displayNodes !== this.props.displayNodes) {
    //   if (this.props.node.id === '6867489c-1f2d-4d2c-9c45-8a62010a37da') {
    //     console.log('nextProps.nodes !== this.props.nodes', nextProps.nodes !== this.props.nodes);
    //     console.log('nextProps.displayNodes !== this.props.displayNodes', nextProps.displayNodes !== this.props.displayNodes);
    //     console.log('should6');
    //   }
    //   return true;
    // }
    if (
      nextProps.app.state.updatedNodeIds &&
      nextProps.app.state.updatedNodeIds.length &&
      !nextProps.node.inbox &&
      (!nextProps.node.parent_id ||
        nextProps.app.state.updatedNodeIds.indexOf(nextProps.node.id) !== -1)
    ) {
      if (this.props.node.id === logNode) {
        console.log('should6') // eslint-disable-line no-console
      }

      // console.log('YOe', nextProps.node.id);
      // console.log('P', nextProps.node.parent_id);
      return true
    }

    if (nextProps.searchstring !== this.props.searchstring) {
      if (this.props.node.id === logNode) {
        console.log('should7') // eslint-disable-line no-console
      }

      return true
    }

    if (
      nextProps.tab &&
      nextProps.tab.expanded_nodes !== this.props.tab.expanded_nodes
    ) {
      var fresh = nextProps.tab.expanded_nodes
      var old = this.props.tab.expanded_nodes
      if (fresh && old) {
        var freshResult = fresh.indexOf(this.props.node.id)
        var oldResult = old.indexOf(this.props.node.id)
        if (freshResult !== oldResult) {
          if (this.props.node.id === logNode) {
            console.log('should8') // eslint-disable-line no-console
          }

          return true
        }
      } else {
        if (fresh) {
          return true
        }
      }
    }

    // if (this.props.node.id === logNode) {
    //   console.log('Hah! false'); // eslint-disable-line no-console
    // }
    return false
  }

  setParentState = state => {
    this.setState(state)
  }

  toggle = () => {
    this.setState({ visible: !this.state.visible })
  }

  handleMouseDown = ev => {
    if (this.props.onMouseDown) {
      this.props.onMouseDown(ev)
    }
  }

  render () {
    var isListView = this.props.app.isListView()

    if (this.props.node.id === '6938a752-8db9-44d4-a78a-3f9b2a372d87') {
      // console.log('render', Math.random());
    }
    // console.log('RENDERED:', this.props.node.title);

    var t0 = new Date().getTime()

    // console.log('render');
    var _this = this
    var isCollapsed = false

    // console.log('-------', _this.props.node.title);
    var nodeId = this.props.node.remote_id
      ? this.props.node.remote_id
      : this.props.node.id

    var children
    if (isListView) {
      children = []
    } else {
      isCollapsed = this.isCollapsed()
      children = _this.props.nodes.filter(node => node.parent_id === nodeId)
    }

    var classes = classNames({
      node: true,
      dragged: this.props.dragged === this.props.node.id,
      'dragged-over':
        this.props.dragIsValid && this.props.draggedOver === this.props.node.id,
      'tmp-completed':
        this.props.node.completed &&
        this.props.tmpVisible.indexOf(this.props.node.id) !== -1,
      completed:
        this.props.node.completed &&
        this.props.tmpVisible.indexOf(this.props.node.id) === -1,
      open: !this.props.node.completed,
      published: this.props.node.settings && this.props.node.settings.published,
      emphasized:
        this.props.node.settings && this.props.node.settings.emphasized,
      remote: this.props.node.remote_id,
      shared: Node.getShare(
        this.props.node,
        this.props.app.state.entities.shares
      ),
      'read-only':
        this.props.hasOwnProperty('accessLevel') &&
        this.props.accessLevel !== null &&
        this.props.accessLevel < constants.WRITE_ACCESS,
      expanded: children.length && (!this.props.searchstring && !isCollapsed),
      children: children.length && (!this.props.searchstring && isCollapsed),
      'cut-node':
        this.props.app.state.cutNodeId &&
        this.props.app.state.cutNodeId === this.props.node.id,
      'raw-title': this.props.app.state.rawTitleNodeId === this.props.node.id,
      'has-due-date': this.props.node.due_at,
      'is-highlighted':
        !this.props.isFilterEmpty &&
        (this.props.app.state.filteredNodes || [])
          .map(n => n.id)
          .indexOf(this.props.node.id) !== -1,
      'is-redacted': this.props.node.redacted,
      collapsed: !this.props.searchstring && isCollapsed,
      'list-view': isListView,
      'is-first': this.props.first,
      'is-last': this.props.last
    })
    classes +=
      ' ' +
      Node.getResolved(this.props.node, this.props.app.state.entities.nodes)
        .type
    classes += ' level-' + this.props.level

    // var nodeItem;
    var nodeItem = (
      <NodeItem
        app={this.props.app}
        node={this.props.node}
        nodes={this.props.nodes}
        rootNode={this.props.rootNode}
        rawTitleNode={this.props.rawTitleNode}
        user={this.props.user}
        org={this.props.org}
        tab={this.props.tab}
        undo={this.props.undo}
        pubUrl={this.props.pubUrl}
        descriptionNode={this.props.descriptionNode}
        dueNode={this.props.dueNode}
        assignNode={this.props.assignNode}
        assignFilter={this.props.assignFilter}
        assignPickerIndex={this.props.assignPickerIndex}
        tmpVisible={this.props.tmpVisible}
        updatedTextNode={this.props.updatedTextNode}
        searchstring={this.props.searchstring}
        getCommonParams={this.props.getCommonParams}
        replaceNodes={this.props.replaceNodes}
        selectedInboxItems={this.props.selectedInboxItems}
        updateAllNodes={this.props.updateAllNodes}
        setAppState={this.props.setAppState}
      />
    )

    var expand
    if (children.length) {
      expand = <span className='expand' onClick={this.toggleChildren} />
    }

    var betweenDropTargets

    if (!this.props.searchstring) {
      if (
        this.props.nextLevel !== null &&
        this.props.nextLevel < this.props.level
      ) {
        betweenDropTargets = []
        for (var i = 0; i < this.props.level - this.props.nextLevel - 1; i++) {
          betweenDropTargets.push(
            <div
              key={'between-' + i}
              data-level={i}
              className={'drop-placeholder between-hover level-' + i}
            />
          )
        }
      }
    }

    var listInfo, pathRow
    if (isListView) {
      var ago = moment(this.props.node.created_at).fromNow()
      listInfo = <div className='list-info'>{ago}</div>

      var maxPathLength = 15
      var path = Node.getPath(
        this.props.node,
        this.props.app.state.entities.nodes
      )
      var pathItems
      if (path.length) {
        var collapsed
        if (path.length > maxPathLength) {
          collapsed = '\u2026'
        }

        var trimmedPathItems = collapsed
          ? path.slice(Math.max(path.length - maxPathLength, 1))
          : path
        var actualPathItems = trimmedPathItems.reverse().map(function (p) {
          return (
            <li key={'node-path-' + p.id}>
              <a href='' onClick={() => _this.props.app.setRoot(p, null)}>
                {p.title}
              </a>
            </li>
          )
        })
        pathItems = collapsed
          ? [
            <li key={'node-path-collapsed-' + this.props.node.id}>
              {collapsed}
              <a href='' />
            </li>
          ].concat(actualPathItems)
          : actualPathItems
        pathRow = (
          <div className='node-info-and-path'>
            {listInfo}
            <ul className='path'>{pathItems}</ul>
          </div>
        )
      }
    }
    var li = (
      <li
        className={classes}
        data-node-id={this.props.node.id}
        data-type={this.props.node.type}
        data-level={this.props.level}
        key={this.props.node.id}
        onMouseDown={this.handleMouseDown}
        onClick={this.handleClick}
      >
        <Stripes
          app={this.props.app}
          prevLevel={this.props.prevLevel}
          level={this.props.level}
          nextLevel={this.props.nextLevel}
        />

        <NodeIndicators
          app={this.props.app}
          node={this.props.node}
          user={this.props.user}
          org={this.props.org}
          tab={this.props.tab}
        />

        <div className='indented'>
          <div className='indent-wrapper'>
            <Bullet
              app={this.props.app}
              node={this.props.node}
              user={this.props.user}
              org={this.props.org}
              tab={this.props.tab}
              setParentState={this.setParentState}
            />
            {expand}
            <div className='node-content'>{nodeItem}</div>
          </div>
          {pathRow}
          {Boolean(betweenDropTargets && betweenDropTargets.length) && (
            <div className='ng-hover betweens'>{betweenDropTargets}</div>
          )}
        </div>
        <NodeMeta
          app={this.props.app}
          node={this.props.node}
          user={this.props.user}
          org={this.props.org}
          tab={this.props.tab}
        />
      </li>
    )

    // console.log(Node.title(this.props.node) + ' was rendered');
    var t1 = new Date().getTime()
    var diff = t1 - t0
    window._app.treeNodeTimer = window._app.treeNodeTimer
      ? window._app.treeNodeTimer + diff
      : diff

    // console.log('rTIME:', t1 - t0);
    return li
  }
}

module.exports = TreeNode
