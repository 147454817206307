import './styles.css'

import React from 'react'

export default class SetPlaceholder extends React.Component {
  render () {
    const content = this.props.children || this.props.text || 'Set'
    return (
      <div className='data-placeholder' onClick={this.props.onClick}>
        {content}
      </div>
    )
  }
}
