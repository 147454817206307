import React from 'react'

import createReactClass from 'create-react-class'

import Misc from '../../../../js/misc'
// import SelectMenu from  '../../../select_menu';
// import Mixins     from  '../../../../mixins/mixins';

// General sections
import ProfileSection from './sections/profile_section'
import SettingsSection from './sections/settings_section'
import PrivacySection from './sections/privacy_section'
import NotificationsSection from './sections/notifications_section'
import FeedbackSection from './sections/feedback_section'
import KeyboardSection from './sections/keyboard_section'

// Appearance section
import ThemesSection from './sections/themes_section'

// Pricing & Billing section
import PlansSection from './sections/plans_section_wrapper'
import BillingHistorySection from './sections/billing_history_section'

// Admin section
import ManageUsersSection from './sections/manage_users_section'
import ManageOrganizationsSection from './sections/manage_organizations_section'
import AnalyticsSection from './sections/analytics_section'

// Development
import DebuggingSection from './sections/debugging_section'
import ErrorsSection from './sections/errors_section'

const sectionTypes = {
  // eslint-disable-line no-unused-vars
  // segment each content type into their own files
  'profile-section': ProfileSection,
  'settings-section': SettingsSection,
  'privacy-section': PrivacySection,
  'notifications-section': NotificationsSection,
  'feedback-section': FeedbackSection,
  'keyboard-section': KeyboardSection,
  'themes-section': ThemesSection,
  'plans-section': PlansSection,
  'billing-history-section': BillingHistorySection,
  'manage-users-section': ManageUsersSection,
  'manage-organizations-section': ManageOrganizationsSection,
  'analytics-section': AnalyticsSection,
  'debugging-section': DebuggingSection,
  'errors-section': ErrorsSection
}

// require('jquery.payment');
require('./styles')

const UserSettingsPage = createReactClass({
  displayName: 'UserSettingsPage',
  // mixins: [Mixins.FormHandling, Mixins.ErrorHandling],

  getInitialState: function () {
    var state = {
      activeSection: 'profile-section',
      ccFormOpen: false,
      message: '',
      error: null,
      invalidFields: [],
      custom_css: this.props.user.settings.custom_css,
      base_color: this.props.user.settings.base_color,
      first_name: this.props.user.first_name,
      last_name: this.props.user.last_name,
      email: this.props.user.email,
      username: this.props.user.username
    }
    return state
  },

  UNSAFE_componentWillMount: function () {
    this.setState({ activeSection: this.props.activeSection })
  },

  closePanel: function () {
    this.props.app.setPanel(null)
  },

  switchSection: function (section) {
    this.setState({ activeSection: section })
  },

  activeSection: function (section) {
    if (this.state.activeSection === section) {
      return 'active'
    } else {
      return ''
    }
  },

  handleColorChange: function (ev, data) {
    var _this = this
    Misc.handleFormFieldChange(this, ev, data, function () {
      _this.saveAppearance()
    })
  },

  selectStylesheet: function (ev) {
    var _this = this
    this.props.app.browseFiles({ fileType: 'text/css' }, function (file) {
      _this.setState({ custom_css: Misc.fileUrl(file) })
    })
    ev.preventDefault()
  },

  saveAppearance: function () {
    var oldCss = this.props.user.settings.custom_css
    var newCss = this.state.custom_css

    if (newCss !== oldCss) {
      if (oldCss) {
        Misc.removeStylesheet(oldCss)
      }

      if (newCss) {
        Misc.addStylesheetOnce(newCss)
      }
    }

    var newSettings = {
      base_color: this.state.base_color,
      custom_css: this.state.custom_css,
      use_gradient: this.useGradient.checked
    }
    this.props.saveUserSettings(newSettings, this.props.app.getCommonParams())
  },

  saveProfile: function () {
    var _this = this

    this.setState({ error: null })
    var data = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      username: this.state.username
    }
    Misc.jx(
      this,
      'update_user',
      data,
      function (res) {
        // eslint-disable-line no-unused-vars
        // TODO: Show global success message
        this.props.app.setState({ user: res.body }, function () {
          _this.setState({ error: null })
        })
      }.bind(this)
    )
  },

  render: function () {
    var section
    if (this.state.activeSection) {
      section = React.createFactory(sectionTypes[this.state.activeSection])(
        this.props
      )
    }

    return (
      <div>
        <div className='heading'>
          <h2>User settings panel</h2>
          <div className='close-settings-page' onClick={this.closePanel}>
            <i className='fa fa-fw fa-close' />
          </div>
        </div>

        <div className='flex-wrapper'>
          <div className='sidebar'>
            <h4>General</h4>
            <ul>
              <li
                onClick={() => this.switchSection('profile-section')}
                className={this.activeSection('profile-section')}
              >
                <i className='fa fa-fw fa-user' />Profile
              </li>
              <li
                onClick={() => this.switchSection('settings-section')}
                className={this.activeSection('settings-section')}
              >
                <i className='fa fa-fw fa-cog' />Settings
              </li>
              {/* <li onClick={() => this.switchSection( 'privacy-section')} className={this.activeSection('privacy-section')}><i className="fa fa-fw fa-shield"></i>Privacy</li> */}
              {/* <li onClick={() => this.switchSection( 'notifications-section')} className={this.activeSection('notifications-section')}><i className="fa fa-fw fa-globe"></i>Notifications</li> */}
              {/* <li onClick={() => this.switchSection( 'feedback-section')} className={this.activeSection('feedback-section')}><i className="fa fa-fw fa-comments"></i>Feedback</li> */}
              <li
                onClick={() => this.switchSection('keyboard-section')}
                className={this.activeSection('keyboard-section')}
              >
                <i className='fa fa-fw fa-keyboard-o' />Keyboard shortcuts
              </li>
            </ul>
            {/*
            <h4>Appearance</h4>
            <ul>
              <li onClick={() => this.switchSection( 'themes-section')} className={this.activeSection('themes-section')}><i className="fa fa-fw fa-hand-lizard-o"></i>Themes</li>
            </ul>
            */}
            <h4>Pricing & billing</h4>
            <ul>
              <li
                onClick={() => this.switchSection('plans-section')}
                className={this.activeSection('plans-section')}
              >
                <i className='fa fa-fw fa-heart' />Plans
              </li>
              <li
                onClick={() => this.switchSection('billing-history-section')}
                className={this.activeSection('billing-history-section')}
              >
                <i className='fa fa-fw fa-credit-card' />Billing history
              </li>
            </ul>

            {/*
            <h4>Admin</h4>
            <ul>
              <li onClick={() => this.switchSection( 'manage-users-section')} className={this.activeSection('manage-users-section')}><i className="fa fa-fw fa-users"></i>Manage users</li>
              <li onClick={() => this.switchSection( 'manage-organizations-section')} className={this.activeSection('manage-organizations-section')}><i className="fa fa-fw fa-users"></i>Manage organizations</li>
              <li onClick={() => this.switchSection( 'analytics-section')} className={this.activeSection('analytics-section')}><i className="fa fa-fw fa-bar-chart"></i>Analytics</li>
            </ul>
            */}

            {this.props.app.state._dev && (
              <div>
                <h4>Development</h4>
                <ul>
                  <li
                    onClick={() => this.switchSection('debugging-section')}
                    className={this.activeSection('debugging-section')}
                  >
                    <i className='fa fa-fw fa-flask' />Debugging
                  </li>
                  <li
                    onClick={() => this.switchSection('errors-section')}
                    className={this.activeSection('errors-section')}
                  >
                    <i className='fa fa-fw fa-code' />Errors
                  </li>
                </ul>
              </div>
            )}
          </div>
          <div className='content'>{section}</div>
        </div>
      </div>
    )
  }
})

module.exports = UserSettingsPage
