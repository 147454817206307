import 'medium-editor/dist/css/medium-editor.css'
import 'medium-editor/dist/css/themes/default.css'
// import 'medium-editor-insert-plugin/dist/css/medium-editor-insert.min.css'

import React from 'react'
import PropTypes from 'prop-types'

import MediumEditor from '../medium_editor'

// var MediumEditorInsert = require('../../node_modules/medium-editor-insert-plugin/dist/js/medium-editor-insert.js');

class WysiwygEditor extends React.Component {
  static propTypes = {
    editText: PropTypes.string.isRequired,
    handleChange: PropTypes.func
  }
  render () {
    // var editorOptions = {
    //   toolbar: {
    //     buttons: [
    //       'bold',
    //       'italic',
    //       'underline',
    //     ]
    //   }
    // };
    var editorOptions = {
      // extensions: {
      //   insert: new MediumEditorInsert(),
      // },
    }
    return (
      <MediumEditor
        text={this.props.editText}
        onChange={this.props.handleChange}
        options={editorOptions}
      />
    )
  }
}

module.exports = WysiwygEditor
