import React from 'react'

import RegisterForm from '../register_form'
import LoginForm from '../login_form'

import Misc from '../../js/misc'

class SpecialPages extends React.Component {
  constructor (props) {
    super(props)
    var initialMode = props.mode

    this.state = {
      mode: initialMode,
      message: ''
    }
    var inviteMatch = window.location.search.match(/[?&]i=([^?&]+)/)
    if (inviteMatch && inviteMatch[1]) {
      this.state.inviteCode = inviteMatch[1]
      this.state.emailCode = true
    }
  }

  switchMode = (mode, ev) => {
    this.setState({ mode: mode })
    ev && ev.preventDefault && ev.preventDefault()
  }

  login = loginData => {
    var _this = this
    this.props.app.login(loginData.username, loginData.password, {}, function (
      res
    ) {
      if (res.ok) {
        if (res.body.error) {
          _this.setState({ message: res.body.error.message })
        } else {
          _this.setState({ message: '' })
          _this.props.app.setUser(res.body.user, res.body.token, function () {
            if (
              _this.state.mode === 'authForOrgInvite' ||
              _this.state.mode === 'orgInvite'
            ) {
              _this.acceptOrgInvite()
            } else if (
              _this.state.mode === 'authForOrgRequest' ||
              _this.state.mode === 'orgRequest'
            ) {
              _this.requestOrgMembership()
            }
          })
        }
      } else {
        _this.setState({
          message:
            'There was a problem communicating with the server, please try again later.'
        })
      }
    })
  }

  register = userData => {
    var _this = this
    // TODO: this looks awfully similar to the login call, DRY it up

    this.props.app.register(
      userData,
      { orgRequestInviteId: this.props.params.org.invite_id },
      function (res) {
        if (res.ok) {
          if (res.body.error) {
            _this.setState({ message: res.body.error.message })
          } else {
            _this.setState({ message: '' })
            _this.props.app.setUser(res.body.user, res.body.token, function () {
              if (_this.state.mode === 'registerForOrgInvite') {
                _this.acceptOrgInvite()
              } else if (_this.state.mode === 'registerForOrgRequest') {
                _this.requestOrgMembership()
              }
            })
          }
        } else {
          _this.setState({
            message:
              'There was a problem communicating with the server, please try again later.'
          })
        }
      }
    )
  }

  yesToInvite = () => {
    var user = this.props.params.user || this.props.user
    var invite = this.props.params.invite
    if (user && invite.username && user.username === invite.username) {
      // go straight to accept
      this.acceptOrgInvite()
    } else {
      this.switchMode('orgInviteOpts')
    }
  }

  acceptOrgInvite = () => {
    var _this = this
    var user = this.props.params.user || this.props.user
    if (user && this.props.params.org) {
      var data = {
        invite_id: this.props.params.invite.id
      }
      Misc.jx(this, 'accept_org_invite', data, function (res) {
        // eslint-disable-line no-unused-vars
        // TODO: Show global success message
        _this.switchMode('orgInviteAccepted')
      })
    }
  }

  declineOrgInvite = () => {
    var _this = this
    if (this.props.params.invite) {
      var data = {
        invite_id: this.props.params.invite.id
      }
      Misc.jx(this, 'decline_org_invite', data, function (res) {
        // eslint-disable-line no-unused-vars
        // TODO: Show global success message
        _this.switchMode('orgInviteDeclined')
      })
    }
  }

  requestOrgMembership = () => {
    var user = this.props.params.user || this.props.user
    if (user && this.props.params.org) {
      var data = {
        user_id: user.id,
        invite_id: this.props.params.org.invite_id
      }
      Misc.jx(this, 'register_org_request', data, function (res) {
        // eslint-disable-line no-unused-vars
        // TODO: Show global success message
        window.location.href = '/'
      })
    }
  }

  render () {
    var content, intro, username, useExisting, loginText, registerText
    var user = this.props.params.user || this.props.user
    var invite = this.props.params.invite
    var org = this.props.params.org
    if (['authForShare', 'registerForShare'].indexOf(this.state.mode) !== -1) {
      if (this.props.params && this.props.params.username) {
        username = this.props.params.username
        intro = (
          <p>
            Someone has shared a private Notebase item with you. You need to log
            in as <strong>{username}</strong> to access it.
          </p>
        )
      } else {
        intro = (
          <p>
            Someone has shared a private <strong>Notebase</strong> item with
            you. You need to log in or register to access it.
          </p>
        )
      }
    } else if (
      ['orgInvite', 'authForOrgInvite', 'registerForOrgInvite'].indexOf(
        this.state.mode
      ) !== -1
    ) {
      intro = (
        <p>
          {invite.inviter.first_name} {invite.inviter.last_name} has invited you
          to join the {org.name} organization.
        </p>
      )
    } else if (
      ['orgRequest', 'authForOrgRequest', 'registerForOrgRequest'].indexOf(
        this.state.mode
      ) !== -1
    ) {
      var reqHeading = (
        <h3>Request to join the {this.props.params.org.name} organization</h3>
      )
      var reqCurrInfo
      if (user) {
        reqCurrInfo = (
          <span>
            You are currently logged in as <strong>{user.username}</strong>.
          </span>
        )
      }
      intro = (
        <div>
          {reqHeading}
          <p>
            You need to be logged in as a Notebase user before you can request
            membership to an organization. {reqCurrInfo}
          </p>
        </div>
      )
    }
    var disclaimer = (
      <div>
        <p className='fine-print'>
          By registering you agree to be bound by the{' '}
          <a href='/terms' target='_blank'>
            Notebase Terms of Service
          </a>
        </p>
        <p className='ps'>
          PS! Notebase is currently in a limited Beta phase, so expect bugs and
          don’t use it for anything super important yet, but feel free to check
          it out.
        </p>
      </div>
    )
    switch (this.state.mode) {
      case 'authForShare':
        var registerPart
        if (
          !this.props.app.state.specialActionParams ||
          !this.props.app.state.specialActionParams.username
        ) {
          registerPart = (
            <div className='register-link'>
              Or{' '}
              <a href='' onClick={this.toggleMode}>
                register a new user
              </a>.
            </div>
          )
        }
        content = (
          <div>
            <LoginForm app={this.props.app} onSubmit={this.login} />
            <span className='notification'>{this.state.message}</span>
            {registerPart}
          </div>
        )
        break
      case 'registerForShare':
        content = (
          <div>
            <RegisterForm app={this.props.app} onSubmit={this.register} />
            <span className='notification'>{this.state.message}</span>
            <div>
              <a href='' onClick={this.toggleMode}>
                Cancel
              </a>.
            </div>
            {disclaimer}
          </div>
        )
        break
      case 'orgInvite':
        content = (
          <div>
            <h3>Accept invitation?</h3>
            <div className='huge-buttons'>
              <button className='btn dark huge' onClick={this.yesToInvite}>
                Yes
              </button>
              <button className='btn dark huge' onClick={this.declineOrgInvite}>
                No
              </button>
            </div>
          </div>
        )
        break
      case 'orgInviteOpts':
        var currentUserInfo
        if (invite.username && (!user || user.username !== invite.username)) {
          if (user) {
            currentUserInfo = (
              <span>
                You are currently logged in as {'"'}
                {user.username}
                {'"'}.
              </span>
            )
          }
          content = (
            <div>
              <p>
                The invitation was sent specifically to{' '}
                <strong>{invite.username}</strong>, so you need to log in with
                that username to accept the invitation. {currentUserInfo}
              </p>
              <LoginForm
                app={this.props.app}
                onSubmit={this.login}
                readonlyUsername={invite.username}
                buttonText='Login and accept invitation'
              />
              <span className='notification'>{this.state.message}</span>
              <div className='register-link'>
                <a href='' onClick={() => this.switchMode('orgInvite')}>
                  Cancel
                </a>.
              </div>
            </div>
          )
        } else {
          loginText = 'I already have a Notebase user'
          registerText = 'I want to create a Notebase user now'
          if (user) {
            useExisting = (
              <button
                className='btn dark huge'
                onClick={this.requestOrgMembership}
              >
                Use {user.username}
              </button>
            )
            loginText = 'Log in as a different user'
            registerText = 'Create another user'
          }
          content = (
            <div className='huge-buttons'>
              <p>
                You need a valid Notebase account to accept the invitation. Pick
                one:
              </p>
              {useExisting}
              <button
                className='btn dark huge'
                onClick={() => this.switchMode('authForOrgInvite')}
              >
                {loginText}
              </button>
              <button
                className='btn dark huge'
                onClick={() => this.switchMode('registerForOrgInvite')}
              >
                {registerText}
              </button>
              <div className='register-link'>
                <a href='' onClick={() => this.switchMode('orgInvite')}>
                  Cancel
                </a>.
              </div>
            </div>
          )
        }
        break
      case 'orgInviteAccepted':
        content = (
          <div>
            <p>
              The invitation has been accepted. <a href='/'>Click here</a> to
              open Notebase.
            </p>
          </div>
        )
        break
      case 'orgInviteDeclined':
        content = (
          <div>
            <p>
              The invitation has been declined. You may close this tab/window
              now if you want.
            </p>
          </div>
        )
        break
      case 'authForOrgInvite':
        content = (
          <div>
            <LoginForm
              app={this.props.app}
              onSubmit={this.login}
              buttonText='Login and accept invitation'
            />
            <span className='notification'>{this.state.message}</span>
            <div className='register-link'>
              <a href='' onClick={() => this.switchMode('orgInvite')}>
                Cancel
              </a>. Or{' '}
              <a
                href=''
                onClick={() => this.switchMode('registerForOrgInvite')}
              >
                register a new user
              </a>.
            </div>
          </div>
        )
        break
      case 'registerForOrgInvite':
        content = (
          <div>
            <RegisterForm
              app={this.props.app}
              onSubmit={this.register}
              noInviteField
              buttonText='Register and accept invitation'
            />
            <span className='notification'>{this.state.message}</span>
            <div className='register-link'>
              <a href='' onClick={() => this.switchMode('orgInvite')}>
                Cancel
              </a>. Or{' '}
              <a href='' onClick={() => this.switchMode('authForOrgInvite')}>
                log in
              </a>{' '}
              if you already have a user.
            </div>
          </div>
        )
        break
      case 'orgRequest':
        loginText = 'I already have a Notebase user'
        registerText = 'I want to create a Notebase user now'
        if (user) {
          useExisting = (
            <button
              className='btn dark huge'
              onClick={this.requestOrgMembership}
            >
              Use {user.username}
            </button>
          )
          loginText = 'Log in as a different user'
          registerText = 'Create another user'
        }
        content = (
          <div className='huge-buttons'>
            {useExisting}
            <button
              className='btn dark huge'
              onClick={() => this.switchMode('authForOrgRequest')}
            >
              {loginText}
            </button>
            <button
              className='btn dark huge'
              onClick={() => this.switchMode('registerForOrgRequest')}
            >
              {registerText}
            </button>
          </div>
        )
        break
      case 'authForOrgRequest':
        content = (
          <div>
            <LoginForm
              app={this.props.app}
              onSubmit={this.login}
              buttonText='Login and request membership'
            />
            <span className='notification'>{this.state.message}</span>
            <div className='register-link'>
              <a href='' onClick={() => this.switchMode('orgRequest')}>
                Cancel
              </a>. Or{' '}
              <a
                href=''
                onClick={() => this.switchMode('registerForOrgRequest')}
              >
                register a new user
              </a>.
            </div>
          </div>
        )
        break
      case 'registerForOrgRequest':
        content = (
          <div>
            <RegisterForm
              app={this.props.app}
              onSubmit={this.register}
              noInviteField
              buttonText='Register and request membership'
            />
            <span className='notification'>{this.state.message}</span>
            <div className='register-link'>
              <a href='' onClick={() => this.switchMode('orgRequest')}>
                Cancel
              </a>. Or{' '}
              <a href='' onClick={() => this.switchMode('authForOrgRequest')}>
                log in
              </a>{' '}
              if you already have a user.
            </div>
          </div>
        )
        break
    }
    return (
      <div id='user-stuff'>
        <div>
          <div id='hp-bg' />
          <div id='hp-overlay' />
          {intro}
        </div>
        <div id='homepage' className='special-actions'>
          {content}
        </div>
      </div>
    )
  }
}

module.exports = SpecialPages
