import React from 'react'

import './styles.css'

export default class LookAndFeel extends React.Component {
  render () {
    const { app } = this.props
    return (
      <div className='look-and-feel'>
        <button className='' onClick={() => app.pushSidebar('background')}>
          Set Background
        </button>
      </div>
    )
  }
}
