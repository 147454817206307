import React from 'react'

import createReactClass from 'create-react-class'

import Misc from '../../../js/misc'

const EmbeddedOverlay = createReactClass({
  displayName: 'EmbeddedOverlay',

  getInitialState: function () {
    return {
      error: null,
      loading: true,
      embedShare: null,
      embedUser: null
    }
  },

  UNSAFE_componentWillMount: function () {
    // this.handleFormFieldChange = Misc.handleFormFieldChange.bind(this, this);
    this.errorFor = Misc.errorFor.bind(this, this)
    var node = this.props.panel.opts.data.node
    this.props.app.ajax('embed_info', { body: { node_id: node.id } }, json => {
      this.setState({
        embedShare: json.share,
        embedUser: json.user,
        loading: false
      })
    })
  },

  close: function () {
    this.props.app.setPanel(null)
  },

  render: function () {
    var content
    if (this.state.loading && this.state.error) {
      // No need to add anything, the error is output further down
    } else if (this.state.loading) {
      content = (
        <div className='loading'>
          <div className='working-indicator'>
            <div className='rect1' />
            <div className='rect2' />
            <div className='rect3' />
            <div className='rect4' />
            <div className='rect5' />
          </div>
          <div className='info'>Loading embed info from server...</div>
        </div>
      )
    } else {
      if (this.state.embedShare && this.state.embedUser) {
        content = (
          <div>
            <div className='row'>
              Shared as{' '}
              <strong>
                {'"'}
                {this.state.embedShare.title}
                {'"'}
              </strong>{' '}
              by <strong>{this.state.embedUser.username}</strong> on{' '}
              {this.state.embedShare.created_at}.
            </div>
            <div className='buttons'>
              <button onClick={this.close} className='button default'>
                Close
              </button>
            </div>
          </div>
        )
      }
    }
    return (
      <div id='embedded'>
        {this.errorFor()}
        {content}
      </div>
    )
  }
})

module.exports = EmbeddedOverlay
