import './styles.css'

import React from 'react'

import Node from '../../../../../js/node'

import Location from '../data_types/location'
import EventAt from '../data_types/event_at'
import EventEnd from '../data_types/event_end'
import People from '../data_types/people'
import Duration from '../data_types/duration'
import Priority from '../data_types/priority'
import StoryPoints from '../data_types/story_points'
import JobTitle from '../data_types/job_title'
import Company from '../data_types/company'
import Phones from '../data_types/phones'
import Emails from '../data_types/emails'
import Url from '../data_types/url'

const typeMappings = {
  location: Location,
  event_at: EventAt,
  event_end: EventEnd,
  people: People,
  duration: Duration,
  priority: Priority,
  story_points: StoryPoints,
  job_title: JobTitle,
  company: Company,
  phones: Phones,
  emails: Emails,
  url: Url
}

export default class Shared extends React.Component {
  state = {
    adding: null
  }

  handlePersonPick = person => {
    console.log('trying to add', person)
    const { app, node } = this.props
    const oldData = node.data || {}
    const people = oldData.people || {}
    let collection = people[person.collection] || []
    const existingIdx =
      person.collection === 'others'
        ? person.data.hasOwnProperty('_idx')
          ? person.data._idx
          : -1
        : collection.indexOf(person.data.id)
    if (existingIdx === -1) {
      const dataToAdd =
        person.collection === 'others' ? person.data : person.data.id
      if (dataToAdd) {
        collection = collection.concat([dataToAdd])
      }
    } else {
      collection = collection
        .slice(0, existingIdx)
        .concat(collection.slice(existingIdx + 1))
    }
    people[person.collection] = collection
    const data = { ...oldData, people }
    Node.modify(node.id, { data }, app.getCommonParams())
  }

  toggleFunc = type => {
    const { app, node } = this.props
    let mode
    let picker = `${type}-picker`
    let field = `data.${type}`
    let onPick
    switch (type) {
      case 'event_at':
        mode = 'node-data-date-field'
        field = 'event_at'
        picker = 'date-picker'
        break
      case 'event_end':
        mode = 'node-data-date-field'
        picker = 'date-picker'
        break
      case 'job_title':
        picker = 'text-field-picker'
        break
      case 'people':
        onPick = this.handlePersonPick
        break
    }
    return ev =>
      app.toggleFloater(ev.target, picker, {
        mode,
        field,
        onPick,
        nodeId: node.id
      })
  }

  render () {
    const { app, node } = this.props
    const data = node.data || {}
    const types = []
    app.state.allDataTypes.forEach(type => {
      const includedTypes =
        app.state.includedDataTypesForNodeType[node.type] || []
      if (
        (data[type] || this.props.adding === type) &&
        includedTypes.indexOf(type) === -1
      ) {
        const Element = typeMappings[type]
        types.push(
          <div key={type}>
            <div>
              <i className={`fa fa-fw fa-${app.getDataTypeIcon(type)}`} />
              <span
                className='data-unset'
                onClick={() => app.unsetNodeData(node, type)}
              >
                <i className='fa fa-fw fa-times' />
              </span>
            </div>
            <div>
              <div>
                <div onClick={this.toggleFunc(type)}>
                  <span className='title'>{app.getDataTypeTitle(type)}</span>
                  <span className='edit'>
                    <i className='fa fa-fw fa-pencil' />
                  </span>
                </div>
              </div>
              <Element
                {...this.props}
                handlePersonPick={
                  type === 'people' ? this.handlePersonPick : null
                }
              />
            </div>
          </div>
        )
      }
    })
    return <div className='shared-data'>{types}</div>
  }
}
