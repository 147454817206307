import React from 'react'
import $ from 'jquery'
import _ from 'lodash'

import Node from '../../js/node'
import Tab from '../../js/tab'
import Keys from '../../js/keys'
import Text from '../../js/text'

class NodeItem extends React.Component {
  state = {
    titleText: Node.title(this.props.node),
    description: this.props.node.description,
    descriptionNode: null,
    local: false
  }

  toggleExpansion = () => {
    const { app } = this.props
    const tab = app.tab()
    const tabSettings = tab.settings || {}
    Tab.modify(
      tab,
      {
        settings: {
          ...tabSettings,
          expand_labels: !tabSettings.expand_labels
        }
      },
      app.getCommonParams(),
      () => {
        app.setAppState({ updateAllNodes: true })
      }
    )
  }

  toggleFilter = label => {
    const { app } = this.props
    const oldFilter = app.state.filter || {}
    const oldLabels = oldFilter.labels || []
    const newLabels =
      oldLabels.indexOf(label.id) === -1
        ? oldLabels.concat([label.id])
        : oldLabels.filter(l => l !== label.id)
    app.setAppState({ filter: { ...oldFilter, labels: newLabels } })
  }

  getKeyParams = () => {
    return _.extend(this.props.getCommonParams(), {
      setTitle: this.setTitle,
      setDescription: this.setDescription
    })
  }

  handleContainerClick = ev => {
    var el = $(ev.target)
    this.props.setAppState({ hasMoved: true }, () => {
      if (el.hasClass('title-container')) {
        el.find('.title').focus()
      }
    })
  }

  handleTitleKeyDown = ev => {
    Keys.handleTitleKeyDown(ev, this.props.node, this.getKeyParams())
  }

  handleTitleKeyUp = ev => {
    this.props.app.setLastUserAction()
    Keys.handleTitleKeyUp(ev, this.props.node, this.getKeyParams())
  }

  handleTitleFocus = ev => {
    this.props.app.setLastUserAction()
    var div = $(ev.target)
    Text.saveSelection(div.get(0))
    // if (this.props.app.featureEnabled('rich_titles')) {
    //   window._app.original_title = div.html()
    // } else {
    window._app.original_title = Node.parseHtml(div.text())
    // }
    window._app.activeNode = this.props.node.id
    // this.props.setAppState({activeNodeId: this.props.node.id, activeNodeOnly: true});
    clearTimeout(window._app.titleFocusTimeout)
    window._app.titleFocusTimeout = setTimeout(() => {
      this.props.setAppState({
        activeNodeId: this.props.node.id,
        activeNodeOnly: true
      })
    }, 200)
  }

  handleTitleBlur = ev => {
    if (!window._app.automatedMode) {
      var div = $(ev.target)
      if (!window._app.skipTitleBlur) {
        Node.flushTextUndo(
          { el: div, node: this.props.node },
          this.props.getCommonParams()
        )
      }
      window._app.skipTitleBlur = false
    }
  }

  handleDescriptionBlur = ev => {
    if (!window._app.automatedMode) {
      var div = $(ev.target)
      if (!window._app.skipDescriptionBlur) {
        Node.flushTextUndo(
          { el: div, node: this.props.node },
          this.props.getCommonParams()
        )
      }
      window._app.skipDescriptionBlur = false
    }
  }

  handleDescriptionKeyDown = ev => {
    Keys.handleDescriptionKeyDown(ev, this.props.node, this.getKeyParams())
  }

  handleDescriptionKeyUp = ev => {
    this.props.app.setLastUserAction()
    Keys.handleDescriptionKeyUp(ev, this.props.node, this.getKeyParams())
  }

  handleDescriptionFocus = ev => {
    this.props.app.setLastUserAction()
    var div = $(ev.target)
    window._app.original_description = Node.parseHtml(div.text())
    window._app.activeNode = this.props.node.id
    clearTimeout(window._app.titleFocusTimeout)
    window._app.titleFocusTimeout = setTimeout(() => {
      this.props.setAppState({
        activeNodeId: this.props.node.id,
        activeNodeOnly: true
      })
    }, 200)
  }

  handleDescPlaceholderClick = () => {
    this.props.app.setLastUserAction()
    this.props.app.toggleDescription(this.props.node.id)
    window._app.original_description = ''
    window._app.activeNode = this.props.node.id
    clearTimeout(window._app.titleFocusTimeout)
    window._app.titleFocusTimeout = setTimeout(() => {
      this.props.setAppState({
        activeNodeId: this.props.node.id,
        activeNodeOnly: true
      })
    }, 200)
  }

  handlePaste = ev => {
    var pastedText
    if (window.clipboardData && window.clipboardData.getData) {
      // IE
      pastedText = window.clipboardData.getData('Text')
    } else if (ev.clipboardData && ev.clipboardData.getData) {
      // console.log('data:', ev.clipboardData.getData('text/html'));
      pastedText = ev.clipboardData.getData('text/plain')
    }

    const commonParams = this.props.app.getCommonParams()
    commonParams._skipFocus = true
    Node.flushTextUndo({}, commonParams, () => {
      var el = $(this.node).find('[contenteditable]')
      if (el && el[0]) {
        Text.replace(
          el[0],
          pastedText,
          this.props.app.featureEnabled('rich_titles')
        )
      }
      // TODO: Do something useful with multi-line paste data
    })

    ev.preventDefault()
  }

  handleBookmarkClick = ev => {
    if (this.props.node && this.props.node.type === 'bookmark') {
      ev.preventDefault()
      window.open(this.props.node.url, '_blank')
    }
  }

  expandOrCollapse = ev => {
    ev.preventDefault()
    if (ev.shiftKey) {
      Node.recursiveToggle(this.props.node, this.props.app.getCommonParams())
    }
  }

  setTitle = newTitle => {
    this.setState({ titleText: newTitle, local: true })
  }

  setDescription = newDescription => {
    this.setState({ description: newDescription, local: true })
  }

  toggleCheckbox = e => {
    var curr = this.props.app.state.selectedInboxItems
    var updatedList
    var idx = curr.indexOf(this.props.node.id)
    if (idx === -1) {
      updatedList = curr.concat([this.props.node.id])
    } else {
      updatedList = curr.slice()
      updatedList.splice(idx, 1)
    }

    this.props.app.setState({ selectedInboxItems: updatedList })
    e.stopPropagation()
  }

  deleteBookmark = e => {
    Node.remove(this.props.node.id, this.props.app.getCommonParams())
    // if (prevLi.length) {
    //   Node.focus(prevLi.data('node-id'));
    // } else if (nextLi.length) {
    //   Node.focus(nextLi.data('node-id'));
    // }
    e.preventDefault()
  }

  titleContent = () => {
    var title

    // var titleText = Node.title(Node.getResolved(this.props.node, this.props.nodes), this.props.searchstring);
    var node = this.props.node
    var titleText
    if (
      !this.props.app.featureEnabled('rich_titles') ||
      this.props.app.state.rawTitleNodeId === node.id
    ) {
      titleText = Node.rawTitle(node, this.props.searchstring)
    } else {
      titleText = Node.title(node, this.props.searchstring)
    }
    var urlText = titleText
      .replace(Keys.urlRegex, Keys.urlReplacer)
      .replace(/\s$/, '&nbsp;')
      .replace(/;$/, '')
    title = urlText
      .replace(Keys.linkRegex, Keys.linkReplacer)
      .replace(/\s$/, '&nbsp;')

    return title
    // if (node._) {
    //   return '[' + node._.access + '] ' + title;
    // } else {
    //   return title;
    // }
  }

  descriptionContent = () => {
    var urlText = Node.description(this.props.node, this.props.searchstring)
      .replace(Keys.urlRegex, Keys.urlReplacer)
      .replace(/\s$/, '&nbsp;')
      .replace(/;$/, '')
    return urlText
      .replace(Keys.linkRegex, Keys.linkReplacer)
      .replace(/\s$/, '&nbsp;')
  }

  // eslint-disable-next-line no-unused-vars,camelcase
  UNSAFE_componentWillReceiveProps (nextProps) {
    this.setState({ local: false })
  }

  componentDidMount () {
    if (this.title) {
      this.title.innerHTML = this.titleContent()
    }

    if (this.desc) {
      this.desc.innerHTML = this.descriptionContent()
    }
  }

  componentDidUpdate (prevProps, prevState) {
    // eslint-disable-line no-unused-vars
    if (this.props.updatedTextNode !== this.props.node.id) {
      if (this.title) {
        this.title.innerHTML = this.titleContent()
      }

      if (this.desc) {
        this.desc.innerHTML = this.descriptionContent()
      }
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    // console.log('nextState.local', nextState.local);
    if (nextProps.updateAllNodes && !nextState.local) {
      // console.log('updating because updateAllNodes');
      return true
    }
    if (
      nextProps.node === this.props.node &&
      nextProps.descriptionNode === this.props.descriptionNode &&
      nextProps.rawTitleNode === this.props.rawTitleNode &&
      nextProps.dueNode === this.props.dueNode &&
      nextProps.assignNode === this.props.assignNode &&
      nextProps.assignFilter === this.props.assignFilter &&
      nextProps.assignPickerIndex === this.props.assignPickerIndex &&
      nextProps.selectedInboxItems === this.props.selectedInboxItems &&
      nextProps.searchstring === this.props.searchstring
    ) {
      return false
    }

    if (nextProps.node.type !== 'bookmark' && nextProps.node.type !== '_ref') {
      if (nextState.local) {
        return false
      }

      if (
        nextProps.node.title === nextState.titleText &&
        nextProps.updatedTextNode &&
        nextProps.updatedTextNode === nextProps.node.id
      ) {
        return false
      }
    }

    // console.log('Just updating because');
    return true
  }

  render () {
    const { app, node, tab } = this.props
    const tabSettings = tab.settings || {}

    var classes = 'title formatable ' + this.props.node.type
    var url, description, descriptionPlaceholder, mainDiv, link, pathRow, labels

    if (node.outbound_link_count) {
      // ↩
      link = <i className='fa fa-fw fa-link node-link' />
    }

    if (
      (this.props.node.description && this.props.node.description !== '') ||
      this.props.descriptionNode === this.props.node.id
    ) {
      description = (
        <div>
          <div
            ref={desc => {
              this.desc = desc
            }}
            className='description'
            contentEditable='true'
            onFocus={this.handleDescriptionFocus}
            onBlur={this.handleDescriptionBlur}
            onClick={this.handleDescriptionClick}
            onKeyDown={this.handleDescriptionKeyDown}
            onKeyUp={this.handleDescriptionKeyUp}
          />
        </div>
      )
    } else if (this.props.node.inbox) {
      descriptionPlaceholder = (
        <div
          className='desc-placeholder'
          onClick={this.handleDescPlaceholderClick}
        >
          Click to add tags/description
        </div>
      )
    }

    if (node.settings.labels && node.settings.labels.length) {
      const overrides =
        (app.orgId()
          ? app.org().settings.label_overrides
          : app.user().settings.label_overrides) || {}
      labels = (
        <div className='inline-labels-container'>
          <em className='expander' onClick={this.toggleExpansion}>
            <i
              className={`fa fa-fw fa-${
                tabSettings.expand_labels ? 'toggle-up' : 'toggle-down'
              }`}
            />
          </em>
          <div className='inline-labels'>
            {node.settings.labels.map(id => {
              const defaultLabel = app.state.defaultLabels.find(
                l => l.id === id
              )
              const override = overrides[id]
              const label = { ...defaultLabel, ...override }
              return (
                <span
                  key={id}
                  className='label'
                  style={{ backgroundColor: label.bg }}
                  onClick={() => this.toggleFilter(label)}
                >
                  {tabSettings.expand_labels && (
                    <span>{label.name || '\xa0'}</span>
                  )}
                  {/* HACH: Not sure why we need this, but the labels are pushed down a few pixels if we don't have a span with a NBSP in it */}
                  {!tabSettings.expand_labels && <span>{'\xa0'}</span>}
                </span>
              )
            })}
          </div>
        </div>
      )
    }
    var extras
    if (this.props.node.type === 'bookmark') {
      classes += ' url-title'
      url = (
        <div className='url'>
          {Node.getResolved(this.props.node, this.props.nodes).url}
        </div>
      )
      if (this.props.node.inbox) {
        var extraClasses = 'extras'
        if (
          this.props.app.state.selectedInboxItems.indexOf(
            this.props.node.id
          ) !== -1
        ) {
          extraClasses += ' on'
        }

        extras = (
          <div className={extraClasses}>
            <i
              className='icon delete fa fa-fw fa-trash'
              onClick={this.deleteBookmark}
            />
            <input type='checkbox' onClick={this.toggleCheckbox} />
          </div>
        )
      }
    } else if (this.props.node.type === 'site') {
      url = (
        <div className='url site'>
          <a
            href={this.props.pubUrl(this.props.node)}
            target='_blank'
            rel='noopener noreferrer'
          >
            {this.props.pubUrl(this.props.node)}
          </a>
        </div>
      )
    }

    mainDiv = (
      <div className='title-container' onClick={this.handleContainerClick}>
        <div
          ref={title => {
            this.title = title
          }}
          className={classes}
          contentEditable={this.props.dragged ? 'false' : 'true'}
          onFocus={this.handleTitleFocus}
          onMouseUp={this.handleTitleFocus}
          onBlur={this.handleTitleBlur}
          onDoubleClick={this.expandOrCollapse}
          onClick={this.handleBookmarkClick}
          onKeyDown={this.handleTitleKeyDown}
          onKeyUp={this.handleTitleKeyUp}
          onPaste={this.handlePaste}
        />
        {link}
        {extras}
      </div>
    )

    return (
      <div
        ref={node => {
          this.node = node
        }}
      >
        {mainDiv}
        {url}
        {descriptionPlaceholder}
        {description}
        {labels}
        {pathRow}
      </div>
    )
  }
}

module.exports = NodeItem
