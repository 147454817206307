module.exports = {

  get: function (groupOrId, groups) {
    groups = groups || []
    if (typeof groupOrId === 'string') {
      return groups.find(g => { return g.id === groupOrId })
    } else {
      return groupOrId
    }
  },

  users: function (groupOrId, params) {
    const entities = (params.updatedState && params.updatedState.entities) ? params.updatedState.entities : (params.state ? params.state.entities : params.entities)
    const group = this.get(groupOrId, entities.groups)
    if (group) {
      const groupUsers = entities.group_users.filter(gu => { return gu.group_id === group.id })
      const userIds = groupUsers.map(gu => { return gu.user_id })
      return entities.users.filter(u => { return userIds.indexOf(u.id) !== -1 })
    } else {
      return []
    }
  }

}
