import './styles.css'

import React from 'react'
import _ from 'lodash'
import Cookies from 'cookies-js'
import $ from 'jquery'

import constants from '../../../..//lib/constants'

class UserMenu extends React.Component {
  state = {
    error: null,
    loading: true,
    currentShare: null,
    defaultShare: {
      enabled: true,
      type: 'link',
      access_level: constants.READ_ACCESS,
      users: []
    }
  }

  componentDidMount () {
    const _this = this
    $(document).bind('click.userMenu', ev => {
      const list = $(ev.target).closest('#user-menu ul')
      if (!list.length) {
        if (_this.props.panel) {
          this.props.app.setPanel(null)
        }
      }
    })
  }

  componentWillUnmount () {
    $(document).unbind('click.userMenu')
  }

  goToUrl = (url, target) => {
    window.open(url, target)
  }

  logout = () => {
    window.sessionStorage.removeItem('authToken')
    window.localStorage.removeItem('authToken')
    Cookies.expire('authToken')
    this.props.app.disconnectSocket()

    // this.props.setUser(null);
    this.props.app.authenticated = false
    var freshState = this.props.app.getInitialState()
    this.props.app.setState(
      _.extend(freshState, { loaded: true, loading: false })
    )
    history.pushState({}, '', '/')
  }

  invite = () => {
    this.props.app.setPanel('invite-beta-overlay', {
      data: { user: this.props.user },
      title: 'Invite your friends',
      activeSection: 'send'
    })
  }

  toggleSettings = () => {
    this.props.app.setPanel('user-settings-page', {
      data: { user: this.props.user },
      title: 'User settings',
      activeSection: 'settings-section'
    })
  }

  toggleProfile = () => {
    this.props.app.setPanel('user-settings-page', {
      data: { user: this.props.user },
      title: 'User settings',
      activeSection: 'profile-section'
    })
  }

  toggleShares = () => {
    this.props.app.setPanel('shares-overlay', {
      data: { user: this.props.user },
      title: 'Shared with me'
    })
  }

  toggleOrgs = () => {
    this.props.app.setPanel('organizations-page', {
      data: { user: this.props.user },
      title: 'Manage organizations',
      activeSection: 'settings-section'
    })
  }

  toggleKeyHelp = ev => {
    this.props.app.setPanel('user-settings-page', {
      data: { user: this.props.user },
      title: 'User settings',
      activeSection: 'keyboard-section'
    })

    window.Intercom && window.Intercom('trackEvent', 'toggled-key-help')
    if (ev && ev.preventDefault) {
      ev.preventDefault()
    }
  }

  openAdmin = () => {
    this.props.app.setPanel(null)
    this.goToUrl('/admin', '_blank')
  }

  render () {
    var admin
    if (
      this.props.user &&
      this.props.user.user_level &&
      this.props.user.user_level === 1000
    ) {
      admin = <li onClick={this.openAdmin}>Admin</li>
    }

    var invite
    if (this.props.user.invites) {
      invite = <li onClick={this.invite}>Invite your friends</li>
    }

    // if (editNode) {
    //   var mdActive = appState.expandMarkdownHelp ? 'active' : '';
    //   mdHelp = (
    //     <div className={mdActive} onClick={this.props.app.toggleMarkdownHelp}>
    //       <span className="icon octicon octicon-markdown"></span>
    //       <span className="label">Markdown Help</span>
    //     </div>
    //   );
    // }

    return (
      <div className='menu-panel' id='user-menu'>
        <ul>
          <li onClick={this.toggleProfile}>Profile (mv)</li>
          <li onClick={this.toggleSettings}>Settings (mv)</li>
          <li onClick={this.toggleShares}>Shared with me (mv)</li>
          <li
            onClick={() => {
              this.goToUrl.bind(this, 'https://help.notebase.io/', '_blank')
            }}
          >
            Help
          </li>
          {invite}
          {true && <li onClick={this.toggleOrgs}>Manage organizations</li>}
          {/*
          <li onClick={this.findBookmarks}>Bookmarks</li>
          */}
          {this.props.app.featureEnabled('due_nodes') && (
            <li onClick={this.props.app.toggleDueList}>TODO List</li>
          )}
          <li onClick={this.toggleKeyHelp}>Keyboard Shortcuts</li>
          {/* <li onClick={this.props.app.toggleHelp}>Video Tutorials</li> */}
          <li onClick={this.props.app.browseFiles}>File Browser</li>
          {admin}
          <li onClick={this.logout}>Logout</li>
          {/* <menuitem><span className="indicator autosync indicators.autoSync.class" onClick="toggleAutosync()">Auto-save: indicators.autoSync.text</span></menuitem> */}
        </ul>
      </div>
    )
  }
}

module.exports = UserMenu
