import './styles.css'

import React from 'react'
import _ from 'lodash'

import Node from '../../../../js/node'

const NOT_TRUNCATED_ITEMS = 2

class Breadcrumbs extends React.Component {
  handleBreadcrumbClick = (node, ev) => {
    this.props.app.setRoot(node)
    if (ev && ev.preventDefault) {
      ev.preventDefault()
    }
  }

  render () {
    const { app } = this.props
    const MAX_ITEMS = app.state.rootIsBoard ? 5 : 30

    let rootNode = this.props.rootNode
    let breadcrumbs = <div />
    let isActive = app.state.rootIsBoard ? false : null

    const path = Node.getPath(
      rootNode,
      this.props.app.state.entities.nodes,
      this.props.app.tab
    )

    let breadcrumbItems = []
    const tab = this.props.tab || {}

    if (tab.root_id) {
      rootNode =
        rootNode || Node.get(tab.root_id, this.props.app.state.entities.nodes)
      var tabRootNode = Node.get(
        tab.root_id,
        this.props.app.state.entities.nodes
      )
      var pinnedTabText =
        rootNode.id === tabRootNode.id ? '' : ' ' + tabRootNode.title
      isActive =
        isActive !== false && this.props.app.state.activeNodeId === tab.root_id
      breadcrumbItems.push(
        <li
          key='home'
          data-node-id={tab.root_id}
          className={'crumb ' + (isActive ? 'active' : '')}
          onClick={() => this.handleBreadcrumbClick(tab.root_id)}
        >
          <i className='fa fa-fw fa-map-pin' />
          {pinnedTabText}
        </li>
      )
    } else {
      isActive = isActive !== false && !this.props.app.state.activeNodeId
      breadcrumbItems.push(
        <li
          key='home'
          className={'crumb ' + (isActive ? 'active' : '')}
          onClick={() => this.handleBreadcrumbClick(null)}
        >
          <i className='fa fa-fw fa-home' />
        </li>
      )
    }

    let seenRoot = !tab.root_id
    let breadcrumbActualItems = []
    path.forEach((node, i) => {
      isActive =
        isActive !== false && this.props.app.state.activeNodeId === node.id
      var item = []

      if (!seenRoot && tab.root_id && node.id === tab.root_id) {
        seenRoot = true
      } else {
        // TODO: Fix this (sharedShares is an array, not an object)
        if (
          seenRoot &&
          !(
            this.props.sharedShares &&
            this.props.sharedShares.node &&
            this.props.sharedShares.node.id === node.id
          )
        ) {
          item.push(
            <li
              key={node.id}
              data-node-id={node.id}
              className={'crumb ' + (isActive ? 'active' : '')}
              onClick={() => this.handleBreadcrumbClick(node)}
            >
              {path.length > MAX_ITEMS && i === NOT_TRUNCATED_ITEMS
                ? '...'
                : node.title}
            </li>
          )
          item.push(
            <li key='spacer-last' className='angle-right'>
              <i className='fa fa-fw fa-angle-right' />
            </li>
          )
        }
      }
      if (
        path.length <= MAX_ITEMS ||
        (i <= NOT_TRUNCATED_ITEMS || i >= path.length - 3)
      ) {
        breadcrumbActualItems.push(item)
      }
    })

    if (rootNode && !(tab.root_id && tab.root_id === rootNode.id)) {
      breadcrumbItems.push(
        <li key='spacer-first' className='angle-right'>
          <i className='fa fa-fw fa-angle-right' />
        </li>
      )
    }

    breadcrumbActualItems = _.compact(breadcrumbActualItems)

    let allItems = breadcrumbItems.concat(breadcrumbActualItems)

    breadcrumbs = <ul id='breadcrumbs'>{allItems}</ul>

    return breadcrumbs
  }
}

module.exports = Breadcrumbs
