import React, { Component } from 'react'
import enhanceWithClickOutside from 'react-click-outside'
import Node from '../../js/node'

class BoardSortOrder extends Component {
  state = {
    isOpen: false
  }

  handleClickOutside = () => {
    this.setState({ isOpen: false })
  }

  sortValueToName = value => {
    if (!value) return this.props.nullName || 'Manual'
    switch (value) {
      case 'comment_count':
        return 'Comment count'
      case 'like_count':
        return 'Like count'
      case 'updated_at':
        return 'Last updated'
      case 'title':
        return 'Title'
      case 'completed':
        return 'Completed or not'
    }
  }

  onSelect = value => {
    const { app, listNode } = this.props
    const fieldName = this.props.fieldName || 'board_sortby'
    Node.setSetting(listNode, fieldName, value, app.getCommonParams())
    this.setState({ isOpen: false })
  }

  render () {
    const { listNode } = this.props
    const text = this.props.text || 'Sort by'
    const fieldName = this.props.fieldName || 'board_sortby'
    const skip = this.props.skip || []
    const allTypes = [
      null,
      'comment_count',
      'like_count',
      'updated_at',
      'title',
      'completed'
    ]
    const types = allTypes.filter(e => skip.indexOf(e) === -1)
    return (
      <div className='kb-settings-item'>
        {text}:{' '}
        <div
          className='current'
          onClick={ev => {
            ev.stopPropagation()
            this.setState({ isOpen: !this.state.isOpen })
          }}
        >
          {this.sortValueToName(listNode.settings[fieldName])}{' '}
          <i className='fa fa-fw fa-angle-down' />
          {this.state.isOpen && (
            <div className='dropdown'>
              {types.map(e => (
                <div key={e} onClick={() => this.onSelect(e)}>
                  {this.sortValueToName(e)}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default enhanceWithClickOutside(BoardSortOrder)
