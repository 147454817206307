import React from 'react'
import moment from 'moment'

// import Popover from '../popover'
// import Calendar from './calendar'
// import DateInput from './date_input'
// import PropTypes from 'prop-types'
import DayPicker from 'react-day-picker'

import 'react-day-picker/lib/style.css'

import Node from '../../js/node'

import './styles'

export default class DatePicker extends React.Component {
  setDay = day => {
    const { app } = this.props
    const { floater } = app.state
    if (floater && floater.type === 'date-picker') {
      const { data } = floater
      if (data) {
        if (data.nodeId) {
          if (data.mode === 'node-due') {
            app.setDueDate(data.nodeId, day, () => {
              app.setState({ floater: null })
            })
          } else if (data.mode === 'node-data-date-field' && data.field) {
            const node = app.node(data.nodeId)
            const newData = { ...(node.data || {}), [data.field]: day }
            Node.modify(
              node.id,
              { data: newData },
              app.getCommonParams(),
              () => {
                app.setState({ floater: null })
              }
            )
          }
        }
      }
    }
  }

  handleDayClick = (day, { selected }) => {
    this.setDay(day)
  }

  clearDate = () => {
    this.setDay(null)
  }

  render () {
    let node
    let selectedDays = null
    const { app } = this.props
    const { floater } = app.state
    if (floater && floater.type === 'date-picker') {
      const { data } = floater
      if (data && data.nodeId) {
        node = app.node(data.nodeId)
        if (node) {
          if (data.mode === 'node-due') {
            selectedDays = node.due_at ? moment(node.due_at).toDate() : null
          } else if (data.mode === 'node-data-date-field' && data.field) {
            const nodeData = node.data || {}
            selectedDays = nodeData[data.field]
              ? moment(nodeData[data.field]).toDate()
              : null
          }
        }
      }
    }
    return (
      <div className='datepicker-outer-container'>
        <DayPicker
          selectedDays={selectedDays}
          onDayClick={this.handleDayClick}
        />
        {node &&
          selectedDays && (
          <div className='clear' onClick={this.clearDate}>
              Clear
          </div>
        )}
      </div>
    )
  }
}
