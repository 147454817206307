import React from 'react'
import { Popper } from 'react-popper'
import enhanceWithClickOutside from 'react-click-outside'
import $script from 'scriptjs'

import DataTypeAdder from '../nodes/current_node/node_type_data/data_type_adder'
import NodeContextMenu from '../node_context_menu'
import DatePicker from '../date_picker'
import GenericPicker from '../generic_picker'
import LocationPicker from '../location_picker'
import NodeUsersPicker from '../node_users_picker'
import PeoplePicker from '../people_picker'
import CompanyPicker from '../company_picker'
import DurationPicker from '../duration_picker'
import PriorityPicker from '../priority_picker'
import PhonesPicker from '../phones_picker'
import EmailsPicker from '../emails_picker'

import './styles.css'

class Floater extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      ready: false,
      locationStr: '',
      locationSuggestion: null
    }
    const { app } = props
    if (
      app &&
      app.state &&
      app.state.floater &&
      app.state.floater.type === 'location-picker'
    ) {
      const apiKey = app.state.config.mapsApiKey
      $script(
        [
          `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`
        ],
        () => {
          this.setState({ ready: true })
        }
      )
    } else {
      this.state.ready = true
    }
  }

  handleClickOutside = () => {
    const { app } = this.props
    const newState = { floater: null }
    if (app.state.floater) {
      newState.oldFloater = { ...app.state.floater }
    }
    app.setState(newState, () => {
      setTimeout(() => {
        app.setState({ oldFloater: null })
      }, 100)
    })
  }

  content = () => {
    const { app } = this.props
    if (app && app.state && app.state.floater) {
      const childProps = { ...(this.props || {}), floater: this }
      let classes = app.state.floater.type
      let child
      switch (app.state.floater.type) {
        case 'context-menu':
          child = <NodeContextMenu {...childProps} node={app.contextNode()} />
          break
        case 'data-type-adder':
          child = <DataTypeAdder {...childProps} />
          break
        case 'assigner':
          child = <NodeUsersPicker {...childProps} />
          break
        case 'date-picker':
          child = <DatePicker {...childProps} />
          break
        case 'people-picker':
          child = <PeoplePicker {...childProps} />
          break
        case 'company-picker':
          child = <CompanyPicker {...childProps} />
          break
        case 'duration-picker':
          child = <DurationPicker {...childProps} />
          break
        case 'priority-picker':
          child = <PriorityPicker {...childProps} />
          break
        case 'emails-picker':
          child = <EmailsPicker {...childProps} />
          break
        case 'phones-picker':
          child = <PhonesPicker {...childProps} />
          break
        case 'location-picker':
          child = <LocationPicker {...childProps} />
          break
        default:
          child = <GenericPicker {...childProps} />
      }
      return [child, classes]
    }
  }

  render () {
    const content = this.content()
    if (!content) return false

    const { app } = this.props
    const { floater } = app.state

    const child = content[0]
    const classes = `${content[1]}-floater`
    const element = floater.element
    const placement = floater.placement || 'bottom'

    return this.state.ready ? (
      <Popper
        referenceElement={element}
        placement={placement}
        modifiers={{
          preventOverflow: {
            enabled: false
          },
          hide: {
            enabled: false
          },
          offset: {
            offset: '10%'
          }
        }}
      >
        {({ ref, style, placement, arrowProps }) => (
          <div
            ref={ref}
            style={style}
            data-placement={placement}
            id='floater'
            className={classes}
          >
            {child}
            <div
              ref={arrowProps.ref}
              style={arrowProps.style}
              className='popper-arrow'
            />
          </div>
        )}
      </Popper>
    ) : (
      false
    )
  }
}

export default enhanceWithClickOutside(Floater)
