var Misc = require('../js/misc')

module.exports = function (addToStack) {
  'use strict'

  var undoCommands = []
  var index = -1
  var isExecuting = false
  var callback

  function execute (command, action, params, cb) {
    // console.log('==========');
    // console.log('command:', command);
    // console.log('action:', action);
    // console.log('command[action]:', command[action]);
    if (!command || !action) {
      return this
    }

    // console.log('command', command);
    // console.log('action', action);
    var json = JSON.parse(command)

    if (params && json[action] && json[action].length) {
      var operations = json[action].filter(cmd => !(cmd.hasOwnProperty('persist') && !cmd.persist))
      addToStack(operations)
    }

    isExecuting = true
    // Set automatedMode to temporarily disable handlTitleBlur and friends
    window._app.automatedMode = true
    Misc.executeCommands(json[action], Object.assign({}, params, {_isUndoOperation: true}), function () {
      isExecuting = false
      // Unset automatedMode to re-enable handlTitleBlur and friends
      // We need a .5s buffer since some undo operations can cause a blur
      setTimeout(function () {
        window._app.automatedMode = false
      }, 500)
      if (typeof cb === 'function') {
        cb()
      }
    })

    return this
  }

  return {

    add: function (command, doAdd, executeOnly) {
      var str
      if (command && ((command.undo && command.undo.length) || (command.redo && command.redo.length))) {
        if (doAdd) {
          var operations = command['redo'].filter(cmd => !(cmd.hasOwnProperty('persist') && !cmd.persist))
          if (operations.length) {
            // console.log('>>> SYNC TO SERVER:', operations);
            str = JSON.stringify(operations)
            addToStack(JSON.parse(str))
          }
        }
        if (!executeOnly) {
          // console.log('undo command', command);
          str = JSON.stringify(command)
          if (isExecuting) {
            return this
          }
          // if we are here after having called undo,
          // invalidate items higher on the stack
          undoCommands.splice(index + 1, undoCommands.length - index)

          undoCommands.push(str)

          // set the current index to the end
          index = undoCommands.length - 1
        }

        if (callback) {
          callback()
        }
      }
      return this
    },

    /*
    Pass a function to be called on undo and redo actions.
    */
    setCallback: function (callbackFunc) {
      callback = callbackFunc
    },

    undo: function (params) {
      var command = undoCommands[index]
      if (!command) {
        return this
      }
      console.log('UNDO:', JSON.parse(command)) // eslint-disable-line no-console
      execute(command, 'undo', params, callback)
      index -= 1
      // if (callback) {
      //   callback();
      // }
      return this
    },

    redo: function (params) {
      var command = undoCommands[index + 1]
      if (!command) {
        return this
      }
      execute(command, 'redo', params, callback)
      index += 1
      // if (callback) {
      //   callback();
      // }
      return this
    },

    /*
    Clears the memory, losing all stored states.
    */
    clear: function () {
      var prevSize = undoCommands.length

      undoCommands = []
      index = -1

      if (callback && (prevSize > 0)) {
        callback()
      }
    },

    hasUndo: function () {
      return index !== -1
    },

    hasRedo: function () {
      return index < (undoCommands.length - 1)
    },

    getCommands: function () {
      return undoCommands
    }
  }
}
