import './styles.css'

import React from 'react'

export default class DataTypeAdder extends React.Component {
  state = {
    moreOpen: false
  }

  handlePick = type => {
    const { app } = this.props
    const onPick = app.state.floater.data.onPick
    onPick(type)
    app.setState({ floater: null })
  }

  renderRow = (type, node) => {
    const { app } = this.props
    return (
      <div key={type} className='row' onClick={() => this.handlePick(type)}>
        <div>
          <i className={`fa fa-fw fa-${app.getDataTypeIcon(type, node)}`} />
        </div>
        <div>
          <div>{app.getDataTypeTitle(type, node)}</div>
          <div>{app.getDataTypeDescription(type, node)}</div>
        </div>
      </div>
    )
  }

  render () {
    const { app } = this.props
    const node = app.node(app.state.floater.data.nodeId)
    const data = node.data || {}
    let types = []
    let moreTypes = []
    const allMoreTypes = [
      'duration',
      'event_end',
      'priority',
      'story_points',
      'job_title',
      'company',
      'phones',
      'emails',
      'url'
    ]

    const restTypes = app.state.allDataTypes.filter(
      t => allMoreTypes.indexOf(t) === -1
    )

    restTypes.forEach(type => {
      const includedTypes =
        app.state.includedDataTypesForNodeType[node.type] || []
      if (!data[type] && includedTypes.indexOf(type) === -1) {
        types.push(this.renderRow(type, node))
      }
    })

    allMoreTypes.forEach(type => {
      const includedTypes =
        app.state.includedDataTypesForNodeType[node.type] || []
      if (!data[type] && includedTypes.indexOf(type) === -1) {
        moreTypes.push(this.renderRow(type, node))
      }
    })

    // If main list if empty, just switch it with the more list (and set that one to empty)
    if (!types.length) {
      types = moreTypes
      moreTypes = []
    }

    const more = moreTypes.length ? (
      <div
        className='more'
        onClick={() => this.setState({ moreOpen: !this.state.moreOpen })}
      >
        <div className='more-label'>
          <div>More</div>
          <div>
            <i className='fa fa-fw fa-angle-right' />
          </div>
        </div>
        {this.state.moreOpen && <div className='more-floater'>{moreTypes}</div>}
      </div>
    ) : (
      false
    )

    return (
      <div className='data-type-adder-inner'>
        {types}
        {more}
      </div>
    )
  }
}
