import './styles.css'

import React from 'react'
import moment from 'moment'

import SetPlaceholder from '../../set_placeholder'

export default class EventAt extends React.Component {
  toggleStart = ev => {
    const { app, node } = this.props
    app.toggleFloater(ev.target, 'date-picker', {
      mode: 'node-data-date-field',
      field: 'event_at',
      nodeId: node.id
    })
  }

  render () {
    const { app, node } = this.props
    const data = node.data || {}
    return (
      <div
        onClick={ev =>
          app.toggleFloater(ev.target, 'date-picker', {
            mode: 'node-data-date-field',
            field: 'event_at',
            nodeId: node.id
          })
        }
      >
        {data.event_at && (
          <div className='actual-data'>
            <div className='data-date'>
              {moment(data.event_at).format('YYYY-MM-DD')}
            </div>
          </div>
        )}
        {!data.event_at && <SetPlaceholder />}
      </div>
    )
  }
}
