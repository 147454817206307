import './styles.css'

import React from 'react'
import $ from 'jquery'
// import Chrono from 'chrono-node';
import moment from 'moment'

import Avatar from '../../../avatar'
import AssignPicker from '../../../assigner/assign_picker'
import DatePicker from '../../../date_picker'

import Node from '../../../../js/node'
import Misc from '../../../../js/misc'

class SingleNode extends React.Component {
  state = {
    assignPickerActive: false,
    duePickerActive: false
  }

  handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick(this.props.node)
    } else {
      this.props.app.setRoot(this.props.node)
    }
  }

  toggleAssigner = ev => {
    this.setState({ assignPickerActive: !this.state.assignPickerActive })
    if (ev && ev.preventDefault) {
      ev.preventDefault()
      ev.stopPropagation()
    }
  }

  handleAssignSelect = userOrId => {
    if (userOrId) {
      var user =
        typeof userOrId === 'string'
          ? this.props.app.state.entities.users.find(u => {
            return u.id === userOrId
          })
          : userOrId
      this.props.app.setAssignedTo(this.props.node, user, () => {
        this.setState({ assignPickerActive: false })
      })
    }
  }

  handleClickOutsideAssigner = ev => {
    var assigner = $(ev.target).closest('.assigner__container')
    var gravatar = $(ev.target).closest('.assigned-gravatar')
    if (!assigner.length && !gravatar.length) {
      this.setState({ assignPickerActive: false })
    }
  }

  toggleDatepicker = ev => {
    this.setState({ duePickerActive: !this.state.duePickerActive })
    if (ev && ev.preventDefault) {
      ev.preventDefault()
      ev.stopPropagation()
    }
  }

  handleDueDateSelect = date => {
    if (date) {
      var dateVal = date.toDate()
      this.props.app.setDueDate(this.props.node, dateVal, () => {
        this.setState({ duePickerActive: false })
      })
    }
  }

  handleClickOutsideDatepicker = () => {
    this.setState({ duePickerActive: false })
  }

  render () {
    const node = this.props.node
    const size = this.props.size || 'small'
    const body = this.props.body || node.title
    const time = this.props.time || node.created_at
    const mode = this.props.mode || 'simple'

    const iconClasses = 'icon-container bullet'
    const icon = this.props.app.nodeIcon(node)

    let deleteIcon,
      completeWidget,
      avatar,
      dueDate,
      duePicker,
      assignPicker,
      assignedUser,
      pathRow

    if (this.props.onDeleteLink) {
      deleteIcon = (
        <i
          className='fa fa-fw fa-close delete'
          onClick={ev => this.props.onDeleteLink(this.props.link, ev)}
        />
      )
    } else {
      if (!this.props.noAvatar) {
        const avatarClickHandler = mode === 'due' ? this.toggleAssigner : null
        avatar = (
          <Avatar
            app={this.props.app}
            user={this.props.user}
            onClick={avatarClickHandler}
            noPlaceholder
            size={size}
          />
        )
        if (mode === 'due') {
          if (this.state.assignPickerActive) {
            var placeholder = 'Name or username'
            if (assignedUser) {
              placeholder = assignedUser.username
            }
            assignPicker = (
              <AssignPicker
                app={this.props.app}
                selected={node.assigned_to}
                placeholder={placeholder}
                onSelect={this.handleAssignSelect}
                onClickOutside={this.handleClickOutsideAssigner}
              />
            )
          }
        }
      }
    }

    let mainClasses = 'single-node'
    if (this.props.showPath) {
      mainClasses += ' with-path'
    }
    if (mode === 'due') {
      let datePickerDate
      completeWidget = <input type='checkbox' />
      mainClasses += ' completable'
      if (this.state.duePickerActive) {
        datePickerDate = moment(time)
        duePicker = (
          <div id='due-date-input'>
            <DatePicker
              selected={datePickerDate}
              focus
              noInput
              placeholder='today, fri, 12/1/11, aug 22'
              onClickOutside={this.handleClickOutsideDatepicker}
              onSelect={this.handleDueDateSelect}
            />
          </div>
        )
      }
      if (node.due_at) {
        dueDate = (
          <div className='date-container'>
            <span onClick={this.toggleDatepicker}>
              <i className='fa fa-fw fa-clock-o' /> {Misc.dueStr(time)}
            </span>
            {duePicker}
          </div>
        )
      }
    }

    let timeStr
    if (mode !== 'due') {
      timeStr = <small>({moment(time).fromNow()})</small>
    }

    if (this.props.showPath) {
      const maxElements = 4
      const path = Node.getPath(node, this.props.app.state.entities.nodes)
      let pathItems
      if (path.length) {
        let collapsed
        if (path.length > maxElements) {
          collapsed = '\u2026'
        }

        const trimmedPathItems = collapsed
          ? path.slice(Math.max(path.length - maxElements, 1))
          : path
        const actualPathItems = trimmedPathItems.map(p => {
          return (
            <li key={'due-path-' + p.id}>
              <a href='' onClick={() => this.props.app.setRoot(p, null)}>
                {Misc.truncate(p.title, 10, true)}
              </a>
            </li>
          )
        })
        pathItems = collapsed
          ? [
            <li key={'due-path-collapsed-' + node.id}>
              {collapsed}
              <a href='' />
            </li>
          ].concat(actualPathItems)
          : actualPathItems
        pathRow = (
          <div key={'due-path-row-' + node.id} className='path'>
            <div className='due-path'>
              <ul>{pathItems}</ul>
            </div>
          </div>
        )
      }
    }

    return (
      <div className={mainClasses}>
        {pathRow}
        <div onClick={this.handleClick} className='info'>
          <div className={iconClasses}>
            {icon}
            {completeWidget}
          </div>
          <div className='text'>
            <div>
              {body} {timeStr}
            </div>
            {dueDate}
          </div>
          {deleteIcon}
          <div>
            {avatar}
            {assignPicker}
          </div>
        </div>
      </div>
    )
  }
}

module.exports = SingleNode
