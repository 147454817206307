const Misc = require('../js/misc')

const commonShortcuts = {
  openSwitcher: {
    desc: 'Quick switcher',
    keys: ['ctrl+.', 'ctrl+k'],
    webOverrides: {
      mac: ['command+.', 'command+k']
    }
  },

  up: {
    desc: 'Up',
    keys: ['up', 'ctrl+p']
  },

  down: {
    desc: 'Down',
    keys: ['down', 'ctrl+n']
  },

  left: {
    keys: ['left'],
    hide: true
  },

  right: {
    keys: ['right'],
    hide: true
  },

  undo: {
    desc: 'Undo',
    keys: 'ctrl+z',
    webOverrides: {
      mac: 'command+z'
    }
  },

  redo: {
    desc: 'Redo',
    keys: 'shift+ctrl+z',
    webOverrides: {
      mac: 'shift+command+z'
    }
  },

  toggleEditing: {
    desc: 'Toggle editing',
    keys: 'ctrl+e',
    webOverrides: {
      mac: 'command+e'
    }
  },

  goUpOneLevel: {
    desc: 'Go up one level',
    keys: 'ctrl+u'
  },

  zoomAndEdit: {
    desc: 'Zoom in and edit',
    keys: 'shift+ctrl+e',
    webOverrides: {
      mac: 'shift+command+e'
    }
  },

  zoomIn: {
    desc: 'Zoom in',
    keys: ['ctrl+alt+right', 'ctrl+g']
  },

  zoomOut: {
    desc: 'Zoom out',
    keys: 'ctrl+alt+left'
  },

  toggleExpansion: {
    desc: 'Expand / collapse',
    keys: 'ctrl+space'
  },

  toggleEmphasis: {
    desc: 'Toggle emphasis',
    keys: 'command+enter'
  },

  toggleDescription: {
    desc: 'Add description',
    keys: 'shift+enter'
  },

  addNode: {
    desc: 'Add / split item',
    keys: 'enter'
  },

  browseFiles: {
    desc: 'Open file browser',
    keys: 'alt+escape'
  },

  insertInternalLink: {
    desc: 'Insert internal link',
    keys: 'ctrl+l'
  },

  eraseNode: {
    desc: 'Remove / merge item',
    keys: 'backspace'
  },

  indent: {
    desc: 'Indent',
    keys: 'tab'
  },

  outdent: {
    desc: 'Outdent',
    keys: 'shift+tab',
    info: "(or hit 'enter' on an empty line)"
  },

  moveUp: {
    desc: 'Move up',
    keys: 'ctrl+shift+up'
  },

  moveDown: {
    desc: 'Move down',
    keys: 'ctrl+shift+down'
  },

  complete: {
    desc: 'Complete',
    keys: 'ctrl+enter'
  },

  remove: {
    desc: 'Delete',
    keys: 'shift+ctrl+backspace'
  },

  collapseOrCancel: {
    desc: 'Collapse current',
    keys: 'escape'
  },

  toggleCompleted: {
    desc: 'Show / hide completed',
    keys: 'ctrl+o'
  },

  openUrl: {
    desc: 'Open bookmark / site',
    keys: 'ctrl+command+enter'
  },

  toggleContextMenu: {
    desc: 'Toggle context menu',
    keys: 'shift+escape'
  },

  help: {
    desc: 'Keyboard help',
    keys: 'ctrl+?'
  },

  modifySelectionUp: {
    desc: 'Modify selection',
    keys: 'shift+up'
  },

  modifySelectionDown: {
    desc: 'Modify selection',
    keys: 'shift+down'
  },

  nextTab: {
    desc: 'Next tab',
    keys: 'ctrl+.'
  },

  prevTab: {
    desc: 'Previous tab',
    keys: 'ctrl+,'
  },

  // setDueDate: {
  //   desc: 'Set due date',
  //   keys: 'Tap \u21E7 twice',
  // },

  // assignToUser: {
  //   desc: 'Assign to user',
  //   keys: 'Tap \u2318 twice',
  // },

  switchToOrg: {
    desc: 'Switch team',
    keys: 'ctrl+1-9',
    desktopOverrides: {
      mac: 'cmd+1-9'
    }
  },

  quickAdd: {
    desc: 'Quick add',
    keys: 'ctrl+command+n'
  }
}

const desktopShortcuts = {
  // cutText: {
  //   desc: 'Cut',
  //   keys: 'ctrl+x',
  //   desktopOverrides: {
  //     mac: 'cmd+x'
  //   }
  // },
  // copyText: {
  //   desc: 'Copy',
  //   keys: 'ctrl+c',
  //   desktopOverrides: {
  //     mac: 'cmd+c'
  //   }
  // },
  // pasteText: {
  //   desc: 'Paste',
  //   keys: 'ctrl+v',
  //   desktopOverrides: {
  //     mac: 'cmd+v'
  //   }
  // }
}

const Shortcuts = {
  allShortcuts: () => {
    // var allShortcuts = Object.assign({}, commonShortcuts, desktopShortcuts);
    const allShortcuts = NOTEBASE_IS_DESKTOP
      ? Object.assign({}, commonShortcuts, desktopShortcuts)
      : Object.assign({}, commonShortcuts)
    return allShortcuts
  },

  getKeyCombo: name => {
    const platform = Misc.platform()
    const allShortcuts = Shortcuts.allShortcuts()
    const entry = allShortcuts[name]
    if (entry) {
      if (
        NOTEBASE_IS_DESKTOP &&
        entry.desktopOverrides &&
        entry.desktopOverrides[platform]
      ) {
        return entry.desktopOverrides[platform]
      } else if (entry.webOverrides && entry.webOverrides[platform]) {
        return entry.webOverrides[platform]
      } else {
        return entry.keys
      }
    } else {
      return 'impossible+combo'
    }
  },

  getShortcuts: () => {
    const shortcuts = []
    const allShortcuts = Shortcuts.allShortcuts()
    for (const key in allShortcuts) {
      var shortcut = allShortcuts[key]
      if (!shortcut.hide) {
        shortcut.keys = Shortcuts.getKeyCombo(key)
        delete shortcuts.webOverrides
        delete shortcuts.desktopOverrides
        shortcuts.push(shortcut)
      }
    }
    return shortcuts
  }
}

module.exports = Shortcuts
