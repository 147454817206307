import React from 'react'

import classNames from 'classnames'

import Misc from '../../js/misc'

class MiniEditor extends React.Component {
  defaultPreviewText = '(Nothing to preview.)'

  doWrite = ev => {
    this.setState({ preview: false, previewResult: null })
    ev.preventDefault()
  }

  doPreview = ev => {
    var _this = this
    var md = this.ta.value
    var result = md ? Misc.parseMarkdown(md) : this.defaultPreviewText
    this.setState({ preview: true, previewResult: result }, function () {
      if (md) {
        Misc.jx(_this, 'parse', { text: md }, function (res) {
          _this.setState({ previewResult: res.body.html })
        })
        // superagent
        //   .post('/_/parse')
        //   .send({text: md})
        //   .set('Authorization', 'Bearer ' + _this.props.app.state.token)
        //   .end(function(res){
        //     if (res.ok) {
        //       _this.setState({previewResult: res.body.html});
        //     } else {
        //       console.error(res.text);
        //     }
        //   });
      }
    })
    ev.preventDefault()
  }

  handleChange = ev => {
    if (this.props.onChange) {
      this.props.onChange(ev, this.ta.value)
    }
  }

  handleSubmit = ev => {
    if (this.props.onSubmit) {
      this.props.onSubmit(ev, this.ta.value)
    }
  }

  state = {
    preview: false,
    previewResult: this.defaultPreviewText
  }

  componentDidMount () {}

  render () {
    var editorClasses = classNames({
      'mini-editor': true
    })
    var taClasses = classNames({
      'ta-container': true,
      hidden: this.state.preview
    })
    var previewClasses = classNames({
      'preview-container': true,
      hidden: !this.state.preview
    })
    var writeTabClasses = classNames({
      'tabnav-tab': true,
      selected: !this.state.preview
    })
    var previewTabClasses = classNames({
      'tabnav-tab': true,
      selected: this.state.preview
    })
    // var buttons;
    // if (this.props.onSubmit || this.props.onCancel) {
    //   buttons = (
    //     <div className="buttons">
    //       <div className="left">
    //         Some text here.
    //       </div>
    //       <span className="right">
    //         <button className="minibutton" onClick={this.handleSubmit}>
    //           <span className="octicon octicon-organization"></span>&nbsp;
    //           {this.props.buttontext || 'Save'}
    //         </button>
    //       </span>
    //     </div>
    //   );
    // }
    return (
      <div
        className={editorClasses}
        ref={editor => {
          this.editor = editor
        }}
      >
        <div className='tabnav'>
          <ul className='tabnav-tabs'>
            <li onClick={this.doWrite}>
              <a href='' className={writeTabClasses}>
                Write
              </a>
            </li>
            <li onClick={this.doPreview}>
              <a href='' className={previewTabClasses}>
                Preview
              </a>
            </li>
          </ul>
          <span className='right'>
            <a
              href='/help/markdown-guide'
              className='widget text extras'
              target='_blank'
            >
              <span className='octicon octicon-markdown' /> Parsed as Markdown
            </a>
          </span>
        </div>
        <div className={taClasses}>
          <textarea
            ref={ta => {
              this.ta = ta
            }}
            data-name={this.props.dataName}
            placeholder={this.props.placeholder}
            defaultValue={this.props.value}
            onChange={this.handleChange}
          />
          {/*
          <div className="footer">
            Attach images by dragging &amp; dropping, selecting them, or pasting from the clipboard.
          </div>
          */}
        </div>
        <div className={previewClasses}>
          <div
            className='preview'
            dangerouslySetInnerHTML={{ __html: this.state.previewResult }}
          />
        </div>
      </div>
    )
  }
}

module.exports = MiniEditor
