// var $ = require('jquery');
var _ = require('lodash')
var uuid = require('node-uuid')
// var io = require('socket.io-client');

var Node = require('../../js/node')
var Misc = require('../../js/misc')

module.exports = {
  doPending: function () {
    var _this = this
    var i = 0
    var doCmd = function (data) {
      if (data) {
        _this.currentlySyncing = true
        var id = uuid.v4()
        window._app.command_log.push(id)
        window._app.sync_timeouts[id] = setTimeout(function () {
          _this.setState({ syncError: { error: true } })
        }, 30 * 1000)
        _this.emit('x', data, {
          cmdId: id,
          callback: function (resp) {
            // console.log('x callback resp:', resp);
            clearTimeout(window._app.sync_timeouts[resp.cmdId])

            // console.log(resp);
            window._app.pending.shift()
            var newNext = window._app.pending[0]
            if (newNext) {
              i++
              console.log('### ---recursing--- in doPending ###', i) // eslint-disable-line no-console
              doCmd(newNext)
            } else {
              _this.currentlySyncing = false

              // console.log('### ---done syncing--- ###', i);
              // _this.setState({indicators: {save: {text: 'Saved', class: 'good'}}});
            }
          }
        })
      }
    }

    // start recursion, unless we are already syncing stuff
    if (this.currentlySyncing) {
      console.log(
        '### ---skipping--- doPending due to sync already in progress ###'
      ) // eslint-disable-line no-console
    } else {
      doCmd(window._app.pending[0])
    }
  },

  addToStack: function (cmd) {
    // this.setState({indicators: {save: {text: 'Unsaved', class: 'soso'}}}, function() {
    // console.log('Adding to stack:', cmd)
    var opts = {}
    var user = this.user()
    if (this.state.share) {
      opts.secretId = this.state.share.secret_id
    }

    window._app.pending.push({ cmd: cmd, opts: opts })
    if (this.socket && (!user || user.settings.auto_sync)) {
      this.doPending()
    }
    // });

    // console.log('PENDING:', window._app.pending);
  },

  // syncClientData: function(data) {
  //   var _this = this;
  //   if (data) {
  //     _this.setAppState(data);
  //   } else {
  //     // this.socket.emit('sync');
  //     this.emit('sync');
  //   }

  // },

  resetTransferDataTimer: function (delay) {
    this._prevCollections = this._prevCollections || {}
    var collectionsToTransfer = ['nodes', 'orgs', 'tabs', 'users', 'ids']
    var _this = this
    var checkInterval = typeof delay === 'number' ? delay : 2000 // ms
    var roundTimeout = 10 // ms
    var itemsPerRound = 100
    var t0

    clearTimeout(window._app.transferDataTimer)

    var ids = [
      { name: 'userId', value: this.userId() },
      { name: 'orgId', value: this.orgId() },
      { name: 'tabId', value: this.tabId() }
    ]

    var recurseItems = function (i, collection, allItems, callback) {
      var start = i * itemsPerRound
      var items = allItems.slice(start, start + itemsPerRound)
      if (items.length) {
        _this.state.filterPathsWorker.postMessage({
          action: 'partial-data',
          type: collection,
          data: items,
          t0: t0
        })
        setTimeout(function () {
          recurseItems(i + 1, collection, allItems, callback)
        }, roundTimeout)
      } else {
        callback()
      }
    }

    var recurseCollections = function (i, collections, callback) {
      var collection = collections[i]
      if (collection) {
        var prevCollection = _this._prevCollections[collection]
        // var t0 = new Date().getTime();
        // var currCollection = _.xfilter(_this.state.entities[collection]);
        var currCollection =
          collection === 'ids' ? ids : _this.state.entities[collection]
        // console.log(new Date().getTime() - t0);
        if (prevCollection && prevCollection === currCollection) {
          recurseCollections(i + 1, collections, callback)
        } else {
          _this._prevCollections[collection] = currCollection
          _this.state.filterPathsWorker.postMessage({
            action: 'start-partial-data',
            type: collection,
            t0: t0
          })
          recurseItems(0, collection, currCollection, function () {
            _this.state.filterPathsWorker.postMessage({
              action: 'end-partial-data',
              type: collection,
              t0: t0
            })
            recurseCollections(i + 1, collections, callback)
          })
        }
      } else {
        callback()
      }
    }

    window._app.transferDataTimer = setTimeout(function () {
      t0 = new Date().getTime()
      recurseCollections(0, collectionsToTransfer, function () {
        // console.log('>>> DONE transferring data');
        _this.resetTransferDataTimer()
      })
    }, checkInterval)
  },

  loadInitialData: function (isRefresh, callback) {
    var _this = this
    var headers = {}
    var data = {}
    var authorized = false
    if (this.state.token) {
      headers.Authorization = 'Bearer ' + this.state.token
    }

    if (window._app.shareId) {
      headers['X-Notebase-Secret-ID'] = window._app.shareId
    }

    if (window._app.inviteId) {
      data.inviteId = window._app.inviteId
    }
    if (window._app.orgInviteId) {
      data.orgInviteId = window._app.orgInviteId
    }
    if (window._app.orgRequestId) {
      data.orgRequestId = window._app.orgRequestId
    }

    // TODO: Account for share URLs here, eg. /shared/<share-id>/<node-id>
    // var matches = window.location.pathname.match(/\/n\/([^\/?]+)/);
    var matches = window.location.pathname.match(/\/n\/([^/?]+)/)
    var rootId
    if (matches && matches[1]) {
      rootId = matches[1]
    } else {
      // matches = window.location.pathname.match(/\/shared\/[^\/]+\/([^\/?]+)/);
      matches = window.location.pathname.match(/\/shared\/[^/]+\/([^/?]+)/)
      if (matches && matches[1]) {
        rootId = matches[1]
      }
    }

    if (rootId) {
      data.node_id = rootId
    }

    // Misc.jx(this, {
    //   cmd: 'index',
    //   data: data,
    //   success: function(res, fullRes) { // success
    this.ajax(
      'index',
      {
        body: data,
        headers: headers
      },
      function (res) {
        // success
        var tab, user, org
        if (res.specialResponse) {
          _this.setState(
            { specialAction: res.action, specialActionParams: res.params },
            function () {
              if (typeof callback === 'function') {
                callback()
              }
            }
          )
        } else {
          var entities = res.entities
          var sets = res.sets
          var initialData = {
            initialized: true,
            specialAction: null,
            config: res.config || {},
            _dev: res._dev,
            entities: entities || {},
            sets: sets || {}
          }
          var nextData = {
            loaded: true,
            error: false,
            online: true
          }

          initialData.stripeToken = initialData._dev
            ? 'pk_AOmVjc7ZDkHWC9EtHXzvXjH1xBvBm'
            : 'pk_oV4ykNt58ZJ9cCjmdxASF267zWBqd'
          // /* eslint-disable */
          // if (typeof Stripe !== 'undefined' && Stripe.setPublishableKey) {
          //   Stripe.setPublishableKey(stripeToken);
          // }
          // /* eslint-enable */

          if (res.authorized) {
            authorized = true
            var nodes = entities.nodes
            if (!rootId && res.tabId) {
              tab = entities.tabs.find(t => t.id === res.tabId)
            }

            initialData.notFound = false
            initialData.nodes = nodes
            initialData.displayNodes = nodes

            let rootNode
            if (rootId) {
              rootNode = Node.get(rootId, initialData.nodes)
              if (rootNode) {
                initialData.urlRootNodeId = rootNode.id
              }
            }

            if (!isRefresh) {
              initialData.version = res.version
            }

            if (res.userId) {
              initialData.wsToken = res.wsToken
              user = entities.users.find(t => t.id === res.userId)
              initialData.userId = res.userId
              initialData.completedTours = res.completedTours
              initialData.expandHelp = user.settings.hasOwnProperty(
                'expand_help'
              )
                ? user.settings.expand_help
                : true
              initialData.expandKeyHelp = user.settings.hasOwnProperty(
                'expand_key_help'
              )
                ? user.settings.expand_key_help
                : true
              initialData.expandMarkdownHelp = user.settings.hasOwnProperty(
                'expand_markdown_help'
              )
                ? user.settings.expand_markdown_help
                : true
              initialData.expandDueList = user.settings.hasOwnProperty(
                'expand_due_list'
              )
                ? user.settings.expand_due_list
                : true
              initialData.sidebarOpen =
                this.featureEnabled('sidebar', user) &&
                user.settings.hasOwnProperty('show_sidebar')
                  ? user.settings.show_sidebar
                  : false
              initialData.scrollSync = user.settings.hasOwnProperty(
                'scroll_sync'
              )
                ? user.settings.scroll_sync
                : true
              initialData.editorCompanion = user.settings.hasOwnProperty(
                'editor_companion'
              )
                ? user.settings.editor_companion
                : 'preview'
              if (window.Intercom) {
                window.Intercom('shutdown')
              }
              if (_this.hasCompletedTour('main', res.completedTours)) {
                if (!res._dev) {
                  _this.setupIntercom(res.user)
                }
              }
              if (res.customers) {
                initialData.customers = res.customers
              }
            }

            if (res.tabs) {
              initialData.tabs = res.tabs
            }

            if (res.tabId) {
              tab = entities.tabs.find(t => t.id === res.tabId)
              initialData.tabId = res.tabId
              if (tab.share_id) {
                initialData.shareId = tab.share_id
              }
            }

            if (res.orgId) {
              initialData.orgId = res.orgId
              initialData.orgsData = {}
              initialData.orgsData[res.orgId] = { haveNodes: true }
            }

            if (res.users) {
              initialData.users = res.users
            }

            if (!user || !user.org_id) {
              // initialData.userNodes = nodes;
              initialData.haveUserNodes = nodes
            }

            if (res.anonShare) {
              nextData.anonShareId = res.anonShare.id
              nextData.shareId = res.anonShare.id
              nextData.secretShareId = res.anonShare.secret_id
              var tabId = 'share-' + res.anonShare.id
              var localTab = window.localStorage.getItem('tab-' + tabId)
              // TODO: This wont work after switching to id-based system
              nextData.tab = localTab
                ? JSON.parse(localTab)
                : {
                  virtual: true,
                  id: tabId,
                  expanded_nodes: [],
                  node_id: rootNode ? rootNode.id : null
                }
              nextData.tab.share_id = res.anonShare.id
              window.localStorage.setItem(
                'tab-' + tabId,
                JSON.stringify(nextData.tab)
              )
              initialData.tabs = [nextData.tab]
            }

            if (user && user.settings.custom_css) {
              Misc.addStylesheetOnce(user.settings.custom_css)
            }

            initialData.entities.nodes = _this.decorateNodesWithAccessLevel(
              entities,
              nodes,
              org,
              user
            )
          }

          const preData = {
            wsToken: initialData.wsToken,
            wsUserId: initialData.userId,
            wsOrgId: res.orgId
          }
          if (nextData.secretShareId) {
            preData.secretShareId = nextData.secretShareId
          }
          _this.setState(preData, function () {
            _this.setupSocket(function () {
              _this.setState(initialData, function () {
                _this.setAppState(nextData, { firstLoad: true }, () => {
                  if (authorized) {
                    // _this.requestNotificationPermission();
                    _this.resetTransferDataTimer(0)
                  }
                  if (typeof callback === 'function') {
                    callback()
                  }
                })
              })
            }, isRefresh || !authorized)
          })
        }
      }.bind(this),
      function (res) {
        // error
        if (res.body) {
          console.log('ERROR:', res.body.error) // eslint-disable-line no-console
          if (res.body.error.code === 401) {
            window.sessionStorage.removeItem('authToken')
            window.localStorage.removeItem('authToken')
            _this.setAppState({
              authToken: null,
              user: null,
              loaded: true,
              notFound: res.body.error.notFound
            })
          }
        } else {
          console.error(res.text) // eslint-disable-line no-console
        }
      }
    )
  },

  decorateNodesMaybe: function (records, type) {
    if (type === 'nodes') {
      return this.decorateNodesWithAccessLevel(
        this.state.entities,
        records,
        this.org(),
        this.user()
      )
    } else {
      return records
    }
  },

  transformSyncData: function (syncData, returnOnlyEntities) {
    var _this = this
    var ret = Object.assign({}, this.state.entities)
    var newData, ids, trimmedData, ops
    _.keys(syncData).forEach(function (key) {
      ops = syncData[key]
      newData = { ...ret[key] }
      ops.forEach(function (op) {
        if (op.op === 'add') {
          newData = newData.concat(_this.decorateNodesMaybe(op.data, key))
        } else if (op.op === 'replace') {
          ids = op.data.map(d => d.id)
          trimmedData = newData.filter(d => ids.indexOf(d.id) === -1)
          newData = trimmedData.concat(_this.decorateNodesMaybe(op.data, key))
        } else if (op.op === 'replaceAll') {
          newData = _this.decorateNodesMaybe(op.data, key)
        } else if (op.op === 'delete') {
          newData = newData.filter(d => op.data.indexOf(d.id) === -1)
        }
      })
      ret[key] = newData
    })
    return returnOnlyEntities ? ret : { entities: ret }
  },

  applyChanges: function (changes, otherState, cb) {
    if (typeof otherState === 'function') {
      cb = otherState
      otherState = {}
    }
    otherState = otherState || {}
    var newState = Object.assign(
      {},
      otherState,
      this.transformSyncData(changes)
    )
    this.setAppState(newState, cb)
  },

  incomingCommand: function (data) {
    var _this = this
    console.log('INCOMING COMMAND:', JSON.stringify(data)) // eslint-disable-line no-console
    // TODO: Save focus
    switch (data.endpoint) {
      case 'x':
        if (!data.cmdId) {
          window.localStorage.setItem(
            'sync_command_without_cmdid',
            JSON.stringify(data)
          )
          console.error('Sync command had no cmdId:', JSON.stringify(data)) // eslint-disable-line no-console
        } else if (window._app.command_log.indexOf(data.cmdId) > -1) {
          window.localStorage.setItem(
            'duplicate_sync_command',
            JSON.stringify(data)
          )
          console.warn('Got duplicate sync command:', JSON.stringify(data)) // eslint-disable-line no-console
        } else {
          var syncData
          var noOp = true
          if (data.syncData) {
            noOp = false
            syncData = this.transformSyncData(data.syncData)
          }
          // this.setStateMaybe(syncData, noOp, function() {
          this.setAppState(syncData, { noop: noOp }, function () {
            Misc.executeCommands(
              data.data,
              _.extend(_this.getCommonParams(), { _remote: true }),
              () => {
                window._app.command_log.push(data.cmdId)
                window._app.command_log = window._app.command_log.slice(-1000)
              }
            )
          })
        }

        break
      // case 'sync':
      //   this.syncClientData(data.data);
      //   break;
    }

    // TODO: Reset saved focus after executing commands
  },

  logMetric: function (name, type, value, tags, opts) {
    type = type || 'counter'
    if (typeof value === 'undefined') {
      value = 1
    }

    this.emit(
      'log',
      { what: 'metric', name: name, value: value, type: type, tags: tags },
      opts
    )
  },

  logEvent: function (name, data, tags, opts) {
    // type = type || 'counter';
    this.emit(
      'log',
      { what: 'event', name: name, data: data, tags: tags },
      opts
    )
  },

  emit: function (endpoint, data, opts) {
    var _this = this
    opts = opts || {}
    var payload = {
      endpoint: endpoint,
      data: data,
      browserId: window._app.browser_id,
      sessionId: window._app.session_id
    }
    if (opts.cmdId) {
      payload.cmdId = opts.cmdId
    }

    let channel = 'userChannel'
    if (opts.context === 'browser') {
      channel = 'browserChannel'
    } else if (opts.context === 'org') {
      channel = 'orgChannel'
    }

    var noOp = Boolean(
      this.socket && this.socket.connected && !this.socket.disconnected
    )
    this.setupSocket(function () {
      _this[channel]
        .push('cmd', payload)
        .receive('ok', resp => {
          // console.log('Got OK after emit', resp); // eslint-disable-line no-console
          if (typeof opts.callback === 'function') {
            opts.callback(resp)
          }
        })
        .receive('error', resp => console.error('Got ERROR after emit', resp)) // eslint-disable-line no-console
        .receive('timeout', () => console.error('Got TIMEOUT after emit')) // eslint-disable-line no-console
    }, noOp)
  },

  disconnectSocket: function () {
    this.socket.disconnect()
  },

  setupSocket: function (callback, noop) {
    // Do callback only on initial call
    // var doCallback = true;

    // if ((this.socket && this.socket.connected && !this.socket.disconnected) || noop) {
    if (this.socket || noop) {
      if (typeof callback === 'function') {
        callback()
      }
    } else {
      // Remove any existing listeners
      // socket.removeAllListeners('connect');
      // socket.removeAllListeners('authenticated');
      // socket.removeAllListeners('version');
      // socket.removeAllListeners('locked');
      // socket.removeAllListeners('unlock');
      // socket.removeAllListeners('connect');
      // socket.removeAllListeners('err');
      // socket.removeAllListeners('reconnect_attempt');
      // socket.removeAllListeners('reconnect_error');
      // socket.removeAllListeners('reconnect_failed');
      // socket.removeAllListeners('connect_error');
      // socket.removeAllListeners('connect_timeout');
      // socket.removeAllListeners('reconnecting');
      // socket.removeAllListeners('reconnect');
      // socket.removeAllListeners('disconnect');
      // socket.removeAllListeners('disconnect');

      // var _this = this;

      // var socket = io.connect('/');
      // var socket = io({ forceNew: true });
      // var query = this.state.authToken ? `auth_token=${this.state.authToken}` : `auth_token=&secret${window._app.shareId}`;
      // var socket = io.connect(NOTEBASE_URL_PREFIX, { forceNew: true, query: query });

      Misc.setupWebSockets(this, () => {
        Misc.joinUserChannel(this)
        Misc.joinOrgChannel(this)
        if (typeof callback === 'function') {
          callback()
        }
      })

      // var socket = io();

      // socket.on('connect', function(data) { // eslint-disable-line no-unused-vars
      //   // console.error('--- CONNECTED! ---', data);

      //   // _this.connected = true;
      //   // clearInterval(_this.connectTimer);

      //   if (_this.state.error) {
      //     _this.setState({error: null});
      //   }

      //   _this.socket = socket;

      //   // if (!_this.authenticated) {
      //   // socket.emit('authenticate', payload);

      //   // }
      // });
      // socket.on('version', function(version) {
      //   if (_this.state.version && version !== _this.state.version) {
      //     //  (' + version + ' is newer than ' + _this.state.version + ')
      //     _this.setState({systemMessage: 'Yay! Notebase has been updated with new features and/or bug fixes. You should <a href="' + window.location.href + '">refresh the page</a> as soon as possible.'});
      //   }
      // });
      // socket.on('locked', function(lock) { // eslint-disable-line no-unused-vars
      //   _this.handleLocked(lock);
      // });
      // socket.on('unlock', function(data) {
      //   _this.handleUnlock(data);
      // });
      // socket.on('authenticated', function(data) { // eslint-disable-line no-unused-vars
      //   // console.log('YAY! Authenticated :)'); // eslint-disable-line no-console
      //   // Remove any existing listeners
      //   socket.removeAllListeners('cmd');
      //   socket.removeAllListeners('sync');
      //   socket.removeAllListeners('import-done');
      //   socket.removeAllListeners('import-error');
      //   socket.removeAllListeners('import-lines');
      //   socket.removeAllListeners('import-line-progress');
      //   socket.removeAllListeners('import-nodes');
      //   socket.removeAllListeners('import-node-progress');

      //   socket.on('cmd', _this.incomingCommand);
      //   socket.on('sync', function(clientData) {
      //     _this.setAppState(clientData);
      //   });

      //   socket.on('import-done', function(importData) {
      //     var newNodes = importData.added.concat(importData.updated);
      //     var updatedIds = _.map(importData.updated, 'id');
      //     var minusUpdated = _.xreject(_this.state.entities.nodes, function(n) { return updatedIds.indexOf(n.id) !== -1; });
      //     var tweakedNodes = minusUpdated.concat(newNodes);
      //     var newEntities = Object.assign({}, _this.state.entities);
      //     newEntities.nodes = _this.decorateNodesWithAccessLevel(_this.state.entities, tweakedNodes, _this.org(), _this.user());
      //     _this.setAppState({error: null, entities: newEntities, updatedNodes: newNodes, importError: null, importing: false});
      //     _this.setPanel(null);
      //   });
      //   socket.on('import-error', function(importError) { // eslint-disable-line no-unused-vars
      //     _this.setAppState({error: null, importError: true, importing: false});
      //   });
      //   socket.on('import-lines', function(lines) {
      //     if (_this.state.importing) {
      //       $('#import-progress').text('Evaluating 0 / ' + lines.count + ' lines');
      //     }

      //     _this.importLines = lines.count;
      //   });
      //   socket.on('import-line-progress', function(progress) {
      //     if (_this.state.importing) {
      //       $('#import-progress').text('Evaluating ' + progress.lines + ' / ' + _this.importLines + ' lines');
      //     }
      //   });
      //   socket.on('import-nodes', function(nodes) {
      //     if (_this.state.importing) {
      //       $('#import-progress').text('Importing 0 / ' + nodes.count + ' nodes');
      //     }

      //     _this.importNodes = nodes.count;
      //   });
      //   socket.on('import-node-progress', function(progress) {
      //     if (_this.state.importing) {
      //       $('#import-progress').text('Importing ' + progress.nodes + ' / ' + _this.importNodes + ' nodes');
      //     }
      //   });

      //   // if (typeof callback === 'function' && !_this.authenticated) {
      //   if (typeof callback === 'function' && doCallback) {
      //     // console.log('Doing callback in setupSocket...');
      //     callback();
      //   }
      //   doCallback = false;

      //   _this.authenticated = true;
      // });

      // socket.on('err', function(data) {
      //   console.error('GOT ERROR:', data); // eslint-disable-line no-console
      //   _this.setState({serverError: data.error});
      // });
      // socket.on('reconnect_attempt', function(data) {
      //   console.error('---RECONNECT ATTEMPT...---', data); // eslint-disable-line no-console
      // });
      // socket.on('reconnect_error', function(data) {
      //   console.error('---RECONNECT ERROR---', data); // eslint-disable-line no-console
      //   _this.setState({error: 'disconnected'});
      // });
      // socket.on('reconnect_failed', function(data) {
      //   console.error('---RECONNECT FAILED---', data); // eslint-disable-line no-console
      // });
      // socket.on('connect_error', function(data) {
      //   console.error('---CONNECT ERROR---', data); // eslint-disable-line no-console
      // });
      // socket.on('connect_timeout', function(data) {
      //   console.error('---CONNECT TIMEOUT---', data); // eslint-disable-line no-console
      // });
      // socket.on('reconnecting', function(data) {
      //   console.error('---RECONNECTING...---', data); // eslint-disable-line no-console
      // });
      // socket.on('reconnect', function(data) {
      //   console.error('--- RECONNECTED! :) ---', data); // eslint-disable-line no-console

      //   _this.loadInitialData(true);
      // });
      // socket.once('disconnect', function(data) {
      //   console.error('WAS DISCONNECTED (first time)', data); // eslint-disable-line no-console

      //   // socket.connect();
      // });
      // socket.on('disconnect', function(data) {
      //   console.error('WAS DISCONNECTED (second time?)', data); // eslint-disable-line no-console
      //   _this.connected = false;
      //   _this.setState({error: 'disconnected'});
      // });
    }
  },

  handleLocked: function (lock) {
    console.log('GOT "locked"', lock) // eslint-disable-line no-console
    // window._app.editor.setReadOnly(true);
    window._app.editor.setOption('readOnly', 'nocursor')
    this.setState({ editLock: lock, activeNodeOnly: false })
  },

  handleUnlock: function (data) {
    var updatedData = _.extend(data, { session_id: window._app.session_id })
    console.log('GOT "unlock"', data) // eslint-disable-line no-console
    if (this.state.editNodeId && this.state.editNodeId === data.node_id) {
      this.emit('lock', updatedData)
      console.log('Editing the unlocked node, locking...') // eslint-disable-line no-console
      // window._app.editor.setReadOnly(false);
      window._app.editor.setOption('readOnly', false)
      this.setState({ editLock: null, activeNodeOnly: false })
    } else {
      console.log('NOT editing the unlocked node, declining...') // eslint-disable-line no-console
      this.emit('decline-lock', updatedData)
    }
  }
}
