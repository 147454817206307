import './styles.css'

import Heading from '../sections/heading'
import React from 'react'

import Misc from '../../../js/misc'

export default class BgScope extends React.Component {
  setOrUnsetBackground = scope => {
    const { app, data } = this.props
    if (data.type === 'unsplash') {
      app.pushSidebar(['unsplash', { scope }])
    } else if (data.type === 'custom') {
      app.launchFP({}, response => {
        const files = response.filesUploaded
        Misc.setBackgroundImage(app, scope, Misc.fileUrl(files[0]), '#ddd')
      })
    } else {
      Misc.setBackgroundImage(app, scope, null, null)
    }
  }

  render () {
    const { app, data } = this.props
    const rootBg = app.getBgImageAndColorForScope('rootNode')
    const tabBg = app.getBgImageAndColorForScope('tab')
    const userBg = app.getBgImageAndColorForScope('user')
    const globalBg = app.getBgImageAndColorForScope('global')
    return (
      <div className='body full-height bg-scope'>
        <Heading {...this.props} name='Choose scope' isMain />
        <div className='panel scopes'>
          <h3>{data.type === 'unset' ? 'Unset' : 'Set'} background for...</h3>
          {app.rootNodeId() && (
            <div
              onClick={() => this.setOrUnsetBackground('node')}
              className='root'
              style={rootBg ? { backgroundImage: `url(${rootBg.uri})` } : {}}
            >
              <div>
                <i className='fa fa-fw fa-circle' />
              </div>
              <button>Current Root Node</button>
            </div>
          )}
          <div
            onClick={() => this.setOrUnsetBackground('tab')}
            className='tab'
            style={tabBg ? { backgroundImage: `url(${tabBg.uri})` } : {}}
          >
            <div>
              <i className='fa fa-fw fa-folder-o' />
            </div>
            <button>Current Tab</button>
          </div>
          <div
            onClick={() => this.setOrUnsetBackground('org')}
            className='org'
            style={userBg ? { backgroundImage: `url(${userBg.uri})` } : {}}
          >
            <div>
              <i className={`fa fa-fw fa-${app.orgId() ? 'users' : 'user'}`} />
            </div>
            <button>
              {app.orgId() ? 'Current Team' : 'Your Personal Notebase'}
            </button>
          </div>
          <div
            onClick={() => this.setOrUnsetBackground('global')}
            className='global'
            style={
              globalBg
                ? { backgroundImage: `url(${globalBg.uri})` }
                : { backgroundColor: 'red' }
            }
          >
            <div>
              <i className='fa fa-fw fa-globe' />
            </div>
            <button>Globally</button>
          </div>
          <p>
            Note that background settings are strictly personal - setting a
            background for a team affects no one else, just you when viewing
            that team.
          </p>
        </div>
      </div>
    )
  }
}
