import './styles.css'

import React from 'react'
import constants from '../../..//lib/constants'
import Breadcrumbs from './breadcrumbs'
import Bullet from '../../bullet'

import Misc from '../../../js/misc'

class NodesTopBar extends React.Component {
  toggleViewMode = ev => {
    this.props.app.toggleViewMode()
    ev && ev.preventDefault && ev.preventDefault()
  }

  toggleEditing = () => {
    this.props.app.toggleEditing()
  }

  settings = () => {
    var node = this.props.rootNode || this.props.app.state.share.node
    this.props.app.setPanel('note-settings-overlay', {
      data: { node: node },
      title: 'Settings for "' + node.title + '"',
      activeSection: 'settings'
    })
  }

  publish = () => {
    var node = this.props.rootNode || this.props.app.state.share.node
    this.props.app.setPanel('note-settings-overlay', {
      data: { node: node },
      title: 'Settings for "' + node.title + '"',
      activeSection: 'settings'
    })
  }

  toggleFilter = () => {
    const { app } = this.props
    const last = app.state.sidebarStack[app.state.sidebarStack.length - 1]
    if (last && last[0] === 'filters') {
      app.popSidebar()
    } else {
      app.pushSidebar(['filters'])
    }
  }

  clearFilter = ev => {
    ev.stopPropagation()
    const { app } = this.props
    app.setAppState({ filter: null })
  }

  render () {
    const { app, rootNode } = this.props
    let editButton, viewModeToggle, settingsButton, publishButton

    // var resolvedNode = Node.getResolved(rootNode, this.props.app.state.entities.nodes);

    // view mode toggle
    if (app.isListView()) {
      viewModeToggle = [
        <div
          key='view-mode-list'
          className='button'
          onClick={this.toggleViewMode}
        >
          <i className='fa fa-fw fa-list' /> List mode
        </div>
      ]
    } else {
      viewModeToggle = [
        <div
          key='view-mode-tree'
          className='button'
          onClick={this.toggleViewMode}
        >
          <i className='fa fa-fw fa-tree' /> Tree mode
        </div>
      ]
    }

    // edit button
    // if (!app.state.editNodeId && rootNode && this.props.currentNodeAccessLevel >= constants.WRITE_ACCESS && (resolvedNode.type === 'note' || resolvedNode.type === 'site')) {
    if (
      !app.state.editNodeId &&
      rootNode &&
      this.props.currentNodeAccessLevel >= constants.WRITE_ACCESS
    ) {
      editButton = (
        <div className='button' onClick={this.toggleEditing}>
          <i className='fa fa-fw fa-edit' /> Edit
        </div>
      )
    }

    // settings button
    if (rootNode && rootNode.type === 'site' && !app.state.editNodeId) {
      settingsButton = (
        <div className='button' onClick={this.settings}>
          <i className='fa fa-fw fa-cog' /> Settings
        </div>
      )
    }

    // publish button
    // if (
    //   !app.state.editNodeId &&
    //   rootNode &&
    //   this.props.currentNodeAccessLevel >= constants.WRITE_ACCESS
    // ) {
    //   if (rootNode && rootNode.type === 'list' && rootNode.body) {
    //     var publishHandler, publishTitle // , publishClasses = 'icon-button';
    //     if (rootNode.settings.published) {
    //       publishHandler = this.settings
    //       // publishClasses += ' mod';
    //       publishTitle = 'Published'
    //     } else {
    //       publishHandler = this.publish
    //       publishTitle = 'Publish'
    //     }

    //     publishButton = (
    //       <div className='button' onClick={publishHandler}>
    //         <i className='fa fa-fw fa-bullhorn' /> {publishTitle}
    //       </div>
    //     )
    //   }
    // }

    const content = (
      <div id='nodes-top-bar'>
        {Boolean(
          !app.state.searchstring &&
            rootNode &&
            !app.editNodeId() &&
            app.state.rootIsBoard
        ) && (
          <div className='current-node' data-rootnode-id={rootNode.id}>
            <Bullet app={app} node={rootNode} />
            <div className='title'>{Misc.truncate(rootNode.title, 25)}</div>
          </div>
        )}
        <Breadcrumbs
          app={app}
          user={this.props.user}
          rootNode={rootNode}
          tab={this.props.tab}
          sharedShares={this.props.sharedShares}
        />
        <div className='right-buttons'>
          {editButton}
          {publishButton}
          {settingsButton}
          {app.featureEnabled('list_mode', this.props.user) && viewModeToggle}
        </div>
        <div
          className={`filter-toggler ${
            Misc.isFilterEmpty(app.state.filter) ? '' : 'is-active'
          }`}
          onClick={this.toggleFilter}
        >
          <i className='fa fa-fw fa-filter' />
          {!Misc.isFilterEmpty(app.state.filter) && (
            <span className='text'>Filter is active</span>
          )}
          {!Misc.isFilterEmpty(app.state.filter) && (
            <span onClick={this.clearFilter}>
              <i className='fa fa-fw fa-times' />
            </span>
          )}
        </div>
      </div>
    )

    return content
  }
}

module.exports = NodesTopBar
