import React from 'react'
import $ from 'jquery'
import Misc from '../../js/misc'

import './styles'

class MainEditorPreview extends React.Component {
  state = {
    parsedBody: ''
  }

  handlePreviewScroll = () => {
    if (
      this.props.app.state.scrollSync &&
      (!window._app.scrollSrc || window._app.scrollSrc === 'preview')
    ) {
      var editorScrollInfo = window._app.editor.getScrollInfo()
      var editorHeight = editorScrollInfo.height
      var previewContainerEl = $('#editor-preview-container')
      var previewEl = $('#editor-preview')
      var previewHeight = previewEl.height()
      var previewScrollPos = previewContainerEl.scrollTop()
      var previewScrollPercentage = previewScrollPos / previewHeight
      var editorScrollPos = previewScrollPercentage * editorHeight

      // console.log('preview scrolls editor');
      window._app.scrollSrc = 'preview'
      clearTimeout(window._app.scrollTimer)
      window._app.scrollTimer = setTimeout(function () {
        window._app.scrollSrc = null
      }, 100)
      window._app.editor.scrollTo(null, editorScrollPos)

      // setTimeout(function() {
      // }, 10);
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.editNode) {
      this.setState({ parsedBody: Misc.parseMarkdown(nextProps.editNode.body) })
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    if (this.props.editNode) {
      this.setState({
        parsedBody: Misc.parseMarkdown(this.props.editNode.body)
      })
    }
  }

  shouldComponentUpdate () {
    // if (nextProps.editNode.id !== this.props.editNode.id ||
    //     nextProps.editNode.body !== this.props.editNode.body ||
    //     nextProps.editorCompanion !== this.props.editorCompanion) {
    //   return true;
    // }
    return true
    // return false;
  }

  render () {
    var preview = null
    if (this.props.editNode && this.props.editorCompanion === 'preview') {
      preview = (
        <div id='editor-preview-container' onScroll={this.handlePreviewScroll}>
          <div className='preview-heading'>Preview</div>
          <div
            id='editor-preview'
            className='note-body'
            dangerouslySetInnerHTML={{ __html: this.state.parsedBody }}
          />
        </div>
      )
    }
    return preview
  }
}

module.exports = MainEditorPreview
