import './styles.css'

import React from 'react'
import $ from 'jquery'

import classNames from 'classnames'
import Text from '../../js/text'

class Bullet extends React.Component {
  state = {
    ctxOpen: false
  }

  handleBulletClick = () => {
    clearTimeout(this.contextTimer)
    if (!this.props.node.inbox) {
      this.props.app.setRoot(this.props.node)
      // var li = $(this.refs.aside).closest('li.node');
      // var src = li.find('div.node-content:first')[0];
      // var dst = $('#body-container h2.node-heading')[0];
      // Misc.transform(dst, src);
    }
  }

  // handleMouseEnter: function() {
  //   var _this = this;
  //   window.contextTimer = setTimeout(function(){
  //     _this.setState({ctxOpen: true});
  //   }, 500);
  // },

  // handleMouseLeave: function() {
  //   clearTimeout(window.contextTimer);
  //   if (this.state.ctxOpen) {
  //     this.setState({ctxOpen: false});
  //   }
  // },

  dragStart = ev => {
    var _this = this
    ev.preventDefault()
    window._app.dragStartTimeout = setTimeout(function () {
      _this.props.app.scrollSmoothly()
      $('#drag-indicator').addClass('active')
      window._app.dragElementType = 'node'
      window._app.nodesEl = $('#nodes')
      window._app.firstNode = $('#nodes li.node:first')
      window._app.lastNode = $('#nodes li.node:last')
      window._app.rightOffset =
        window._app.nodesEl.offset().left + window._app.nodesEl.width()

      var focusEl = $(':focus')
      if (focusEl.length) {
        Text.saveSelection(focusEl.get(0))
      }
      _this.props.app.setAppState({ floater: null })
      clearTimeout(_this.contextTimer)
      console.log('dragstart') // eslint-disable-line no-console
      // XXX: DIRTY! Make ctrls play nice with react
      // $('.ctrl-container').hide();
      $('#app-wrapper').addClass('drag-in-progress')
      $('[contenteditable]')
        .attr('contenteditable', false)
        .addClass('were-editable')
      // if (this.props.node.type !== 'bookmark') {
      //   var li = $(e.target).closest('li.node');
      //   var div = li.find('> .indented > .indent-wrapper > .node-content > div > div.data[contenteditable]');
      //   console.log(div.get(0));
      //   html = Node.parseHtml(div.html());
      //   Node.setTextUndo(this.props.node.id, html, this.props.undo, this.props.nodes, this.props.replaceNodes);
      //   Text.saveSelection(div.get(0));
      // }
      // this.props.app.setAppState({dragged: this.props.node.id});
      window._app.dragged = _this.props.node
      window._app.draggedOver = null
      var li = $(_this.aside).closest('li.node')
      $('li.node').removeClass('dragged')
      $(li).addClass('dragged')
      // e.dataTransfer.effectAllowed = 'move';
      if (_this.props.node.inbox) {
        var dragIcon = document.createElement('img')
        dragIcon.src = '/static/bullet.png'
        dragIcon.width = 16
        // e.dataTransfer.setDragImage(dragIcon, -10, -10);
      }

      // Firefox requires dataTransfer data to be set
      // e.dataTransfer.setData('text/html', e.currentTarget);
    }, 200)
  }

  shouldComponentUpdate (nextProps, nextState) {
    if (
      nextProps.node === this.state.node &&
      nextState.ctxOpen === this.state.ctxOpen
    ) {
      return false
    }
    return true
  }

  setBulletState = state => {
    this.setState(state)
  }

  render () {
    var bulletClasses = classNames({
      bullet: true,
      noselect: true,
      open: this.state.ctxOpen
    })

    let icon = this.props.app.nodeIcon(this.props.node)

    return (
      <aside
        ref={aside => {
          this.aside = aside
        }}
        className={bulletClasses}
        onClick={this.handleBulletClick}
      >
        {icon}
        <i className='dot remote' />

        <em onMouseDown={this.dragStart} />
      </aside>
    )
  }
}

module.exports = Bullet
