const React = require('react')

class DueListSettingsOverlay extends React.Component {
  state = {}

  handleChangeRoot = root => {
    var newSettings = { due_list_root: root }
    this.props.app.saveUserSettings(
      newSettings,
      this.props.app.getCommonParams()
    )
  }

  handleAssignedTo = assignedTo => {
    var newSettings = { show_assigned_to: assignedTo }
    this.props.app.saveUserSettings(
      newSettings,
      this.props.app.getCommonParams()
    )
  }

  handleAutoHide = e => {
    var newSettings = { auto_hide_due_list: e.target.checked }
    this.props.app.saveUserSettings(
      newSettings,
      this.props.app.getCommonParams()
    )
  }

  render () {
    const user = this.props.user

    return (
      <div>
        <div className='content radio'>
          <div className='row'>
            <div className='row-heading'>
              <label>Show due items from:</label>
            </div>
            <div className='row-content'>
              <div className='input-label-wrapper'>
                <input
                  type='radio'
                  onChange={() => this.handleChangeRoot(null)}
                  checked={user.settings.due_list_root === null}
                />
                <label>From entire Notebase</label>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='row-content'>
              <div className='input-label-wrapper'>
                <input
                  type='radio'
                  onChange={() => this.handleChangeRoot('tab')}
                  checked={user.settings.due_list_root === 'tab'}
                />
                <label>From current tab (if pinned)</label>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='row-content'>
              <div className='input-label-wrapper'>
                <input
                  type='radio'
                  onChange={() => this.handleChangeRoot('node')}
                  checked={user.settings.due_list_root === 'node'}
                />
                <label>Below the current item</label>
              </div>
            </div>
          </div>
        </div>
        <div className='content radio'>
          <div className='row'>
            <div className='row-heading'>
              <label>Show due items assigned to:</label>
            </div>
            <div className='row-content'>
              <div className='input-label-wrapper'>
                <input
                  type='radio'
                  onChange={() => this.handleAssignedTo(null)}
                  checked={!user.settings.show_assigned_to}
                />
                <label>Anyone</label>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='row-content'>
              <div className='input-label-wrapper'>
                <input
                  type='radio'
                  onChange={() => this.handleAssignedTo(user.id)}
                  checked={user.settings.show_assigned_to === user.id}
                />
                <label>Me</label>
              </div>
            </div>
          </div>
        </div>
        {/*
          <div className='row'>
            <div className='row-content'>
              <div className='input-label-wrapper'>
                <input
                  type='checkbox'
                  checked={this.props.user.settings.auto_hide_due_list}
                  onChange={this.handleAutoHide}
                />{' '}
                <label>Auto hide when empty</label>
              </div>
            </div>
          </div>
        */}
      </div>
    )
  }
}

module.exports = DueListSettingsOverlay
