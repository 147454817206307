import React from 'react'
import enhanceWithClickOutside from 'react-click-outside'

class EditorCtrlDropdown extends React.Component {
  state = {
    isOpen: false
  }

  handleClickOutside = () => {
    this.setState({ isOpen: false })
  }

  handleScrollSyncChange = () => {
    this.props.app.setState(
      { scrollSync: !this.props.app.state.scrollSync },
      () => {
        if (this.props.user) {
          var newSettings = { ...this.props.user.settings }
          newSettings.scroll_sync = this.props.app.state.scrollSync
          this.props.app.saveUserSettings(
            newSettings,
            this.props.app.getCommonParams()
          )
        }
      }
    )
  }

  toggleBackgroundImage = () => {
    const { component } = this.props
    component.setState({ disableBg: !component.state.disableBg })
  }

  render () {
    return (
      <span
        className={`${this.props.className || ''} ctrl-btn`}
        onClick={() => this.setState({ isOpen: !this.state.isOpen })}
      >
        <i className='fa fa-fw fa-bars' />
        {this.state.isOpen && (
          <div className='hamburger-menu'>
            {this.props.mode !== 'full-screen' && (
              <div className='hamburger-menu-items'>
                <div onClick={this.handleScrollSyncChange}>
                  {!this.props.app.state.scrollSync && 'Enable'}
                  {this.props.app.state.scrollSync && 'Disable'} scroll sync
                </div>
              </div>
            )}
            {this.props.mode === 'full-screen' && (
              <div className='hamburger-menu-items'>
                <div onClick={this.toggleBackgroundImage}>
                  {!this.props.disableBg && 'Disable'}
                  {this.props.disableBg && 'Enable'} background image
                </div>
              </div>
            )}
          </div>
        )}
      </span>
    )
  }
}

export default enhanceWithClickOutside(EditorCtrlDropdown)
