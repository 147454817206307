import React from 'react'

import './styles'

class EditLock extends React.Component {
  stealLock = ev => {
    const { app } = this.props
    const rootNode = app.rootNode()
    const user = app.user()
    var lockData = { node_id: rootNode.id, session_id: window._app.session_id }
    if (this.props.user) {
      lockData.user_id = user.id
    }

    this.props.app.emit('steal-lock', lockData)
    ev.preventDefault()
  }

  render () {
    const { app } = this.props
    const user = app.user()
    var lockClasses = 'full-screen'
    var editLock = null
    if (this.props.app.state.editNodeId && this.props.app.state.editLock) {
      var lockName
      if (this.props.app.state.editLock.user) {
        if (this.props.app.state.editLock.user.id === user.id) {
          lockName =
            ' you (probably because you reloaded the page while editing) '
        } else {
          lockName = (
            <strong>
              {this.props.app.state.editLock.user.first_name}
              {this.props.app.state.editLock.user.last_name}
            </strong>
          )
        }
      } else {
        lockName =
          'an unknown user (session ID "' +
          this.props.app.state.editLock.session_id +
          '")'
      }

      editLock = (
        <div id='editlock-container' className={lockClasses}>
          <div id='editlock'>
            <p>
              This item is locked by {lockName} and cannot be edited by you.
            </p>
            <p>
              However, you can{' '}
              <strong>
                <a href='' onClick={this.stealLock}>
                  steal the lock
                </a>
              </strong>{' '}
              if you really need to make changes. This will prevent {lockName}{' '}
              from making any further changes until you are done (or they steal
              the lock back).
            </p>
          </div>
          <div id='editlock-dimmer' />
        </div>
      )
    }
    return editLock
  }
}

module.exports = EditLock
