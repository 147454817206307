import './styles.css'

import React from 'react'
import _ from 'lodash'

import Heading from '../heading'
import SingleNode from '../single_node'

class LatestActivity extends React.Component {
  state = {
    activity: [],
    ready: true,
    wasQueued: false
  }

  getActivity = props => {
    var numOfEntries = 5
    var nodes = props.orgNodes.filter(n => {
      return n.title
    }) // this.props.app.state.entities.nodes;
    var comments = props.app.state.entities.comments
    var latestComments = _.sortBy(comments, 'created_at')
      .reverse()
      .slice(0, numOfEntries)
    // var latestCreatedNodes = _.sortBy(nodes, 'created_at').reverse().slice(0, numOfEntries);
    var latestUpdatedNodes = _.sortBy(nodes, 'updated_at')
      .reverse()
      .slice(0, numOfEntries)
    var activity = []
    activity = activity.concat(
      latestComments.map(function (c) {
        return {
          entity: c,
          time: c.created_at,
          type: 'comment',
          action: 'create',
          body:
            'Commented on "' +
            ((
              props.orgNodes.find(n => {
                return n.id === c.node_id
              }) || {}
            ).title || '(no title)') +
            '"'
        }
      })
    )
    // activity = activity.concat(latestCreatedNodes.map(function(n) { return {time: n.created_at, type: 'node',    action: 'create', body: 'Node created: ' + n.title, entity: n}; }));
    activity = activity.concat(
      latestUpdatedNodes.map(function (n) {
        return {
          time: n.updated_at,
          type: 'node',
          action: 'update',
          body: n.title,
          entity: n
        }
      })
    )
    this.setState({
      activity: _.sortBy(activity, 'time')
        .reverse()
        .slice(0, numOfEntries)
    })
  }

  handleClick = activityItem => {
    var entity = activityItem.entity
    if (activityItem.type === 'node') {
      this.props.app.setRoot(entity.parent_id)
    } else if (activityItem.type === 'comment') {
      this.props.app.setCommentNode(entity.node_id, { highlight: entity.id })
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps (nextProps) {
    var _this = this
    if (this.state.ready) {
      if (nextProps && nextProps.orgNodes) {
        this.getActivity(nextProps)
      }
      this.setState({ ready: false }, function () {
        _this.updateTimer = setTimeout(function () {
          if (_this.state.wasQueued) {
            _this.getActivity(_this.props)
          }
          _this.setState({ ready: true, wasQueued: false })
        }, 5000)
      })
    } else {
      this.setState({ wasQueued: true })
    }
  }

  componentDidMount () {
    if (this.props && this.props.orgNodes) {
      this.getActivity(this.props)
    }
  }

  componentWillUnmount () {
    clearTimeout(this.updateTimer)
  }

  render () {
    if (!this.props.orgNodes) return false
    var list
    var activity = this.state.activity
    if (activity.length) {
      list = activity.map(a => {
        var avatarUser =
          a.type === 'comment'
            ? a.entity.user_id
            : a.action === 'update'
              ? a.entity.updated_by
              : a.entity.created_by
        return (
          <SingleNode
            key={'activity-' + a.entity.id}
            app={this.props.app}
            onClick={() => this.handleClick(a)}
            user={avatarUser}
            body={a.body}
            time={a.time}
            showPath
            node={a.entity}
          />
        )
      })
    } else {
      list = [
        <div key='no-activity' className='no-items'>
          Nothing found.
        </div>
      ]
    }
    return (
      <div id='latest-activity' className='panel'>
        <Heading {...this.props} name='Latest Activity' />
        <div className='activity-list'>{list}</div>
      </div>
    )
  }
}

module.exports = LatestActivity
