import React from 'react'

class ManageUsersSection extends React.Component {
  state = {}

  componentDidMount () {}

  render () {
    var content = <div>Manage users</div>

    return content
  }
}

module.exports = ManageUsersSection
