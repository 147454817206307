import React from 'react'
import _ from 'lodash'
import Shortcuts from '../../../../../js-modules/shortcuts'

require('./keyboard_section.css')

class KeyboardSection extends React.Component {
  state = {}

  componentDidMount () {}

  render () {
    var content = <div>Keyboard section</div>
    var user = this.props.app.user()

    if (
      !this.props.app.state.editNodeId &&
      this.props.app.state.loaded &&
      ((!user && this.props.app.state.anonShare) || user)
    ) {
      var rows = Shortcuts.getShortcuts().map(function (shortcut, i) {
        i++
        var keys = _.flatten([shortcut.keys])
          .join(', ')
          .replace(/\+/g, ' + ')
          .replace(/enter/, '\u23ce')
          .replace(/shift/, '\u21E7')
          .replace(/command/, '\u2318')
          .replace(/backspace/, '\u232b')
          .replace(/option/, '\u2325')
        return (
          <div className='keyboard-shortcuts-row' key={'shortcut-' + i}>
            <div className='keyboard-shortcuts-col'>{shortcut.desc}</div>
            <div className='keyboard-shortcuts-col'>{keys}</div>
          </div>
        )
      })

      content = (
        <div className='keyboard-help'>
          <h4>Keyboard shortcuts</h4>
          <div className='wrapper'>{rows}</div>
        </div>
      )
    }

    return content
  }
}

module.exports = KeyboardSection
