import React from 'react'

class AnalyticsSection extends React.Component {
  state = {}

  componentDidMount () {}

  render () {
    var content = <div>Analytics</div>

    return content
  }
}

module.exports = AnalyticsSection
