import React from 'react'
import createReactClass from 'create-react-class'
import _ from 'lodash'
// import Mixins     from  '../../../../mixins/mixins';
import Org from '../../../../js/org'

// General sections
import ManageUsersSection from './sections/manage_users_section'
import ManageGroupsSection from './sections/manage_groups_section'
import SettingsSection from './sections/settings_section'
import PrivacySection from './sections/privacy_section'
import AnalyticsSection from './sections/analytics_section'
import PlansSection from './sections/plans_section'
import BillingHistorySection from './sections/billing_history_section'
import ThemesSection from './sections/themes_section'

const sectionTypes = {
  // eslint-disable-line no-unused-vars
  // segment each content type into their own files
  'manage-users-section': ManageUsersSection,
  'manage-groups-section': ManageGroupsSection,
  'settings-section': SettingsSection,
  'privacy-section': PrivacySection,
  'analytics-section': AnalyticsSection,
  'plans-section': PlansSection,
  'billing-history-section': BillingHistorySection,
  'themes-section': ThemesSection
}

// require('jquery.payment');
require('./styles')

const ManageOrganizationsPage = createReactClass({
  displayName: 'ManageOrganizationsPage',
  // mixins: [Mixins.FormHandling, Mixins.ErrorHandling],

  getInitialState: function () {
    var state = {
      activeSection: 'settings-section',
      pickerOpen: false
    }
    return state
  },

  UNSAFE_componentWillMount: function () {
    this.setState({ activeSection: this.props.activeSection })
  },

  closePanel: function () {
    this.props.app.setPanel(null)
  },

  switchSection: function (section) {
    this.setState({ activeSection: section })
  },

  activeSection: function (section) {
    if (this.state.activeSection === section) {
      return 'active'
    } else {
      return ''
    }
  },

  setOrg: function (orgId) {
    this.props.selectVtab(orgId)()
    this.setState({ pickerOpen: false })
  },

  getCurrentOrg: function () {
    var org
    if (
      this.props.activeVtabId &&
      this.props.app.state.entities.orgs &&
      this.props.app.state.entities.orgs.length
    ) {
      org = Org.get(this.props.activeVtabId, this.props.app.state.entities.orgs)
    }
    return org
  },

  renderPickerOrg: function (org, isCurrent, others) {
    const logoImage = org.settings.logo || '/img/org-img-placeholder.png'
    const clickHandler = isCurrent
      ? ev => {
        ev.stopPropagation()
        this.setState({ pickerOpen: !this.state.pickerOpen })
      }
      : () => this.setOrg(org.id)
    return (
      <div key={'org-' + org.id} onClick={clickHandler}>
        <div
          className='logo'
          style={{ backgroundImage: 'url(' + logoImage + ')' }}
        />
        <div className='name'>{org.name}</div>
        {Boolean(isCurrent && others.length) && (
          <div className='expand'>
            <i className='fa fa-fw fa-angle-down' />
          </div>
        )}
      </div>
    )
  },

  render: function () {
    var org, section
    var orgs = this.props.app.state.entities.orgs

    if (orgs && orgs.length) {
      org = this.getCurrentOrg()
      if (!org) {
        org = orgs[0]
      }

      // var isOwner = Org.isOwner(org, _this.props.user, _this.props.app.getCommonParams());
    }

    if (this.state.activeSection) {
      section = React.createFactory(sectionTypes[this.state.activeSection])(
        this.props
      )
    }

    const sortedOrgs = _.sortBy(orgs, 'name')
    const currentOrg = this.props.activeVtabId
      ? orgs.find(o => o.id === this.props.activeVtabId)
      : orgs[0]
    const otherOrgs = sortedOrgs.filter(o => o.id !== currentOrg.id)

    var otherOrgsList = otherOrgs.map(org => this.renderPickerOrg(org))

    const orgPicker = (
      <div className={`org-picker ${this.state.pickerOpen ? 'open' : ''}`}>
        <div className='current-org'>
          {this.renderPickerOrg(currentOrg, true, otherOrgs)}
        </div>
        {Boolean(otherOrgs.length && this.state.pickerOpen) && (
          <div className='other-orgs'>{otherOrgsList}</div>
        )}
      </div>
    )

    return (
      <div>
        <div className='heading'>
          <h2>Manage team</h2>
          <div className='close-settings-page' onClick={this.closePanel}>
            <i className='fa fa-fw fa-close' />
          </div>
        </div>

        <div className='flex-wrapper'>
          <div className='sidebar'>
            <div className='org-picker-container'>{orgPicker}</div>

            {/* <h4>General</h4> */}
            <ul>
              <li
                onClick={() => this.switchSection('settings-section')}
                className={this.activeSection('settings-section')}
              >
                <i className='fa fa-fw fa-cog' />Settings
              </li>
              <li
                onClick={() => this.switchSection('manage-users-section')}
                className={this.activeSection('manage-users-section')}
              >
                <i className='fa fa-fw fa-users' />Users
              </li>
              {/*
              <li
                onClick={() => this.switchSection('manage-groups-section')}
                className={this.activeSection('manage-groups-section')}
              >
                <i className='fa fa-fw fa-users' />Groups
              </li>
              */}
              {/*
              <li
                onClick={() => this.switchSection('privacy-section')}
                className={this.activeSection('privacy-section')}
              >
                <i className='fa fa-fw fa-shield' />Privacy
              </li>
              */}

              <li
                onClick={() => this.switchSection('plans-section')}
                className={this.activeSection('plans-section')}
              >
                <i className='fa fa-fw fa-heart' />Plan
              </li>
              <li
                onClick={() => this.switchSection('billing-history-section')}
                className={this.activeSection('billing-history-section')}
              >
                <i className='fa fa-fw fa-credit-card' />Billing history
              </li>
            </ul>

            {/*
            <h4>Appearance</h4>
            <ul>
              <li onClick={() => this.switchSection( 'themes-section')} className={this.activeSection('themes-section')}><i className="fa fa-fw fa-hand-lizard-o"></i>Themes (hide)</li>
            </ul>
            */}

            {/*
            <h4>Extra</h4>
            <ul>
              <li onClick={() => this.switchSection( 'analytics-section')} className={this.activeSection('analytics-section')}><i className="fa fa-fw fa-bar-chart"></i>Analytics (hide)</li>
            </ul>
            */}
          </div>
          <div className='content'>{section}</div>
        </div>
      </div>
    )
  }
})

module.exports = ManageOrganizationsPage
