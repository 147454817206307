var uuid = require('node-uuid')
var update = require('react-addons-update')

var Node = require('./node')

var NodeLink = {

  debug: false,

  add: function (link, params, callback) {
    const doAction = (actionParams) => {
      const newParams = Object.assign({}, actionParams)
      const nodes = actionParams.updatedState.entities ? actionParams.updatedState.entities.nodes : actionParams.state.entities.nodes
      const links = actionParams.updatedState.entities ? actionParams.updatedState.entities.node_links : actionParams.state.entities.node_links
      const updatedNodes = (actionParams.updatedState.updatedNodes || actionParams.state.updatedNodes || []).slice()

      const newLink = NodeLink.new(link, actionParams)
      const newLinks = links.concat([newLink])

      const srcNode = Node.get(newLink.src, nodes)
      let newSrcNode = srcNode
      newSrcNode = update(srcNode, {$merge: {outbound_link_count: srcNode.outbound_link_count + 1, updated_at: new Date().toISOString()}})
      const trimmedNodes = nodes.filter(n => n.id !== newSrcNode.id)
      const newNodes = trimmedNodes.concat([newSrcNode])
      updatedNodes.push(newSrcNode)

      const dstNode = Node.get(newLink.dst, nodes)
      let newDstNode = dstNode
      newDstNode = update(dstNode, {$merge: {inbound_link_count: dstNode.inbound_link_count + 1, updated_at: new Date().toISOString()}})
      const newTrimmedNodes = newNodes.filter(n => n.id !== newDstNode.id)
      const newestNodes = newTrimmedNodes.concat([newDstNode])
      updatedNodes.push(newDstNode)

      const undoData = {
        undo: [{obj: 'NodeLink', cmd: 'remove', args: [link.id]}],
        redo: [{obj: 'NodeLink', cmd: 'add', args: [link]}]
      }

      if (!actionParams._isUndoOperation && !actionParams._skipUndoAdd) {
        actionParams.undo.add(undoData, true)
      }

      const newEntities = Object.assign({}, (actionParams.updatedState.entities || actionParams.state.entities))
      newEntities.nodes = newestNodes
      newEntities.node_links = newLinks
      const result = {
        entities: newEntities,
        updatedNodes: updatedNodes
      }

      newParams.updatedState = Object.assign({}, actionParams.updatedState, result)
      newParams.undoData = actionParams.undoData || {}
      newParams.undoData.undo = (newParams.undoData.undo || []).concat(undoData.undo)
      newParams.undoData.redo = (newParams.undoData.redo || []).concat(undoData.redo)

      if (actionParams._skipSetState) {
        if (typeof callback === 'function') {
          callback(newParams)
        }
      } else {
        actionParams.setAppState(newParams.updatedState, newParams.stateParams, () => {
          if (typeof callback === 'function') {
            callback(newParams)
          }
        })
      }
    }

    if (params._skipFlushUndo) {
      doAction(params)
    } else {
      params.flushTextUndo(params, ret => {
        doAction(ret)
      })
    }
  },

  remove: function (linkId, params, callback) {
    const doAction = (actionParams) => {
      const newParams = Object.assign({}, actionParams)
      const nodes = actionParams.updatedState.entities ? actionParams.updatedState.entities.nodes : actionParams.state.entities.nodes
      const links = actionParams.updatedState.entities ? actionParams.updatedState.entities.node_links : actionParams.state.entities.node_links
      const link = NodeLink.get(linkId, links)
      const newLinks = links.filter(l => l.id !== linkId)
      const updatedNodes = (actionParams.updatedState.updatedNodes || actionParams.state.updatedNodes || []).slice()

      const srcNode = Node.get(link.src, nodes)
      let newSrcNode = srcNode
      newSrcNode = update(srcNode, {$merge: {outbound_link_count: srcNode.outbound_link_count - 1, updated_at: new Date().toISOString()}})
      const trimmedNodes = nodes.filter(n => n.id !== newSrcNode.id)
      const newNodes = trimmedNodes.concat([newSrcNode])
      updatedNodes.push(newSrcNode)

      const dstNode = Node.get(link.dst, nodes)
      let newDstNode = dstNode
      newDstNode = update(dstNode, {$merge: {inbound_link_count: dstNode.inbound_link_count - 1, updated_at: new Date().toISOString()}})
      const newTrimmedNodes = newNodes.filter(n => n.id !== newDstNode.id)
      const newestNodes = newTrimmedNodes.concat([newDstNode])
      updatedNodes.push(newDstNode)

      const undoData = {
        undo: [{obj: 'NodeLink', cmd: 'add', args: [link]}],
        redo: [{obj: 'NodeLink', cmd: 'remove', args: [link.id]}]
      }

      if (!actionParams._isUndoOperation && !actionParams._skipUndoAdd) {
        actionParams.undo.add(undoData, true)
      }

      const newEntities = Object.assign({}, (actionParams.updatedState.entities || actionParams.state.entities))
      newEntities.nodes = newestNodes
      newEntities.node_links = newLinks
      const result = {
        entities: newEntities,
        updatedNodes: updatedNodes
      }

      newParams.updatedState = Object.assign({}, actionParams.updatedState, result)
      newParams.undoData = actionParams.undoData || {}
      newParams.undoData.undo = (newParams.undoData.undo || []).concat(undoData.undo)
      newParams.undoData.redo = (newParams.undoData.redo || []).concat(undoData.redo)

      if (actionParams._skipSetState) {
        if (typeof callback === 'function') {
          callback(newParams)
        }
      } else {
        actionParams.setAppState(newParams.updatedState, newParams.stateParams, () => {
          if (typeof callback === 'function') {
            callback(newParams)
          }
        })
      }
    }

    if (params._skipFlushUndo) {
      doAction(params)
    } else {
      params.flushTextUndo(params, ret => {
        doAction(ret)
      })
    }
  },

  modify: function (linkOrId, data, params, callback) {
    const doAction = (actionParams) => {
      const newParams = Object.assign({}, actionParams)
      const links = actionParams.updatedState.entities ? actionParams.updatedState.entities.node_links : actionParams.state.entities.node_links
      const link = NodeLink.get(linkOrId, links)
      const newLink = update(link, {$merge: {updated_at: new Date().toISOString()}})
      const oldData = {}
      for (const key in data) {
        const keys = key.split('.')
        let obj = newLink
        for (let i = 0; i <= keys.length - 1; i++) {
          if (i === keys.length - 1) {
            obj[keys[i]] = data[key]
          }

          oldData[key] = link[key]
          obj = obj[keys[i]]
        }
      }
      const trimmedLinks = links.filter(c => c.id !== newLink.id)
      const newLinks = trimmedLinks.concat([newLink])

      const undoData = {
        undo: [{obj: 'NodeLink', cmd: 'modify', args: [link.id, oldData]}],
        redo: [{obj: 'NodeLink', cmd: 'modify', args: [link.id, data]}]
      }

      if (!actionParams._isUndoOperation && !actionParams._skipUndoAdd) {
        actionParams.undo.add(undoData, true)
      }

      const newEntities = Object.assign({}, (actionParams.updatedState.entities || actionParams.state.entities))
      newEntities.node_links = newLinks
      const result = {entities: newEntities}

      newParams.updatedState = Object.assign({}, actionParams.updatedState, result)
      newParams.undoData = actionParams.undoData || {}
      newParams.undoData.undo = (newParams.undoData.undo || []).concat(undoData.undo)
      newParams.undoData.redo = (newParams.undoData.redo || []).concat(undoData.redo)

      if (actionParams._skipSetState) {
        if (typeof callback === 'function') {
          callback(newParams)
        }
      } else {
        actionParams.setAppState(newParams.updatedState, newParams.stateParams, () => {
          if (typeof callback === 'function') {
            callback(newParams)
          }
        })
      }
    }

    if (params._skipFlushUndo) {
      doAction(params)
    } else {
      params.flushTextUndo(params, ret => {
        doAction(ret)
      })
    }
  },

  get: function (linkOrId, nodeLinks) {
    if (typeof linkOrId === 'string') {
      return nodeLinks.find(nl => nl.id === linkOrId)
    } else {
      return linkOrId
    }
  },

  new: function (data) {
    var time = new Date().toISOString()
    if (typeof data === 'undefined') data = {}
    data.id = uuid.v4()
    data.created_at = time
    data.updated_at = time
    return data
  }

}

module.exports = NodeLink
