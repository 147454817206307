var $ = require('jquery')

var Text = {
  savedSelection: {},

  getSelection: function (containerEl) {
    var start
    if (window.getSelection && document.createRange) {
      var sel = window.getSelection()
      if (
        sel.focusNode &&
        (sel.focusNode.contentEditable || sel.focusNode.nodeType === 3)
      ) {
        // 3 === text
        var range = sel.getRangeAt(0)

        // var position = 123
        // XXX: This dummy insertion thing is used to get the position of the cursor in the
        // *html* of the input, for use with rich text in titles. Unfortunately, the insertNode
        // causes focus to be lost, which is VERY BAD. This is why it has been disabled for now
        // var dummy = document.createTextNode('\u0001');
        // range.insertNode(dummy);
        // var position = $(containerEl).html().indexOf('\u0001');
        // dummy.parentNode.removeChild(dummy);

        // UPDATE 2018-10-27: This might be a better approach:
        // https://stackoverflow.com/questions/40747657/how-to-get-the-caret-position-of-a-contenteditable-div-which-contains-images

        var preSelectionRange = range.cloneRange()
        preSelectionRange.selectNodeContents(containerEl)
        preSelectionRange.setEnd(range.startContainer, range.startOffset)
        start = preSelectionRange.toString().length

        return {
          start: start,
          end: start + range.toString().length
          // htmlPos: position
        }
      } else {
        // console.log('Nope, can’t save selection here...', sel);
      }
    } else if (document.selection && document.body.createTextRange) {
      var selectedTextRange = document.selection.createRange()
      var preSelectionTextRange = document.body.createTextRange()
      preSelectionTextRange.moveToElementText(containerEl)
      preSelectionTextRange.setEndPoint('EndToStart', selectedTextRange)
      start = preSelectionTextRange.text.length

      return {
        start: start,
        end: start + selectedTextRange.text.length
      }
    }
  },

  saveSelection: function (el) {
    var ret = Text.getSelection(el)
    Text.savedSelection = ret
    return ret
  },

  restoreSelection: function (el) {
    if (Text.savedSelection) {
      Text._restoreSelection(el, Text.savedSelection)
    }
  },

  setSelection: function (el, start, end) {
    if (typeof end === 'undefined') end = start
    Text._restoreSelection(el, { start: start, end: end })
  },

  replace: function (el, newText, useHtml) {
    var $el = $(el)
    var sel = Text.getSelection(el)
    var orig
    if (useHtml) {
      orig = $el.html()
      $el.html(orig.substr(0, sel.htmlPos) + newText + orig.substr(sel.htmlPos))
    } else {
      orig = $el.text()
      $el.text(orig.substr(0, sel.start) + newText + orig.substr(sel.end))
    }
    Text.setSelection(el, sel.start + newText.length)
  },

  isBlank: function (str) {
    return !str || /^\s*$/.test(str)
  },

  _restoreSelection: function (containerEl, savedSel) {
    containerEl.focus()
    if (window.getSelection && document.createRange) {
      var charIndex = 0
      var range = document.createRange()
      range.setStart(containerEl, 0)
      range.collapse(true)
      var nodeStack = [containerEl]
      var node
      var foundStart = false
      var stop = false

      while (!stop && (node = nodeStack.pop())) {
        if (node.nodeType === 3) {
          var nextCharIndex = charIndex + node.length
          if (
            !foundStart &&
            savedSel.start >= charIndex &&
            savedSel.start <= nextCharIndex
          ) {
            range.setStart(node, savedSel.start - charIndex)
            foundStart = true
          }
          if (
            foundStart &&
            savedSel.end >= charIndex &&
            savedSel.end <= nextCharIndex
          ) {
            range.setEnd(node, savedSel.end - charIndex)
            stop = true
          }
          charIndex = nextCharIndex
        } else {
          var i = node.childNodes.length
          while (i--) {
            nodeStack.push(node.childNodes[i])
          }
        }
      }

      var sel = window.getSelection()
      sel.removeAllRanges()
      sel.addRange(range)
    } else if (document.selection && document.body.createTextRange) {
      var textRange = document.body.createTextRange()
      textRange.moveToElementText(containerEl)
      textRange.collapse(true)
      textRange.moveEnd('character', savedSel.end)
      textRange.moveStart('character', savedSel.start)
      textRange.select()
    }
  }
}

module.exports = Text
