import React from 'react'
import classnames from 'classnames'
import moment from 'moment'
import _ from 'lodash'
import $ from 'jquery'

import Avatar from '../../../avatar'
import CommentDropdown from '../comment_dropdown'

import Like from '../../../../js/like'

class CommentList extends React.Component {
  state = {}

  toggleMore = commentId => {
    var moreId = this.state.moreId ? null : commentId
    this.setState({ moreId: moreId })
  }

  toggleLike = comment => {
    var meLikey = this.props.app.state.entities.likes.find(l => {
      return l.user_id === this.props.user.id && l.comment_id === comment.id
    })
    if (meLikey) {
      Like.remove(meLikey.id, this.props.app.getCommonParams())
    } else {
      var newLike = { comment_id: comment.id, user_id: this.props.user.id }
      Like.add(newLike, this.props.app.getCommonParams())
    }
  }

  scrollTo = (id, isNewComment, isMount) => {
    var listEl = this.container
    if (listEl) {
      var $list = $(listEl)
      if (id) {
        var commentEl = $list.find('[data-id="comment-' + id + '"]')
        if (commentEl.length) {
          $list.scrollTop(commentEl.offset().top - $list.offset().top)
          commentEl.highlight(1000)
        }
      } else {
        if (isNewComment || isMount) {
          $list.scrollTop($list.prop('scrollHeight'))
        }
        if (isNewComment) {
          $list.find('.comment:last').highlight(1000)
        }
      }
      // $dom.animate({ scrollTop: $dom.prop('scrollHeight')}, 200, function() {
      //   setTimeout(function() {
      //     console.log('in timeout');
      //     $dom.scrollTop($dom.prop('scrollHeight'));
      //   }, 1000);
      // });
    }
  }

  componentDidMount () {
    var _this = this
    this.scrollTo(this.props.app.state.highlightComment, false, true)
    $('#comments .comment-form textarea').focus()
    if (this.props.app.state.highlightComment) {
      setTimeout(function () {
        _this.props.app.setState({ highlightComment: false })
      }, 0)
    }
  }

  componentDidUpdate (prevProps) {
    var _this = this
    var isNewComment = false
    if (
      prevProps.nodeId === this.props.nodeId &&
      prevProps.comments.length < this.props.comments.length
    ) {
      isNewComment = true
    }
    this.scrollTo(this.props.app.state.highlightComment, isNewComment, false)
    if (this.props.app.state.highlightComment) {
      setTimeout(function () {
        _this.props.app.setState({ highlightComment: false })
      }, 0)
    }
  }

  renderComment = (comment, isPinned) => {
    var _this = this
    var commentUser = this.props.app.user(comment.user_id)
    var more
    var keyPrefix = isPinned ? 'comment-pinned-' : 'comment-'
    if (this.state.moreId === keyPrefix + comment.id) {
      more = (
        <CommentDropdown
          app={_this.props.app}
          comment={comment}
          onClickOutside={_this.toggleMore}
        />
      )
    }
    var commentClasses = classnames({
      comment: true,
      pinned: isPinned,
      'pinned-in-list': comment.pinned,
      self: comment.user_id === this.props.user.id
    })
    var likeCount =
      comment.like_count === 0 ? null : (
        <span className='like-count'>{comment.like_count}</span>
      )
    var meLikey = this.props.app.state.entities.likes.find(
      l => l.user_id === _this.props.user.id && l.comment_id === comment.id
    )
    return (
      <div
        className={commentClasses}
        key={keyPrefix + comment.id}
        data-id={'comment-' + comment.id}
      >
        <div className='avatar'>
          <Avatar app={_this.props.app} user={comment.user_id} size='small' />
        </div>
        <div className='comment-contents'>
          <div className='comment-header'>
            <span className='userinfo'>{commentUser.first_name}</span>
            <span className='meta'>{moment(comment.created_at).fromNow()}</span>
            <div className='controls'>
              {likeCount}{' '}
              <i
                title='Like this'
                onClick={() => _this.toggleLike(comment)}
                className={
                  'control heart fa fa-fw ' +
                  (meLikey
                    ? 'fa-heart has-likes'
                    : comment.like_count > 0
                      ? 'fa-heart-o has-likes'
                      : 'fa-heart-o')
                }
              />
              <i
                onClick={() => _this.toggleMore(keyPrefix + comment.id)}
                className='control toggle-more fa fa-fw fa-angle-down'
              />
            </div>
            {more}
          </div>
          <div className='comment-body'>{comment.body}</div>
        </div>
      </div>
    )
  }

  render () {
    var _this = this
    var comments = this.props.comments
    var commentElements, pinnedElements, allElements
    if (comments.length) {
      commentElements = _.sortBy(comments, 'created_at').map(function (c) {
        return _this.renderComment(c)
      })
      pinnedElements = comments
        .filter(function (c) {
          return c.pinned
        })
        .map(function (c) {
          return _this.renderComment(c, true)
        })
      allElements = pinnedElements.concat(commentElements)
    } else {
      allElements = <div className='no-comments'>No comments yet.</div>
    }

    return (
      <div
        className='comment-list'
        ref={container => {
          this.container = container
        }}
      >
        {allElements}
      </div>
    )
  }
}

module.exports = CommentList
