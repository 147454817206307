/* @flow */

const $ = require('jquery')
const _ = require('lodash')

const Node = require('../../js/node')
const Misc = require('../../js/misc')
const Text = require('../../js/text')

module.exports = {
  resizeEditor: function () {
    window._app.editor.resize()
  },

  saveBody: function (autoSave) {
    // console.log('saving body', autoSave);
    const prevRoot = this.editNodeId()
    const doneCb = (doneParams, nValue) => {
      if (!autoSave) {
        this.setAppState(doneParams.updatedState, { body: nValue }, () => {
          if (prevRoot !== this.rootNodeId()) {
            this.setRoot(prevRoot)
          }
        })
      }
    }

    const commonParams = Object.assign({}, this.getCommonParams())
    commonParams.updatedState = {
      editLock: null,
      editNodeId: null,
      editTab: null
    }
    commonParams._skipFlushUndo = true

    if (autoSave) {
      commonParams._skipSetState = true
    }

    // var oldValue = this.rootNode().body;
    var editNode = this.editNode()
    var format = editNode.format || 'markdown'
    var oldValue = autoSave
      ? window._app.prev_body_values[this.state.editNodeId]
      : editNode.body
    var newValue =
      format === 'wysiwyg'
        ? this.state.editorStates[editNode.id].text
        : window._app.editor.getValue()
    var redoMods = {}
    var undoMods = {}
    // if (!Text.isBlank(newValue) && (editNode.type !== 'note' && editNode.type !== 'site')) {
    //   redoMods.type = 'note';
    //   undoMods.type = editNode.type;
    //   window.Intercom && window.Intercom('trackEvent', 'created-note'); // jshint ignore:line
    // } else if (Text.isBlank(newValue) && editNode.type === 'note') {
    //   redoMods.type = 'list';
    //   undoMods.type = 'note';
    // }

    if (format !== editNode.format) {
      redoMods.format = format
      undoMods.format = editNode.format
    }

    if (
      !(Text.isBlank(oldValue) && Text.isBlank(newValue)) &&
      oldValue !== newValue
    ) {
      redoMods.body = newValue
      undoMods.body = oldValue

      window._app.prev_body_values[this.state.editNodeId] = newValue

      if (autoSave) {
        redoMods.autosaved = true
        // } else if (format === 'markdown' && this.rootNode() && editNode && this.rootNode().id === this.state.editNodeId) {
        //   commonParams.updatedState.parsedBody = Misc.parseMarkdown(newValue);
      }
    }

    if (_.keys(redoMods).length) {
      Node.modify(this.state.editNodeId, redoMods, commonParams, modParams => {
        doneCb(modParams, newValue)
      })
    } else {
      doneCb(commonParams, newValue)
    }
  },

  handleEditorScroll: function (scroll) {
    // eslint-disable-line no-unused-vars
    if (
      this.state.scrollSync &&
      (!window._app.scrollSrc || window._app.scrollSrc === 'editor')
    ) {
      // if (!window._app.scrollSrc || window._app.scrollSrc === 'editor') {
      var editorHeight =
        window._app.editor.getSession().getScreenLength() *
        window._app.editor.renderer.lineHeight
      var previewContainerEl = $('#editor-preview-container')
      var previewEl = $('#editor-preview')
      var previewHeight = previewEl.height()
      var editorScrollPos = window._app.editor.getSession().getScrollTop()
      var editorScrollPercentage = editorScrollPos / editorHeight
      var previewScrollPos = editorScrollPercentage * previewHeight

      // console.log('editor scrolls preview');
      window._app.scrollSrc = 'editor'
      clearTimeout(window._app.scrollTimer)
      window._app.scrollTimer = setTimeout(function () {
        window._app.scrollSrc = null
      }, 100)
      previewContainerEl.scrollTop(previewScrollPos)
    }

    // setTimeout(function(){
    //   previewEl.scrollTo(0, editorScrollPos);
    // }, 10);
  },

  updateEditor: function (opts) {
    var body = opts.body || ''
    // window._app.editor.session.setValue(body);
    window._app.editor.setValue(body)
    if (opts.position) {
      window._app.editor.selection.setSelectionAnchor(
        opts.position.row,
        opts.position.column
      )
      window._app.editor.moveCursorToPosition(opts.position)
    }
    window._app.editor.focus()
  },

  toggleEditing: function (opts) {
    opts = opts || {}
    var tab = this.tab()
    var editNodeSrc = opts.editNode || this.rootNode()
    window._app.tab_states[tab.id] = window._app.tab_states[tab.id] || {}
    if (this.state.editTab) {
      window._app.tab_states[this.state.editTab.id] =
        window._app.tab_states[this.state.editTab.id] || {}
    }
    var _this = this
    var scrollPos, elStart, noteEl, otherEl, tabId, body
    var scrollPercentage = 0
    var changes = { editTab: null, editNode: null, editLock: null }
    if (this.state.editNodeId && !opts.force) {
      // noteEl = $('#editor');
      // otherEl = $('#note-body');
      // elStart = noteEl.offset().top;
      // scrollPos = $(document).scrollTop() - elStart - 20;
      // scrollPercentage = (scrollPos / noteEl.height()) * 100;
      tabId = this.state.editTab ? this.state.editTab.id : tab.id
      // window._app.tab_states[tabId].editorCursorPosition = window._app.editor.getCursorPosition();
      const curs = window._app.editor.getCursor()
      window._app.tab_states[tabId].editorCursorPosition = curs
      window._app.tab_states[tabId].editNode = null
      window._app.focusItem = 'node'
      clearInterval(this.saveTimer)
      clearTimeout(this.previewTimer)
      var lockData = { node_id: this.state.editNodeId, user_id: null }
      if (this.state.userId) {
        lockData.user_id = this.state.userId
      }

      this.emit('unlock', lockData)
      this.saveBody()
      if (window.fullscreen) {
        document.exitFullscreen()
      }
    } else {
      if (editNodeSrc) {
        var newEditorStates = Object.assign({}, this.state.editorStates)
        var newState = Object.assign({}, newEditorStates[editNodeSrc.id] || {})
        newState.text = editNodeSrc.body
        newEditorStates[editNodeSrc.id] = newState
        changes.editorStates = newEditorStates

        noteEl = $('#note-body')
        if (noteEl.length) {
          otherEl = $('#editor')
          elStart = noteEl.offset().top
          scrollPos = $(document).scrollTop() - elStart
          scrollPercentage = scrollPos / noteEl.height() * 100
        }

        if (!opts.skipSetState) {
          changes.editNodeId = editNodeSrc.id
        }

        if (!opts.skipSetState) {
          window._app.tab_states[tab.id].editNode = editNodeSrc.id
          if (!window._app.tab_states[tab.id].editorCursorPosition) {
            window._app.tab_states[tab.id].editorCursorPosition = {
              column: 0,
              row: 0
            }
          }
          // console.log('opts.customValue', opts.customValue); // eslint-disable-line no-console
          // console.log('editNode.body', editNode.body); // eslint-disable-line no-console
          body = opts.customValue ? opts.customValue : editNodeSrc.body
          window._app.prev_body_values[editNodeSrc.id] = body
          window._app.saveTimerId = editNodeSrc.id
        }

        if (_this.state.editorCompanion === 'lists') {
          changes.editTab = { ...tab }
        }

        var params = opts.skipSetState ? { startEditing: true } : {}

        // start auto-save timer
        // var i = 0;
        this.saveTimer = setInterval(function () {
          // console.log('saving...', i);
          if (
            _this.state.editNodeId &&
            _this.state.editNodeId === window._app.saveTimerId
          ) {
            // console.log('saving ACTUALLY...', i);
            _this.saveBody(true)
          }

          // i++;
        }, 5000)
        this.setAppState(changes, params, () => {
          // Necessary to make editor visible after changing "editNodeId" immediately before
          setTimeout(function () {
            var lockData = {
              node_id: _this.state.editNodeId,
              session_id: window._app.session_id,
              user_id: null
            }
            if (_this.state.userId) {
              lockData.user_id = _this.state.userId
            }
            _this.emit('lock', lockData)
            window._app.editor.focus()
            window._app.editor.refresh()
          }, 0)
        })
      }
    }

    if (opts.customScrollPos) {
      setTimeout(function () {
        window.scrollTo(0, opts.customScrollPos)
      }, 10)
    } else {
      if (scrollPercentage > 0) {
        setTimeout(function () {
          // console.log(otherEl.height());
          $(document).scrollTop(
            elStart + otherEl.height() * scrollPercentage / 100
          )
        }, 100)
      }
    }
  },

  updateBody: function (body) {
    this.setAppState(
      { parsedBody: Misc.parseMarkdown(body) },
      { keepUrl: true }
    )
  }
}
