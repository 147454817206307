import React from 'react'
import classNames from 'classnames'
import $ from 'jquery'

import Node from '../../js/node'

import EditLock from '../edit_lock'
import MarkdownEditor from '../markdown_editor'
import WysiwygEditor from '../wysiwyg_editor'
import MainEditorPreview from '../main_editor_preview'
import MainEditorCtrl from '../main_editor_ctrl'
import Dropdown from '../main_editor_ctrl/dropdown'

import './styles'

class MainEditor extends React.Component {
  state = {
    disableBg: false
  }
  // shouldComponentUpdate(nextProps) {
  //   if (nextProps.format !== this.props.format) {
  //     return true;
  //   }
  //   if (nextProps.editLock !== this.props.editLock) {
  //     return true;
  //   }
  //   if (nextProps.editNodeId === this.props.editNodeId) {
  //     return false;
  //   }
  //   return true;
  // }

  // window._app.editor.on('focus', function() {
  //   window._app.focusItem = 'editor';
  // });
  // window._app.editor.on('blur', function() {
  //   tabId = _this.state.editTab ? _this.state.editTab.id : tab.id;
  //   window._app.tab_states[tabId].editorValue = window._app.editor.getValue();
  //   window._app.tab_states[tabId].editorCursorPosition = window._app.editor.getCursorPosition();
  //   window._app.focusItem = 'node';
  // });

  // eslint-disable-next-line
  handleScroll = (editor, scrollInfo) => {
    if (
      this.props.app.state.scrollSync &&
      (!window._app.scrollSrc || window._app.scrollSrc === 'editor')
    ) {
      // if (!window._app.scrollSrc || window._app.scrollSrc === 'editor') {
      var editorHeight = scrollInfo.height
      var previewContainerEl = $('#editor-preview-container')
      var previewEl = $('#editor-preview')
      var previewHeight = previewEl.height()
      var editorScrollPos = scrollInfo.top
      var editorScrollPercentage = editorScrollPos / editorHeight
      var previewScrollPos = editorScrollPercentage * previewHeight

      // console.log('editor scrolls preview');
      window._app.scrollSrc = 'editor'
      clearTimeout(window._app.scrollTimer)
      window._app.scrollTimer = setTimeout(function () {
        window._app.scrollSrc = null
      }, 100)
      previewContainerEl.scrollTop(previewScrollPos)
    }

    // setTimeout(function () {
    //   // previewEl.scrollTo(0, editorScrollPos)
    //   previewEl.scrollTop(editorScrollPos)
    // }, 10)
  }

  handleFocus = () => {
    console.log('setting focusItem to EDITOR')
    window._app.focusItem = 'editor'
  }

  handleBlur = () => {
    var tab = this.props.app.tab()
    var tabId = this.props.app.state.editTab
      ? this.props.app.state.editTab.id
      : tab.id
    var cursor = window._app.editor.getCursor()
    window._app.tab_states[tabId].editorValue = window._app.editor.getValue()
    window._app.tab_states[tabId].editorCursorPosition = cursor
    window._app.focusItem = 'node'
    console.log('setting focusItem to NODE')
  }

  handleChange = (editor, data, text) => {
    // eslint-disable-line no-unused-vars
  }

  handleClick = ev => {
    this.props.app.editor.focus()
  }

  // handleChange = (text, medium) => { // eslint-disable-line no-unused-vars
  handleBeforeChange = (editor, data, text) => {
    // eslint-disable-line no-unused-vars
    var _this = this
    var editNode = this.props.app.editNode()
    if (editNode) {
      var newEditorStates = Object.assign({}, this.props.app.state.editorStates)
      var newState = Object.assign({}, newEditorStates[editNode.id] || {})
      newState.text = text
      newEditorStates[editNode.id] = newState
      // console.log(newEditorStates);
      this.props.app.setState({ editorStates: newEditorStates }, function () {
        const rootNodeId = _this.props.app.rootNodeId()
        if (
          editNode &&
          editNode.format === 'markdown' &&
          (_this.props.app.state.editorCompanion === 'preview' ||
            (_this.props.app.state.editorCompanion === 'lists' &&
              rootNodeId &&
              _this.props.app.state.editNodeId &&
              rootNodeId === _this.props.app.state.editNodeId))
        ) {
          clearTimeout(_this.props.app.previewTimer)
          _this.props.app.previewTimer = setTimeout(function () {
            var newValue = window._app.editor.getValue()
            const commonParams = Object.assign(
              {},
              _this.props.app.getCommonParams(),
              { _skipFlushUndo: true, _skipUndoAdd: true }
            )
            Node.modify(editNode.id, { body: newValue }, commonParams)
          }, 500)
        }
      })
    }
    // this.setState({text: text});
  }

  render () {
    var user = this.props.user
    var editNode = this.props.app.editNode()
    var useFullScreenEditor = Boolean(
      !user || user.settings.use_full_screen_editor
    )
    var editorOuterClasses = classNames({
      hidden: !this.props.editNodeId,
      'full-screen': useFullScreenEditor,
      'with-nothing': this.props.editorCompanion === 'disabled',
      'with-preview':
        !this.props.editorCompanion || this.props.editorCompanion === 'preview',
      'with-nodes': this.props.editorCompanion === 'lists',
      'no-bg': this.state.disableBg || !this.props.bgImage
    })
    var markdownClasses = classNames({
      hidden: !editNode || editNode.format !== 'markdown'
    })
    var wysiwygClasses = classNames({
      hidden: !editNode || editNode.format !== 'wysiwyg'
    })
    return !editNode ? (
      false
    ) : (
      <div id='editor-outer-container' className={editorOuterClasses}>
        {Boolean(!this.state.disableBg && this.props.bgImage) && (
          <div
            className='editor-bg'
            style={{ backgroundImage: `url(${this.props.bgImage.uri})` }}
          />
        )}
        <MainEditorCtrl
          app={this.props.app}
          user={this.props.user}
          editNode={this.props.editNode}
          editNodeId={this.props.editNodeId}
          editorCompanion={this.props.editorCompanion}
        />
        <div className='editor-wrapper'>
          <div
            id='editor-container'
            ref={editor => {
              this.editor = editor
            }}
          >
            <div
              id='markdown-editor'
              className={markdownClasses}
              onClick={this.handleClick}
            >
              <EditLock app={this.props.app} />
              <MarkdownEditor
                app={this.props.app}
                user={this.props.user}
                onBeforeChange={this.handleBeforeChange}
                onChange={this.handleChange}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                onScroll={this.handleScroll}
                editText={this.props.editText}
                editNode={this.props.editNode}
                editNodeId={this.props.editNodeId}
              />
            </div>
            <div id='wysiwyg-editor' className={wysiwygClasses}>
              <WysiwygEditor
                app={this.props.app}
                user={this.props.user}
                onChange={this.handleChange}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                editText={this.props.editText}
                editNode={this.props.editNode}
                editNodeId={this.props.editNodeId}
              />
            </div>
          </div>
          <MainEditorPreview
            app={this.props.app}
            editNode={this.props.editNode}
            editorCompanion={this.props.editorCompanion}
          />
          <div className='full-screen-icons'>
            <div onClick={() => document.exitFullscreen()}>
              <i className='fa fa-fw fa-times' />
            </div>
            <Dropdown
              {...this.props}
              disableBg={this.state.disableBg}
              component={this}
              mode='full-screen'
              className='full-screen-hamburger'
            />
          </div>
        </div>
      </div>
    )
  }
}

module.exports = MainEditor
