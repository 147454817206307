import React from 'react'

class ImportOverlay extends React.Component {
  state = {
    type: 'basic',
    dst: this.props.panel.opts.data.node.id,
    dataToImport: null
  }

  handleDstChange = e => {
    this.setState({ dst: e.target.value })
  }

  handleTypeChange = e => {
    this.setState({ type: e.target.value })
  }

  handleDataChange = e => {
    this.setState({ dataToImport: e.target.value })
  }

  doImport = e => {
    var data = {
      text: this.state.dataToImport,
      format: this.state.type,
      target_id: this.state.dst
    }
    this.props.app.setState({ importing: true })
    this.props.app.emit('import', data)
    // this.props.app.socket.emit('import', data);
    if (window.Intercom) {
      window.Intercom('trackEvent', 'imported-data')
    }
    e.preventDefault()
  }

  done = () => {
    this.props.app.setPanel(null)
  }

  render () {
    var node = this.props.panel.opts.data.node
    return (
      <div id='import'>
        <div className='row'>
          <div className='row-heading'>
            <label>Where should this data be imported?</label>
          </div>
          <div className='row-content'>
            <div className='input-label-wrapper'>
              <input
                type='radio'
                name='dst'
                value=''
                checked={!this.state.dst}
                onChange={this.handleDstChange}
                id='as-root'
              />
              <label htmlFor='as-root'>At the root of my Notebase</label>
            </div>
            <div className='input-label-wrapper'>
              <input
                type='radio'
                name='dst'
                value={node.id}
                checked={this.state.dst === node.id}
                onChange={this.handleDstChange}
                id='as-children'
              />
              <label htmlFor='as-children'>As children of this node</label>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='row-heading'>
            <label>Import:</label>
          </div>
          <div className='row-content'>
            <textarea onChange={this.handleDataChange} />
          </div>
        </div>

        <div className='buttons'>
          <button onClick={this.doImport} className='button default'>
            Import
          </button>
        </div>
      </div>
    )
  }
}

module.exports = ImportOverlay
