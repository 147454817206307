import React, { Component } from 'react'
import classNames from 'classnames'

import CardCompletionStatus from '../card_completion_status'
import CardHeader, { hasHeaderContent } from './header'
import CardFooter, { hasFooterContent } from './footer'
import CardPeekSettings from './peek_settings'
import Bullet from '../bullet'

import Node from '../../js/node'
import Misc from '../../js/misc'

export default class BoardCard extends Component {
  state = {
    editedTitle: '',
    isEditing: false,
    isSettingsOpen: false
  }

  startEditing = () => {
    const { cardNode } = this.props
    this.setState({ isEditing: true, editedTitle: cardNode.title }, () =>
      this.editInput.focus()
    )
  }

  finishEditing = () => {
    const { app, cardNode } = this.props
    const title = this.state.editedTitle
    Node.modify(cardNode.id, { title }, app.getCommonParams())
    this.setState({ isEditing: false, editedTitle: '' })
  }

  handleKeyDown = ev => {
    if (ev.which === 13) {
      ev.preventDefault()
      this.finishEditing()
    }
  }

  handleMouseDown = ev => {
    if (ev.nativeEvent.which === 1) {
      const { cardNode, listNode } = this.props
      if (!listNode.settings.board_sortby) {
        window._app.dragJustStarted = true
        window._app.boardCardDragId = cardNode.id
        window._app.boardCardDragEl = this.el
      }
    }
  }

  render () {
    const { app, board, boardNode, cardNode } = this.props
    const coverObj = app.state.entities.node_files.find(
      e => e.node_id === cardNode.id && e.is_cover
    )
    const cover = coverObj
      ? app.state.entities.files.find(e => e.id === coverObj.file_id)
      : null
    const users = app.state.entities.node_users.filter(
      e => e.node_id === cardNode.id
    )
    const classes = classNames({
      node: true,
      'kb-card': true,
      regular: true,
      completed: cardNode.completed,
      emphasized: cardNode.settings.emphasized,
      remote: cardNode.remote_id,
      'with-header': hasHeaderContent(cardNode),
      'with-footer': hasFooterContent(cardNode, users),
      focused: cardNode.id === app.state.activeNodeId
    })
    let tinyChildren = false
    const boardSettings = boardNode.settings.peek_settings || {}
    const peekSetting = boardSettings[cardNode.id] || {}
    if (peekSetting.count) {
      const actualChildren = Node.children(cardNode, app.state.entities.nodes)
      if (actualChildren.length) {
        let allChildren = []
        let hideInbetweens = false
        if (peekSetting.hide_inbetweens && peekSetting.count === 2) {
          hideInbetweens = true
          actualChildren.forEach(c => {
            allChildren = allChildren.concat(
              Node.children(c, app.state.entities.nodes)
            )
          })
        } else {
          allChildren = actualChildren
        }
        tinyChildren = (
          <div className='card-children'>
            {allChildren.map(c => {
              let childChildrenEl = false
              let outerPeekClasses = ''
              if (peekSetting.count === 2 && !hideInbetweens) {
                outerPeekClasses = 'depth-2'
                const childChildren = Node.children(c, app.state.entities.nodes)
                if (childChildren.length) {
                  childChildrenEl = (
                    <div className='card-children'>
                      {childChildren.map(cc => (
                        <div key={`peek-2-${cc.id}`}>
                          <div className='child-row'>
                            <Bullet app={app} node={cc} />
                            <div>{cc.title}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )
                }
              }
              return (
                <div key={`peek-1-${c.id}`} className={outerPeekClasses}>
                  <div className='child-row'>
                    <Bullet app={app} node={c} />
                    <div>{c.title}</div>
                  </div>
                  {childChildrenEl}
                </div>
              )
            })}
          </div>
        )
      }
    }
    return (
      <div
        className={classes}
        key={cardNode.id}
        onMouseDown={this.handleMouseDown}
        ref={el => {
          this.el = el
        }}
        data-node-id={cardNode.id}
      >
        <div className='kb-card-container'>
          {Boolean(!this.state.editing && cover) && (
            <div
              className='cover-img'
              onClick={() => this.props.board.openNode(cardNode)}
            >
              <img draggable='false' src={Misc.fileUrl(cover)} />
            </div>
          )}
          <CardHeader app={app} board={board} node={cardNode} />
          <div className='kb-card-inner'>
            <Bullet app={app} node={cardNode} />
            <div className='kb-card-main'>
              {!this.state.isEditing && (
                <div
                  className='text-container'
                  onClick={() => this.props.board.openNode(cardNode)}
                >
                  <div className='actions'>
                    <span
                      title='Edit'
                      onClick={ev => {
                        ev.stopPropagation()
                        this.startEditing()
                      }}
                    >
                      <i className='fa fa-fw fa-pencil' />
                    </span>
                    <span
                      title='Comment'
                      onClick={ev => {
                        ev.stopPropagation()
                        app.setCommentNode(cardNode.id)
                      }}
                    >
                      <i className='fa fa-fw fa-comment-o' />
                    </span>
                    <span
                      title='Like'
                      onClick={ev => {
                        ev.stopPropagation()
                        app.toggleLike(cardNode.id)
                      }}
                    >
                      <i className='fa fa-fw fa-heart-o' />
                    </span>
                    <span
                      title='Settings'
                      onClick={ev => {
                        ev.stopPropagation()
                        this.setState({
                          isSettingsOpen: !this.state.isSettingsOpen
                        })
                      }}
                    >
                      <i className='fa fa-fw fa-gear' />
                    </span>
                  </div>
                  <div className='title'>{cardNode.title}</div>
                </div>
              )}
              {this.state.isEditing && (
                <div className='text'>
                  <textarea
                    ref={editInput => {
                      this.editInput = editInput
                    }}
                    value={this.state.editedTitle}
                    onChange={ev =>
                      this.setState({ editedTitle: ev.target.value })
                    }
                    onKeyDown={this.handleKeyDown}
                  />
                </div>
              )}
              <CardFooter app={app} node={cardNode} users={users} />
              <CardCompletionStatus app={app} node={cardNode} />
              {Boolean(!this.state.isEditing && this.state.isSettingsOpen) && (
                <div className='kb-settings'>
                  <CardPeekSettings
                    app={app}
                    boardNode={boardNode}
                    cardNode={cardNode}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {tinyChildren}
      </div>
    )
  }
}
