import React from 'react'

import createReactClass from 'create-react-class'

import Misc from '../../../js/misc'

const SharesOverlay = createReactClass({
  displayName: 'SharesOverlay',

  getInitialState: function () {
    const state = {
      error: null,
      user: null,
      share: null,
      shares: []
    }
    return state
  },

  UNSAFE_componentWillMount: function () {
    // this.handleFormFieldChange = Misc.handleFormFieldChange.bind(this, this);
    this.errorFor = Misc.errorFor.bind(this, this)
  },

  // select: function (share) {
  //   share // eslint cheat
  // },

  embed: function (share, ev) {
    this.props.app.embed(share, embed => {
      this.openEmbedded(share, embed)
    })
    ev.preventDefault()
  },

  openEmbedded: function (share, embed, ev) {
    this.props.app.setRoot(embed, () => {
      this.props.app.setPanel(null)
    })
    if (ev) {
      ev.preventDefault()
    }
  },

  openInTab: function (share) {
    const tab = {
      share_id: share.id
    }
    this.props.app.addTab(tab, () => {
      this.props.app.setPanel(null)
    })
  },

  switchToTab: function (share, tab, ev) {
    this.props.app.setTab(tab, () => {
      this.props.app.setPanel(null)
    })
    ev.preventDefault()
  },

  render: function () {
    let content
    const availableShares = this.props.app.availableShares()
    const consumedShares = this.props.app.consumedShares()
    const allShares = availableShares.concat(consumedShares)

    if (allShares.length) {
      const shares = allShares.map(share => {
        const embed = this.props.app.state.entities.nodes.find(n => {
          return n.remote_id === share.node_id
        })
        const tab = this.props.app.state.entities.tabs.find(t => {
          return t.share_id === share.id
        })
        let embedLink, tabLink
        if (embed) {
          embedLink = (
            <button
              className='button minibutton'
              title='Go to embedded share'
              onClick={() => this.openEmbedded(share, embed)}
            >
              <span className='octicon octicon-link-external' />
            </button>
          )
        } else {
          embedLink = (
            <button
              className='button minibutton'
              title='Embed this share into your Notebase'
              onClick={() => this.embed(share)}
            >
              <span className='octicon octicon-arrow-down' />
            </button>
          )
        }
        if (tab) {
          tabLink = (
            <button
              className='button minibutton'
              title='Switch to the tab for this share'
              onClick={() => this.switchToTab(share, tab)}
            >
              <span className='octicon octicon-file-directory' />
            </button>
          )
        } else {
          tabLink = (
            <button
              className='button minibutton'
              title='Open share in new tab'
              onClick={() => this.openInTab(share)}
            >
              <span className='octicon octicon-file-directory' />
            </button>
          )
        }
        return (
          <tr key={share.id}>
            <td>
              <div onClick={() => this.select(share)}>{share.title}</div>
            </td>
            <td>{share.username}</td>
            <td>{Misc.timeFor(share.created_at, '%b %d, %Y')}</td>
            <td>{embedLink}</td>
            <td>{tabLink}</td>
          </tr>
        )
      })
      content = (
        <table>
          <thead>
            <tr>
              <th>Title</th>
              <th>Shared by</th>
              <th>Date</th>
              <th />
              <th />
            </tr>
          </thead>
          {/*
          */}
          <tbody>{shares}</tbody>
        </table>
      )
    } else {
      content = <p>Nobody has shared anything with you yet.</p>
    }

    return (
      <div id='admin-shares'>
        {this.errorFor()}
        {content}
      </div>
    )
  }
})

module.exports = SharesOverlay
