var $ = require('jquery')

var Node = require('../../js/node')
var Tab = require('../../js/tab')
var constants = require('../..//lib/constants')
// var Misc = require('../../js/misc');

function resetBoardMargins (type) {
  if (!type || type === 'list') {
    $('.kb-list-container.regular').css({
      marginLeft: window._app.boardDragLeftMargin,
      marginRight: window._app.boardDragRightMargin
    })
  }
  if (!type || type === 'card') {
    $('.kb-card.regular').css({
      marginTop: window._app.boardDragTopMargin,
      marginBottom: window._app.boardDragBottomMargin
    })
    $('.kb-pusher').css({
      marginBottom: 0
    })
  }
}

function setBoardPositions () {
  const selector =
    '.kb-cards:visible, .kb-card.regular:visible, .kb-list-container.regular:visible'
  window._app.boardPositions = $(selector)
    .map((i, e) => {
      var $e = $(e)
      var offset = $e.offset()
      var outerHeight = $e.outerHeight()
      var outerWidth = $e.outerWidth()
      return {
        el: $e,
        type: $e.hasClass('kb-list-container')
          ? 'list'
          : $e.hasClass('kb-cards')
            ? 'cards'
            : 'card',
        top: offset.top,
        left: offset.left,
        bottom: offset.top + outerHeight,
        right: offset.left + outerWidth,
        width: outerWidth,
        height: outerHeight
      }
    })
    .get()
}

module.exports = {
  dragEnd: function (dragged) {
    if (window._app.dragElementType === 'tab') {
      var dstIsLast = false
      var dstEl = $('#tabs .droppable')
      if (!dstEl.length) {
        dstEl = $('#tabs .droppable-right')
        dstIsLast = true
      }
      $('#tabs .tab').removeClass('droppable droppable-right')
      $('#app-wrapper').removeClass('drag-in-progress')

      if (dstEl.length) {
        var srcEl = window._app.dragged
        if (srcEl) {
          var dstId =
            dstEl.data('tab-id') || $('#tabs .tab:last').data('tab-id')
          var src = Tab.get(srcEl.data('tab-id'), this.state.entities.tabs)
          var dst = Tab.get(dstId, this.state.entities.tabs)
          if (src && dst) {
            var newPrev = dstIsLast ? dst.id : dst.prev_id
            if (newPrev !== src.prev_id) {
              Tab.move(src.id, newPrev, this.getCommonParams())
            }
          }
        }
      }
      window.Intercom && window.Intercom('trackEvent', 'dragged-tab') // jshint ignore:line
    } else if (window._app.dragElementType === 'node') {
      clearTimeout(window._app.smoothScrollTimeout)
      $('#drag-indicator')
        .removeClass('active')
        .hide()
      $('.were-editable')
        .attr('contenteditable', true)
        .removeClass('were-editable')
      $('#app-wrapper').removeClass('drag-in-progress')
      $('li.node').removeClass('dragged')
      $('li.node').removeClass('dragged-over')
      $('li.node').removeClass('outline')
      $('.drop-hover').removeClass('active')
      $('.ng-hover').removeClass('active')

      // var li = $(this.refs.aside).closest('li.node');
      // $(li).addClass('dragged');
      setTimeout(
        function () {
          console.log('<< DRAG END >>', window._app.dragElementType) // eslint-disable-line no-console
          var el = document.elementFromPoint(
            window._app.mousePosition.clientX,
            window._app.mousePosition.clientY - 10
          )
          // console.log(e.screenX, e.screenY); // eslint-disable-line no-console
          console.log('dragEndTarget:', el) // eslint-disable-line no-console

          // var el = $(e.currentTarget);
          // var src = Node.get(this.props.app.state.dragged, this.props.app.state.entities.nodes);
          var src = Node.get(dragged, this.state.entities.nodes)
          var dst = Node.get(window._app.draggedOver, this.state.entities.nodes)

          // var dst = Node.get(dstId, this.props.app.state.entities.nodes);
          if (src && dst) {
            // console.log('src', src);
            // console.log('dst', dst);
            if (this.isNodeDragValid(src.id, dst.id, el)) {
              var newParent =
                window._app.dragType === 'child' ? dst.id : dst.parent_id
              var newPrev = window._app.dragType === 'normal' ? dst.id : null
              if (!(src.parent_id === newParent && src.prev_id === newPrev)) {
                console.log(
                  'move ' + dragged.id + ' to ' + newParent + ':' + newPrev
                ) // eslint-disable-line no-console
                Node.move(src, newParent, newPrev, this.getCommonParams())
              }
            } else {
              console.log('NOT dragging because !isDragValid') // eslint-disable-line no-console
            }
          } else {
            console.log('NOT dragging because no src && dst') // eslint-disable-line no-console
          }

          window._app.dragged = null
          window._app.draggedOver = null
        }.bind(this),
        0
      )

      window.Intercom && window.Intercom('trackEvent', 'dragged-node') // jshint ignore:line
      // this.props.app.setAppState({dragged: null, draggedOver: null});
    }
  },

  dragOver: function (e) {
    this.moveIndicator(e)
    this.dragDampener = this.dragDampener || 0
    // var scrollTop = $(window).scrollTop();
    // var scrollLeft = $(window).scrollLeft();
    // var el;
    if (this.dragDampener === 10) {
      if (window._app.dragElementType === 'tab') {
        // el = document.elementFromPoint(window._app.mousePosition.clientX, window._app.mousePosition.clientY);
        // if (el) {
        //   var tab = $(el).closest('#tabs .tab');
        //   if (tab.length) {
        //     $('#tabs .tab').removeClass('droppable');
        //     var tabStart = tab.offset().left;
        //     var tabWidth = tab.outerWidth();
        //     var goRight = Boolean(window._app.mousePosition.clientX > (tabStart + (tabWidth / 2)));
        //     var nextTab = tab.next();
        //     if (this.isTabDragValid(tab, goRight)) {
        //       if (goRight) {
        //         var dropTab = nextTab.length ? nextTab : tab;
        //         dropTab.addClass('droppable');
        //       } else {
        //         tab.addClass('droppable');
        //       }
        //     }
        //   }
        // }
      } else if (window._app.dragElementType === 'node') {
        // this.moveIndicator(e);
        // window._app.draggedOver = null;
        // var li;
        // window._app.dragType = 'normal';
        // el = document.elementFromPoint(window._app.rightOffset - 150, e.pageY - scrollTop);
        // if (el) {
        //   li = $(el).closest('li.node');
        //   $('li.node').removeClass('dragged-over');
        //   $('.ng-hover').removeClass('hovered');
        //   if ($(el).hasClass('ng-hover')) {
        //     $(el).addClass('hovered');
        //   }
        //   if (!(li && li.length)) {
        //     if (e.pageY < window._app.firstNode.offset().top) {
        //       li = window._app.firstNode;
        //       window._app.dragType = 'first';
        //     } else if (e.pageY > (window._app.lastNode.offset().top + window._app.lastNode.height())) {
        //       li = window._app.lastNode;
        //       // window._app.dragType = 'last';
        //     }
        //   }
        //   if (li && li.length) {
        //     var nodeId = li.data('node-id');
        //     window._app.dragIsValid = this.isNodeDragValid(window._app.dragged.id, nodeId, el);
        //     if (window._app.dragIsValid) {
        //       window._app.draggedOver = nodeId;
        //       // $(li).addClass('dragged-over');
        //       switch (window._app.dragType) {
        //       case 'first':
        //         li.find('> .drop-hover.first').addClass('active');
        //         break;
        //       case 'last':
        //         li.find('  > .drop-hover:last').addClass('active');
        //         break;
        //       default:
        //         if (/\bdrop-hover\b/.test(el.className) && /\bbottom\b/.test(el.className)) {
        //           $(el).addClass('active');
        //         } else {
        //           window._app.draggedOver = nodeId;
        //           if (li.hasClass('expanded')) {
        //             window._app.dragType = 'child';
        //           }
        //           $(li).addClass('dragged-over');
        //         }
        //         break;
        //       }
        //     }
        //   }
        // }
      }

      this.dragDampener = 0
    } else {
      this.dragDampener++
    }

    // var pxPerSec = 1000;
    // var scrollAreaPx = 120;

    // if (!this.prevDragOver) {
    //   this.prevDragOver = new Date().getTime();
    // }

    // var curr = new Date().getTime();
    // var diff = curr - this.prevDragOver;
    // this.prevDragOver = curr;
    // if (diff > 0) {
    //   var distanceFromTop = e.pageY - scrollTop;
    //   var distance;
    //   var direction;
    //   if (distanceFromTop < scrollAreaPx) {
    //     direction = 'up';
    //     distance = distanceFromTop;
    //   } else if (distanceFromTop > $(window).height() - scrollAreaPx) {
    //     direction = 'down';
    //     distance = scrollAreaPx - (distanceFromTop - ($(window).height() - scrollAreaPx));
    //   }

    //   if (distance) {
    //     var scrollDiff = (diff / 1000) * pxPerSec;
    //     var actualScrollDiff = -((scrollAreaPx - distance) / scrollAreaPx) * scrollDiff;
    //     if (direction === 'down') {
    //       actualScrollDiff = Math.abs(actualScrollDiff);
    //     }

    //     window.scrollTo(scrollLeft, scrollTop + actualScrollDiff);
    //   }
    // }

    e.preventDefault()
    e.stopPropagation()
  },

  isTabDragValid: function (tab, goRight) {
    var srcId = window._app.dragged.data('tab-id')
    var dstId = tab.data('tab-id')
    var lastId = $('#tabs .tab:last').data('tab-id')
    var nextId, prevId
    var nextTab = tab.next()
    var prevTab = tab.prev()
    if (nextTab.length) {
      nextId = nextTab.data('tab-id')
    }

    if (prevTab.length) {
      prevId = prevTab.data('tab-id')
    }
    return Boolean(
      srcId !== dstId &&
        ((srcId !== lastId || dstId) &&
          ((goRight && srcId !== nextId) || (!goRight && srcId !== prevId)))
    )
  },

  isNodeDragValid: function (src, dst, el) {
    // TODO: Use access
    if (el && !$(el).closest('#nodes').length) {
      console.log('NOT dragging because target element in #inbox:', el) // eslint-disable-line no-console
      return false
    }

    if (src === dst) {
      console.log('NOT dragging because src === dst') // eslint-disable-line no-console
      return false
    } else if (!dst) {
      console.log('ALLOW drag/move because dst is root') // eslint-disable-line no-console
      return true
    } else {
      var dstNode = Node.get(dst, this.state.entities.nodes)
      if (dstNode._.access < constants.WRITE_ACCESS) {
        console.log('NOT dragging because not enough access') // eslint-disable-line no-console
        return false
      } else {
        if (new RegExp(src).test(dstNode.path.replace(/_/g, '-'))) {
          console.log('NOT dragging because dst is a child of src') // eslint-disable-line no-console
          return false
        }
      }
    }

    return true
  },

  moveIndicator: function () {
    var $indicator, el, $el
    if (window._app.dragged) {
      if (window._app.dragElementType === 'tab') {
        $indicator = $('#tab-drag-indicator')
        el = document.elementFromPoint(
          window._app.mousePosition.clientX,
          window._app.mousePosition.clientY
        )
        if (el) {
          var tab = $(el).closest('#tabs .tab')
          if (tab.length) {
            $('#tabs .tab').removeClass('droppable droppable-right')
            var tabStart = tab.offset().left
            var tabWidth = tab.outerWidth()
            var goRight = Boolean(
              window._app.mousePosition.clientX > tabStart + tabWidth / 2
            )
            var nextTab = tab.next('.tab')
            if (this.isTabDragValid(tab, goRight)) {
              if (goRight) {
                var dropTab = nextTab.length ? nextTab : tab
                var dropClass = nextTab.length ? 'droppable' : 'droppable-right'
                if (!dropTab.hasClass(dropClass)) {
                  $('#tabs .tab').removeClass('droppable droppable-right')
                  dropTab.addClass(dropClass)
                }
              } else {
                if (!tab.hasClass('droppable')) {
                  $('#tabs .tab').removeClass('droppable droppable-right')
                  tab.addClass('droppable')
                }
              }
            } else {
              $('#tabs .tab').removeClass('droppable')
            }
          }
        }
      } else if (window._app.dragElementType === 'node') {
        $indicator = $('#drag-indicator')
        // var el = document.elementFromPoint(window._app.rightOffset - 150, window._app.mousePosition.pageY - scrollTop);
        el = document.elementFromPoint(
          window._app.rightOffset - 150,
          window._app.mousePosition.clientY
        )
        // console.log('el', el);
        $el = $(el)
        var dropHover = $el.closest('.drop-placeholder')
        var li, prev, next
        if (dropHover.length) {
          li = $(el).closest('li.node')
          var hoverOffset = dropHover.offset()
          $indicator.css({
            top: hoverOffset.top,
            left: hoverOffset.left,
            width: dropHover.width()
          })
          var prevLevel =
            parseInt(li.attr('data-level')) -
            parseInt(dropHover.attr('data-level')) -
            1
          prev = li.prevAll('[data-level="' + prevLevel + '"]')
          window._app.draggedOver = prev.data('node-id')
        } else {
          li = $(el).closest('li.node')
          var top, left
          var id = null
          var dragType = 'normal'
          var prevIsIndented = false
          var prevIsOutdented = false
          var nextIsIndented = false
          var nextIsOutdented = false
          var inTopHalf = false
          if (li.length) {
            prev = li.prev()
            next = li.next()
            prevIsIndented = Boolean(
              prev.length && prev.attr('data-level') > li.attr('data-level')
            )
            prevIsOutdented = Boolean(
              prev.length && prev.attr('data-level') < li.attr('data-level')
            )
            nextIsIndented = Boolean(
              next.length && next.attr('data-level') > li.attr('data-level')
            )
            nextIsOutdented = Boolean(
              next.length && next.attr('data-level') < li.attr('data-level')
            )
            inTopHalf = Boolean(
              window._app.mousePosition.pageY <
                li.offset().top + li.height() / 2
            )

            var wrapperSelector = '.indent-wrapper'
            var wrapper = li.find(wrapperSelector)
            var width = wrapper.width()
            id = li.data('node-id')
            if (inTopHalf) {
              id = prev.data('node-id')
              if (prevIsIndented) {
                top = wrapper.offset().top
                left = wrapper.offset().left
                var realLi = li.prevAll(
                  '[data-level="' + li.attr('data-level') + '"]'
                )
                if (realLi.length) {
                  id = realLi.data('node-id')
                }
              } else if (prevIsOutdented) {
                top = wrapper.offset().top
                left = wrapper.offset().left
                dragType = 'child'
              } else {
                // Same level
                left = wrapper.offset().left
                if (prev.length) {
                  wrapper = prev.find(wrapperSelector)
                  top = wrapper.offset().top + wrapper.height()
                } else {
                  top = wrapper.offset().top
                  id = null
                }
              }
            } else {
              // isBottomHalf
              if (nextIsIndented) {
                wrapper = next.find(wrapperSelector)
                left = wrapper.offset().left
                top = wrapper.offset().top
                width = wrapper.width()
                dragType = 'child'
              } else if (nextIsOutdented) {
                left = wrapper.offset().left
                top = wrapper.offset().top + wrapper.height()
              } else {
                // Same level
                left = wrapper.offset().left
                top = wrapper.offset().top + wrapper.height()
              }
            }

            if (this.isNodeDragValid(window._app.dragged.id, id, el)) {
              $indicator
                .show()
                .css({
                  top: top,
                  left: left + 22,
                  width: width
                })
                .data('id', id)
              window._app.draggedOver = id
              window._app.dragType = dragType
            } else {
              $indicator.hide()
              window._app.draggedOver = null
              window._app.dragType = null
            }
          }
        }
      }
    }
  },

  scrollSmoothly: function () {
    var _this = this
    var timeout = 10
    var pxPerSec = 400
    var scrollAreaPx = 100

    var timesPerSecond = pxPerSec / timeout

    var scrollTop = $(window).scrollTop()
    var scrollLeft = $(window).scrollLeft()

    var mouseY = window._app.mousePosition.clientY

    var mouseDistanceFromTop = mouseY - $('#header').height()
    var mouseDistanceFromBottom = $(window).height() - mouseY
    var distance
    var direction

    if (mouseDistanceFromTop < scrollAreaPx) {
      direction = 'up'
      distance = mouseDistanceFromTop
    } else if (mouseDistanceFromBottom < scrollAreaPx) {
      direction = 'down'
      distance = mouseDistanceFromBottom
    }

    if (distance) {
      distance = Math.max(1, distance)
      var percentage = 100 - distance / scrollAreaPx * 100
      var scrollAmount = -(pxPerSec / timesPerSecond * (percentage / 100))
      if (direction === 'down') {
        scrollAmount = Math.abs(scrollAmount)
      }

      window.scrollTo(scrollLeft, scrollTop + scrollAmount)
      if (window._app.dragged) {
        _this.moveIndicator()
      }
    }
    window._app.smoothScrollTimeout = setTimeout(_this.scrollSmoothly, timeout)
  },

  startBoardDrag: function (type) {
    var el
    if (type === 'list') {
      window._app.boardListDragStarted = true
      el = window._app.boardListDragEl
    } else {
      window._app.boardCardDragStarted = true
      el = window._app.boardCardDragEl
    }
    var mover = $('.board-' + type + '-mover')
    var placeholder = $('.board-' + type + '-placeholder')
    var movedEl = $(el)
    mover.html(movedEl.html())
    var elOffset = movedEl.offset()
    var elWidth = movedEl.outerWidth()
    var elHeight =
      type === 'list'
        ? movedEl.find('.kb-list').outerHeight()
        : movedEl.outerHeight()
    window._app.boardDragOffsetLeft =
      window._app.mousePosition.pageX - elOffset.left
    window._app.boardDragOffsetTop =
      window._app.mousePosition.pageY - elOffset.top
    window._app.boardDragTopMargin = parseInt(
      $('.kb-card.regular:first').css('marginTop') || 0
    )
    window._app.boardDragBottomMargin = parseInt(
      $('.kb-card.regular:first').css('marginBottom') || 0
    )
    window._app.boardDragLeftMargin = parseInt(
      $('.kb-list-container.regular:first').css('marginleft') || 0
    )
    window._app.boardDragRightMargin = parseInt(
      $('.kb-list-container.regular:first').css('marginRight') || 0
    )
    if (type === 'list') {
      movedEl
        .next('.kb-list-container')
        .css({ marginLeft: window._app.boardDragRightMargin + elWidth })
      movedEl.css('display', 'none')
    } else {
      movedEl
        .next('.kb-card.regular')
        .css({ marginTop: window._app.boardDragTopMargin * 2 + elHeight })
      movedEl.css('display', 'none')
    }
    setBoardPositions(type)
    mover.css({ display: 'block', width: elWidth, height: elHeight })
    placeholder.css({
      display: 'block',
      width: elWidth,
      height: elHeight,
      top: elOffset.top,
      left: elOffset.left
    })
  },

  handleBoardDrag: function (type) {
    var mover = $('.board-' + type + '-mover')
    var placeholder = $('.board-' + type + '-placeholder')
    var placeholderOffset = placeholder.offset()
    var placeholderWidth = placeholder.outerWidth()
    var placeholderHeight = placeholder.outerHeight()
    var mouseX = window._app.mousePosition.pageX
    var mouseY = window._app.mousePosition.pageY
    mover.css({
      top: mouseY - window._app.boardDragOffsetTop,
      left: mouseX - window._app.boardDragOffsetLeft
    })
    var isOnPlaceholder =
      placeholderOffset.top <= mouseY &&
      placeholderOffset.top + placeholderHeight >= mouseY &&
      placeholderOffset.left <= mouseX &&
      placeholderOffset.left + placeholderWidth >= mouseX
    if (!isOnPlaceholder) {
      var cardBelow = window._app.boardPositions.find(
        p =>
          p.type === type &&
          p.top <= mouseY &&
          p.bottom >= mouseY &&
          p.left <= mouseX &&
          p.right >= mouseX
      )
      if (cardBelow) {
        var cardBelowOffset, placeholderPos, placeholderOtherPos, parentId
        var isFirstHalf =
          type === 'list'
            ? mouseX < cardBelow.left + (cardBelow.right - cardBelow.left) / 2
            : mouseY < cardBelow.top + (cardBelow.bottom - cardBelow.top) / 2
        var firstHalfMargin = type === 'list' ? 'marginLeft' : 'marginTop'
        var lastHalfMargin = type === 'list' ? 'marginRight' : 'marginBottom'
        var directionMarginKey =
          type === 'list' ? 'boardDragLeftMargin' : 'boardDragTopMargin'
        var otherDirectionMarginKey =
          type === 'list' ? 'boardDragRightMargin' : 'boardDragBottomMargin'
        var placeholderDirectionKey = type === 'list' ? 'left' : 'top'
        var placeholderOtherDirectionKey = type === 'list' ? 'top' : 'left'
        var mainMeasurement = type === 'list' ? 'width' : 'height'
        var placeholderMainDim =
          type === 'list' ? placeholderWidth : placeholderHeight
        var firstHalfMarginMultiplier = type === 'list' ? 1 : 2
        var lastHalfMarginMultiplier = type === 'list' ? 2 : 2

        var hasMovedDown =
          parseInt(cardBelow.el.css(firstHalfMargin) || 0) !==
          window._app[directionMarginKey]
        var hasPushedDown =
          parseInt(cardBelow.el.css(lastHalfMargin) || 0) !==
          window._app[otherDirectionMarginKey]

        if (isFirstHalf && !hasMovedDown) {
          resetBoardMargins(type)
          placeholder.css(cardBelow.el.offset())
          cardBelow.el.css({
            [firstHalfMargin]:
              window._app[otherDirectionMarginKey] * firstHalfMarginMultiplier +
              placeholderMainDim,
            [lastHalfMargin]: window._app[otherDirectionMarginKey]
          })

          setBoardPositions(type)
          parentId = Node.get(
            cardBelow.el.data('node-id'),
            this.state.entities.nodes
          ).parent_id
          var targetEl = cardBelow.el.prev()
          window._app.boardMoveTarget = {
            prev: targetEl.length ? targetEl.data('node-id') : null,
            parent: parentId
          }
        } else if (!isFirstHalf && !hasPushedDown) {
          const goingUp =
            parseInt(cardBelow.el.css(firstHalfMargin) || 0) ===
            window._app[directionMarginKey]
          cardBelowOffset = cardBelow.el.offset()
          placeholderPos = goingUp
            ? cardBelowOffset[placeholderDirectionKey] +
              cardBelow[mainMeasurement] +
              window._app[otherDirectionMarginKey]
            : parseInt(placeholder.css(placeholderDirectionKey)) +
              cardBelow[mainMeasurement] +
              window._app[otherDirectionMarginKey]
          placeholderOtherPos = cardBelowOffset[placeholderOtherDirectionKey]

          resetBoardMargins(type)
          placeholder.css({
            [placeholderOtherDirectionKey]: placeholderOtherPos,
            [placeholderDirectionKey]: placeholderPos
          })
          cardBelow.el.css({
            [lastHalfMargin]:
              window._app[otherDirectionMarginKey] * lastHalfMarginMultiplier +
              placeholderMainDim,
            [firstHalfMargin]: window._app[directionMarginKey]
          })
          setBoardPositions(type)
          parentId = Node.get(
            cardBelow.el.data('node-id'),
            this.state.entities.nodes
          ).parent_id
          window._app.boardMoveTarget = {
            prev: cardBelow.el.data('node-id'),
            parent: parentId
          }
        }
      } else if (type === 'card') {
        var cardsBelow = window._app.boardPositions.find(
          p =>
            p.type === 'cards' &&
            p.top <= mouseY &&
            p.bottom >= mouseY &&
            p.left <= mouseX &&
            p.right >= mouseX
        )
        if (!cardsBelow) {
          var listBelow = window._app.boardPositions.find(
            p =>
              p.type === 'list' &&
              p.top <= mouseY &&
              p.bottom >= mouseY &&
              p.left <= mouseX &&
              p.right >= mouseX
          )
          // if (listBelow && listBelow.el.hasClass('empty')) {
          if (listBelow) {
            const cards = listBelow.el.find('.kb-cards')
            const cardsOffset = cards.offset()
            const cardsHeight = cards.outerHeight()
            if (mouseY > cardsOffset.top + cardsHeight) {
              var children = Node.children(
                listBelow.el.data('node-id'),
                this.state.entities.nodes
              )
              var prev = children.length
                ? children[children.length - 1].id
                : null
              window._app.boardMoveTarget = {
                prev: prev,
                parent: listBelow.el.data('node-id')
              }
              var pusher = listBelow.el.find('.kb-pusher')
              var pusherOffset = pusher.offset()
              pusher.css(
                'marginBottom',
                placeholderHeight + window._app.boardDragBottomMargin
              )
              placeholder.css({
                top: pusherOffset.top,
                left: pusherOffset.left
              })
            }
          }
        }
      }
    }
  },

  endBoardDrag: function (type) {
    if (window._app.boardCardDragStarted || window._app.boardListDragStarted) {
      this.moveMaybe(type)
    }
    $('.board-card-mover, .board-list-mover').css('display', 'none')
    $('.board-card-placeholder, .board-list-placeholder').css('display', 'none')
    $(window._app.boardCardDragEl).css('display', 'block')
    $(window._app.boardListDragEl).css('display', 'block')
    resetBoardMargins()
    window._app.dragJustStarted = false
    window._app.boardMoveTarget = null
    window._app.boardCardDragStarted = false
    window._app.boardListDragStarted = false
    window._app.boardCardDragId = null
    window._app.boardCardDragEl = null
    window._app.boardListDragId = null
    window._app.boardListDragEl = null
  },

  moveMaybe: function (type) {
    var srcId =
      type === 'list'
        ? window._app.boardListDragId
        : window._app.boardCardDragId
    if (
      srcId &&
      window._app.boardMoveTarget &&
      window._app.boardMoveTarget.parent
    ) {
      var src = Node.get(srcId, this.getCommonParams().entities.nodes)
      if (
        src &&
        src.id !== window._app.boardMoveTarget.prev &&
        (src.prev_id !== window._app.boardMoveTarget.prev ||
          src.parent_id !== window._app.boardMoveTarget.parent)
      ) {
        Node.move(
          src,
          window._app.boardMoveTarget.parent,
          window._app.boardMoveTarget.prev,
          this.getCommonParams()
        )
      }
    }
  }
}
