import React from 'react'
import _ from 'lodash'
// import moment from 'moment';

import Heading from '../heading'
import Avatar from '../../../avatar'
import NodeUsersPicker from '../../../node_users_picker'

import './styles.css'
export default class NodeUsers extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      editing: props.mode === 'assign'
    }
  }

  render () {
    const { app, node } = this.props
    const userId = app.userId()
    const nodeUsers = _.sortBy(
      app.state.entities.node_users.filter(e => e.node_id === node.id),
      'created_at'
    ).reverse()
    const editText = this.state.editing
      ? 'Done Editing Users'
      : nodeUsers.length
        ? 'Edit Assigned Users'
        : 'Assign Users'
    return (
      <div className='panel node-users'>
        {!this.props.noHeading && <Heading {...this.props} name='Users' />}
        {Boolean(!this.state.editing && nodeUsers.length) && (
          <div className='users'>
            {nodeUsers.map(e => {
              const user = app.state.entities.users.find(
                u => u.id === e.assigned_to
              )
              return (
                <div key={e.id}>
                  <Avatar
                    app={app}
                    user={user}
                    size='large'
                    onClick={() => app.setAssignedTo(node, user)}
                  />
                  <div className='name'>{user.first_name}</div>
                </div>
              )
            })}
          </div>
        )}
        {Boolean(!this.state.editing && !nodeUsers.length) && (
          <div className='no-data'>No users have been assigned.</div>
        )}
        {this.state.editing && <NodeUsersPicker {...this.props} node={node} />}
        {userId && (
          <div className='add-new'>
            <button
              onClick={() => this.setState({ editing: !this.state.editing })}
            >
              {editText}
            </button>
          </div>
        )}
      </div>
    )
  }
}
