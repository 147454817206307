import './styles.css'

import React from 'react'

import Heading from '../sections/heading'
import SingleNode from '../sections/single_node'
import Node from '../../../js/node'

class NodeSelection extends React.Component {
  render () {
    const { app } = this.props
    var list
    var selection = app.state.nodeSelection
    if (selection) {
      list = selection.nodeIds.map(nId => {
        const node = Node.get(nId, app.state.entities.nodes)
        return (
          <SingleNode key={'selection-' + nId} app={app} showPath node={node} />
        )
      })
    } else {
      list = [
        <div key='no-selection-items' className='no-items'>
          Nothing found.
        </div>
      ]
    }
    return (
      <div id='node-selection' className='panel'>
        <Heading {...this.props} name={selection.title} isMain />
        <div className='selection-list'>{list}</div>
      </div>
    )
  }
}

module.exports = NodeSelection
