import './styles.css'

import React from 'react'

import SetPlaceholder from '../../set_placeholder'

export default class Emails extends React.Component {
  typeName = type => {
    return type.replace(/^\w/, c => c.toUpperCase())
  }

  render () {
    const { app, node } = this.props
    const data = node.data || {}
    const toggleFunc = ev =>
      app.toggleFloater(ev.target, 'emails-picker', {
        nodeId: node.id
      })

    return (
      <div>
        {Boolean(data.emails && data.emails.length) && (
          <div className='actual-data'>
            <table className='type-table'>
              <tbody>
                {data.emails.map((email, i) => {
                  return (
                    <tr key={i}>
                      <td className={`type ${email.type}`}>
                        <span>{this.typeName(email.type)}</span>
                      </td>
                      <td className='data'>
                        <span>{email.address}</span>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )}
        {!data.emails && <SetPlaceholder onClick={toggleFunc} />}
      </div>
    )
  }
}
