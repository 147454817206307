import React from 'react'
import $ from 'jquery'
import classNames from 'classnames'
import ReactTooltip from 'react-tooltip'

import Node from '../../../js/node'

class Tab extends React.Component {
  setTab = () => {
    this.props.app.setTab(this.props.tab.id)
  }

  closeTab = e => {
    this.props.app.closeTab(this.props.tab.id)
    e.stopPropagation()
  }

  moveTab = () => {
    this.props.app.moveTab(this.props.tab.id)
  }

  openSettings = e => {
    this.props.app.setPanel('tab-settings-overlay', {
      data: { tab: this.props.tab },
      title: 'Tab settings'
    })
    e.stopPropagation()
  }

  name = (node, main) => {
    var title = '(unknown)'
    node =
      node ||
      Node.get(this.props.tab.node_id, this.props.app.state.entities.nodes)
    var rootTitle = this.props.app.headingForUserOrOrg()
    if (this.props.tab.share_id) {
      var share = this.props.app.state.entities.shares.find(s => {
        return s.id === this.props.tab.share_id
      })
      var str = main ? 'Shared: ' : ''
      title = str + share.title
    } else {
      if (
        !this.props.tab.name &&
        this.props.tab.pinned &&
        this.props.tab.root_id
      ) {
        const tabRoot = Node.get(
          this.props.tab.root_id,
          this.props.app.state.entities.nodes
        )
        title = tabRoot.title
      } else {
        title = this.props.tab.name || (node ? node.title : rootTitle)
      }
    }
    return title
  }

  dragStart = e => {
    console.log('TAB dragstart') // eslint-disable-line no-console
    window._app.dragElementType = 'tab'
    window._app.dragged = $(e.target).closest('.tab')
    $('#app-wrapper').addClass('drag-in-progress')
    window._app.dragStartTimeout = setTimeout(function () {}, 200)
    // e.dataTransfer.effectAllowed = 'move';

    // Firefox requires dataTransfer data to be set
    // e.dataTransfer.setData('text/html', e.currentTarget);
  }

  render () {
    var node = Node.get(
      this.props.tab.node_id,
      this.props.app.state.entities.nodes
    )
    var rootTitle = this.props.app.headingForUserOrOrg()
    var subTitle, subTitleEl
    if (this.props.tab.name || this.props.tab.pinned) {
      subTitle = node ? node.title : rootTitle
      subTitleEl = <div className='subtitle'>{subTitle}</div>
    } else if (
      this.props.tab.share_id &&
      this.props.tab.node_id &&
      this.props.app.state.share
    ) {
      subTitle = this.name()
      subTitleEl = <div className='subtitle'>{subTitle}</div>
    }
    // subTitleEl = <div className="subtitle">{this.props.tab.id}</div>;
    // var org = this.props.org;
    // var user = this.props.user;
    // var isActive = (org && user.settings.orgTabs) ? (this.props.tab.id === user.settings.orgTabs[org.id]) : (this.props.tab.id === user.tab_id);
    var isActive = this.props.tab.id === this.props.app.tabId()
    var isBg = !isActive
    var classes = classNames({
      tab: true,
      active: isActive,
      bg: isBg,
      shared: this.props.tab.share_id,
      subtitle: subTitle
    })
    var title = this.name(node, true)
    var closeButton
    if (!this.props.tab.pinned) {
      closeButton = (
        <span className='close' onClick={this.closeTab}>
          <i className='fa fa-fw fa-close' />
        </span>
      )
    }
    return (
      <div
        className={classes}
        data-tab-id={this.props.tab.id}
        onClick={this.setTab}
        onMouseDown={this.dragStart}
      >
        {isActive && <span className='active-tab-indicator' />}
        <div>
          <div className='title' title={title}>
            {title}
          </div>
          {subTitleEl}
          <div className='controls'>
            <span
              className='settings'
              data-tip
              data-for='tab-settings'
              onClick={this.openSettings}
            >
              <i className='fa fa-fw fa-cog' />
            </span>
          </div>
          {closeButton}
        </div>
        <ReactTooltip
          id='tab-settings'
          type='dark'
          effect='solid'
          place='bottom'
          data-delay-show='1000'
        >
          <span>Tab settings</span>
        </ReactTooltip>
      </div>
    )
  }
}

module.exports = Tab
