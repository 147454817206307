import './styles.css'

import React from 'react'
import sortBy from 'lodash.sortby'

import Avatar from '../avatar'

export default class NodeUsersPicker extends React.Component {
  addUser = user => {
    const { app } = this.props
    const node = this.props.node || app.node(app.state.floater.data.nodeId)
    app.ajax(
      'add_node_user',
      { body: { org_id: app.orgId(), node_id: node.id, assigned_to: user.id } },
      () => {
        app.setAppState({ updatedNodes: [node.id] })
      }
    )
  }

  deleteUser = nodeUser => {
    const { app } = this.props
    const node = this.props.node || app.node(app.state.floater.data.nodeId)
    app.ajax('delete_node_user', { body: { id: nodeUser.id } }, () => {
      app.setAppState({ updatedNodes: [node.id] })
    })
  }

  render () {
    const { app } = this.props
    const node = this.props.node || app.node(app.state.floater.data.nodeId)
    const nodeUsers = sortBy(
      app.state.entities.node_users.filter(e => e.node_id === node.id),
      'created_at'
    ).reverse()
    const orgId = app.orgId()
    const orgUsers = orgId
      ? app.state.entities.org_users.filter(e => e.org_id === orgId)
      : []
    return (
      <div className='node-users-picker'>
        {orgUsers.map(e => {
          const user = app.state.entities.users.find(u => u.id === e.user_id)
          const nodeUser = nodeUsers.find(u => u.assigned_to === user.id)
          const action = nodeUser ? this.deleteUser : this.addUser
          const arg = nodeUser || user
          return (
            <div
              key={e.id}
              className={`user-row ${nodeUser ? 'checked' : ''}`}
              onClick={() => action(arg)}
            >
              <Avatar app={app} user={user} />
              <div className='name'>
                {user.first_name} {user.last_name}
              </div>
              <div className='check'>
                <i className='fa fa-fw fa-check' />
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}
