module.exports = (function () {
  const protocol = window.location.protocol
  const host = window.location.hostname
  const isDevPort = [80, 443].indexOf(parseInt(window.location.port)) === -1
  const portPart = isDevPort ? `:${window.location.port}` : ''

  window.NOTEBASE_IS_DESKTOP = Boolean(
    window && window.process && window.process.type
  ) // eslint-disable-line no-unused-vars
  window.NOTEBASE_IS_DESKTOP = /electron/i.test(navigator.userAgent)

  // window.NOTEBASE_URL_PREFIX = window.NOTEBASE_IS_DESKTOP ? 'https://dev.notebase.io' : ''; // eslint-disable-line no-unused-vars
  window.NOTEBASE_FULL_URL =
    isDevPort && !window.NOTEBASE_IS_DESKTOP
      ? `${protocol}//${host}${portPart}`
      : 'https://dev.notebase.io' // eslint-disable-line no-unused-vars
  window.NOTEBASE_URL_PREFIX = window.NOTEBASE_IS_DESKTOP
    ? 'https://dev.notebase.io'
    : '' // eslint-disable-line no-unused-vars
  window.NOTEBASE_WS_URL_PREFIX = window.NOTEBASE_IS_DESKTOP
    ? 'wss://dev.notebase.io'
    : '' // eslint-disable-line no-unused-vars
  window.NOTEBASE_LOCAL_URL_PREFIX = window.NOTEBASE_IS_DESKTOP ? '' : '' // eslint-disable-line no-unused-vars
  // window.NOTEBASE_URL_PREFIX = window.NOTEBASE_IS_DESKTOP ? 'http://dev.notebase.dev:3199' : ''; // eslint-disable-line no-unused-vars
  // require('datejs')
  // require('dropbox')
  var chrono = require('chrono-node')
  var $ = require('jquery')
  const WebSocket = require('isomorphic-ws')
  // require('jquery.payment');
  window.$ = $
  window.jQuery = $
  window.WebSocket = WebSocket

  require('../js-lib/jquery.toc')

  /* eslint-disable */

  // Disable scrolling the parent element(s) when reaching the top/bottom
  $.fn.scrollLock = function() {
    return $(this).on('DOMMouseScroll mousewheel', function(h) {
      var g = $(this),
        f = this.scrollTop,
        d = this.scrollHeight,
        b = g.height(),
        i = h.originalEvent.wheelDelta,
        a = i > 0,
        c = function() {
          h.stopPropagation()
          h.preventDefault()
          h.returnValue = false
          return false
        }
      if (g.get(0).scrollHeight > g.get(0).clientHeight) {
        if (!a && -i > d - b - f) {
          g.scrollTop(d)
          return c()
        } else {
          if (a && i > f) {
            g.scrollTop(0)
            return c()
          }
        }
      }
    })
  }
  $.fn.scrollRelease = function() {
    return $(this).off('DOMMouseScroll mousewheel')
  }

  // Highlight the matched elements with a yellow overlay that fades away after <duration>
  $.fn.highlight = function(duration) {
    $(this).each(function() {
      var el = $(this)
      $('<div/>')
        .width(el.outerWidth())
        .height(el.outerHeight())
        .css({
          position: 'absolute',
          left: el.offset().left,
          top: el.offset().top,
          'background-color': '#ffff99',
          opacity: '.7',
          'z-index': '9999999'
        })
        .appendTo('body')
        .fadeOut(duration)
        .queue(function() {
          $(this).remove()
        })
    })
  }
  /* eslint-enable */

  /* eslint-disable */
  // Load Ink Filepicker
  // (function(a){if(window.filepicker){return}var b=a.createElement("script");b.type="text/javascript";b.async=!0;b.src=("https:"===a.location.protocol?"https:":"http:")+"//api.filepicker.io/v1/filepicker.js";var c=a.getElementsByTagName("script")[0];c.parentNode.insertBefore(b,c);var d={};d._queue=[];var e="pick,pickMultiple,pickAndStore,read,write,writeUrl,export,convert,store,storeUrl,remove,stat,setKey,constructWidget,makeDropPane".split(",");var f=function(a,b){return function(){b.push([a,arguments])}};for(var g=0;g<e.length;g++){d[e[g]]=f(e[g],d._queue)}window.filepicker=d})(document);
  // filepicker.setKey('AD0m5WsumSZempJokkthiz');

  // (function(a){if(window.filepicker){return}var b=a.createElement("script");b.type="text/javascript";b.async=!0;b.src=("https:"===a.location.protocol?"https:":"http:")+"//api.filestackapi.com/filestack.js";var c=a.getElementsByTagName("script")[0];c.parentNode.insertBefore(b,c);var d={};d._queue=[];var e="pick,pickMultiple,pickAndStore,read,write,writeUrl,export,convert,store,storeUrl,remove,stat,setKey,constructWidget,makeDropPane".split(",");var f=function(a,b){return function(){b.push([a,arguments])}};for(var g=0;g<e.length;g++){d[e[g]]=f(e[g],d._queue)}window.filepicker=d})(document);
  // filepicker.setKey('AD0m5WsumSZempJokkthiz');
  // filepicker.setKey('AsrKL7I1vQF2l-dwv12W2z');
  // Load Intercom
  // window.intercomSettings = {
  //   app_id: "pmxixnvq",
  // };
  // (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',intercomSettings);}else{var d=document;var i=function(){i.c(arguments)};i.q=[];i.c=function(args){i.q.push(args)};w.Intercom=i;function l(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/pmxixnvq';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);}if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})()
  /* eslint-enable */

  window._app = window._app || {}
  window._app.original_title = ''
  window._app.original_description = ''
  window._app.tab_states = {}
  window._app.prev_body_values = {}
  window._app.sync_timeouts = {}
  window._app.command_log = []
  window._app.focusItem = 'node'

  /* eslint-disable */
  // window._app.dropbox = new Dropbox.Client({key: 'ii2mojgid91kr07'});

  // window._app.dropbox.authDriver(new Dropbox.AuthDriver.Popup({
  //   receiverUrl: location.protocol + '//' + location.host + '/static/dropbox_oauth_receiver.html'}));
  /* eslint-enable */

  var Chrono = new chrono.Chrono()

  var todayParser = new chrono.Parser()

  // Provide search pattern
  todayParser.pattern = function () {
    return /today/i
  } // Provide search pattern
  todayParser.extract = function (text, ref, match, opt) {
    // eslint-disable-line no-unused-vars
    // Chrono will find all indexes of the text that match our pattern.
    // We need to check and return the result
    var matchedText = text.substr(match.index).match(todayParser.pattern())

    var numberOfWeeks = matchedText[1] || matchedText[3]
    if (!numberOfWeeks) {
      numberOfWeeks = 1 // default to 1 week if the number of weeks is not found
    }

    var numberOfDaysToAdd = 7 * numberOfWeeks
    /*
    var today = new Date();
    var nextWeek = new Date();
    nextWeek.setDate(today.getDate() + numberOfDaysToAdd);
    */

    var nextWeek = new Date(
      new Date().getTime() + numberOfDaysToAdd * 24 * 60 * 60 * 1000
    )

    return new chrono.ParsedResult({
      ref: ref,
      text: matchedText[0],
      index: match.index,
      start: {
        day: nextWeek.getDate(),
        month: nextWeek.getMonth() + 1,
        year: nextWeek.getFullYear()
      }
    })
  }

  Chrono.parsers.push(todayParser)

  var weeksParser = new chrono.Parser()

  // Provide search pattern
  weeksParser.pattern = function () {
    return /in (\d*) week(s|)|next week|(\d*) week(s|) (later|from now|from today)/i
  } // Provide search pattern

  weeksParser.extract = function (text, ref, match, opt) {
    // eslint-disable-line no-unused-vars
    // Chrono will find all indexes of the text that match our pattern.
    // We need to check and return the result
    var matchedText = text.substr(match.index).match(weeksParser.pattern())

    var numberOfWeeks = matchedText[1] || matchedText[3]
    if (!numberOfWeeks) {
      numberOfWeeks = 1 // default to 1 week if the number of weeks is not found
    }

    var numberOfDaysToAdd = 7 * numberOfWeeks
    /*
    var today = new Date();
    var nextWeek = new Date();
    nextWeek.setDate(today.getDate() + numberOfDaysToAdd);
    */

    var nextWeek = new Date(
      new Date().getTime() + numberOfDaysToAdd * 24 * 60 * 60 * 1000
    )

    return new chrono.ParsedResult({
      ref: ref,
      text: matchedText[0],
      index: match.index,
      start: {
        day: nextWeek.getDate(),
        month: nextWeek.getMonth() + 1,
        year: nextWeek.getFullYear()
      }
    })
  }

  Chrono.parsers.push(weeksParser)

  // Initialize TypeKit
  /* eslint-disable */
  try {
    if (typeof Typekit !== 'undefined') {
      Typekit.load()
    }
  } catch (e) {
    console.log(e) // eslint-disable-line no-console
  }
  /* eslint-enable */
})()

// window.addEventListener('error', function (ev) {
//   var el = document.getElementById('js-error');
//   el.style.display = 'block';
//   var msg = 'An unknown JavaScript error occurred. The safest thing to do right now is reload the page. The error was:<br /><br />';
//   var errMsg = '"' + ev.message + '" from ' + ev.filename + ':' + ev.lineno;
//   msg += errMsg;
//   el.innerHTML = msg;
//   // console.log('Caught[via "error" event]:  ' + errMsg);
//   console.log(ev); // has srcElement / target / etc
//   // evt.preventDefault();
// });

// window.onerror = function (msg, url, line) {
//   console.log("Caught[via window.onerror]: '" + msg + "' from " + url + ":" + line);
//   return true; // same as preventDefault
// };
