import './styles.css'

import React from 'react'
import _ from 'lodash'
// import moment from 'moment';

import Heading from '../heading'

import Node from '../../../../js/node'
import Misc from '../../../../js/misc'

export default class NodeFiles extends React.Component {
  addFile = file => {
    const { app, node } = this.props
    app.ajax(
      'add_node_file',
      {
        body: {
          org_id: app.orgId(),
          node_id: node.id,
          file_id: file.id,
          is_cover: true
        }
      },
      () => {
        const commonParams = { ...app.getCommonParams(), _skipUndoAdd: true }
        Node.modify(
          node,
          { attachment_count: node.attachment_count + 1 },
          commonParams,
          () => {
            app.setAppState({ updatedNodes: [node.id] })
          }
        )
      }
    )
  }

  deleteFile = nodeFile => {
    const { app, node } = this.props
    app.ajax('delete_node_file', { body: { id: nodeFile.id } }, () => {
      const commonParams = { ...app.getCommonParams(), _skipUndoAdd: true }
      Node.modify(
        node,
        { attachment_count: node.attachment_count - 1 },
        commonParams,
        () => {
          app.setAppState({ updatedNodes: [node.id] })
        }
      )
    })
  }

  browseFile = () => {
    const { app } = this.props
    app.browseFiles({ fileType: '*/*' }, file => {
      this.addFile(file)
    })
  }

  unsetCover = () => {
    const { app, node } = this.props
    app.ajax('unset_cover_image', { body: { node_id: node.id } })
  }

  render () {
    const { app, node } = this.props
    const userId = app.userId()
    const links = _.sortBy(
      app.state.entities.node_files.filter(e => e.node_id === node.id),
      'created_at'
    ).reverse()
    return (
      <div className='panel attachments'>
        {!this.props.noHeading && (
          <Heading {...this.props} name='Attachments' />
        )}
        <div className='files'>
          {links.map(l => {
            const file = app.state.entities.files.find(e => e.id === l.file_id)
            return (
              <div key={l.id}>
                <div className='img'>
                  <i className='fa fa-fw fa-external-link-square' />
                  <a
                    href={Misc.fileUrl(file)}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img src={Misc.fileUrl(file)} />
                  </a>
                </div>
                <div className='rest'>
                  <div className='filename'>{file.filename}</div>
                  <span onClick={() => this.deleteFile(l)}>
                    <i className='fa fa-fw fa-trash' />
                  </span>{' '}
                  {l.is_cover && (
                    <span className='link' onClick={this.unsetCover}>
                      Remove cover image
                    </span>
                  )}
                  {!l.is_cover && (
                    <span className='link' onClick={() => this.addFile(file)}>
                      Set cover image
                    </span>
                  )}
                </div>
              </div>
            )
          })}
        </div>
        {Boolean(!userId && !links.length) && (
          <div className='notice centered'>No attachments.</div>
        )}
        {userId && (
          <div className='add-new'>
            <button onClick={this.browseFile}>Add attachment</button>
          </div>
        )}
      </div>
    )
  }
}
