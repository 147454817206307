import $ from 'jquery'
import _ from 'lodash'
import $script from 'scriptjs'
// var MarkdownEditor = require('../../components/markdown_editor');

var constants = require('../..//lib/constants')

var Node = require('../../js/node')
var Misc = require('../../js/misc')

module.exports = {

  recursivelyConvertImages: function (files, i, doneCallback) {
    var _this = this
    var _file = files[i]
    if (_file) {
      if (Misc.isImage(_file)) {
        var file = _.omit(_file, ['id', 'thumb_key', 'thumb_url', 'created_at', 'updated_at', 'user_id'])
        var blob = {
          location: 'S3',
          path: '/thumbs/',
          access: 'public'
        }
        filepicker.convert(file, {width: 200, height: 200}, blob, function (file) { // eslint-disable-line no-undef
          Misc.jx(_this, 'add_thumb', _.extend(file, {id: _file.id}), function (res) { // eslint-disable-line no-unused-vars
            _this.recursivelyConvertImages(files, i + 1, doneCallback)

            // var errText = (res.body && res.body.error) ? res.body.error : res.text;
            // doneCallback(errText);
          })
        })
      } else {
        _this.recursivelyConvertImages(files, i + 1, doneCallback)
      }
    } else {
      doneCallback()
    }
  },

  launchFP: function (ev, opts, callback) {
    if (typeof ev === 'function') {
      callback = ev
      ev = null
    } else if (typeof opts === 'function') {
      callback = opts
      opts = null
    }
    opts = opts || {}
    const _this = this
    $script(['https://static.filestackapi.com/v3/filestack.js'], () => {
      const fsClient = filestack.init('AsrKL7I1vQF2l-dwv12W2z') // eslint-disable-line no-undef
      const defaultOptions = {
        fromSources: [
          'local_file_system',
          'url',
          'imagesearch',
          'facebook',
          'instagram',
          'googledrive',
          'dropbox',
          'evernote',
          'box',
          'github',
          'gmail',
          'onedrive'
        ],
        storeTo: {
          location: 's3',
          path: '/files/',
          access: 'public'
        }
      }
      const options = {...defaultOptions, ...opts}
      fsClient.pick(options).then(function (response) {
        if (typeof callback === 'function') {
          callback(response)
        } else {
          const files = response.filesUploaded
          var opts = {}
          var orgId
          if (_this.state.browseFilesOptions.org || _this.state.browseFilesOptions.orgId) {
            orgId = _this.state.browseFilesOptions.org ? _this.state.browseFilesOptions.org.id : _this.state.browseFilesOptions.orgId
          } else {
            orgId = _this.orgId()
          }
          if (orgId && !_this.state.usePersonalFileBrowser) {
            opts.org_id = orgId
          }
          _this.ajax('upload', {body: {opts: opts, files: files}}, function (res) { // eslint-disable-line no-unused-vars
            var data = {...opts}

            if (_this.state.browseFilesOptions && _this.state.browseFilesOptions.fileType) {
              data.fileType = _this.state.browseFilesOptions.fileType
            }

            Misc.jx(_this, 'browse_files', data, function (res2) {
              // TODO: hide spinner
              _this.setState({userFiles: res2.body})
            })
          })
          window.Intercom && window.Intercom('trackEvent', 'uploaded-files') // jshint ignore:line
        }
      })
    })
    if (ev && ev.preventDefault) {
      ev.preventDefault()
    }

    // var _this = this;
    // var opts = {
    // };
    // filepicker.pickAndStore({multiple: true}, opts, function(files) { // eslint-disable-line no-undef
    //   // console.log('files', files);
    //   var opts = {};
    //   var orgId;
    //   if (_this.state.browseFilesOptions.org || _this.state.browseFilesOptions.orgId) {
    //     orgId = _this.state.browseFilesOptions.org ? _this.state.browseFilesOptions.org.id : _this.state.browseFilesOptions.orgId;
    //   } else {
    //     orgId = _this.orgId();
    //   }
    //   if (orgId && !_this.state.usePersonalFileBrowser) {
    //     opts.org_id = orgId;
    //   }
    //   _this.ajax('upload', {body: {opts: opts, files: files}}, function(res) { // eslint-disable-line no-unused-vars
    //     var data = _.xclone(opts);

    //     if (_this.state.browseFilesOptions && _this.state.browseFilesOptions.fileType) {
    //       data.fileType = _this.state.browseFilesOptions.fileType;
    //     }

    //     Misc.jx(_this, 'browse_files', data, function(res2) {
    //       // TODO: hide spinner
    //       _this.setState({userFiles: res2.body});
    //     }.bind(_this));
    //   });
    //   window.Intercom && window.Intercom('trackEvent', 'uploaded-files'); // jshint ignore:line
    // });
    // ev.preventDefault();
  },

  openRevisionBrowser: function (node) {
    var _this = this
    var data = {node_id: node.id}
    this.setState({browsingRevisionsFor: node, nodeRevisions: null}, function () {
      Misc.jx(_this, 'browse_revisions', data, function (res) {
        _this.setState({nodeRevisions: res.body.revisions})
        $('body').addClass('modal-open')
      })
    })
    window.Intercom && window.Intercom('trackEvent', 'opened-revisions') // jshint ignore:line
  },

  closeRevisionBrowser: function (ev) {
    this.setState({browsingRevisionsFor: null, nodeRevisions: null})
    $('body').removeClass('modal-open')
    ev.preventDefault()
  },

  previewRevision: function (revision, ev) {
    var _this = this
    var data = {id: revision.id}
    Misc.jx(this, 'get_revision', data, function (res) {
      _this.setState({previewingRevision: res.body.revision})
    })
    ev.preventDefault()
  },

  closePreviewRevision: function (ev) {
    this.setState({previewingRevision: null})
    ev.preventDefault()
  },

  diffRevisions: function (newerRev, olderRev, ev) {
    var _this = this
    var data = {newer_id: newerRev.id}
    if (olderRev && !olderRev.preventDefault) {
      data.older_id = olderRev.id
    }

    Misc.jx(this, 'diff_nodes', data, function (res) {
      _this.setState({diffingRevisions: {diff: res.body.diff, older: res.body.older, newer: res.body.newer}})
    })
    ev.preventDefault()
  },

  closeDiffRevision: function (ev) {
    this.setState({diffingRevisions: null})
    ev.preventDefault()
  },

  openRevertRevision: function (revision, ev) {
    console.log('opening revert overlay for ', revision) // eslint-disable-line no-console
    this.setState({revertingRevision: revision})
    ev.preventDefault()
  },

  revertRevision: function (ev) {
    var _this = this
    var data = {id: this.state.revertingRevision.id}
    Misc.jx(this, 'get_revision', data, function (res) {
      _this.setState({previewingRevision: res.body.revision})
      var changes = {body: res.body.revision.node.body, type: res.body.revision.node.type}
      console.log(changes) // eslint-disable-line no-console
      var results = Node.modify(_this.state.browsingRevisionsFor.id, changes, _this.getCommonParams())
      _this.state.undo.add({
        undo: results.undoCommands,
        redo: [{
          obj: 'Node',
          cmd: 'modify',
          args: [_this.state.browsingRevisionsFor.id, changes]
        }]
      }, true)
      var parsedBody = Misc.parseMarkdown(changes.body)
      _this.setAppState(_.extend(results, {
        revertingRevision: null,
        browsingRevisionsFor: null,
        previewingRevision: null,
        parsedBody: parsedBody
      }),
      {body: changes.body})
    })
    ev.preventDefault()
  },

  closeRevertRevision: function (ev) {
    this.setState({revertingRevision: null})
    ev.preventDefault()
  },

  browseFiles: function (options, callback) {
    options = options || {}
    var _this = this
    var orgId
    var data = {
      limit: constants.BROWSER_ITEMS_PER_PAGE
    }
    var usePersonalFileBrowser = this.state.usePersonalFileBrowser
    if (options.hasOwnProperty('usePersonalFileBrowser')) {
      usePersonalFileBrowser = options.usePersonalFileBrowser
    }
    if (options.org || options.orgId) {
      orgId = options.org ? options.org.id : options.orgId
    } else {
      orgId = this.orgId()
    }
    if (orgId && !usePersonalFileBrowser) {
      data.org_id = orgId
    }
    if (options.fileType) {
      data.fileType = options.fileType
    }

    Misc.jx(this, 'browse_files', data, function (res) {
      var newState = {
        browsingFiles: true,
        userFiles: res.body,
        browseFilesOptions: options,
        browseFilesCallback: callback
      }
      if (options.hasOwnProperty('usePersonalFileBrowser')) {
        newState.usePersonalFileBrowser = options.usePersonalFileBrowser
      }
      _this.setState(newState)
      $('body').addClass('modal-open')
    })
  },

  selectFile: function (file, ev) {
    if (typeof this.state.browseFilesCallback === 'function') {
      this.state.browseFilesCallback(file)
    } else {
      if (window._app.editor) {
        this.insertUrlAtCursor(file, ev)
      }
    }

    this.closeFileBrowser(ev)
    ev.preventDefault()
  },

  deleteFile: function (rawFile, ev) {
    var _this = this
    this.getConfirmation(function () {
      var deleteThumb = function (file, callback) {
        if (file.thumb_url) {
          filepicker.remove({url: file.thumb_url, key: file.thumb_key}, callback) // eslint-disable-line no-undef
        } else {
          callback()
        }
      }
      var file = _.omit(rawFile, ['id', 'thumb_key', 'thumb_url', 'created_at', 'updated_at', 'user_id'])
      filepicker.remove(file, // eslint-disable-line no-undef
        function () {
          deleteThumb(rawFile, function () {
            var data = {id: rawFile.id}
            if (_this.state.orgId && !_this.state.usePersonalFileBrowser) {
              data.org_id = _this.state.orgId
            }
            Misc.jx(_this, 'delete_file', data, function (res) {
              _this.setState({userFiles: res.body.files})
            })
          })
        })
    })
    ev.preventDefault()
    ev.stopPropagation()
  },

  newFile: function (ev) {
    this.setState({editingFile: true}, function () {
      $('#file-editor-filename').val('')
      $('#file-editor-mimetype').val('text/plain')
      $('#file-editor-container').show()
      // window._app.fileEditor = ace.edit('file-editor');
      window._app.fileEditor.setTheme('ace/theme/dreamweaver')
      window._app.fileEditor.getSession().setMode('ace/mode/markdown')
      window._app.fileEditor.getSession().setUseWrapMode(true)
      window._app.fileEditor.setHighlightActiveLine(false)
      window._app.fileEditor.renderer.setShowGutter(false)
      window._app.fileEditor.setValue('')
      window._app.fileEditor.setShowPrintMargin(false)
      window._app.fileEditor.moveCursorTo(0, 0)
      window._app.fileEditor.focus()
    })
    ev && ev.preventDefault && ev.preventDefault() // jshint ignore:line
    ev && ev.stopPropagation && ev.stopPropagation() // jshint ignore:line
  },

  editFile: function (file, ev) {
    var _this = this

    function featherLaunch () {
      const opts = {
        image: 'user-image-' + file.id,
        url: Misc.fileUrl(file)
      }
      // if (hiresEnabled) {
      //   opts.hiresUrl = $('#preview-image').attr('src');
      //   opts.hiresWidth = entry.width_in_pixels;
      //   opts.hiresHeight = entry.height_in_pixels;
      // }
      window.featherEditor.launch(opts)
    }

    function onSave (imageID, newURL) {
      // const img = document.getElementById('preview-image');
      // img.src = newURL;
      // _this.saveNewVersion(newURL);
      // window.featherEditor.close();
      // return false;

      // console.log('Aviary onSave imageID:', imageID);
      // console.log('Aviary onSave newURL:', newURL);
      var file = _this.state.userFiles.files.find(f => f.id === imageID.replace('user-image-', ''))
      filepicker.writeUrl({url: file.url}, // eslint-disable-line no-undef
        newURL,
        function (newImg) { // eslint-disable-line no-unused-vars
          // console.log('thumb_url:', file.thumb_url);
          filepicker.writeUrl({url: file.thumb_url}, // eslint-disable-line no-undef
            file.url + '/convert?w=200&h=200',
            function (newThumb) { // eslint-disable-line no-unused-vars
              var data = {}
              if (_this.state.browseFilesOptions && _this.state.browseFilesOptions.fileType) {
                data.fileType = _this.state.browseFilesOptions.fileType
              }

              Misc.jx(_this, 'browse_files', data, function (res) {
                // TODO: hide spinner
                var newUserFiles = [..._this.state.userFiles]
                newUserFiles.files = [..._this.state.userFiles.files]
                newUserFiles.size = _this.state.userFiles.files.reduce((sum, file) => sum + file.size, 0)
                newUserFiles.count = _this.state.userFiles.files.length
                _this.setState({userFiles: res.body.files})
              })

              // console.log(JSON.stringify(newThumb));
            }

          )
          // console.log(JSON.stringify(newImg));
        }

      )
    }

    if (Misc.isImage(file)) {
      if (typeof Aviary !== 'undefined') { // eslint-disable-line no-undef
        featherLaunch()
      } else {
        $script(['https://dme0ih8comzn4.cloudfront.net/imaging/v3/editor.js'], () => {
          window.featherEditor = new Aviary.Feather({ // eslint-disable-line no-undef
            apiKey: 'b88882ba1e682bdc',
            // authenticationURL: `${config.ajaxUrl}_/creative_sdk_auth`,
            // authenticationURL: '/_/creative_sdk_auth',
            // enableCORS: true,
            enableCORS: false,
            onError: (err) => {
              console.error(err) // eslint-disable-line no-console
            },
            onSaveButtonClicked: () => {
              // if (hiresEnabled) {
              //   window.featherEditor.saveHiRes();
              //   return false;
              // }
            },
            onSaveHiRes: onSave,
            onSave: onSave,
            onLoad: () => {
              featherLaunch()
            }
          })
        })
      }
      $('#user-image-scratch-area').html('<img id="user-image-' + file.id + '" src="' + Misc.fileUrl(file) + '?x' + _.random(100000000) + '" />')

      // this.featherEditor.launch({
      //   image: 'user-image-' + file.id,
      //   url: Misc.fileUrl(file),
      // });
    } else {
      _this.setState({editingFile: true, editedFile: file}, function () {
        filepicker.read({url: file.url}, function (data) { // eslint-disable-line no-undef
          $('#file-editor-filename').val(file.filename)
          $('#file-editor-mimetype').val(file.mimetype)
          $('#file-editor-container').show()
          // window._app.fileEditor = ace.edit('file-editor');
          window._app.fileEditor.setTheme('ace/theme/dreamweaver')
          window._app.fileEditor.getSession().setMode('ace/mode/markdown')
          window._app.fileEditor.getSession().setUseWrapMode(true)
          window._app.fileEditor.setHighlightActiveLine(false)
          window._app.fileEditor.renderer.setShowGutter(false)
          window._app.fileEditor.setValue(data)
          window._app.fileEditor.setShowPrintMargin(false)
          window._app.fileEditor.moveCursorTo(0, 0)
          window._app.fileEditor.focus()
        })
      })
    }

    ev.preventDefault()
    ev.stopPropagation()
    return false
  },

  saveFile: function (ev) {
    var _this = this
    var opts = {}
    var orgId = this.orgId()
    if (orgId && !this.state.usePersonalFileBrowser) {
      opts.org_id = orgId
    }
    if (this.state.editedFile) {
      filepicker.write({url: _this.state.editedFile.url}, // eslint-disable-line no-undef
        window._app.fileEditor.getValue(),
        function (newFile) {
          console.log('newFile:', newFile) // eslint-disable-line no-console
          var filename = $('#file-editor-filename').val()
          var mimetype = $('#file-editor-mimetype').val()
          if ((filename !== newFile.filename) || mimetype !== newFile.mimetype) {
            var changes = {filename: $('#file-editor-filename').val(), mimetype: $('#file-editor-mimetype').val()}
            newFile = _.extend({..._this.state.editedFile}, changes)
            filepicker.write({url: _this.state.editedFile.url}, // eslint-disable-line no-undef
              newFile,
              function (newestFile) {
                console.log('newFile (updated):', newFile) // eslint-disable-line no-console
                console.log('newestFile:', newestFile) // eslint-disable-line no-console
                Misc.jx(_this, 'upload', {opts: opts, files: [newFile]}, function (res) {
                  _this.setState({userFiles: res.body.files})
                  _this.closeFileEditor()
                })
              }
            )
          } else {
            _this.closeFileEditor()
          }
        }
      )
    } else {
      var blob = {
        filename: $('#file-editor-filename').val(),
        mimetype: $('#file-editor-mimetype').val(),
        location: 'S3',
        path: '/files/',
        access: 'public'
      }
      filepicker.store(window._app.fileEditor.getValue(), blob, function (newFile) { // eslint-disable-line no-undef
        console.log('newFile:', newFile) // eslint-disable-line no-console
        _this.ajax('upload', {body: {opts: opts, files: [newFile]}}, function (res) {
          console.log('res.body.files:', res.body.files) // eslint-disable-line no-console
          _this.setState({userFiles: res.body.files})
        })
        _this.closeFileEditor()
      })
    }
    window.Intercom && window.Intercom('trackEvent', 'created-file') // jshint ignore:line
    ev.preventDefault()
  },

  closeFileBrowser: function (ev) {
    this.setState({browsingFiles: false, browseFilesCallback: null})
    $('body').removeClass('modal-open')
    ev.preventDefault()
  },

  closeFileEditor: function (ev) {
    this.setState({editingFile: false, editedFile: null})
    if (ev) {
      ev.preventDefault()
    }
  },

  setFileBrowserMode: function (usePersonal) {
    if ((this.state.usePersonalFileBrowser && !usePersonal) || (!this.state.usePersonalFileBrowser && usePersonal)) {
      this.browseFiles({usePersonalFileBrowser: usePersonal})
    }
  }

}
