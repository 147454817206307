import './styles.css'

import React from 'react'
// import sortBy from 'lodash.sortby'

import Avatar from '../avatar'

export default class PeoplePicker extends React.Component {
  state = {
    str: ''
  }

  // addUser = user => {
  //   const { app, node } = this.props
  //   app.ajax(
  //     'add_node_user',
  //     { body: { org_id: app.orgId(), node_id: node.id, assigned_to: user.id } },
  //     () => {
  //       app.setAppState({ updatedNodes: [node.id] })
  //     }
  //   )
  // }

  // deleteUser = nodeUser => {
  //   const { app, node } = this.props
  //   app.ajax('delete_node_user', { body: { id: nodeUser.id } }, () => {
  //     app.setAppState({ updatedNodes: [node.id] })
  //   })
  // }

  handleInputKeyPress = ev => {
    const { app } = this.props
    if (ev.key === 'Enter') {
      app.state.floater.data.onPick({
        collection: 'others',
        data: { name: this.state.str }
      })
      this.setState({ str: '' })
    }
  }

  handlePick = (collection, data) => {
    const { app } = this.props
    app.state.floater.data.onPick({
      collection,
      data
    })
    app.setState({ floater: null })
  }

  render () {
    const { app } = this.props
    const node = app.node(app.state.floater.data.nodeId)
    const people = (node.data || {}).people || {}
    const orgId = app.orgId()
    const allUsers = orgId
      ? app.state.entities.org_users.filter(e => e.org_id === orgId)
      : []
    const allNodes = app.orgNodes()
    const nodes = allNodes.filter(n => n.type === 'person')
    const others = people.others || []

    return (
      <div className='people-picker'>
        <div className='input-container'>
          <input
            placeholder='Type a name'
            value={this.state.str}
            onKeyPress={this.handleInputKeyPress}
            onChange={ev => this.setState({ str: ev.target.value })}
          />
        </div>
        <div className='suggestions'>
          {Boolean(allUsers.length) && (
            <div>
              <h5>Users</h5>
              <ul>
                {allUsers.map(u => {
                  const user = app.state.entities.users.find(
                    usr => usr.id === u.user_id
                  )

                  const checked = (people.users || []).indexOf(u.user_id) !== -1
                  return (
                    <li
                      key={u.id}
                      onClick={() => this.handlePick('users', user)}
                      className={`user-row ${checked ? 'checked' : ''}`}
                    >
                      <div className='icon'>
                        <Avatar app={app} user={user} size='tiny' />
                      </div>
                      <div className='name'>
                        {user.first_name} {user.last_name}
                      </div>
                      <div className='check'>
                        <i className='fa fa-fw fa-check' />
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          )}
          {Boolean(nodes.length) && (
            <div>
              <h5>People</h5>
              <ul>
                {nodes.map(n => {
                  const checked = (people.people || []).indexOf(n.id) !== -1
                  return (
                    <li
                      key={n.id}
                      onClick={() => this.handlePick('people', n)}
                      className={`user-row ${checked ? 'checked' : ''}`}
                    >
                      <div className='icon'>{app.nodeIcon(n)}</div>
                      <div className='name'>{n.title}</div>
                      <div className='check'>
                        <i className='fa fa-fw fa-check' />
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          )}
          {Boolean(others.length) && (
            <div>
              <h5>Others</h5>
              <ul>
                {others.map((o, i) => {
                  const other = { ...(o || {}), _idx: i }
                  return (
                    <li
                      key={i}
                      onClick={() => this.handlePick('others', other)}
                      className='user-row'
                    >
                      <div className='icon'>
                        <i className='fa fa-fw fa-user' />
                      </div>
                      <div className='name'>{o ? o.name : '(no name)'}</div>
                      <div className='check'>
                        <i className='fa fa-fw fa-times' />
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          )}
        </div>
      </div>
    )
  }
}
