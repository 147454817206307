import './styles.css'

import React from 'react'

import SetPlaceholder from '../../set_placeholder'
import Avatar from '../../../../../avatar'

export default class People extends React.Component {
  render () {
    const { app, node } = this.props
    const data = node.data || {}
    const people = data.people || {}
    const allNodes = app.orgNodes()
    return (
      <div className='people icon-list'>
        {Boolean(
          (!people.users || !people.users.length) &&
            (!people.people || !people.people.length) &&
            (!people.others || !people.others.length)
        ) && (
          <SetPlaceholder
            text='Add People'
            onClick={ev => {
              app.toggleFloater(ev.target, 'people-picker', {
                field: 'data.people',
                nodeId: node.id,
                onPick: this.props.handlePersonPick
              })
            }}
          />
        )}
        {Boolean(
          (people.users && people.users.length) ||
            (people.people && people.people.length) ||
            (people.others && people.others.length)
        ) && (
          <div className='actual-data'>
            {Boolean(people.users && people.users.length) && (
              <div>
                {people.users.map(uId => {
                  const user = app.state.entities.users.find(u => u.id === uId)
                  return (
                    <div
                      key={uId}
                      className='item user'
                      onClick={() => app.pushSidebar(['user', { userId: uId }])}
                    >
                      <div className='avatar-container'>
                        <Avatar app={app} size='tiny' user={user} />
                      </div>
                      <span>
                        {user.first_name} {user.last_name}
                      </span>
                    </div>
                  )
                })}
              </div>
            )}
            {Boolean(people.people && people.people.length) && (
              <div>
                {people.people.map(pId => {
                  const person = allNodes.find(n => n.id === pId)
                  return (
                    <div
                      key={pId}
                      className='item person'
                      onClick={() => app.setRoot(pId)}
                    >
                      <i className='icon fa fa-fw fa-user' />
                      <span>{person.title}</span>
                    </div>
                  )
                })}
              </div>
            )}
            {Boolean(people.others && people.others.length) && (
              <div>
                {people.others.map((o, i) => (
                  <div key={i} className='item other'>
                    <i className='icon fa fa-fw fa-address-book-o' />
                    <span>{o.name}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}
