import React from 'react'
import $ from 'jquery'
import _ from 'lodash'
// import LazyLoad from 'react-lazyload';
import classNames from 'classnames'

import TreeNode from '../tree_node'
import Board from '../board'
import NodesTopBar from './nodes_top_bar/'

import Node from '../../js/node'
import User from '../../js/user'
import Org from '../../js/org'
import OrgUser from '../../js/org_user'
import Misc from '../../js/misc'

import constants from '../../lib/constants'

var CurrentNode = React.createFactory(require('./current_node'))

class Nodes extends React.Component {
  shouldComponentUpdate (nextProps) {
    if (this.props.app && this.props.shouldComponentTypeUpdate) {
      return this.props.app.shouldComponentTypeUpdate(nextProps)
    } else {
      return true
    }
  }

  saveHeading = newText => {
    const updates = { heading: newText }
    const commonParams = Object.assign({}, this.props.app.getCommonParams())
    commonParams.updatedState = commonParams.updatedState || {}
    commonParams.updatedState.headingOnly = true
    commonParams._skipFlushUndo = true
    const model = this.props.org ? OrgUser : User
    const id = this.props.org
      ? this.props.app.orgUser().id
      : this.props.app.state.userId
    model.modify(id, updates, commonParams)
  }

  handleHeadingKeyDown = ev => {
    clearTimeout(window._app.headingTimeout)
    var newText = $(ev.target).text()
    if (ev.keyCode === 40 || (ev.ctrlKey && ev.keyCode === 78)) {
      // DOWN ARROW
      var first = $('#nodes li.node')
      if (first.length) {
        Node.focus(first.data('node-id'), null)
      }
      this.saveHeading(newText)
      ev.preventDefault()
    }
  }

  handleHeadingKeyUp = ev => {
    var _this = this
    var newText = $(ev.target).text()

    clearTimeout(window._app.headingTimeout)

    window._app.headingTimeout = setTimeout(function () {
      _this.saveHeading(newText)
    }, 500)
  }

  // handleHeadingBlur: function(ev) {
  //   var div = $(ev.target);
  //   if (!window._app.skipHeadingBlur) {
  //     Node.flushTextUndo({el: div, node: this.props.node}, this.props.getCommonParams());
  //   }
  //   window._app.skipHeadingBlur = false;
  // },

  toggleBrowseContextMenu = () => {
    return this.props.editNodeId ? 'Save' : 'Edit'
  }

  editButtonText = () => {
    return this.props.editNodeId ? 'Save' : 'Edit'
  }

  publish = () => {
    var node = this.props.app.rootNode() || this.props.app.shareNode()
    var newNode = { ...node }
    newNode.settings = { ...node.settings }
    newNode.settings.published = true
    this.props.app.setPanel('note-settings-overlay', {
      data: { node: newNode }
    })
  }

  navigate = node => {
    var _this = this
    return function () {
      _this.props.setRoot(node)
      return false
    }
  }

  isCollapsed = node => {
    var tab = this.props.tab
    return (
      this.props.app.state.isFilterEmpty &&
      !this.props.app.state.searchstring &&
      (!tab ||
        !tab.expanded_nodes ||
        tab.expanded_nodes.indexOf(node.id) === -1)
    )
  }

  showNode = node => {
    var tab = this.props.tab
    var hideCompleted = Misc.hideCompleted(this.props.user, tab)
    if (node.type !== 'placeholder') {
      if (
        this.props.displayNodes === this.props.nodes ||
        this.props.displayNodes.indexOf(node) !== -1
      ) {
        return (
          !hideCompleted ||
          !node.completed ||
          (node.completed && this.props.tmpVisible.indexOf(node.id) !== -1)
        )
      }
    }
  }

  getParents = () => {
    const _this = this
    const { app, tab, org } = this.props
    if (this.props.searchstring && !this.props.displayNodes.length) {
      // No search results
      return [[0, { type: 'no-search-results' }]]
    } else {
      let rootNode
      let useRootAnyway = false
      if (app.state.anonShareId && !this.props.rootNode) {
        const share = app.share(app.state.anonShareId)
        if (share) {
          rootNode = app.node(share.node_id)
        }
      } else if (
        this.props.rootNode &&
        (!this.props.searchstring || /^\s*>\s*/.test(this.props.searchstring))
      ) {
        rootNode = this.props.rootNode
      } else if (
        !/^\s*<\s*/.test(this.props.searchstring) &&
        tab &&
        tab.root_id &&
        tab.pinned
      ) {
        rootNode = Node.get(tab.root_id, this.props.nodes)
        useRootAnyway = true
      }

      if (this.props.app.isListView()) {
        var nodes = this.props.orgNodes
        nodes = rootNode
          ? Node.subTree(rootNode, nodes)
          : this.props.displayNodes
        var sortKey = tab.settings.list_sort_key || 'updated_at'
        return _.sortBy(nodes, sortKey)
          .reverse()
          .slice(0, 100)
      } else {
        var parents
        var ret = []
        parents = this.props.nodes.filter(function (n) {
          if (
            rootNode &&
            (useRootAnyway ||
              !_this.props.searchstring ||
              /^\s*>\s*/.test(_this.props.searchstring))
          ) {
            var id = rootNode.remote_id ? rootNode.remote_id : rootNode.id
            return n.parent_id === id
          } else {
            if (_this.props.share) {
              return n.parent_id === _this.props.share.node_id
            } else {
              if (org) {
                return (
                  (n.parent_id === null ||
                    typeof n.parent_id === 'undefined') &&
                  !n.inbox &&
                  n.org_id === org.id
                )
              } else {
                return (
                  (n.parent_id === null ||
                    typeof n.parent_id === 'undefined') &&
                  !n.inbox &&
                  n.user_id === _this.props.user.id
                )
              }
            }
          }
        })
        var i = 0
        var next = this.props.app.isListView()
          ? parents[0]
          : parents.find(function (parent) {
            return !parent.prev_id
          })
        while (next) {
          if (this.showNode(next)) {
            ret.push(next)
          }
          i++
          next = this.props.app.isListView()
            ? parents[i]
            : parents.find(function (parent) {
              return parent.prev_id === next.id
            })
        }
        return ret
      }
    }
  }

  getNodes = () => {
    // var t0 = new Date().getTime();
    var _this = this
    var nodes = []

    var isListView = this.props.app.isListView()

    if (isListView) {
      var sortField = 'created_at'
      var numOfNodes = 100
      var workNodes = this.props.rootNode
        ? Node.subTree(this.props.rootNode.id, this.props.nodes)
        : this.props.nodes
      return _.sortBy(workNodes, sortField)
        .reverse()
        .slice(0, numOfNodes)
        .map(function (n) {
          return [0, n]
        })
    } else {
      var doRecurse = function (node, level) {
        // console.log('node', node.title)
        if (_this.showNode(node)) {
          nodes.push([level, node])
          var isCollapsed = _this.isCollapsed(node)
          if (!isCollapsed) {
            var subChildren = _this.props.nodes.filter(function (n) {
              return n.parent_id === (node.remote_id || node.id)
            })
            var next = subChildren.find(function (child) {
              return !child.prev_id
            })
            while (next) {
              var newLevel = isListView ? level : level + 1
              doRecurse(next, newLevel)
              next = subChildren.find(function (child) {
                return child.prev_id === next.id
              })
            }
          }
        }
      }

      this.getParents().forEach(function (parent) {
        doRecurse(parent, 0)
      })
      // console.log('getNodes.time:', new Date().getTime() - t0);
      return nodes
    }
  }

  componentDidMount () {
    if (this.mainHeading) {
      this.mainHeading.innerHTML = this.props.app.headingForUserOrOrg()
    }
    this.props.app.setupRichEditing()
  }

  componentDidUpdate () {
    // console.log('---------NODES WAS UPDATED---------');
    // Object.keys(this.props).forEach(function(key) {
    //   console.log(key, _this.props[key] === prevProps[key]);
    // });
    if (!this.props.headingOnly && this.mainHeading) {
      this.mainHeading.innerHTML = this.props.app.headingForUserOrOrg()
    }
    this.props.app.setupRichEditing()
    // console.log('ready to render more node stuff');
  }

  nodeOpts = (node, prevLevel, level, nextLevel, isFirst, isLast, idx) => {
    var nodeAccessLevel = this.props.app.accessLevelForNode(node)
    return {
      key: node.id,
      app: this.props.app,
      prevLevel: prevLevel,
      level: level,
      nextLevel: nextLevel,
      first: isFirst,
      last: isLast,
      idx: idx,
      node: node,
      accessLevel: nodeAccessLevel,
      nodes: this.props.nodes,
      displayNodes: this.props.displayNodes,
      user: this.props.user,
      tab: this.props.tab,
      rootNode: this.props.rootNode,
      rawTitleNode: this.props.rawTitleNode,
      setRoot: this.props.setRoot,
      tmpVisible: this.props.tmpVisible,
      pubUrl: this.props.pubUrl,
      setAppState: this.props.setAppState,
      getCommonParams: this.props.getCommonParams,
      setOverlay: this.props.setPanel,
      overlay: this.props.overlay,
      updatedTextNode: this.props.updatedTextNode,
      dragged: this.props.dragged,
      draggedOver: this.props.draggedOver,
      dragIsValid: this.props.dragIsValid,
      searchstring: this.props.searchstring,
      sharedShares: this.props.sharedShares,
      selectedInboxItems: this.props.selectedInboxItems,
      descriptionNode: this.props.descriptionNode,
      dueNode: this.props.dueNode,
      assignNode: this.props.assignNode,
      assignFilter: this.props.assignFilter,
      assignPickerIndex: this.props.assignPickerIndex,
      authToken: this.props.authToken,
      undo: this.props.undo,
      nodeLink: this.props.nodeLink,
      updatedNodes: this.props.updatedNodes,
      updateAllNodes: this.props.updateAllNodes,
      replaceNodes: this.props.replaceNodes
    }
  }

  render () {
    // var t0 = new Date().getTime()
    var _this = this
    var org = this.props.org
    var isOwner = Org.isOwner(
      org,
      this.props.user,
      this.props.app.getCommonParams()
    )

    var contentEditable = true
    var currentNodeAccessLevel, nodes
    var rawNodes = this.getNodes()
    if (rawNodes[0] && rawNodes[0][0].type === 'no-search-results') {
      nodes.push(
        <li key='no-hits' className='no-search-results'>
          No matches
        </li>
      )
    } else {
      nodes = rawNodes.map(function (node, i) {
        var prev = rawNodes[i - 1]
        var next = rawNodes[i + 1]
        var prevLevel = prev ? prev[0] : null
        var nextLevel = next ? next[0] : null
        var level = node[0]
        // const treeNode = React.createElement(TreeNode, _this.nodeOpts(node[1], prevLevel, level, nextLevel, (i === 0), (i === rawNodes.length - 1), i));
        // return <LazyLoad key={i} height={60} overflow={true}>{treeNode}</LazyLoad>;
        return React.createElement(
          TreeNode,
          _this.nodeOpts(
            node[1],
            prevLevel,
            level,
            nextLevel,
            i === 0,
            i === rawNodes.length - 1,
            i
          )
        )
      })
    }

    var currentNode, rootNode
    if (this.props.rootNode || this.props.shareNode) {
      rootNode = this.props.rootNode || this.props.shareNode
      currentNodeAccessLevel = this.props.app.accessLevelForNode(rootNode)
      // console.log('currentNodeAccessLevel', currentNodeAccessLevel);
      contentEditable = Boolean(
        currentNodeAccessLevel >= constants.WRITE_ACCESS
      )
      var resolvedNode = Node.getResolved(rootNode, this.props.nodes)
      var currentNodeOpts = {
        app: this.props.app,
        nodesComponent: this,
        node: rootNode,
        user: this.props.user,
        org: this.props.org,
        tab: this.props.tab,
        rootNode: this.props.rootNode,
        rawTitleNode: this.props.rawTitleNode,
        resolvedNode: resolvedNode,
        nodes: this.props.nodes,
        setAppState: this.props.setAppState,
        getCommonParams: this.props.getCommonParams,
        editNodeId: this.props.editNodeId,
        updatedTextNode: this.props.updatedTextNode,
        parsedBody: this.props.parsedBody,
        previewBody: this.props.previewBody,
        tmpVisible: this.props.tmpVisible,
        createdAt: Misc.timeFor(resolvedNode.created_at),
        updatedAt: Misc.timeFor(resolvedNode.updated_at),
        sharedShares: this.props.sharedShares,
        descriptionNode: this.props.descriptionNode,
        updatedNodeIds: this.props.updatedNodeIds,
        updateAllNodes: this.props.updateAllNodes,
        undo: this.props.undo,
        replaceNodes: this.props.replaceNodes,
        editorCompanion: this.props.editorCompanion,
        addingData: this.props.addingData
      }

      currentNode = CurrentNode(currentNodeOpts)
    }

    var headingClasses = classNames({
      'root-heading': true,
      hidden: rootNode
    })
    var nodeClasses = classNames({
      'heading-container': true,
      hidden:
        !rootNode ||
        (_this.props.searchstring &&
          !/^\s*>\s*$/.test(_this.props.searchstring))
    })
    // var pubInfoClasses = classNames({
    //   'hidden': !rootNode || !((rootNode.settings && rootNode.settings.published && rootNode.short_id) || rootNode.type === 'site'),
    // });

    // console.log(this.props.updatedNodes);
    // var inbox;
    // if (this.props.user) {
    //   var inboxIsOpen = this.props.user && this.props.user.settings && this.props.user.settings.show_inbox;
    //   inbox = <Inbox app={this.props.app}
    //                  user={this.props.user}
    //                  org={this.props.org}
    //                  tab={this.props.tab}
    //                  isOpen={inboxIsOpen}
    //                  descriptionNode={this.props.descriptionNode}
    //                  selectedInboxItems={this.props.selectedInboxItems} />;
    // }

    var embedButton
    if (this.props.share && this.props.share.type === 'email') {
      var embedded = this.props.nodes.find(function (n) {
        return n.remote_id === _this.props.share.node_id
      })
      if (!embedded) {
        embedButton = (
          <button
            onClick={() => this.props.app.embed(this.props.share)}
            id='embed-button'
            className='btn dark'
          >
            Embed this in your Notebase
          </button>
        )
      }
    }

    var nodesClasses = 'nodes tree'
    if (
      this.props.app.state.editNodeId &&
      (!this.props.user ||
        (this.props.user.settings.use_full_screen_editor &&
          (!this.props.user.settings.editor_companion ||
            this.props.user.settings.editor_companion !== 'lists')))
    ) {
      nodesClasses += ' hidden'
    }

    // var t1 = new Date().getTime();
    // console.log('nodes render.time:', t1 - t0);
    var mainContent
    if (nodes.length || rootNode || !org || isOwner) {
      mainContent = (
        <div className='content main'>
          <div id='nodes'>
            {embedButton}
            <div className={nodeClasses}>{currentNode}</div>
            <h2 className={headingClasses}>
              <span
                ref={mainHeading => {
                  this.mainHeading = mainHeading
                }}
                className='heading'
                contentEditable={contentEditable}
                onKeyDown={this.handleHeadingKeyDown}
                onKeyUp={this.handleHeadingKeyUp}
              />
            </h2>
            {Boolean(
              !this.props.app.state.searchstring &&
                !this.props.app.editNodeId() &&
                rootNode &&
                rootNode.mode === 'board'
            ) && (
              <Board
                app={this.props.app}
                tab={this.props.tab}
                boardNode={rootNode}
                orgNodes={this.props.orgNodes}
                getParents={this.getParents}
              />
            )}
            {Boolean(
              this.props.app.state.searchstring ||
                this.props.app.editNodeId() ||
                (!rootNode || rootNode.mode !== 'board')
            ) && <ul className={nodesClasses}>{nodes}</ul>}
          </div>
        </div>
      )
    } else {
      mainContent = (
        <div className='content main'>
          <h2 className={headingClasses}>
            <span
              ref={mainHeading => {
                this.mainHeading = mainHeading
              }}
            />
          </h2>
          <div className='no-nodes-info'>
            No items have been shared with you. Contact an admin if you feel
            that this is a mistake.
          </div>
        </div>
      )
    }
    const ret = (
      <div id='main'>
        <NodesTopBar
          app={this.props.app}
          user={this.props.user}
          rootNode={rootNode}
          tab={this.props.tab}
          sharedShares={this.props.sharedShares}
          currentNodeAccessLevel={currentNodeAccessLevel}
        />

        {mainContent}
      </div>
    )
    // const t1 = new Date().getTime()
    // console.log('nodes T:', t1 - t0) // eslint-disable-line no-console
    return ret
  }
}

module.exports = Nodes
