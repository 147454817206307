import React from 'react'
import { injectStripe, CardElement } from 'react-stripe-elements'

import PlansHelper from '../../../../../helpers/plans'

class PlansSection extends React.Component {
  state = {
    stripe: null
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    this.toggleCCForm = PlansHelper.CcHandling.toggleCCForm.bind(this, this)
    this.changePlan = PlansHelper.CcHandling.changePlan.bind(this, this)
    this.stripeResponseHandler = PlansHelper.CcHandling.stripeResponseHandler.bind(
      this,
      this
    )
    this.addCC = PlansHelper.CcHandling.addCC.bind(this, this)
  }

  render () {
    var proStuff
    var customer = this.props.app.customer()

    if (this.state.ccFormOpen) {
      var buttonText =
        customer && customer.subscription_status === 'active'
          ? 'Save new card details'
          : 'Upgrade'
      var freqs
      if (!(customer && customer.subscription_status === 'active')) {
        freqs = (
          <div className='freqs-container'>
            <label>
              <input
                type='radio'
                id='cc-monthly'
                name='pay-freq'
                defaultChecked
              />
              <span>$7 / month</span>
            </label>
            <label>
              <input
                ref={yearly => {
                  this.yearly = yearly
                }}
                type='radio'
                id='cc-yearly'
                name='pay-freq'
              />
              <span>$70 / year</span>
            </label>
          </div>
        )
      }

      proStuff = (
        <form
          action=''
          method='POST'
          autoComplete='on'
          id='payment-form'
          onSubmit={this.addCC}
          noValidate
        >
          <h3>Upgrade to Pro</h3>
          <p className='sub-heading'>Please provide a valid credit card.</p>
          <span className='payment-errors error'>{this.state.error}</span>

          <div className='row'>
            <CardElement />
          </div>

          <div className='splitter'>
            <div className='main'>
              {freqs}
              <div className='buttons cc'>
                <button className='button' onClick={this.toggleCCForm}>
                  Cancel
                </button>
                <button className='button primary' type='submit'>
                  {buttonText}
                </button>
              </div>
            </div>
            <div className='reassurance-container'>
              <img src='https://storage.googleapis.com/notebase-files/misc/stripe/dark_solid_big.svg' />
              <div className='reassurance'>
                Payments are handled securely by Stripe, an industry leader in
                payment solutions.
              </div>
            </div>
          </div>
        </form>
      )
    } else {
      if (customer && customer.subscription_status === 'active') {
        proStuff = (
          <div>
            <p>
              Credit card on file: {customer.card_type} ending with{' '}
              {customer.last4} (expires {customer.card_expires_month}/{
                customer.card_expires_year
              }).
            </p>
            <div>
              <button className='button' onClick={this.toggleCCForm}>
                Update payment details
              </button>
              <button className='button' onClick={this.changePlan}>
                Downgrade to Basic plan
              </button>
            </div>
          </div>
        )
      } else {
        proStuff = (
          <div className='pricing-wrapper'>
            <div className='pricing-row'>
              <div className='pricing-col'>
                <div className='row'>
                  <div className='price'>
                    <span className='currency'>$</span>
                    0
                  </div>
                </div>
                <div className='row'>
                  <div className='small-description'>
                    Try for free, and see how<br />awesome it is!
                  </div>
                </div>
                <div className='row'>
                  <div className='type'>Free</div>
                </div>
                <div className='line' />
                <div className='row'>
                  <ul>
                    <li>
                      <div className='title'>List items/month:</div>
                      <div className='sub-title'>100</div>
                    </li>
                    <li>
                      <div className='title'>Shares:</div>
                      <div className='sub-title'>3</div>
                    </li>
                    {/*
                    <li>
                      <div className="title">
                        Bookmarks
                      </div>
                      <div className="sub-title">
                        20
                      </div>
                    </li>
                    */}
                    <li>
                      <div className='title'>Support:</div>
                      <div className='sub-title'>Community</div>
                    </li>
                  </ul>
                </div>
                <div className='row'>
                  <a href='#' className='btn full-width current'>
                    Current plan
                  </a>
                </div>
              </div>
              <div className='pricing-col'>
                <div className='row'>
                  <div className='price'>
                    <span className='currency'>$</span>
                    <span className='small-desc'>/month</span>
                    2
                  </div>
                </div>
                <div className='row'>
                  <div className='small-description'>
                    Get a year of Notebase for<br />$49.90 if billed anually
                  </div>
                </div>
                <div className='row'>
                  <div className='type'>Supporter</div>
                </div>
                <div className='line' />
                <div className='row'>
                  <ul>
                    <li>
                      <div className='title'>List items/month:</div>
                      <div className='sub-title'>Unlimited</div>
                    </li>
                    <li>
                      <div className='title'>Shares:</div>
                      <div className='sub-title'>Unlimited</div>
                    </li>
                    {/*
                    <li>
                      <div className="title">
                        Bookmarks
                      </div>
                      <div className="sub-title">
                        Unlimited
                      </div>
                    </li>
                    */}
                    <li>
                      <div className='title'>Support:</div>
                      <div className='sub-title'>Chat/Email</div>
                    </li>
                  </ul>
                </div>
                <div className='row'>
                  <a
                    href='#'
                    className='btn full-width'
                    onClick={this.toggleCCForm}
                  >
                    Upgrade
                  </a>
                </div>
              </div>
              <div className='pricing-col'>
                <div className='row'>
                  <div className='price'>
                    <span className='currency'>$</span>
                    <span className='small-desc'>/month</span>
                    4.99
                  </div>
                </div>
                <div className='row'>
                  <div className='small-description'>
                    Get a year of Notebase for<br />$49.90 if billed anually
                  </div>
                </div>
                <div className='row'>
                  <div className='type'>Pro</div>
                </div>
                <div className='line' />
                <div className='row'>
                  <ul>
                    <li>
                      <div className='title'>List items/month:</div>
                      <div className='sub-title'>Unlimited</div>
                    </li>
                    <li>
                      <div className='title'>Shares:</div>
                      <div className='sub-title'>Unlimited</div>
                    </li>
                    {/*
                    <li>
                      <div className="title">
                        Bookmarks
                      </div>
                      <div className="sub-title">
                        Unlimited
                      </div>
                    </li>
                    */}
                    <li>
                      <div className='title'>Support:</div>
                      <div className='sub-title'>Chat/Email</div>
                    </li>
                  </ul>
                </div>
                <div className='row'>
                  <a
                    href='#'
                    className='btn full-width'
                    onClick={this.toggleCCForm}
                  >
                    Upgrade
                  </a>
                </div>
              </div>
            </div>
          </div>
        )
      }
    }

    return <div className='form cc'>{proStuff}</div>
  }
}

export default injectStripe(PlansSection)
