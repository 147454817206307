var colors = require('./default_bg_colors')

module.exports = [
  {id: '1', bg: colors[0], text: '#fff', name: ''},
  {id: '2', bg: colors[1], text: '#fff', name: ''},
  {id: '3', bg: colors[2], text: '#fff', name: ''},
  {id: '4', bg: colors[3], text: '#fff', name: ''},
  {id: '5', bg: colors[4], text: '#fff', name: ''},
  {id: '6', bg: colors[5], text: '#fff', name: ''},
  {id: '7', bg: colors[6], text: '#fff', name: ''},
  {id: '8', bg: colors[7], text: '#fff', name: ''},
  {id: '9', bg: colors[8], text: '#fff', name: ''},
  {id: '10', bg: colors[9], text: '#fff', name: ''},
  {id: '11', bg: colors[0], text: '#fff', name: ''},
  {id: '12', bg: colors[1], text: '#fff', name: ''},
  {id: '13', bg: colors[2], text: '#fff', name: ''},
  {id: '14', bg: colors[3], text: '#fff', name: ''},
  {id: '15', bg: colors[4], text: '#fff', name: ''},
  {id: '16', bg: colors[5], text: '#fff', name: ''},
  {id: '17', bg: colors[6], text: '#fff', name: ''},
  {id: '18', bg: colors[7], text: '#fff', name: ''},
  {id: '19', bg: colors[8], text: '#fff', name: ''},
  {id: '20', bg: colors[9], text: '#fff', name: ''},
  {id: '21', bg: colors[0], text: '#fff', name: ''},
  {id: '22', bg: colors[1], text: '#fff', name: ''},
  {id: '23', bg: colors[2], text: '#fff', name: ''},
  {id: '24', bg: colors[3], text: '#fff', name: ''},
  {id: '25', bg: colors[4], text: '#fff', name: ''},
  {id: '26', bg: colors[5], text: '#fff', name: ''},
  {id: '27', bg: colors[6], text: '#fff', name: ''},
  {id: '28', bg: colors[7], text: '#fff', name: ''},
  {id: '29', bg: colors[8], text: '#fff', name: ''},
  {id: '30', bg: colors[9], text: '#fff', name: ''},
  {id: '31', bg: colors[0], text: '#fff', name: ''},
  {id: '32', bg: colors[1], text: '#fff', name: ''},
  {id: '33', bg: colors[2], text: '#fff', name: ''},
  {id: '34', bg: colors[3], text: '#fff', name: ''},
  {id: '35', bg: colors[4], text: '#fff', name: ''},
  {id: '36', bg: colors[5], text: '#fff', name: ''},
  {id: '37', bg: colors[6], text: '#fff', name: ''},
  {id: '38', bg: colors[7], text: '#fff', name: ''},
  {id: '39', bg: colors[8], text: '#fff', name: ''},
  {id: '40', bg: colors[9], text: '#fff', name: ''},
  {id: '41', bg: colors[0], text: '#fff', name: ''},
  {id: '42', bg: colors[1], text: '#fff', name: ''},
  {id: '43', bg: colors[2], text: '#fff', name: ''},
  {id: '44', bg: colors[3], text: '#fff', name: ''},
  {id: '45', bg: colors[4], text: '#fff', name: ''},
  {id: '46', bg: colors[5], text: '#fff', name: ''},
  {id: '47', bg: colors[6], text: '#fff', name: ''},
  {id: '48', bg: colors[7], text: '#fff', name: ''},
  {id: '49', bg: colors[8], text: '#fff', name: ''},
  {id: '50', bg: colors[9], text: '#fff', name: ''},
  {id: '51', bg: colors[0], text: '#fff', name: ''},
  {id: '52', bg: colors[1], text: '#fff', name: ''},
  {id: '53', bg: colors[2], text: '#fff', name: ''},
  {id: '54', bg: colors[3], text: '#fff', name: ''},
  {id: '55', bg: colors[4], text: '#fff', name: ''},
  {id: '56', bg: colors[5], text: '#fff', name: ''},
  {id: '57', bg: colors[6], text: '#fff', name: ''},
  {id: '58', bg: colors[7], text: '#fff', name: ''},
  {id: '59', bg: colors[8], text: '#fff', name: ''},
  {id: '60', bg: colors[9], text: '#fff', name: ''},
  {id: '61', bg: colors[0], text: '#fff', name: ''},
  {id: '62', bg: colors[1], text: '#fff', name: ''},
  {id: '63', bg: colors[2], text: '#fff', name: ''},
  {id: '64', bg: colors[3], text: '#fff', name: ''},
  {id: '65', bg: colors[4], text: '#fff', name: ''},
  {id: '66', bg: colors[5], text: '#fff', name: ''},
  {id: '67', bg: colors[6], text: '#fff', name: ''},
  {id: '68', bg: colors[7], text: '#fff', name: ''},
  {id: '69', bg: colors[8], text: '#fff', name: ''},
  {id: '70', bg: colors[9], text: '#fff', name: ''},
  {id: '71', bg: colors[0], text: '#fff', name: ''},
  {id: '72', bg: colors[1], text: '#fff', name: ''},
  {id: '73', bg: colors[2], text: '#fff', name: ''},
  {id: '74', bg: colors[3], text: '#fff', name: ''},
  {id: '75', bg: colors[4], text: '#fff', name: ''},
  {id: '76', bg: colors[5], text: '#fff', name: ''},
  {id: '77', bg: colors[6], text: '#fff', name: ''},
  {id: '78', bg: colors[7], text: '#fff', name: ''},
  {id: '79', bg: colors[8], text: '#fff', name: ''},
  {id: '80', bg: colors[9], text: '#fff', name: ''},
  {id: '81', bg: colors[0], text: '#fff', name: ''},
  {id: '82', bg: colors[1], text: '#fff', name: ''},
  {id: '83', bg: colors[2], text: '#fff', name: ''},
  {id: '84', bg: colors[3], text: '#fff', name: ''},
  {id: '85', bg: colors[4], text: '#fff', name: ''},
  {id: '86', bg: colors[5], text: '#fff', name: ''},
  {id: '87', bg: colors[6], text: '#fff', name: ''},
  {id: '88', bg: colors[7], text: '#fff', name: ''},
  {id: '89', bg: colors[8], text: '#fff', name: ''},
  {id: '90', bg: colors[9], text: '#fff', name: ''},
  {id: '91', bg: colors[0], text: '#fff', name: ''},
  {id: '92', bg: colors[1], text: '#fff', name: ''},
  {id: '93', bg: colors[2], text: '#fff', name: ''},
  {id: '94', bg: colors[3], text: '#fff', name: ''},
  {id: '95', bg: colors[4], text: '#fff', name: ''},
  {id: '96', bg: colors[5], text: '#fff', name: ''},
  {id: '97', bg: colors[6], text: '#fff', name: ''},
  {id: '98', bg: colors[7], text: '#fff', name: ''},
  {id: '99', bg: colors[8], text: '#fff', name: ''},
  {id: '100', bg: colors[9], text: '#fff', name: ''}
]
