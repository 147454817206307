'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol ? "symbol" : typeof obj; };

exports.setNotice = setNotice;
exports.transformTransforms = transformTransforms;
exports.applyTransforms = applyTransforms;
exports.ajax = ajax;
exports.setSetting = setSetting;
exports.updateSettings = updateSettings;
exports.updateAllSettings = updateAllSettings;
exports.setCustomerSetting = setCustomerSetting;
exports.updateCustomerSettings = updateCustomerSettings;

require('whatwg-fetch');

var _config = require('./config');

var _config2 = _interopRequireDefault(_config);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; } /* globals fetch:false */

function setNotice(component, noticeData, type, callback) {
  if (typeof type === 'function') {
    callback = type;
    type = null;
  }
  var notice = noticeData ? {} : null;
  if (notice) {
    (function () {
      var timeout = void 0;
      if (typeof noticeData === 'string') {
        notice.text = noticeData;
        notice.type = type;
        noticeData = {};
      } else {
        notice.text = noticeData.text;
        notice.type = noticeData.type;
        timeout = noticeData.timeout;
      }
      if (timeout === undefined) {
        timeout = 2000;
      }
      notice.type = notice.type || 'neutral';
      var prefix = '',
          suffix = '';
      switch (notice.type) {
        case 'working':
          prefix = '<i class="fa fa-fw fa-spinner fa-spin"></i>';
          break;
        case 'error':
          prefix = '<i class="fa fa-fw fa-error-outline"></i>';
          suffix = '<i class="fa fa-fw fa-close close-notice"></i>';
          break;
      }
      notice.text = prefix + '<span>' + notice.text + '</span>' + suffix;
      component.setState({ notice: notice, prevNoticeText: notice.text, prevNoticeType: notice.type }, function () {
        if (notice.type !== 'error' && notice.type !== 'working' && timeout !== null) {
          setTimeout(function () {
            component.setState({ notice: null });
          }, timeout);
        }
        if (typeof callback === 'function') {
          callback();
        }
      });
    })();
  } else {
    component.setState({ notice: null });
  }
}

function transformTransforms(currentState, transforms) {
  var ret = Object.assign({}, currentState);
  var newData = void 0,
      ids = void 0,
      trimmedData = void 0,
      ops = void 0;
  Object.keys(transforms).forEach(function (key) {
    ret[key] = ret[key] || [];
    ops = transforms[key];
    newData = ret[key].slice(0);
    ops.forEach(function (op) {
      if (op.op === 'add') {
        newData = newData.concat(op.data);
      } else if (op.op === 'replace') {
        ids = op.data.map(function (d) {
          return d.id;
        });
        trimmedData = newData.filter(function (d) {
          return ids.indexOf(d.id) === -1;
        });
        newData = trimmedData.concat(op.data);
      } else if (op.op === 'replaceAll') {
        newData = op.data;
      } else if (op.op === 'delete' || op.op === 'remove') {
        // 'delete' is the official op name
        newData = newData.filter(function (data) {
          return op.data.indexOf(data.id) === -1;
        });
      }
    });
    ret[key] = newData;
  });
  return ret;
}

function applyTransforms(component, transforms, entitiesKey, otherState, cb, data, response) {
  if (typeof entitiesKey !== 'string') {
    response = data;
    data = cb;
    cb = otherState;
    otherState = entitiesKey;
    entitiesKey = null;
  }
  if (typeof otherState === 'function') {
    response = data;
    data = cb;
    cb = otherState;
    otherState = {};
  }
  otherState = otherState || {};
  var existingData = entitiesKey ? component.state[entitiesKey] : component.state;
  var transformedData = transformTransforms(existingData, transforms);
  var transformedState = entitiesKey ? _defineProperty({}, entitiesKey, transformedData) : transformedData;
  var newState = Object.assign({}, otherState, transformedState);
  component.setState(newState, function () {
    if (typeof cb === 'function') {
      cb(data, response);
    }
  });
}

function ajax(stateComponent, fixedOpts, url, options, onSuccess, onError) {
  if (typeof fixedOpts === 'string') {
    onError = onSuccess;
    onSuccess = options;
    options = url;
    url = fixedOpts;
    fixedOpts = {};
  }
  if (typeof options === 'function') {
    onError = onSuccess;
    onSuccess = options;
    options = {};
  }
  options = options || {};

  fixedOpts = fixedOpts || {};
  fixedOpts.prefix = fixedOpts.prefix || '/_/';

  var realUrl = /^\//.test(url) || /^https?:/.test(url) ? url : fixedOpts.prefix + (fixedOpts.context ? fixedOpts.context + '/' : '') + url;

  function commonSuccessLogic(data, response) {
    if (!options.noNotices && !options.noSuccessNotice) {
      setNotice(stateComponent, {
        type: 'success',
        text: options.successNotice || 'Success!'
      });
    }
    if (typeof onSuccess === 'function') {
      onSuccess(data, response);
    }
  }

  function handleSuccess(data, response, entitiesKey) {
    if (data.error && data.error.fields) {
      if (!options.noNotices && !options.noDataNotice) {
        setNotice(stateComponent, {
          type: 'data',
          text: options.dataNotice || 'Oops, looks like there is a problem'
        });
      }
      stateComponent.setState({ errorFields: data.error.fields });
    } else {
      if (data.transforms) {
        applyTransforms(stateComponent, data.transforms, entitiesKey, options.otherState || {}, commonSuccessLogic, data, response);
      } else {
        commonSuccessLogic(data, response);
      }
    }
  }

  function handleError(err) {
    console.error(err); // eslint-disable-line no-console
    stateComponent.setState({ errorFields: [] });
    if (!options.noNotices && !options.noErrorNotice) {
      setNotice(stateComponent, {
        type: 'error',
        text: options.errorNotice || 'An error occured. Please try again later.'
      });
    }
    if (typeof onError === 'function') {
      onError(err);
    }
  }

  var fetchOpts = {
    method: options.method || 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  };

  if (options.hasOwnProperty('credentials')) {
    if (options.credentials) {
      // Only set the key if the value is truthy
      fetchOpts.credentials = options.credentials;
    }
  } else {
    fetchOpts.credentials = 'include';
  }

  if (true || fixedOpts.product === 'bitbox') {
    // TODO: Remove always-true logic after adding product to Bitbox config
    // XXX: This is Bitbox-specific
    if (stateComponent.state && stateComponent.state.serviceJoinRecordId) {
      var header = stateComponent.state.customerId ? 'X-Levelup-CustomerServiceId' : 'X-Levelup-UserServiceId';
      fetchOpts.headers[header] = stateComponent.state.serviceJoinRecordId;
    }
    // Done with Bitbox-specific stuff
  }

  if (fixedOpts.product === 'notebase') {
    // XXX: This is Notebase-specific
    var token = stateComponent.props.authToken;
    if (!token) {
      if (stateComponent.props.app && stateComponent.props.app.state.authToken) {
        token = stateComponent.props.app.state.authToken;
      } else if (stateComponent.state.authToken) {
        token = stateComponent.state.authToken;
      }
    }
    if (token) {
      fetchOpts.headers['Authorization'] = 'Bearer ' + token;
    }
    var matches = typeof window !== 'undefined' && window.location && window.location.pathname.match(/\/shared\/([^\/]+)/);
    if (matches && matches[1]) {
      fetchOpts.headers['X-Notebase-Secret-ID'] = matches[1];
    }
    // Done with Notebase-specific stuff
  }

  if (options.headers) {
    fetchOpts.headers = Object.assign({}, fetchOpts.headers, options.headers);
  }

  if (options.body) {
    fetchOpts.body = typeof options.body === 'string' ? options.body : JSON.stringify(options.body);
  }

  if (!(stateComponent.state.notice && stateComponent.state.notice.type === 'working') && !options.noNotices && !options.noWorkingNotice) {
    setNotice(stateComponent, {
      type: 'working',
      text: options.workingNotice || 'Working...'
    });
  }

  stateComponent.setState({ fieldErrors: [] }, function () {
    fetch(realUrl, fetchOpts).then(function (response) {
      if (response.ok) {
        return Promise.resolve(response.json());
      } else {
        return Promise.reject(new Error(response.statusText));
      }
    }).then(function (json) {
      handleSuccess(json, null, fixedOpts.entitiesKey);
    }).catch(function (err) {
      handleError(err);
    });
  });
}

function setSetting(component, prefix, key, value, stateCallback, finalCallback) {
  var doSet = true;
  var settings = Object.assign({}, component.state.settings);
  var target = prefix ? settings[prefix] : settings;
  if ((typeof key === 'undefined' ? 'undefined' : _typeof(key)) === 'object') {
    if (prefix) {
      settings[prefix] = Object.assign({}, settings[prefix] || {}, key);
    } else {
      settings = Object.assign({}, settings, key);
    }
    finalCallback = stateCallback;
    stateCallback = value;
  } else {
    if (value !== target[key]) {
      target[key] = value;
    } else {
      doSet = false;
    }
  }

  if (doSet) {
    updateSettings(component, settings, stateCallback, finalCallback);
  } else {
    if (typeof stateCallback === 'function') {
      stateCallback();
    }
    if (typeof finalCallback === 'function') {
      finalCallback();
    }
  }
}

function updateSettings(component, newSettings, stateCallback, finalCallback) {
  component.setState({ settings: newSettings }, function () {
    if (typeof stateCallback === 'function') {
      stateCallback();
    }
    var payload = {
      settings: newSettings
    };
    ajax(component, _config2.default.loginUrl + '_/update_profile', { body: { changes: payload } }, finalCallback);
  });
}

function updateAllSettings(component, args, stateCallback, finalCallback) {
  var newState = args.state || {};
  if (args.service) {
    var joinCollectionName = args.service.type === 'customer' ? 'customerServiceUsers' : 'userServices';
    var joinCollection = component.state[joinCollectionName];
    var joinRecord = joinCollection.find(function (e) {
      return e.id === args.service.id;
    });

    if (joinRecord) {
      var idx = joinCollection.indexOf(joinRecord);

      var currentSettings = joinRecord.settings || {};
      var updatedSettings = Object.assign({}, currentSettings, args.service.settings);

      var updatedJoinRecord = Object.assign({}, joinRecord, { settings: updatedSettings });
      var updatedCollection = joinCollection.slice(0, idx).concat([updatedJoinRecord]).concat(joinCollection.slice(idx + 1));
      newState[joinCollectionName] = updatedCollection;
    }
  }
  if (args.user) {
    if (component.state.users) {
      var user = component.state.users.find(function (u) {
        return u.id === args.user.id;
      });
      if (user) {
        var userIdx = component.state.users.indexOf(user);
        var updatedUserSettings = Object.assign({}, user.settings || {}, args.user.settings);
        var updatedUser = Object.assign({}, user, { settings: updatedUserSettings });
        var updatedUsers = component.state.users.slice(0, userIdx).concat([updatedUser]).concat(component.state.users.slice(userIdx + 1));
        newState.users = updatedUsers;
      }
    }
  }
  if (args.customer) {
    if (component.state.customers) {
      var customer = component.state.customers.find(function (c) {
        return c.id === args.customer.id;
      });
      if (customer) {
        var customerIdx = component.state.customers.indexOf(customer);
        var updatedCustomerSettings = Object.assign({}, customer.settings || {}, args.customer.settings);
        var updatedCustomer = Object.assign({}, customer, { settings: updatedCustomerSettings });
        var updatedCustomers = component.state.customers.slice(0, customerIdx).concat([updatedCustomer]).concat(component.state.customers.slice(customerIdx + 1));
        newState.customers = updatedCustomers;
      }
    }
  }
  if (args.customerService) {
    if (component.state.customers) {
      var customerService = component.state.customerServices.find(function (cs) {
        return cs.id === args.customerService.id;
      });
      if (customerService) {
        var customerServiceIdx = component.state.customerServices.indexOf(customerService);
        var updatedCustomerServiceSettings = Object.assign({}, customerService.settings || {}, args.customerService.settings);
        var updatedCustomerService = Object.assign({}, customerService, { settings: updatedCustomerServiceSettings });
        var updatedCustomerServices = component.state.customerServices.slice(0, customerServiceIdx).concat([updatedCustomerService]).concat(component.state.customerServices.slice(customerServiceIdx + 1));
        newState.customerServices = updatedCustomerServices;
      }
    }
  }

  function doAfterState() {
    if (typeof stateCallback === 'function') {
      stateCallback();
    }
    ajax(component, _config2.default.loginUrl + '_/update_settings', { body: args }, finalCallback);
  }

  if (args.skipState) {
    doAfterState();
  } else {
    component.setState(newState, function () {
      doAfterState();
    });
  }
}

function setCustomerSetting(component, prefix, existingSettings, customerId, key, value, finalCallback) {
  var doSet = true;
  var settings = Object.assign({}, existingSettings);
  var target = prefix ? settings[prefix] : settings;
  if ((typeof key === 'undefined' ? 'undefined' : _typeof(key)) === 'object') {
    if (prefix) {
      settings[prefix] = Object.assign({}, settings[prefix] || {}, key);
    } else {
      settings = Object.assign({}, settings, key);
    }
    finalCallback = value;
  } else {
    if (value !== target[key]) {
      target[key] = value;
    } else {
      doSet = false;
    }
  }

  if (doSet) {
    updateCustomerSettings(component, customerId, settings, finalCallback);
  } else {
    if (typeof finalCallback === 'function') {
      finalCallback();
    }
  }
}

function updateCustomerSettings(component, customerId, newSettings, finalCallback) {
  var payload = {
    settings: newSettings
  };
  ajax(component, _config2.default.loginUrl + '_/update_customer', { body: { customer_id: customerId, changes: payload } }, finalCallback);
}

exports.default = {
  setNotice: setNotice,
  transformTransforms: transformTransforms,
  applyTransforms: applyTransforms,
  ajax: ajax,
  updateAllSettings: updateAllSettings,
  setSetting: setSetting,
  updateSettings: updateSettings,
  setCustomerSetting: setCustomerSetting,
  updateCustomerSettings: updateCustomerSettings
};