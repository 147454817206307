import './styles.css'

import React from 'react'
import classnames from 'classnames'
import $ from 'jquery'

import User from './user'
import Comments from './comments'
import Likes from './likes'
import NodeSelection from './node_selection'
import Inbox from './inbox'
import Summary from './summary'
import Dashboard from './dashboard'
import Menu from './menu'
import Filters from './filters'
import Labels from './labels'
import Background from './background'
import BgScope from './bg_scope'
import Unsplash from './unsplash'
import MarkdownHelp from './markdown_help'

const DEFAULT_SIDEBAR = 'dashboard'

const mappings = {
  user: User,
  nodes: NodeSelection,
  comments: Comments,
  likes: Likes,
  summary: Summary,
  menu: Menu,
  unsplash: Unsplash,
  inbox: Inbox,
  navigator: Navigator,
  dashboard: Dashboard,
  filters: Filters,
  labels: Labels,
  background: Background,
  'bg-scope': BgScope,
  'markdown-help': MarkdownHelp
}

class Sidebar extends React.Component {
  lockScroll = dom => {
    if (dom) {
      var $dom = $(dom)
      $dom.scrollLock()
      var body = $dom.find('.body')
      if (body.length) {
        body.scrollLock()
      }
    }
  }

  componentDidMount () {
    this.lockScroll(this.sidebar)
  }

  componentDidUpdate () {
    this.lockScroll(this.sidebar)
  }

  sidebarComponent = sidebar => {
    const Component = mappings[sidebar[0]]
    return Component ? <Component {...this.props} data={sidebar[1]} /> : false
  }

  render () {
    const { app } = this.props
    let sidebarContent

    const stack = app.state.sidebarStack
    const sidebar = stack[stack.length - 1] || [DEFAULT_SIDEBAR]

    // if (
    //   (app.state.sidebarOpen || sidebar[0] !== DEFAULT_SIDEBAR) &&
    //   !app.state.editNodeId
    // ) {
    if (app.state.sidebarOpen || sidebar[0] !== DEFAULT_SIDEBAR) {
      sidebarContent = this.sidebarComponent(sidebar)
    }

    const sidebarClasses = classnames({
      closed: !sidebarContent
    })

    const sidebarFooter = (
      <div className='sidebar-footer'>
        <div className='sidebar-toggle' onClick={app.toggleSidebar} />
      </div>
    )

    return (
      <div
        ref={sidebar => {
          this.sidebar = sidebar
        }}
        id='sidebar'
        className={sidebarClasses}
      >
        {sidebarContent}
        {sidebarFooter}
      </div>
    )
  }
}

module.exports = Sidebar
