import React from 'react'
import superagent from 'superagent'

import Heading from '../sections/heading'

import Misc from '../../../js/misc'

import './styles.css'

const CLIENT_ID =
  '67f413101af6030c7f767b066b8571d80e5187f2af41d70428bf9cc6eaceef65'
const DEFAULT_COLLECTION = '317099'
const IMAGES_PER_PAGE = 40
const COLLECTIONS_PER_PAGE = 4

const randNum = () => {
  return Math.floor(Math.random() * 100) + 1
}

export default class Unsplash extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      page: randNum(),
      images: [],
      collections: [],
      collectionsPage: randNum(),
      collectionId: null,
      searchPage: 1,
      searchResults: [],
      q: ''
    }
    this.fetchImages(this.state.page)
    this.fetchCollections(this.state.collectionsPage, true)
  }

  handleSearchChange = ev => {
    clearTimeout(this.searchTimeout)
    const q = ev.target.value
    const newState = { q }
    if (q) {
      this.setState(newState, () => {
        this.searchTimeout = setTimeout(() => {
          this.search(q, 1)
        }, 500)
      })
    } else {
      newState.page = randNum()
      newState.searchPage = 1
      newState.totalPhotos = null
      newState.collectionsPage = randNum()
      newState.images = []
      newState.collections = []
      newState.searchResults = []
      this.setState(newState, () => {
        this.fetchImages()
        this.fetchCollections()
      })
    }
  }

  fetchImages = page => {
    page = page || 1
    if (this.state.collectionId) {
      this.getCollectionImages(page)
    } else {
      if (this.state.q) {
        this.search(this.state.q, page, 'images')
      } else {
        superagent
          // .get(`https://api.unsplash.com/photos/curated?page=${page}&IMAGES_PER_PAGE=${IMAGES_PER_PAGE}&client_id=${CLIENT_ID}`)
          // .get(`https://api.unsplash.com/photos/random?featured=1&count=${IMAGES_PER_PAGE}&client_id=${CLIENT_ID}`)
          .get(
            `https://api.unsplash.com/collections/${DEFAULT_COLLECTION}/photos?page=${page}&per_page=${IMAGES_PER_PAGE}&client_id=${CLIENT_ID}`
          )
          .end((err, res) => {
            if (!err && res.ok) {
              this.setState({
                loading: false,
                page: page,
                images: this.state.images.concat(res.body)
              })
            } else {
              console.error('Unsplash error: ' + res.text) // eslint-disable-line no-console
              this.setState({ loading: false, error: true })
            }
          })
      }
    }
  }

  fetchCollections = page => {
    page = page || 1
    if (this.state.q) {
      this.search(this.state.q, page, 'collections')
    } else {
      superagent
        // .get(`https://api.unsplash.com/photos/curated?page=${page}&IMAGES_PER_PAGE=${IMAGES_PER_PAGE}&client_id=${CLIENT_ID}`)
        .get(
          `https://api.unsplash.com/collections/featured?page=${page}&per_page=${COLLECTIONS_PER_PAGE}&client_id=${CLIENT_ID}`
        )
        .end((err, res) => {
          if (!err && res.ok) {
            this.setState({
              loading: false,
              collectionsPage: page,
              collections: res.body
            })
          } else {
            console.error('Unsplash error: ' + res.text) // eslint-disable-line no-console
            this.setState({ loading: false, error: true })
          }
        })
    }
  }

  getCollectionImages = (page, id, totalPhotos) => {
    id = id || this.state.collectionId
    // const key = this.state.q ? 'searchResults' : 'images';
    superagent
      // .get(`https://api.unsplash.com/photos/curated?page=${page}&IMAGES_PER_PAGE=${IMAGES_PER_PAGE}&client_id=${CLIENT_ID}`)
      .get(
        `https://api.unsplash.com/collections/${id}/photos?page=${page}&per_page=${IMAGES_PER_PAGE}&client_id=${CLIENT_ID}`
      )
      .end((err, res) => {
        if (!err && res.ok) {
          const images =
            page === 1 ? res.body : this.state.images.concat(res.body)
          const newState = { loading: false, page, collectionId: id, images }
          if (typeof totalPhotos !== 'undefined') {
            newState.totalPhotos = totalPhotos
          }
          this.setState(newState)
        } else {
          console.error('Unsplash error: ' + res.text) // eslint-disable-line no-console
          this.setState({ loading: false, error: true })
        }
      })
  }

  search = (q, page, type) => {
    const doSearch = () => {
      if (!type || type === 'images') {
        superagent
          .get(
            `https://api.unsplash.com/search/photos?query=${
              this.state.q
            }&page=${page}&per_page=${IMAGES_PER_PAGE}&client_id=${CLIENT_ID}`
          )
          .end((err, res) => {
            if (!err && res.ok) {
              const results = res.body.results || []
              const newResults =
                page > 1 ? this.state.searchResults.concat(results) : results
              this.setState({
                loading: false,
                totalPhotos: res.body.total,
                searchPage: page,
                searchResults: newResults
              })
            } else {
              console.error('Unsplash error: ' + res.text) // eslint-disable-line no-console
              this.setState({ loading: false, error: true })
            }
          })
      }
      if (!type || type === 'collections') {
        superagent
          .get(
            `https://api.unsplash.com/search/collections?query=${
              this.state.q
            }&page=${page}&per_page=${COLLECTIONS_PER_PAGE}&client_id=${CLIENT_ID}`
          )
          .end((err, res) => {
            if (!err && res.ok) {
              this.setState({
                loading: false,
                collectionId: null,
                collectionsPage: page,
                collections: res.body.results || []
              })
            } else {
              console.error('Unsplash error: ' + res.text) // eslint-disable-line no-console
              this.setState({ loading: false, error: true })
            }
          })
      }
    }
    if (page === 1) {
      this.setState({ loading: true }, () => {
        doSearch()
      })
    } else {
      doSearch()
    }
  }

  pickBackground = image => {
    const { app, data } = this.props
    const { scope } = data
    Misc.setBackgroundImage(app, scope, image.urls.full, image.color)
  }

  render () {
    const images =
      this.state.q && !this.state.collectionId
        ? this.state.searchResults
        : this.state.images
    const collections = this.state.collections
    return (
      <div className='body full-height unsplash'>
        <div className='panel'>
          <header>
            <Heading {...this.props} isMain>
              <h4>
                Backgrounds by{' '}
                <a
                  href='https://unsplash.com/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Unsplash
                </a>
              </h4>
            </Heading>
            <div className='search-field'>
              <input
                type='text'
                placeholder='Search Backgrounds and Collections...'
                onChange={this.handleSearchChange}
                value={this.state.q}
              />
            </div>
          </header>
          {this.state.loading && <div className='loading'>Loading...</div>}
          {!this.state.loading && (
            <div className='grid-container'>
              <div className='grid-inner'>
                <h4>Collections</h4>
                <div className='grid collections'>
                  {Boolean(collections.length) &&
                    collections.map((e, i) => {
                      return (
                        <div
                          key={i}
                          onClick={() =>
                            this.getCollectionImages(1, e.id, e.total_photos)
                          }
                        >
                          <div className='stack'>
                            <div
                              style={{ backgroundColor: e.cover_photo.color }}
                            />
                            <div
                              style={{ backgroundColor: e.cover_photo.color }}
                            />
                          </div>
                          <div
                            className='cover'
                            style={{
                              backgroundImage: `url(${
                                e.cover_photo.urls.thumb
                              })`,
                              backgroundColor: e.cover_photo.color
                            }}
                          >
                            <div className='name'>
                              {Misc.truncate(e.title, 30)}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  {Boolean(!collections.length) && (
                    <div>
                      No collections found - please try again a little later.
                    </div>
                  )}
                </div>
                {Boolean(collections.length) && (
                  <div className='get-more'>
                    <button
                      onClick={() =>
                        this.fetchCollections(this.state.collectionsPage - 1)
                      }
                      disabled={this.state.collectionsPage === 1}
                    >
                      &lt; Previous Page
                    </button>
                    <button
                      onClick={() =>
                        this.fetchCollections(this.state.collectionsPage + 1)
                      }
                    >
                      Next Page &gt;
                    </button>
                  </div>
                )}
                <h4 className='images'>Backgrounds</h4>
                <div className='grid'>
                  {Boolean(images.length) &&
                    images.map((e, i) => {
                      return (
                        <div
                          key={i}
                          onClick={() => this.pickBackground(e)}
                          style={{
                            backgroundImage: `url(${e.urls.thumb})`,
                            backgroundColor: e.color
                          }}
                        >
                          <div className='credits'>
                            <a
                              href={e.user.links.html}
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              {e.user.first_name} {e.user.last_name}
                            </a>
                          </div>
                        </div>
                      )
                    })}
                  {Boolean(!images.length) && (
                    <div>
                      No backgrounds found - please try again a little later.
                    </div>
                  )}
                </div>
                {Boolean(
                  !this.state.loading &&
                    (!this.state.collectionId ||
                      this.state.totalPhotos >= images.length)
                ) && (
                  <div className='get-more single'>
                    <button
                      onClick={() =>
                        this.fetchImages(
                          (this.state.q
                            ? this.state.searchPage
                            : this.state.page) + 1
                        )
                      }
                    >
                      Load More
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}
