import React from 'react'

import onClickOutside from 'react-onclickoutside'

import Avatar from '../avatar'

var AssignPicker = onClickOutside(
  class extends React.Component {
    static displayName = 'AssignPicker'
    state = {}

    handleClickOutside = ev => {
      if (typeof this.props.onClickOutside === 'function') {
        this.props.onClickOutside(ev)
      } else {
        this.props.hideCalendar()
      }
    }

    handleUserClick = (user, ev) => {
      this.props.onSelect(user)
      ev.preventDefault()
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps (nextProps) {
      if (nextProps.selected !== this.props.selected) {
        this.setState({})
      }
    }

    render () {
      var matchingUsers = this.props.app.usersForNode(this.props.node)
      if (this.props.filter) {
        var filterRe = new RegExp(this.props.filter, 'ig')
        matchingUsers = this.props.app.state.entities.users.filter(u => {
          return (
            filterRe.test(u.first_name) ||
            filterRe.test(u.last_name) ||
            filterRe.test(u.username)
          )
        })
      }
      var i = 0
      var users = matchingUsers.map(user => {
        var classes = ''
        if (
          this.props.selected === user.id ||
          (!this.props.selected && i === 0)
        ) {
          classes = 'active'
        }
        i++
        return (
          <div
            className={classes}
            key={'assign-picker-' + user.id}
            data-user-id={user.id}
            onClick={() => this.handleUserClick(user)}
          >
            <Avatar app={this.props.app} user={user} />
            <span>{user.username}</span>
          </div>
        )
        // return (
        //   <div className={classes} key={'assign-picker-' + user.id} data-user-id={user.id} onClick={() => _this.handleUserClick( user)}>
        //     <img src={'https:' + user.gravatar} />
        //     <span>{user.username}</span>
        //   </div>
        // );
      })
      return <div className='assign-picker'>{users}</div>
    }
  }
)

module.exports = AssignPicker
