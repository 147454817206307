import React from 'react'
import createReactClass from 'create-react-class'
import $ from 'jquery'
import _ from 'lodash'

// import Combobox from 'react-widgets/lib/Combobox'

import Misc from '../../../js/misc'
import Org from '../../../js/org'
import User from '../../../js/user'

import constants from '../../..//lib/constants'

const OrgsOverlay = createReactClass({
  displayName: 'OrgsOverlay',

  getInitialState: function () {
    var org = this.getCurrentOrg() || {}
    var creatingOrNot =
      this.props.panel &&
      this.props.panel.opts.data &&
      this.props.panel.opts.data.mode &&
      this.props.panel.opts.data.mode === 'new'
    var state = {
      message: '',
      error: null,
      creatingOrg: creatingOrNot,
      creatingGroup: false,
      addingUserToGroup: false,
      invalidFields: [],
      selectedUserId: null,
      selectedInviteId: null,
      selectedRequestId: null,
      selectedGroupId: null,
      selectedGroupUserId: null,
      inviteStr: '',
      orgId: org.id,
      newOrg: {},
      newGroup: {}
    }
    return state
  },

  UNSAFE_componentWillMount: function () {
    this.handleFormFieldChange = Misc.handleFormFieldChange.bind(this, this)
    this.errorFor = Misc.errorFor.bind(this, this)
  },

  selectOrg: function (orgId, tabId, ev) {
    this.setState(
      {
        error: null,
        creatingOrg: false,
        creatingGroup: false,
        addingUserToGroup: false,
        selectedUserId: null,
        selectedInviteId: null,
        selectedRequestId: null,
        selectedGroupId: null,
        selectedGroupUserId: null,
        orgId: orgId
      },
      function () {
        this.props.selectVtab(orgId, tabId)()
      }
    )
    ev && ev.preventDefault()
  },

  selectUser: function (userId, ev) {
    this.setState({
      error: null,
      selectedInviteId: null,
      selectedRequestId: null,
      selectedUserId: userId,
      addingUserToGroup: false
    })
    ev && ev.preventDefault()
  },

  selectInvite: function (inviteId, ev) {
    this.setState({
      error: null,
      selectedUserId: null,
      selectedRequestId: null,
      selectedInviteId: inviteId,
      addingUserToGroup: false
    })
    ev && ev.preventDefault()
  },

  selectRequest: function (requestId, ev) {
    this.setState({
      error: null,
      selectedUserId: null,
      selectedInviteId: null,
      selectedRequestId: requestId,
      addingUserToGroup: false
    })
    ev && ev.preventDefault()
  },

  selectGroup: function (groupId, ev) {
    this.setState({
      error: null,
      creatingOrg: false,
      creatingGroup: false,
      addingUserToGroup: false,
      selectedGroupUserId: null,
      selectedGroupId: groupId
    })
    ev && ev.preventDefault()
  },

  selectGroupUser: function (userId, ev) {
    this.setState({
      error: null,
      creatingOrg: false,
      creatingGroup: false,
      addingUserToGroup: false,
      selectedGroupUserId: userId
    })
    ev && ev.preventDefault()
  },

  toggleCreateOrg: function (ev) {
    this.setState({ creatingOrg: !this.state.creatingOrg })
    ev.preventDefault()
  },

  toggleCreateGroup: function (ev) {
    this.setState({ creatingGroup: !this.state.creatingGroup })
    ev.preventDefault()
  },

  toggleAddUserToGroup: function (ev) {
    this.setState({
      userToAddToGroup: null,
      addingUserToGroup: !this.state.addingUserToGroup
    })
    ev.preventDefault()
  },

  getCurrentOrg: function () {
    var _this = this
    var org
    if (
      this.props.activeVtabId &&
      this.props.app.state.entities.orgs &&
      this.props.app.state.entities.orgs.length
    ) {
      org = Org.get(
        _this.props.activeVtabId,
        this.props.app.state.entities.orgs
      )
    }
    return org
  },

  registerOrg: function () {
    var _this = this
    Misc.jx(
      this,
      'register_org',
      this.state.newOrg,
      function (res) {
        // TODO: Show global success message
        this.props.app.setState(
          this.props.app.transformSyncData(res.body.syncData),
          function () {
            _this.selectOrg(res.body.id, 'users')
          }
        )
      }.bind(this)
    )
  },

  registerInvite: function () {
    var _this = this
    var org = this.getCurrentOrg()
    if (org) {
      var data = {
        str: this.state.inviteStr,
        org_id: org.id
      }
      Misc.jx(
        this,
        'register_org_invite',
        data,
        function (res) {
          // TODO: Show global success message
          this.props.app.applyChanges(res.body.syncData, function () {
            _this.setState({ inviteStr: '' }, function () {
              _this.selectInvite(res.body.id)
            })
          })
          // var newOrg = {...org};
          // newOrg.invites = org.invites.concat([res.body]);
          // _this.updateOrgs(newOrg, function() {
          //   _this.setState({inviteStr: ''}, function() {
          //     _this.selectInvite(res.body);
          //   });
          // });
        }.bind(this)
      )
    }
  },

  registerGroup: function (ev) {
    var _this = this
    var org = this.getCurrentOrg()
    if (org) {
      var newGroup = { ...this.state.newGroup }
      newGroup.org_id = org.id
      Misc.jx(this, 'register_org_group', newGroup, function (res) {
        // TODO: Show global success message
        var newOrg = { ...org }
        newOrg.groupIds = org.groupIds.concat([res.body.id])
        _this.updateOrgs(newOrg, function () {
          _this.selectGroup(res.body)
        })
      })
    }
    ev && ev.preventDefault()
  },

  updateOrgs: function (updatedOrg, callback) {
    var newOrgs = []
    this.props.app.state.entities.orgs.forEach(function (org) {
      if (org.id === updatedOrg.id) {
        newOrgs.push(updatedOrg)
      } else {
        newOrgs.push(org)
      }
    })
    var newState = { orgs: newOrgs }
    this.props.app.setAppState(newState, callback)
  },

  removeUser: function (userOrEv, ev) {
    var _this = this
    var user = userOrEv.preventDefault ? this.state.selectedUser : userOrEv
    ev = userOrEv.preventDefault ? userOrEv : ev

    var org = this.getCurrentOrg()
    if (!org) return

    // TODO: Add sanity checks (don't delete last user, at least)
    var data = {
      user_id: user.id,
      org_id: org.id
    }
    Misc.jx(
      this,
      'remove_user_from_org',
      data,
      function (res) {
        // eslint-disable-line no-unused-vars
        // TODO: Show global success message
        var trimmedUsers = org.users.filter(u => u.id !== user.id)
        org.users = trimmedUsers
        var trimmedOrgs = this.props.app.state.entities.orgs.filter(
          o => o.id !== org.id
        )
        this.props.app.setState(
          { orgs: trimmedOrgs.concat([org]) },
          function () {
            _this.selectUser()
          }
        )
      }.bind(this)
    )
    ev.preventDefault()
  },

  resendInvite: function (inviteOrEv, ev) {
    var invite = inviteOrEv.preventDefault
      ? this.state.selectedInvite
      : inviteOrEv
    ev = inviteOrEv.preventDefault ? inviteOrEv : ev

    var org = this.getCurrentOrg()
    if (!org) return

    var data = {
      invite_id: invite.id
    }
    Misc.jx(
      this,
      'resend_org_invite',
      data,
      function (res) {
        // eslint-disable-line no-unused-vars
        // TODO: Show global success message
        var trimmedInvites = org.invites.filter(i => i.id !== invite.id)
        org.invites = trimmedInvites.concat([res.body])
        var trimmedOrgs = this.props.app.state.entities.orgs.filter(
          o => o.id !== org.id
        )
        this.props.app.setState({ orgs: trimmedOrgs.concat([org]) })
      }.bind(this)
    )

    ev.preventDefault()
  },

  cancelInvite: function (inviteOrEv, ev) {
    var _this = this
    var invite = inviteOrEv.preventDefault
      ? this.state.selectedInvite
      : inviteOrEv
    ev = inviteOrEv.preventDefault ? inviteOrEv : ev

    var org = this.getCurrentOrg()
    if (!org) return

    var data = {
      invite_id: invite.id
    }
    Misc.jx(
      this,
      'cancel_org_invite',
      data,
      function (res) {
        // eslint-disable-line no-unused-vars
        // TODO: Show global success message
        org.invites = org.invites.filter(i => i.id !== invite.id)
        var trimmedOrgs = this.props.app.state.entities.orgs.filter(
          o => o.id !== org.id
        )
        this.props.app.setState(
          { orgs: trimmedOrgs.concat([org]) },
          function () {
            _this.selectInvite()
          }
        )
      }.bind(this)
    )

    ev.preventDefault()
  },

  acceptRequest: function (requestOrEv, ev) {
    var _this = this
    var request = requestOrEv.preventDefault
      ? this.state.selectedRequest
      : requestOrEv
    ev = requestOrEv.preventDefault ? requestOrEv : ev

    var org = this.getCurrentOrg()
    if (!org) return

    var data = {
      request_id: request.id
    }
    Misc.jx(
      this,
      'accept_org_request',
      data,
      function (res) {
        // eslint-disable-line no-unused-vars
        // TODO: Show global success message
        var trimmedRequests = org.requests.filter(r => r.id !== request.id)
        org.requests = trimmedRequests
        org.users = org.users.concat([res.body.user])
        var trimmedOrgs = this.props.app.state.entities.entities.orgs.filter(
          o => o.id !== org.id
        )
        this.props.app.setState(
          { orgs: trimmedOrgs.concat([org]) },
          function () {
            _this.selectUser(res.body.user)
          }
        )
      }.bind(this)
    )

    ev.preventDefault()
  },

  denyRequest: function (requestOrEv, ev) {
    var request = requestOrEv.preventDefault
      ? this.state.selectedRequest
      : requestOrEv
    ev = requestOrEv.preventDefault ? requestOrEv : ev

    var org = this.getCurrentOrg()
    if (!org) return

    var data = {
      request_id: request.id
    }
    Misc.jx(
      this,
      'deny_org_request',
      data,
      function (res) {
        // eslint-disable-line no-unused-vars
        // TODO: Show global success message
        var trimmedRequests = org.requests.filter(r => r.id !== request.id)
        org.requests = trimmedRequests
        var trimmedOrgs = this.props.app.state.entities.orgs.filter(
          o => o.id !== org.id
        )
        this.props.app.setState({ orgs: trimmedOrgs.concat([org]) })
      }.bind(this)
    )

    ev.preventDefault()
  },

  addUserToGroup: function (userOrEv, ev) {
    var _this = this
    var user = userOrEv
      ? userOrEv.preventDefault
        ? this.state.userToAddToGroup || this.state.selectedGroupUser
        : null
      : null
    ev = userOrEv && userOrEv.preventDefault ? userOrEv : ev

    var org = this.getCurrentOrg()
    var group = this.state.selectedGroup
    if (!(org && group && user)) return

    // TODO: Add sanity checks (don't delete last user, at least)
    var data = {
      user_id: user.id,
      group_id: group.id
    }
    Misc.jx(
      this,
      'add_user_to_group',
      data,
      function (res) {
        // eslint-disable-line no-unused-vars
        // TODO: Show global success message
        var newGroup = { ...group }
        newGroup.users = group.users.concat([user])
        var trimmedGroups = org.groups.filter(g => g.id !== group.id)
        org.groups = trimmedGroups.concat([newGroup])
        var trimmedOrgs = this.props.app.state.entities.orgs.filter(
          o => o.id !== org.id
        )
        this.props.app.setState(
          { orgs: trimmedOrgs.concat([org]) },
          function () {
            _this.setState({ selectedGroup: newGroup }, function () {
              _this.selectGroupUser()
            })
          }
        )
      }.bind(this)
    )
    ev && ev.preventDefault && ev.preventDefault()
  },

  removeUserFromGroup: function (userOrEv, ev) {
    var _this = this
    var user = userOrEv.preventDefault ? this.state.selectedGroupUser : userOrEv
    ev = userOrEv.preventDefault ? userOrEv : ev

    var org = this.getCurrentOrg()
    var group = this.state.selectedGroup
    if (!(org && group && user)) return

    // TODO: Add sanity checks (don't delete last user, at least)
    var data = {
      user_id: user.id,
      group_id: group.id
    }
    Misc.jx(
      this,
      'remove_user_from_group',
      data,
      function (res) {
        // eslint-disable-line no-unused-vars
        // TODO: Show global success message
        var newGroup = { ...group }
        var trimmedUsers = group.users.filter(u => u.id !== user.id)
        newGroup.users = trimmedUsers
        var trimmedGroups = org.groups.filter(g => g.id !== group.id)
        org.groups = trimmedGroups.concat([newGroup])
        var trimmedOrgs = this.props.app.state.entities.orgs.filter(
          o => o.id !== org.id
        )
        this.props.app.setState(
          { orgs: trimmedOrgs.concat([org]) },
          function () {
            _this.setState({ selectedGroup: newGroup }, function () {
              _this.selectGroupUser()
            })
          }
        )
      }.bind(this)
    )
    ev.preventDefault()
  },

  selectLogo: function (ev) {
    var _this = this
    this.props.app.browseFiles({ fileType: 'image/*' }, function (file) {
      var fileUrl = Misc.fileUrl(file)
      var org = _this.getCurrentOrg()
      if (org) {
        var newSettings = { ...org.settings }
        newSettings.logo = fileUrl
        var data = { id: org.id, updates: { settings: newSettings } }
        Misc.jx(_this, 'update_org', data, function (res) {
          // eslint-disable-line no-unused-vars
          org.settings = newSettings
          _this.updateOrgs(org)
        })
      }
    })
    ev.preventDefault()
  },

  clearLogo: function (ev) {
    var _this = this
    var org = _this.getCurrentOrg()
    if (org) {
      var newSettings = { ...org.settings }
      newSettings.logo = null
      var data = { id: org.id, updates: { settings: newSettings } }
      Misc.jx(_this, 'update_org', data, function (res) {
        // eslint-disable-line no-unused-vars
        org.settings = newSettings
        _this.updateOrgs(org)
      })
    }
    ev.preventDefault()
  },

  saveProfile: function () {
    var _this = this

    var org = this.getCurrentOrg()
    if (org) {
      org.name = this.state.org.name
      org.identifier = this.state.org.identifier
      var data = {
        id: org.id,
        updates: {
          name: this.state.org.name,
          identifier: this.state.org.identifier
        }
      }
      Misc.jx(_this, 'update_org', data, function (res) {
        // eslint-disable-line no-unused-vars
        _this.updateOrgs(org)
      })
    }
  },

  toggleChangePassword: function (ev) {
    this.setState({ changePassword: !this.state.changePassword })
    ev.preventDefault()
  },

  toggleCCForm: function (ev) {
    this.setState(
      { invalidFields: [], error: null, ccFormOpen: !this.state.ccFormOpen },
      function () {
        if (this.state.ccFormOpen) {
          $('#cc-number').payment('formatCardNumber')
          $('#cc-cvc').payment('formatCardCVC')
          $('#cc-exp').payment('formatCardExpiry')
        }
      }
    )
    ev.preventDefault()
  },

  changePassword: function (ev) {
    var _this = this
    var oldPwd = this.oldPassword.value
    var newPwd = this.newPassword.value
    var confirmation = this.confirmNewPassword.value
    if (newPwd === confirmation) {
      var data = { old: oldPwd, new: newPwd }
      this.setState({ error: null })
      Misc.jx(this, 'change_password', data, function (res) {
        // eslint-disable-line no-unused-vars
        // TODO: Show global success message
        _this.setState({ changePassword: false })
      })
    } else {
      this.setState({ error: 'New password and confirmation do not match.' })
    }

    ev.preventDefault()
  },

  deleteOrg: function (ev) {
    var org = this.getCurrentOrg()
    if (org) {
      this.props.app.getConfirmation(
        () => {
          Misc.jx(this, 'delete_org', { org_id: org.id }, res => {
            // eslint-disable-line no-unused-vars
            this.props.app.setOrg(null, () => {
              var newOrgs = this.props.app.state.entities.orgs.filter(
                o => o.id !== org.id
              )
              var newOrg =
                newOrgs.length && newOrgs.length > 1 ? newOrgs[0] : null
              // XXX: Possible timing issue here
              if (newOrg) {
                var isOwner = Org.isOwner(
                  newOrg,
                  this.props.user,
                  this.props.app.getCommonParams()
                )
                var newTabId = isOwner ? 'users' : 'groups'
                this.selectOrg(newOrg.id, newTabId)
              }
              this.props.app.setAppState({
                orgs: newOrgs
              })
            })
          })
        },
        null,
        'Are you absolutely sure that you want to permanently delete this organization and all its groups and group memberships? This action cannot be undone.'
      )
    }
    ev.preventDefault()
  },

  handleUserToAddChange: function (value) {
    // console.log(value);
    this.setState({ userToAddToGroup: value })
  },

  handleInviteFieldChange: function (ev) {
    this.setState({ inviteStr: ev.target.value })
  },

  handleInviteFieldKeyDown: function (ev) {
    if (ev.key === 'Enter') {
      if (/^[a-zA-Z0-9.-_@+]+$/.test(this.state.inviteStr)) {
        this.registerInvite()
      }
      ev.preventDefault()
    }
  },

  handleAllowRequestsChange: function () {
    var _this = this
    var org = this.getCurrentOrg()
    if (org) {
      var data = {
        id: org.id,
        updates: {
          settings: { disable_requests: !org.settings.disable_requests }
        }
      }
      Misc.jx(_this, 'update_org', data, function (res) {
        _this.updateOrgs(res.body)
      })
    }
  },

  handleDefaultAccessChange: function (ev) {
    var _this = this
    var org = this.getCurrentOrg()
    if (org) {
      var data = {
        id: org.id,
        updates: { default_access_level: ev.target.value }
      }
      Misc.jx(_this, 'update_org', data, function (res) {
        _this.updateOrgs(res.body)
      })
    }
  },

  stripeResponseHandler: function (status, response) {
    var _this = this
    var $form = $('#payment-form')

    if (response.error) {
      // Show the errors on the form
      $form.find('.payment-errors').text(response.error.message)
      $form.find('button').prop('disabled', false)
    } else {
      // response contains id and card, which contains additional card details
      var org = this.getCurrentOrg()
      var data = {
        org_id: org.id,
        response: response
      }
      Misc.jx(
        this,
        'cc',
        data,
        function (res) {
          // success
          $form.find('button').prop('disabled', false)
          if (res.body.error) {
            _this.setState({ error: res.body.error.message })
          } else {
            _this.setState({ ccFormOpen: false })
            _this.props.app.setAppState(
              _this.props.app.transformSyncData(res.body.syncData)
            )
          }
        },
        function (res) {
          // error
          $form.find('button').prop('disabled', false)
          _this.setState({ error: res.text })
          console.log(
            'An error occurred while communicating with the server: ' + res.text
          ) // eslint-disable-line no-console
        }
      )
    }
  },

  validateCard: function (callback) {
    var invalidFields = []
    var exp = $('#cc-exp').payment('cardExpiryVal')
    if (!$.payment.validateCardNumber($('#cc-number').val())) {
      invalidFields.push('number')
    }
    if (!$.payment.validateCardCVC($('#cc-cvc').val())) {
      invalidFields.push('cvc')
    }
    if (!$.payment.validateCardExpiry(exp.month, exp.year)) {
      invalidFields.push('expiry')
    }
    this.setState({ error: null, invalidFields: invalidFields }, callback)
  },

  addCC: function (ev) {
    this.validateCard(function () {
      if (!this.state.invalidFields.length) {
        $('#payment-form')
          .find('button')
          .prop('disabled', true)
        var exp = $('#cc-exp').payment('cardExpiryVal')
        // eslint-disable-next-line no-undef
        Stripe.card.createToken(
          {
            // eslint-disable-line no-undef
            number: $('#cc-number').val(),
            cvc: $('#cc-cvc').val(),
            exp_month: exp.month,
            exp_year: exp.year
          },
          this.stripeResponseHandler
        )
      }
    })
    ev.preventDefault()
  },

  downgrade: function (ev) {
    var _this = this
    var org = this.getCurrentOrg()
    this.props.app.getConfirmation(function () {
      Misc.jx(_this, 'downgrade', { org_id: org.id }, function (res) {
        // eslint-disable-line no-unused-vars
        _this.setState({ ccFormOpen: false })
        _this.props.app.setAppState(
          _this.props.app.transformSyncData(res.body.syncData)
        )
        // _this.props.app.syncClientData();
      })
    })
    ev.preventDefault()
  },

  newOrgForm: function () {
    return (
      <div>
        <h4>Create new organization</h4>
        <div className='row'>
          <label>
            <span>Name</span>
            <input
              type='text'
              placeholder='Name - Apple, Samsung, EA Sports'
              onChange={this.handleFormFieldChange}
              data-name='name'
              data-obj='newOrg'
            />
          </label>
          {this.errorFor('name')}
        </div>
        <div className='row'>
          <label>
            <span>Identifier</span>
            <input
              type='text'
              placeholder='apple, samsumg, easports'
              onChange={this.handleFormFieldChange}
              data-name='identifier'
              data-obj='newOrg'
            />
          </label>
          {this.errorFor('identifier')}
          <p>
            <small className='muted'>
              This is like a username for your organization. Valid characters:
              a-z, 0-9, dash, underscore.
            </small>
          </p>
        </div>
        <div className='buttons'>
          <button className='button primary' onClick={this.registerOrg}>
            Create
          </button>
          <button className='button' onClick={this.toggleCreateOrg}>
            Cancel
          </button>
        </div>
      </div>
    )
  },

  newGroupForm: function () {
    return (
      <div>
        <h4>Create new group</h4>
        <div className='row'>
          <label>
            <span>Name</span>
            <input
              type='text'
              placeholder='Admins, Developers, Public Relations'
              onChange={this.handleFormFieldChange}
              data-name='name'
              data-obj='newGroup'
            />
          </label>
          {this.errorFor('name')}
        </div>
        <div className='row'>
          <label>
            <span>Identifier</span>
            <input
              type='text'
              placeholder='admins, developers, pr'
              onChange={this.handleFormFieldChange}
              data-name='identifier'
              data-obj='newGroup'
            />
          </label>
          {this.errorFor('identifier')}
          <p>
            <small className='muted'>
              This is like a username for the group. It needs to be unique
              within an organization. Valid characters: a-z, 0-9, dash,
              underscore.
            </small>
          </p>
        </div>
        <div className='buttons'>
          <button className='button primary' onClick={this.registerGroup}>
            Create
          </button>
          <button className='button' onClick={this.toggleCreateGroup}>
            Cancel
          </button>
        </div>
      </div>
    )
  },

  render: function () {
    var _this = this

    var content
    var orgs = this.props.app.state.entities.orgs
    if (orgs && orgs.length) {
      var ownersGroup
      var org = this.getCurrentOrg()
      if (!org) {
        org = orgs[0]
      }
      var isOwner = Org.isOwner(
        org,
        _this.props.user,
        _this.props.app.getCommonParams()
      )

      var proStuff
      var customer = Org.customer(
        this.getCurrentOrg(),
        this.props.app.getCommonParams()
      )
      if (this.state.ccFormOpen) {
        var expPlaceholder = '02 / ' + (new Date().getFullYear() + 1)
        var buttonText =
          customer && customer.subscription_status === 'active'
            ? 'Save new card details'
            : 'Add payment method'
        var numberError, cvcError, expiryError
        if (this.state.invalidFields.indexOf('number') !== -1) {
          numberError = <div className='error'>Invalid credit card number.</div>
        }

        if (this.state.invalidFields.indexOf('cvc') !== -1) {
          cvcError = <div className='error'>Invalid CVC.</div>
        }

        if (this.state.invalidFields.indexOf('expiry') !== -1) {
          expiryError = <div className='error'>Invalid expiry date.</div>
        }

        var freqRow
        if (!(customer && customer.subscription_status === 'active')) {
          freqRow = (
            <div className='row'>
              <label>
                <input
                  type='radio'
                  id='cc-monthly'
                  name='pay-freq'
                  defaultChecked
                />
                <span>$9 / user / month</span>
              </label>
              &nbsp; &nbsp; &nbsp;
              <label>
                <input type='radio' id='cc-yearly' name='pay-freq' />
                <span>$90 / user / year</span>
              </label>
            </div>
          )
        }

        proStuff = (
          <form
            action=''
            method='POST'
            autoComplete='on'
            id='payment-form'
            onSubmit={this.addCC}
            noValidate
          >
            <span className='payment-errors error'>{this.state.error}</span>

            <div className='row'>
              <label>
                <span>Card Number</span>
                <input
                  type='text'
                  size='20'
                  placeholder='1234 1234 1234 1234'
                  id='cc-number'
                  pattern='\d*'
                  autoComplete='cc-number'
                  required
                />
              </label>
              {numberError}
            </div>

            <div className='row'>
              <label>
                <span>CVC</span>
                <input
                  type='text'
                  size='4'
                  autoComplete='off'
                  placeholder='123'
                  id='cc-cvc'
                />
              </label>
              {cvcError}
            </div>

            <div className='row'>
              <label>
                <span>Expiration (MM/YYYY)</span>
                <input
                  type='text'
                  size='10'
                  placeholder={expPlaceholder}
                  id='cc-exp'
                  pattern='\d*'
                  autoComplete='cc-exp'
                  required
                />
              </label>
              {expiryError}
            </div>

            {freqRow}

            <div className='buttons cc'>
              <img src='/static/stripe/dark_outline.png' />
              <div className='reassurance'>
                We never see your credit card details, that’s all handled
                securely by Stripe, an industry leader in payment solutions.
              </div>
              <button className='button primary' type='submit'>
                {buttonText}
              </button>
              &nbsp; &nbsp; &nbsp;
              <button className='button' onClick={this.toggleCCForm}>
                Cancel
              </button>
            </div>
          </form>
        )
      } else {
        if (customer && customer.subscription_status === 'active') {
          proStuff = (
            <div>
              <p>
                Credit card on file: {customer.card_type} ending with{' '}
                {customer.last4} (expires {customer.card_expires_month}/{
                  customer.card_expires_year
                }).
              </p>
              <div>
                <button className='button' onClick={this.toggleCCForm}>
                  Update payment details
                </button>
                &nbsp; &nbsp; &nbsp;
                <button className='button' onClick={this.downgrade}>
                  Remove payment method
                </button>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                <a href='/payments' target='_blank'>
                  Payment history
                </a>
              </div>
            </div>
          )
        } else {
          proStuff = (
            <div>
              <div className='static-content'>
                <p>
                  You have not added a payment method for this organization yet.
                  When you add a payment method, you’ll be able to:
                </p>
                <ul>
                  <li>
                    <strong>Add users to your org</strong>
                  </li>
                  <li>
                    <strong>Set custom permissions for subtrees</strong>
                  </li>
                  <li>
                    <strong>Assign items to users in your org</strong>
                  </li>
                  <li>
                    <strong>Share files</strong>
                  </li>
                </ul>
                <p>Plus, you get:</p>
                <ul>
                  <li>
                    <strong>Unlimited everything</strong>{' '}
                    <span className='muted'>
                      - list items, documents, sites, shares
                    </span>
                  </li>
                  <li>
                    <strong>Priority support</strong>
                  </li>
                </ul>
              </div>
              <p className='center'>
                Org pricing is simple: <strong>$9 / user / month</strong> or{' '}
                <strong>$90 / user / year</strong>.
              </p>
              <p className='center'>
                <button className='button' onClick={this.toggleCCForm}>
                  Add payment method now
                </button>
              </p>
              {customer && (
                <p className='center'>
                  <a href='/payments' target='_blank'>
                    Payment history
                  </a>
                </p>
              )}
            </div>
          )
        }
      }

      var logo
      if (org.settings.logo) {
        logo = (
          <div>
            <div className='logo-preview'>
              <img src={org.settings.logo} />
            </div>
            <button className='minibutton' onClick={this.selectLogo}>
              Change logo image
            </button>&nbsp;&nbsp;&nbsp;
            <button className='minibutton' onClick={this.clearLogo}>
              Remove logo image
            </button>
            <br />
            <br />
          </div>
        )
      } else {
        logo = (
          <div className='static-content'>
            <p>No logo selected.</p>
            <button className='minibutton' onClick={this.selectLogo}>
              Pick logo image using file browser
            </button>
            <br />
            <br />
          </div>
        )
      }

      var vtabsData = _.sortBy(orgs, 'name').map(function (org) {
        return { id: org.id, name: org.name }
      })

      var tabsData
      if (isOwner) {
        tabsData = [
          { id: 'users', name: 'Users' },
          { id: 'groups', name: 'Groups' },
          // {id: 'settings', name: 'Settings'},
          { id: 'profile', name: 'Profile' },
          { id: 'billing', name: 'Billing' },
          { id: 'misc', name: 'Misc.' }
        ]
      } else {
        tabsData = [
          { id: 'groups', name: 'Groups' },
          // {id: 'settings', name: 'Settings'},
          { id: 'misc', name: 'Misc.' }
        ]
      }
      var tabs = []
      tabsData.forEach(tab => {
        tabs.push(
          <td
            key={tab.id}
            className={_this.props.activeTabClass(
              tab.id,
              _this.props.defaultTab
            )}
          >
            <div onClick={_this.props.selectTab(tab.id)}>{tab.name}</div>
          </td>
        )
      })
      var vtabs = []
      vtabsData.forEach(vtab => {
        vtabs.push(
          <li
            key={vtab.id}
            className={_this.props.activeVtabClass(
              vtab.id,
              _this.props.defaultVtab
            )}
          >
            <div onClick={() => _this.selectOrg(vtab.id, null)}>
              {vtab.name}
            </div>
          </li>
        )
      })

      var rightContent
      if (this.state.creatingOrg) {
        rightContent = <div className='tabs-and-data'>{this.newOrgForm()}</div>
      } else {
        var addGroupButton
        if (!this.state.creatingGroup) {
          addGroupButton = (
            <button
              className='button minibutton square'
              onClick={this.toggleCreateGroup}
            >
              <span className='octicon octicon-plus' />
            </button>
          )
        }
        var orgGroups = Org.groups(org, this.props.app.getCommonParams())
        var groups
        if (orgGroups && orgGroups.length) {
          var ownerGroup = orgGroups.find(g => g.is_owners)
          var nonOwnerGroups = _.sortBy(
            orgGroups.filter(g => !g.is_owners),
            'name'
          )
          var groupItems = [ownerGroup]
            .concat(nonOwnerGroups)
            .map(function (group) {
              var groupClasses =
                _this.state.selectedGroup &&
                _this.state.selectedGroup.id === group.id
                  ? 'active'
                  : ''
              if (group.is_owners) {
                groupClasses += ' owners'
              }
              return (
                <li
                  className={groupClasses}
                  key={'org:' + org.id + '-group:' + group.id}
                  onClick={() => _this.selectGroup(group)}
                >
                  {group.name}
                </li>
              )
            })
          groups = <ul>{groupItems}</ul>
        } else {
          groups = <p>No groups yet.</p>
        }

        var activeUsers
        var orgUsers = Org.users(org, this.props.app.getCommonParams())
        if (orgUsers && orgUsers.length) {
          var activeUserItems = orgUsers.map(function (user) {
            var userClasses =
              _this.state.selectedUser &&
              _this.state.selectedUser.id === user.id
                ? 'active'
                : ''
            return (
              <li
                className={userClasses}
                key={'org:' + org.id + '-user:' + user.id}
                onClick={() => _this.selectUser(user)}
              >
                {user.first_name} {user.last_name}
              </li>
            )
          })
          activeUsers = <ul>{activeUserItems}</ul>
        } else {
          activeUsers = <p>No active users.</p>
        }

        var pendingInvites
        var orgInvites = Org.invites(org, this.props.app.getCommonParams())
        if (orgInvites && orgInvites.length) {
          var inviteItems = orgInvites.map(function (invite) {
            var inviteClasses =
              _this.state.selectedInvite &&
              _this.state.selectedInvite.id === invite.id
                ? 'active'
                : ''
            var inviteStr = invite.user
              ? invite.user.first_name + ' ' + invite.user.last_name
              : invite.user_string
            return (
              <li
                className={inviteClasses}
                key={'org:' + org.id + '-invite:' + invite.id}
                onClick={() => _this.selectInvite(invite)}
              >
                {inviteStr}
              </li>
            )
          })
          pendingInvites = <ul>{inviteItems}</ul>
        } else {
          pendingInvites = <p>No pending invites.</p>
        }

        var membershipRequests
        var orgRequests = Org.requests(org, this.props.app.getCommonParams())
        if (orgRequests && orgRequests.length) {
          var requestItems = orgRequests.map(function (request) {
            var requestUser = User.get(
              request.user_id,
              _this.props.app.state.entities.users
            )
            var requestClasses =
              _this.state.selectedRequest &&
              _this.state.selectedRequest.id === request.id
                ? 'active'
                : ''
            var userStr = requestUser
              ? requestUser.first_name + ' ' + requestUser.last_name
              : '(unknown user)'
            return (
              <li
                className={requestClasses}
                key={'org:' + org.id + '-request:' + request.id}
                onClick={() => _this.selectRequest(request)}
              >
                {userStr}
              </li>
            )
          })
          membershipRequests = <ul>{requestItems}</ul>
        } else {
          membershipRequests = <p>No membership requests.</p>
        }

        var groupsContainerClasses = 'list-container groups-container'
        if (this.state.creatingGroup) {
          groupsContainerClasses += ' creating'
        }
        var userActions
        if (this.state.selectedUser) {
          ownersGroup = orgGroups.find(g => g.is_owners)
          userActions = (
            <div>
              <button
                className='button danger'
                onClick={this.removeUser}
                disabled={
                  !this.state.selectedUser ||
                  (ownersGroup &&
                    ownersGroup.users.length === 1 &&
                    ownersGroup.users[0].id === this.state.selectedUser.id)
                }
              >
                <span className='octicon octicon-trashcan' /> Remove from
                organization
              </button>
            </div>
          )
        }
        if (this.state.selectedInvite) {
          userActions = (
            <div>
              <button className='button' onClick={this.resendInvite}>
                <span className='octicon octicon-sync' /> Resend
              </button>
              <button className='button danger' onClick={this.cancelInvite}>
                <span className='octicon octicon-circle-slash' /> Cancel
              </button>
            </div>
          )
        }
        if (this.state.selectedRequest) {
          userActions = (
            <div>
              <button className='button primary' onClick={this.acceptRequest}>
                <span className='octicon octicon-check' /> Accept
              </button>
              <button
                className='button danger active'
                onClick={this.denyRequest}
              >
                <span className='octicon octicon-x' /> Deny
              </button>
            </div>
          )
        }
        var groupActions,
          groupUsers,
          groupUserItems,
          groupUserActions,
          groupInlineActions
        if (this.state.selectedGroup) {
          groupUserItems = this.state.selectedGroup.users.map(function (
            groupUser
          ) {
            var groupUserClasses =
              _this.state.selectedGroupUser &&
              _this.state.selectedGroupUser.id === groupUser.id
                ? 'active'
                : ''
            return (
              <li
                className={groupUserClasses}
                key={
                  'org:' +
                  org.id +
                  '-group:' +
                  _this.state.selectedGroup.id +
                  '-user:' +
                  groupUser.id
                }
                onClick={() => _this.selectGroupUser(groupUser)}
              >
                {groupUser.first_name} {groupUser.last_name}
              </li>
            )
          })
          groupUsers = groupUserItems.length ? (
            <ul>{groupUserItems}</ul>
          ) : (
            <p>No users in group.</p>
          )
          if (this.state.selectedGroup.is_owners) {
            groupActions = (
              <p>
                The Owners group is special and cannot be deleted or renamed.
              </p>
            )
          } else {
            groupActions = <div />
          }
          if (this.state.addingUserToGroup) {
            groupInlineActions = (
              <div>
                {/* <Combobox
                  data={org.users.filter(
                    u =>
                      _this.state.selectedGroup.users
                        .map(u => u.id)
                        .indexOf(u.id) === -1
                  )}
                  onChange={this.handleUserToAddChange}
                  valueField='id'
                  textField='username'
                  filter='contains'
                  defaultOpen
                /> */}
                <button
                  className='button minibutton'
                  onClick={this.addUserToGroup}
                >
                  Add
                </button>
              </div>
            )
          }
          if (this.state.selectedGroupUser) {
            if (
              this.state.selectedGroup.is_owners &&
              this.state.selectedGroup.users.length === 1
            ) {
              groupUserActions = (
                <p>
                  Note: You cannot delete the last user in the Owners group. Add
                  another user first.
                </p>
              )
            } else {
              groupUserActions = <div />
            }
          }
        }
        var requestInfo, requestLabelTail
        if (!org.settings.disable_requests) {
          requestLabelTail = ' using the URL below'
          var requestUrl =
            window.location.protocol +
            '//' +
            window.location.host +
            '/org/request/' +
            org.invite_id
          requestInfo = (
            <div className='request-info'>
              <a href={requestUrl}>{requestUrl}</a>
            </div>
          )
        }
        rightContent = (
          <div className='tabs-and-data'>
            <table className='tabs'>
              <tbody>
                <tr>{tabs}</tr>
              </tbody>
            </table>
            <div className='tab-contents'>
              {(this.props.activeTab || this.props.defaultTab) === 'users' && (
                <div className='tab'>
                  <div className='row flex-h'>
                    <div className='outdented-checkbox'>
                      <input
                        type='checkbox'
                        onChange={this.handleAllowRequestsChange}
                        id='allow-requests'
                        checked={!org.settings.disable_requests}
                      />
                    </div>
                    <div>
                      <label className='inline' htmlFor='allow-requests'>
                        Allow users to request membership to this organization{' '}
                        {requestLabelTail}
                      </label>
                      {requestInfo}
                    </div>
                    {this.props.app.featureEnabled('permissions') && (
                      <div>
                        <label htmlFor='default-access-level'>
                          Access level for members
                        </label>
                        <br />
                        <select
                          value={org.default_access_level}
                          onChange={this.handleDefaultAccessChange}
                        >
                          <option value={constants.NO_ACCESS}>No access</option>
                          <option value={constants.READ_ACCESS}>
                            Read-only access
                          </option>
                          <option value={constants.WRITE_ACCESS}>
                            Write/edit access
                          </option>
                          <option value={constants.ADMIN_ACCESS}>
                            Admin access
                          </option>
                          <option value={constants.OWNER_ACCESS}>
                            Owner access
                          </option>
                        </select>
                        <p className='muted tiny'>
                          You can override this or set custom access based on
                          groups membership for any sub-tree.
                        </p>
                      </div>
                    )}
                  </div>
                  <div className='row'>
                    <div className='invite-more-container'>
                      <label>
                        <span>Invite a user</span>
                        <input
                          type='text'
                          value={this.state.inviteStr}
                          onKeyDown={this.handleInviteFieldKeyDown}
                          onChange={this.handleInviteFieldChange}
                          placeholder='Type in a username or an email address to invite another user'
                        />
                      </label>
                    </div>
                  </div>
                  <div className='list-container'>
                    <div className='list active-users-container'>
                      <h4>Active</h4>
                      {activeUsers}
                    </div>
                    <div className='list invites-container'>
                      <h4>Pending invitations</h4>
                      {pendingInvites}
                    </div>
                    <div className='list requests-container'>
                      <h4>Membership requests</h4>
                      {membershipRequests}
                    </div>
                  </div>
                  <div className='actions-container user-actions-container'>
                    {userActions}
                  </div>
                </div>
              )}
              {(this.props.activeTab || this.props.defaultTab) === 'groups' && (
                <div className='tab'>
                  <div className={groupsContainerClasses}>
                    <div className='list groups-list'>
                      {groups}
                      <div className='cp'>
                        {addGroupButton}
                        <button
                          className='button minibutton square'
                          disabled={
                            !this.state.selectedGroup ||
                            this.state.selectedGroup.is_owners
                          }
                          onClick={this.removeGroup}
                        >
                          <span className='octicon octicon-dash' />
                        </button>
                      </div>
                    </div>
                    <div className='list'>
                      {this.state.creatingGroup && this.newGroupForm()}
                      {!this.state.creatingGroup &&
                        !this.state.selectedGroup && <p>No group selected.</p>}
                      {!this.state.creatingGroup &&
                        this.state.selectedGroup && (
                        <div>
                          {groupUsers}
                          {groupInlineActions}
                          <div className='cp'>
                            <button
                              className='button minibutton square'
                              onClick={this.toggleAddUserToGroup}
                            >
                              <span className='octicon octicon-plus' />
                            </button>
                            <button
                              className='button minibutton square'
                              disabled={
                                !this.state.selectedGroupUser ||
                                  (this.state.selectedGroup.is_owners &&
                                    this.state.selectedGroup.users.length === 1)
                              }
                              onClick={this.removeUserFromGroup}
                            >
                              <span className='octicon octicon-dash' />
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='group-combined-actions flex-h'>
                    <div className='actions-container group-actions-container'>
                      {groupActions}
                    </div>
                    <div className='actions-container group-user-actions-container'>
                      {groupUserActions}
                    </div>
                  </div>
                </div>
              )}
              {(this.props.activeTab || this.props.defaultTab) ===
                'settings' && (
                <div className='tab'>
                  <div className='row'>
                    <label className='inline'>
                      <input
                        type='checkbox'
                        ref={dropboxBackup => {
                          this.dropboxBackup = dropboxBackup
                        }}
                        checked={
                          this.props.user.settings.dropbox_backup_enabled
                        }
                        onChange={this.handleDropboxChange}
                      />{' '}
                      Save daily backup to Dropbox
                    </label>
                    <p>
                      <small className='muted'>
                        We <strong>strongly</strong> recommend that you enable
                        this option.
                      </small>
                    </p>
                  </div>
                  <div className='row'>
                    <label className='inline'>
                      <input
                        type='checkbox'
                        ref={hideCompleted => {
                          this.hideCompleted = hideCompleted
                        }}
                        checked={this.props.user.settings.hide_completed}
                        onChange={this.saveSettings}
                      />{' '}
                      Hide completed items by default
                    </label>
                    <p>
                      <small className='muted'>
                        Note that this can be overridden for any tab.
                      </small>
                    </p>
                  </div>
                  <div className='row'>
                    <label className='inline'>
                      <input
                        type='checkbox'
                        ref={hideWidgets => {
                          this.hideWidgets = hideWidgets
                        }}
                        checked={this.props.user.settings.hide_widgets}
                        onChange={this.saveSettings}
                      />{' '}
                      Hide Due Date and Assigned To in main list
                    </label>
                    <p>
                      <small className='muted'>
                        Note that this can be overridden for any tab. Due Dates
                        and Assigned To are always visible in the Due List.
                      </small>
                    </p>
                  </div>
                </div>
              )}
              {(this.props.activeTab || this.props.defaultTab) ===
                'billing' && <div className='tab'>{proStuff}</div>}
              {(this.props.activeTab || this.props.defaultTab) ===
                'profile' && (
                <div className='tab'>
                  <div>
                    <div className='row'>
                      <label>
                        Name
                        <input
                          type='text'
                          data-name='name'
                          data-obj='org'
                          value={this.state.org.name}
                          placeholder='Apple Inc.'
                          onChange={this.handleFormFieldChange}
                        />
                      </label>
                      {this.errorFor('name')}
                    </div>
                    <div className='row'>
                      <label>
                        Identifier
                        <input
                          type='text'
                          data-name='identifier'
                          data-obj='org'
                          value={this.state.org.identifier}
                          placeholder='apple'
                          onChange={this.handleFormFieldChange}
                        />
                      </label>
                      {this.errorFor('identifier')}
                      <p>
                        <small className='muted'>
                          This is like a username for your organization. Valid
                          characters: a-z, 0-9, dash, underscore.
                        </small>
                      </p>
                    </div>
                    <div className='row'>
                      <label>{logo}</label>
                      {this.errorFor('settings.logo')}
                    </div>
                  </div>
                  <div className='buttons'>
                    <button
                      onClick={this.saveProfile}
                      className='button default'
                    >
                      Save
                    </button>
                  </div>
                </div>
              )}
              {(this.props.activeTab || this.props.defaultTab) === 'misc' && (
                <div className='tab'>
                  <div className='static-content'>
                    <div>
                      <p>
                        You can permanently delete this organization if you
                        want. This will delete all groups and group memberships.
                        Deleting an organization cannot be undone.
                      </p>
                      <p>
                        Note that deleting your account will <em>not</em> delete
                        any backups in your Dropbox account.
                      </p>
                      <p>
                        <button
                          className='button danger'
                          onClick={this.deleteOrg}
                        >
                          Delete organization
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )
      }

      var addOrgButton
      if (!this.state.creatingOrg) {
        addOrgButton = (
          <button
            className='button minibutton square'
            onClick={this.toggleCreateOrg}
          >
            <span className='octicon octicon-plus' />
          </button>
        )
      }
      var vtabsClasses = 'vtabs'
      if (this.state.creatingOrg) {
        vtabsClasses += ' creating'
      }

      content = (
        <div className='tabs-container with-vtabs'>
          <div className='vtabs-container'>
            <ul className={vtabsClasses}>{vtabs}</ul>
            <div className='cp'>{addOrgButton}</div>
          </div>
          {rightContent}
        </div>
      )
    } else {
      if (this.state.creatingOrg) {
        content = <div>{this.newOrgForm()}</div>
      } else {
        content = (
          <div>
            <p>No orgs yet.</p>
            <p>
              <button className='button primary' onClick={this.toggleCreateOrg}>
                Create org
              </button>
            </p>
          </div>
        )
      }
    }

    return <div id='orgs'>{content}</div>
  }
})

module.exports = OrgsOverlay
