import './styles.css'

import React from 'react'

// import NodeInfo from '../sections/node_info'
import DueList from '../sections/due_list'
import Navigator from '../sections/navigator'
import LatestActivity from '../sections/latest_activity'
import Stats from '../sections/stats'
// import Node           from '../../../js/node';

export default class Dashboard extends React.Component {
  close = () => {
    this.props.app.setNodeSelection(null)
  }

  render () {
    var dueList
    if (this.props.app.featureEnabled('due_nodes')) {
      dueList = <DueList {...this.props} />
    }
    return (
      <div className='body'>
        {/*
        <NodeInfo {...this.props} />
        */}
        {dueList}
        <Navigator {...this.props} />
        <LatestActivity {...this.props} />
        <Stats {...this.props} />
      </div>
    )
  }
}
