import React from 'react'
import scriptJs from 'scriptjs'
import { StripeProvider, Elements } from 'react-stripe-elements'

import InjectedPlans from './plans_section'

export default class PlansSectionWrapper extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      stripe: null
    }
  }

  componentDidMount () {
    scriptJs(['https://js.stripe.com/v3/'], () => {
      const stripe = window.Stripe(this.props.app.state.stripeToken)
      this.setState({ stripe })
    })
  }

  render () {
    return (
      <StripeProvider stripe={this.state.stripe}>
        <Elements>
          <InjectedPlans {...this.props} />
        </Elements>
      </StripeProvider>
    )
  }
}
