import './styles.css'

import React from 'react'

export default class PhonesPicker extends React.Component {
  // state = {
  //   str: ''
  // }

  render () {
    // const { app } = this.props
    // const node = app.node(app.state.floater.data.nodeId)
    // const points = (node.data || {}).story_points || {}

    return <div className='phones-picker'>PHONES PICKER</div>
  }
}
