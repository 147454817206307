import React from 'react'

class ErrorsSection extends React.Component {
  state = {}

  componentDidMount () {}

  render () {
    var content = <div>Errors</div>

    return content
  }
}

module.exports = ErrorsSection
