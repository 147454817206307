import './styles.css'

import React from 'react'

import SetPlaceholder from '../../set_placeholder'

export default class Company extends React.Component {
  render () {
    const { app, node } = this.props
    const data = node.data || {}
    const company = data.company || {}
    let name = ''
    let companyNode
    if (company.id) {
      companyNode = app.node(company.id)
      if (companyNode) {
        name = companyNode.title
      }
    } else {
      name = company.name
    }
    const clickHandler = companyNode ? () => app.setRoot(companyNode) : null
    return (
      <div>
        {data.company && (
          <div className='actual-data data-company' onClick={clickHandler}>
            {name}
          </div>
        )}
        {!data.company && (
          <SetPlaceholder
            onClick={ev =>
              app.toggleFloater(ev.target, 'company-picker', {
                nodeId: node.id
              })
            }
          />
        )}
      </div>
    )
  }
}
