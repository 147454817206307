import './styles.css'

import React from 'react'
import ReactTooltip from 'react-tooltip'
import Avatar from '../avatar'

import Org from '../../js/org'
import Misc from '../../js/misc'

class OrgSwitcher extends React.Component {
  shouldComponentUpdate (nextProps) {
    if (this.props.app && this.props.shouldComponentTypeUpdate) {
      return this.props.app.shouldComponentTypeUpdate(nextProps)
    } else {
      return true
    }
  }

  selectOrg = (org, ev) => {
    this.props.app.setOrg(org)
    ev && ev.preventDefault && ev.preventDefault()
  }

  newOrg = ev => {
    this.props.app.setPanel('create-org', { data: {}, title: 'Create team' })
    ev && ev.preventDefault && ev.preventDefault()
  }

  manageOrg = (org, ev) => {
    this.props.app.setPanel('organizations-page', {
      data: { org: org, user: this.props.user },
      title: 'Manage team',
      activeSection: 'settings-section'
    })
    // this.props.app.setPanel('orgs-overlay', {data: {org: org}, title: 'Manage organizations'});
    if (ev && ev.preventDefault) {
      ev.stopPropagation()
      ev.preventDefault()
    }
  }

  manageUser = ev => {
    this.props.app.setPanel('user-settings-page', {
      data: { user: this.props.user },
      title: 'User settings',
      activeSection: 'profile-section'
    })
    ev && ev.preventDefault && ev.preventDefault()
  }

  render () {
    var _this = this
    var user = this.props.user
    var editNode = this.props.app.editNode()
    // var personalClasses = 'owner '
    // personalClasses += this.props.org ? '' : 'active'
    var platform = Misc.platform()
    var modifier = NOTEBASE_IS_DESKTOP && platform === 'mac' ? '⌘' : 'ctrl'
    // var personal = [
    //   <div
    //     key='org-personal'
    //     className={personalClasses}
    //     onClick={() => this.selectOrg(null)}
    //     title='My Notebase'
    //   >
    //     <Avatar
    //       app={this.props.app}
    //       user={this.props.user}
    //       shape='square'
    //       size='medium'
    //     />
    //     <div className='key'>{modifier}+1</div>
    //     <div
    //       className='settings'
    //       onClick={this.manageUser}
    //       data-tip
    //       data-for='user-settings'
    //     >
    //       <i className='fa fa-fw fa-cog' />
    //     </div>
    //   </div>
    // ]
    var orgs = this.props.app.sortedOrgs() || []
    var orgItems
    if (orgs.length) {
      var i = 0
      orgItems = orgs.map(function (org) {
        var isOwner = Org.isOwner(org, user, _this.props.app.getCommonParams())
        var active =
          _this.props.org && _this.props.org.id === org.id ? 'active' : ''
        i++
        return (
          <div
            key={'org-' + org.id}
            className={active + (isOwner ? ' owner' : '')}
            onClick={() => _this.selectOrg(org)}
            title={org.name}
          >
            {active && <div className='active-org-indicator' />}
            <Avatar
              app={_this.props.app}
              org={org}
              shape='square'
              size='medium'
            />
            <div className='key'>
              {modifier}+{i}
            </div>
            <div
              className='settings'
              data-tip
              data-for='team-settings'
              onClick={() => _this.manageOrg(org)}
            >
              <i className='fa fa-fw fa-cog' />
            </div>
          </div>
        )
      })
    }
    // orgItems = personal.concat(orgItems)
    var orgElement = <div className='orgs'>{orgItems}</div>
    return editNode ? (
      false
    ) : (
      <div id='org-switcher'>
        {orgElement}
        {user && (
          <div
            className='add-org'
            data-tip
            data-for='create-team'
            onClick={this.newOrg}
          >
            <i className='fa fa-plus' />
          </div>
        )}
        {user && (
          <ReactTooltip
            id='create-team'
            type='dark'
            effect='solid'
            data-delay-show='1000'
          >
            <span>Create team</span>
          </ReactTooltip>
        )}
        <ReactTooltip
          id='user-settings'
          type='dark'
          effect='solid'
          data-delay-show='1000'
        >
          <span>User settings</span>
        </ReactTooltip>
        <ReactTooltip
          id='team-settings'
          type='dark'
          effect='solid'
          data-delay-show='1000'
        >
          <span>Team settings</span>
        </ReactTooltip>
      </div>
    )
  }
}

module.exports = OrgSwitcher
