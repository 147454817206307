import React, { Component } from 'react'

import Node from '../../js/node'

export function hasHeaderContent (node) {
  return (node.settings.labels || []).length
  // return node.title;
}

export default class BoardHeader extends Component {
  toggleExpansion = () => {
    const { app, board } = this.props
    const boardNode = board.node()
    const boardSettings = boardNode.settings || {}
    Node.modify(
      boardNode,
      {
        settings: {
          ...boardSettings,
          expand_board_labels: !boardSettings.expand_board_labels
        }
      },
      app.getCommonParams()
    )
  }

  toggleFilter = label => {
    const { app } = this.props
    const oldFilter = app.state.filter || {}
    const oldLabels = oldFilter.labels || []
    const newLabels =
      oldLabels.indexOf(label.id) === -1
        ? oldLabels.concat([label.id])
        : oldLabels.filter(l => l !== label.id)
    app.setAppState({ filter: { ...oldFilter, labels: newLabels } })
  }

  render () {
    const { app, board, node } = this.props
    const boardNode = board.node()
    const boardSettings = boardNode.settings || {}
    const overrides =
      (app.orgId()
        ? app.org().settings.label_overrides
        : app.user().settings.label_overrides) || {}
    return !hasHeaderContent(node) ? (
      false
    ) : (
      <div className='kb-header'>
        <div className='kb-header-spacer'>
          <div className='ctrl' onClick={this.toggleExpansion}>
            <i
              className={`fa fa-fw fa-${
                boardSettings.expand_board_labels ? 'toggle-up' : 'toggle-down'
              }`}
            />
          </div>
        </div>
        <div
          className={`kb-header-content ${
            boardSettings.expand_board_labels ? 'expanded' : ''
          }`}
        >
          {node.settings.labels.map(id => {
            const defaultLabel = app.state.defaultLabels.find(l => l.id === id)
            const override = overrides[id]
            const label = { ...defaultLabel, ...override }
            return (
              <span
                key={id}
                className='label'
                style={{ backgroundColor: label.bg }}
                onClick={() => this.toggleFilter(label)}
              >
                <span>{label.name || '\xa0'}</span>
              </span>
            )
          })}
        </div>
      </div>
    )
  }
}
