import React from 'react'

import createReactClass from 'create-react-class'

// import Mixins from '../../../mixins/mixins';

import Node from '../../../js/node'
import Misc from '../../../js/misc'

const BookmarkSettingsOverlay = createReactClass({
  displayName: 'BookmarkSettingsOverlay',
  // mixins: [Mixins.FormHandling, Mixins.ErrorHandling],

  UNSAFE_componentWillMount: function () {
    this.handleFormFieldChange = Misc.handleFormFieldChange.bind(this, this)
    this.errorFor = Misc.errorFor.bind(this, this)
  },

  getInitialState: function () {
    var node = this.props.panel.opts.data.node
    return {
      title: node.title,
      url: node.url
    }
  },

  save: function () {
    if (!/.+/.test(this.state.title)) {
      this.setState({
        error: {
          field: 'title',
          message: 'Bookmarks need a non-empty title to be clickable'
        }
      })
    } else if (!/[^:]+:[^:]+/.test(this.state.url)) {
      this.setState({
        error: { field: 'url', message: 'This does not look like a valid URL' }
      })
    } else {
      var node = this.props.panel.opts.data.node
      var changes = {
        type: 'bookmark',
        title: this.title.value,
        url: this.url.value
      }
      Node.modify(node, changes, this.props.app.getCommonParams(), () => {
        this.props.app.setPanel(null)
      })
    }
  },

  render: function () {
    return (
      <div id='node-settings'>
        {this.errorFor()}
        <div className='row'>
          <div className='row-heading'>
            <label>Title:</label>
          </div>
          <div className='row-content'>
            <input
              type='text'
              ref={title => {
                this.title = title
              }}
              data-name='title'
              value={this.state.title}
              placeholder='10 ways to improve your life'
              onChange={this.handleFormFieldChange}
            />

            {this.errorFor('title')}
          </div>
        </div>
        <div className='row'>
          <div className='row-heading'>
            <label>URL:</label>
          </div>
          <div className='row-content'>
            <input
              type='text'
              ref={url => {
                this.url = url
              }}
              data-name='url'
              value={this.state.url}
              placeholder='http://superawesometips.example/'
              onChange={this.handleFormFieldChange}
            />
            {this.errorFor('url')}
          </div>
        </div>
        <div className='buttons'>
          <button onClick={this.save} className='button default'>
            Save
          </button>
        </div>
      </div>
    )
  }
})

module.exports = BookmarkSettingsOverlay
