import React from 'react'

import Node from '../../js/node'

import './styles.css'

export default class PriorityPicker extends React.Component {
  setPriority = value => {
    const { app } = this.props
    const node = app.node(app.state.floater.data.nodeId)
    const data = { ...(node.data || {}), priority: value }
    Node.modify(node.id, { data }, app.getCommonParams(), () => {
      app.setState({ floater: null })
    })
  }

  render () {
    const { app } = this.props
    const node = app.node(app.state.floater.data.nodeId)
    const priority = (node.data || {}).priority

    return (
      <div className='priority-picker'>
        {app.state.dataPriorities.map(p => {
          const checked = priority === p.value
          return (
            <div key={p.value} onClick={() => this.setPriority(p.value)}>
              <div style={{ backgroundColor: p.color }}>
                {checked && <i className='fa fa-fw fa-check' />}
              </div>
              <div>{p.label}</div>
            </div>
          )
        })}
      </div>
    )
  }
}
