import React from 'react'

import Node from '../../js/node'

import './styles.css'

export default class GenericPicker extends React.Component {
  constructor (props) {
    super(props)
    this.state = { value: this.getValue(props) }
  }

  getValue = props => {
    const { app } = props
    if (app.state.floater.data && app.state.floater.data.field) {
      const node = app.node(app.state.floater.data.nodeId)
      const fieldParts = app.state.floater.data.field.split('.')
      const obj = fieldParts.length > 1 ? node[fieldParts[0]] || {} : node
      return obj[fieldParts[1] || fieldParts[0]] || ''
    } else {
      return ''
    }
  }

  handleKeyUp = ev => {
    const { app } = this.props
    const node = app.node(app.state.floater.data.nodeId)

    if (ev.key === 'Enter') {
      const fieldParts = app.state.floater.data.field.split('.')
      let changes
      if (fieldParts.length > 1) {
        const obj = node[fieldParts[0]] || {}
        const objValue = { [fieldParts[1]]: this.state.value }
        const newObj = { ...obj, ...objValue }
        changes = { [fieldParts[0]]: newObj }
      } else {
        changes = { [fieldParts[0]]: this.state.value }
      }
      Node.modify(node.id, changes, app.getCommonParams(), () => {
        app.setState({ floater: null })
      })
      ev.preventDefault()
    }
  }

  handleChange = ev => {
    this.setState({ value: ev.target.value })
  }

  render () {
    const { app } = this.props
    const floaterData = app.state.floater.data || {}
    const type = floaterData.fieldType || 'text'
    return floaterData.field ? (
      <div className='generic-picker'>
        <input
          type={type}
          value={this.state.value}
          onChange={this.handleChange}
          onKeyUp={this.handleKeyUp}
        />
      </div>
    ) : (
      <div>NO FIELD</div>
    )
  }
}
