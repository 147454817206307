import React from 'react'

import createReactClass from 'create-react-class'

// import Mixins from'../../../../../mixins/mixins';
import Org from '../../../../../js/org'
import Misc from '../../../../../js/misc'

const SettingsSection = createReactClass({
  displayName: 'SettingsSection',
  // mixins: [Mixins.FormHandling, Mixins.ErrorHandling],

  getInitialState: function () {
    var org = this.getCurrentOrg() || {}
    var state = {
      message: '',
      error: null,
      inviteStr: '',
      org: org
    }
    return state
  },

  UNSAFE_componentWillMount: function () {
    // this.handleFormFieldChange = Misc.handleFormFieldChange.bind(this, this);
    this.errorFor = Misc.errorFor.bind(this, this)
  },

  UNSAFE_componentWillReceiveProps: function (nextProps) {
    if (nextProps.activeVtabId !== this.props.activeVtabId) {
      const org = Org.get(
        nextProps.activeVtabId,
        nextProps.app.state.entities.orgs
      )
      this.setState({ org: org })
    }
  },

  getCurrentOrg: function () {
    var org
    if (
      this.props.activeVtabId &&
      this.props.app.state.entities.orgs &&
      this.props.app.state.entities.orgs.length
    ) {
      org = Org.get(this.props.activeVtabId, this.props.app.state.entities.orgs)
    }
    return org
  },

  deleteOrg: function (ev) {
    const doIt = () => {
      const org = this.getCurrentOrg()
      if (org) {
        const data = { org_id: org.id }
        const switchOrg = Boolean(
          this.props.app.orgId() && this.props.app.orgId() === org.id
        )
        this.props.app.ajax('delete_org', { body: data }, () => {
          if (switchOrg) {
            this.props.app.setOrg(null, () => {
              this.props.app.setPanel(null)
            })
          } else {
            this.props.app.setPanel(null)
          }
        })
      }
    }
    this.props.app.getConfirmation(() => {
      doIt()
    })
    ev.preventDefault()
    // this.props.app.getConfirmation(() => {
    //   Misc.jx(this, 'delete_org', {org_id: org.id}, (res) => { // eslint-disable-line no-unused-vars
    //     this.props.app.setOrg(null, () => {
    //       var newOrgs = _.xreject(this.props.app.state.entities.orgs, function(o) { return o.id === org.id; });
    //       this.props.app.setAppState({
    //         orgs: newOrgs,
    //       });
    //       this.props.app.setPanel(null);
    //     });
    //   });
    // },
    // null,
    // 'Are you absolutely sure that you want to permanently delete this organization and all its groups and group memberships? This action cannot be undone.');
  },

  // updateOrgs: function(updatedOrg, callback) {
  //   var newOrgs = [];
  //   this.props.app.state.entities.orgs.forEach(function(org) {
  //     if (org.id === updatedOrg.id) {
  //       newOrgs.push(updatedOrg);
  //     } else {
  //       newOrgs.push(org);
  //     }
  //   });
  //   var newState = {orgs: newOrgs};
  //   this.props.app.setAppState(newState, callback);
  // },

  saveProfile: function () {
    var org = this.getCurrentOrg()
    if (org) {
      org.name = this.state.org.name
      org.identifier = this.state.org.identifier
      var data = {
        id: org.id,
        updates: {
          name: this.state.org.name,
          identifier: this.state.org.identifier
        }
      }
      this.props.app.ajax('update_org', { body: data })
      // Misc.jx(_this, 'update_org', data, function(res) { // eslint-disable-line no-unused-vars
      //   _this.updateOrgs(org);
      // });
    }
  },

  handleNameChange: function (ev) {
    const org = Object.assign({}, this.state.org)
    org.name = ev.target.value

    this.setState({ org: org })
  },

  handleIdentifierChange: function (ev) {
    const org = Object.assign({}, this.state.org)
    org.identifier = ev.target.value

    this.setState({ org: org })
  },

  pickProfileImage: function () {
    const org = this.getCurrentOrg()
    if (org) {
      this.props.app.browseFiles({ orgId: org.id }, file => {
        if (file) {
          const url = Misc.fileUrl(file)
          const newSettings = Object.assign({}, org.settings)
          newSettings.logo = url
          const data = { id: org.id, updates: { settings: newSettings } }
          this.props.app.ajax('update_org', { body: data })
        }
      })
    }
  },

  render: function () {
    var content = <div />

    var orgs = this.props.app.state.entities.orgs
    if (orgs && orgs.length) {
      // var ownersGroup;
      var org = this.getCurrentOrg()
      if (!org) {
        org = orgs[0]
      }

      const logoImage = org.settings.logo || '/img/org-img-placeholder.png'
      content = (
        <div className='form'>
          <div className='row'>
            <label>Logo</label>
            <div className='row profile-picture-container'>
              <img src={logoImage} />
            </div>
            <button onClick={this.pickProfileImage}>
              Upload or select logo image
            </button>
          </div>

          <div className='row'>
            <label>Name:</label>
            <input
              type='text'
              data-name='name'
              value={this.state.org.name}
              placeholder='Apple'
              onChange={this.handleNameChange}
            />
            {this.errorFor('name')}
          </div>

          <div className='row'>
            <label>Identifier:</label>
            <input
              type='text'
              data-name='identifier'
              value={this.state.org.identifier}
              placeholder='apple'
              onChange={this.handleIdentifierChange}
            />
            {this.errorFor('identifier')}
          </div>

          <div className='row'>
            <input type='submit' value='Save' onClick={this.saveProfile} />
          </div>

          <div className='row spacer' />

          <div className='row'>
            <label>Delete team</label>
            <div className='small-description'>
              <p>
                You can permanently delete this team if you want. This will
                delete all groups and group memberships. Deleting an team cannot
                be undone.
              </p>
              <p>
                Note that deleting your account will not delete any backups in
                your Dropbox account.
              </p>
            </div>
            <p>
              <button className='button delete' onClick={this.deleteOrg}>
                Delete team
              </button>
            </p>
          </div>
        </div>
      )
    }

    return content
  }
})

module.exports = SettingsSection
