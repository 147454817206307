const $ = require('jquery')
const Combokeys = require('combokeys')
const Shortcuts = require('../js-modules/shortcuts')
const Node = require('../js/node')
const Tab = require('../js/tab')
const Keys = require('../js/keys')
const Text = require('../js/text')
const Misc = require('../js/misc')

function wrapCommand (app, cb) {
  return (e, combo) => {
    // console.log('EXECUTING COMMAND', combo);
    if (['backspace', 'left', 'right'].indexOf(combo) !== -1) {
      // if (['backspace'].indexOf(combo) !== -1) {
      cb(e, combo)
    } else {
      const commonParams = app.getCommonParams()
      commonParams._skipFocus = true
      Node.flushTextUndo({}, commonParams, () => {
        cb(e, combo)
      })
    }
  }
}

function setHasMoved (app) {
  clearTimeout(window._app.cursorMoveTimeout)
  window._app.cursorMoveTimeout = setTimeout(() => {
    app.setState({ hasMoved: true })
  }, 200)
}

module.exports = {
  init: app => {
    const combokeys = new Combokeys(document.documentElement)
    require('combokeys/plugins/global-bind')(combokeys)

    combokeys.bindGlobal(
      Shortcuts.getKeyCombo('addNode'),
      wrapCommand(app, e => {
        const button = $('#overlay').find('.buttons button.default')
        const hamburger = $('#hamburger.open')
        const ctxMenu = $('#floater.context-menu:visible')
        const crumb = $('#breadcrumbs li.crumb.active')
        const newBoardNode = $(
          '.kb-card.add-new textarea:focus, .kb-list-container.add-new textarea:focus'
        )
        const ctrl = hamburger.length ? hamburger : ctxMenu
        if (button.length) {
          console.log('here1')
          button.click()
        } else if (ctrl.length) {
          console.log('here2')
          e.stopPropagation()
          e.preventDefault()
          ctrl.find('li.active').click()

          // Needed so that handleTitleEnterKeys can also check if the ctrl is open
          setTimeout(() => {
            ctrl.removeClass('open')
          }, 0)
        } else if (app.state.switcherOpen) {
          console.log('here3')
          // Passthrough
        } else if (
          app.state.rootIsBoard &&
          app.state.activeNodeId &&
          app.state.activeNodeId !== app.state.rootNodeId
        ) {
          console.log('here4')
          app.setSummaryNode(app.state.activeNodeId)
        } else if (newBoardNode.length) {
          console.log('here5')
          // Passthrough
        } else if (crumb.length) {
          console.log('here6')
          e.stopPropagation()
          e.preventDefault()
          app.setRoot(crumb.data('node-id'))
        } else {
          // if (window._app.activeNode && window._app.focusItem === 'node') {
          //   const activeNode = Node.get(window._app.activeNode, app.state.entities.nodes);
          //   if (activeNode && activeNode.type === 'bookmark') {
          //     if (!$(e.target).closest('li.node.read-only').length) {
          //       Keys.handleTitleEnter(e, activeNode, app.getCommonParams());
          //     }
          //   }
          // }
        }
      })
    )

    combokeys.bindGlobal(
      Shortcuts.getKeyCombo('goUpOneLevel'),
      wrapCommand(app, e => {
        const rootNode = app.rootNode()
        if (rootNode) {
          app.setRoot(rootNode.parent_id)
          e.preventDefault()
        }
      })
    )

    combokeys.bindGlobal(
      Shortcuts.getKeyCombo('eraseNode'),
      wrapCommand(app, e => {
        if (window._app.activeNode && e.target.tagName === 'BODY') {
          const activeNode = Node.get(
            window._app.activeNode,
            app.state.entities.nodes
          )
          if (activeNode && activeNode.type === 'bookmark') {
            e.preventDefault()
          }
        }
      })
    )

    combokeys.bindGlobal(
      Shortcuts.getKeyCombo('indent'),
      wrapCommand(app, e => {
        if (window._app.activeNode && e.target.tagName === 'BODY') {
          const activeNode = Node.get(
            window._app.activeNode,
            app.state.entities.nodes
          )
          if (activeNode && activeNode.type === 'bookmark') {
            if (!$(e.target).closest('li.node.read-only').length) {
              Keys.handleTitleTab(e, activeNode, app.getCommonParams())
            }
          }
        }
      })
    )

    combokeys.bindGlobal(
      Shortcuts.getKeyCombo('outdent'),
      wrapCommand(app, e => {
        if (window._app.activeNode && e.target.tagName === 'BODY') {
          const activeNode = Node.get(
            window._app.activeNode,
            app.state.entities.nodes
          )
          if (activeNode && activeNode.type === 'bookmark') {
            if (!$(e.target).closest('li.node.read-only').length) {
              Keys.handleTitleTab(e, activeNode, app.getCommonParams())
            }
          }
        }
      })
    )

    combokeys.bindGlobal(
      Shortcuts.getKeyCombo('toggleEmphasis'),
      wrapCommand(app, e => {
        if (window._app.activeNode && window._app.focusItem === 'node') {
          const activeNode = Node.get(
            window._app.activeNode,
            app.state.entities.nodes
          )
          if (activeNode && activeNode.type === 'bookmark') {
            if (!$(e.target).closest('li.node.read-only').length) {
              app.stashFocus()
              Keys.handleTitleEnter(e, activeNode, app.getCommonParams())
              app.applyStashedFocus()
            }
          }
        }
      })
    )

    combokeys.bindGlobal(
      Shortcuts.getKeyCombo('complete'),
      wrapCommand(app, e => {
        if (window._app.activeNode && window._app.focusItem === 'node') {
          const activeNode = Node.get(
            window._app.activeNode,
            app.state.entities.nodes
          )
          if (activeNode && activeNode.type === 'bookmark') {
            if (!$(e.target).closest('li.node.read-only').length) {
              app.stashFocus()
              Keys.handleTitleEnter(e, activeNode, app.getCommonParams())
              app.applyStashedFocus()
            }
          }
        }
      })
    )

    combokeys.bindGlobal(
      Shortcuts.getKeyCombo('undo'),
      wrapCommand(app, e => {
        if (
          ['INPUT', 'TEXTAREA'].indexOf(e.target.tagName) === -1 &&
          !app.state.editNodeId
        ) {
          e.preventDefault()
          e.stopPropagation()
          app.doUndo()
        }
      })
    )

    combokeys.bindGlobal(
      Shortcuts.getKeyCombo('redo'),
      wrapCommand(app, e => {
        if (
          ['INPUT', 'TEXTAREA'].indexOf(e.target.tagName) === -1 &&
          !app.state.editNodeId
        ) {
          app.doRedo()
          e.preventDefault()
        }
      })
    )

    combokeys.bindGlobal(
      Shortcuts.getKeyCombo('prevTab'),
      wrapCommand(app, e => {
        const active = $('#tabs .tab.active')
        const prev = active.prev('.tab')
        if (prev.length) {
          app.setTab(prev.data('tab-id'))
        }

        e.preventDefault()
      })
    )

    combokeys.bindGlobal(
      Shortcuts.getKeyCombo('nextTab'),
      wrapCommand(app, e => {
        const active = $('#tabs .tab.active')
        const next = active.next('.tab')
        if (next.length) {
          app.setTab(next.data('tab-id'))
        }

        e.preventDefault()
      })
    )

    combokeys.bindGlobal(
      Shortcuts.getKeyCombo('openUrl'),
      wrapCommand(app, e => {
        const node = Node.get(window._app.activeNode, app.state.entities.nodes)
        let url
        if (node.type === 'bookmark') {
          url = node.url
        } else if (node.settings.published) {
          url = app.pubUrl(node)
        } else if (node.type === 'site') {
          url = app.pubUrl(node)
        }

        if (url) {
          window.open(url, '_blank')
          e.preventDefault()
          e.stopPropagation()
        }
      })
    )

    combokeys.bindGlobal(
      Shortcuts.getKeyCombo('toggleEditing'),
      wrapCommand(app, e => {
        if (!$('#body-container.read-only').length) {
          app.toggleEditing()
        }

        e.preventDefault()
      })
    )

    combokeys.bindGlobal(
      Shortcuts.getKeyCombo('zoomAndEdit'),
      wrapCommand(app, e => {
        if (!$(e.target).closest('li.node.read-only').length) {
          const node = Node.get(
            window._app.activeNode,
            app.state.entities.nodes
          )
          app.toggleEditing({ editNode: node })
          // if (app.state.editNode) {
          //   app.toggleEditing();
          //   app.setRoot(app.state.prevRoot);
          // } else {
          //   app.setAppState({rootNode: window._app.activeNode}, {skipRemoteParse: true}, () => {
          //     app.toggleEditing();
          //   });
          // }
        }
        if (window.Intercom) {
          window.Intercom('trackEvent', 'used-zoom-and-edit')
        }

        e.preventDefault()
      })
    )

    combokeys.bindGlobal(
      Shortcuts.getKeyCombo('remove'),
      wrapCommand(app, e => {
        const nodeId = window._app.activeNode
        app.deleteNode(nodeId)
        e.preventDefault()
      })
    )

    combokeys.bindGlobal(
      Shortcuts.getKeyCombo('zoomIn'),
      wrapCommand(app, e => {
        app.setRoot(window._app.activeNode)
        e.preventDefault()
      })
    )

    combokeys.bindGlobal(
      Shortcuts.getKeyCombo('zoomOut'),
      wrapCommand(app, e => {
        if (app.state.rootNodeId) {
          const rootNode = app.rootNode()
          app.setRoot(rootNode.parent_id)
        }

        e.preventDefault()
      })
    )

    combokeys.bindGlobal(
      Shortcuts.getKeyCombo('moveUp'),
      wrapCommand(app, e => {
        if (!$(e.target).closest('li.node.read-only').length) {
          let li, src, dst
          li = $(e.target).closest('li.node')
          if (li.length) {
            src = Node.get(li.data('node-id'), app.state.entities.nodes)
          } else {
            // Probably a bookmark
            li = $(Misc.nodeEl(window._app.activeNode))
            src = Node.get(window._app.activeNode, app.state.entities.nodes)
          }

          const dstLi = Misc.prevNodeEl(li)
          if (dstLi.length) {
            dst = Node.get(dstLi.data('node-id'), app.state.entities.nodes)
            if (app.isNodeDragValid(src.id, dst.id)) {
              let newPrev
              const newParent = dst.parent_id
              // eslint-disable-next-line eqeqeq
              if (src.parent_id == dst.parent_id || src.parent_id == dst.id) {
                newPrev = dst.prev_id
              } else {
                newPrev = dst.id
              }

              Node.move(src, newParent, newPrev, app.getCommonParams(), () => {
                Node.focus(src.id, Text.savedSelection, app.getCommonParams())
              })
            }
          }
        }
        if (window.Intercom) {
          window.Intercom('trackEvent', 'moved-node')
        }
        e.stopPropagation()
        e.preventDefault()
      })
    )

    combokeys.bindGlobal(
      Shortcuts.getKeyCombo('moveDown'),
      wrapCommand(app, e => {
        if (!$(e.target).closest('li.node.read-only').length) {
          let div, li, src, dst
          li = $(e.target).closest('li.node')
          if (li.length) {
            div = li.find(
              '> .indented > .indent-wrapper > .node-content > div > div > div.title[contenteditable]'
            )
            src = Node.get(li.data('node-id'), app.state.entities.nodes)
            if (src.type !== 'bookmark') {
              Text.saveSelection(div.get(0))
            }
          } else {
            // Probably a bookmark
            li = $(Misc.nodeEl(window._app.activeNode))
            src = Node.get(window._app.activeNode, app.state.entities.nodes)
          }

          // console.log(src.title);
          const nextLi = Misc.nextNodeEl(li, true)
          if (nextLi.length) {
            let newPrev
            const origLevel = parseInt(li.attr('data-level'))
            const nextLevel = parseInt(nextLi.attr('data-level'))
            const next = Node.get(
              nextLi.data('node-id'),
              app.state.entities.nodes
            )
            const nextNextLi = Misc.nextNodeEl(nextLi)
            if (
              nextNextLi.length &&
              nextLevel === origLevel &&
              parseInt(nextNextLi.attr('data-level')) > origLevel
            ) {
              dst = Node.get(next.id, app.state.entities.nodes)
              newPrev = null
            } else if (nextLevel === origLevel) {
              dst = Node.get(src.parent_id, app.state.entities.nodes)
              newPrev = next.id
            } else {
              const parent = Node.get(src.parent_id, app.state.entities.nodes)
              dst = Node.get(parent.parent_id, app.state.entities.nodes)
              newPrev = parent.id
            }
            const newParent = dst ? dst.id : null

            if (app.isNodeDragValid(src.id, newParent)) {
              // console.log('move ' + src.id + ' to ' + newParent + ':' + newPrev);
              Node.move(src, newParent, newPrev, app.getCommonParams(), () => {
                Node.focus(src.id, Text.savedSelection, app.getCommonParams())
              })
            }
          }
        }
        if (window.Intercom) {
          window.Intercom('trackEvent', 'moved-node')
        }
        e.stopPropagation()
        e.preventDefault()
      })
    )

    combokeys.bindGlobal(
      Shortcuts.getKeyCombo('toggleExpansion'),
      wrapCommand(app, e => {
        let li = $(e.target).closest('li.node')
        if (!li.length && window._app.activeNode) {
          li = $(Misc.nodeEl(window._app.activeNode))
        }
        if (li.length) {
          // var savedSelection;
          const div = li.find(
            '> .indented > .indent-wrapper > .node-content > div > div > div.title[contenteditable]'
          )
          if (div.length) {
            Text.saveSelection(div.get(0))
            // savedSelection = Text.savedSelection;
          }
          const tab = app.tab()
          const currExpanded = tab.expanded_nodes || []
          const idx = currExpanded.indexOf(window._app.activeNode)
          const command = idx === -1 ? 'expand' : 'collapse'
          Tab[command](tab.id, window._app.activeNode, app.getCommonParams())
          // app.state.undo.add({undo: [{obj: 'Tab',
          //                             cmd: undoCommand,
          //                             args: [tab.id, window._app.activeNode]}],
          //                     redo: [{obj: 'Tab',
          //                             cmd: command,
          //                             args: [tab.id, window._app.activeNode]}]}, true);
          // const node = Node.get(window._app.activeNode, app.state.entities.nodes);
          // app.setAppState({entities: entities, updatedNodes: [node]}, {}, () => {
          //   Node.focus(window._app.activeNode, savedSelection, app.getCommonParams());
          // });
        }
        e.preventDefault()
      })
    )

    combokeys.bindGlobal(
      Shortcuts.getKeyCombo('toggleDescription'),
      wrapCommand(app, e => {
        if (!$(e.target).closest('li.node.read-only').length) {
          app.toggleDescription(window._app.activeNode)
        }

        e.preventDefault()
      })
    )

    // combokeys.bindGlobal(Shortcuts.getKeyCombo('cancelOrSearch'), wrapCommand(app, e => {
    combokeys.bindGlobal(
      Shortcuts.getKeyCombo('collapseOrCancel'),
      wrapCommand(app, e => {
        const menu = $('.select-menu.active')
        if (menu.length) {
          // passthrough
        } else if (app.state.assignNode) {
          app.setState({ assignNode: null })
        } else if (app.state.switcherOpen) {
          app.toggleSwitcher(e)
        } else if (app.state.editingFile) {
          app.closeFileEditor(e)
        } else if (app.state.browsingFiles) {
          app.closeFileBrowser(e)
        } else if (app.state.panel) {
          app.setPanel(null)
        } else {
          const hamburger = $('#hamburger.open')
          const ctxMenu = $('#floater.context-menu:visible')
          const ctrl = hamburger.length ? hamburger : ctxMenu
          if (ctrl.length) {
            ctrl.removeClass('open')
            app.setState({ floater: null })
          } else {
            const selected = $('.multi-selected')
            if (selected.length) {
              selected.removeClass('multi-selected')
            } else {
              const searchWithFocus = $('#search:focus')
              if (searchWithFocus.length) {
                app.setAppState(
                  {
                    activateSearch: false,
                    deActivateSearch: true,
                    searchstring: null
                  },
                  () => {
                    setTimeout(() => {
                      Node.focus(
                        window._app.activeNode,
                        Text.savedSelection,
                        app.getCommonParams()
                      )
                    }, 0)
                  }
                )
              } else {
                const focusEl = $(':focus')
                if (focusEl.length) {
                  const activeNode = Node.get(
                    window._app.activeNode,
                    app.state.entities.nodes
                  )
                  if (activeNode) {
                    let toggleMe
                    let doFocus = false
                    const activeLi = $(Misc.nodeEl(activeNode))
                    if (activeLi.length && activeLi.hasClass('expanded')) {
                      toggleMe = activeNode.id
                    } else {
                      if (activeNode.parent_id) {
                        const parent = Node.get(
                          activeNode.parent_id,
                          app.state.entities.nodes
                        )
                        if (parent) {
                          const parentLi = Misc.nodeEl(parent)
                          if (parentLi) {
                            toggleMe = parent.id
                            doFocus = true
                          }
                        }
                      }
                    }
                    if (toggleMe) {
                      app.toggleChildren(toggleMe, () => {
                        if (doFocus) {
                          Node.focus(
                            toggleMe,
                            Text.savedSelection,
                            app.getCommonParams()
                          )
                        }
                      })
                    }
                    Text.saveSelection(e.target)
                  }
                } else {
                  // Try to focus SOME node to fascilitate easy keyboard nav when returning from another screen etc.
                  const firstNode = $('.node[data-node-id]:first')
                  if (firstNode.length) {
                    firstNode.find('.heading, .title:first').focus()
                  }
                }
              }
            }
          }
        }

        e.preventDefault()
      })
    )

    combokeys.bindGlobal(
      Shortcuts.getKeyCombo('toggleContextMenu'),
      wrapCommand(app, e => {
        let active
        let nodeId = null
        const li = $(e.target).closest('li.node')
        if (li.length) {
          nodeId = li.data('node-id')
        } else {
          active = $('li.node.active')
          if (active.length && active.data('type') === 'bookmark') {
            nodeId = active.data('node-id')
          } else if (app.state.rootNodeId) {
            nodeId = app.state.rootNodeId
          }
        }
        if (nodeId) {
          app.toggleContextMenu(nodeId)
        }
        e.preventDefault()
      })
    )

    combokeys.bindGlobal(
      Shortcuts.getKeyCombo('browseFiles'),
      wrapCommand(app, e => {
        app.browseFiles(e)
        e.preventDefault()
      })
    )

    combokeys.bindGlobal(
      Shortcuts.getKeyCombo('openSwitcher'),
      wrapCommand(app, e => {
        app.toggleSwitcher(e)
        e.preventDefault()
      })
    )

    combokeys.bindGlobal(
      Shortcuts.getKeyCombo('insertInternalLink'),
      wrapCommand(app, e => {
        let mode = 'internal-link'
        let focusNode
        let opts = {}
        // TODO: Also check that the editor has focus, so that we can actually insert the link
        if (window._app.focusItem === 'editor') {
          mode = 'link'
        } else {
          focusNode = $('li.node .title:focus, .node-heading .heading:focus')
          if (focusNode.length) {
            opts.src = Node.get(
              focusNode.closest('.node').data('node-id'),
              app.state.entities.nodes
            )
          } else if (app.state.activeNodeId) {
            opts.src = Node.get(
              app.state.activeNodeId,
              app.state.entities.nodes
            )
          }
        }
        app.toggleSwitcher(e, mode, opts)
        e.preventDefault()
      })
    )

    combokeys.bindGlobal(
      Shortcuts.getKeyCombo('modifySelectionUp'),
      wrapCommand(app, e => {
        let prev
        if (/\btitle\b/.test(e.target.className)) {
          const li = $(e.target).closest('li.node')
          if (li.length) {
            Node.flushTextUndo(app.getCommonParams(), () => {
              if (Text.savedSelection && Text.savedSelection.start === 0) {
                const selected = $('.multi-selected')
                if (selected.length) {
                  prev = Misc.prevNodeEl(selected.eq(0).data('node-id'))
                  prev.addClass('multi-selected')
                  window._app.lastAddedToSelection = prev
                } else {
                  li.addClass('multi-selected')
                }

                e.preventDefault()
              }
            })
          }
        }
      })
    )

    combokeys.bindGlobal(
      Shortcuts.getKeyCombo('modifySelectionDown'),
      wrapCommand(app, e => {
        let div, next
        if (/\btitle\b/.test(e.target.className)) {
          const li = $(e.target).closest('li.node')
          if (li.length) {
            div = li.find(
              '> .indented > .indent-wrapper > .node-content > div > div > div.title[contenteditable]'
            )
            Node.flushTextUndo(app.getCommonParams(), () => {
              if (
                Text.savedSelection &&
                Text.savedSelection.end === div.text().length
              ) {
                const selected = $('.multi-selected')
                if (selected.length) {
                  const lastSelected = selected.eq(selected.length - 1)
                  const tmpLastNode = lastSelected.find('li.node').last()
                  const lastNode = tmpLastNode.length
                    ? tmpLastNode
                    : lastSelected
                  next = Misc.nextNodeEl(lastNode.data('node-id'))
                  next.addClass('multi-selected')
                  window._app.lastAddedToSelection = next
                } else {
                  li.addClass('multi-selected')
                }

                e.preventDefault()
              }
            })
          }
        }
      })
    )

    const orgModifier = NOTEBASE_IS_DESKTOP ? 'command' : 'ctrl'
    combokeys.bindGlobal(
      orgModifier + '+1',
      wrapCommand(app, () => {
        app.setOrg(null)
      })
    )

    combokeys.bindGlobal(
      orgModifier + '+2',
      wrapCommand(app, () => {
        if (app.state.entities.orgs) {
          const org = app.sortedOrgs()[0]
          if (org) {
            app.setOrg(org)
          }
        }
      })
    )

    combokeys.bindGlobal(
      orgModifier + '+3',
      wrapCommand(app, () => {
        if (app.state.entities.orgs) {
          const org = app.sortedOrgs()[1]
          if (org) {
            app.setOrg(org)
          }
        }
      })
    )

    combokeys.bindGlobal(
      orgModifier + '+4',
      wrapCommand(app, () => {
        if (app.state.entities.orgs) {
          const org = app.sortedOrgs()[2]
          if (org) {
            app.setOrg(org)
          }
        }
      })
    )

    combokeys.bindGlobal(
      orgModifier + '+5',
      wrapCommand(app, () => {
        if (app.state.entities.orgs) {
          const org = app.sortedOrgs()[3]
          if (org) {
            app.setOrg(org)
          }
        }
      })
    )

    combokeys.bindGlobal(
      orgModifier + '+6',
      wrapCommand(app, () => {
        if (app.state.entities.orgs) {
          const org = app.sortedOrgs()[4]
          if (org) {
            app.setOrg(org)
          }
        }
      })
    )

    combokeys.bindGlobal(
      orgModifier + '+7',
      wrapCommand(app, () => {
        if (app.state.entities.orgs) {
          const org = app.sortedOrgs()[5]
          if (org) {
            app.setOrg(org)
          }
        }
      })
    )

    combokeys.bindGlobal(
      orgModifier + '+8',
      wrapCommand(app, () => {
        if (app.state.entities.orgs) {
          const org = app.sortedOrgs()[6]
          if (org) {
            app.setOrg(org)
          }
        }
      })
    )

    combokeys.bindGlobal(
      orgModifier + '+9',
      wrapCommand(app, () => {
        if (app.state.entities.orgs) {
          const org = app.sortedOrgs()[7]
          if (org) {
            app.setOrg(org)
          }
        }
      })
    )

    combokeys.bindGlobal(
      Shortcuts.getKeyCombo('up'),
      wrapCommand(app, e => {
        window._app.automatedMode = false
        let active, prev, index, newActive, div
        const select = $('.select-menu.active')
        const hamburger = $('#hamburger.open')
        const ctxMenu = $('#floater.context-menu:visible')
        const ctrl = hamburger.length ? hamburger : ctxMenu
        const assigner = $('.assign-picker:visible')
        if (assigner.length) {
          const pickerItems = assigner.find('div')
          active = assigner.find('div.active')
          index = pickerItems.index(active)
          newActive = pickerItems[index - 1]
          if (newActive) {
            pickerItems.removeClass('active')
            $(newActive).addClass('active')
          }
          // const pickerIndex = app.state.assignPickerIndex || 0;
          // if (pickerIndex > 0) {
          //   const nodeEl = assigner.closest('li.node');
          //   const pickerChanges = {assignPickerIndex: pickerIndex - 1};
          //   if (nodeEl.length) {
          //     pickerChanges.updatedNodes = [nodeEl.data('node-id')];
          //   }
          //   app.setAppState(pickerChanges);
          // }
          e.preventDefault()
        } else if (select.length) {
          // passthrough
        } else if (app.state.switcherOpen) {
          // passthrough
        } else if (ctrl.length) {
          const menuitems = ctrl.find('li').not('.sep, .section')
          active = ctrl.find('li.active')
          index = menuitems.index(active)
          newActive = menuitems[index - 1]
          if (newActive) {
            menuitems.removeClass('active')
            $(newActive).addClass('active')
          }

          e.preventDefault()
        } else {
          $('.multi-selected').removeClass('multi-selected')
          if (/\btitle\b/.test(e.target.className)) {
            const li = $(e.target).closest('li.node')
            if (li.length) {
              div = li.find(
                '> .indented > .indent-wrapper > .node-content > div > div > div.title[contenteditable]'
              )
              // if (li.data('type') !== 'bookmark') {
              clearTimeout(window._app.titleUndoTimeout)
              // Node.setTextUndo(li.data('node-id'), 'title', div.text(), app.getCommonParams(), () => {
              $('li.node').removeClass('active')
              prev = Misc.prevNodeEl(li.data('node-id'))
              if (prev.length) {
                Misc.scrollIfNeeded(li, prev, 'up')
                app.setActiveNodeEl(prev)
                if (div.length) {
                  div.blur()
                }

                Node.focus(prev.data('node-id'), null, app.getCommonParams())

                // prev.find('> .indented > .indent-wrapper > .node-content > div > div[contenteditable]').focus();
              } else if (app.rootNodeId()) {
                $('#body-container .node-heading > span').focus()
                window.scrollTo(0, 0)
              } else {
                $('.root-heading > span').focus()
                window.scrollTo(0, 0)
              }
              setHasMoved(app)

              // });
              // }
              e.preventDefault()
            }
          } else {
            const headingEl = $('#body-container .node-heading > span:focus')
            if (headingEl.length) {
              const crumb = $('#breadcrumbs li.crumb:last')
              if (crumb.length) {
                app.setAppState({ activeNodeId: crumb.data('node-id') }, () => {
                  headingEl.blur()
                })
                e.preventDefault()
              }
            } else {
              if (app.state.rootIsBoard) {
                e.preventDefault()
                prev = Misc.prevNodeEl(app.state.activeNodeId)
                if (prev.length) {
                  app.setAppState({
                    activeNodeId: prev.data('node-id'),
                    activeNodeOnly: true
                  })
                }
              }
            }
            //   active = $('li.node.active');
            //   if (active.length) {
            //     if (active.data('type') === 'bookmark') {
            //       prev = Misc.prevNodeEl(active.data('node-id'));
            //       if (prev.length) {
            //         Misc.scrollIfNeeded(active, prev, 'up');
            //         clearTimeout(window._app.titleUndoTimeout);
            //         app.setActiveNodeEl(prev);
            //         Node.focus(prev.data('node-id'), null, app.getCommonParams());
            //         e.preventDefault();
            //       }
            //     }
            //   }
          }
        }
      })
    )

    combokeys.bindGlobal(
      Shortcuts.getKeyCombo('down'),
      wrapCommand(app, e => {
        window._app.automatedMode = false
        let active, next, index, newActive, div
        const select = $('.select-menu.active')
        const hamburger = $('#hamburger.open')
        const ctxMenu = $('#floater.context-menu:visible')
        const ctrl = hamburger.length ? hamburger : ctxMenu
        const assigner = $('.assign-picker:visible')
        const breadcrumb = $('#breadcrumbs li.active')
        if (assigner.length) {
          const pickerItems = assigner.find('> div')
          active = assigner.find('> div.active')
          index = pickerItems.index(active)
          newActive = pickerItems[index + 1]
          if (newActive) {
            pickerItems.removeClass('active')
            $(newActive).addClass('active')
          }

          // const pickerIndex = app.state.assignPickerIndex || 0;
          // const pickerChanges = {assignPickerIndex: pickerIndex + 1};
          // if (assigner.length) {
          //   const nodeEl = assigner.closest('li.node');
          //   if (nodeEl.length) {
          //     pickerChanges.updatedNodes = [nodeEl.data('node-id')];
          //   }
          // }
          // console.log(JSON.stringify(pickerChanges));
          // app.setAppState(pickerChanges);
          e.preventDefault()
        } else if (breadcrumb.length) {
          let focusNodeId = app.rootNodeId()
          if (focusNodeId) {
            Node.headingFocus(focusNodeId, null, app.getCommonParams())
          } else {
            focusNodeId = $('li.node:first').data('node-id')
            if (focusNodeId) {
              Node.focus(focusNodeId, null, app.getCommonParams())
            }
          }
          e.preventDefault()
        } else if (select.length) {
          // passthrough
        } else if (app.state.switcherOpen) {
          // passthrough
        } else if (ctrl.length) {
          const menuitems = ctrl.find('li').not('.sep, .section')
          active = ctrl.find('li.active')
          index = menuitems.index(active)
          newActive = menuitems[index + 1]
          if (newActive) {
            menuitems.removeClass('active')
            $(newActive).addClass('active')
          }
          e.preventDefault()
        } else {
          $('.multi-selected').removeClass('multi-selected')
          if (/\btitle\b/.test(e.target.className)) {
            const li = $(e.target).closest('li.node')
            if (li.length) {
              div = li.find(
                '> .indented > .indent-wrapper > .node-content > div > div > div.title[contenteditable]'
              )
              // window._app.original_title = div.text();
              // if (li.data('type') !== 'bookmark') {
              clearTimeout(window._app.titleUndoTimeout)
              // XXX: Use callback for code below?
              // Node.setTextUndo(li.data('node-id'), 'title', div.text(), app.getCommonParams());
              // }

              next = Misc.nextNodeEl(li.data('node-id'))
              if (next.length) {
                Misc.scrollIfNeeded(li, next, 'down')
                app.setActiveNodeEl(next)
                if (div.length) {
                  div.blur()
                }

                Node.focus(next.data('node-id'), null, app.getCommonParams())
                setHasMoved(app)

                // next.find('> .indented > .indent-wrapper > .node-content > div > div[contenteditable]').focus();
              }

              e.preventDefault()
            }
            // } else {
            //   active = $('li.node.active');
            //   if (active.length) {
            //     if (active.data('type') === 'bookmark') {
            //       next = Misc.nextNodeEl(active.data('node-id'));
            //       if (next.length) {
            //         clearTimeout(window._app.titleUndoTimeout);
            //         Misc.scrollIfNeeded(active, next, 'down');
            //         app.setActiveNodeEl(next);
            //         Node.focus(next.data('node-id'), null, app.getCommonParams());

            //         // next.find('> .indented > .indent-wrapper > .node-content > div > div[contenteditable]').focus();
            //         e.preventDefault();
            //       }
            //     }
            //   }
          } else {
            if (app.state.rootIsBoard) {
              e.preventDefault()
              next = Misc.nextNodeEl(app.state.activeNodeId)
              if (!next.length) {
                next = $('.kb-card.regular:first')
              }
              if (next.length) {
                app.setAppState({
                  activeNodeId: next.data('node-id'),
                  activeNodeOnly: true
                })
              }
            }
          }
        }
      })
    )

    combokeys.bindGlobal(
      Shortcuts.getKeyCombo('left'),
      wrapCommand(app, e => {
        const el = e.target
        if (!el || (el.tagName !== 'TEXTAREA' && el.tagName !== 'INPUT')) {
          const activeCrumb = $('#breadcrumbs li.active')
          if (activeCrumb.length) {
            e.preventDefault()
            const prev = activeCrumb.prevAll('.crumb')
            if (prev.length) {
              app.setAppState({ activeNodeId: prev.data('node-id') })
            }
          } else {
            if (app.state.rootIsBoard) {
              if (
                app.state.activeNodeId &&
                app.state.activeNodeId !== app.state.rootNodeId
              ) {
                const currNode = Node.get(
                  app.state.activeNodeId,
                  app.state.entities.nodes
                )
                if (
                  currNode &&
                  currNode.parent_id &&
                  currNode.parent_id !== app.state.rootNodeId
                ) {
                  const parentNode = Node.get(
                    currNode.parent_id,
                    app.state.entities.nodes
                  )
                  if (parentNode && parentNode.prev_id) {
                    const parentNodeEl = $(`[data-node-id="${parentNode.id}"]`)
                    if (parentNodeEl.length) {
                      const prev = parentNodeEl.prevAll(
                        '.kb-list-container.regular.open:first'
                      )
                      if (prev.length) {
                        const firstCard = prev.find('.kb-card.regular')
                        if (firstCard.length) {
                          app.setAppState({
                            activeNodeId: firstCard.data('node-id'),
                            activeNodeOnly: true
                          })
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      })
    )

    combokeys.bindGlobal(
      Shortcuts.getKeyCombo('right'),
      wrapCommand(app, e => {
        const el = e.target
        if (!el || (el.tagName !== 'TEXTAREA' && el.tagName !== 'INPUT')) {
          const activeCrumb = $('#breadcrumbs li.active')
          if (activeCrumb.length) {
            e.preventDefault()
            const next = activeCrumb.nextAll('.crumb')
            if (next.length) {
              app.setAppState({ activeNodeId: next.data('node-id') })
            }
          } else {
            if (app.state.rootIsBoard) {
              if (
                app.state.activeNodeId &&
                app.state.activeNodeId !== app.state.rootNodeId
              ) {
                const currNode = Node.get(
                  app.state.activeNodeId,
                  app.state.entities.nodes
                )
                if (
                  currNode &&
                  currNode.parent_id &&
                  currNode.parent_id !== app.state.rootNodeId
                ) {
                  const parentNode = Node.get(
                    currNode.parent_id,
                    app.state.entities.nodes
                  )
                  if (parentNode) {
                    e.preventDefault()
                    const parentNodeEl = $(`[data-node-id="${parentNode.id}"]`)
                    if (parentNodeEl.length) {
                      const next = parentNodeEl.nextAll(
                        '.kb-list-container.regular.open:first'
                      )
                      if (next.length) {
                        const firstCard = next.find('.kb-card.regular')
                        if (firstCard.length) {
                          app.setAppState({
                            activeNodeId: firstCard.data('node-id'),
                            activeNodeOnly: true
                          })
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      })
    )

    combokeys.bindGlobal(
      Shortcuts.getKeyCombo('quickAdd'),
      wrapCommand(app, e => {
        app.setState({ quickAdding: !app.state.quickAdding })
      })
    )

    // if (NOTEBASE_IS_DESKTOP) {
    //   combokeys.bindGlobal(Shortcuts.getKeyCombo('reloadApp'), wrapCommand(app, () => {
    //     location.reload(true);
    //   }));
    // }
  }
}
