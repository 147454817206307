var Node = require('../js/node')
var _ = require('lodash')

module.exports = {
  palette: function (app) {
    function getActiveNode () {
      if (window._app.activeNode && window._app.focusItem === 'node') {
        return Node.get(window._app.activeNode, app.state.entities.nodes)
      }
    }

    var viewModeText = app.isListView() ? 'Set View Mode: Tree' : 'Set View Mode: List'
    var commands = {

      openSettings: ['Open Settings', function () {
        app.setPanel('user-settings-overlay', 'overlay', app.user(), 'User settings')
      }],

      openFileBrowser: ['Open File Browser', function () {
        app.browseFiles()
      }],

      toggleInbox: ['Toggle Inbox', function () {
        app.toggleInbox()
      }],

      toggleSidebar: ['Toggle Sidebar', function () {
        app.toggleSidebar()
      }],

      toggleDueList: ['Toggle Due List', function () {
        app.toggleDueList()
      }],

      toggleViewMode: app.featureEnabled('list_mode') && [viewModeText, function () {
        app.toggleViewMode()
      }],

      manageOrgs: ['Manage Organizations', function () {
        app.setPanel('orgs-overlay', 'overlay', app.user(), 'Manage organizations')
      }],

      sharedWithMe: ['List Shares I Have Access To', function () {
        app.setPanel('shares-overlay', 'overlay', app.user(), 'Shared with me')
      }],

      inviteFriends: ['Invite Your Friends', function () {
        app.setPanel('invite-beta-overlay', 'overlay', app.user(), 'Invite your friends')
      }],

      addOrg: ['Add Organization', function () {
        app.setPanel('orgs-overlay', 'overlay', {mode: 'new'}, 'Manage organizations')
      }],

      closeCurrentTab: ['Tab: Close Current Tab', function () {
        app.closeTab(app.tabId())
      }],

      toggleNodeEmphasis: ['List Item: Toggle Emphasis', function () {
        var activeNode = getActiveNode()
        if (activeNode) {
          app.toggleEmphasis(activeNode)
        }
      }],

      toggleNodeCompleted: ['List Item: Toggle Completed', function () {
        var activeNode = getActiveNode()
        if (activeNode) {
          app.toggleCompleted(activeNode)
        }
      }]

    }

    return _.pick(commands, Object.keys(commands).filter(function (c) { return commands[c] }))
  }
}
