import React from 'react'

class Confirmation extends React.Component {
  confirm = () => {
    this.props.doConfirm()
  }

  decline = () => {
    this.props.doDecline()
  }

  render () {
    return (
      <div id='confirmation'>
        <div className='box'>
          <div className='message'>{this.props.message}</div>
          <div className='buttons'>
            <button className='btn confirm' onClick={this.confirm}>
              Yes
            </button>
            <button className='btn decline' onClick={this.decline}>
              No
            </button>
          </div>
        </div>
      </div>
    )
  }
}

module.exports = Confirmation
