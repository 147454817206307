import './styles.css'

import React from 'react'

import SetPlaceholder from '../../set_placeholder'

export default class StoryPoints extends React.Component {
  render () {
    const { app, node } = this.props
    const data = node.data || {}
    return (
      <div
        onClick={ev =>
          app.toggleFloater(ev.target, 'generic-picker', {
            fieldType: 'number',
            field: 'data.story_points',
            nodeId: node.id
          })
        }
      >
        {data.story_points && (
          <div className='actual-data'>{data.story_points}</div>
        )}
        {!data.story_points && <SetPlaceholder />}
      </div>
    )
  }
}
