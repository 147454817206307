import './styles.css'

import React from 'react'
import $ from 'jquery'
import classNames from 'classnames'
import strftime from 'strftime'
import moment from 'moment'
import Chrono from 'chrono-node'
import ReactTooltip from 'react-tooltip'

// var Assigner = require('./assigner');

import Misc from '../../js/misc'
import Node from '../../js/node'
// import DatePicker from '../datepicker'
import Avatar from '../avatar'

class NodeMeta extends React.Component {
  state = {
    settingDueDate: false,
    startDate: moment(),
    tooltipsRendered: false
  }

  toggleAssigner = ev => {
    const target = ev.target
    this.props.app.toggleFloater(
      target,
      'assigner',
      { mode: 'node-assign', nodeId: this.props.node.id },
      () => {
        // The "if" is to avoid the target disappearing from under us, making Popper
        // confused about where to put the floater (for the current root node)
        if (this.props.node.id !== this.props.app.rootNodeId()) {
          this.props.app.setAppState({
            assignNode: this.props.app.state.assignNode
              ? null
              : this.props.node.id,
            updatedNodes: [this.props.node]
          })
        }
      }
    )
  }

  toggleDueDatePicker = ev => {
    const target = ev.target
    this.props.app.toggleFloater(
      target,
      'date-picker',
      { mode: 'node-due', nodeId: this.props.node.id },
      () => {
        // The "if" is to avoid the target disappearing from under us, making Popper
        // confused about where to put the floater (for the current root node)
        if (this.props.node.id !== this.props.app.rootNodeId()) {
          this.props.app.setAppState({
            dueNode: this.props.app.state.dueNode ? null : this.props.node.id,
            updatedNodes: [this.props.node]
          })
        }
      }
    )
  }

  clearDueDate = () => {
    this.props.app.setDueDate(this.props.node, null)
  }

  dueDateKeyDown = ev => {
    var input = $(ev.target)
    var li = input.closest('li.node')
    var date
    var setDate = false
    if (ev.keyCode === 13) {
      if (ev.target.value) {
        date = Date.parse(ev.target.value)
        if (!date) {
          try {
            var chronoDate = Chrono.parse(ev.target.value)
            if (chronoDate && chronoDate[0]) {
              date = chronoDate[0].start.date()
            }
          } catch (e) {
            console.error(e) // eslint-disable-line no-console
          }
        }
      } else {
        date = null
        setDate = true
      }

      if (date) {
        setDate = true
      } else {
        console.log('No date') // eslint-disable-line no-console
      }

      if (setDate) {
        this.props.app.setDueDate(this.props.node, date, function () {
          li.find('div.title:first').focus()
        })
      }

      ev.preventDefault()
    }
  }

  openShareOverlay = () => {
    const { app, node } = this.props
    app.setPanel('share-overlay', {
      data: { node: node },
      title: 'Share "' + Misc.truncate(node.title, 30) + '"'
    })
  }

  render () {
    let assignedTo
    const indicators = []
    const content = []

    const { app, node, user } = this.props
    const { entities } = app.state

    // published sites and notes. Might also include bookmark link in the future!
    if (node.type === 'site' || node.settings.published) {
      content.push(
        <span key='published' className='published-item'>
          <a href={app.pubUrl(node)} target='_blank' rel='noopener noreferrer'>
            <i className='fa fa-fw fa-link' />
          </a>
          <div className='tooltip link'>
            <div>
              <b>Link:</b>{' '}
              <a
                href={app.pubUrl(node)}
                target='_blank'
                rel='noopener noreferrer'
              >
                {app.pubUrl(node)}
              </a>
            </div>
          </div>
        </span>
      )
    }

    let sharedNode
    if (user) {
      // Share / shared
      sharedNode = Node.getShare(
        node,
        app.state.entities.shares,
        app.state.userId,
        app.state.orgId
      )
      let sharedItemTooltip

      const sharedNodeClasses = classNames({
        'share-indicator': true,
        shared: sharedNode
      })

      if (sharedNode) {
        let tooltipContent
        const tooltipClasses = classNames({
          tooltip: true,
          link: true
        })
        let link = `${app.appUrl()}/shared/${sharedNode.secret_id}`
        tooltipContent = (
          <div>
            <b>Link:</b>{' '}
            <a href={link} target='_blank'>
              {link}
            </a>
          </div>
        )

        sharedItemTooltip = (
          <div className={tooltipClasses}>{tooltipContent}</div>
        )
        content.push(
          <span
            key='shared'
            className={sharedNodeClasses}
            data-tip
            data-for={`share-${node.id}`}
            onClick={this.openShareOverlay}
          >
            <i className='fa fa-fw fa-share-alt' />
            {sharedItemTooltip}
          </span>
        )
      }
    }

    // {Boolean(!sharedNode) && sharedItem}
    // {Boolean(!node.like_count) && likes}
    // {Boolean(!node.comment_count) && comments}
    // {publishedItem}
    // {Boolean(sharedNode) && sharedItem}
    // {Boolean(
    //   hasContent &&
    //     !dueDate &&
    //     app.featureEnabled('due_nodes', this.props.user)
    // ) && dueDateIndicator}
    // {Boolean(node.like_count) && likes}
    // {Boolean(node.comment_count) && comments}
    // {Boolean(
    //   !hasContent &&
    //     !dueDate &&
    //     app.featureEnabled('due_nodes', this.props.user)
    // ) && dueDateIndicator}
    // {dueDate}

    // Attachments
    var attachmentClasses = classNames({
      'attachment-indicator': true,
      'has-attachments': node.attachment_count > 0
    })
    const attachments = (
      <span
        key='attachments'
        data-tip
        data-for={`attachments-${node.id}`}
        className={attachmentClasses}
      >
        {Boolean(node.attachment_count) && (
          <span className='num-of-attachments'>{node.attachment_count}</span>
        )}
        <i
          className={`fa fa-fw fa-paperclip`}
          onClick={() => app.setSummaryNode(node.id)}
        />
      </span>
    )
    if (node.attachment_count) {
      content.push(attachments)
    }

    // Likes
    const userLikesNode =
      node.like_count && user
        ? entities.likes.find(
          e => e.node_id === node.id && e.user_id === user.id
        )
        : false
    const likeHeart = userLikesNode ? 'heart' : 'heart-o'
    var likeClasses = classNames({
      'like-indicator': true,
      'has-likes': node.like_count > 0,
      'has-liked': Boolean(userLikesNode)
    })
    const likes = (
      <span
        key='likes'
        data-tip
        data-for={`likes-${node.id}`}
        className={likeClasses}
      >
        {Boolean(node.like_count) && (
          <span className='num-of-likes'>{node.like_count}</span>
        )}
        <i
          className={`fa fa-fw fa-${likeHeart}`}
          onClick={() =>
            !user || userLikesNode
              ? app.setLikesNode(node.id)
              : app.toggleLike(node.id)
          }
        />
      </span>
    )
    if (node.like_count) {
      content.push(likes)
    } else {
      indicators.push(likes)
    }

    var commentClasses = classNames({
      'comment-indicator': true,
      'has-comments': node.comment_count > 0,
      'is-open': app.state.commentNodeId === node.id
    })
    var commentCount = node.comment_count ? (
      <span className='num-of-comments'>{node.comment_count}</span>
    ) : (
      ''
    )
    const comments = (
      <span
        key='comments'
        className={commentClasses}
        data-tip
        data-for={`comments-${node.id}`}
        onClick={() => app.setCommentNode(node.id)}
      >
        {commentCount}
        <i className='fa fa-fw fa-comment-o' />
      </span>
    )

    if (node.comment_count || app.state.commentNodeId === node.id) {
      content.push(comments)
    } else {
      indicators.push(comments)
    }

    if (app.featureEnabled('due_nodes', this.props.user)) {
      // var dueDateDate, dueStr
      var dueStr

      // if (app.state.dueNode === node.id) {
      //   // <div className={dueClasses}><input type="text" id="due-date-input" onKeyDown={this.dueDateKeyDown} defaultValue={this.props.node.due_at} placeholder="today, fri, 12/1/11, aug 22" /></div>
      //   dueDateDate = node.due_at ? moment(node.due_at) : null
      //   content.push(
      //     <DatePicker
      //       className='date-picker'
      //       key='due-date'
      //       selected={dueDateDate}
      //       focus
      //       placeholder='today, fri, 12/1/11, aug 22'
      //       onChange={this.handleDueDateSelect}
      //       onClickOutside={this.handleClickOutsideDatepicker}
      //       onClear={this.clearDueDate}
      //     />
      //   )
      // } else if (
      if (node.due_at && !Misc.hideWidgets(user, this.props.tab)) {
        var fmt = '%b %e'
        var now = new Date()
        var nowStr = strftime(fmt, now)
        var due = node.due_at
        if (typeof due === 'string') {
          var dueParsed = Chrono.parse(due)
          due = dueParsed[0].start.date()
        }

        dueStr = Misc.dueStr(due)
        var veryOverdue = Boolean(
          due.getTime() + 86400 * 1000 * 7 < now.getTime()
        )
        var overdue = Boolean(due < now)
        var today = Boolean(nowStr === dueStr)
        var dueClasses = classNames({
          'due-date': true,
          today: today,
          overdue: !veryOverdue && !today && overdue,
          'very-overdue': veryOverdue
        })
        // <div onClick={this.toggleDueDatePicker}><i className="fa fa-fw fa-calendar"></i> <span className="date-value">{dueStr}</span></div> <i onClick={this.clearDueDate} className="fa fa-fw fa-close"></i>
        content.push(
          <div
            key='due-date'
            data-tip
            data-for={`due-date-${node.id}`}
            className={dueClasses}
          >
            <div onClick={user ? this.toggleDueDatePicker : null}>
              <span className='date-value'>{dueStr}</span>
            </div>
            {/*
            <i
              onClick={this.clearDueDate}
              className='fa fa-fw fa-close close'
            />
            */}
          </div>
        )
      } else {
        // indicators.push(
        //   <DatePicker
        //     className='date-picker'
        //     key='due-date'
        //     selected={dueDateDate}
        //     focus
        //     placeholder='today, fri, 12/1/11, aug 22'
        //     onChange={this.handleDueDateSelect}
        //     onClickOutside={this.handleClickOutsideDatepicker}
        //     onClear={this.clearDueDate}
        //   />
        // )
        indicators.push(
          <span
            key='due-date'
            className='due-date-indicator'
            data-tip
            data-for={`due-date-${node.id}`}
            onClick={user ? this.toggleDueDatePicker : null}
          >
            <i className='fa fa-fw fa-clock-o' />
          </span>
        )
      }
    }

    if (app.featureEnabled('assign_to')) {
      const users = app.state.entities.node_users.filter(
        e => e.node_id === node.id
      )
      if (users.length) {
        assignedTo = (
          <div
            className={`assigned-to ${users.length ? 'multiple' : ''}`}
            onClick={user ? this.toggleAssigner : null}
          >
            {users.map(u => {
              const user = app.state.entities.users.find(
                e => e.id === u.assigned_to
              )
              return <Avatar key={u.id} app={app} user={user} size='small' />
            })}
          </div>
        )
      }
    }

    const summaryToggler = (
      <span
        data-tip
        data-for={`summary-${node.id}`}
        onClick={() => app.setSummaryNode(node)}
      >
        <i className='fa fa-fw fa-bars' />
      </span>
    )

    return (
      <div
        className={`node-meta ${app.state.dueNode === node.id ? 'active' : ''}`}
        onMouseOver={() =>
          !this.state.tooltipsRendered &&
          this.setState({ tooltipsRendered: true })
        }
      >
        {indicators}
        {content}
        {Boolean(
          !assignedTo && app.featureEnabled('assign_to', this.props.user)
        ) && (
          <span
            className={`assign-to-indicator ${
              content.length ? 'show-faded' : ''
            }`}
            data-tip
            data-for={`assign-to-${node.id}`}
            onClick={user ? this.toggleAssigner : null}
          >
            <i className='fa fa-fw fa-user' />
          </span>
        )}
        {assignedTo}
        {summaryToggler}

        {this.state.tooltipsRendered && (
          <div className='tooltips'>
            {!sharedNode && (
              <ReactTooltip
                id={`share-${node.id}`}
                type='dark'
                effect='solid'
                data-delay-show='1000'
              >
                <span>{sharedNode ? 'Shared' : 'Share'}</span>
              </ReactTooltip>
            )}
            {this.props.app.featureEnabled('assign_to', this.props.user) && (
              <ReactTooltip
                id={`assign-to-${node.id}`}
                type='dark'
                effect='solid'
                data-delay-show='1000'
              >
                <span>Assign to a team member</span>
              </ReactTooltip>
            )}
            {this.props.app.featureEnabled('due_nodes', this.props.user) && (
              <ReactTooltip
                id={`due-date-${node.id}`}
                type='dark'
                effect='solid'
                data-delay-show='1000'
              >
                <span>Due date</span>
              </ReactTooltip>
            )}
            <ReactTooltip
              id={`comments-${node.id}`}
              type='dark'
              effect='solid'
              data-delay-show='1000'
            >
              <span>Comment</span>
            </ReactTooltip>
            <ReactTooltip
              id={`likes-${node.id}`}
              type='dark'
              effect='solid'
              data-delay-show='1000'
            >
              <span>Like</span>
            </ReactTooltip>
            <ReactTooltip
              id={`fave-${node.id}`}
              type='dark'
              effect='solid'
              data-delay-show='1000'
            >
              <span>Favorite</span>
            </ReactTooltip>
            <ReactTooltip
              id={`summary-${node.id}`}
              type='dark'
              effect='solid'
              data-delay-show='1000'
            >
              <span>Open summary in Sidebar</span>
            </ReactTooltip>
          </div>
        )}
      </div>
    )
  }
}

module.exports = NodeMeta
