import React from 'react'

class AnalyticsSection extends React.Component {
  componentDidMount () {}

  render () {
    var content = <div />

    if (this.props) {
      content = <div className='form'>analytics section</div>
    }

    return content
  }
}

module.exports = AnalyticsSection
