import React from 'react'

import Node from '../../../js/node'

class ExportOverlay extends React.Component {
  state = {
    type: 'full',
    src: this.props.panel.opts.data.node.id,
    exportedData: null
  }

  handleSrcChange = e => {
    this.setState({ src: e.target.value, exportedData: null }, function () {
      this.doExport()
    })
  }

  handleTypeChange = e => {
    this.setState({ type: e.target.value, exportedData: null }, function () {
      this.doExport()
    })
  }

  componentDidMount () {
    this.doExport()
  }

  doExport = () => {
    var _this = this
    _this.setState({
      exportedData: Node.export(
        this.state.src,
        this.state.type,
        this.props.app.state.entities.nodes
      )
    })
  }

  close = () => {
    this.props.app.setPanel(null)
  }

  render () {
    var exportedData
    if (this.state.exportedData) {
      exportedData = (
        <textarea value={this.state.exportedData} readOnly='true' />
      )
    } else {
      exportedData = (
        <div className='placeholder'>Exporting, please wait...</div>
      )
    }
    var node = this.props.panel.opts.data.node
    return (
      <div id='export'>
        <div className='row'>
          <div className='row-heading'>
            <label>What do you want to export?</label>
          </div>
          <div className='row-content'>
            <div className='input-label-wrapper'>
              <input
                type='radio'
                name='src'
                value={node.id}
                checked={this.state.src === node.id}
                onChange={this.handleSrcChange}
                id='this-node-and-its-children'
              />{' '}
              <label htmlFor='this-node-and-its-children'>
                This node and its children
              </label>
            </div>
            <div className='input-label-wrapper'>
              <input
                type='radio'
                name='src'
                value=''
                checked={!this.state.src}
                onChange={this.handleSrcChange}
                id='my-entrie-notebase'
              />{' '}
              <label htmlFor='my-entrie-notebase'>My entire Notebase</label>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='row-heading'>
            <label>How much do you want to export?</label>
          </div>
          <div className='row-content'>
            <div className='input-label-wrapper'>
              <input
                type='radio'
                name='type'
                value='basic'
                checked={this.state.type === 'basic'}
                id='type_basic'
                onChange={this.handleTypeChange}
              />
              <label htmlFor='type_basic'>
                <b>Basic</b> - Titles only, suitable for pasting a list into
                other programs.
              </label>
            </div>
            <div className='input-label-wrapper'>
              <input
                type='radio'
                name='type'
                value='full'
                checked={this.state.type === 'full'}
                id='type_full'
                onChange={this.handleTypeChange}
              />
              <label htmlFor='type_full'>
                <b>Full</b> - Titles, descriptions, URLs and note contents.
              </label>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='row-heading'>
            <label>Export:</label>
          </div>
          <div className='row-content'>{exportedData}</div>
        </div>

        <div className='buttons'>
          <button onClick={this.close} className='button default'>
            Close
          </button>
        </div>
      </div>
    )
  }
}

module.exports = ExportOverlay
