import React from 'react'
import Node from '../../../../../js/node'

import './styles.css'

export default class Todo extends React.Component {
  render () {
    const { app, node } = this.props
    const children = Node.children(node, app.state.entities.nodes)
    const doneCount = children.filter(e => e.completed).length
    const totalCount = children.length
    return (
      <table style={{ width: '100%' }}>
        <tbody>
          <tr>
            <td style={{ width: '100%' }}>
              <div className='percentage'>
                <span
                  className={'pct-' + parseInt((doneCount / totalCount) * 100)}
                />
              </div>
            </td>
            <td style={{ whiteSpace: 'noWrap' }}>
              {doneCount} / {totalCount}
            </td>
          </tr>
        </tbody>
      </table>
    )
  }
}
