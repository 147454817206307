import React from 'react'

import './styles.css'

export default class Heading extends React.Component {
  render () {
    const { app, bgImage } = this.props
    const styles = bgImage ? { backgroundColor: bgImage.color } : {}
    return (
      <div className='panel'>
        <div className='panel-heading' style={styles}>
          {Boolean(
            this.props.isMain &&
              (app.state.sidebarOpen || app.state.sidebarStack.length > 1)
          ) && (
            <div className='back' onClick={app.popSidebar}>
              <i className='fa fa-fw fa-arrow-left' />
            </div>
          )}
          <div className='heading-text'>
            {Boolean(this.props.children) && this.props.children}
            {Boolean(!this.props.children) && <h4>{this.props.name}</h4>}
          </div>
          {this.props.isMain && (
            <div className='close close-summary' onClick={app.toggleSidebar}>
              <i className='fa fa-fw fa-close' />
            </div>
          )}
        </div>
      </div>
    )
  }
}
