import React, { Component } from 'react'
import classNames from 'classnames'
import _ from 'lodash'

import Node from '../../js/node'

import Bullet from '../bullet'
import BoardCard from '../board_card'
import BoardSortOrder from './board_sort_order'

class BoardList extends Component {
  state = {
    editedTitle: '',
    isEditing: false,
    isSettingsOpen: false
  }

  startEditing = () => {
    const { listNode } = this.props
    this.setState({ isEditing: true, editedTitle: listNode.title }, () =>
      this.editInput.focus()
    )
  }

  finishEditing = () => {
    const { app, listNode } = this.props
    const title = this.state.editedTitle
    Node.modify(listNode.id, { title }, app.getCommonParams())
    this.setState({ isEditing: false, editedTitle: '' })
  }

  isCollapsed = () => {
    const { app, listNode } = this.props
    const tab = app.tab()
    return (
      tab &&
      tab.collapsed_board_nodes &&
      tab.collapsed_board_nodes.indexOf(listNode.id) !== -1
    )
  }

  addCard = () => {
    const { listNode } = this.props
    const nodes = this.getOrderedCards(
      this.props.orgNodes.filter(n => n.parent_id === listNode.id)
    )
    const last = nodes[nodes.length - 1]
    const commonParams = this.props.app.getCommonParams()
    const newNode = Node.new(
      {
        title: this.state.newCardTitle,
        parent_id: listNode.id,
        prev_id: last ? last.id : null,
        user_id: listNode.user_id,
        org_id: listNode.org_id
      },
      commonParams
    )
    Node.add(newNode, commonParams, () => {
      this.setState({ newCardTitle: '' }, () => {
        this.newCardInput.focus()
        this.cards.scrollTop = this.cards.scrollHeight
      })
    })
  }

  handleKeyDown = ev => {
    if (ev.which === 13) {
      ev.preventDefault()
      ev.stopPropagation()
      if (this.state.isEditing) {
        this.finishEditing()
      } else {
        this.addCard()
      }
    }
  }

  handleMouseDown = ev => {
    if (ev.nativeEvent.which === 1) {
      const { listNode } = this.props
      window._app.dragJustStarted = true
      window._app.boardListDragId = listNode.id
      window._app.boardListDragEl = this.el
    }
  }

  getOrderedCards = () => {
    const { listNode, orgNodes } = this.props
    const nodes = orgNodes.filter(n => n.parent_id === listNode.id)
    const orderedItems = []
    let next = nodes.find(i => !i.prev_id)
    while (next) {
      orderedItems.push(next)
      next = nodes.find(i => i.prev_id === next.id)
    }
    return orderedItems
  }

  getSortedCards = () => {
    const { listNode, orgNodes } = this.props
    const settings = listNode.settings || {}
    const mainSortby = settings.board_sortby
    const secSortby = settings.board_sortby_secondary
    const sorter = secSortby ? [mainSortby, secSortby] : [mainSortby]
    const sorted = _.sortBy(
      orgNodes.filter(n => n.parent_id === listNode.id),
      sorter
    )
    const ascTypes = ['title', 'completed']
    const returnReversed = ascTypes.indexOf(mainSortby) === -1
    return returnReversed ? sorted.reverse() : sorted
  }

  getCards = () => {
    const { app, listNode } = this.props
    const allCards = listNode.settings.board_sortby
      ? this.getSortedCards()
      : this.getOrderedCards()
    return Node.filter(app, app.state.filter, allCards)
  }

  render () {
    const { app, board, listNode } = this.props
    const isCollapsed = this.isCollapsed()
    const showSettings = listNode.settings.board_sortby
    const cardNodes = isCollapsed ? [] : this.getCards()
    // const users = app.state.entities.node_users.filter(
    //   e => e.node_id === listNode.id
    // )
    const classes = classNames({
      node: true,
      'kb-list-container': true,
      regular: true,
      empty: !cardNodes.length,
      collapsed: isCollapsed,
      open: !isCollapsed
    })

    return (
      <div
        className={classes}
        ref={el => {
          this.el = el
        }}
        data-node-id={listNode.id}
      >
        <div className='kb-list'>
          <div className='kb-list-title'>
            <Bullet app={app} node={listNode} />
            {isCollapsed && (
              <div
                className='expander'
                onClick={() => app.toggleBoardChildren(listNode)}
              >
                <i className='fa fa-fw fa-arrows-h' />
              </div>
            )}
            {!this.state.isEditing && (
              <div
                className='text-container'
                onMouseDown={this.handleMouseDown}
                onClick={() => this.props.board.openNode(listNode)}
              >
                {!isCollapsed && (
                  <div className='actions'>
                    <span
                      title='Edit'
                      onClick={ev => {
                        ev.stopPropagation()
                        this.startEditing()
                      }}
                    >
                      <i className='fa fa-fw fa-pencil' />
                    </span>
                    <span
                      title='Collapse'
                      onClick={ev => {
                        ev.stopPropagation()
                        app.toggleBoardChildren(listNode)
                      }}
                    >
                      <i className='fa fa-fw fa-arrows-v' />
                    </span>
                    {!showSettings && (
                      <span
                        title='Settings'
                        onClick={ev => {
                          ev.stopPropagation()
                          this.setState({
                            isSettingsOpen: !this.state.isSettingsOpen
                          })
                        }}
                      >
                        <i className='fa fa-fw fa-gear' />
                      </span>
                    )}
                  </div>
                )}
                <div className='title'>{listNode.title}</div>
                {Boolean(
                  !this.state.isEditing &&
                    (this.state.isSettingsOpen || showSettings)
                ) && (
                  <div className='kb-settings'>
                    <BoardSortOrder
                      app={app}
                      listNode={listNode}
                      text='Sort by'
                      fieldName='board_sortby'
                      nullName='Nothing (manually)'
                    />
                    {listNode.settings.board_sortby && (
                      <BoardSortOrder
                        app={app}
                        listNode={listNode}
                        text='Then by'
                        fieldName='board_sortby_secondary'
                        skip={[listNode.settings.board_sortby]}
                        nullName='Unspecified'
                      />
                    )}
                  </div>
                )}
              </div>
            )}
            {Boolean(!isCollapsed && this.state.isEditing) && (
              <div className='text-container'>
                <textarea
                  ref={editInput => {
                    this.editInput = editInput
                  }}
                  value={this.state.editedTitle}
                  onChange={ev =>
                    this.setState({ editedTitle: ev.target.value })
                  }
                  onKeyDown={this.handleKeyDown}
                />
              </div>
            )}
          </div>
          {!isCollapsed && (
            <div
              className='kb-cards'
              ref={cards => {
                this.cards = cards
              }}
            >
              {cardNodes.map(n => (
                <BoardCard
                  app={app}
                  board={board}
                  boardNode={listNode}
                  key={n.id}
                  listNode={listNode}
                  cardNode={n}
                />
              ))}
              <div className='kb-pusher' />
            </div>
          )}
          {!isCollapsed && (
            <div className='kb-card add-new'>
              <textarea
                ref={newCardInput => {
                  this.newCardInput = newCardInput
                }}
                placeholder='Add a card...'
                value={this.state.newCardTitle}
                onChange={ev =>
                  this.setState({ newCardTitle: ev.target.value })
                }
                onKeyDown={this.handleKeyDown}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default BoardList
