import React from 'react'
import createReactClass from 'create-react-class'
import _ from 'lodash'

import Misc from '../../../js/misc'
import Node from '../../../js/node'
import SelectMenu from '../../select_menu'

const NodeSettingsOverlay = createReactClass({
  displayName: 'NodeSettingsOverlay',

  getInitialState: function () {
    this.node = this.props.panel.opts.data.node
    this.nodes = this.props.app.state.entities.nodes
    this.site = Node.site(this.node, this.nodes)
    var slugObj = Node.slug(this.node, this.site)
    var slug = slugObj
      ? slugObj.slug
      : Misc.slugify(Misc.truncate(this.node.title, 40))
    var state = {
      published: this.node.settings.published,
      slug: slug,
      comment_provider: this.node.settings.comment_provider,
      theme: this.node.settings.theme,
      site_type: this.node.settings.site_type,
      treat_as_category: this.node.settings.treat_as_category,
      error: null
    }
    if (!this.site) {
      state.site_type = state.site_type || 'blog'
      state.theme = state.theme || 'minimal'
    }
    return state
  },

  UNSAFE_componentWillMount: function () {
    this.handleFormFieldChange = Misc.handleFormFieldChange.bind(this, this)
    this.errorFor = Misc.errorFor.bind(this, this)
  },

  handleSlugChange: function (ev) {
    this.setState({ slug: Misc.slugify(ev.target.value) })
  },

  handleCommentProviderChange: function (provider) {
    this.setState({ comment_provider: provider.value })
  },

  handleThemeChange: function (theme) {
    this.setState({ theme: theme.value })
  },

  handlePublishedChange: function (ev) {
    this.setState({ published: ev.target.checked })
  },

  handleTreatAsCategoryChange: function (ev) {
    this.setState({ treat_as_category: ev.target.checked })
  },

  save: function () {
    var newSettings = _.omit(this.state, ['error'])
    if (!this.state.published) {
      newSettings.treat_as_category = this.treatAsCategory.checked
    }
    Node.modify(
      this.props.panel.opts.data.node.id,
      { settings: _.omit(this.state, ['error']) },
      this.props.app.getCommonParams(),
      () => {
        this.setState({ error: null })
        this.props.app.setPanel(null)
      }
    )
  },

  render: function () {
    var publicSettings, treatAsCategory
    if (this.state.published) {
      var siteTypes = [
        { value: 'blog', name: 'Blog' },
        { value: 'wiki', name: 'Wiki' },
        { value: 'kb', name: 'Knowledge Base' }
      ]
      var themes = [
        { value: 'minimal', name: 'Minimalist' },
        { value: 'shiny', name: 'Shiny' }
      ]
      var commentProviders = [
        { value: '-none-', name: 'None' },
        { value: 'disqus', name: 'Disqus' },
        { value: 'fb', name: 'Facebook' }
      ]
      var site = Node.site(
        this.props.panel.opts.data.node,
        this.props.app.state.entities.nodes
      )
      if (site) {
        var commentProvider =
          site.settings.comment_provider &&
          site.settings.comment_provider !== '-inherit-'
            ? commentProviders.find(
              p => p.value === site.settings.comment_provider
            )
            : commentProviders[0]
        var siteType =
          site.settings.site_type && site.settings.site_type !== '-inherit-'
            ? siteTypes.find(t => t.value === site.settings.site_type)
            : siteTypes[0]
        var theme =
          site.settings.theme && site.settings.theme !== '-inherit-'
            ? themes.find(t => t.value === site.settings.theme)
            : themes[0]
        siteTypes = [
          { value: '-inherit-', name: 'Site default: ' + siteType.name }
        ].concat(siteTypes)
        themes = [
          { value: '-inherit-', name: 'Site default: ' + theme.name }
        ].concat(themes)
        commentProviders = [
          { value: '-inherit-', name: 'Site default: ' + commentProvider.name }
        ].concat(commentProviders)
      }

      publicSettings = (
        <div className='sub-container'>
          <div className='sub-row'>
            <SelectMenu
              items={commentProviders}
              label='Comments'
              title='Select comment provider for this note'
              icon='comment-discussion'
              defaultValue={this.state.comment_provider || '-inherit-'}
              classes=''
              dataName='comment_provider'
              onChange={this.handleFormFieldChange}
            />&nbsp;&nbsp;&nbsp;
            {!site && (
              <SelectMenu
                items={siteTypes}
                label='Note type'
                title='What type of note is this?'
                icon='versions'
                defaultValue={this.state.site_type || '-inherit-'}
                classes=''
                dataName='site_type'
                onChange={this.handleFormFieldChange}
              />
            )}
            &nbsp;&nbsp;&nbsp;
            {false &&
              !site && (
              <SelectMenu
                items={themes}
                label='Theme'
                title='Select theme for this note'
                icon='device-desktop'
                defaultValue={this.state.theme || '-inherit-'}
                classes=''
                dataName='theme'
                onChange={this.handleFormFieldChange}
              />
            )}
          </div>
          <div className='sub-row'>
            <div className='row-heading'>
              <label>Slug:</label>
            </div>
            <div className='row-content'>
              <input
                type='text'
                ref={slug => {
                  this.slug = slug
                }}
                value={this.state.slug}
                onChange={this.handleSlugChange}
              />
              {this.errorFor('slug')}
            </div>
          </div>
        </div>
      )
    } else {
      treatAsCategory = (
        <div className='row'>
          <div className='row-content'>
            <div className='input-label-wrapper'>
              <input
                type='checkbox'
                ref={treatAsCategory => {
                  this.treatAsCategory = treatAsCategory
                }}
                checked={this.state.treat_as_category}
                onChange={this.handleTreatAsCategoryChange}
                id='treat-as-category'
              />
              <label htmlFor='treat-as-category'>
                Treat as category (if part of a site)
              </label>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div id='note-settings'>
        {this.errorFor()}
        <div className='row'>
          <div className='row-content'>
            <div className='input-label-wrapper'>
              <input
                type='checkbox'
                ref={published => {
                  this.published = published
                }}
                checked={this.state.published}
                onChange={this.handlePublishedChange}
                id='published'
              />
              <label htmlFor='published'>Publish on the web</label>
            </div>
            {publicSettings}
          </div>
        </div>
        {treatAsCategory}
        <div className='buttons'>
          <button onClick={this.save} className='button default'>
            Save
          </button>
        </div>
      </div>
    )
  }
})

module.exports = NodeSettingsOverlay
