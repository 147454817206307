import './styles.css'

import React from 'react'
import _ from 'lodash'

import SingleNotification from '../../../single_notification'

import Misc from '../../../../js/misc'

var NOTIFICATIONS_PER_LOAD = 50

class NotificationsPage extends React.Component {
  state = {
    gotAll: false
  }

  getNotifications = start => {
    var _this = this
    Misc.jx(
      this,
      'get_notifications',
      { limit: NOTIFICATIONS_PER_LOAD, start: start },
      function (res) {
        var syncData = res.body.syncData
        var newState = {}
        if (
          syncData &&
          syncData.notifications &&
          syncData.notifications[0] &&
          syncData.notifications[0].records &&
          syncData.notifications[0].records.length < NOTIFICATIONS_PER_LOAD
        ) {
          newState.gotAll = true
        }
        this.setState(newState, () => {
          _this.props.app.applyChanges(res.body.syncData)
        })
      }.bind(this)
    )
  }

  getMore = () => {
    this.getNotifications(this.props.app.state.entities.notifications.length)
  }

  componentDidMount () {
    this.getNotifications(0)
  }

  closePanel = () => {
    this.props.app.setPanel(null)
  }

  render () {
    return (
      <div>
        <div className='heading'>
          <h2>Notifications</h2>
          <div className='close-settings-page' onClick={this.closePanel}>
            <i className='fa fa-fw fa-close' />
          </div>
        </div>

        {this.props.app.state.entities.notifications.length ? (
          <div className='all-notifications'>
            <div className='notifications-list'>
              {_.sortBy(
                this.props.app.state.entities.notifications,
                'created_at'
              )
                .reverse()
                .map(n => {
                  return (
                    <SingleNotification
                      key={'notification-all-' + n.id}
                      app={this.props.app}
                      notification={n}
                      inList
                    />
                  )
                })}
            </div>
            {!this.state.gotAll && (
              <div className='notifications-more'>
                <button className='button' onClick={this.getMore}>
                  Load more
                </button>
              </div>
            )}
          </div>
        ) : (
          <div>You have no notifications</div>
        )}
      </div>
    )
  }
}

module.exports = NotificationsPage
