import './styles.css'

import React from 'react'
import Avatar from '../../../../avatar'
import Node from '../../../../../js/node'
import User from '../../../../../js/user'

class Project extends React.Component {
  setNodeSelection = (selection, ev) => {
    console.log('selection', selection)
    this.props.app.setNodeSelection(selection)
    ev.preventDefault()
  }

  render () {
    var todos,
      todoItems,
      doneTodoItems,
      userTodoItems,
      totalUserTodoItems,
      userDoneTodoItems,
      todoUser,
      unassignedTodoItems,
      unassignedDoneTodoItems
    var content = false
    var todoUsers = []
    var userTodoEls = []
    var todoEls = []
    var allTodoItems = []
    var allDoneTodoItems = []
    var todoCount = 0
    var doneCount = 0

    todos = Node.subTree(
      this.props.node,
      this.props.app.state.entities.nodes
    ).filter(n => {
      return n.type === 'todo'
    })
    todos.forEach(todo => {
      todoItems = Node.subTree(
        todo,
        this.props.app.state.entities.nodes
      ).filter(n => {
        return n.type !== 'todo'
      })
      doneTodoItems = todoItems.filter(n => {
        return n.completed
      })
      allTodoItems = allTodoItems.concat(todoItems)
      allDoneTodoItems = allDoneTodoItems.concat(doneTodoItems)
      todoCount += todoItems.length
      doneCount += doneTodoItems.length
      todoEls.push(
        <tr key={'todo-' + todo.id}>
          <td>
            {this.props.app.nodeIcon(todo)} {todo.title}
          </td>
          <td>
            <div className='percentage'>
              <span
                className={
                  'pct-' +
                  parseInt(doneTodoItems.length / todoItems.length * 100)
                }
              />
            </div>
          </td>
          <td>
            <span
              className='selection-link'
              onClick={() =>
                this.setNodeSelection({
                  title:
                    this.props.node.title +
                    ': Done Tasks in "' +
                    todo.title +
                    '"',
                  nodeIds: doneTodoItems.slice()
                })
              }
            >
              {doneTodoItems.length}
            </span>{' '}
            /{' '}
            <span
              className='selection-link'
              onClick={() =>
                this.setNodeSelection({
                  title:
                    this.props.node.title +
                    ': All Tasks in "' +
                    todo.title +
                    '"',
                  nodeIds: todoItems.slice()
                })
              }
            >
              {todoItems.length}
            </span>
          </td>
        </tr>
      )
    })
    totalUserTodoItems = allTodoItems.filter(n => {
      return n.assigned_to
    })
    totalUserTodoItems.forEach(n => {
      if (todoUsers.indexOf(n.assigned_to) === -1) {
        todoUsers.push(n.assigned_to)
      }
    })
    userTodoEls = todoUsers.map(u => {
      todoUser = User.get(u, this.props.app.state.entities.users)
      userTodoItems = totalUserTodoItems.filter(n => {
        return n.assigned_to === u
      })
      userDoneTodoItems = userTodoItems.filter(n => {
        return n.completed
      })
      return (
        <div key={'todo-user-' + u}>
          <div>
            <Avatar app={this.props.app} user={u} noPlaceholder size='huge' />
          </div>
          <div>
            {todoUser.first_name} {todoUser.last_name}
          </div>
          <div>
            <div className='percentage'>
              <span
                className={
                  'pct-' +
                  parseInt(
                    userDoneTodoItems.length / userTodoItems.length * 100
                  )
                }
              />
            </div>
          </div>
          <div>
            <span
              className='selection-link'
              onClick={() =>
                this.setNodeSelection({
                  title:
                    this.props.node.title +
                    ': Done Tasks for ' +
                    todoUser.first_name,
                  nodeIds: userDoneTodoItems
                })
              }
            >
              {userDoneTodoItems.length}
            </span>{' '}
            /{' '}
            <span
              className='selection-link'
              onClick={() =>
                this.setNodeSelection({
                  title:
                    this.props.node.title +
                    ': All Tasks for ' +
                    todoUser.first_name,
                  nodeIds: userTodoItems
                })
              }
            >
              {userTodoItems.length}
            </span>
          </div>
        </div>
      )
    })
    if (userTodoEls.length) {
      unassignedTodoItems = allTodoItems.filter(n => {
        return !n.assigned_to
      })
      unassignedDoneTodoItems = unassignedTodoItems.filter(n => {
        return n.completed
      })
      userTodoEls.push(
        <div key='todo-user-unassigned' className='unassigned'>
          <div>
            <Avatar
              app={this.props.app}
              user={null}
              noPlaceholder={false}
              size='huge'
            />
          </div>
          <div>Unassigned</div>
          <div>
            <div className='percentage'>
              <span
                className={
                  'pct-' +
                  parseInt(
                    unassignedDoneTodoItems.length /
                      unassignedTodoItems.length *
                      100
                  )
                }
              />
            </div>
          </div>
          <div>
            <span
              className='selection-link'
              onClick={() =>
                this.setNodeSelection({
                  title: this.props.node.title + ': Done Unassigned Tasks',
                  nodeIds: unassignedDoneTodoItems
                })
              }
            >
              {unassignedDoneTodoItems.length}
            </span>{' '}
            /{' '}
            <span
              className='selection-link'
              onClick={() =>
                this.setNodeSelection({
                  title: this.props.node.title + ': All Unassigned Tasks',
                  nodeIds: unassignedTodoItems
                })
              }
            >
              {unassignedTodoItems.length}
            </span>
          </div>
        </div>
      )
    }
    if (todoEls.length) {
      todoEls.push(
        <tr key='todo-total' className='total'>
          <td>Total</td>
          <td>
            <div className='percentage'>
              <span
                className={'pct-' + parseInt(doneCount / todoCount * 100)}
              />
            </div>
          </td>
          <td>
            <span
              className='selection-link'
              onClick={() =>
                this.setNodeSelection({
                  title: this.props.node.title + ': Done Tasks',
                  nodeIds: allDoneTodoItems
                })
              }
            >
              {doneCount}
            </span>{' '}
            /{' '}
            <span
              className='selection-link'
              onClick={() =>
                this.setNodeSelection({
                  title: this.props.node.title + ': All Tasks',
                  nodeIds: allTodoItems
                })
              }
            >
              {todoCount}
            </span>
          </td>
        </tr>
      )
      content = (
        <div className='project-stats'>
          <h3>Project Summary</h3>
          <table>
            <tbody>{todoEls}</tbody>
          </table>
          {Boolean(userTodoEls.length) && (
            <div className='user-stats'>
              <h4>Per-User Status</h4>
              <div>{userTodoEls}</div>
            </div>
          )}
        </div>
      )
    }
    return content
  }
}

module.exports = Project
