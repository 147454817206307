var update = require('react-addons-update')

var Notification = {

  debug: false,

  add: function (notification, params, callback) {
    const doAction = (actionParams) => {
      const newParams = Object.assign({}, actionParams)
      const notifications = actionParams.updatedState.entities ? actionParams.updatedState.entities.notifications : actionParams.state.entities.notifications
      const newNotifications = notifications.concat(notification)

      const undoData = {
        undo: [{obj: 'Notification', cmd: 'remove', args: [notification.id]}],
        redo: [{obj: 'Notification', cmd: 'add', args: [notification]}]
      }

      if (!actionParams._isUndoOperation && !actionParams._skipUndoAdd) {
        actionParams.undo.add(undoData, true)
      }

      const newEntities = Object.assign({}, (actionParams.updatedState.entities || actionParams.state.entities))
      newEntities.notifications = newNotifications
      const result = {entities: newEntities}

      newParams.updatedState = Object.assign({}, actionParams.updatedState, result)
      newParams.undoData = actionParams.undoData || {}
      newParams.undoData.undo = (newParams.undoData.undo || []).concat(undoData.undo)
      newParams.undoData.redo = (newParams.undoData.redo || []).concat(undoData.redo)

      if (actionParams._skipSetState) {
        if (typeof callback === 'function') {
          callback(newParams)
        }
      } else {
        actionParams.setAppState(newParams.updatedState, newParams.stateParams, () => {
          if (typeof callback === 'function') {
            callback(newParams)
          }
        })
      }
    }
    if (params._skipFlushUndo) {
      doAction(params)
    } else {
      params.flushTextUndo(params, ret => {
        doAction(ret)
      })
    }
  },

  modify: function (notificationOrId, data, params, callback) {
    const doAction = (actionParams) => {
      const newParams = Object.assign({}, actionParams)
      const notifications = actionParams.updatedState.entities ? actionParams.updatedState.entities.notifications : actionParams.state.entities.notifications

      var notification = Notification.get(notificationOrId, notifications)
      var newNotification = update(notification, {$merge: {updated_at: new Date().toISOString()}})
      const oldData = {}
      for (var key in data) {
        var keys = key.split('.')
        var obj = newNotification
        for (var i = 0; i <= keys.length - 1; i++) {
          if (i === keys.length - 1) {
            obj[keys[i]] = data[key]
          }

          oldData[key] = notification[key]
          obj = obj[keys[i]]
        }
      }
      var trimmedNotifications = notifications.filter(n => n.id !== newNotification.id)
      var newNotifications = trimmedNotifications.concat([newNotification])

      const undoData = {
        undo: [{obj: 'Notification', cmd: 'modify', args: [notification.id, oldData]}],
        redo: [{obj: 'Notification', cmd: 'modify', args: [notification.id, data]}]
      }

      if (!actionParams._isUndoOperation && !actionParams._skipUndoAdd) {
        actionParams.undo.add(undoData, true)
      }

      const newEntities = Object.assign({}, (actionParams.updatedState.entities || actionParams.state.entities))
      newEntities.notifications = newNotifications
      const result = {entities: newEntities}

      newParams.updatedState = Object.assign({}, actionParams.updatedState, result)
      newParams.undoData = actionParams.undoData || {}
      newParams.undoData.undo = (newParams.undoData.undo || []).concat(undoData.undo)
      newParams.undoData.redo = (newParams.undoData.redo || []).concat(undoData.redo)

      if (actionParams._skipSetState) {
        if (typeof callback === 'function') {
          callback(newParams)
        }
      } else {
        actionParams.setAppState(newParams.updatedState, newParams.stateParams, () => {
          if (typeof callback === 'function') {
            callback(newParams)
          }
        })
      }
    }
    if (params._skipFlushUndo) {
      doAction(params)
    } else {
      params.flushTextUndo(params, ret => {
        doAction(ret)
      })
    }
  },

  read: function (notificationOrId, params, callback) {
    const newParams = Object.assign({}, params)
    const notifications = params.updatedState.entities ? params.updatedState.entities.notifications : params.state.entities.notifications
    const notification = Notification.get(notificationOrId, notifications)
    const newNotification = update(notification, {$merge: {read_at: new Date().toISOString(), updated_at: new Date().toISOString()}})
    const trimmedNotifications = notifications.filter(n => n.id !== newNotification.id)
    const newNotifications = trimmedNotifications.concat([newNotification])

    params.undo.add({redo: [{obj: 'Notification', cmd: 'read', args: [notification.id]}]}, true)

    const newEntities = Object.assign({}, (params.updatedState.entities || params.state.entities))
    newEntities.notifications = newNotifications
    const result = {entities: newEntities}

    newParams.updatedState = Object.assign({}, params.updatedState, result)

    if (params._skipSetState) {
      if (typeof callback === 'function') {
        callback(newParams)
      }
    } else {
      params.setAppState(newParams.updatedState, newParams.stateParams, () => {
        if (typeof callback === 'function') {
          callback(newParams)
        }
      })
    }
  },

  readAll: function (params, callback) {
    const newParams = Object.assign({}, params)
    const notifications = params.updatedState.entities ? params.updatedState.entities.notifications : params.state.entities.notifications
    const newNotifications = notifications.map(n => {
      if (!n.read_at) {
        return update(n, {$merge: {read_at: new Date().toISOString(), updated_at: new Date().toISOString()}})
      } else {
        return n
      }
    })

    params.undo.add({redo: [{obj: 'Notification', cmd: 'readAll', args: []}]}, true)

    const newEntities = Object.assign({}, (params.updatedState.entities || params.state.entities))
    newEntities.notifications = newNotifications
    const result = {entities: newEntities}

    newParams.updatedState = Object.assign({}, params.updatedState, result)

    if (params._skipSetState) {
      if (typeof callback === 'function') {
        callback(newParams)
      }
    } else {
      params.setAppState(newParams.updatedState, newParams.stateParams, () => {
        if (typeof callback === 'function') {
          callback(newParams)
        }
      })
    }
  },

  hide: function (notificationOrId, params, callback) {
    const newParams = Object.assign({}, params)
    const notifications = params.updatedState.entities ? params.updatedState.entities.notifications : params.state.entities.notifications
    const notification = Notification.get(notificationOrId, notifications)
    const newNotification = update(notification, {$merge: {hidden_at: new Date().toISOString(), updated_at: new Date().toISOString()}})
    const trimmedNotifications = notifications.filter(n => n.id !== newNotification.id)
    const newNotifications = trimmedNotifications.concat([newNotification])

    params.undo.add({redo: [{obj: 'Notification', cmd: 'hide', args: [notification.id]}]}, true)

    const newEntities = Object.assign({}, (params.updatedState.entities || params.state.entities))
    newEntities.notifications = newNotifications
    const result = {entities: newEntities}

    newParams.updatedState = Object.assign({}, params.updatedState, result)

    if (params._skipSetState) {
      if (typeof callback === 'function') {
        callback(newParams)
      }
    } else {
      params.setAppState(newParams.updatedState, newParams.stateParams, () => {
        if (typeof callback === 'function') {
          callback(newParams)
        }
      })
    }
  },

  hideAll: function (params, callback) {
    const newParams = Object.assign({}, params)
    const notifications = params.updatedState.entities ? params.updatedState.entities.notifications : params.state.entities.notifications
    const newNotifications = notifications.map(n => {
      if (!n.hidden_at) {
        return update(n, {$merge: {hidden_at: new Date().toISOString(), updated_at: new Date().toISOString()}})
      } else {
        return n
      }
    })

    params.undo.add({redo: [{obj: 'Notification', cmd: 'hideAll', args: []}]}, true)

    const newEntities = Object.assign({}, (params.updatedState.entities || params.state.entities))
    newEntities.notifications = newNotifications
    const result = {entities: newEntities}

    newParams.updatedState = Object.assign({}, params.updatedState, result)

    if (params._skipSetState) {
      if (typeof callback === 'function') {
        callback(newParams)
      }
    } else {
      params.setAppState(newParams.updatedState, newParams.stateParams, () => {
        if (typeof callback === 'function') {
          callback(newParams)
        }
      })
    }
  },

  get: function (notificationOrId, notifications) {
    if (typeof notificationOrId === 'string') {
      return notifications.find(n => n.id === notificationOrId)
    } else {
      return notificationOrId
    }
  }

}

module.exports = Notification
