import './styles.css'

import React from 'react'
import $ from 'jquery'

import onClickOutside from 'react-onclickoutside'

import Notification from '../../../js/notification'

const NotificationDropdown = onClickOutside(
  class extends React.Component {
    static displayName = 'NotificationDropdown'

    handleClickOutside = ev => {
      if (this.props.onClickOutside) {
        var $target = $(ev.target)
        var moreParent = $target.closest('.more')
        var toggleMoreParent = $target.closest('.more-toggle')
        if (!moreParent.length && !toggleMoreParent.length) {
          this.props.onClickOutside()
        }
      }
    }

    toggleHidden = (notification, ev) => {
      ev && ev.stopPropagation()
      var updates = {
        hidden_at: notification.hidden_at ? null : new Date().toISOString()
      }
      Notification.modify(
        notification.id,
        updates,
        this.props.app.getCommonParams()
      )
      this.props.onClickOutside()
    }

    toggleRead = (notification, ev) => {
      ev && ev.stopPropagation()
      var updates = {
        read_at: notification.read_at ? null : new Date().toISOString()
      }
      Notification.modify(
        notification.id,
        updates,
        this.props.app.getCommonParams()
      )
      this.props.onClickOutside()
    }

    render () {
      var hiddenText = this.props.notification.hidden_at ? 'Unhide' : 'Hide'
      var readText = this.props.notification.read_at
        ? 'Mark as Unread'
        : 'Mark as Read'
      return (
        <div className='more notification-dropdown'>
          <ul>
            <li onClick={() => this.toggleHidden(this.props.notification)}>
              {hiddenText}
            </li>
            <li onClick={() => this.toggleRead(this.props.notification)}>
              {readText}
            </li>
          </ul>
        </div>
      )
    }
  }
)

module.exports = NotificationDropdown
