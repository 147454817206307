import React from 'react'

var $ = require('jquery')
// var _ = require('lodash');

var utils = require('../client_utils')
var keyCommands = require('../key_commands')
var UndoManager = require('../../js-lib/undo')
var defaultLabels = require('../../js-lib/default_labels')
// var CommandPalette = require('../command_palette');

var Wrapper = require('../../components/wrapper')

// var Node = require('../../js/node');
var Misc = require('../../js/misc')

var Tours = require('../../js/tours')

module.exports = {
  getInitialState: function () {
    // console.log(ipcRenderer.sendSync('synchronous-message', 'ping')); // prints "pong"

    // ipcRenderer.on('asynchronous-reply', (event, arg) => {
    //   console.log(arg); // prints "pong"
    // });
    // ipcRenderer.send('asynchronous-message', 'ping');

    window._app.pending = window._app.pending || []
    var token =
      window.sessionStorage.getItem('authToken') ||
      window.localStorage.getItem('authToken')
    // var commands = CommandPalette.palette(this);
    var filterWorker, filterPathsWorker
    if (NOTEBASE_IS_DESKTOP) {
      // filterWorker = new Worker('./bundle/filter-worker.js');
      // filterPathsWorker = new Worker('./bundle/filter-paths-worker.js');
      filterWorker = new Worker('notebase:///bundle/filter-worker.js')
      filterPathsWorker = new Worker(
        'notebase:///bundle/filter-paths-worker.js'
      )
      // window._app.filterWorker = {
      //   postMessage: window._app.ipcRenderer.send.bind(this, 'filter-worker-message'),
      // };
      // window._app.filterPathsWorker = {
      //   postMessage: window._app.ipcRenderer.send.bind(this, 'filter-paths-message'),
      // };
      // filterWorker = window._app.filterWorker;
      // filterPathsWorker = window._app.filterPathsWorker;
    } else {
      filterWorker = new Worker('/static/filter-worker.js')
      filterPathsWorker = new Worker('/static/filter-paths-worker.js')
    }
    var state = {
      _debug: false,
      loaded: false,
      noLoad: false,
      error: false,
      online: true,
      unloading: false,
      accountDeleted: false,
      connectionState: 'up',
      connectionString: 'Online',
      authToken: token,
      userId: null,
      orgId: null,
      updatedNodes: [],
      shares: [],
      tab: null,
      tabs: [],
      tmpVisible: [],
      displayNodes: [],
      rootNode: null,
      editNode: false,
      saving: false,
      switcherOpen: false,
      parsedBody: '',
      dragged: null,
      draggedOver: null,
      dragIsValid: true,
      pollInterval: 1000,
      minPollInterval: 1000,
      syncsWithoutAction: 0,
      updatedTextNode: null,
      activateSearch: false,
      deActivateSearch: false,
      overlay: null,
      panel: null,
      descriptionNode: null,
      browsingFiles: false,
      hasMoved: false,
      userFiles: [],
      selectedInboxItems: [],
      searchstring: null,
      systemMessage: null,
      confirmationMessage: 'Are you sure?',
      indicators: { save: { class: 'good', text: 'Saved' } },
      scrollSync: true,
      editorCompanion: 'preview',
      assignPickerIndex: 0,
      orgsData: {},
      entities: {},
      config: {},
      history: [],
      defaultLabels: defaultLabels,
      editorStates: {},
      // commands: commands,
      // commandPalette: Object.keys(commands).map(function(key) { return {title: commands[key][0], id: key}; }),
      undo: new UndoManager(this.addToStack),
      workerChannel: new MessageChannel(),
      filterWorker: filterWorker,
      filterPathsWorker: filterPathsWorker,
      sidebarStack: [],
      filter: null,
      dataPriorities: [
        { value: 10, color: '#ddd', label: 'Very Low' },
        { value: 20, color: '#add', label: 'Low' },
        { value: 30, color: '#ada', label: 'Normal' },
        { value: 40, color: '#dda', label: 'High' },
        { value: 50, color: '#daa', label: 'Critical' }
      ],
      allDataTypes: [
        'people',
        'location',
        'duration',
        'event_at',
        'event_end',
        'priority',
        'story_points',
        'job_title',
        'company',
        'phones',
        'emails',
        'url'
      ],
      includedDataTypesForNodeType: {
        // trip: ['location', 'people', 'event_at', 'event_end'],
        // event: ['location', 'people', 'event_at'],
        // meeting: ['location', 'people', 'event_at']
        trip: [],
        event: [],
        meeting: []
      },
      // nodeTypesWithData: ['meeting'],
      nodeTypesWithData: [],
      nodeTypesWithSpecifics: ['project', 'todo']
    }
    if (state.filterWorker) {
      state.filterWorker.postMessage(
        {
          action: 'connect'
        },
        [state.workerChannel.port1]
      )
      state.filterPathsWorker.postMessage(
        {
          action: 'connect'
        },
        [state.workerChannel.port2]
      )
    }

    return state
  },

  componentWillMount: function () {
    this.ajax = utils.react.ajax.bind(this, this, {
      prefix: NOTEBASE_URL_PREFIX + '/_/',
      product: 'notebase',
      entitiesKey: 'entities'
    })
    window._app.pending = []
    window._app.app = this
    Tours.initTours()
    var parts = window.location.pathname.split('/')
    if (parts[1] && parts[1] === 'shared' && parts[2]) {
      window._app.shareId = parts[2]
      window._app.inviteId = Misc.getQueryParam('i')
    } else if (parts[1] && parts[1] === 'org' && parts[2] && parts[3]) {
      if (parts[2] === 'request') {
        window._app.orgRequestId = parts[3]
      } else if (parts[2] === 'invite') {
        window._app.orgInviteId = parts[3]
      }
    }

    this.loadInitialData()
    // if (this.state.token || window._app.shareId || window._app.orgInviteId || window._app.orgRequestId) {
    //   this.loadInitialData();
    // } else {
    //   this.setState({noLoad: true});
    // }
  },

  // componentDidUpdate: function(prevProps, prevState) {
  //   var _this = this;
  //   console.log('---------APP WAS UPDATED---------');
  //   Object.keys(this.state).forEach(function(key) {
  //     if (_this.state[key] !== prevState[key]) {
  //       console.log(key, _this.state[key] === prevState[key]);
  //     }
  //   });
  // },

  componentDidMount: function () {
    var _this = this

    this.rootSelections = {}

    window.onpopstate = this.handleBackButton

    window.onbeforeunload = this.beforeUnload

    // Add SVG overlay image with square hole cut out
    // var bWidth = $(window).width();
    // var bHeight = $(window).height();
    // window._app.svgnode = document.createElementNS('http://www.w3.org/2000/svg','svg');
    // window._app.svgpath = document.createElementNS('http://www.w3.org/2000/svg','path');
    // window._app.svgPathPrefix = 'M0 0 h ' + bWidth + ' v ' + bHeight + ' h -' + bWidth + 'z';
    // window._app.oldSvgPath = 'M' + Math.round(bWidth/2) + ' ' + Math.round(bHeight/2) + 'v1h1v-1z';
    // var path = window._app.svgPathPrefix + window._app.oldSvgPath;
    // window._app.svgpath.setAttribute('d', path);
    // window._app.svgnode.setAttribute('id', 'svg-overlay');
    // window._app.svgnode.setAttribute('width', bWidth + 'px');
    // window._app.svgnode.setAttribute('height', bHeight + 'px');
    // window._app.svgnode.appendChild(window._app.svgpath);
    // // Record exact time the SVG was added to the DOM, so that we can time
    // // the animations based on it
    // window._app.svgnode.addEventListener('load', function() {
    //   window._app.svgWasAdded = new Date();
    // });
    // document.getElementById('app-wrapper').appendChild(window._app.svgnode);

    // $(document).on('mouseenter', 'ul.nodes li', function(ev) {
    //   $('li').removeClass('hovered');
    //   var li = $(ev.target).closest('li');
    //   li.addClass('hovered');
    // });

    // $(document).on('mouseleave', 'ul.nodes li', function(ev) {
    //   var parent = $(ev.target).closest('li').closest('ul').closest('li');
    //   if (parent.length) {
    //     $('li').removeClass('hovered');
    //     parent.addClass('hovered');
    //   }
    // });

    // if (typeof Aviary === 'object' && Aviary.Feather) { // eslint-disable-line no-undef
    //   this.featherEditor = new Aviary.Feather({ // eslint-disable-line no-undef
    //     apiKey: 'b88882ba1e682bdc',
    //     apiVersion: 3,
    //     onSave: function(imageID, newURL) {
    //       // console.log('Aviary onSave imageID:', imageID);
    //       // console.log('Aviary onSave newURL:', newURL);
    //       var file = _.xfind(_this.state.userFiles.files, function(f) { return f.id === imageID.replace('user-image-', ''); });
    //       filepicker.writeUrl({url: file.url}, // eslint-disable-line no-undef
    //         newURL,
    //         function(newImg) { // eslint-disable-line no-unused-vars
    //           // console.log('thumb_url:', file.thumb_url);
    //           filepicker.writeUrl({url: file.thumb_url}, // eslint-disable-line no-undef
    //             file.url + '/convert?w=200&h=200',
    //             function(newThumb) { // eslint-disable-line no-unused-vars
    //               var data = {};
    //               if (_this.state.browseFilesOptions && _this.state.browseFilesOptions.fileType) {
    //                 data.fileType = _this.state.browseFilesOptions.fileType;
    //               }

    //               Misc.jx(_this, 'browse_files', data, function(res) {
    //                 // TODO: hide spinner
    //                 var newUserFiles = _.xclone(_this.state.userFiles);
    //                 newUserFiles.files = _.xclone(_this.state.userFiles.files);
    //                 newUserFiles.size = _.xreduce(_this.state.userFiles.files, function(sum, file) { return sum + file.size; }, 0);
    //                 newUserFiles.count = _this.state.userFiles.files.length;
    //                 _this.setState({userFiles: res.body.files});
    //               }.bind(_this));

    //               // console.log(JSON.stringify(newThumb));
    //             }

    //           );
    //           // console.log(JSON.stringify(newImg));
    //         }

    //       );
    //     },
    //   });
    // }

    $(document).bind('keyup', function (ev) {
      clearTimeout(window._app.keytimeout)
      if (ev.keyCode === window._app.prevKeyCode) {
        window._app.keypresses++
      } else {
        window._app.keypresses = 1
      }

      var func =
        _this['handleKeyPresses_' + ev.keyCode + '_' + window._app.keypresses]

      if (typeof func === 'function') {
        func(ev)
      }

      window._app.keytimeout = setTimeout(function () {
        // move check for function here to support sequences of different lengths
        window._app.keypresses = 0
      }, 250)

      window._app.prevKeyCode = ev.keyCode
    })

    $(document).on('mouseenter', '.bullet, #context-icon-container', function (
      ev
    ) {
      if (!window._app.dragged) {
        clearTimeout(_this.closeContextTimer)
        // var aside = target.closest('aside, #context-icon-container')
        var node = $(this).closest('.node, .current-node')
        if (node.length) {
          var nodeId = node.data('node-id') || node.data('rootnode-id')
          if (nodeId) {
            _this.openContextTimer = setTimeout(function () {
              _this.toggleContextMenu(nodeId)
            }, 500)
          }
        }
      }
    })

    $(document).on('mouseleave', '.bullet', function (ev) {
      if (!window._app.dragged) {
        var x = ev.pageX
        var y = ev.pageY
        var scrollTop = $(document).scrollTop()
        var scrollLeft = $(document).scrollLeft()
        var el = document.elementFromPoint(x - scrollLeft, y - scrollTop)
        clearTimeout(_this.openContextTimer)
        $('.bullet').removeClass('open')
        var ctx = $(el).closest('#floater.context-menu')
        if (
          !ctx.length &&
          _this.state.floater &&
          _this.state.floater.type === 'context-menu'
        ) {
          _this.closeContextTimer = setTimeout(() => {
            const el = document.elementFromPoint(
              window._app.mousePosition.clientX,
              window._app.mousePosition.clientY
            )
            if (!$(el).closest('#floater.context-menu-floater').length) {
              if (!window._app.disableContextAutoHide) {
                _this.setState({ floater: null })
              }
            }
          }, 500)
        }
      }
    })

    $(document).on('mouseleave', '.context-menu', function () {
      if (!window._app.disableContextAutoHide) {
        _this.setState({ floater: null })
      }
    })

    // perform username/tagname filtering
    $(document).on('click', '.searchlink', function (ev) {
      $('#header #search').val($(ev.target).text())
      _this.setAppState({ searchstring: $(ev.target).text() })
    })

    // $(document).on('click', '.node-link', function(ev) {
    //   var link = $(ev.target);
    //   var node = Node.get(link.data('dst'), _this.state.entities.nodes);
    //   _this.setRoot(node);
    // });

    // do something sensible when clicking on auto-detected links
    $(document).on('click', 'a.link.auto', function (ev) {
      var url = $(ev.target).attr('href')
      window.open(url)
    })

    // record mouse position - always
    $(document).on('mousemove', function (ev) {
      window._app.mousePosition = {
        pageX: ev.pageX,
        pageY: ev.pageY,
        screenX: ev.screenX,
        screenY: ev.screenY,
        clientX: ev.clientX,
        clientY: ev.clientY
      }
      if (window._app.dragJustStarted) {
        window._app.dragInitialCoords = Object.assign(
          {},
          window._app.mousePosition
        )
        window._app.dragJustStarted = false
      }
      if (window._app.dragged) {
        _this.dragOver(ev)
      }
      if (window._app.dragInitialCoords) {
        const dragDiff =
          Math.abs(ev.pageX - window._app.dragInitialCoords.pageX) +
          Math.abs(ev.pageY - window._app.dragInitialCoords.pageY)
        if (dragDiff > 2) {
          if (window._app.boardCardDragId) {
            if (!window._app.boardCardDragStarted) {
              _this.startBoardDrag('card')
            }
            _this.handleBoardDrag('card')
          } else if (window._app.boardListDragId) {
            if (!window._app.boardListDragStarted) {
              _this.startBoardDrag('list')
            }
            _this.handleBoardDrag('list')
          }
        }
      }
    })

    $(document).on('mouseup', function (ev) {
      _this.endBoardDrag(window._app.boardCardDragStarted ? 'card' : 'list')
      if (window._app.dragged) {
        _this.dragEnd(window._app.dragged, ev)
      } else {
        clearTimeout(window._app.dragStartTimeout)
      }
      $('#app-wrapper').removeClass('drag-in-progress')
      window._app.dragged = null
    })

    keyCommands.init(this)
  },

  render: function () {
    // eslint-disable-line react/display-name
    return <Wrapper app={this} />
  }
}
