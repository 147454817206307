import React from 'react'

var HELP_VIDEOS = {
  intro: 'LrUvu1mlWco',
  lists: 'ktvTqknDobU',
  notes: 'F90Cw4l-8NY',
  sites: 'F90Cw4l-8NY',
  tips: 'F90Cw4l-8NY',
  sharing: 'F90Cw4l-8NY',
  import: 'F90Cw4l-8NY',
  tabs: 'F90Cw4l-8NY',
  files: 'F90Cw4l-8NY',
  search: 'F90Cw4l-8NY',
  bookmarks: 'F90Cw4l-8NY',
  backup: 'F90Cw4l-8NY',
  markdown: 'F90Cw4l-8NY',
  customization: 'F90Cw4l-8NY',
  keyboard: 'F90Cw4l-8NY'
}

class Help extends React.Component {
  constructor (props) {
    super(props)
    var defaultVideo = 'intro'

    this.state = {
      autoplay: false,
      videoId: defaultVideo
    }
  }

  getVideoId = friendlyId => {
    return HELP_VIDEOS[friendlyId]
  }

  doPlay = (id, ev) => {
    this.setState({ videoId: id, autoplay: true })
    ev.preventDefault()
  }

  playVideo = id => {
    return this.doPlay.bind(this, id)
  }

  listElement = combo => {
    var id = combo[0]
    var name = combo[1]
    var classes = this.state.videoId === id ? 'active' : ''
    return (
      <li key={'help-video-' + id} className={classes}>
        <span className='icon'>~</span>{' '}
        <a href='' onClick={this.playVideo(id)}>
          {name}
        </a>
      </li>
    )
  }

  render () {
    var _this = this
    var realId = this.getVideoId(this.state.videoId)
    var col1 = [
      ['intro', 'Introduction'],
      ['lists', 'Lists'],
      ['notes', 'Notes'],
      ['bookmarks', 'Bookmarks'],
      ['sites', 'Sites']
    ].map(function (combo) {
      return _this.listElement(combo)
    })
    var col2 = [
      ['tabs', 'Tabs'],
      ['sharing', 'Sharing/Collaboration'],
      ['import', 'Import and Export'],
      ['files', 'Files and Media'],
      ['search', 'Search']
    ].map(function (combo) {
      return _this.listElement(combo)
    })
    var col3 = [
      ['backup', 'Backup'],
      ['markdown', 'Markdown'],
      ['customization', 'Customization'],
      ['keyboard', 'Keyboard Shortcuts'],
      ['tips', 'Tips & Tricks']
    ].map(function (combo) {
      return _this.listElement(combo)
    })
    return (
      <div className='help tag-content'>
        <div className='help-body'>
          <iframe
            width='560'
            height='315'
            src={
              'https://www.youtube.com/embed/' +
              realId +
              (this.state.autoplay ? '?autoplay=1' : '')
            }
            frameBorder='0'
            allowFullScreen
          />
        </div>
        <div className='help-toc'>
          <ul>{col1}</ul>
          <ul>{col2}</ul>
          <ul>{col3}</ul>
        </div>
      </div>
    )
  }
}

module.exports = Help
