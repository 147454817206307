'use strict';

module.exports = function () {
  var local = {
    loginUrl: 'http://auth.bitbox.dev:5115/'
  };

  var remote = {
    loginUrl: 'https://auth.bitbox.xyz/'
  };

  function isLocal() {
    if (typeof location !== 'undefined') {
      return (/\.dev$/.test(location.hostname)
      ); // eslint-disable-line no-undef
    } else {
        return false;
      }
  }

  function getProperty(name) {
    var data = isLocal() ? local : remote;
    return data[name];
  }

  return {
    isLocal: isLocal(),
    loginUrl: getProperty('loginUrl'),
    pollTimeout: 10000
  };
}();