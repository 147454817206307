import React from 'react'

import createReactClass from 'create-react-class'

import Misc from '../../../js/misc'
import Node from '../../../js/node'

const ProjectSettingsOverlay = createReactClass({
  displayName: 'ProjectSettingsOverlay',

  getInitialState: function () {
    this.node = this.props.panel.opts.data.node
    this.nodes = this.props.app.state.entities.nodes
    var state = this.node.settings
    state.error = null
    return state
  },

  UNSAFE_componentWillMount: function () {
    this.handleFormFieldChange = Misc.handleFormFieldChange.bind(this, this)
    this.errorFor = Misc.errorFor.bind(this, this)
  },

  save: function () {
    var commonParams = this.props.app.getCommonParams()
    commonParams.updatedState = { panel: null }
    var node = this.props.panel.opts.data.node
    Node.modify(node.id, { type: 'project' }, commonParams)
    // $('body').removeClass('modal-open');
  },

  render: function () {
    return (
      <div id='node-settings'>
        {this.errorFor()}
        <div className='row'>
          <div className='row-content'>
            <div className='input-label-wrapper'>
              <input
                type='checkbox'
                data-name='emphasized'
                checked={this.state.emphasized}
                value={this.state.emphasized}
                onChange={this.handleFormFieldChange}
                id='emphasized-in-ui'
              />
              <label htmlFor='emphasized-in-ui'>Emphasize in the UI</label>
            </div>
          </div>
        </div>
        <div className='buttons'>
          <button onClick={this.save} className='button default'>
            Save
          </button>
        </div>
      </div>
    )
  }
})

module.exports = ProjectSettingsOverlay
