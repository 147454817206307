import React from 'react'
import createReactClass from 'create-react-class'
import $ from 'jquery'
import _ from 'lodash'

// import Misc from '../../../js/misc'

const ShareOverlay = createReactClass({
  displayName: 'ShareOverlay',

  getInitialState: function () {
    return {
      error: null,
      loading: true,
      currentShareId: null,
      defaultShare: {
        title: this.props.panel.opts.data.node.title || '(no title)'
      }
    }
  },

  UNSAFE_componentWillMount: function () {
    // this.handleFormFieldChange = Misc.handleFormFieldChange.bind(this, this);
    // this.errorFor = Misc.errorFor.bind(this, this)
    // // TODO: Send secret_id if it's availeble
    // var headers = {}
    // if (window._app.shareId) {
    //   headers['X-Notebase-Secret-ID'] = window._app.shareId
    // }
    // var data = { node_id: this.props.panel.opts.data.node.id }
    // this.props.app.ajax(
    //   'share_info',
    //   {
    //     body: data,
    //     headers: headers
    //   },
    //   res => {
    //     var newState = { loading: false }
    //     if (res.share) {
    //       newState.currentShareId = res.share.id
    //     }
    //     this.setState(newState)
    //   },
    //   res => {
    //     var error = res.error || res.text
    //     this.setState({ error: error })
    //   }
    // )
  },

  currentShare: function () {
    return this.state.currentShareId
      ? this.props.app.state.entities.shares.find(s => {
        return s.id === this.state.currentShareId
      })
      : null
  },

  handleTitleChange: function (ev) {
    this.setState({
      defaultShare: _.extend(this.state.defaultShare, {
        title: $(ev.target).val()
      })
    })
  },

  share: function () {
    this.props.app.setLastUserAction()
    // const share = this.currentShare() || this.state.defaultShare
    // var accessComponent = $(this.refs.accessLevel);
    // var accessLevel = accessComponent.find('input:radio[name="access_level"]:checked').val();
    var data = {
      node_id: this.props.panel.opts.data.node.id,
      title: this.title.value
    }
    this.props.app.ajax('share', { body: data }, res => {
      this.setState({ currentShareId: res.id }, () => {
        this.props.app.setAppState({ updatedNodes: [data.node_id] })
      })
    })
    if (window.Intercom) {
      window.Intercom('trackEvent', 'shared-node')
    }
  },

  unshare: function (ev, keepOpen, skipConfirmation) {
    var callback = () => {
      var currentShare = this.currentShare()
      if (currentShare) {
        var nodeId = currentShare.node_id
        var data = { id: currentShare.id }
        this.props.app.ajax('unshare', { body: data }, () => {
          this.setState({ currentShareId: null }, () => {
            this.props.app.setAppState({ updatedNodes: [nodeId] })
          })
          if (!keepOpen) {
            this.props.app.setPanel(null)
          }
        })
      }
    }
    if (skipConfirmation) {
      callback()
    } else {
      this.props.app.getConfirmation(function () {
        callback()
      })
    }
  },

  render: function () {
    return (
      <div>
        <div className='row'>
          <div className='row-content'>
            <p>Sharing... would be nice to have.</p>
          </div>
        </div>
      </div>
    )
    // var content
    // var currentShare = this.currentShare()
    // var share = currentShare || this.state.defaultShare
    // if (this.state.loading && this.state.error) {
    //   // No need to add anything, the error is output further down
    // } else if (this.state.loading) {
    //   content = (
    //     <div className='loading'>
    //       <div className='working-indicator'>
    //         <div className='rect1' />
    //         <div className='rect2' />
    //         <div className='rect3' />
    //         <div className='rect4' />
    //         <div className='rect5' />
    //       </div>
    //       <div className='info'>Loading share data from server...</div>
    //     </div>
    //   )
    // } else {
    //   if (currentShare) {
    //     var innerContent = (
    //       <div>
    //         <div className='row'>
    //           <div className='row-heading'>
    //             <label>Shared as:</label>
    //           </div>
    //           <div className='row-content'>
    //             <input type='text' value={share.title} readOnly />
    //           </div>
    //         </div>
    //         <div className='row'>
    //           <div className='row-heading'>
    //             <label>Share link:</label>
    //           </div>
    //           <div className='row-content'>
    //             <input
    //               type='text'
    //               value={`${this.props.app.appUrl()}/shared/${
    //                 currentShare.secret_id
    //               }`}
    //               readOnly
    //             />
    //           </div>
    //         </div>
    //       </div>
    //     )
    //     content = (
    //       <div>
    //         {innerContent}
    //         <div className='buttons'>
    //           <button onClick={this.unshare} className='button danger'>
    //             <span className='octicon octicon-stop' />&nbsp; Stop sharing
    //           </button>
    //         </div>
    //       </div>
    //     )
    //   } else {
    //     content = (
    //       <div>
    //         <div className='row'>
    //           <div className='row-heading'>
    //             <label>Share as</label>
    //           </div>
    //           <div className='row-content'>
    //             <input
    //               type='text'
    //               value={share.title}
    //               ref={title => {
    //                 this.title = title
    //               }}
    //               onChange={this.handleTitleChange}
    //             />
    //           </div>
    //         </div>

    //         <div className='buttons'>
    //           <button onClick={this.share} className='button default'>
    //             Share
    //           </button>
    //         </div>
    //       </div>
    //     )
    //   }
    // }
    // return (
    //   <div id='share'>
    //     {this.errorFor()}
    //     {content}
    //   </div>
    // )
  }
})

module.exports = ShareOverlay
