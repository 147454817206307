import React from 'react'
import blacklist from 'blacklist'
import ActualMediumEditor from 'medium-editor'

// window.Handlebars = require('handlebars/runtime');

// var MediumInsert = require('medium-editor-insert-plugin');
// window.MediumInsert = MediumInsert.MediumInsert;
// require('medium-editor-insert-plugin/dist/css/medium-editor-insert-plugin.min.css');

window.MediumEditor = ActualMediumEditor

class MediumEditor extends React.Component {
  static defaultProps = {
    tag: 'div'
  }

  state = {
    text: this.props.text
  }

  componentDidMount () {
    var _this = this
    window._meditor = this

    var dom = this.editor

    this.medium = new ActualMediumEditor(dom, this.props.options)
    window._medium = this.medium
    this.medium.subscribe('editableInput', function () {
      _this._updated = true
      _this.change(dom.innerHTML)
    })
    // $(dom).mediumInsert({
    //   editor: _this.medium,
    // });
    // this.setState({text: this.state.text + ' OHAI'});
  }

  componentWillUnmount () {
    this.medium.destroy()
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.text !== this.state.text && !this._updated) {
      this.setState({ text: nextProps.text })
    }

    if (this._updated) this._updated = false
  }

  change = text => {
    if (this.props.onChange) this.props.onChange(text, this.medium)
  }

  render () {
    var tag = this.props.tag
    var props = blacklist(
      this.props,
      'tag',
      'contentEditable',
      'dangerouslySetInnerHTML'
    )

    Object.assign(props, {
      contentEditable: true,
      ref: editor => {
        this.editor = editor
      },
      dangerouslySetInnerHTML: { __html: this.state.text }
    })

    return React.createElement(tag, props)
  }
}

module.exports = MediumEditor
