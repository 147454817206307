import React from 'react'

import constants from '../..//lib/constants'

import SelectMenu from '../select_menu'

class FileEditor extends React.Component {
  render () {
    var fileEditor

    if (this.props.app.state.editingFile) {
      fileEditor = (
        <div id='file-editor-container' className='browser'>
          <div id='file-editor-box'>
            <div className='header'>
              <table>
                <tbody>
                  <tr>
                    <td className='p30'>
                      <input
                        className='full-width'
                        type='text'
                        id='file-editor-filename'
                        placeholder='Type the filename here'
                      />
                    </td>
                    <td className='pl20'>
                      <SelectMenu
                        items={constants.FILE_MIME_TYPES}
                        label='File type'
                        title='Select file type for this file'
                        filterTitle='Filter file types'
                        defaultValue={
                          this.props.app.state.editedFile
                            ? this.props.app.state.editedFile.mimetype
                            : 'text/plain'
                        }
                        id='file-editor-mimetype'
                        classes='dark flat'
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className='close-x'>
                <a
                  href=''
                  classNamc='btn'
                  onClick={this.props.app.closeFileEditor}
                >
                  <span className='octicon octicon-x' />
                </a>
              </div>
            </div>
            <div id='file-editor' className='content' />
            <div className='footer'>
              <a href='' className='btn' onClick={this.props.app.saveFile}>
                Save
              </a>
            </div>
          </div>
        </div>
      )
    }
    return <div>{fileEditor}</div>
  }
}

module.exports = FileEditor
