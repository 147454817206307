import './styles.css'

import React from 'react'

import SetPlaceholder from '../../set_placeholder'

export default class Url extends React.Component {
  render () {
    const { app, node } = this.props
    const data = node.data || {}
    return (
      <div
        onClick={ev =>
          app.toggleFloater(ev.target, 'generic-picker', {
            fieldType: 'string',
            field: 'data.url',
            nodeId: node.id
          })
        }
      >
        {data.url && <div className='actual-data'>{data.url}</div>}
        {!data.url && <SetPlaceholder />}
      </div>
    )
  }
}
