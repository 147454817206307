import React from 'react'

import Dropdown from './dropdown'

import Node from '../../js/node'
import Misc from '../../js/misc'

import './styles.css'

class MainEditorCtrl extends React.Component {
  state = {
    hamburgerOpen: false
  }

  toggleEditing = () => {
    // document.exitFullscreen()
    this.props.app.toggleEditing()
  }

  setEditorCompanion = value => {
    var _this = this
    // var oldValue = this.props.editorCompanion;
    this.props.app.setState({ editorCompanion: value }, function () {
      if (_this.props.user) {
        var newSettings = { ..._this.props.user.settings }
        newSettings.editor_companion = _this.props.app.state.editorCompanion
        _this.props.app.saveUserSettings(
          newSettings,
          _this.props.app.getCommonParams()
        )
      }
      // if (value === 'disabled' || oldValue === 'disabled') {
      //   setTimeout(function() {
      //     _this.props.app.resizeEditor();
      //   }, 0);
      // }
      if (value === 'preview' || value === 'lists') {
        var newValue = window._app.editor.getValue()
        var parsedBody = Misc.parseMarkdown(newValue)
        var key = value === 'preview' ? 'previewBody' : 'parsedBody'
        var state = {}
        state[key] = parsedBody
        _this.props.app.setState(state)
      }
    })
  }

  setFormat = (value, ev) => {
    // var _this = this;
    var editNode = this.props.app.editNode()
    var changes = { format: value }
    Node.modify(editNode.id, changes, this.props.app.getCommonParams())
    // if (value === 'markdown') {
    //   // _this.props.app.toggleEditing({force: true});
    // }
    if (ev && ev.preventDefault) {
      ev.preventDefault()
    }
  }

  toggleFullScreen = () => {
    var elem = document.getElementById('editor-outer-container')
    if (elem.requestFullscreen) {
      elem.requestFullscreen()
    }
  }

  render () {
    const { app } = this.props
    var ctrl = null
    var scrollSync
    var editNode = this.props.app.editNode()

    if (editNode) {
      var previewActive =
        this.props.editorCompanion === 'preview' ? 'active' : ''
      var listActive = this.props.editorCompanion === 'lists' ? 'active' : ''
      var nothingActive =
        this.props.editorCompanion === 'disabled' ||
        (editNode.format === 'wysiwyg' &&
          this.props.editorCompanion === 'preview')
          ? 'active'
          : ''

      // var markdownActive = editNode.format === 'markdown' ? 'active' : '';
      // var wysiwygActive = editNode.format === 'wysiwyg' ? 'active' : '';

      ctrl = (
        <div id='editor-ctrl'>
          <span className='title'>
            <i className='fa fa-fw fa-pencil' /> {editNode.title}
          </span>
          {/*
          <label>
            <input type="checkbox" onChange={this.handleSplitScreenChange} checked={this.state.splitScreen} /> Use split-screen
          </label>
          {this.props.app.featureEnabled('wysiwyg_editor') &&
            <div className="button-group">
              <a href="" onClick={() => this.setFormat( 'markdown')} className={'button ' + markdownActive}>Markdown</a>
              <a href="" onClick={() => this.setFormat( 'wysiwyg')} className={'button ' + wysiwygActive}>WYSIWYG</a>
            </div>
          }
          */}
          <div className='button-group'>
            {Boolean(!editNode.format || editNode.format === 'markdown') && (
              <span
                onClick={() => this.setEditorCompanion('preview')}
                className={'button ' + previewActive}
              >
                Preview
              </span>
            )}
            <span
              onClick={() => this.setEditorCompanion('disabled')}
              className={'button ' + nothingActive}
            >
              Nothing
            </span>
            <span
              onClick={() => this.setEditorCompanion('lists')}
              className={'button ' + listActive}
            >
              List items
            </span>
          </div>
          {scrollSync}
          <button
            className='save button minibutton'
            onClick={this.toggleEditing}
          >
            Done
          </button>
          <span
            className='first ctrl-btn'
            onClick={this.toggleFullScreen}
            title='Full-screen distraction-free mode'
          >
            <i className='fa fa-fw fa-arrows-alt' />
          </span>
          <span
            className='ctrl-btn'
            onClick={() => app.pushSidebar(['markdown-help'])}
            title='Show Markdown Help in the sidebar'
          >
            <i className='fa fa-fw fa-life-ring' />
          </span>
          <Dropdown {...this.props} />
        </div>
      )
    }
    return ctrl
  }
}

module.exports = MainEditorCtrl
