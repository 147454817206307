import React from 'react'

import './styles.css'

class Badge extends React.Component {
  render () {
    var count = this.props.count.toString()
    if (this.props.count > 20) {
      count = '+20'
    }
    var size = count.length
    var type = this.props.type || ''
    return (this.props.hasOwnProperty('hideIf') && this.props.hideIf) ||
      !this.props.count ? (
        false
      ) : (
        <span className={'badge ' + type + ' size-' + size}>{count}</span>
      )
  }
}

module.exports = Badge
