import React from 'react'

// import Avatar from '../../../avatar'
import Node from '../../../../js/node'
// import Org from '../../../../js/org'
import Misc from '../../../../js/misc'

import constants from '../../../..//lib/constants'

import './styles.css'

export default class PermissionsOverlay extends React.Component {
  state = {
    addingGroup: false,
    newGroupId: null,
    newGroupValue: constants.READ_ACCESS
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    this.errorFor = Misc.errorFor.bind(this, this)
  }

  close = () => {
    this.props.app.setPanel(null)
  }

  handleAccessChange = ev => {
    const { app } = this.props
    const { node } = this.props.panel.opts.data
    let value = parseInt(ev.target.value)
    value = value === '-default-' ? null : value
    const data = { node_id: node.id, access_level: value }
    app.ajax(
      'set_node_default_access',
      { body: data },
      this.afterChangeCallback
    )
  }

  handleUserAccessChange = (ev, user) => {
    const { app } = this.props
    const { node } = this.props.panel.opts.data
    const accessLevel = parseInt(ev.target.value)
    const userId = user.id
    const data = {
      org_id: app.orgId(),
      node_id: node.id,
      user_id: userId,
      access_level: accessLevel
    }
    app.ajax('set_node_user_access', { body: data }, this.afterChangeCallback)
  }

  addUser = () => {
    const { app } = this.props
    const { node } = this.props.panel.opts.data
    const data = {
      org_id: app.orgId(),
      node_id: node.id,
      user_id: this.state.userBeingAdded,
      access_level: this.state.newUserAccessLevel
    }
    app.ajax(
      'set_node_user_access',
      { body: data },
      () => {
        this.setState({
          addingUser: false,
          newUserAccessLevel: constants.READ_ACCESS,
          userBeingAdded: null
        })
      },
      this.afterChangeCallback
    )
  }

  deleteUser = record => {
    const { app } = this.props
    const data = {
      org_id: record.org_id,
      node_id: record.node_id,
      user_id: record.user_id,
      access_level: null
    }
    app.ajax('set_node_user_access', { body: data }, this.afterChangeCallback)
  }

  handleGroupAccessChange = (id, ev) => {
    const { app } = this.props
    app.ajax(
      'update_node_group_permission',
      {
        body: { id: id, access_level: ev.target.value }
      },
      this.afterChangeCallback
    )
  }

  afterChangeCallback = () => {
    const { app } = this.props
    const { node } = this.props.panel.opts.data
    const org = app.org()
    const user = app.user()
    app.decorateNodesWithAccessLevel(
      app.state.entities,
      app.state.entities.nodes,
      org,
      user
    )
    app.setAppState({ updatedNodes: [node] })
  }

  handleNewGroupChange = ev => {
    this.setState({ newGroupId: ev.target.value })
  }

  handleNewAccessChange = ev => {
    this.setState({ newGroupValue: ev.target.value })
  }

  toggleAddGroupPermission = () => {
    this.setState({ addingGroup: !this.state.addingGroup })
  }

  addGroup = () => {
    const { app } = this.props
    const { node } = this.props.panel.opts.data
    app.jx(
      'add_node_group_permission',
      {
        body: {
          node_id: node.id,
          group_id: this.state.newGroupId,
          access_level: this.state.newGroupValue
        }
      },
      () => {
        this.setState({
          addingGroup: false,
          newGroupId: null,
          newGroupValue: null,
          error: null
        })
      }
    )
  }

  deleteGroupPermission = id => {
    const { app } = this.props
    app.ajax('delete_node_group_permission', { body: { id: id } })
  }

  switchPermissionsNode = nodeId => {
    const { app } = this.props
    const newNode = app.node(nodeId)
    if (newNode) {
      app.setPanel('permissions-overlay', {
        data: { node: newNode },
        title: 'Permissions for "' + Misc.truncate(newNode.title, 30) + '"'
      })
    }
  }

  accessLevelPrettyName = level => {
    const mapping = {
      [constants.NO_ACCESS]: 'No access',
      [constants.READ_ACCESS]: 'Read access',
      [constants.WRITE_ACCESS]: 'Write access',
      [constants.ADMIN_ACCESS]: 'Admin access',
      [constants.OWNER_ACCESS]: 'Owner access'
    }
    return mapping[level]
  }

  renderPermissionSelect = opts => {
    opts = opts || {}
    // const org = this.props.org;
    let defaultOption
    if (opts.includeDefault) {
      // var orgAccessStr = this.accessLevelPrettyName(org.default_access_level);
      defaultOption = (
        <option value='-default-'>Use inherited default access level</option>
      )
    }
    let noAccess
    if (!opts.skipNoAccess) {
      noAccess = <option value={constants.NO_ACCESS}>No access</option>
    }
    return (
      <select value={opts.value} onChange={opts.onChange}>
        {defaultOption}
        {noAccess}
        <option value={constants.READ_ACCESS}>Read-only access</option>
        <option value={constants.WRITE_ACCESS}>Write/edit access</option>
      </select>
    )
  }

  getInheritedAccessLevel = allNodePermissions => {
    const { app, org } = this.props
    const { node } = this.props.panel.opts.data
    allNodePermissions =
      allNodePermissions ||
      app.state.entities.node_permissions.filter(e => e.org_id === org.id)
    const defaultLevel = org.default_access_level
    const path = Node.getPath(
      node,
      app.getCommonParams().entities.nodes
    ).reverse()
    let perm = null
    let i = 0
    while (i < path.length && !perm) {
      perm = allNodePermissions.find(p => p.node_id === path[i].id)
      i++
    }
    const ret = { accessLevel: perm ? perm.access_level : defaultLevel }
    if (perm) {
      ret.nodeId = perm.node_id
    }
    return ret
  }

  render () {
    // const { app } = this.props
    // const { node } = this.props.panel.opts.data
    // const org = app.org()
    // const inheritedLevel = this.getInheritedAccessLevel()
    // const inheritedFromNode = inheritedLevel.nodeId
    //   ? app.node(inheritedLevel.nodeId)
    //   : null
    // const allNodePermissions = app.state.entities.node_permissions.filter(
    //   e => e.org_id === org.id
    // )
    // const allUserPermissions = app.state.entities.user_permissions.filter(
    //   e => e.org_id === org.id && e.node_id === node.id
    // )
    // const allUserPermissionUserIds = allUserPermissions.map(e => e.user_id)
    // const remainingUsers = Org.users(org, app.getCommonParams()).filter(
    //   e => allUserPermissionUserIds.indexOf(e.id) === -1
    // )
    // const override = allNodePermissions.find(p => p.node_id === node.id)
    // const parensText = inheritedFromNode ? (
    //   <span>
    //     from {'"'}
    //     <span
    //       className='link'
    //       onClick={() => this.switchPermissionsNode(inheritedFromNode.id)}
    //     >
    //       {Misc.truncate(inheritedFromNode.title, 30)}
    //     </span>
    //     {'"'}
    //   </span>
    // ) : (
    //   'the default for your team'
    // )

    return <div id='user-permissions'>Permissions</div>

    // return (
    //   <div id='user-permissions' className='wide'>
    //     {this.errorFor()}
    //     <div className='info-box'>
    //       <div className='info-inner'>
    //         <div className='icon-box'>
    //           <i className='fa fa-fw fa-info-circle' />
    //         </div>
    //         <div className='message-box'>
    //           All permission settings apply to this item and all its children.
    //         </div>
    //       </div>
    //     </div>
    //     <div className='splitter'>
    //       <div>
    //         <div className='row'>
    //           <div className='row-heading'>
    //             {override && (
    //               <div>
    //                 <label htmlFor='default-access-level'>
    //                   The following permissions have been set for this item and
    //                   its children:
    //                 </label>
    //                 {this.renderPermissionSelect({
    //                   value: override ? override.access_level : '-default-',
    //                   onChange: this.handleAccessChange,
    //                   includeDefault: true
    //                 })}
    //               </div>
    //             )}
    //             {override && (
    //               <div className='applies-to'>
    //                 This applies to all regular members of this team.
    //               </div>
    //             )}
    //             {!override && (
    //               <label htmlFor='default-access-level'>
    //                 No permissions have been set for this particular item, so
    //                 the following inherited access level ({parensText}) is in
    //                 effect for regular users on this team:
    //               </label>
    //             )}
    //           </div>
    //           {!override && (
    //             <div className='row-content'>
    //               <div className='inherited-perms'>
    //                 <div className='inherited-access'>
    //                   {this.accessLevelPrettyName(inheritedLevel.accessLevel)}
    //                 </div>
    //               </div>
    //               {Boolean(override || this.state.editingNodePermissions) && (
    //                 <div>
    //                   <div>
    //                     New access level for this item and its children:
    //                   </div>
    //                   {this.renderPermissionSelect({
    //                     value: override ? override.access_level : '-default-',
    //                     onChange: this.handleAccessChange,
    //                     includeDefault: Boolean(override)
    //                   })}
    //                 </div>
    //               )}
    //               {!override && (
    //                 <div>
    //                   {!this.state.editingNodePermissions && (
    //                     <div>
    //                       You can click the button below to set a different
    //                       access level for this node and its children.
    //                     </div>
    //                   )}
    //                   <button
    //                     className='edit-button'
    //                     onClick={() =>
    //                       this.setState({
    //                         editingNodePermissions: !this.state
    //                           .editingNodePermissions
    //                       })
    //                     }
    //                   >
    //                     {this.state.editingNodePermissions
    //                       ? 'Cancel'
    //                       : 'Set Access Level'}
    //                   </button>
    //                 </div>
    //               )}
    //               {this.errorFor('access')}
    //             </div>
    //           )}
    //         </div>
    //         <div className='row user-permissions'>
    //           <div className='row-heading'>
    //             <label htmlFor='default-access-level'>
    //               You can also set permissions for individual users, which will
    //               override the default access level from above.
    //             </label>
    //           </div>
    //           <div className='row-content'>
    //             <div />
    //             <div className='users'>
    //               <table>
    //                 <tbody>
    //                   {allUserPermissions.map(p => {
    //                     const user = app.user(p.user_id)
    //                     return (
    //                       <tr key={p.id} className='user'>
    //                         <td className='min'>
    //                           <Avatar app={app} user={user} />
    //                         </td>
    //                         <td className='min'>
    //                           <span>
    //                             {user.first_name} {user.last_name}
    //                           </span>
    //                         </td>
    //                         <td>
    //                           {this.renderPermissionSelect({
    //                             value: p.access_level,
    //                             onChange: ev =>
    //                               this.handleUserAccessChange(ev, user),
    //                             includeDefault: false
    //                           })}
    //                         </td>
    //                         <td className='min'>
    //                           <div
    //                             className='delete'
    //                             onClick={() => this.deleteUser(p)}
    //                           >
    //                             <i className='fa fa-fw fa-times' />
    //                           </div>
    //                         </td>
    //                       </tr>
    //                     )
    //                   })}
    //                 </tbody>
    //               </table>
    //             </div>
    //             {this.state.addingUser && (
    //               <div className='adding-user'>
    //                 <select
    //                   value={this.userBeingAdded}
    //                   onChange={ev =>
    //                     this.setState({ userBeingAdded: ev.target.value })
    //                   }
    //                 >
    //                   {remainingUsers.map(user => (
    //                     <option key={user.id} value={user.id}>
    //                       {user.first_name} {user.last_name}
    //                     </option>
    //                   ))}
    //                 </select>
    //                 {this.renderPermissionSelect({
    //                   value: this.state.newUserAccessLevel,
    //                   onChange: ev =>
    //                     this.setState({
    //                       newUserAccessLevel: parseInt(ev.target.value)
    //                     }),
    //                   includeDefault: false
    //                 })}
    //                 <button onClick={this.addUser}>Save</button>
    //               </div>
    //             )}
    //             {Boolean(remainingUsers.length) && (
    //               <button
    //                 className='add-user'
    //                 onClick={() =>
    //                   this.setState({
    //                     addingUser: !this.state.addingUser,
    //                     userBeingAdded: remainingUsers[0].id,
    //                     newUserAccessLevel: constants.READ_ACCESS
    //                   })
    //                 }
    //               >
    //                 {this.state.addingUser ? 'Cancel' : 'Set User Permission'}
    //               </button>
    //             )}
    //           </div>
    //         </div>
    //       </div>
    //       <div>
    //         <div className='sharing'>
    //           <h3>Sharing (read-only)</h3>
    //           <div>
    //             <p>
    //               You can share this item with anyone using a secret link. Note
    //               that shares are read-only - to collaborate you’ll need to
    //               invite some users below.
    //             </p>
    //             <div>
    //               <button>Create Share Link</button>
    //             </div>
    //           </div>
    //         </div>
    //         <div className='inviting'>
    //           <h3>Invite Users</h3>
    //           <div>
    //             <p>
    //               You can invite users to collaborate on this item. Guests can
    //               only see items they have been given explicit access to. If you
    //               like, you can convert them to regular users later from your
    //               team’s settings page.
    //             </p>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // )
  }
}
