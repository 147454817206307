var User = require('./user')
var Group = require('./group')

module.exports = {

  get: function (orgOrId, orgs) {
    orgs = orgs || []
    if (typeof orgOrId === 'string') {
      return orgs.find(o => o.id === orgOrId)
    } else {
      return orgOrId
    }
  },

  customer: function (orgOrId, params) {
    const entities = (params.updatedState && params.updatedState.entities) ? params.updatedState.entities : (params.state ? params.state.entities : params.entities)
    var org = this.get(orgOrId, entities.orgs)
    if (org && org.customer_id) {
      return entities.customers.find(c => c.id === org.customer_id)
    }
  },

  groups: function (orgOrId, params) {
    const entities = (params.updatedState && params.updatedState.entities) ? params.updatedState.entities : (params.state ? params.state.entities : params.entities)
    var org = this.get(orgOrId, entities.orgs)
    if (org) {
      return entities.groups.filter(g => g.org_id === org.id)
    } else {
      return []
    }
  },

  users: function (orgOrId, params) {
    const entities = (params.updatedState && params.updatedState.entities) ? params.updatedState.entities : (params.state ? params.state.entities : params.entities)
    var org = this.get(orgOrId, entities.orgs)
    if (org) {
      var orgUsers = entities.org_users.filter(ou => { return ou.org_id === org.id })
      var userIds = orgUsers.map(ou => { return ou.user_id })
      return entities.users.filter(u => userIds.indexOf(u.id) !== -1)
    } else {
      return []
    }
  },

  requests: function (orgOrId, params) {
    const entities = (params.updatedState && params.updatedState.entities) ? params.updatedState.entities : (params.state ? params.state.entities : params.entities)
    var org = this.get(orgOrId, entities.orgs)
    if (org) {
      return entities.org_requests.filter(r => r.org_id === org.id)
    } else {
      return []
    }
  },

  invites: function (orgOrId, params) {
    const entities = (params.updatedState && params.updatedState.entities) ? params.updatedState.entities : (params.state ? params.state.entities : params.entities)
    var org = this.get(orgOrId, entities.orgs)
    if (org) {
      return entities.org_invites.filter(i => i.org_id === org.id)
    } else {
      return []
    }
  },

  groupPermissions: function (orgOrId, params) {
    const entities = (params.updatedState && params.updatedState.entities) ? params.updatedState.entities : (params.state ? params.state.entities : params.entities)
    var org = this.get(orgOrId, entities.orgs)
    if (org) {
      return entities.group_permissions.filter(p => this.groups(org, params).map(g => g.id).indexOf(p.id) !== -1)
    } else {
      return []
    }
  },

  nodePermissions: function (orgOrId, params) {
    const entities = (params.updatedState && params.updatedState.entities) ? params.updatedState.entities : (params.state ? params.state.entities : params.entities)
    var org = this.get(orgOrId, entities.orgs)
    if (org) {
      return entities.node_permissions.filter(p => p.org_id === org.id)
    } else {
      return []
    }
  },

  userPermissions: function (orgOrId, params) {
    const entities = (params.updatedState && params.updatedState.entities) ? params.updatedState.entities : (params.state ? params.state.entities : params.entities)
    var org = this.get(orgOrId, entities.orgs)
    if (org) {
      return entities.user_permissions.filter(p => p.org_id === org.id)
    } else {
      return []
    }
  },

  isOwner: function (orgOrId, userOrId, params) {
    const entities = (params.updatedState && params.updatedState.entities) ? params.updatedState.entities : (params.state ? params.state.entities : params.entities)
    let isOwner = false
    const org = this.get(orgOrId, entities.orgs)
    const user = User.get(userOrId, entities.users)
    const groups = this.groups(orgOrId, params)
    if (org && user) {
      var ownersGroup = groups.find(g => { return g.is_owners })
      if (ownersGroup) {
        isOwner = Boolean(Group.users(ownersGroup, params).map(u => { return u.id }).indexOf(user.id) !== -1)
      }
    }
    return isOwner
  }

}
