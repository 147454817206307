import React from 'react'

import Heading from '../sections/heading'

import colors from '../../../js-lib/default_bg_colors'

import './styles.css'

export default class Labels extends React.Component {
  state = {
    editing: null,
    name: '',
    color: ''
  }

  saveLabel = label => {
    const { app } = this.props
    const orgId = app.orgId()
    const allOverrides =
      (orgId
        ? app.org().settings.label_overrides
        : app.user().settings.label_overrides) || {}
    const override = allOverrides[label.id] || {}

    let endpoint, entity
    const overrides = override || {}
    if ((override.name || '') !== this.state.name) {
      overrides.name = this.state.name
    }
    if ((override.bg || '') !== this.state.color) {
      overrides.bg = this.state.color
    }
    const resetState = { name: '', color: '' }
    this.setState({ editing: null })
    if (Object.keys(overrides).length) {
      if (orgId) {
        entity = app.org()
        endpoint = 'update_org'
      } else {
        entity = app.user()
        endpoint = 'update_org'
      }
      const oldOverrides = entity.settings.label_overrides || {}
      const newOverrides = { ...oldOverrides, [label.id]: overrides }
      const data = {
        id: orgId,
        updates: { settings: { label_overrides: newOverrides } }
      }
      app.ajax(endpoint, { body: data }, () => {
        this.setState(resetState)
      })
    } else {
      this.setState(resetState)
    }
  }

  render () {
    const { app } = this.props
    const overrides =
      (app.orgId()
        ? app.org().settings.label_overrides
        : app.user().settings.label_overrides) || {}
    return (
      <div className='body full-height labels-container'>
        <Heading {...this.props} name='Manage Labels' isMain />
        <div className='labels'>
          {app.state.defaultLabels.slice(0, 6).map(l => {
            const override = overrides[l.id] || {}
            const name = override.name || ''
            const overriddenBgColor = override.bg || ''
            const bgColor =
              this.state.editing === l.id
                ? this.state.color || overriddenBgColor || l.bg
                : overriddenBgColor || l.bg
            return (
              <div key={l.id}>
                <div
                  className='row'
                  style={{ backgroundColor: bgColor, color: l.text }}
                >
                  <div className='main'>{name}</div>
                  <div
                    className='edit'
                    onClick={() =>
                      this.setState({
                        name: name,
                        color: bgColor,
                        editing: this.state.editing ? null : l.id
                      })
                    }
                  >
                    <i className='fa fa-fw fa-pencil' />
                  </div>
                </div>
                {this.state.editing === l.id && (
                  <div className='editor'>
                    <div>
                      <input
                        placeholder='Name'
                        value={this.state.name}
                        onChange={ev =>
                          this.setState({ name: ev.target.value })
                        }
                      />
                    </div>
                    <div className='color-picker'>
                      {colors.map((c, i) => (
                        <div
                          key={i}
                          onClick={() => this.setState({ color: c })}
                          style={{ backgroundColor: c }}
                        />
                      ))}
                    </div>
                    <button
                      onClick={ev => {
                        ev.preventDefault()
                        this.saveLabel(l)
                      }}
                    >
                      Save
                    </button>
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}
