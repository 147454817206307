import './styles.css'

import React from 'react'
import Chrono from 'chrono-node'
import _ from 'lodash'

import classNames from 'classnames'

import Misc from '../../../../js/misc'

import SingleNode from '../single_node'
import Heading from '../heading'

class DueList extends React.Component {
  state = {
    assignPickerNode: null,
    duePickerNode: null
  }

  toggleSettings = ev => {
    this.props.app.setPanel('due-list-settings-overlay', {
      data: this.props,
      title: 'TODO Settings'
    })
    ev.preventDefault()
  }

  render () {
    var dueList
    if (this.props.user) {
      var rootNode = this.props.rootNode
      var user = this.props.user
      var root = user.settings.due_list_root
      var dueDateHeading = (
        <Heading {...this.props}>
          <h4>My TODO List</h4>
          <span className='icon' onClick={this.toggleSettings}>
            <i className='fa fa-cog' />
          </span>
        </Heading>
      )

      if (this.props.app.state.expandDueList) {
        var noDateShown = false
        var overdueShown = false
        var todayShown = false
        var upcomingShown = false
        var allNodes = this.props.orgNodes
        var eligibleRe
        var eligibleNodes = allNodes
        if (root) {
          var doRoot = false
          if (root === 'tab') {
            if (this.props.tab.root_id) {
              eligibleRe = new RegExp(this.props.tab.root_id.replace(/-/g, '_'))
              doRoot = true
            }
          } else if (root === 'node') {
            if (rootNode) {
              eligibleRe = new RegExp(rootNode.id.replace(/-/g, '_'))
              doRoot = true
            }
          }

          if (doRoot) {
            eligibleNodes = allNodes.filter(n => eligibleRe.test(n.path))
            var remotes = allNodes.filter(n => n.remote_id)
            remotes.forEach(function (r) {
              var re = new RegExp(r.remote_id.replace(/-/g, '_'))
              eligibleNodes = eligibleNodes.concat(
                allNodes.filter(n => re.test(n.path))
              )
            })
          } else {
            eligibleNodes = []
          }
        }

        var dueNodes = eligibleNodes.filter(
          n =>
            (n.assigned_to || n.due_at) &&
            (!user.settings.show_assigned_to ||
              (user.settings.show_assigned_to === n.assigned_to &&
                user.settings.show_assigned_to)) &&
            !n.completed
        )
        // Parsing the date is DOG SLOW - avoid at all costs (not sure why it was there is the first place, sorting the text representation seems to work)
        // dueNodes = _.sortBy(dueNodes, function(n) { return (typeof n.due_at === 'string') ? Date.parse(n.due_at) : n.due_at; });
        dueNodes = _.uniq(_.sortBy(dueNodes, 'due_at'))
        dueNodes.reverse()

        var dueItems = dueNodes.map(n => {
          var dueStr, dueClassesObj, dueClasses, due

          dueClassesObj = {
            'due-date': true
          }

          var heading
          if (n.due_at) {
            var now = new Date()
            var nowStr = Misc.dueStr(now)
            due = n.due_at
            if (typeof due === 'string') {
              var dueParsed = Chrono.parse(due)
              due = dueParsed[0].start.date()
            }

            dueStr = Misc.dueStr(due)
            var veryOverdue = Boolean(
              due.getTime() + 86400 * 1000 * 7 < now.getTime()
            )
            var overdue = Boolean(due < now)
            var today = Boolean(nowStr === dueStr)
            dueClassesObj['today'] = today
            dueClassesObj['overdue'] = !veryOverdue && !today && overdue
            dueClassesObj['very-overdue'] = veryOverdue
            if (today && !todayShown) {
              heading = (
                <div key='due-heading-today' className='sub-heading today'>
                  <h4>Today</h4>
                </div>
              )
              todayShown = true
            } else if (overdue && !overdueShown) {
              heading = (
                <div key='due-heading-overdue' className='sub-heading overdue'>
                  <h4>Overdue</h4>
                </div>
              )
              overdueShown = true
            } else if (!overdue && !today && !upcomingShown) {
              heading = (
                <div
                  key='due-heading-upcoming'
                  className='sub-heading upcoming'
                >
                  <h4>Upcoming</h4>
                </div>
              )
              upcomingShown = true
            }
          } else {
            if (!noDateShown) {
              heading = (
                <div key='due-heading-no-date' className='sub-heading no-date'>
                  <h4>No date</h4>
                </div>
              )
              noDateShown = true
            }
          }
          dueClasses = classNames(dueClassesObj)

          // var duePicker;
          // if (_this.state.duePickerNode && _this.state.duePickerNode.id === n.id) {
          //   var dueDate = moment(n.due_at);
          //   duePicker = (
          //     <div id="due-date-input">
          //       <DatePicker
          //         selected={dueDate}
          //         focus={true}
          //         noInput={true}
          //         placeholder="today, fri, 12/1/11, aug 22"
          //         onClickOutside={_this.handleClickOutsideDatepicker}
          //         onSelect={_this.handleDueDateSelect} />
          //   </div>
          //   );
          // }

          return (
            <div key={'due-path-container-' + n.id} className={dueClasses}>
              {heading}
              <SingleNode
                app={this.props.app}
                mode='due'
                time={due}
                user={n.assigned_to}
                showPath={false}
                node={n}
              />
            </div>
          )
        })
        if (dueNodes.length) {
          dueList = (
            <div id='due-list'>
              {dueDateHeading}
              <div className='due-blocks'>{dueItems}</div>
              {/*
              <div className="show-more">Show more...</div>
              */}
            </div>
          )
        } else {
          // if (!user.settings.auto_hide_due_list) {
          // eslint-disable-next-line
          if (true) {
            dueList = (
              <div id='due-list'>
                {dueDateHeading}
                <div className='no-items'>Nothing found.</div>
              </div>
            )
          }
        }
      }
    }

    return <div className='panel'>{dueList}</div>
  }
}

module.exports = DueList
