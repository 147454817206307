// lodash methods in use:
// sortBy
// random
// uniq
// without
// difference
// union
// extend
// keys
// compact
// omit
// flatten
// pick

require('../stylesheets/app.scss')
var App = require('../containers/app')

var $ = require('jquery')
window.$ = $
var $script = require('scriptjs')

var externalScripts = [
  '',
  // 'https://js.stripe.com/v2/',
  'https://use.typekit.net/yok1wla.js' // Nimbus Sans (Ole)
  // 'https://use.typekit.net/lgc2nek.js', // Proxima Nova (Stian)
  // 'https://api.filestackapi.com/filestack.js',
]

var externalStylesheets = [
  'https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,600',
  'https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css'
]

$script(externalScripts, function () {
  require('../js/app-init')

  externalStylesheets.forEach(function (url) {
    $('head').append(
      '<link rel="stylesheet" type="text/css" href="' + url + '">'
    )
  })

  var React = require('react')
  var ReactDOM = require('react-dom')

  ReactDOM.render(<App />, document.querySelector('#app-container'))

  if (module && module.hot) {
    module.hot.accept('../containers/app', () => {
      const NextRootContainer = require('../containers/app')
      ReactDOM.render(
        <NextRootContainer />,
        document.querySelector('#app-container')
      )
    })
  }

  require('../js/app-post-init')
})
