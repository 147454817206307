import React, { Component } from 'react'

import Node from '../../js/node'

export default class BoardCompletionStatus extends Component {
  render () {
    const { app, node } = this.props
    if (['project', 'todo'].indexOf(node.type) === -1) {
      return false
    }

    const completionStatus = Node.completionStatus(
      node,
      app.state.entities.nodes
    )
    if (completionStatus.total) {
      return (
        <div className='kb-percentage'>
          <div className='percentage'>
            <span className={'pct-' + completionStatus.percentage} />
          </div>
        </div>
      )
    } else {
      return false
    }
  }
}
