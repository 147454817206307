import React from 'react'
// import sortBy from 'lodash.sortby'

import Node from '../../js/node'

import './styles.css'

export default class CompanyPicker extends React.Component {
  constructor (props) {
    super(props)
    const { app } = props
    const node = app.node(app.state.floater.data.nodeId)
    this.state = { str: ((node.data || {}).company || {}).name || '' }
  }

  handleInputKeyPress = ev => {
    if (ev.key === 'Enter') {
      const { app } = this.props
      const node = app.node(app.state.floater.data.nodeId)
      const data = { ...(node.data || {}), company: { name: this.state.str } }
      Node.modify(node.id, { data }, app.getCommonParams(), () => {
        app.setState({ floater: null })
      })
    }
  }

  handlePick = id => {
    const { app } = this.props
    const node = app.node(app.state.floater.data.nodeId)
    const data = { ...(node.data || {}), company: { id } }
    Node.modify(node.id, { data }, app.getCommonParams(), () => {
      app.setState({ floater: null })
    })
  }

  render () {
    const { app } = this.props
    const node = app.node(app.state.floater.data.nodeId)
    const company = (node.data || {}).company || {}
    const allNodes = app.orgNodes()
    const nodes = allNodes.filter(n => n.type === 'company')

    return (
      <div className='people-picker'>
        <div className='input-container'>
          <input
            placeholder='Type a company name'
            value={this.state.str}
            onKeyPress={this.handleInputKeyPress}
            onChange={ev => this.setState({ str: ev.target.value })}
          />
        </div>
        <div className='suggestions'>
          {Boolean(nodes.length) && (
            <div>
              <h5>Companies</h5>
              <ul>
                {nodes.map(n => {
                  const checked = company.id === n.id
                  return (
                    <li
                      key={n.id}
                      onClick={() => this.handlePick(n.id)}
                      className={`company-row ${checked ? 'checked' : ''}`}
                    >
                      <div className='icon'>{app.nodeIcon(n)}</div>
                      <div className='name'>{n.title}</div>
                      <div className='check'>
                        <i className='fa fa-fw fa-check' />
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          )}
        </div>
      </div>
    )
  }
}
