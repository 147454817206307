import React, { Component } from 'react'
import Node from '../../js/node'
import Tab from '../../js/tab'

import BoardList from '../board_list'

import './styles.css'

class Board extends Component {
  state = {
    newListTitle: ''
  }

  node = () => {
    const { boardNode } = this.props
    return boardNode
  }

  openNode = node => {
    const { app } = this.props
    app.setSummaryNode(node.id, { activeNodeId: node.id })
  }

  addList = () => {
    const { app, boardNode } = this.props
    const nodes = this.props.getParents()
    const last = nodes[nodes.length - 1]
    const commonParams = app.getCommonParams()
    const newNode = Node.new(
      {
        title: this.state.newListTitle,
        parent_id: boardNode.id,
        prev_id: last ? last.id : null,
        user_id: boardNode.user_id,
        org_id: boardNode.org_id
      },
      commonParams
    )
    Tab.expand(this.props.tab, newNode.id, commonParams, tabParams => {
      Node.add(newNode, tabParams, () => {
        this.setState({ newListTitle: '' }, () => {
          this.newListInput.focus()
          this.board.scrollLeft = this.board.scrollWidth
        })
      })
    })
  }

  handleKeyDown = ev => {
    if (ev.which === 13) {
      ev.preventDefault()
      ev.stopPropagation()
      this.addList()
    }
  }

  render () {
    // const { x, y, connectDropTarget, isOver } = this.props; // eslint-disable-line
    return (
      <div
        className='kb-board noselect nodes'
        ref={board => {
          this.board = board
        }}
      >
        <div className='board-card-mover kb-card' />
        <div className='board-card-placeholder' />
        <div className='board-list-mover kb-list' />
        <div className='board-list-placeholder' />
        {this.props
          .getParents()
          .map(p => (
            <BoardList
              key={p.id}
              board={this}
              app={this.props.app}
              tab={this.props.tab}
              orgNodes={this.props.orgNodes}
              listNode={p}
            />
          ))}
        <div className='kb-list-container add-new'>
          <div className='kb-list'>
            <textarea
              ref={newListInput => {
                this.newListInput = newListInput
              }}
              placeholder='Add a list...'
              value={this.state.newListTitle}
              onChange={ev => this.setState({ newListTitle: ev.target.value })}
              onKeyDown={this.handleKeyDown}
            />
          </div>
        </div>
      </div>
    )
  }
}

// export default DropTarget('board-list', nodeTarget, collect)(Board);
export default Board
