import React from 'react'

class BillingHistorySection extends React.Component {
  render () {
    return (
      <div className='form'>
        <em>Coming soon!</em>
      </div>
    )
  }
}

module.exports = BillingHistorySection
