import React from 'react'

// import moment from 'moment'

// import Misc from '../../js/misc'
// import Node from '../../js/node'
// import Keys from '../../js/keys'

class RevisionBrowser extends React.Component {
  render () {
    return <div>Revisions</div>
    // var previousVersions, previewNode, diffNodes, revertNode
    // if (this.props.app.state.browsingRevisionsFor) {
    //   var revisions = []
    //   var i = 0
    //   var revision
    //   if (this.props.app.state.nodeRevisions) {
    //     if (this.props.app.state.nodeRevisions.length) {
    //       while ((revision = this.props.app.state.nodeRevisions[i])) {
    //         var nextRev = this.props.app.state.nodeRevisions[i + 1]
    //         var prevRev = this.props.app.state.nodeRevisions[i - 1]
    //         var viewHandler = this.props.app.previewRevision.bind(
    //           this.props.app,
    //           revision
    //         )
    //         var diffHandler = this.props.app.diffRevisions.bind(
    //           this.props.app,
    //           revision,
    //           nextRev
    //         )
    //         var compareToCurrentHandler = this.props.app.diffRevisions.bind(
    //           this.props.app,
    //           revision,
    //           null
    //         )
    //         var revertHandler = this.props.app.openRevertRevision.bind(
    //           this.props.app,
    //           revision
    //         )
    //         var diffCode = null
    //         var compareCode = null
    //         var revertCode = null
    //         if (nextRev) {
    //           diffCode = (
    //             <a href='' onClick={diffHandler}>
    //               diff from prev
    //             </a>
    //           )
    //         }

    //         if (prevRev) {
    //           revertCode = (
    //             <a href='' onClick={revertHandler}>
    //               revert
    //             </a>
    //           )
    //           compareCode = (
    //             <a href='' onClick={compareToCurrentHandler}>
    //               compare to curr
    //             </a>
    //           )
    //         }

    //         revisions.push(
    //           <tr key={revision.id}>
    //             <td>
    //               <time
    //                 dateTime={revision.created_at}
    //                 title={revision.created_at}
    //               >
    //                 {moment(revision.created_at).fromNow()}
    //               </time>
    //             </td>
    //             <td>{revision.username ? revision.username : revision.ip}</td>
    //             <td>{revision.bytes_changed}</td>
    //             <td>
    //               <a href='' onClick={viewHandler}>
    //                 view
    //               </a>
    //             </td>
    //             <td>{diffCode}</td>
    //             <td>{compareCode}</td>
    //             <td>{revertCode}</td>
    //           </tr>
    //         )
    //         i++
    //       }
    //     } else {
    //       revisions.push(
    //         <tr key='loading'>
    //           <td colSpan='3'>No history found.</td>
    //         </tr>
    //       )
    //     }
    //   } else {
    //     revisions.push(
    //       <tr key='loading'>
    //         <td colSpan='3'>Please wait...</td>
    //       </tr>
    //     )
    //   }

    //   previousVersions = (
    //     <div id='revision-browser' className='browser'>
    //       <div>
    //         <div className='header'>
    //           <div className='close-x'>
    //             <a
    //               href=''
    //               className='btn'
    //               onClick={this.props.app.closeRevisionBrowser}
    //             >
    //               <span className='octicon octicon-x' />
    //             </a>
    //           </div>
    //           Previous versions of {'"'}
    //           {Misc.truncate(
    //             this.props.app.state.browsingRevisionsFor.title,
    //             30
    //           )}
    //           {'"'}
    //         </div>
    //         <div className='content'>
    //           <table className='full-width'>
    //             <tbody>{revisions}</tbody>
    //           </table>
    //         </div>
    //         <div className='footer'>
    //           <a
    //             href=''
    //             className='btn'
    //             onClick={this.props.app.closeRevisionBrowser}
    //           >
    //             Close
    //           </a>
    //         </div>
    //       </div>
    //     </div>
    //   )
    // }

    // if (this.props.app.state.previewingRevision) {
    //   // TODO: DRY this up
    //   previewNode = this.props.app.state.previewingRevision.node
    //   var urlText = Node.title(previewNode)
    //     .replace(Keys.urlRegex, Keys.urlReplacer)
    //     .replace(/\s$/, '&nbsp;')
    //     .replace(/;$/, '')
    //   var title = urlText
    //     .replace(Keys.linkRegex, Keys.linkReplacer)
    //     .replace(/\s$/, '&nbsp;')

    //   // var title = Node.title(previewNode).replace(linkRegex, linkReplacer);
    //   var createdAt = Misc.timeFor(previewNode.created_at)
    //   var updatedAt = Misc.timeFor(previewNode.updated_at)
    //   var updatedAtEl
    //   if (createdAt !== updatedAt) {
    //     updatedAtEl = (
    //       <span className='updated-time'> &mdash; Updated {updatedAt}</span>
    //     )
    //   }

    //   var previewRevertHandler = this.props.app.openRevertRevision.bind(
    //     this.props.app,
    //     this.props.app.state.previewingRevision
    //   )
    //   previewNode = (
    //     <div id='preview-node' className='browser'>
    //       <div>
    //         <div className='header'>
    //           <div className='close-x'>
    //             <a
    //               href=''
    //               className='btn'
    //               onClick={this.props.app.closePreviewRevision}
    //             >
    //               <span className='octicon octicon-x' />
    //             </a>
    //           </div>
    //           Viewing revision from{' '}
    //           {this.props.app.state.previewingRevision.created_at}
    //         </div>
    //         <div className='node content'>
    //           <h2
    //             className='node-heading with-date'
    //             dangerouslySetInnerHTML={{ __html: title }}
    //           />
    //           <div className='dates'>
    //             <span className='created-time'>{createdAt} </span>
    //             {updatedAtEl}
    //           </div>
    //           <div
    //             id='note-body'
    //             className='note-body'
    //             dangerouslySetInnerHTML={{
    //               __html: this.props.app.state.previewingRevision._parsedBody
    //             }}
    //           />
    //         </div>
    //         <div className='footer'>
    //           <a href='' className='btn' onClick={previewRevertHandler}>
    //             Revert to this version
    //           </a>&nbsp;&nbsp;&nbsp;
    //           <a
    //             href=''
    //             className='btn'
    //             onClick={() =>
    //               this.props.app.diffRevisions(
    //                 this.props.app.state.previewingRevision,
    //                 null
    //               )
    //             }
    //           >
    //             View diff
    //           </a>&nbsp;&nbsp;&nbsp;
    //           <a
    //             href=''
    //             className='btn'
    //             onClick={this.props.app.closePreviewRevision}
    //           >
    //             Close
    //           </a>
    //         </div>
    //       </div>
    //     </div>
    //   )
    // }

    // if (this.props.app.state.diffingRevisions) {
    //   diffNodes = (
    //     <div id='diff-nodes' className='browser'>
    //       <div>
    //         <div className='header'>
    //           <div className='close-x'>
    //             <a
    //               href=''
    //               className='btn'
    //               onClick={this.props.app.closeDiffRevision}
    //             >
    //               <span className='octicon octicon-x' />
    //             </a>
    //           </div>
    //           Diff from version at{' '}
    //           {this.props.app.state.diffingRevisions.older.created_at}
    //         </div>
    //         <div
    //           className='diff content'
    //           dangerouslySetInnerHTML={{
    //             __html: this.props.app.state.diffingRevisions.diff.replace(
    //               /\n/gm,
    //               '<br />'
    //             )
    //           }}
    //         />
    //         <div className='footer'>
    //           <a
    //             href=''
    //             className='btn'
    //             onClick={this.props.app.closeDiffRevision}
    //           >
    //             Close
    //           </a>
    //         </div>
    //       </div>
    //     </div>
    //   )
    // }

    // if (this.props.app.state.revertingRevision) {
    //   revertNode = (
    //     <div id='revert-node' className='browser'>
    //       <div>
    //         <div className='header'>
    //           <div className='close-x'>
    //             <a
    //               href=''
    //               className='btn'
    //               onClick={this.props.app.closeRevertRevision}
    //             >
    //               <span className='octicon octicon-x' />
    //             </a>
    //           </div>
    //           Revert to version @{' '}
    //           {this.props.app.state.revertingRevision.created_at}?
    //         </div>
    //         <div className='revert content'>
    //           <h4>Choose what should be reverted</h4>
    //           <div>
    //             <input type='checkbox' id='revert-body' defaultChecked /> Body
    //             text
    //           </div>
    //           <div>
    //             <input
    //               type='checkbox'
    //               id='revert-title'
    //               defaultChecked={false}
    //             />{' '}
    //             Title
    //           </div>
    //           <div>
    //             <input
    //               type='checkbox'
    //               id='revert-description'
    //               defaultChecked={false}
    //             />{' '}
    //             Description
    //           </div>
    //         </div>
    //         <div className='footer'>
    //           <a
    //             href=''
    //             className='btn'
    //             onClick={this.props.app.revertRevision}
    //           >
    //             Revert
    //           </a>&nbsp;&nbsp;&nbsp;
    //           <a
    //             href=''
    //             className='btn'
    //             onClick={this.props.app.closeRevertRevision}
    //           >
    //             Cancel
    //           </a>
    //         </div>
    //       </div>
    //     </div>
    //   )
    // }
    // return (
    //   <div>
    //     {previousVersions}
    //     {previewNode}
    //     {diffNodes}
    //     {revertNode}
    //   </div>
    // )
  }
}

module.exports = RevisionBrowser
