import React, { Component } from 'react'
import moment from 'moment'

import Avatar from '../avatar'

import Misc from '../../js/misc'

export function hasFooterContent (node, users) {
  return node.comment_count || node.like_count || node.due_at || users.length
}

export default class BoardFooter extends Component {
  toggleFilter = user => {
    const { app } = this.props
    const oldFilter = app.state.filter || {}
    const oldUsers = oldFilter.users || []
    const newUsers =
      oldUsers.indexOf(user.id) === -1
        ? oldUsers.concat([user.id])
        : oldUsers.filter(u => u !== user.id)
    app.setAppState({ filter: { ...oldFilter, users: newUsers } })
  }

  render () {
    const { app, node, users } = this.props
    return !hasFooterContent(node, users) ? (
      false
    ) : (
      <div className='kb-footer'>
        {Boolean(node.due_at) && (
          <span className='due-date'>
            <i className='fa fa-fw fa-clock-o' />{' '}
            {Misc.dueStr(moment(node.due_at).toDate())}
          </span>
        )}
        {Boolean(node.comment_count) && (
          <span
            className='comment-count'
            onClick={() => app.setCommentNode(node.id)}
          >
            {node.comment_count}
            <i className='fa fa-fw fa-comment-o' />
          </span>
        )}
        {Boolean(node.like_count) && (
          <span
            className='like-count'
            onClick={() => app.setLikesNode(node.id)}
          >
            {node.like_count}
            <i className='fa fa-fw fa-heart-o' />
          </span>
        )}
        {Boolean(users.length) && (
          <div
            className='assigned-to'
            onClick={() => app.setSummaryNode(node.id, { mode: 'assign' })}
          >
            {users.map(u => {
              const user = app.state.entities.users.find(
                e => e.id === u.assigned_to
              )
              return (
                <Avatar
                  key={u.id}
                  app={app}
                  user={user}
                  onClick={ev => {
                    ev.stopPropagation()
                    this.toggleFilter(user)
                  }}
                />
              )
            })}
          </div>
        )}
      </div>
    )
  }
}
