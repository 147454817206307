import React from 'react'
// import Project from './project'
// import Trip from './trip'
// import Event from './event'
// import Todo from './todo'
// import Bookmark from './bookmark'
// import Meeting from './meeting'
// import Person from './person'
// import Company from './company'
// import Site from './site'
import Shared from './shared'

import './styles.css'

export default class NodeTypeData extends React.Component {
  state = {
    adding: null
  }

  getComponent = () => {
    switch (this.props.node.type) {
      // case 'project':
      //   return <Project app={this.props.app} node={this.props.node} />
      // case 'trip':
      //   return <Trip app={this.props.app} node={this.props.node} />
      // case 'event':
      //   return <Event app={this.props.app} node={this.props.node} />
      // case 'todo':
      //   return <Todo app={this.props.app} node={this.props.node} />
      // case 'bookmark':
      //   return <Bookmark app={this.props.app} node={this.props.node} />
      // case 'meeting':
      //   return <Meeting app={this.props.app} node={this.props.node} />
      // case 'person':
      //   return <Person app={this.props.app} node={this.props.node} />
      // case 'company':
      //   return <Company app={this.props.app} node={this.props.node} />
      // case 'site':
      //   return <Site app={this.props.app} node={this.props.node} />
      // case 'list':
      //   return false
      default:
        return false
    }
  }

  handleDataTypePick = type => {
    const { app } = this.props
    console.log('picking', type)
    app.setState({ addingData: type })
  }

  render () {
    const { app, node } = this.props
    const component = this.getComponent()
    const data = node.data || {}
    const alwaysShow = app.state.nodeTypesWithData.indexOf(node.type) !== -1
    return (
      <div className={`node-type-data ${node.type}`}>
        {!this.props.isReadOnly && (
          <div
            className='data-type-adder-button'
            onClick={ev =>
              app.toggleFloater(ev.target, 'data-type-adder', {
                onPick: this.handleDataTypePick,
                nodeId: node.id
              })
            }
          >
            <i className='fa fa-fw fa-plus-square' />
            <span>Add additional data</span>
          </div>
        )}
        {Boolean(
          app.state.addingData || Object.keys(data).length || alwaysShow
        ) && (
          <div className='node-type-data-inner'>
            {component}
            <Shared app={app} node={node} adding={app.state.addingData} />
          </div>
        )}
      </div>
    )
  }
}
