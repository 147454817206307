import $ from 'jquery'

module.exports.CcHandling = {

  toggleCCForm: function (component, ev) {
    component.setState({invalidFields: [], error: null, ccFormOpen: !component.state.ccFormOpen}, () => {
      // if (component.state.ccFormOpen) {
      //   $('#cc-number').payment('formatCardNumber');
      //   $('#cc-cvc').payment('formatCardCVC');
      //   $('#cc-exp').payment('formatCardExpiry');
      // }
      if (component.yearly) {
        component.yearly.click()
        component.yearly.focus()
      }
    })
    ev.preventDefault()
  },

  changePlan: function (component, ev) {
    component.props.app.getConfirmation(() => {
      var data = {plan_id: component.state.selectedPlan}
      var org
      if (component.getCurrentOrg) {
        org = component.getCurrentOrg()
      }
      if (org) {
        data.org_id = org.id
      }
      component.props.app.ajax('change_plan', {body: data}, () => {
        component.setState({ccFormOpen: false})
      })
    })
    ev.preventDefault()
  },

  stripeResponseHandler: function (component, status, response) {
    var $form = $('#payment-form')

    if (response.error) {
      // Show the errors on the form
      $form.find('.payment-errors').text(response.error.message)
      $form.find('button').prop('disabled', false)
    } else {
      // response contains id and card, which contains additional card details
      var data = {response: response}
      var org
      if (component.getCurrentOrg) {
        org = component.getCurrentOrg()
      }
      if (org) {
        data.org_id = org.id
      }
      component.props.app.ajax('add_or_update_cc', {body: data}, json => {
        $form.find('button').prop('disabled', false)
        if (json.error) {
          component.setState({error: json.error.message})
        } else {
          component.setState({ccFormOpen: false})
          // _component.props.app.syncClientData();
        }
      },
      (res) => { // error
        $form.find('button').prop('disabled', false)
        component.setState({error: res.text})
        console.log('An error occurred while communicating with the server: ' + res.text) // eslint-disable-line no-console
      })
    }
  },

  validateCard: function (component, callback) {
    var invalidFields = []
    var exp = $('#cc-exp').payment('cardExpiryVal')
    if (!$.payment.validateCardNumber($('#cc-number').val())) invalidFields.push('number')
    if (!$.payment.validateCardCVC($('#cc-cvc').val())) invalidFields.push('cvc')
    if (!$.payment.validateCardExpiry(exp.month, exp.year)) invalidFields.push('expiry')
    component.setState({error: null, invalidFields: invalidFields}, callback)
  },

  addCC: function (component, ev) {
    component.props.stripe.createToken().then(payload => {
      module.exports.CcHandling.stripeResponseHandler(component, 'OK', payload)
    })
    // component.validateCard(() => {
    //   if (!component.state.invalidFields.length) {
    //     $('#payment-form').find('button').prop('disabled', true);
    //     var exp = $('#cc-exp').payment('cardExpiryVal');
    //     Stripe.card.createToken({ // eslint-disable-line no-undef
    //       number: $('#cc-number').val(),
    //       cvc: $('#cc-cvc').val(),
    //       exp_month: exp.month,
    //       exp_year: exp.year,
    //     }, component.stripeResponseHandler);
    //   }
    // });
    ev.preventDefault()
  }

}
