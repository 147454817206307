var uuid = require('node-uuid')
var update = require('react-addons-update')

var Node = require('./node')

var Comment = {

  debug: false,

  add: function (comment, params, callback) {
    const doAction = (actionParams) => {
      const newParams = Object.assign({}, actionParams)
      const nodes = actionParams.updatedState.entities ? actionParams.updatedState.entities.nodes : actionParams.state.entities.nodes
      const comments = actionParams.updatedState.entities ? actionParams.updatedState.entities.comments : actionParams.state.entities.comments
      const updatedNodes = (actionParams.updatedState.updatedNodes || actionParams.state.updatedNodes || []).slice()

      const newComment = Comment.new(comment, actionParams)
      const newComments = comments.concat([newComment])

      const node = Node.get(newComment.node_id, nodes)
      let newNode = node
      if (!params._remote) {
        newNode = update(node, {$merge: {comment_count: node.comment_count + 1, updated_at: new Date().toISOString()}})
      }
      const trimmedNodes = nodes.filter(n => n.id !== newNode.id)
      const newNodes = trimmedNodes.concat([newNode])
      updatedNodes.push(newNode)

      const undoData = {
        undo: [{obj: 'Comment', cmd: 'remove', args: [comment.id]}],
        redo: [{obj: 'Comment', cmd: 'add', args: [comment]}]
      }

      if (!actionParams._isUndoOperation && !actionParams._skipUndoAdd) {
        actionParams.undo.add(undoData, true)
      }

      const newEntities = Object.assign({}, (actionParams.updatedState.entities || actionParams.state.entities))
      newEntities.nodes = newNodes
      newEntities.comments = newComments
      const result = {
        entities: newEntities,
        updatedNodes: updatedNodes
      }

      newParams.updatedState = Object.assign({}, actionParams.updatedState, result)
      newParams.undoData = actionParams.undoData || {}
      newParams.undoData.undo = (newParams.undoData.undo || []).concat(undoData.undo)
      newParams.undoData.redo = (newParams.undoData.redo || []).concat(undoData.redo)

      if (actionParams._skipSetState) {
        if (typeof callback === 'function') {
          callback(newParams)
        }
      } else {
        actionParams.setAppState(newParams.updatedState, newParams.stateParams, () => {
          if (typeof callback === 'function') {
            callback(newParams)
          }
        })
      }
    }

    if (params._skipFlushUndo) {
      doAction(params)
    } else {
      params.flushTextUndo(params, ret => {
        doAction(ret)
      })
    }
  },

  remove: function (commentId, params, callback) {
    const doAction = (actionParams) => {
      const newParams = Object.assign({}, actionParams)
      const nodes = actionParams.updatedState.entities ? actionParams.updatedState.entities.nodes : actionParams.state.entities.nodes
      const comments = actionParams.updatedState.entities ? actionParams.updatedState.entities.comments : actionParams.state.entities.comments
      const comment = Comment.get(commentId, comments)
      const newComments = comments.filter(c => c.id !== commentId)
      const updatedNodes = (actionParams.updatedState.updatedNodes || actionParams.state.updatedNodes || []).slice()

      var node = Node.get(comment.node_id, nodes)
      var newNode = node
      if (!params._remote) {
        newNode = update(node, {$merge: {comment_count: node.comment_count - 1, updated_at: new Date().toISOString()}})
      }
      var trimmedNodes = nodes.filter(n => n.id !== newNode.id)
      var newNodes = trimmedNodes.concat([newNode])
      updatedNodes.push(newNode)

      const undoData = {
        undo: [{obj: 'Comment', cmd: 'add', args: [comment]}],
        redo: [{obj: 'Comment', cmd: 'remove', args: [comment.id]}]
      }

      if (!actionParams._isUndoOperation && !actionParams._skipUndoAdd) {
        actionParams.undo.add(undoData, true)
      }

      const newEntities = Object.assign({}, (actionParams.updatedState.entities || actionParams.state.entities))
      newEntities.nodes = newNodes
      newEntities.comments = newComments
      const result = {
        entities: newEntities,
        updatedNodes: updatedNodes
      }

      newParams.updatedState = Object.assign({}, actionParams.updatedState, result)
      newParams.undoData = actionParams.undoData || {}
      newParams.undoData.undo = (newParams.undoData.undo || []).concat(undoData.undo)
      newParams.undoData.redo = (newParams.undoData.redo || []).concat(undoData.redo)

      if (actionParams._skipSetState) {
        if (typeof callback === 'function') {
          callback(newParams)
        }
      } else {
        actionParams.setAppState(newParams.updatedState, newParams.stateParams, () => {
          if (typeof callback === 'function') {
            callback(newParams)
          }
        })
      }
    }

    if (params._skipFlushUndo) {
      doAction(params)
    } else {
      params.flushTextUndo(params, ret => {
        doAction(ret)
      })
    }
  },

  modify: function (commentOrId, data, params, callback) {
    const doAction = (actionParams) => {
      const newParams = Object.assign({}, actionParams)
      const comments = actionParams.updatedState.entities ? actionParams.updatedState.entities.comments : actionParams.state.entities.comments
      const comment = Comment.get(commentOrId, comments)
      const newComment = update(comment, {$merge: {updated_at: new Date().toISOString()}})
      const oldData = {}
      for (const key in data) {
        const keys = key.split('.')
        let obj = newComment
        for (let i = 0; i <= keys.length - 1; i++) {
          if (i === keys.length - 1) {
            obj[keys[i]] = data[key]
          }

          oldData[key] = comment[key]
          obj = obj[keys[i]]
        }
      }
      const trimmedComments = comments.filter(c => c.id !== newComment.id)
      const newComments = trimmedComments.concat([newComment])

      const undoData = {
        undo: [{obj: 'Comment', cmd: 'modify', args: [comment.id, oldData]}],
        redo: [{obj: 'Comment', cmd: 'modify', args: [comment.id, data]}]
      }

      if (!actionParams._isUndoOperation && !actionParams._skipUndoAdd) {
        actionParams.undo.add(undoData, true)
      }

      const newEntities = Object.assign({}, (actionParams.updatedState.entities || actionParams.state.entities))
      newEntities.comments = newComments
      const result = {entities: newEntities}

      newParams.updatedState = Object.assign({}, actionParams.updatedState, result)
      newParams.undoData = actionParams.undoData || {}
      newParams.undoData.undo = (newParams.undoData.undo || []).concat(undoData.undo)
      newParams.undoData.redo = (newParams.undoData.redo || []).concat(undoData.redo)

      if (actionParams._skipSetState) {
        if (typeof callback === 'function') {
          callback(newParams)
        }
      } else {
        actionParams.setAppState(newParams.updatedState, newParams.stateParams, () => {
          if (typeof callback === 'function') {
            callback(newParams)
          }
        })
      }
    }

    if (params._skipFlushUndo) {
      doAction(params)
    } else {
      params.flushTextUndo(params, ret => {
        doAction(ret)
      })
    }
  },

  get: function (commentOrId, comments) {
    if (typeof commentOrId === 'string') {
      return comments.find(c => c.id === commentOrId)
    } else {
      return commentOrId
    }
  },

  new: function (data) {
    var time = new Date().toISOString()
    if (typeof data === 'undefined') data = {}
    data.id = uuid.v4()
    data.created_at = time
    data.updated_at = time
    return data
  }

}

module.exports = Comment
