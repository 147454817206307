import React from 'react'

import Node from '../../../js/node'

class TabSettingsOverlay extends React.Component {
  constructor (props) {
    super(props)
    this.tab = props.panel.opts.data.tab
    var state = {
      name: this.tab.name,
      pinned: this.tab.pinned,
      rootId: this.tab.root_id,
      nodeId: this.tab.node_id
    }
    state.wasPinned = state.pinned
    state.initialRootId = state.rootId
    if (this.tab.settings.hasOwnProperty('hide_completed')) {
      state.hide_completed = this.tab.settings.hide_completed
    }
    if (this.tab.settings.hasOwnProperty('hide_widgets')) {
      state.hide_widgets = this.tab.settings.hide_widgets
    }
    this.state = state
  }

  handleNameChange = ev => {
    this.setState({ name: ev.target.value })
  }

  handlePinnedChange = ev => {
    var newState = { pinned: ev.target.checked }
    if (newState.pinned) {
      var rootId = this.props.rootNode ? this.props.rootNode.id : null
      newState.rootId = rootId
    }
    this.setState(newState)
  }

  handlePinnedRootChange = ev => {
    this.setState({ rootId: ev.target.value })
  }

  disableCompletedOverride = () => {
    var state = this.state
    delete state.hide_completed
    this.setState(state)
  }

  hideCompleted = () => {
    this.setState({ hide_completed: true })
  }

  showCompleted = () => {
    this.setState({ hide_completed: false })
  }

  disableWidgetsOverride = () => {
    var state = this.state
    delete state.hide_widgets
    this.setState(state)
  }

  hideWidgets = () => {
    this.setState({ hide_widgets: true })
  }

  showWidgets = () => {
    this.setState({ hide_widgets: false })
  }

  save = () => {
    var _this = this
    var tab = this.props.panel.opts.data.tab
    var newValues = {
      name: this.name.value,
      pinned: this.pinned.checked
    }
    if (newValues.pinned) {
      newValues.root_id = this.state.rootId
    } else {
      newValues.root_id = null
    }
    var newSettings = {}
    var settingsToDelete = []
    if (this.state.hasOwnProperty('hide_completed')) {
      newSettings.hide_completed = this.state.hide_completed
    } else {
      settingsToDelete.push('hide_completed')
    }
    if (this.state.hasOwnProperty('hide_widgets')) {
      newSettings.hide_widgets = this.state.hide_widgets
    } else {
      settingsToDelete.push('hide_widgets')
    }
    this.props.app.updateTab(
      tab,
      newValues,
      newSettings,
      settingsToDelete,
      function () {
        _this.setState({ error: null })
        _this.props.app.setPanel(null)
      }
    )
  }

  render () {
    var generalError, nameError
    var userDefaultCompleted = 'show'
    if (
      this.props.user &&
      this.props.user.settings &&
      this.props.user.settings.hide_completed
    ) {
      userDefaultCompleted = 'hide'
    }
    // var userDefaultWidgets = 'show';
    // if (this.props.user &&
    //     this.props.user.settings &&
    //     this.props.user.settings.hide_widgets) {
    //   userDefaultWidgets = 'hide';
    // }
    var pinnedChangeStuff, pinnedRoot, initialPinnedRoot
    var initialRootId = this.state.initialRootId
    if (initialRootId) {
      initialPinnedRoot = Node.get(
        initialRootId,
        this.props.app.state.entities.nodes
      )
      if (!initialPinnedRoot) {
        initialRootId = null
      }
    }
    var rootId = this.state.rootId
    if (rootId) {
      pinnedRoot = Node.get(rootId, this.props.app.state.entities.nodes)
      if (!pinnedRoot) {
        rootId = null
      }
    }
    var currentRootId = this.props.rootNode ? this.props.rootNode.id : null
    // We use != here (instead of !==) to ensure that undefined and null compare as the same value
    if (
      // eslint-disable-next-line eqeqeq
      (currentRootId != rootId || rootId != initialRootId) &&
      this.state.pinned &&
      this.state.wasPinned
    ) {
      // eslint-disable-line eqeqeq
      var currentTitle = 'the root of my Notebase'
      if (initialPinnedRoot) {
        currentTitle = '"' + initialPinnedRoot.title + '"'
      }
      var newTitle = this.props.rootNode
        ? ' the current node ("' + this.props.rootNode.title + '")'
        : ' the root of my Notebase'
      pinnedChangeStuff = (
        <div className='sub-container'>
          <div className='sub-row'>
            <div className='input-label-wrapper'>
              <input
                type='radio'
                value={initialRootId}
                checked={rootId === initialRootId}
                onChange={this.handlePinnedRootChange}
                id='to-initial-root'
              />
              <label htmlFor='to-initial-root'>To {currentTitle}</label>
            </div>
            <div className='input-label-wrapper'>
              <input
                type='radio'
                value={currentRootId}
                checked={rootId !== initialRootId}
                onChange={this.handlePinnedRootChange}
                id='to-current-root'
              />
              <label htmlFor='to-current-root'>To {newTitle}</label>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div id='tab-settings'>
        {generalError}
        <div className='row'>
          <div className='row-heading'>
            <label>Name:</label>
          </div>
          <div className='row-content'>
            <input
              type='text'
              ref={name => {
                this.name = name
              }}
              value={this.state.name}
              placeholder='A descriptive name for this tab, like &quot;Work&quot;'
              onChange={this.handleNameChange}
            />
            {nameError}
          </div>
        </div>
        <div className='row'>
          <div className='row-content'>
            <div className='input-label-wrapper'>
              <input
                type='checkbox'
                ref={pinned => {
                  this.pinned = pinned
                }}
                checked={this.state.pinned}
                onChange={this.handlePinnedChange}
                id='pin-this-tab'
              />
              <label htmlFor='pin-this-tab'>Pin this tab</label>
            </div>
            {pinnedChangeStuff}
          </div>
        </div>
        <div className='row'>
          <div className='row-heading'>
            <label>Show completed items in this tab?</label>
          </div>
          <div className='row-content'>
            <div className='input-label-wrapper'>
              <input
                type='radio'
                onChange={this.disableCompletedOverride}
                checked={!this.state.hasOwnProperty('hide_completed')}
                id='user-default-completed'
              />
              <label htmlFor='user-default-completed'>
                {' '}
                Use my user’s default setting ({userDefaultCompleted})
              </label>
            </div>
            <div className='input-label-wrapper'>
              <input
                type='radio'
                onChange={this.showCompleted}
                checked={this.state.hide_completed === false}
                id='show-completed'
              />
              <label htmlFor='show-completed'>Show completed items</label>
            </div>
            <div className='input-label-wrapper'>
              <input
                type='radio'
                onChange={this.hideCompleted}
                checked={this.state.hide_completed === true}
                id='hide-completed-items'
              />
              <label htmlFor='hide-completed-items'>Hide completed items</label>
            </div>
          </div>
        </div>
        {/*
        {this.props.app.featureEnabled('due_nodes') &&
          <div className="row">
            <label>Hide Due Date and Assigned To in this tab?</label>
            <div className="content radio">
              <div>
                <input type="radio" onChange={this.disableWidgetsOverride} checked={!this.state.hasOwnProperty('hide_widgets')} /><label>Use my user’s default setting ({userDefaultWidgets})</label>
              </div>
              <div>
                <input type="radio" onChange={this.showWidgets} checked={this.state.hide_widgets === false} /><label>Show Due Date/Assigned To</label>
              </div>
              <div>
                <input type="radio" onChange={this.hideWidgets} checked={this.state.hide_widgets === true} /><label>Hide Due Date/Assigned To</label>
              </div>
            </div>
          </div>
        }
        */}
        <div className='buttons'>
          <button onClick={this.save} className='button'>
            Save
          </button>
        </div>
      </div>
    )
  }
}

module.exports = TabSettingsOverlay
