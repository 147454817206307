import React from 'react'
import createReactClass from 'create-react-class'
// import $ from 'jquery';
// import Misc from '../../../../../js/misc';
import Org from '../../../../../js/org'
// import Mixins from '../../../../../mixins/mixins';
// import PlansMixins from '../../mixins/plans';

const PlansSection = createReactClass({
  displayName: 'PlansSection',

  // mixins: [
  //   Mixins.FormHandling,
  //   Mixins.ErrorHandling,
  //   PlansMixins.CcHandling,
  // ],

  getInitialState: function () {
    var state = {
      ccFormOpen: false,
      error: null,
      invalidFields: []
    }
    return state
  },

  // toggleCCForm: function(ev) {
  //   this.setState({invalidFields: [], error: null, ccFormOpen: !this.state.ccFormOpen}, function() {
  //     if (this.state.ccFormOpen) {
  //       $('#cc-number').payment('formatCardNumber');
  //       $('#cc-cvc').payment('formatCardCVC');
  //       $('#cc-exp').payment('formatCardExpiry');
  //     }
  //   });
  //   ev.preventDefault();
  // },

  // downgrade: function(ev) {
  //   var _this = this;
  //   this.props.app.getConfirmation(function() {
  //     Misc.jx(_this, 'downgrade', null, function(res) { // eslint-disable-line no-unused-vars
  //       _this.setState({ccFormOpen: false});
  //       _this.props.app.syncClientData();
  //     }.bind(_this));
  //   });
  //   ev.preventDefault();
  // },

  // stripeResponseHandler: function(status, response) {
  //   var _this = this;
  //   var $form = $('#payment-form');

  //   if (response.error) {
  //     // Show the errors on the form
  //     $form.find('.payment-errors').text(response.error.message);
  //     $form.find('button').prop('disabled', false);
  //   } else {
  //     // response contains id and card, which contains additional card details
  //     var data = {response: response};
  //     Misc.jx(this, 'cc', data,
  //       function(res) { // success
  //         $form.find('button').prop('disabled', false);
  //         if (res.body.error) {
  //           _this.setState({error: res.body.error.message});
  //         } else {
  //           _this.setState({ccFormOpen: false});
  //           _this.props.app.syncClientData();
  //         }
  //       }.bind(this),
  //       function(res) { // error
  //         $form.find('button').prop('disabled', false);
  //         _this.setState({error: res.text});
  //         console.log('An error occurred while communicating with the server: ' + res.text); // eslint-disable-line no-console
  //       }.bind(this)
  //     );
  //   }
  // },

  // validateCard: function(callback) {
  //   var invalidFields = [];
  //   var exp = $('#cc-exp').payment('cardExpiryVal');
  //   if (!$.payment.validateCardNumber($('#cc-number').val())) invalidFields.push('number');
  //   if (!$.payment.validateCardCVC($('#cc-cvc').val())) invalidFields.push('cvc');
  //   if (!$.payment.validateCardExpiry(exp.month, exp.year)) invalidFields.push('expiry');
  //   this.setState({error: null, invalidFields: invalidFields}, callback);
  // },

  // addCC: function(ev) {
  //   this.validateCard(function() {
  //     if (!this.state.invalidFields.length) {
  //       $('#payment-form').find('button').prop('disabled', true);
  //       var exp = $('#cc-exp').payment('cardExpiryVal');
  //       Stripe.card.createToken({ // eslint-disable-line no-undef
  //         number: $('#cc-number').val(),
  //         cvc: $('#cc-cvc').val(),
  //         exp_month: exp.month,
  //         exp_year: exp.year,
  //       }, this.stripeResponseHandler);
  //     }
  //   });
  //   ev.preventDefault();
  // },

  getCurrentOrg: function () {
    var org
    if (
      this.props.activeVtabId &&
      this.props.app.state.entities.orgs &&
      this.props.app.state.entities.orgs.length
    ) {
      org = Org.get(this.props.activeVtabId, this.props.app.state.entities.orgs)
    }
    return org
  },

  render: function () {
    var proStuff
    var org = this.getCurrentOrg()
    if (org) {
      var customer = this.props.app.customer(org.id)

      if (this.state.ccFormOpen) {
        var expPlaceholder = '02 / ' + (new Date().getFullYear() + 1)
        var buttonText =
          customer && customer.subscription_status === 'active'
            ? 'Save new card details'
            : 'Upgrade'
        var numberError, cvcError, expiryError
        if (this.state.invalidFields.indexOf('number') !== -1) {
          numberError = <div className='error'>Invalid credit card number.</div>
        }

        if (this.state.invalidFields.indexOf('cvc') !== -1) {
          cvcError = <div className='error'>Invalid CVC.</div>
        }

        if (this.state.invalidFields.indexOf('expiry') !== -1) {
          expiryError = <div className='error'>Invalid expiry date.</div>
        }

        var freqRow
        if (!(customer && customer.subscription_status === 'active')) {
          freqRow = (
            <div className='row'>
              <label>
                <input
                  type='radio'
                  id='cc-monthly'
                  name='pay-freq'
                  defaultChecked
                />
                <span>$7 / month</span>
              </label>
              &nbsp; &nbsp; &nbsp;
              <label>
                <input type='radio' id='cc-yearly' name='pay-freq' />
                <span>$70 / year</span>
              </label>
            </div>
          )
        }

        proStuff = (
          <form
            action=''
            method='POST'
            autoComplete='on'
            id='payment-form'
            onSubmit={this.addCC}
            noValidate
          >
            <span className='payment-errors error'>{this.state.error}</span>

            <div className='row'>
              <label>
                <span>Card Number</span>
                <input
                  type='text'
                  size='20'
                  placeholder='1234 1234 1234 1234'
                  id='cc-number'
                  pattern='\d*'
                  autoComplete='cc-number'
                  required
                />
              </label>
              {numberError}
            </div>

            <div className='row'>
              <label>
                <span>CVC</span>
                <input
                  type='text'
                  size='4'
                  autoComplete='off'
                  placeholder='123'
                  id='cc-cvc'
                />
              </label>
              {cvcError}
            </div>

            <div className='row'>
              <label>
                <span>Expiration (MM/YYYY)</span>
                <input
                  type='text'
                  size='10'
                  placeholder={expPlaceholder}
                  id='cc-exp'
                  pattern='\d*'
                  autoComplete='cc-exp'
                  required
                />
              </label>
              {expiryError}
            </div>

            {freqRow}

            <div className='buttons cc'>
              <img src='/static/stripe/dark_outline.png' />
              <div className='reassurance'>
                We never see your credit card details, that’s all handled
                securely by Stripe, an industry leader in payment solutions.
              </div>
              <button className='button primary' type='submit'>
                {buttonText}
              </button>
              &nbsp; &nbsp; &nbsp;
              <button className='button' onClick={this.toggleCCForm}>
                Cancel
              </button>
            </div>
          </form>
        )
      } else {
        if (customer && customer.subscription_status === 'active') {
          proStuff = (
            <div>
              <p>
                Credit card on file: {customer.card_type} ending with{' '}
                {customer.last4} (expires {customer.card_expires_month}/{
                  customer.card_expires_year
                }).
              </p>
              <div>
                <button className='button' onClick={this.toggleCCForm}>
                  Update payment details
                </button>
                &nbsp; &nbsp; &nbsp;
                <button className='button' onClick={this.changePlan}>
                  Downgrade to Basic plan
                </button>
              </div>
            </div>
          )
        } else {
          proStuff = (
            <div className='pricing-wrapper'>
              <div className='pricing-row'>
                <div className='pricing-col'>
                  <div className='row'>
                    <div className='price'>
                      <span className='currency'>$</span>
                      0
                    </div>
                  </div>
                  <div className='row'>
                    <div className='small-description'>
                      Create a team for free, and test<br />Notebase with your
                      friends.
                    </div>
                  </div>
                  <div className='row'>
                    <div className='type'>Free team</div>
                  </div>
                  <div className='line' />
                  <div className='row'>
                    <ul>
                      <li>
                        <div className='title'>List items/month:</div>
                        <div className='sub-title'>100</div>
                      </li>
                      <li>
                        <div className='title'>Shares:</div>
                        <div className='sub-title'>3</div>
                      </li>
                      {/*
                      <li>
                        <div className="title">
                          Bookmarks
                        </div>
                        <div className="sub-title">
                          20
                        </div>
                      </li>
                      */}
                      <li>
                        <div className='title'>Support:</div>
                        <div className='sub-title'>Community</div>
                      </li>
                    </ul>
                  </div>
                  <div className='row'>
                    <a href='#' className='btn full-width current'>
                      Current plan
                    </a>
                  </div>
                </div>

                <div className='pricing-col'>
                  <div className='row'>
                    <div className='price'>
                      <span className='currency'>$</span>
                      <span className='small-desc'>/user/month</span>
                      7
                    </div>
                  </div>
                  <div className='row'>
                    <div className='small-description'>
                      Get billed anually for a reduced<br />price of $70/user.
                    </div>
                  </div>
                  <div className='row'>
                    <div className='type'>Pro</div>
                  </div>
                  <div className='line' />
                  <div className='row'>
                    <ul>
                      <li>
                        <div className='title'>List items/month:</div>
                        <div className='sub-title'>1000</div>
                      </li>
                      <li>
                        <div className='title'>Shares:</div>
                        <div className='sub-title'>20</div>
                      </li>
                      {/*
                      <li>
                        <div className="title">
                          Bookmarks
                        </div>
                        <div className="sub-title">
                          Unlimited
                        </div>
                      </li>
                      */}
                      <li>
                        <div className='title'>Support:</div>
                        <div className='sub-title'>Chat/Email</div>
                      </li>
                    </ul>
                  </div>
                  <div className='row'>
                    <a
                      href='#'
                      className='btn full-width'
                      onClick={this.toggleCCForm}
                    >
                      Upgrade
                    </a>
                  </div>
                </div>

                <div className='pricing-col'>
                  <div className='row'>
                    <div className='price'>
                      <span className='currency'>$</span>
                      <span className='small-desc'>/user/month</span>
                      15
                    </div>
                  </div>
                  <div className='row'>
                    <div className='small-description'>
                      Get billed anually for a reduced<br />price of $150/user.
                    </div>
                  </div>
                  <div className='row'>
                    <div className='type'>Business</div>
                  </div>
                  <div className='line' />
                  <div className='row'>
                    <ul>
                      <li>
                        <div className='title'>List items/month:</div>
                        <div className='sub-title'>1 million</div>
                      </li>
                      <li>
                        <div className='title'>Shares:</div>
                        <div className='sub-title'>100</div>
                      </li>
                      {/*
                      <li>
                        <div className="title">
                          Bookmarks
                        </div>
                        <div className="sub-title">
                          Unlimited
                        </div>
                      </li>
                      */}
                      <li>
                        <div className='title'>Support:</div>
                        <div className='sub-title'>Chat/Email</div>
                      </li>
                    </ul>
                  </div>
                  <div className='row'>
                    <a
                      href='#'
                      className='btn full-width'
                      onClick={this.toggleCCForm}
                    >
                      Upgrade
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      }
    }
    return <div className='form'>{proStuff}</div>
  }
})

module.exports = PlansSection
