import './styles.css'

import React from 'react'

class Avatar extends React.Component {
  handleClick = ev => {
    if (this.props.onClick) {
      this.props.onClick(ev)
    }
    ev && ev.preventDefault()
  }

  render () {
    let content, letters, imageSrc
    let avatar = false
    const size = this.props.size || 'small'
    const shape = this.props.shape || 'circle'
    const classes = ['nb-avatar', size, shape]
    const user = this.props.app.user(this.props.user, true)
    const org = this.props.org ? this.props.app.org(this.props.org) : null
    if (org) {
      imageSrc = org.settings.logo
    } else if (user) {
      imageSrc = user.avatar || user.gravatar
    }
    if (!imageSrc) {
      if (org) {
        letters = org.name.charAt(0)
      } else if (user) {
        letters = `${user.first_name.charAt(0)}${user.last_name.charAt(0)}`
      }
      if (!this.props.noPlaceholder) {
        content = <span className='avatar-letters'>{letters}</span>
      }
    }
    if (
      !this.props.noPlaceholder &&
      !imageSrc &&
      (!letters || this.props.placeholder)
    ) {
      imageSrc = org
        ? '/img/org-img-placeholder.png'
        : '/img/user-img-placeholder.png'
    }
    // '/img/org-img-placeholder.png'   '/img/user-img-placeholder.png')
    if (imageSrc || content) {
      avatar = (
        <div
          className={classes.join(' ')}
          onClick={this.handleClick}
          style={{ backgroundImage: 'url(' + imageSrc + ')' }}
        >
          {content}
        </div>
      )
    }
    return avatar
  }
}

module.exports = Avatar
