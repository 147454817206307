import React from 'react'

import Heading from '../sections/heading'
// import Avatar from '../../avatar'

import './styles.css'

export default class User extends React.Component {
  render () {
    const { app, data } = this.props
    const user = app.user(data.userId)
    return (
      <div className='body full-height filters-container'>
        <Heading
          {...this.props}
          name={`${user.first_name} ${user.last_name}`}
          isMain
        />
        <div className='user'>
          <div className='something'>
            <h3>Useful stuff about the user...</h3>
            <p>... will soon be here.</p>
          </div>
        </div>
      </div>
    )
  }
}
