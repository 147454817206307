import React from 'react'
import superagent from 'superagent'

import RegisterForm from '../register_form'
import LoginForm from '../login_form'

class PublicPages extends React.Component {
  constructor (props) {
    super(props)
    var initialMode = props.mode || 'harvest'

    this.state = {
      mode: initialMode,
      message: ''
    }
    var inviteMatch = window.location.search.match(/[?&]i=([^?&]+)/)
    if (inviteMatch && inviteMatch[1]) {
      this.state.inviteCode = inviteMatch[1]
      this.state.emailCode = true
    }
  }

  forgotPassword = ev => {
    var _this = this
    this.setState({ mode: 'forgot-password', message: '' }, function () {
      _this.forgotEmail.focus()
    })
    ev.preventDefault()
  }

  doForgotPassword = ev => {
    var _this = this
    var email = this.forgotEmail.value
    var data = { email: email }

    this.setState({ forgotEmail: email }, function () {
      superagent
        .post(NOTEBASE_URL_PREFIX + '/_/forgot_password')
        .send(data)
        .end(function (err, res) {
          if (!err && res.ok) {
            if (res.body.error) {
              _this.setState({ message: res.body.error.message })
            } else {
              _this.setState({ mode: 'reset-info-sent', message: '' })
            }
          } else {
            _this.setState({
              message:
                'There was a problem communicating with the server, please try again later.'
            })
            console.log(
              'An error occurred while communicating with the server: ' +
                res.text
            ) // eslint-disable-line no-console
          }
        })
    })
    ev.preventDefault()
  }

  toggleMode = ev => {
    var _this = this
    var mode, focusField
    if (
      this.state.mode === 'auth-for-share' ||
      this.state.mode === 'register-for-share'
    ) {
      mode =
        this.state.mode === 'auth-for-share'
          ? 'register-for-share'
          : 'auth-for-share'
    } else {
      mode =
        this.state.mode === 'login' ||
        this.state.mode === 'register' ||
        this.state.mode === 'forgot-password'
          ? 'harvest'
          : 'register'
    }
    if (mode === 'share-auth') {
      focusField = 'username'
    }
    if (mode === 'share-register') {
      focusField = 'firstName'
    }
    if (mode === 'register') {
      focusField = this.state.inviteCode ? 'firstName' : 'inviteCode'
    }

    this.setState({ mode: mode, message: '' }, function () {
      if (focusField && _this[focusField]) {
        _this[focusField].focus()
      }
    })
    ev.preventDefault()
  }

  loginMode = ev => {
    this.setState({ mode: 'login', message: '' }, function () {
      // this.refs.username.focus();
    })
    ev.preventDefault()
  }

  registerMode = ev => {
    this.setState({ mode: 'register', message: '' }, () => {
      // this.inviteCode.focus()
    })
    ev.preventDefault()
  }

  shareRegisterMode = ev => {
    var _this = this
    this.setState({ mode: 'share-register', message: '' }, function () {
      _this.firstName.focus()
    })
    ev.preventDefault()
  }

  harvestMode = ev => {
    var _this = this
    this.setState({ mode: 'harvest', message: '' }, function () {
      _this.email.focus()
    })
    ev.preventDefault()
  }

  login = loginData => {
    var _this = this
    this.props.app.login(loginData.username, loginData.password, {}, function (
      res
    ) {
      if (res.ok) {
        if (res.body.error) {
          _this.setState({ message: res.body.error.message })
        } else {
          _this.setState({ message: '' })
          _this.props.app.setUser(res.body.user, res.body.token)
        }
      } else {
        _this.setState({
          message:
            'There was a problem communicating with the server, please try again later.'
        })
      }
    })
  }

  register = userData => {
    var _this = this
    // TODO: this looks awfully similar to the login call, DRY it up

    this.props.app.register(userData, {}, function (res) {
      if (res.ok) {
        if (res.body.error) {
          _this.setState({ message: res.body.error.message })
        } else {
          _this.setState({ message: '' })
          _this.props.app.setUser(res.body.user, res.body.token)
        }
      } else {
        _this.setState({
          message:
            'There was a problem communicating with the server, please try again later.'
        })
      }
    })
  }

  harvest = ev => {
    var _this = this
    this.props.app.setLastUserAction()
    var email = this.email.value
    if (email && /@/.test(email)) {
      // TODO: this looks awfully similar to the login call, DRY it up
      var data = { email: email }
      var headers = {}

      superagent
        .post(NOTEBASE_URL_PREFIX + '/_/harvest')
        .set(headers)
        .send(data)
        .end(function (err, res) {
          if (!err && res.ok) {
            if (res.body.error) {
              _this.setState({ message: res.body.error.message })
            } else {
              _this.setState({ mode: 'email-harvested', message: '' })
            }
          } else {
            console.log(
              'An error occurred while communicating with the server: ' +
                res.text
            ) // eslint-disable-line no-console
            window.sessionStorage.removeItem('authToken')
            window.localStorage.removeItem('authToken')
            _this.setState({
              message:
                'There was a problem communicating with the server, please try again later.'
            })
          }
        })
    } else {
      _this.setState({ message: 'You need to supply a valid email address.' })
    }
    ev.preventDefault()
  }

  render () {
    var content
    var notice
    var d = new Date()
    var year = d.getFullYear()

    // static header that will be visible in all landingpage views
    var header = (
      <div className='wrapper clearfix'>
        <div className='logo-area' onClick={this.harvestMode}>
          Notebase
        </div>
        <div className='main-navigation'>
          <ul>
            <li className='ghost-button' onClick={this.loginMode}>
              Login
            </li>
          </ul>
        </div>
      </div>
    )

    // Static footer that will be visible in all landingpage views
    var registerText = this.props.app.state.config.requireInvite
      ? 'Have an invite?'
      : 'Get your own account now!'
    var footer = (
      <div className='wrapper clearfix'>
        <div className='copyright-information'>&copy; {year} Notebase</div>
        <div className='have-an-invite'>
          <span>{registerText}</span>
          <div className='ghost-button' onClick={this.registerMode}>
            Register
          </div>
        </div>
      </div>
    )

    var disclaimer = (
      <div>
        <p className='fine-print'>
          By registering you agree to be bound by the{' '}
          <a href='/terms' target='_blank'>
            Notebase Terms of Service
          </a>
        </p>
      </div>
    )

    var hideInviteCode = false
    switch (this.state.mode) {
      case 'harvest':
        var registerStuff
        var harvestClasses = 'harvest'
        if (this.state.inviteCode && this.state.emailCode) {
          registerStuff = (
            <p className='happy'>
              You’ve been invited!{' '}
              <a href='' onClick={this.toggleMode}>
                Click here
              </a>{' '}
              to register your account.
            </p>
          )
          harvestClasses += ' hidden'
        } else {
          registerStuff = null
          // (
          //   {/* <p>Have an invite code? <a href="" onClick={this.toggleMode}>Click here</a> to register your account.</p> */}
          // );
        }
        content = (
          <div className='content'>
            <div className='notice'>
              <h2>Home is where the lists are</h2>
              <p>
                <strong>Notebase</strong> is a home for your lists, documents
                and more. You can use it for simple things like grocery lists,
                TODO lists or to write a document now and then. Or you can
                create full-featured knowledge bases, wikis and support sites.
                Notebase grows with your needs.
              </p>
            </div>
            <div className={harvestClasses}>
              <div id='harvest-form'>
                <form onSubmit={this.harvest}>
                  <input
                    type='text'
                    id='harvest-email'
                    ref={email => {
                      this.email = email
                    }}
                    name='mail'
                    placeholder='Your email address'
                  />
                  <input
                    type='submit'
                    value='Sign me up'
                    className='btn dark'
                  />
                </form>
                <div className='notification'>{this.state.message}</div>
              </div>
            </div>
            <div className='social-media'>
              <p>Interact with us and give feedback</p>
              <p>
                <a href='/'>
                  <i className='fa fa-fw fa-facebook' />
                </a>
                <a href='/'>
                  <i className='fa fa-fw fa-twitter' />
                </a>
                <a href='mailto:hello@notebase.io'>
                  <i className='fa fa-fw fa-envelope-o' />
                </a>
              </p>
            </div>
            <div className='actions'>{registerStuff}</div>
          </div>
        )
        break

      case 'email-harvested':
        content = (
          <div className='content'>
            <div className='harvest harvested'>
              <h2>Thanks!</h2>
              <p className='faded no-margin'>
                You’ll receive an email when we’re ready for more users.
              </p>
              <p className='faded'>
                What now? I don’t know, maybe see if there’s anything good on{' '}
                <a href='http://reddit.com/'>Reddit</a>?
              </p>
            </div>
          </div>
        )
        break

      case 'forgot-password':
        notice = null
        content = (
          <div className='content align-left'>
            <h3>Yeah, I forgot my password</h3>
            <p className='faded'>
              No problem, we’ll help you reset it. Type in the email you
              registered with to get started.
            </p>
            <form onSubmit={this.doForgotPassword}>
              <div>
                <input
                  type='email'
                  ref={forgotEmail => {
                    this.forgotEmail = forgotEmail
                  }}
                  placeholder='Enter your email address'
                  size='30'
                />
              </div>
              <div className='notification'>{this.state.message}</div>
              <div>
                <input type='submit' value='Continue' className='btn dark' />
              </div>
            </form>
          </div>
        )
        break

      case 'reset-info-sent':
        notice = null
        content = (
          <div className='content'>
            <h2>Instructions sent</h2>
            <p className='faded no-margin'>
              Instructions to reset your password have been sent to{' '}
              <strong>{this.state.forgotEmail}</strong>.
            </p>
            <p className='faded no-margin'>
              Please check your Inbox (or Spam folder if the email doesn’t
              arrive within a few minutes.)
            </p>
          </div>
        )
        break

      case 'share-auth':
        notice = null
        content = (
          <div className='content align-left'>
            <h3>Someone shared a private notebase with you!</h3>
            <p className='info-text'>
              Click {'"'}Login{'"'} if you already have an account, or {'"'}Register{
                '"'
              }{' '}
              if you’d like to register a new account.
            </p>
            <div
              className='submit space-bottom half-width'
              onClick={this.loginMode}
            >
              Login
            </div>
            <div
              className='submit space-bottom half-width'
              onClick={this.shareRegisterMode}
            >
              Register
            </div>
          </div>
        )
        break

      case 'share-register':
        notice = null
        content = (
          <div className='content align-left'>
            <h3>Register</h3>
            <p className='faded'>We are excited to have you!</p>
            <RegisterForm
              app={this.props.app}
              onSubmit={this.register}
              hideInviteCode={hideInviteCode}
              inviteCode={this.state.inviteCode}
            />
            {disclaimer}
          </div>
        )
        break

      case 'login':
        content = (
          <div className='content align-left'>
            <h3>Log in</h3>
            <p className='faded'>Type in your username and password.</p>
            <LoginForm
              app={this.props.app}
              onSubmit={this.login}
              forgotPassword={this.forgotPassword}
              message={this.state.message}
            />
          </div>
        )
        break

      case 'register':
        if (this.state.inviteCode && this.state.emailCode) {
          hideInviteCode = true
        }
        content = (
          <div className='content align-left'>
            <h3>Got an invite?</h3>
            <p className='faded'>Let’s get you set up!</p>
            <RegisterForm
              app={this.props.app}
              onSubmit={this.register}
              hideInviteCode={hideInviteCode}
              inviteCode={this.state.inviteCode}
            />
            {disclaimer}
          </div>
        )
        break
    }
    return (
      <div>
        <header className='header'>{header}</header>
        <div className='main-content'>
          <div className='wrapper'>
            {notice}
            {content}
          </div>
        </div>
        <footer className='footer'>{footer}</footer>
      </div>
    )
  }
}

module.exports = PublicPages
