var uuid = require('node-uuid')
var update = require('react-addons-update')

var Node = require('./node')
var Comment = require('./comment')

var Like = {

  debug: false,

  add: function (like, params, callback) {
    const doAction = (actionParams) => {
      const newParams = Object.assign({}, actionParams)
      const likes = actionParams.updatedState.entities ? actionParams.updatedState.entities.likes : actionParams.state.entities.likes
      const nodes = actionParams.updatedState.entities ? actionParams.updatedState.entities.nodes : actionParams.state.entities.nodes
      const comments = actionParams.updatedState.entities ? actionParams.updatedState.entities.comments : actionParams.state.entities.comments

      const newLike = Like.new(like, params)
      const newLikes = likes.concat([newLike])

      const model = newLike.comment_id ? Comment : Node
      const entities = newLike.comment_id ? comments : nodes
      const entitiesName = newLike.comment_id ? 'comments' : 'nodes'
      const entity = model.get(newLike.node_id || newLike.comment_id, entities)

      let newEntity = entity
      if (!params._remote) {
        newEntity = update(entity, {$merge: {like_count: entity.like_count + 1, updated_at: new Date().toISOString()}})
      }
      const trimmedEntities = entities.filter(e => e.id !== newEntity.id)
      const newActualEntities = trimmedEntities.concat([newEntity])

      const undoData = {
        undo: [{obj: 'Like', cmd: 'remove', args: [like.id]}],
        redo: [{obj: 'Like', cmd: 'add', args: [like]}]
      }

      if (!actionParams._isUndoOperation && !actionParams._skipUndoAdd) {
        actionParams.undo.add(undoData, true)
      }

      const newEntities = Object.assign({}, (actionParams.updatedState.entities || actionParams.state.entities))
      newEntities.likes = newLikes
      newEntities[entitiesName] = newActualEntities
      const result = {entities: newEntities}

      newParams.updatedState = Object.assign({}, actionParams.updatedState, result)
      newParams.undoData = actionParams.undoData || {}
      newParams.undoData.undo = (newParams.undoData.undo || []).concat(undoData.undo)
      newParams.undoData.redo = (newParams.undoData.redo || []).concat(undoData.redo)
      newParams.newLike = newLike

      if (actionParams._skipSetState) {
        if (typeof callback === 'function') {
          callback(newParams)
        }
      } else {
        actionParams.setAppState(newParams.updatedState, newParams.stateParams, () => {
          if (typeof callback === 'function') {
            callback(newParams)
          }
        })
      }
    }

    if (params._skipFlushUndo) {
      doAction(params)
    } else {
      params.flushTextUndo(params, ret => {
        doAction(ret)
      })
    }
  },

  remove: function (likeId, params, callback) {
    const doAction = (actionParams) => {
      const newParams = Object.assign({}, actionParams)
      const likes = actionParams.updatedState.entities ? actionParams.updatedState.entities.likes : actionParams.state.entities.likes
      const nodes = actionParams.updatedState.entities ? actionParams.updatedState.entities.nodes : actionParams.state.entities.nodes
      const comments = actionParams.updatedState.entities ? actionParams.updatedState.entities.comments : actionParams.state.entities.comments

      const like = Like.get(likeId, likes)
      if (like) {
        const without = likes.filter(c => c.id !== likeId)

        const model = like.comment_id ? Comment : Node
        const entities = like.comment_id ? comments : nodes
        const entitiesName = like.comment_id ? 'comments' : 'nodes'
        var entity = model.get(like.node_id || like.comment_id, entities)
        var newEntity = entity
        if (!params._remote) {
          newEntity = update(entity, {$merge: {like_count: entity.like_count - 1, updated_at: new Date().toISOString()}})
        }
        var trimmedEntities = entities.filter(e => e.id !== newEntity.id)
        var newActualEntities = trimmedEntities.concat([newEntity])

        const undoData = {
          undo: [{obj: 'Like', cmd: 'add', args: [like]}],
          redo: [{obj: 'Like', cmd: 'remove', args: [like.id]}]
        }

        if (!actionParams._isUndoOperation && !actionParams._skipUndoAdd) {
          actionParams.undo.add(undoData, true)
        }

        const newEntities = Object.assign({}, (actionParams.updatedState.entities || actionParams.state.entities))
        newEntities.likes = without
        newEntities[entitiesName] = newActualEntities

        const result = {entities: newEntities}
        newParams.updatedState = Object.assign({}, actionParams.updatedState, result)
        newParams.undoData = actionParams.undoData || {}
        newParams.undoData.undo = (newParams.undoData.undo || []).concat(undoData.undo)
        newParams.undoData.redo = (newParams.undoData.redo || []).concat(undoData.redo)

        if (actionParams._skipSetState) {
          if (typeof callback === 'function') {
            callback(newParams)
          }
        } else {
          actionParams.setAppState(newParams.updatedState, newParams.stateParams, () => {
            if (typeof callback === 'function') {
              callback(newParams)
            }
          })
        }
      } else {
        if (typeof callback === 'function') {
          callback(newParams)
        }
      }
    }

    if (params._skipFlushUndo) {
      doAction(params)
    } else {
      params.flushTextUndo(params, ret => {
        doAction(ret)
      })
    }
  },

  modify: function (likeOrId, data, params, callback) {
    const doAction = (actionParams) => {
      const newParams = Object.assign({}, actionParams)
      const likes = actionParams.updatedState.entities ? actionParams.updatedState.entities.likes : actionParams.state.entities.likes

      var like = Like.get(likeOrId, likes)
      var newLike = update(like, {$merge: {updated_at: new Date().toISOString()}})
      const oldData = {}
      for (var key in data) {
        var keys = key.split('.')
        var obj = newLike
        for (var i = 0; i <= keys.length - 1; i++) {
          if (i === keys.length - 1) {
            obj[keys[i]] = data[key]
          }

          oldData[key] = like[key]
          obj = obj[keys[i]]
        }
      }
      var trimmedLikes = likes.filter(l => l.id !== newLike.id)
      var newLikes = trimmedLikes.concat([newLike])

      const undoData = {
        undo: [{obj: 'Like', cmd: 'modify', args: [oldData]}],
        redo: [{obj: 'Like', cmd: 'modify', args: [data]}]
      }

      if (!actionParams._isUndoOperation && !actionParams._skipUndoAdd) {
        actionParams.undo.add(undoData, true)
      }

      const newEntities = Object.assign({}, (actionParams.updatedState.entities || actionParams.state.entities))
      newEntities.likes = newLikes
      const result = {entities: newEntities}

      newParams.updatedState = Object.assign({}, actionParams.updatedState, result)
      newParams.undoData = actionParams.undoData || {}
      newParams.undoData.undo = (newParams.undoData.undo || []).concat(undoData.undo)
      newParams.undoData.redo = (newParams.undoData.redo || []).concat(undoData.redo)

      if (actionParams._skipSetState) {
        if (typeof callback === 'function') {
          callback(newParams)
        }
      } else {
        actionParams.setAppState(newParams.updatedState, newParams.stateParams, () => {
          if (typeof callback === 'function') {
            callback(newParams)
          }
        })
      }
    }

    if (params._skipFlushUndo) {
      doAction(params)
    } else {
      params.flushTextUndo(params, ret => {
        doAction(ret)
      })
    }
  },

  get: function (likeOrId, likes) {
    if (typeof likeOrId === 'string') {
      return likes.find(l => l.id === likeOrId)
    } else {
      return likeOrId
    }
  },

  new: function (data) {
    var time = new Date().toISOString()
    if (typeof data === 'undefined') data = {}
    data.id = uuid.v4()
    data.created_at = time
    data.updated_at = time
    return data
  }

}

module.exports = Like
