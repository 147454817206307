import React from 'react'
import createReactClass from 'create-react-class'
import _ from 'lodash'

import constants from '../../..//lib/constants'

import Misc from '../../../js/misc'

const InviteBetaOverlay = createReactClass({
  displayName: 'InviteBetaOverlay',

  getInitialState: function () {
    var state = {
      error: null,
      user: null,
      invite: null,
      invites: [],
      emails: [],
      resent: []
    }
    return state
  },

  UNSAFE_componentWillMount: function () {
    // this.handleFormFieldChange = Misc.handleFormFieldChange.bind(this, this);
    this.errorFor = Misc.errorFor.bind(this, this)
    Misc.jx(
      this,
      'get_invites',
      {},
      function (res) {
        // TODO: Show global success message
        this.setState({
          error: null,
          invites: res.body.invites,
          emails: res.body.emails
        })
      }.bind(this)
    )
  },

  clearInvite: function (ev) {
    this.setState({ invite: null, code: null })
    ev.preventDefault()
  },

  sendInvite: function (ev) {
    var data = {
      email: this.email.value
    }
    if (data.email && constants.VALID_EMAIL_REGEX.test(data.email)) {
      Misc.jx(
        this,
        'send_invite',
        data,
        function (res) {
          // TODO: Show global success message
          this.setState({
            error: null,
            invite: res.body.invite,
            email: res.body.email,
            invites: res.body.invites,
            emails: res.body.emails
          })
        }.bind(this)
      )
    } else {
      this.setState({
        error: {
          field: 'email',
          message: 'You need to type in a valid email address'
        }
      })
    }
    ev.preventDefault()
  },

  generateInvite: function (ev) {
    var data = {
      quantity: this.quantity.value
    }
    if (data.quantity && data.quantity < this.invitesLeft()) {
      Misc.jx(
        this,
        'generate_invite_code',
        data,
        function (res) {
          // TODO: Show global success message
          this.setState({
            error: null,
            code: res.body.code,
            invites: res.body.invites
          })
        }.bind(this)
      )
    } else {
      this.setState({
        error: {
          field: 'quantity',
          message:
            'You need to enter a valid number (higher than 0 and lower than or equal to ' +
            this.invitesLeft() +
            ')'
        }
      })
    }
    ev.preventDefault()
  },

  cancelInvite: function (inviteId) {
    var data = { invite_id: inviteId }
    Misc.jx(
      this,
      'cancel_invite',
      data,
      function (res) {
        // TODO: Show global success message
        this.setState({
          error: null,
          invites: res.body.invites,
          emails: res.body.emails
        })
      }.bind(this)
    )
  },

  resendInvite: function (emailId) {
    var data = { email_id: emailId }
    Misc.jx(
      this,
      'resend_invite',
      data,
      function (res) {
        // TODO: Show global success message
        this.setState({
          error: null,
          invites: res.body.invites,
          emails: res.body.emails,
          resent: this.state.resent.concat([emailId])
        })
      }.bind(this)
    )
  },

  invitesLeft: function () {
    var count = 0
    this.state.invites.forEach(invite => {
      count += invite.quantity
    })
    return this.props.user.invites - count
  },

  selectInvite: function (invite, ev) {
    ev.preventDefault()
  },

  activeInvites: function (invites) {
    // TODO: Filter out revoked/cancelled invites
    return invites
  },

  render: function () {
    var tabsData = [
      { id: 'send', name: 'Send an email invite' },
      { id: 'generate', name: 'Generate invite code' },
      { id: 'status', name: 'Sent/generated invites' }
    ]
    var tabs = []
    tabsData.forEach(tab => {
      tabs.push(
        <td
          key={tab.id}
          className={this.props.activeTabClass(tab.id, this.props.defaultTab)}
        >
          <div onClick={this.props.selectTab(tab.id)}>{tab.name}</div>
        </td>
      )
    })
    var invitesLeft = this.invitesLeft()
    var sendContent, generateContent, statusContent
    var nounStr = invitesLeft === 1 ? 'invite' : 'invites'
    if (this.state.invite) {
      sendContent = (
        <div className='tab'>
          <p className='center'>
            The invite was sent! You can check the status on the{' '}
            <a href='' onClick={this.props.selectTab('status')}>
              Status tab
            </a>.
          </p>
          <br />
          <br />
          <p className='center'>
            <button className='button' onClick={this.clearInvite}>
              Send another
            </button>
          </p>
        </div>
      )
    } else {
      if (invitesLeft > 0) {
        sendContent = (
          <div className='tab'>
            <p>
              Would you like to invite a friend to try the Notebase Beta? Type
              in their email address below, and we’ll send them an invite. You
              currently have <strong>{invitesLeft}</strong> {nounStr} left.
            </p>
            <form onSubmit={this.sendInvite}>
              <div className='row'>
                <label>
                  Email address
                  <input
                    type='email'
                    ref={email => {
                      this.email = email
                    }}
                    placeholder='Your friend’s email address'
                  />&nbsp;
                </label>
                {this.errorFor('email')}
              </div>
              <div className='buttons'>
                <input type='submit' className='button' value='Send invite' />
              </div>
            </form>
          </div>
        )
      } else {
        sendContent = (
          <div className='tab'>
            <p>
              You had <strong>{this.props.user.invites}</strong> invites, but
              they have all been used. Unless you revoke one or more existing
              invites, you won’t be able to send any more email invites.
            </p>
          </div>
        )
      }
    }

    if (this.state.code) {
      generateContent = (
        <div className='tab'>
          <p className='center'>
            Success! Share the code below with anybody you want to invite to
            Notebase:
          </p>
          <div className='code-box'>
            <span>{this.state.code.short_id}</span>
          </div>
          <p className='center'>
            You can check the status on the{' '}
            <a href='' onClick={this.props.selectTab('status')}>
              Status tab
            </a>.
          </p>
          <br />
          <br />
          <p className='center'>
            <button className='button' onClick={this.clearInvite}>
              Generate another
            </button>
          </p>
        </div>
      )
    } else {
      if (invitesLeft > 0) {
        generateContent = (
          <div className='tab'>
            <p>
              If you prefer to send your own invite email, or maybe you want to
              invite your friends on Twitter or Facebook, you can generate an
              invite code here which will be valid for as many signups as you
              choose (up to your current limit of <strong>{invitesLeft}</strong>).
            </p>
            <form onSubmit={this.generateInvite}>
              <div className='row'>
                <label className='inline'>
                  <input
                    type='number'
                    ref={quantity => {
                      this.quantity = quantity
                    }}
                    className='width-4'
                    defaultValue='1'
                  />&nbsp; Number of people that can sign up using this invite
                  code
                </label>
                {this.errorFor('quantity')}
              </div>
              <div className='buttons'>
                <input type='submit' className='button' value='Generate code' />
              </div>
            </form>
          </div>
        )
      } else {
        generateContent = (
          <div className='tab'>
            <p>
              You had <strong>{this.props.user.invites}</strong> invites, but
              they have all been used. Unless you revoke one or more existing
              invites, you cannot generate a code.
            </p>
          </div>
        )
      }
    }

    if (this.state.invites.length) {
      var invites = []
      var email
      var idStr
      var quantityStr
      var accepted
      var icon
      var resendButton
      _.sortBy(this.state.invites, 'created_at').forEach(invite => {
        email = this.state.emails.find(email => email.invite_id === invite.id)
        if (email) {
          if (this.state.resent.indexOf(email.id) === -1) {
            resendButton = (
              <button
                className='minibutton'
                onClick={() => this.resendInvite(email.id)}
              >
                <span className='octicon octicon-sync' title='Resend email' />
              </button>
            )
          } else {
            resendButton = <span className='resent'>(resent)</span>
          }
        }
        idStr = email ? (
          <span className='email'>{email.email}</span>
        ) : (
          <span className='code'>{invite.short_id}</span>
        )
        accepted = email
          ? Boolean(invite.users.length)
          : Boolean(invite.users.length === invite.quantity)
        quantityStr = email ? (
          accepted ? (
            <span>
              <span className='octicon octicon-person' />{' '}
              {invite.users[0].username}
            </span>
          ) : (
            resendButton
          )
        ) : (
          invite.users.length + ' / ' + invite.quantity
        )
        icon = email ? 'mention' : 'code'
        var revoke
        if (!accepted) {
          revoke = (
            <button
              className='minibutton danger'
              onClick={() => this.cancelInvite(invite.id)}
            >
              <span
                className='octicon octicon-trashcan'
                title='Cancel this invite'
              />
            </button>
          )
        }
        invites.push(
          <tr key={invite.id}>
            <td className='icon'>
              <div>
                <span className={'octicon octicon-' + icon} />
              </div>
            </td>
            <td className='status'>
              <div className={accepted && 'ok'}>{idStr}</div>
            </td>
            <td>
              <div className={'center ' + (accepted && 'ok')}>
                {quantityStr}
              </div>
            </td>
            <td>
              <div>{revoke}</div>
            </td>
          </tr>
        )
      })
      statusContent = (
        <div className='tab'>
          <table className='invites'>
            <tbody>{invites}</tbody>
          </table>
        </div>
      )
    } else {
      statusContent = (
        <div className='tab'>
          You haven’t sent any email invites or generated any codes yet.
        </div>
      )
    }
    return (
      <div id='invite-beta'>
        {this.errorFor()}
        <div className='tabs-container'>
          <table className='tabs'>
            <tbody>
              <tr>{tabs}</tr>
            </tbody>
          </table>
          <div className='tab-contents'>
            {(this.props.activeTab || this.props.defaultTab) === 'send' &&
              sendContent}
            {(this.props.activeTab || this.props.defaultTab) === 'generate' &&
              generateContent}
            {(this.props.activeTab || this.props.defaultTab) === 'status' &&
              statusContent}
          </div>
        </div>
      </div>
    )
  }
})

module.exports = InviteBetaOverlay
