var _ = require('lodash')
var uuid = require('node-uuid')

var User = require('../../js/user')
var Tab = require('../../js/tab')
var OrgUser = require('../../js/org_user')

module.exports = {

  updateTab: function (tabOrId, data, settings, settingsToDelete, callback) {
    let prevHideWidgets, prevViewMode
    data = data || {}
    settings = settings || {}
    settingsToDelete = settingsToDelete || []
    const tab = Tab.get(tabOrId, this.state.entities.tabs)
    if (tab) {
      prevHideWidgets = tab.settings.hide_widgets
      prevViewMode = tab.settings.view_mode
      const newSettings = _.extend(tab.settings, settings)
      let delSetting
      while ((delSetting = settingsToDelete.shift())) {
        delete newSettings[delSetting]
      }
      data.settings = newSettings
      const commonParams = this.getCommonParams()

      if ((prevHideWidgets !== newSettings.hide_widgets) ||
          (prevViewMode !== newSettings.view_mode)) {
        commonParams.updatedState.updateAllNodes = true
      }

      Tab.modify(tab, data, commonParams, callback)
    }
  },

  addTab: function (data, callback, isBack) {
    const internalCb = () => {
      window.scrollTo(0, 0)
      window.Intercom && window.Intercom('trackEvent', 'created-tab') // jshint ignore:line
      if (typeof callback === 'function') {
        callback()
      }
    }

    const org = this.org()
    const user = this.user()
    const newTab = data || {}
    newTab.id = uuid.v4()
    newTab.user_id = user.id
    if (org) {
      newTab.org_id = org.id
    }
    newTab.expanded_nodes = []
    newTab.settings = {}
    const allTabs = this.state.entities.tabs
    let lastId
    let next = org ? allTabs.find(tab => tab.org_id === org.id && !tab.prev_id) : allTabs.find(tab => !tab.org_id && !tab.prev_id)
    while (next) {
      const prevId = next.id
      next = allTabs.find(tab => tab.prev_id === next.id) // jshint ignore:line
      if (!next) {
        lastId = prevId
      }
    }

    newTab.prev_id = newTab.prev_id || lastId

    const commonParams = this.getCommonParams()
    commonParams.stateParams = {keepUrl: isBack}

    Tab.add(newTab, commonParams, addParams => {
      if (newTab.org_id) {
        var orgUser = this.orgUser()
        if (orgUser) {
          OrgUser.modify(orgUser.id, {tab_id: newTab.id}, addParams, () => {
            internalCb()
          })
        }
      } else {
        User.modify(this.state.userId, {tab_id: newTab.id}, addParams, () => {
          internalCb()
        })
      }
    })
  },

  setTab: function (tabOrId, callback, skipCommands, isBack) { // eslint-disable-line no-unused-vars
    const internalCb = () => {
      window.scrollTo(0, 0)
      if (typeof callback === 'function') {
        callback()
      }
    }

    window._app.nodeGetTimer = 0
    window._app.nodeChildrenTimer = 0
    window._app.treeNodeTimer = 0

    const tab = Tab.get(tabOrId, this.state.entities.tabs)
    const prevTabId = this.tabId()
    const org = this.org()

    const commonParams = this.getCommonParams()
    commonParams.stateParams = {tabWasSet: true, keepUrl: isBack}
    commonParams.updatedState.prevTab = prevTabId
    commonParams.updatedState.updateAllNodes = true

    if (org) {
      var orgUser = this.orgUser()
      if (orgUser) {
        if (orgUser.org_id === tab.org_id) {
          OrgUser.modify(orgUser.id, {tab_id: tab.id}, commonParams, () => {
            internalCb()
          })
        } else {
          console.warn('OH NO! Tried to set the current orgUser\'s tab_id to a tab that belongs to another org') // eslint-disable-line no-console
          console.log('orgUser', orgUser) // eslint-disable-line no-console
          console.log('tab', tab) // eslint-disable-line no-console
        }
      }
    } else {
      if (tab.org_id) {
        console.warn('OH NO! Tried to set the current user\'s tab_id to a tab that has an org_id') // eslint-disable-line no-console
        console.log('user', this.user()) // eslint-disable-line no-console
        console.log('tab', tab) // eslint-disable-line no-console
      } else {
        User.modify(this.userId(), {tab_id: tab.id}, commonParams, () => {
          internalCb()
        })
      }
    }
  },

  closeTab: function (tabOrId, callback) { // eslint-disable-line no-unused-vars
    const internalCb = (tabId, modParams) => {
      Tab.remove(tabId, modParams, () => {
        window.scrollTo(0, 0)
        if (typeof callback === 'function') {
          callback()
        }
      })
    }

    const tab = Tab.get(tabOrId, this.state.entities.tabs)
    const next = this.state.entities.tabs.find(t => { return t.prev_id === tab.id })
    const newUserTabId = next ? next.id : tab.prev_id

    // Make sure we don't delete the last tab
    if (newUserTabId) {
      const commonParams = this.getCommonParams()
      const newTab = Tab.get(newUserTabId, this.state.entities.tabs)

      if (newTab.org_id) {
        var orgUser = this.orgUser()
        if (orgUser) {
          OrgUser.modify(orgUser.id, {tab_id: newTab.id}, commonParams, modParams => {
            internalCb(tab.id, modParams)
          })
        }
      } else {
        User.modify(this.state.userId, {tab_id: newTab.id}, commonParams, modParams => {
          internalCb(tab.id, modParams)
        })
      }
    }
  },

  moveTab: function (tabOrId, callback) { // eslint-disable-line no-unused-vars
    var tab = Tab.get(tabOrId, this.state.entities.tabs)
    console.log('MOVING', tab.id) // eslint-disable-line no-console
  }

}
