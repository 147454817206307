import React from 'react'
import createReactClass from 'create-react-class'
import _ from 'lodash'

import Node from '../../../js/node'
import Misc from '../../../js/misc'

import SelectMenu from '../../select_menu'
import MiniEditor from '../../mini_editor'

const SiteSettingsOverlay = createReactClass({
  displayName: 'SiteSettingsOverlay',

  getInitialState: function () {
    var node = this.props.panel.opts.data.node
    var subDomain =
      node.settings && node.settings.sub_domain
        ? node.settings.sub_domain
        : Misc.slugify(node.title)
    var state = node.settings
    state.theme = state.theme || 'minimal'
    state.site_type = state.site_type || 'blog'
    state.sub_domain = subDomain
    state.enable_categories = node.settings.hasOwnProperty('enable_categories')
      ? node.settings.enable_categories
      : true
    state.error = null
    return state
  },

  UNSAFE_componentWillMount: function () {
    this.handleFormFieldChange = Misc.handleFormFieldChange.bind(this, this)
    this.errorFor = Misc.errorFor.bind(this, this)
  },

  handleSubDomainChange: function (ev) {
    this.setState({ sub_domain: Misc.slugify(ev.target.value) })
  },

  handleCustomDomainChange: function (ev) {
    this.setState({ custom_domain: ev.target.value })
  },

  handleThemeChange: function (theme) {
    this.setState({ theme: theme.value })
  },

  handleCustomStylesChange: function (ev) {
    this.setState({ custom_styles: ev.target.value })
  },

  handleEnableCategoriesChange: function (ev) {
    this.setState({ enable_categories: ev.target.checked })
  },

  handleCustomHeaderChange: function (value) {
    this.setState({ custom_header: value })
  },

  handleCustomSidebarChange: function (value) {
    this.setState({ custom_sidebar: value })
  },

  handleCustomFooterChange: function (value) {
    this.setState({ custom_footer: value })
  },

  selectLogo: function (ev) {
    var _this = this
    this.props.app.browseFiles({ fileType: 'image/*' }, function (file) {
      _this.setState({ custom_logo: Misc.fileUrl(file) })
    })
    ev.preventDefault()
  },

  selectStylesheet: function (ev) {
    var _this = this
    this.props.app.browseFiles({ fileType: 'text/css' }, function (file) {
      _this.setState({ custom_styles: Misc.fileUrl(file) })
    })
    ev.preventDefault()
  },

  clearLogo: function (ev) {
    this.setState({ custom_logo: null })
    ev.preventDefault()
  },

  save: function (ev) {
    Node.modify(
      this.props.panel.opts.data.node.id,
      { settings: _.omit(this.state, ['error']) },
      this.props.app.getCommonParams(),
      () => {
        this.setState({ error: null })
        this.props.app.setPanel(null)
      }
    )
    ev.preventDefault()
  },

  render: function () {
    var _this = this
    var customLogo
    if (this.state.custom_logo) {
      customLogo = (
        <div>
          <div className='logo-preview'>
            <img src={this.state.custom_logo} />
          </div>
          <button className='minibutton flat' onClick={this.selectLogo}>
            Change logo image
          </button>&nbsp;&nbsp;&nbsp;
          <button className='minibutton flat' onClick={this.clearLogo}>
            Remove custom logo image
          </button>
          <br />
          <br />
        </div>
      )
    } else {
      customLogo = (
        <div className='static-content'>
          <p>No custom logo selected.</p>
          <button className='minibutton flat' onClick={this.selectLogo}>
            Pick logo image using file browser
          </button>
          <br />
          <br />
        </div>
      )
    }

    // {value: 'wiki', name: 'Wiki'},
    var siteTypes = [
      { value: 'blog', name: 'Blog' },
      { value: 'kb', name: 'Knowledge Base' }
    ]
    // var themes = [
    //   {value: 'minimal', name: 'Minimalist'},
    //   {value: 'shiny', name: 'Shiny'},
    // ];
    var commentProviders = [
      { value: '-none-', name: 'None' },
      { value: 'disqus', name: 'Disqus' },
      { value: 'fb', name: 'Facebook' }
    ]
    var tabsData = [
      { id: 'settings', name: 'Settings' },
      { id: 'appearance', name: 'Appearance' },
      { id: 'content', name: 'Custom content' }
    ]
    var tabs = []
    tabsData.forEach(tab => {
      tabs.push(
        <td
          key={tab.id}
          className={_this.props.activeTabClass(tab.id, _this.props.defaultTab)}
        >
          <div onClick={_this.props.selectTab(tab.id)}>{tab.name}</div>
        </td>
      )
    })
    return (
      <div id='node-settings'>
        {this.errorFor()}
        <div className='tabs-container'>
          <table className='tabs'>
            <tbody>
              <tr>{tabs}</tr>
            </tbody>
          </table>
          <div className='tab-contents'>
            {(this.props.activeTab || this.props.defaultTab) === 'settings' && (
              <div className='tab'>
                <div className='row'>
                  <div className='content'>
                    <SelectMenu
                      items={siteTypes}
                      label='Site type'
                      title='What type of site is this?'
                      id='published-type'
                      icon='versions'
                      defaultValue={this.state.site_type}
                      dataName='site_type'
                      classes='fw'
                      onChange={this.handleFormFieldChange}
                    />
                    {/*
                    <SelectMenu
                      items={themes}
                      label="Theme"
                      title="Select theme for this site"
                      id="published-theme"
                      icon="device-desktop"
                      defaultValue={this.state.theme}
                      dataName="theme"
                      onChange={this.handleFormFieldChange}
                    />&nbsp;&nbsp;&nbsp;&nbsp;
                    */}
                  </div>
                </div>
                <div className='row'>
                  <div className='content'>
                    <SelectMenu
                      items={commentProviders}
                      label='Comments'
                      title='Default comment provider for article pages'
                      id='comment-provider'
                      icon='comment-discussion'
                      defaultValue={this.state.comment_provider}
                      dataName='comment_provider'
                      classes='fw'
                      onChange={this.handleFormFieldChange}
                    />
                  </div>
                </div>
                <div className='sections'>
                  <div
                    className={
                      'section ' + this.props.openSectionClass('general')
                    }
                  >
                    <div
                      className='title'
                      onClick={this.props.toggleSection('general')}
                    >
                      General
                    </div>
                    <div className='body'>
                      {this.state.site_type === 'kb' && (
                        <div className='row'>
                          <div className='row-content'>
                            <div className='input-label-wrapper'>
                              <input
                                type='checkbox'
                                data-name='qa_site'
                                checked={this.state.qa_site}
                                onChange={this.handleFormFieldChange}
                              />&nbsp;
                              <label>
                                This is a Q&amp;A site (titles are questions and
                                note bodies are answers, essentially)
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className='row'>
                        <div className='row-content'>
                          <div className='input-label-wrapper'>
                            <input
                              type='checkbox'
                              data-name='enable_categories'
                              checked={this.state.enable_categories}
                              onChange={this.handleFormFieldChange}
                            />&nbsp;
                            <label>Expose list items as categories</label>
                          </div>
                        </div>
                      </div>
                      {this.state.enable_categories && (
                        <div className='row'>
                          <div className='row-content'>
                            <div className='input-label-wrapper'>
                              <input
                                type='checkbox'
                                data-name='show_categories_everywhere'
                                checked={this.state.show_categories_everywhere}
                                onChange={this.handleFormFieldChange}
                              />&nbsp;
                              <label>
                                Show top-level categories in sidebar on all
                                pages
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className='row'>
                        <div className='row-content'>
                          <div className='input-label-wrapper'>
                            <input
                              type='checkbox'
                              data-name='enable_related'
                              checked={this.state.enable_related}
                              onChange={this.handleFormFieldChange}
                            />&nbsp;
                            <label>
                              Show related articles on article pages
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='row-content'>
                          <div className='input-label-wrapper'>
                            <input
                              type='checkbox'
                              data-name='enable_feedback_widget'
                              checked={this.state.enable_feedback_widget}
                              onChange={this.handleFormFieldChange}
                            />&nbsp;
                            <label>
                              Show helpful/not helpful widget on article pages
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='row-content'>
                          <div className='input-label-wrapper'>
                            <input
                              type='checkbox'
                              data-name='enable_search'
                              checked={this.state.enable_search}
                              onChange={this.handleFormFieldChange}
                            />&nbsp;
                            <label>Show search field</label>
                          </div>
                        </div>
                      </div>
                      {this.state.enable_search && (
                        <div className='row'>
                          <div className='row-content'>
                            <div className='input-label-wrapper'>
                              <input
                                type='checkbox'
                                data-name='search_siblings'
                                checked={this.state.search_siblings}
                                onChange={this.handleFormFieldChange}
                              />&nbsp;
                              <label>
                                Include search results from sibling sites
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.enable_contact_form && (
                        <div>
                          <input
                            type='text'
                            placeholder='Email addresses that receive contact form submissions'
                            data-name='contact_form_emails'
                            value={this.state.contact_form_emails}
                            onChange={this.handleFormFieldChange}
                          />&nbsp;
                          {this.errorFor('settings.contact_form_emails')}
                        </div>
                      )}
                    </div>

                    {/*
                    <div className="row">
                      <label className="inline">
                        <input type="checkbox" data-name="enable_contact_form" checked={this.state.enable_contact_form} onChange={this.handleFormFieldChange} />&nbsp;
                        Show contact form
                      </label>
                    </div>
                    */}
                  </div>

                  <div
                    className={
                      'section ' +
                      this.props.openSectionClass('domains', this.state)
                    }
                  >
                    <div
                      className='title'
                      onClick={this.props.toggleSection('domains')}
                    >
                      Domain names
                    </div>
                    <div className='body'>
                      <div className='row'>
                        <label>Sub-domain</label>
                        <div className='content'>
                          <input
                            type='text'
                            data-name='sub_domain'
                            value={this.state.sub_domain}
                            placeholder='johns-blog'
                            onChange={this.handleFormFieldChange}
                          />
                        </div>
                        {this.errorFor('settings.sub_domain')}
                      </div>
                      <div className='row'>
                        <label>Custom domain name</label>
                        <div className='content'>
                          <input
                            type='text'
                            data-name='custom_domain'
                            value={this.state.custom_domain}
                            placeholder='blog.yourdomain.example'
                            onChange={this.handleFormFieldChange}
                          />
                        </div>
                        {this.errorFor('settings.custom_domain')}
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      'section ' + this.props.openSectionClass('advanced')
                    }
                  >
                    <div
                      className='title'
                      onClick={this.props.toggleSection('advanced')}
                    >
                      Advanced
                    </div>
                    <div className='body'>
                      <div className='row'>
                        <label>
                          Alternative title (shown instead of the Notebase node
                          title)
                        </label>
                        <div className='content'>
                          <input
                            type='text'
                            data-name='alt_title'
                            value={this.state.alt_title}
                            placeholder='Acme Support'
                            onChange={this.handleFormFieldChange}
                          />
                        </div>
                        {this.errorFor('settings.alt_title')}
                      </div>
                      <div className='row'>
                        <label>
                          Short title (shown in cross-site search results)
                        </label>
                        <div className='content'>
                          <input
                            type='text'
                            data-name='short_name'
                            value={this.state.short_name}
                            placeholder='FAQ'
                            onChange={this.handleFormFieldChange}
                          />
                        </div>
                        {this.errorFor('settings.custom_domain')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {(this.props.activeTab || this.props.defaultTab) ===
              'appearance' && (
              <div className='tab'>
                <div className='sections'>
                  <div
                    className={'section ' + this.props.openSectionClass('logo')}
                  >
                    <div
                      className='title'
                      onClick={this.props.toggleSection('logo')}
                    >
                      Logo
                    </div>
                    <div className='body'>
                      <label>{customLogo}</label>
                      {this.errorFor('settings.custom_logo')}
                    </div>
                  </div>
                  <div
                    className={
                      'section ' + this.props.openSectionClass('stylesheet')
                    }
                  >
                    <div
                      className='title'
                      onClick={this.props.toggleSection('stylesheet')}
                    >
                      Stylesheet
                    </div>
                    <div className='body'>
                      <div className='row'>
                        <label>
                          Custom stylesheet
                          <input
                            type='text'
                            data-name='custom_styles'
                            value={this.state.custom_styles}
                            placeholder='https://some.url/styles.css'
                            onChange={this.handleFormFieldChange}
                          />
                        </label>
                        <br />
                        <button
                          className='minibutton'
                          onClick={this.selectStylesheet}
                        >
                          Pick stylesheet using file browser
                        </button>
                        {this.errorFor('settings.custom_styles')}
                      </div>
                      {this.state.custom_styles && (
                        <div className='row'>
                          <label>
                            <input
                              type='checkbox'
                              checked={this.state.no_default_styles}
                              onChange={this.handleFormFieldChange}
                            />&nbsp; Use only the custom stylesheet, no default
                            styles
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {(this.props.activeTab || this.props.defaultTab) === 'content' && (
              <div className='tab'>
                <div className='sections'>
                  <div
                    className={
                      'section ' + this.props.openSectionClass('header')
                    }
                  >
                    <div
                      className='title'
                      onClick={this.props.toggleSection('header')}
                    >
                      Header
                    </div>
                    <div className='body'>
                      <MiniEditor
                        app={this.props.app}
                        onChange={this.handleFormFieldChange}
                        dataName='custom_header'
                        value={this.state.custom_header}
                        placeholder='Type Markdown here to customize your site’s header'
                      />
                      {this.errorFor('settings.custom_header')}
                    </div>
                  </div>
                  <div
                    className={
                      'section ' + this.props.openSectionClass('footer')
                    }
                  >
                    <div
                      className='title'
                      onClick={this.props.toggleSection('footer')}
                    >
                      Footer
                    </div>
                    <div className='body'>
                      <MiniEditor
                        app={this.props.app}
                        onChange={this.handleFormFieldChange}
                        dataName='custom_footer'
                        value={this.state.custom_footer}
                        placeholder='Type Markdown here to customize your site’s footer'
                      />
                      {this.errorFor('settings.custom_footer')}
                    </div>
                  </div>
                  <div
                    className={
                      'section ' + this.props.openSectionClass('sidebar')
                    }
                  >
                    <div
                      className='title'
                      onClick={this.props.toggleSection('sidebar')}
                    >
                      Sidebar
                    </div>
                    <div className='body'>
                      <MiniEditor
                        app={this.props.app}
                        onChange={this.handleFormFieldChange}
                        dataName='custom_sidebar'
                        value={this.state.custom_sidebar}
                        placeholder='Type Markdown here to customize your site’s sidebar'
                      />
                      {this.errorFor('settings.custom_sidebar')}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='buttons'>
          <button onClick={this.save} className='button'>
            Save
          </button>
        </div>
      </div>
    )
  }
})

module.exports = SiteSettingsOverlay
