import React from 'react'

import classNames from 'classnames'
import contrast from 'contrast'

import Confirmation from '../confirmation'
import Nodes from '../nodes'
import Panels from '../panels'
import PublicPages from '../public_pages'
import SpecialPages from '../special_pages'
import Header from '../header'
import Switcher from '../switcher'
import Sidebar from '../sidebar'
import Help from '../help'
import Floater from '../floater'
import OrgSwitcher from '../org_switcher'
import FileBrowser from '../file_browser'
import FileEditor from '../file_editor'
import MainEditor from '../main_editor'

import Node from '../../js/node'

class Wrapper extends React.Component {
  render () {
    var user = this.props.app.user()
    var share = this.props.app.share()
    var anonShare = this.props.app.anonShare()
    var shareNode = this.props.app.shareNode()
    var tab = this.props.app.tab()
    var org = this.props.app.org()
    var orgUser = this.props.app.orgUser()
    var rootNode = this.props.app.rootNode()
    var activeNode = this.props.app.activeNode()
    var editNode = this.props.app.editNode()
    var rawTitleNode = this.props.app.rawTitleNode()
    var orgNodes = this.props.app.orgNodes()

    const path = rootNode
      ? Node.getPath(rootNode, this.props.app.state.entities.nodes).reverse()
      : []
    // const isBoard =
    //   !editNode &&
    //   ((rootNode && rootNode.mode === 'board') ||
    //     path.find(n => n.mode === 'board'))

    // var t0 = new Date().getTime();
    // var nodeKeys = this.props.app.state.entities.nodes ? Object.keys(this.props.app.state.entities.nodes) : [];
    // console.log(new Date().getTime() - t0);

    const bgImage = this.props.app.getBgImageAndColorForCurrent(
      rootNode,
      tab,
      orgUser,
      user,
      path
    )
    const hasOrgs = this.props.app.state.entities.orgs

    const contrastClass = bgImage ? contrast(bgImage.color) : ''

    var editText =
      this.props.app.state.editNodeId &&
      this.props.app.state.editorStates[this.props.app.state.editNodeId]
        ? this.props.app.state.editorStates[this.props.app.state.editNodeId]
          .text || ''
        : ''

    var editFormat = editNode ? editNode.format : null

    var mainComponent, overlayMsg
    var landingpage = false
    if (this.props.app.state.accountDeleted) {
      mainComponent = (
        <div id='user-stuff'>
          <div id='account-deleted'>
            <h2>Account deleted</h2>
            <p>
              Your account has been permanently deleted. We’re sorry to see you
              go!
            </p>
          </div>
        </div>
      )
    } else if (
      this.props.app.state.panel &&
      this.props.app.state.panel.type === 'page'
    ) {
      // if the panel type "page" is active, then hide the main content.
      mainComponent = <div />
    } else {
      if (
        !this.props.app.state.unloading &&
        (this.props.app.state.error || this.props.app.state.syncError)
      ) {
        // if (!this.props.app.state.unloading && this.props.app.state.syncError) {
        var actualMsg
        if (this.props.app.state.error) {
          // if (false && this.props.app.state.error) { // eslint-disable-line no-constant-condition
          actualMsg = (
            <div>
              <div>
                <i className='info fa fa-fw fa-circle-o-notch fa-spin' />
              </div>
              <div>
                <h3>Reconnecting...</h3>
                <p>
                  Trying to reconnect. If the connection isn’t automatically
                  restored within a minute or two you might try reloading the
                  page.
                </p>
                <div>
                  <button
                    className='muted'
                    onClick={() => window.location.reload()}
                  >
                    Reload Now
                  </button>
                </div>
              </div>
            </div>
          )
        } else {
          actualMsg = (
            <div>
              <div>
                <i className='error fa fa-fw fa-exclamation-circle' />
              </div>
              <div>
                <h3>Sync Timeout</h3>
                <p>
                  We are currently unable to save your changes. Changes made in
                  the last 30 seconds may not have been saved.
                </p>
                <p>
                  This can sometimes happen if there is a network problem or a
                  problem with our servers. It is usually temporary.
                </p>
                <p>
                  You should reload the page to avoid further data loss. We
                  apologize for the inconvenience.
                </p>
                <div>
                  <button
                    className='primary'
                    onClick={() => window.location.reload()}
                  >
                    Reload Now
                  </button>
                </div>
              </div>
            </div>
          )
        }

        overlayMsg = <div id='overlay-msg'>{actualMsg}</div>
      }

      if (!this.props.app.state.unloading && this.props.app.state.serverError) {
        mainComponent = (
          <div id='error'>
            There was a problem communicating with the server, please reload the
            page.
          </div>
        )
      } else if (this.props.app.state.specialAction) {
        mainComponent = (
          <SpecialPages
            app={this.props.app}
            mode={this.props.app.state.specialAction}
            params={this.props.app.state.specialActionParams}
          />
        )
        if (this.props.app.state.specialAction === 'authForShare') {
          landingpage = true
          mainComponent = (
            <PublicPages
              app={this.props.app}
              mode='share-auth'
              setUser={this.props.app.setUser}
            />
          )
        }
      } else {
        if (
          !this.props.app.state.loading &&
          (this.props.app.state.loaded || this.props.app.state.noLoad)
        ) {
          if (user || anonShare) {
            mainComponent = (
              <Nodes
                app={this.props.app}
                nodes={this.props.app.state.entities.nodes}
                displayNodes={this.props.app.state.displayNodes}
                orgNodes={orgNodes}
                // isBoard={isBoard}
                user={user}
                share={share}
                anonShare={anonShare}
                tab={tab}
                org={org}
                rootNode={rootNode}
                activeNode={activeNode}
                editNode={editNode}
                rawTitleNode={rawTitleNode}
                shareNode={shareNode}
                editLock={this.props.app.state.editLock}
                pubUrl={this.props.app.pubUrl}
                activeNodeOnly={this.props.app.state.activeNodeOnly}
                tmpVisible={this.props.app.state.tmpVisible}
                rootNodeId={this.props.app.state.rootNodeId}
                setRoot={this.props.app.setRoot}
                setLastUserAction={this.props.app.setLastUserAction}
                setAppState={this.props.app.setAppState}
                getCommonParams={this.props.app.getCommonParams}
                setPanel={this.props.app.setPanel}
                overlay={this.props.app.state.overlay}
                updateBody={this.props.app.updateBody}
                updatedTextNode={this.props.app.state.updatedTextNode}
                toggleEditing={this.props.app.toggleEditing}
                parsedBody={this.props.app.state.parsedBody}
                previewBody={this.props.app.state.previewBody}
                editNodeId={this.props.app.state.editNodeId}
                editor={this.props.app.state.editor}
                editorCursorPosition={window._app.editorCursorPosition}
                dragged={this.props.app.state.dragged}
                draggedOver={this.props.app.state.draggedOver}
                dragIsValid={this.props.app.state.dragIsValid}
                searchstring={this.props.app.state.searchstring}
                toggleInbox={this.props.app.toggleInbox}
                descriptionNode={this.props.app.state.descriptionNode}
                dueNode={this.props.app.state.dueNode}
                assignNode={this.props.app.state.assignNode}
                assignFilter={this.props.app.state.assignFilter}
                assignPickerIndex={this.props.app.state.assignPickerIndex}
                token={this.props.app.state.token}
                sharedShares={this.props.app.state.entities.shares}
                selectedInboxItems={this.props.app.state.selectedInboxItems}
                undo={this.props.app.state.undo}
                nodeLink={this.props.app.nodeLink}
                updatedNodes={this.props.app.state.updatedNodes}
                updatedNodeIds={this.props.app.state.updatedNodeIds}
                updateAllNodes={this.props.app.state.updateAllNodes}
                headingOnly={this.props.app.state.headingOnly}
                editorCompanion={this.props.app.state.editorCompanion}
                bgImage={bgImage}
                addingData={this.props.app.state.addingData}
              />
            )
          } else {
            landingpage = true
            mainComponent = (
              <PublicPages
                app={this.props.app}
                setLastUserAction={this.props.app.setLastUserAction}
                setUser={this.props.app.setUser}
              />
            )
          }
        } else {
          var loadingExtra
          var loadingClass = ''
          if (this.props.app.state.loading) {
            loadingClass = 'small'
          } else {
            loadingExtra = <div>Loading...</div>
          }
          mainComponent = (
            <div id='loading' className={loadingClass}>
              <div className='working-indicator'>
                <div className='rect1' />
                <div className='rect2' />
                <div className='rect3' />
                <div className='rect4' />
                <div className='rect5' />
              </div>
              {loadingExtra}
            </div>
          )
        }
      }
    }

    var appClasses = classNames({
      'drag-in-progress': this.props.app.state.dragged,
      // 'hide-completed': Misc.hideCompleted(user, tab),
      // 'inbox-open': user && user.settings.show_inbox,
      editing: this.props.app.state.editNodeId,
      'edit-with-lists':
        this.props.app.state.editNodeId &&
        this.props.app.state.editorCompanion === 'lists',
      'edit-without-companion':
        this.props.app.state.editNodeId &&
        this.props.app.state.editorCompanion === 'disabled',
      'has-orgs': hasOrgs,
      'tour-active': this.props.app.state.tourActive,
      landingpage: landingpage,
      sidebar:
        this.props.app.state.sidebarOpen ||
        this.props.app.state.sidebarStack.length,
      board:
        !this.props.app.state.searchstring &&
        !editNode &&
        rootNode &&
        rootNode.mode === 'board',
      'with-bg-image': bgImage,
      'expanded-labels': tab && tab.settings && tab.settings.expand_labels
    })

    if (contrastClass) {
      appClasses += ` is-${contrastClass}`
    }

    if (user) {
      if (user.settings.base_color) {
        // appClasses += ' color-' + user.settings.base_color;
      }

      if (user.settings.use_gradient) {
        // appClasses += ' use-gradient';
      }
    }

    // var connClasses = 'offline-ui offline-ui-' + this.props.app.state.connectionState;
    // var connectionStatus;
    // if (!this.props.app.state.online) {
    //   connectionStatus = (
    //     <div id="conn-status" className={connClasses}>
    //       {this.props.app.state.connectionString}
    //     </div>
    //   );
    // }

    var helpClasses = 'border-tag'
    var helpContents
    if (this.props.app.state.expandHelp) {
      helpClasses += ' open'
      helpContents = <Help />
    }

    var help

    // TODO: Show for non-logged-in users?
    // if (this.props.app.state.loaded && !this.props.app.state.editing && (!user || user.settings.show_help)) {
    if (
      !this.props.app.state.editNodeId &&
      this.props.app.state.loaded &&
      user &&
      user.settings.show_help
    ) {
      help = (
        <div id='help' className={helpClasses}>
          <h3 onClick={this.props.app.toggleHelp}>Video Help</h3>
          <a className='tag-close' href='' onClick={this.props.app.toggleHelp}>
            <span className='octicon octicon-x' />
          </a>
          {helpContents}
        </div>
      )
    }

    var message
    if (this.props.app.state.notFound) {
      message = (
        <div id='message'>
          <span className='bad'>That page was not found.</span>
        </div>
      )
    }

    var confirmation
    if (this.props.app.state.showConfirmation) {
      confirmation = (
        <Confirmation
          message={this.props.app.state.confirmationMessage}
          doConfirm={this.props.app.doConfirm}
          doDecline={this.props.app.doDecline}
        />
      )
    }

    var systemMessage
    if (this.props.app.state.systemMessage) {
      systemMessage = (
        <div
          id='system-message'
          dangerouslySetInnerHTML={{
            __html: this.props.app.state.systemMessage
          }}
        />
      )
    }

    // var supportChatLauncher;
    // if (this.props.app.state.loaded && this.props.app.state.entities.nodes && this.props.app.state.entities.nodes.length) {
    //   supportChatLauncher = (
    //     <div id="support-chat-launcher"><span className="octicon octicon-comment"></span></div>
    //   );
    // }

    var orgSwitcher

    if (hasOrgs) {
      orgSwitcher = (
        <OrgSwitcher
          app={this.props.app}
          user={user}
          org={org}
          tab={tab}
          activeNodeOnly={this.props.app.state.activeNodeOnly}
          rootNode={rootNode}
        />
      )
    }

    const outerStyles = {}
    if (bgImage) {
      outerStyles.backgroundImage = `url(${bgImage.uri})`
    }

    return (
      <div
        id='app-wrapper'
        className={appClasses}
        onDragOver={this.props.app.dragOver}
        onDragEnd={this.props.app.dragEnd}
      >
        <div id='bg-layer' style={outerStyles} />
        <div id='user-image-scratch-area' />
        {this.props.app.state.floater && (
          <Floater
            user={user}
            org={org}
            tab={tab}
            rootNode={rootNode}
            activeNodeOnly={this.props.app.state.activeNodeOnly}
            app={this.props.app}
          />
        )}
        {systemMessage}
        {overlayMsg}
        {confirmation}
        {message}
        {help}
        {/* supportChatLauncher */}
        {orgSwitcher}
        <Switcher
          app={this.props.app}
          user={user}
          org={org}
          tab={tab}
          rootNode={rootNode}
          orgNodes={orgNodes}
          activeNodeOnly={this.props.app.state.activeNodeOnly}
          switcherOpen={this.props.app.state.switcherOpen}
        />

        <Header
          app={this.props.app}
          user={user}
          org={org}
          tab={tab}
          rootNode={rootNode}
          indicators={this.props.app.state.indicators}
          panel={this.props.app.state.panel}
          searchstring={this.props.app.state.searchstring}
          doPending={this.props.app.doPending}
          token={this.props.app.state.token}
          search={this.props.app.search}
          activeNodeOnly={this.props.app.state.activeNodeOnly}
          doUndo={this.props.app.doUndo}
          doRedo={this.props.app.doRedo}
          hasUndo={this.props.app.hasUndo}
          hasRedo={this.props.app.hasRedo}
        />

        <FileBrowser app={this.props.app} />
        <FileEditor app={this.props.app} />
        <MainEditor
          app={this.props.app}
          user={user}
          editText={editText}
          editNode={editNode}
          editNodeId={this.props.app.state.editNodeId}
          editLock={this.props.app.state.editLock}
          format={editFormat}
          bgImage={bgImage}
          editorCompanion={this.props.app.state.editorCompanion}
        />

        <div id='outer-wrapper'>
          <div id='outer'>
            {mainComponent}

            <Panels
              app={this.props.app}
              panel={this.props.app.state.panel}
              user={user}
              org={org}
              tab={tab}
              rootNode={rootNode}
              activeNode={activeNode}
              orgNodes={orgNodes}
              activeNodeOnly={this.props.app.state.activeNodeOnly}
              descriptionNode={this.props.app.state.descriptionNode}
              token={this.props.app.state.token}
            />
          </div>
        </div>

        <Sidebar
          app={this.props.app}
          user={user}
          org={org}
          tab={tab}
          bgImage={bgImage}
          orgNodes={orgNodes}
          rootNode={rootNode}
          activeNodeOnly={this.props.app.state.activeNodeOnly}
          activeNode={activeNode}
        />
        <div id='drag-indicator' />
      </div>
    )
  }
}

module.exports = Wrapper
