import React from 'react'

class RegisterForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      username: '',
      password: '',
      invite_code: this.props.inviteCode
    }
  }

  onSubmit = ev => {
    ev.preventDefault()
    var data = {
      firstName: this.state.first_name,
      lastName: this.state.last_name,
      email: this.state.email,
      username: this.state.username,
      password: this.state.password
    }
    if (this.state.invite_code) {
      data.inviteCode = this.state.invite_code
    }
    this.props.onSubmit(data)
  }

  render () {
    var inviteClasses = ''
    var inviteCodeField
    if (this.props.hideInviteCode) {
      inviteClasses = 'hidden'
    }
    if (
      this.props.app &&
      this.props.app.state.config &&
      this.props.app.state.config.requireInvite &&
      !this.props.noInviteField
    ) {
      inviteCodeField = (
        <div className={inviteClasses}>
          <input
            type='text'
            className='invite space-bottom'
            onChange={ev => this.setState({ invite_code: ev.target.value })}
            value={this.state.invite_code}
            placeholder='Invite code'
            required
          />
        </div>
      )
    }
    var buttonText = this.props.buttonText || 'Register'
    return (
      <form onSubmit={this.onSubmit}>
        {inviteCodeField}
        <div>
          <input
            className='space-bottom half-width'
            type='text'
            onChange={ev => this.setState({ first_name: ev.target.value })}
            value={this.state.first_name}
            placeholder='First name'
            required
          />
          <input
            className='space-bottom half-width'
            type='text'
            onChange={ev => this.setState({ last_name: ev.target.value })}
            value={this.state.last_name}
            placeholder='Last Name'
            required
          />
        </div>
        <div>
          <input
            className='space-bottom'
            type='email'
            onChange={ev => this.setState({ email: ev.target.value })}
            value={this.state.email}
            placeholder='Email'
            required
          />
        </div>
        <div>
          <input
            className='space-bottom'
            type='text'
            onChange={ev => this.setState({ username: ev.target.value })}
            value={this.state.username}
            placeholder='Username'
            required
          />
        </div>
        <div>
          <input
            className='space-bottom'
            type='password'
            onChange={ev => this.setState({ password: ev.target.value })}
            value={this.state.password}
            placeholder='Password'
            required
          />
        </div>
        <div>
          <input
            type='submit'
            value={buttonText}
            className='btn dark space-bottom'
          />
        </div>
      </form>
    )
  }
}

module.exports = RegisterForm
