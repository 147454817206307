import React from 'react'
import $ from 'jquery'

import onClickOutside from 'react-onclickoutside'

import Comment from '../../../../js/comment'
import Misc from '../../../../js/misc'

const CommentDropdown = onClickOutside(
  class extends React.Component {
    static displayName = 'CommentDropdown'

    handleClickOutside = ev => {
      if (this.props.onClickOutside) {
        var $target = $(ev.target)
        var moreParent = $target.closest('.more')
        var toggleMoreParent = $target.closest('.toggle-more')
        if (!moreParent.length && !toggleMoreParent.length) {
          this.props.onClickOutside()
        }
      }
    }

    togglePinning = comment => {
      // eslint-disable-line no-unused-vars
      Comment.modify(
        comment.id,
        { pinned: !comment.pinned },
        this.props.app.getCommonParams()
      )
      this.props.onClickOutside()
    }

    delete = comment => {
      // eslint-disable-line no-unused-vars
      Comment.remove(comment.id, this.props.app.getCommonParams(), () => {
        var nodeEl = Misc.nodeEl(comment.node_id)
        if (nodeEl) {
          $(nodeEl)
            .find('.comment-indicator')
            .highlight(1000)
        }
      })
      this.props.onClickOutside()
    }

    render () {
      var pinText = this.props.comment.pinned ? 'Unpin from Top' : 'Pin to Top'
      return (
        <div className='more'>
          <ul>
            <li onClick={() => this.togglePinning(this.props.comment)}>
              {pinText}
            </li>
            <li onClick={() => this.delete(this.props.comment)}>
              Delete Comment
            </li>
          </ul>
        </div>
      )
    }
  }
)

module.exports = CommentDropdown
