import React from 'react'
// import CodeMirror from 'codemirror';
// import ReactCodeMirror from '../codemirror';
import { Controlled as CodeMirror } from 'react-codemirror2'

require('codemirror/lib/codemirror.css')
require('codemirror/theme/neat.css')
require('codemirror/mode/gfm/gfm')

class MarkdownEditor extends React.Component {
  handleEditorMounted = editor => {
    this.editor = editor
    this.props.app.editor = this.editor
    window._app.editor = this.editor
    // window._app.editorRef = this.editor;
  }

  render () {
    var editorOptions = {
      lineWrapping: true,
      mode: 'gfm',
      theme: 'neat',
      autofocus: true,
      placeholder: 'Type some Markdown...'
    }
    return (
      <CodeMirror
        value={this.props.editText}
        onBeforeChange={this.props.onBeforeChange}
        onChange={this.props.onChange}
        onFocus={this.props.onFocus}
        onBlur={this.props.onBlur}
        onScroll={this.props.onScroll}
        options={editorOptions}
        editorDidMount={editor => {
          this.handleEditorMounted(editor)
        }}
      />
    )
  }
}

module.exports = MarkdownEditor
