import React from 'react'

class PrivacySection extends React.Component {
  componentDidMount () {}

  render () {
    var content = <div />

    if (this.props) {
      content = <div className='form'>Privacy section</div>
    }

    return content
  }
}

module.exports = PrivacySection
