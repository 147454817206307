import './styles.css'

import React from 'react'
import createReactClass from 'create-react-class'
import $ from 'jquery'

import Node from '../../js/node'
import NodeLink from '../../js/node_link'
import Tab from '../../js/tab'
import Misc from '../../js/misc'

const Switcher = createReactClass({
  displayName: 'Switcher',

  getInitialState: function () {
    var _this = this
    var delayedList = false
    var nodes = [] // this.props.app.orgNodes();
    var tab = this.props.tab
    if (tab && tab.root_id) {
      // var t0 = new Date().getTime();
      // nodes = Node.subTree(tab.root_id, this.props.app.state.entities.nodes);
      nodes = []
      delayedList = true
      // var t1 = new Date().getTime();
      // console.log('subtree time:', t1 - t0 + 'ms');
    }

    const tabs = this.props.app.state.entities.tabs || []
    var allTabs = []
    var org = this.props.org
    var sortedTabs = []
    var next = org
      ? tabs.find(t => t.org_id === org.id && !t.prev_id)
      : tabs.find(t => !t.org_id && !t.prev_id)
    while (next) {
      sortedTabs.push({
        id: next.id,
        name: this.tabName(next, true),
        root_id: next.root_id,
        node_id: next.node_id
      })
      next = tabs.find(t => t.prev_id === next.id)
    }

    allTabs = sortedTabs.filter(
      t => !_this.props.tab || t.id !== _this.props.tab.id
    )

    var all = allTabs.concat(nodes)
    var active = all.length ? all[0].id : null
    // console.log('switcher nodes:', nodes.length);
    return {
      delayedList: delayedList,
      selectedTab: null,
      filter: '',
      actualFilter: '',
      allTabs: allTabs,
      allNodes: nodes,
      tabs: allTabs,
      orgs: [],
      commands: [],
      nodes: nodes,
      active: active,
      prev: null,
      paths: []
    }
  },

  shouldComponentUpdate: function (nextProps) {
    if (this.props.app && this.props.shouldComponentTypeUpdate) {
      return this.props.app.shouldComponentTypeUpdate(nextProps)
    } else {
      return true
    }
  },

  handleOutsideClick: function (ev) {
    if (!$(ev.target).closest('#switcher').length) {
      this.props.app.toggleSwitcher()
    }
  },

  tabName: function (tab, noHighlight) {
    var ret
    var node
    if (tab.name) {
      ret = tab.name
    } else {
      if (tab.node_id) {
        node = Node.get(tab.node_id, this.props.app.state.entities.nodes)
      } else if (tab.root_id) {
        node = Node.get(tab.root_id, this.props.app.state.entities.nodes)
      }

      if (node) {
        ret = node.title
      } else {
        var user = this.props.user
        if (user) {
          ret = user.heading
        } else {
          ret = 'All notes'
        }
      }
    }
    return noHighlight ? ret : Misc.highlightFilter(this.state.filter, ret)
  },

  nodeName: function (node) {
    return Misc.highlightFilter(this.state.filter, node.title)
  },

  commandName: function (command) {
    return Misc.highlightFilter(this.state.filter, command.title)
  },

  orgName: function (org) {
    return Misc.highlightFilter(this.state.filter, org.name)
  },

  filter: function (type, items, filter) {
    var key = type === 'tab' ? 'name' : 'title'
    return window.fuzzer.filter(items, filter, { key: key })
  },

  getPrev: function (valueOnly) {
    var prevTab = this.props.tab
    var prevNode = this.props.rootNode

    var prev = [prevTab, prevNode]
    return valueOnly ? prev : { prev: prev }
  },

  selectItem: function (item, type, ev) {
    var _this = this
    var tab, doIt, rootId

    if (type === 'tab') {
      if (item) {
        var active =
          ['internal-link', 'link'].indexOf(
            this.props.app.state.switcherMode
          ) === -1
            ? 'select'
            : null
        var newData = {
          selectedTab: item.id,
          commands: [],
          orgs: [],
          tabs: [],
          allNodes: [],
          nodes: [],
          active: active,
          filter: '',
          paths: []
        }
        this.props.app.state.filterWorker.postMessage({
          action: 'data',
          data: newData
        })
        var data = {}
        if (item.root_id) {
          data.rootId = item.root_id
        }
        this.props.app.state.filterPathsWorker.postMessage({
          action: 'get-subtree',
          data: data
        })
        this.setState(newData, function () {
          _this.getPaths(0)
        })
      } else {
        this.setState(this.getPrev())
        this.props.app.setTab(this.state.selectedTab, function () {
          _this.props.app.toggleSwitcher()
        })
      }
    } else {
      if (this.props.app.state.switcherMode === 'link') {
        this.props.app.insertLinkAtCursor(item, ev)
      } else if (this.props.app.state.switcherMode === 'internal-link') {
        var src = this.props.app.state.switcherOpts
          ? this.props.app.state.switcherOpts.src
          : null
        if (src) {
          var newLink = {
            src: src.id,
            dst: item.id,
            src_user_id: src.user_id,
            dst_user_id: item.user_id,
            src_org_id: src.org_id,
            dst_org_id: item.org_id
          }
          NodeLink.add(newLink, this.props.app.getCommonParams(), () => {
            this.props.app.toggleSwitcher()
            this.props.app.applyStashedFocus()
          })
        }
      } else {
        switch (type) {
          case 'prev':
            doIt = false
            tab = null
            if (this.state.prev) {
              var prevTab = this.state.prev[0]
              var prevNode = this.state.prev[1]
              if (prevTab) {
                tab = Tab.get(prevTab, this.props.app.state.entities.tabs)
                if (tab) {
                  doIt = true
                }
              } else {
                doIt = true
              }
              if (doIt) {
                this.setState(this.getPrev())
                this.props.app.setRoot(prevNode, tab, function () {
                  _this.props.app.toggleSwitcher()
                })
              }
            }
            break

          case 'root':
            doIt = false
            tab = null
            if (this.state.selectedTab) {
              tab = Tab.get(
                this.state.selectedTab,
                this.props.app.state.entities.tabs
              )
              if (tab) {
                doIt = true
                rootId = tab.root_id
              }
            } else {
              doIt = true
              rootId = this.props.tab.root_id
            }
            if (doIt) {
              this.setState(this.getPrev())
              this.props.app.setRoot(rootId, tab, function () {
                _this.props.app.toggleSwitcher()
              })
            }
            break

          case 'parent':
            if (this.props.rootNode) {
              _this.setState(_this.getPrev())
              this.props.app.setRoot(
                this.props.rootNode.parent_id,
                null,
                function () {
                  _this.props.app.toggleSwitcher()
                }
              )
            }
            break

          // case 'tab':
          //   if (item) {
          //     var active = 'select';
          //     var newData = {
          //       selectedTab: item.id,
          //       commands: [],
          //       orgs: [],
          //       tabs: [],
          //       allNodes: [],
          //       nodes: [],
          //       active: active,
          //       filter: '',
          //       paths: [],
          //     };
          //     this.props.app.state.filterWorker.postMessage({
          //       action: 'data',
          //       data: newData,
          //     });
          //     var data = {};
          //     if (item.root_id) {
          //       data.rootId = item.root_id;
          //     }
          //     this.props.app.state.filterPathsWorker.postMessage({
          //       action: 'get-subtree',
          //       data: data,
          //     });
          //     this.setState(newData, function() {
          //       _this.getPaths(0);
          //     });
          //   } else {
          //     this.setState(this.getPrev());
          //     this.props.app.setTab(this.state.selectedTab, function() {
          //       _this.props.app.toggleSwitcher();
          //     });
          //   }

          //   break;

          case 'node':
            _this.setState(_this.getPrev())
            if (this.state.selectedTab) {
              _this.props.app.setRoot(item, this.state.selectedTab, function () {
                _this.props.app.toggleSwitcher()
              })
            } else {
              if (item.type === 'bookmark' && !ev.metaKey) {
                window.open(item.url, '_blank')
                _this.props.app.toggleSwitcher()
              } else {
                this.props.app.setRoot(item, null, function () {
                  _this.props.app.toggleSwitcher()
                })
              }
            }

            break

          case 'org':
            _this.props.app.toggleSwitcher()
            var orgItem =
              typeof item === 'object' && item.id === '-personal-' ? null : item
            this.props.app.setOrg(orgItem)
            break

          case 'command':
            _this.props.app.toggleSwitcher()
            _this.props.app.executePaletteCommand(item)
            break
        }
      }
    }
  },

  isParentFilter: function (filter) {
    var root = this.props.rootNode
    var tab = this.props.tab
    if (root && root.id !== tab.root_id) {
      return filter === '..' || filter === '<'
    } else {
      return false
    }
  },

  getPaths: function (delay) {
    delay = typeof delay === 'number' ? delay : 100
    var _this = this
    this.pathTimeout = setTimeout(function () {
      // var paths = [];
      // var t0 = new Date().getTime();
      // _this.state.entities.nodes.slice(0, 100).forEach(function(n) {
      //   paths.push(Node.getPath(n, _this.props.app.state.entities.nodes));
      // });
      // var t1 = new Date().getTime();
      // console.log('paths time:', (t1 - t0) + 'ms');
      // _this.setState({paths: paths});
      _this.props.app.state.filterPathsWorker.postMessage({
        action: 'get-paths',
        data: {
          nodes: _this.state.nodes
        }
      })
    }, delay)
  },

  handleFilterChange: function (ev) {
    var _this = this
    if (this.pathTimeout) {
      clearTimeout(this.pathTimeout)
    }
    if (this.filterTimeout) {
      clearTimeout(this.filterTimeout)
    }
    var filter = ev.target.value
    // var tabs = [], active;
    // var commands = this.state.commands;
    // var orgs = this.state.entities.orgs;
    var filteredTabs = this.state.allTabs.filter(
      t => !_this.props.tab || t.id !== _this.props.tab.id
    )
    const orgId = this.props.app.orgId()
    var orgFaves = this.props.app.state.entities.faves.filter(f => {
      return orgId ? f.org_id === orgId : !f.org_id
    })
    var faveNodeIds = orgFaves.map(f => {
      return f.node_id
    })
    var filteredFaves = this.props.app.state.entities.nodes.filter(
      n => faveNodeIds.indexOf(n.id) !== -1
    )
    // if (filter.length) {
    this.filterTimeout = setTimeout(function () {
      _this.props.app.state.filterWorker.postMessage({
        action: 'filter',
        data: {
          selectedTab: _this.state.selectedTab,
          filteredTabs: filteredTabs,
          filteredFaves: filteredFaves,
          filter: filter,
          isParentFilter: _this.isParentFilter(filter)
        }
      })
      _this.setState({
        actualFilter: filter
      })
    }, 200)
    _this.setState({
      filter: filter
    })
    // } else {
    //   tabs = this.state.selectedTab ? [] : filteredTabs;
    //   if (this.state.selectedTab) {
    //     active = 'select';
    //   } else {
    //     if (this.state.prev) {
    //       active = 'prev';
    //     } else {
    //       active = commands.length ? commands[0].id : (orgs.length ? orgs[0].id : (tabs.length ? tabs[0].id : (this.state.allNodes.length ? this.state.allNodes[0].id : null)));
    //     }
    //   }

    //   console.log('here?');
    //   this.setState({
    //     filter: '',
    //     nodes: this.state.allNodes,
    //     commands: [],
    //     orgs: [],
    //     tabs: tabs,
    //     active: active,
    //   }, function() {
    //     _this.getPaths();
    //   });
    // }
  },

  handleKeyDown: function (ev) {
    switch (ev.key) {
      case 'ArrowUp':
        this.activatePrev()
        ev.preventDefault()
        break
      case 'ArrowDown':
        this.activateNext()
        ev.preventDefault()
        break
      case 'Enter':
        var activeEl = $('#switcher li.active')
        if (activeEl.length) {
          if (activeEl.hasClass('select')) {
            this.selectItem(null, 'tab', ev)
          } else if (activeEl.hasClass('prev')) {
            this.selectItem(null, 'prev', ev)
          } else if (activeEl.hasClass('root')) {
            this.selectItem(null, 'root', ev)
          } else if (activeEl.hasClass('parent')) {
            this.selectItem(null, 'parent', ev)
          } else if (activeEl.hasClass('back')) {
            // do nothing
          } else if (activeEl.hasClass('tab')) {
            var tab = this.state.allTabs.find(t => t.id === activeEl.data('id'))
            if (tab) {
              this.selectItem(tab, 'tab', ev)
            }
          } else if (activeEl.hasClass('org')) {
            var org = this.state.orgs.find(o => o.id === activeEl.data('id'))
            if (org) {
              this.selectItem(org, 'org', ev)
            }
          } else if (activeEl.hasClass('command')) {
            this.selectItem(activeEl.data('id'), 'command', ev)
          } else {
            var node = Node.get(
              activeEl.data('id'),
              this.props.app.state.entities.nodes
            )
            if (node) {
              if (ev.shiftKey && node.parent_id) {
                node =
                  Node.get(
                    node.parent_id,
                    this.props.app.state.entities.nodes
                  ) || node
              }
              this.selectItem(node, 'node', ev)
            }
          }
        }

        ev.preventDefault()
        break
      default:
        if (ev.ctrlKey) {
          if (ev.keyCode === 80) {
            this.activatePrev()
            ev.preventDefault()
          }

          if (ev.keyCode === 78) {
            this.activateNext()
            ev.preventDefault()
          }
        }
    }
  },

  activatePrev: function () {
    var active = $('#switcher li.active')
    if (active.length) {
      var prev = active.prev('li')
      if (prev.length) {
        this.setState({ active: prev.data('id') })
      }
    }
  },

  activateNext: function () {
    var active = $('#switcher li.active')
    if (active.length) {
      var next = active.next('li')
      if (next.length) {
        this.setState({ active: next.data('id') })
      }
    }
  },

  icon: function (item, personal) {
    var icon = 'folder-o tab'
    var iconAndType
    if (item) {
      if (item === 'prev') {
        icon = 'backward'
      } else if (item === 'command') {
        icon = 'terminal'
      } else if (item === 'org') {
        icon = personal ? 'user' : 'building-o'
      } else {
        iconAndType = this.props.app.nodeIcon(item, true, true)
        icon = iconAndType[0]
      }
    }
    const type = iconAndType
      ? iconAndType[1]
      : item && item.type
        ? item.type
        : ''
    // return '<span class="item-type octicon octicon-' + icon + '"></span>';
    return '<i class="item-type ' + type + ' fa fa-fw fa-' + icon + '"></i>'
  },

  getPristineState: function (props) {
    var pristineState = this.getInitialState()
    if (
      ['link', 'internal-link'].indexOf(props.app.state.switcherMode) === -1
    ) {
      pristineState.prev = this.state.prev
      if (pristineState.prev) {
        pristineState.active = 'prev'
      }
    }
    return pristineState
  },

  UNSAFE_componentWillReceiveProps: function (nextProps) {
    var _this = this
    this.t0 = new Date().getTime()
    if (nextProps.switcherOpen && !this.props.switcherOpen) {
      var pristineState = this.getPristineState(nextProps)
      this.getPristineState(nextProps)
      var tab = this.props.tab
      var org = this.props.org
      var data = {
        action: 'get-subtree',
        data: {},
        t0: _this.t0
      }
      if (tab && tab.root_id) {
        data.data.rootId = tab.root_id
      }
      if (org && org.id) {
        data.data.orgId = org.id
      }
      if (_this.props.app.state.filterWorker) {
        _this.props.app.state.filterPathsWorker.postMessage(data)
        _this.props.app.state.filterWorker.postMessage({
          action: 'data',
          data: { commandPalette: _this.props.app.commandPalette() }
        })
      }
      _this.setState(pristineState)
    }
  },

  componentDidMount: function () {
    var _this = this
    // var pristineState = this.getPristineState(this.props);
    if (this.props.app.state.filterWorker) {
      this.props.app.state.filterWorker.onmessage = function (e) {
        var data = e.data
        switch (data.action) {
          case 'log':
            console.log(data.msg) // eslint-disable-line no-console
            break
          case 'filter-results':
            _this.setState(data.data, function () {
              _this.getPaths()
            })
            break
        }
      }
      this.props.app.state.filterPathsWorker.onmessage = function (e) {
        var data = e.data
        switch (data.action) {
          case 'log':
            console.log(data.msg) // eslint-disable-line no-console
            break
          case 'path-results':
          case 'subtree-results':
            var doPaths = false
            if (data.action === 'subtree-results') {
              // console.log('>>> subtree-results start', new Date().getTime() - _this.t0); // eslint-disable-line no-console
              data.data.delayedList = false
              data.data.nodes = data.data.nodes
              doPaths = true
            }
            _this.setState(data.data, function () {
              if (doPaths) {
                _this.getPaths(0)
              }
            })
            break
        }
      }
    }
  },

  render: function () {
    var _this = this
    var switcher = <span />
    const mode = this.props.app.state.switcherMode
    if (this.props.app.state.switcherOpen) {
      var commands, orgs, tabs, nodes, active

      if (this.state.commands.length) {
        commands = this.state.commands.map(function (command) {
          active = command.id === _this.state.active ? ' active' : ''
          return (
            <li
              className={'command' + active}
              onClick={ev => _this.selectItem(command, 'command', ev)}
              data-id={command.id}
              key={'switcher-command-' + command.id}
              dangerouslySetInnerHTML={{
                __html: _this.icon('command') + _this.commandName(command)
              }}
            />
          )
        })
      }

      if (this.state.orgs.length) {
        orgs = this.state.orgs.map(function (org) {
          active = org.id === _this.state.active ? ' active' : ''
          return (
            <li
              className={'org' + active}
              onClick={ev => _this.selectItem(org, 'org', ev)}
              data-id={org.id}
              key={'switcher-org-' + org.id}
              dangerouslySetInnerHTML={{
                __html:
                  _this.icon('org', org.id === '-personal-') +
                  _this.orgName(org)
              }}
            />
          )
        })
      }

      if (this.state.tabs.length) {
        tabs = this.state.tabs.map(function (tab) {
          active = tab.id === _this.state.active ? ' active' : ''
          return (
            <li
              className={'tab' + active}
              onClick={ev => _this.selectItem(tab, 'tab', ev)}
              data-id={tab.id}
              key={'switcher-tab-' + tab.id}
              dangerouslySetInnerHTML={{
                __html: _this.icon() + _this.tabName(tab)
              }}
            />
          )
        })
      }

      if (['link', 'internal-link'].indexOf(mode) === -1) {
        if (this.state.filter === '.' || this.state.filter === '/') {
          active = this.state.active === 'root' ? ' active' : ''
          var expl = this.state.selectedTab ? 'selected' : 'current'
          tabs = [
            <li
              className={'tab root' + active}
              data-id='root'
              onClick={ev => _this.selectItem(null, 'root', ev)}
              key='switcher-root'
            >
              (Open root of {expl} tab)
            </li>
          ].concat(tabs)
        }

        if (this.isParentFilter(this.state.filter)) {
          active = this.state.active === 'parent' ? ' active' : ''
          tabs = [
            <li
              className={'tab parent' + active}
              data-id='parent'
              onClick={ev => _this.selectItem(null, 'parent', ev)}
              key='switcher-parent'
            >
              (Open parent item)
            </li>
          ].concat(tabs)
        }

        if (this.state.selectedTab && !this.state.filter) {
          active = this.state.active === 'select' ? ' active' : ''
          tabs = [
            <li
              className={'tab select' + active}
              data-id='select'
              onClick={ev => _this.selectItem(null, 'tab', ev)}
              key='switcher-select'
            >
              (Switch to tab)
            </li>
          ].concat(tabs)
        }

        if (!this.state.selectedTab && !this.state.filter && this.state.prev) {
          active = this.state.active === 'prev' ? ' active' : ''
          var prevTabText = ''
          var prevNodeText = ''
          if (this.state.prev[0]) {
            var tab = Tab.get(
              this.state.prev[0],
              this.props.app.state.entities.tabs
            )
            if (tab && tab.id !== this.props.tab.id) {
              prevTabText = this.tabName(tab)
            }
          }

          if (this.state.prev[1]) {
            var node = Node.get(
              this.state.prev[1],
              this.props.app.state.entities.nodes
            )
            if (node) {
              var chevron = ''
              if (prevTabText) {
                chevron =
                  '<span class="octicon octicon-chevron-right sep"></span>'
              }

              prevNodeText =
                '<span class="node-part">' + chevron + node.title + '</span>'
            }
          }

          tabs = [
            <li
              className={'tab prev' + active}
              data-id='prev'
              onClick={ev => _this.selectItem(null, 'prev', ev)}
              key='switcher-prev'
              dangerouslySetInnerHTML={{
                __html: _this.icon('prev') + prevTabText + prevNodeText
              }}
            />
          ].concat(tabs)
        }
      }

      if (this.state.nodes.length) {
        _this.t1 = new Date().getTime()
        // console.log('filter time', _this.t1 - _this.t0); // eslint-disable-line no-console
        var i = 0
        nodes = this.state.nodes.map(function (node) {
          active =
            node.id === _this.state.active || (i === 0 && !_this.state.active)
              ? ' active'
              : ''
          var path
          var pathElements
          if (_this.state.paths[i]) {
            var pathInfo = _this.state.paths[i].slice(0) // Need to use slice to clone it, since we need to run reverse() on it
            pathElements = pathInfo.reverse().map(function (p) {
              return <li key={'path-' + node.id + '-' + p.id}>{p.title}</li>
            })
          } else {
            pathElements = [<li key={'path-empty-' + node.id} />]
          }
          path = <ul>{pathElements}</ul>
          i++
          return (
            <li
              className={'node' + active}
              onClick={ev => _this.selectItem(node, 'node', ev)}
              data-id={node.id}
              key={'switcher-node-' + node.id}
            >
              <div
                className='title'
                dangerouslySetInnerHTML={{
                  __html: _this.icon(node) + _this.nodeName(node)
                }}
              />
              <div className='path'>{path}</div>
            </li>
          )
        })
      }

      switcher = (
        <div id='switcher-overlay' onClick={this.handleOutsideClick}>
          <div className='switcher-background' />
          <div
            id='switcher-invisible-toggle'
            onClick={this.props.app.toggleSwitcher}
          />
          <div id='switcher'>
            <div className='input-wrapper'>
              <input
                type='text'
                value={this.state.filter}
                onChange={this.handleFilterChange}
                onKeyDown={this.handleKeyDown}
                placeholder='&#8984; + K'
              />
            </div>
            <div className='list'>
              <ul>
                {commands}
                {orgs}
                {tabs}
                {nodes}
              </ul>
            </div>
          </div>
        </div>
      )
    }

    return switcher
  }
})

module.exports = Switcher
