module.exports.RESERVED_WORDS = [
  '_', '_admin', '-', '!', 'robots.txt', 'favicon.ico', 'favicon.png', 'favicon-admin.ico', 'favicon-admin.png', 'notebase', 'notebaseio', 'site', 'dev', 'dist', 'help', 'support',
  'downloads', 'about', 'legal', 'shared', 'settings', 'setting', 'note', 'node', 'notes', 'nodes', 'go', 'do', 'public', 'pub',
  'users', 'user', 'password', 'passwords', 'chat', 'kb', 'articles', 'blog', 'blogs', 'iwatch', 'org', 'organization', 'organisation', 'team', 'teams',
  'security', 'bookmarklets', 'bookmarks', 'bookmark', 'post', 'posts', 'update', 'api', 'v1', 'v2', 'v3', 'v4', 'v5', 'v6', 'v7',
  'v8', 'v9', 'v10', 'contact', 'facebook', 'twitter', 'social', 'int', 'internal', 'app', 'apps', 'mac', 'windows', 'linux',
  'android', 'ios', 'iphone', 'ipad', 'tag', 'tags', 'search', 'my', 'test', 'tests', 'current', 'legacy', 'old', 'new', 'label', 'labels',
  'xxx', 'next', 'prev', 'previous', 'press', 'content', 'log', 'logs', 'static', 'stats', 'images', 'javascripts', 'javascript', 'js',
  'img', 'css', 'styles', 'root', 'admin', 'administrator', 'administration', 'cp', 'dashboard', 'dash', 'history', 'share', 'store',
  'abuse', 'register', 'international', 'global', 'local', 'special', 'db', 'database', 'ws', 'socket', 'realtime',
  'rt', 'live', 'ajax', 'async', 'perf', 'tour', 'features', 'trial', 'pricing', 'benefits', 'compare', 'plans', 'sub',
  'subscription', 'subscriptions', 'invoices', 'invoice', 'order', 'orders', 'prices', 'price', 'business', 'pro', 'themes',
  'theme', 'company', 'enterprise', 'github', 'git', 'reddit', 'google', 'apple', 'amazon', 'app.net', 'cc', 'payments', 'pay',
  'payment', 'refunds', 'refund', 'engine', 'json', 'marketing', 'finance', 'billing', 'account', 'accounts', 'promo',
  'collaborate', 'markdown', 'partial', 'partials', 'register', 'authenticate', 'auth', 'anon', 'anonymous', 'ops', 'devops',
  'talk', 'mobile', 'report', 'flag', 'terms', 'docs', 'reset_password'
]

module.exports.ADJECTIVES = [
  'adaptable', 'adventurous', 'affable', 'affectionate', 'agreeable', 'ambitious', 'amiable', 'amicable', 'amusing', 'brave',
  'bright', 'broad-minded', 'calm', 'careful', 'charming', 'communicative', 'compassionate ', 'conscientious', 'considerate',
  'convivial', 'courageous', 'courteous', 'creative', 'decisive', 'determined', 'diligent', 'diplomatic', 'discreet', 'dynamic',
  'easygoing', 'emotional', 'energetic', 'enthusiastic', 'exuberant', 'faithful', 'fearless', 'forceful', 'frank', 'friendly',
  'funny', 'generous', 'gentle', 'good', 'gregarious', 'hard-working', 'helpful', 'honest', 'humorous', 'imaginative', 'impartial',
  'independent', 'intellectual', 'intelligent', 'intuitive', 'inventive', 'kind', 'loving', 'loyal', 'modest', 'neat', 'nice',
  'optimistic', 'passionate', 'patient', 'persistent ', 'pioneering', 'philosophical', 'placid', 'plucky', 'polite', 'powerful',
  'practical', 'pro-active', 'quick-witted', 'quiet', 'rational', 'reliable', 'reserved', 'resourceful', 'romantic', 'self-confident',
  'self-disciplined', 'sensible', 'sensitive', 'shy', 'sincere', 'sociable', 'straightforward', 'sympathetic', 'thoughtful', 'tidy',
  'tough', 'unassuming', 'understanding', 'versatile', 'warmhearted', 'willing', 'witty'
]

module.exports.NOUNS = [
  'ace', 'ally', 'altruist', 'agent', 'ambassador', 'archetype', 'artisan', 'artist', 'asset', 'benefit', 'bigwig', 'catalyst',
  'cause', 'celebrant', 'celebrator', 'chair', 'champ', 'champion', 'charmer', 'autumn', 'award', 'backbone', 'balloon', 'beach',
  'benefit', 'blockbuster', 'bough', 'bells', 'breeze', 'brook', 'butterfly', 'brightness', 'cake', 'candy', 'candle', 'carnival',
  'celebration', 'champagne', 'charm', 'cheer', 'chocolate', 'climb', 'coast', 'coffee', 'concert', 'cornerstone', 'dawn', 'diamond',
  'dream', 'earth', 'emerald', 'energy', 'fawn', 'festival', 'flagship', 'forest', 'foundation', 'gala', 'galleon', 'gallery',
  'garden', 'gem', 'generator', 'gift', 'gold', 'gravy', 'greeting', 'egg', 'grass', 'greeting', 'harp', 'heart', 'home', 'invention',
  'jewel', 'kitten', 'lamb', 'laugh', 'library', 'lift', 'light', 'monument', 'moon', 'motor', 'mountain', 'museum', 'music', 'novelty',
  'nugget', 'oasis', 'park', 'party', 'poetry', 'pond', 'popcorn', 'present', 'prize', 'rainbow', 'river', 'ruby', 'salutation',
  'sapphire', 'sculpture', 'sky', 'snow', 'spring', 'star', 'strawberry', 'summer', 'sun', 'tool', 'treasure', 'tree', 'trophy',
  'tuxedo', 'universe', 'village', 'water', 'waterfall', 'whirlpool', 'world', 'absoluteness', 'abundance', 'armadillo'
]

module.exports.RESERVED_PUBLIC_WORDS = [
  '_', '-', '!', 'robots.txt', 'favicon.ico', 'favicon.png', 'site', 'dist', 'contact', 'search', 'static', 'ws'
]

module.exports.URL_KEYWORDS = ['shared', 'org']

module.exports.PUBLIC_URL_KEYWORDS = ['search']

module.exports.EMBED_REGEX = '\\/\\/([^\\/]*)(youtube|vimeo|jsfiddle|gist\\.github)(\\.[^\\/]+\\/)(.*)'
module.exports.EMBED_REGEX_REPLACEMENT = 'x$1//$2$3$4$5'
module.exports.VALID_EMAIL_REGEX = /\w+@\w+/
module.exports.VALID_USERNAME_REGEX = /^[a-zA-Z0-9][a-zA-Z0-9-]+$/
module.exports.UUID_REGEXP = /[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12}/

module.exports.OWNER_ACCESS = 400
module.exports.ADMIN_ACCESS = 300
module.exports.WRITE_ACCESS = 200
module.exports.READ_ACCESS = 100
module.exports.NO_ACCESS = 0

module.exports.NOT_NULL = '!!not-NB-null!!'

module.exports.MIN_PASSWORD_LENGTH = 6
module.exports.MIN_USERNAME_LENGTH = 3

module.exports.FREE_NODES_PER_MONTH = 3

module.exports.BROWSER_ITEMS_PER_PAGE = 20

module.exports.FILE_MIME_TYPES = [
  {value: 'image/*', name: 'Image'},
  {value: 'text/plain', name: 'Plain text'},
  {value: 'text/css', name: 'Stylesheet (CSS)'},
  {value: 'text/html', name: 'HTML'},
  {value: 'text/javascript', name: 'Javascript'}
]
