import React from 'react'
import createReactClass from 'create-react-class'

// import Mixins from '../../../mixins/mixins';
import Org from '../../../js/org'
import Misc from '../../../js/misc'

const CreateOrgOverlay = createReactClass({
  displayName: 'CreateOrgOverlay',
  // mixins: [Mixins.FormHandling, Mixins.ErrorHandling],

  getInitialState: function () {
    var state = {
      message: '',
      error: null,
      invalidFields: [],
      selectedUserId: null,
      newOrg: {}
    }
    return state
  },

  UNSAFE_componentWillMount: function () {
    this.handleFormFieldChange = Misc.handleFormFieldChange.bind(this, this)
    this.errorFor = Misc.errorFor.bind(this, this)
  },

  registerOrg: function (ev) {
    if (ev.preventDefault()) {
      ev.preventDefault()
    }
    this.props.app.ajax('register_org', { body: this.state.newOrg }, res => {
      // const newEntities = Object.assign({}, this.props.app.state.entities);
      // for (let key in res.entities) {
      //   if (key === 'nodes') {
      //     newEntities.nodes = newEntities.nodes.concat(res.entities.nodes);
      //   } else {
      //     newEntities[key] = res.entities[key];
      //   }
      // }
      // this.props.app.setState({entities: newEntities}, () => {
      // TODO: Show global success message
      const orgId = res.id
      this.props.app.setOrg(orgId, () => {
        const org = Org.get(orgId, this.props.app.state.entities.orgs)
        this.props.app.setPanel('organizations-page', {
          data: {
            org: org
          },
          title: 'Manage organizations',
          activeSection: 'settings-section'
        })
      })
      // });
    })
  },

  selectLogo: function (ev) {
    var _this = this
    this.props.app.browseFiles({ fileType: 'image/*' }, function (file) {
      var fileUrl = Misc.fileUrl(file)
      var org = _this.getCurrentOrg()
      if (org) {
        var newSettings = { ...org.settings }
        newSettings.logo = fileUrl
        var data = { id: org.id, updates: { settings: newSettings } }
        Misc.jx(_this, 'update_org', data, function (res) {
          // eslint-disable-line no-unused-vars
          org.settings = newSettings
          _this.updateOrgs(org)
        })
      }
    })
    ev.preventDefault()
  },

  clearLogo: function (ev) {
    var _this = this
    var org = _this.getCurrentOrg()
    if (org) {
      var newSettings = { ...org.settings }
      newSettings.logo = null
      var data = { id: org.id, updates: { settings: newSettings } }
      Misc.jx(_this, 'update_org', data, function (res) {
        // eslint-disable-line no-unused-vars
        org.settings = newSettings
        _this.updateOrgs(org)
      })
    }
    ev.preventDefault()
  },

  render: function () {
    // Code that has been deleted from this can be found in the org_overlay.jsx file. And will be moved to the actual manage orgs page
    return (
      <form action='' onSubmit={this.registerOrg}>
        <div className='row'>
          <div className='row-heading'>
            <label>Name:</label>
          </div>
          <div className='row-content'>
            <input
              type='text'
              placeholder='Apple, Samsung, EA Sports'
              onChange={this.handleFormFieldChange}
              data-name='name'
              data-obj='newOrg'
            />
            {this.errorFor('name')}
          </div>
        </div>
        <div className='row'>
          <div className='row-heading'>
            <label>Identifier:</label>
          </div>
          <div className='row-content'>
            <input
              type='text'
              placeholder='apple, samsung, easports'
              onChange={this.handleFormFieldChange}
              data-name='identifier'
              data-obj='newOrg'
            />
            {this.errorFor('identifier')}
            <small className='muted'>
              This is like a username for your team. It has to be globally
              unique. Valid characters: a-z, 0-9, dash, underscore.
            </small>
          </div>
        </div>
        <div className='buttons'>
          <button className='button' onClick={this.registerOrg}>
            Create
          </button>
        </div>
      </form>
    )
  }
})

module.exports = CreateOrgOverlay
