import React from 'react'

class DebuggingSection extends React.Component {
  state = {}

  componentDidMount () {}

  saveSettings = () => {
    var newSettings = {}
    if (this.props.app.state._dev) {
      newSettings._show_index = this._showIndex.checked
      newSettings.auto_sync = this.autoSync.checked
    }
    this.props.app.saveUserSettings(
      newSettings,
      this.props.app.getCommonParams()
    )
  }

  render () {
    var content = <div />

    if (this.props.app.state._dev) {
      content = (
        <div className='form'>
          <div className='row'>
            <label>DEBUG: Show node index</label>
            <input
              type='checkbox'
              ref={_showIndex => {
                this._showIndex = _showIndex
              }}
              checked={this.props.user.settings._show_index}
              onChange={this.saveSettings}
            />{' '}
            Show index
          </div>
          <div className='row'>
            <label>DEBUG: Auto-sync with server</label>
            <input
              type='checkbox'
              ref={autoSync => {
                this.autoSync = autoSync
              }}
              checked={this.props.user.settings.auto_sync}
              onChange={this.saveSettings}
            />{' '}
            Auto-sync
          </div>
        </div>
      )
    }

    return content
  }
}

module.exports = DebuggingSection
