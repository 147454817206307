import './styles.css'

import React from 'react'

import SetPlaceholder from '../../set_placeholder'

export default class Phones extends React.Component {
  render () {
    const { app, node } = this.props
    const data = node.data || {}
    return (
      <div
        onClick={ev =>
          app.toggleFloater(ev.target, 'phones-picker', {
            nodeId: node.id
          })
        }
      >
        {data.phones && (
          <div className='actual-data'>{JSON.stringify(data.phones)}</div>
        )}
        {!data.phones && <SetPlaceholder />}
      </div>
    )
  }
}
