import React from 'react'

import './styles.css'

export default class LabelsSection extends React.Component {
  render () {
    const { app } = this.props
    return (
      <div className='labels-section'>
        <button className='' onClick={() => app.pushSidebar('labels')}>
          Manage Labels
        </button>
      </div>
    )
  }
}
