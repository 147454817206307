import React from 'react'

import Avatar from '../../../../avatar'
import Org from '../../../../../js/org'

// import User from '../../../../../js/user';

class ManageUsersSection extends React.Component {
  constructor (props, context) {
    super(props, context)
    var org = this.getCurrentOrg() || {}
    var state = {
      message: '',
      error: null,
      inviteStr: '',
      inviteType: 'regular',
      orgId: org.id
    }
    this.state = state
  }

  componentDidMount () {}

  getCurrentOrg = () => {
    const _this = this
    let org
    if (
      this.props.activeVtabId &&
      this.props.app.state.entities.orgs &&
      this.props.app.state.entities.orgs.length
    ) {
      org = Org.get(
        _this.props.activeVtabId,
        this.props.app.state.entities.orgs
      )
    }
    return org
  }

  registerInvite = () => {
    var org = this.getCurrentOrg()
    if (org) {
      var data = {
        str: this.state.inviteStr,
        type: this.state.inviteType,
        org_id: org.id
      }
      this.props.app.ajax('register_org_invite', { body: data }, () => {
        this.setState({ inviteStr: '', inviteType: 'regular' })
      })
      // Misc.jx(this, 'register_org_invite', data, function(res) {
      //   // TODO: Show global success message
      //   this.props.app.applyChanges(res.body.syncData, function() {
      //     _this.setState({inviteStr: ''}, function() {
      //       //_this.selectInvite(res.body.id);
      //     });
      //   });
      //   // var newOrg = _.xclone(org);
      //   // newOrg.invites = org.invites.concat([res.body]);
      //   // _this.updateOrgs(newOrg, function() {
      //   //   _this.setState({inviteStr: ''}, function() {
      //   //     _this.selectInvite(res.body);
      //   //   });
      //   // });
      // }.bind(this));
    }
  }

  // resendInvite: function(inviteIdOrEv, ev) {
  // var invite = inviteOrEv.preventDefault ? this.state.selectedInvite : inviteOrEv;
  // ev = inviteOrEv.preventDefault ? inviteOrEv : ev;

  resendInvite = (inviteId, ev) => {
    var org = this.getCurrentOrg()
    if (!org) return

    var data = {
      invite_id: inviteId
    }
    this.props.app.ajax('resend_org_invite', { body: data })

    ev.preventDefault()
  }

  handleInviteFieldChange = ev => {
    this.setState({ inviteStr: ev.target.value })
  }

  handleInviteFieldKeyDown = ev => {
    if (ev.key === 'Enter') {
      if (/^[a-zA-Z0-9.-_@+]+$/.test(this.state.inviteStr)) {
        this.registerInvite()
      }
      ev.preventDefault()
    }
  }

  handleInviteTypeChange = ev => {
    this.setState({ inviteType: ev.target.value })
  }

  removeUser = (userId, ev) => {
    var doIt = () => {
      var org = this.getCurrentOrg()
      if (!org) return

      // TODO: Add sanity checks (don't delete last user, at least)
      var data = {
        user_id: userId,
        org_id: org.id
      }

      this.props.app.ajax('remove_user_from_org', { body: data })
      // Misc.jx(this, 'remove_user_from_org', data, function(res) { // eslint-disable-line no-unused-vars
      //   // TODO: Show global success message
      //   var trimmedUsers = _.xfilter(org.users, function(u) { return u.id !== userId; });
      //   org.users = trimmedUsers;
      //   var trimmedOrgs = _.xfilter(this.props.app.state.entities.orgs, function(o) { return o.id !== org.id; });
      //   this.props.app.setState({orgs: trimmedOrgs.concat([org])}, function() {
      //     // _this.selectUser();
      //   });
      // }.bind(this));
    }

    this.props.app.getConfirmation(() => {
      doIt()
    })
    ev.preventDefault()
  }

  cancelInvite = (inviteId, ev) => {
    var doIt = () => {
      var org = this.getCurrentOrg()
      if (!org) return

      var data = {
        invite_id: inviteId
      }

      this.props.app.ajax('cancel_org_invite', { body: data })
    }

    this.props.app.getConfirmation(() => {
      doIt()
    })
    // Misc.jx(this, 'cancel_org_invite', data, function(res) { // eslint-disable-line no-unused-vars
    //   // TODO: Show global success message
    //   org.invites = _.xfilter(org.invites, function(i) { return i.id !== inviteId; });
    //   var trimmedOrgs = _.xfilter(this.props.app.state.entities.orgs, function(o) { return o.id !== org.id; });
    //   this.props.app.setState({orgs: trimmedOrgs.concat([org])}, function() {
    //     //_this.selectInvite();
    //   });
    // }.bind(this));

    ev.preventDefault()
  }

  render () {
    const { app } = this.props
    var content = <div />
    var orgs = app.state.entities.orgs
    if (orgs && orgs.length) {
      // var ownersGroup;
      var org = this.getCurrentOrg()
      if (!org) {
        org = orgs[0]
      }
      // var isOwner = Org.isOwner(org, _this.props.user, _this.props.app.getCommonParams());

      var activeUserItems
      var orgUsers = Org.users(org, app.getCommonParams())
      if (orgUsers && orgUsers.length) {
        activeUserItems = orgUsers.map(user => {
          const orgUser = app.orgUser(user)
          return (
            <tr key={'org:' + org.id + '-user:' + user.id}>
              <td>
                <Avatar user={user} app={app} size='small' />
              </td>
              <td>{user.username}</td>
              <td>{user.email}</td>
              <td>{orgUser.type[0].toUpperCase() + orgUser.type.slice(1)}</td>
              <td>Enabled</td>
              {/* <td>Read/write</td> */}
              <td>
                <div className='green'>
                  <i className='fa fa-fw fa-circle' />
                </div>
              </td>
              <td onClick={() => this.removeUser(user.id)} className='delete'>
                <i className='fa fa-fw fa-remove' />
              </td>
            </tr>
          )
        })
      }

      var pendingInvites
      var orgInvites = Org.invites(org, this.props.app.getCommonParams())
      if (orgInvites && orgInvites.length) {
        pendingInvites = orgInvites.map(invite => {
          return (
            <tr key={'org:' + org.id + '-invite:' + invite.id}>
              <td />
              <td>{`${invite.user ? invite.user.username : '-'}`}</td>
              <td>{`${invite.user ? '-' : invite.user_string}`}</td>
              <td>{invite.type[0].toUpperCase() + invite.type.slice(1)}</td>
              <td>Invitation pending</td>
              {/* <td>Read/write</td> */}
              <td>
                <div className='gray'>
                  <i className='fa fa-fw fa-circle' />
                </div>
              </td>
              <td>
                <i
                  title='Cancel invite'
                  className='fa fa-fw fa-remove'
                  onClick={ev => this.cancelInvite(invite.id, ev)}
                />
                <i
                  title='Resend invitation email'
                  className='fa fa-fw fa-refresh'
                  onClick={ev => this.resendInvite(invite.id, ev)}
                />
              </td>
            </tr>
          )
        })
      }

      // var membershipRequests;
      // var orgRequests = Org.requests(org, this.props.app.getCommonParams());
      // if (orgRequests && orgRequests.length) {
      //   membershipRequests = orgRequests.map(function(request) {
      //     var requestUser = User.get(request.user_id, _this.props.app.state.entities.users);
      //     var userStr = requestUser ? requestUser.first_name + ' ' + requestUser.last_name : '(unknown user)';
      //     return (
      //       <tr key={'org:' + org.id + '-request:' + request.id}>
      //         <td colSpan="2">{{userStr}}</td>
      //         <td>Requesting membership</td>
      //         <td>Read/write</td>
      //         <td><div className="gray"><i className="fa fa-fw fa-circle"></i></div></td>
      //         <td><i className="fa fa-fw fa-cog"></i></td>
      //       </tr>
      //     );
      //   });
      // }

      content = (
        <div className='form'>
          <div className='row'>
            <label>Users</label>
            <table className='table'>
              <tbody>
                <tr>
                  <th />
                  <th>Username</th>
                  <th>Email</th>
                  <th>Type</th>
                  <th>Status</th>
                  <th>Active</th>
                  <th />
                </tr>
                {activeUserItems}
                {pendingInvites}
                {/* membershipRequests */}
              </tbody>
            </table>
          </div>

          <div className='row spacer' />

          <div className='row'>
            <label>Invite user</label>
            <div className='row input-plus-button'>
              <input
                type='text'
                value={this.state.inviteStr}
                onKeyDown={this.handleInviteFieldKeyDown}
                onChange={this.handleInviteFieldChange}
                placeholder='Type in a username or an email address to invite another user'
              />
              <select
                onChange={this.handleInviteTypeChange}
                value={this.state.inviteType}
              >
                <option value='regular'>Regular</option>
                <option value='guest'>Guest</option>
              </select>
              <button className='button' onClick={this.registerInvite}>
                Invite user
              </button>
            </div>
          </div>

          <div className='row spacer' />

          {/* Let users request membership
          <div className="row">
            <label><input type="checkbox" defaultChecked /> Let users request membership</label>
            <div className="small-description">Allow users to request membership to this organization using the URL below</div>
            <p><a href="https://dev.notebase.io/org/request/uIRlfYe7">https://dev.notebase.io/org/request/uIRlfYe7</a></p>
          </div>
          */}
        </div>
      )
    }

    return content
  }
}

module.exports = ManageUsersSection
