var React = require('react')
var $ = require('jquery')
var _ = require('lodash')
var Cookies = require('cookies-js')
var superagent = require('superagent')
var toMarkdown = require('to-markdown')
// var shortId = require('shortid');
var MediumEditor = require('medium-editor')
var MediumButton = require('medium-button')
var stableStringify = require('json-stable-stringify')
var constants = require('../..//lib/constants')

var CommandPalette = require('../command_palette')

var Node = require('../../js/node')
var Tab = require('../../js/tab')
var Org = require('../../js/org')
var User = require('../../js/user')
var Fave = require('../../js/fave')
var Like = require('../../js/like')
var Misc = require('../../js/misc')
var Text = require('../../js/text')

window._app = window._app || {}
window._app.Node = Node

module.exports = {
  getCommonParams: function () {
    return {
      app: this,
      state: this.state,
      updatedState: {},
      stateParams: {},
      entities: this.state.entities,
      rootNode: this.rootNode,
      user: this.user,
      org: this.org,
      tab: this.tab,
      undo: this.state.undo,
      tmpVisible: this.state.tmpVisible,
      setAppState: this.setAppState,
      setRoot: this.setRoot,
      setActiveNodeEl: this.setActiveNodeEl,
      toggleCompleted: this.toggleCompleted,
      // syncClientData: this.syncClientData,
      isOwner: this.isOwner,
      searchstring: this.state.searchstring,
      flushTextUndo: this.flushTextUndo,
      _shortId: Misc.shortId,
      _parseMarkdown: Misc.parseMarkdown
    }
  },

  doUndo: function () {
    clearTimeout(window._app.titleUndoTimeout)
    this.setLastUserAction()
    this.state.undo.undo(this.getCommonParams())
  },

  doRedo: function () {
    clearTimeout(window._app.titleUndoTimeout)
    this.setLastUserAction()
    this.state.undo.redo(this.getCommonParams())
  },

  hasUndo: function () {
    return this.state.undo.hasUndo()
  },

  hasRedo: function () {
    return this.state.undo.hasRedo()
  },

  flushTextUndo: function (args, params, callback) {
    Node.flushTextUndo(args, params, callback)
  },

  login: function (username, password, params, callback) {
    var data = {
      username: username,
      password: password
    }

    var headers = {}
    if (window._app.shareId) {
      headers['X-Notebase-Secret-ID'] = window._app.shareId
    }
    if (window._app.inviteId) {
      data.inviteId = window._app.inviteId
    }

    superagent
      .post(NOTEBASE_URL_PREFIX + '/authenticate')
      .set(headers)
      .send(data)
      .end(function (err, res) {
        if (!err && res.ok) {
          if (!res.body.error) {
            console.log('Setting token to ', res.body.token) // eslint-disable-line no-console
            window.sessionStorage.setItem('authToken', res.body.token)
            // TODO: Implement remember-me flag
            window.localStorage.setItem('authToken', res.body.token)
            Cookies.set('authToken', res.body.token, {
              expires: 60 * 60 * 24 * 30
            })
          }
        } else {
          console.log(
            'An error occurred while communicating with the server: ' + res.text
          ) // eslint-disable-line no-console
          window.sessionStorage.removeItem('authToken')
          window.localStorage.removeItem('authToken')
          Cookies.expire('authToken')
        }
        if (typeof callback === 'function') {
          callback(res)
        }
      })
  },

  register: function (userdata, params, callback) {
    // TODO: this looks awfully similar to the login call, DRY it up
    var data = {
      invite_code: userdata.inviteCode,
      first_name: userdata.firstName,
      last_name: userdata.lastName,
      email: userdata.email,
      username: userdata.username,
      password: userdata.password
    }

    var headers = {}
    if (window._app.shareId) {
      headers['X-Notebase-Secret-ID'] = window._app.shareId
    }
    if (window._app.inviteId) {
      data.inviteId = window._app.inviteId
    }

    if (params.orgRequestInviteId) {
      data.orgRequestInviteId = params.orgRequestInviteId
    }

    superagent
      .post(NOTEBASE_URL_PREFIX + '/register')
      .set(headers)
      .send(data)
      .end(function (err, res) {
        if (!err && res.ok) {
          if (!res.body.error) {
            console.log('Setting token to ', res.body.token) // eslint-disable-line no-console
            window.sessionStorage.setItem('authToken', res.body.token)
            // TODO: Implement remember-me flag
            window.localStorage.setItem('authToken', res.body.token)
            Cookies.set('authToken', res.body.token, {
              expires: 60 * 60 * 24 * 30
            })
          }
        } else {
          console.log(
            'An error occurred while communicating with the server: ' + res.text
          ) // eslint-disable-line no-console
          window.sessionStorage.removeItem('authToken')
          window.localStorage.removeItem('authToken')
          Cookies.expire('authToken')
        }
        if (typeof callback === 'function') {
          callback(res)
        }
      })
  },

  insertLinkAtCursor: function (node, ev) {
    // var url = '/n/' + node.short_id || node.id;
    var url = 'nb:' + node.short_id || node.id
    if (window._app.editor) {
      var editor = window._app.editor
      // var selected = editor.getSelectedText();
      var selected = editor.getSelection()
      var linkText = selected || node.title
      var title = selected ? ' "' + node.title.replace(/"/g, '') + '"' : ''
      var text = '[' + linkText + '](' + url + title + ')'
      // editor.session.replace(editor.selection.getRange(), text);
      editor.replaceSelection(text)
      editor.focus()
    }

    this.toggleSwitcher(ev)
    ev.preventDefault()
  },

  insertUrlAtCursor: function (file, ev) {
    console.log('file', file) // eslint-disable-line no-console
    var url = Misc.fileUrl(file)
    if (window._app.editor) {
      var text = Misc.isImage(file.mimetype) ? '!' : ''
      text += '[' + file.filename + '](' + url + ')'
      // window._app.editor.insert(text);
      window._app.editor.replaceSelection(text)
      window._app.editor.focus()
    } else {
      var focusEl = $(':focus')
      if (focusEl.length) {
        var caretPos = focusEl.get(0).selectionStart
        var focusText = focusEl.val()
        focusEl.val(
          focusText.substring(0, caretPos) + url + focusText.substring(caretPos)
        )
      }
    }

    this.closeFileBrowser(ev)
    ev.preventDefault()
  },

  getConfirmation: function (
    confirmCallback,
    declineCallback,
    message,
    confirmButtonText,
    declineButtonText
  ) {
    message = message || 'Are you sure?'
    confirmButtonText = confirmButtonText || 'Yes'
    declineButtonText = declineButtonText || 'No'
    this.setState({
      showConfirmation: true,
      confirmCallback: confirmCallback,
      declineCallback: declineCallback,
      confirmationMessage: message,
      confirmButtonText: confirmButtonText,
      declineButtonText: declineButtonText
    })
  },

  closeConfirmation: function () {
    this.setState({
      showConfirmation: false,
      confirmCallback: null,
      declineCallback: null,
      confirmationMessage: 'Are you sure?',
      confirmButtonText: 'Yes',
      declineButtonText: 'No'
    })
  },

  doConfirm: function () {
    if (typeof this.state.confirmCallback === 'function') {
      this.state.confirmCallback()
    }

    this.closeConfirmation()
  },

  doDecline: function () {
    if (typeof this.state.declineCallback === 'function') {
      this.state.declineCallback()
    }

    this.closeConfirmation()
  },

  // Remember, this is duplicated in filter-worker
  orgNodes: function (orgOrId, nodes) {
    nodes = nodes || this.state.entities.nodes || []
    var orgId
    if (orgOrId) {
      orgId = typeof orgOrId === 'string' ? orgOrId : orgOrId.id
    } else {
      orgId = this.orgId()
    }

    var retNodes
    if (orgId) {
      retNodes = nodes.filter(n => n.org_id === orgId)
    } else {
      if (this.state.userId) {
        retNodes = nodes.filter(n => n.user_id === this.state.userId)
      } else {
        retNodes = nodes
      }
    }
    var remotes = retNodes.filter(n => n.remote_id)
    remotes.forEach(function (n) {
      var remoteRe = new RegExp(n.remote_id.replace(/-/g, '_') + '.')
      var remoteNodes = nodes.filter(nd => remoteRe.test(nd.path))
      retNodes = retNodes.concat(remoteNodes)
    })
    return retNodes
  },

  // Remember, this is duplicated in filter-worker
  tabNodes: function (tabOrId, nodes) {
    nodes = nodes || this.state.entities.nodes
    var tab = this.tab(tabOrId)
    if (tab) {
      if (tab.root_id) {
        return Node.subTree(tab.root_id, nodes)
      } else {
        return this.orgNodes()
      }
    }
  },

  toggleSwitcher: function (ev, mode, opts) {
    var _this = this
    opts = opts || {}
    var oldMode = this.state.switcherMode
    var state = {
      switcherOpen: !this.state.switcherOpen,
      switcherMode: mode,
      switcherOpts: opts
    }
    if (state.switcherOpen) {
      _this.stashFocus()
    }
    this.setState(state, function () {
      if (this.state.switcherOpen) {
        setTimeout(() => {
          $('#switcher input[type="text"]').focus()
        })
      } else {
        if (oldMode === 'link' && window._app.editor) {
          window._app.editor.focus()
        } else {
          _this.applyStashedFocus()
        }
      }
    })
    window.Intercom && window.Intercom('trackEvent', 'used-quick-switcher') // jshint ignore:line
  },

  toggleViewMode: function () {
    var tab = this.tab()
    if (tab) {
      var newMode = tab.settings.view_mode === 'list' ? null : 'list'
      this.updateTab(tab, null, { view_mode: newMode })
    }
  },

  toggleInbox: function () {
    // var user = this.user();
    // this.saveUserSettings({show_inbox: !user.settings.show_inbox}, this.getCommonParams());
    const last = this.state.sidebarStack[this.state.sidebarStack.length - 1]
    if (last && last[0] === 'inbox') {
      this.popSidebar()
    } else {
      this.pushSidebar(['inbox'])
    }
    window.Intercom && window.Intercom('trackEvent', 'used-inbox') // jshint ignore:line
  },

  nodeLink: function (nodeOrId, user, shareOrId) {
    const share =
      shareOrId && typeof shareOrId === 'string'
        ? this.share(shareOrId)
        : shareOrId
    var node = Node.get(nodeOrId, this.state.entities.nodes)
    var link = '/'
    if (share) {
      link += 'shared/' + share.secret_id

      // } else {
      // link += this.state.user.username;
    }

    if (node) {
      if (!share) {
        link += 'n'
      }

      link += '/' + (node.short_id || node.id)
    }

    return NOTEBASE_LOCAL_URL_PREFIX + link.replace('//', '/')
  },

  saveUserSettings: function (newSettings, params, callback) {
    var user = this.user()
    if (user) {
      var prevHideWidgets = !user.settings.hideWidgets
      var autoSyncWasOff = !user.settings.auto_sync
      var settings = _.extend(user.settings, newSettings)
      if (prevHideWidgets !== newSettings.hide_widgets) {
        params.updatedState.updateAllNodes = true
      }
      User.modify(user.id, { settings: settings }, params, modParams => {
        if (settings.auto_sync && autoSyncWasOff) {
          this.doPending()
        }
        if (typeof callback === 'function') {
          callback(modParams)
        }
      })
    }
  },

  toggleCompleted: function (node, doReturn, ev) {
    var newValue = !node.completed
    var next = Misc.nextNodeEl(node.id)
    var prev = Misc.prevNodeEl(node.id)
    var nextId = next.length ? next.data('node-id') : prev.data('node-id')

    if (Misc.hideCompleted(this.user(), this.tab())) {
      // console.log('----DOING HIDE TIMEOUT----');
      var newTmpVisible = { ...this.state.tmpVisible }
      newTmpVisible.push(node.id)
      this.setAppState({ tmpVisible: newTmpVisible })
      setTimeout(() => {
        this.setAppState({ tmpVisible: [] })
        setTimeout(() => {
          Node.focus(nextId, null, this.getCommonParams())
        }, 10)
      }, 1500)
    }

    if (ev) {
      ev.preventDefault()
    }

    window.Intercom && window.Intercom('trackEvent', 'completed-node') // jshint ignore:line

    const commonParams = this.getCommonParams()
    const rootNodeId = this.rootNodeId()
    if (rootNodeId) {
      commonParams.updatedState = commonParams.updatedState || {}
      const updatedNodes = (
        commonParams.updatedState.updatedNodes ||
        commonParams.state.updatedNodes ||
        []
      ).slice()
      updatedNodes.push(this.rootNode())
      commonParams.updatedState.updatedNodes = updatedNodes
    }
    Node.modify(node, { completed: newValue }, commonParams)
  },

  toggleEmphasis: function (node) {
    var newSettings = { ...node.settings }
    newSettings.emphasized = !node.settings.emphasized
    Node.modify(node, { settings: newSettings }, this.getCommonParams())
  },

  toggleFave: function (node) {
    const fave = this.state.entities.faves.find(f => {
      return f.node_id === node.id
    })
    if (fave) {
      Fave.remove(fave.id, this.getCommonParams())
    } else {
      const userId = this.userId()
      if (userId) {
        Fave.add(
          { user_id: userId, node_id: node.id, org_id: this.orgId() },
          this.getCommonParams()
        )
      }
    }
  },

  toggleLike: function (nodeId) {
    const likeCb = () => this.setAppState({ updatedNodes: [nodeId] })
    var meLikey = this.state.entities.likes.find(l => {
      return l.user_id === this.state.userId && l.node_id === nodeId
    })
    if (meLikey) {
      Like.remove(meLikey.id, this.getCommonParams(), likeCb)
    } else {
      var newLike = { node_id: nodeId, user_id: this.state.userId }
      Like.add(newLike, this.getCommonParams(), likeCb)
    }
  },

  deleteNode: function (nodeOrId) {
    var li
    var node =
      typeof nodeOrId === 'string'
        ? Node.get(nodeOrId, this.state.entities.nodes)
        : nodeOrId
    if (node) {
      var accessChecked = false
      if (this.rootNodeId() === node.id) {
        accessChecked = true
        var path = Node.getPath(node, this.state.entities.nodes)
        var remoteInPath = path.find(n => n.remote_id)
        if (remoteInPath) {
          var share = this.state.shares.find(
            s => s.id === remoteInPath.share_id
          )
          if (share) {
            if (
              share.type === 'link' &&
              share.access_level < constants.WRITE_ACCESS
            ) {
              accessChecked = false
            } else if (share.type === 'link') {
              var userRecord = share.users.find(
                u => u.user_id === this.state.userId
              )
              if (
                !userRecord ||
                userRecord.access_level < constants.WRITE_ACCESS
              ) {
                accessChecked = false
              }
            }
          }
        }
      } else {
        li = $(Misc.nodeEl(node))
      }

      // Allow delete if node is a remote for the current user OR there is no read-only flag set for the tree
      if (
        accessChecked ||
        (this.state.userId &&
          node.user_id === this.state.userId &&
          node.remote_id) ||
        (li && !li.closest('li.node.read-only').length)
      ) {
        Node.remove(node.id, this.getCommonParams())
      }
    }
  },

  convertTreeToDocument: function (nodeOrId) {
    var node =
      typeof nodeOrId === 'string'
        ? Node.get(nodeOrId, this.state.entities.nodes)
        : nodeOrId
    if (node) {
      var readOnly = false
      var li = $(Misc.nodeEl(node))
      if (li.length) {
        if (li.closest('li.node.read-only').length) {
          readOnly = true
        }
      } else {
        var bodyContainer = $('#body-container')
        if (bodyContainer.length && bodyContainer.hasClass('read-only')) {
          readOnly = true
        }
      }

      if (!readOnly) {
        // TODO: Check if children contain difficult-to-merge stuff, like sites, and if so, bail out and warn the user
        var exportedData = Node.export(
          node,
          'convert',
          this.state.entities.nodes
        )
        var body = node.body || ''
        var newBody = body ? body + '\n\n' + exportedData : exportedData
        var changes = { body: newBody }
        // var oldType = node.type;
        // if (!(oldType === 'note' || oldType === 'site')) {
        //   changes.type = 'note';
        // }

        var recurseChild = (prevId, children, recurseParams, recurseCb) => {
          let next
          if (prevId) {
            next = children.find(c => c.prev_id === prevId) // jshint ignore:line
          } else {
            next = children.find(child => !child.prev_id)
          }
          if (next) {
            Node.remove(next.id, recurseParams, delParams => {
              recurseChild(next.id, children, delParams, recurseCb)
            })
          } else {
            if (typeof recurseCb === 'function') {
              recurseCb(recurseParams)
            }
          }
        }

        var commonParams = this.getCommonParams()
        Node.modify(node.id, changes, commonParams, modParams => {
          const nodes = modParams.updatedState.entities
            ? modParams.updatedState.entities.nodes
            : modParams.state.entities.nodes
          var children = Node.children(node.id, nodes)
          recurseChild(null, children, modParams)
        })
      }
    }
    window.Intercom && window.Intercom('trackEvent', 'converted-tree-to-note') // jshint ignore:line
  },

  setDueDate: function (nodeOrId, date, callback) {
    date = typeof date === 'undefined' ? new Date() : date
    var commonParams = this.getCommonParams()
    commonParams.updatedState.dueNode = null
    window.Intercom && window.Intercom('trackEvent', 'set-due-date') // jshint ignore:line
    Node.modify(nodeOrId, { due_at: date }, commonParams, callback)
  },

  // setAssignedTo: function(node, user, callback) {
  setAssignedTo: function (node, user) {
    var userId = user ? user.id : null
    var commonParams = this.getCommonParams()
    commonParams.updatedState.assignNode = null
    commonParams.updatedState.assignFilter = null
    commonParams.updatedState.assignPickerIndex = 0
    if (user) {
      window.Intercom && window.Intercom('trackEvent', 'set-assigned-to') // jshint ignore:line
    }
    this.ajax('add_node_user', {
      body: { node_id: node.id, assigned_to: userId, org_id: this.orgId() }
    })
    // Node.modify(node.id, {assigned_to: userId}, commonParams, callback);
  },

  getAppState: function (key) {
    return this.state[key]
  },

  setAppState: function (state, params, callback) {
    params = params || {}
    state = state || {}
    if (typeof params === 'function') {
      callback = params
      params = {}
    }
    if (params.noop) {
      if (typeof callback === 'function') {
        callback(state)
      }
    } else {
      // var parsedBody, rootNode;
      var rootNode
      var _this = this
      var rootWasSet = params.rootWasSet
      var tabWasSet = params.tabWasSet
      var orgWasSet = params.orgWasSet
      var nodes =
        (state.entities && state.entities.nodes) ||
        (this.state.entities && this.state.entities.nodes)
      // var tabs = (state.entities && state.entities.tabs) || (this.state.entities && this.state.entities.tabs);
      var userId = state.hasOwnProperty('userId')
        ? state.userId
        : this.state.userId
      var shareId = state.hasOwnProperty('shareId')
        ? state.shareId
        : this.state.shareId
      var anonShareId = state.hasOwnProperty('anonShareId')
        ? state.anonShareId
        : this.state.anonShareId
      // var consumedShares = state.consumedShares || this.state.consumedShares;
      // var rootNodeId = state.rootNodeId || this.state.rootNodeId;
      var searchstring = state.searchstring || this.state.searchstring
      var filter = state.hasOwnProperty('filter')
        ? state.filter
        : this.state.filter
      var editNodeId = state.hasOwnProperty('editNodeId')
        ? state.editNodeId
        : this.state.editNodeId
      var editorCompanion = state.editorCompanion || this.state.editorCompanion
      var editTab = state.hasOwnProperty('editTab')
        ? state.editTab
        : this.state.editTab

      nodes = nodes || []
      // tabs = tabs || [];

      if (!_.isEmpty(state)) {
        state.deActivateSearch = state.hasOwnProperty('deActivateSearch')
          ? state.deActivateSearch
          : false
        state.activateSearch = state.hasOwnProperty('activateSearch')
          ? state.activateSearch
          : false
        state.updatedTextNode = state.hasOwnProperty('updatedTextNode')
          ? state.updatedTextNode
          : null
        state.headingOnly = state.hasOwnProperty('headingOnly')
          ? state.headingOnly
          : false
      }
      if (
        (state.hasOwnProperty('updateAllNodes') && state.updateAllNodes) ||
        (state.hasOwnProperty('updatedNodes') && state.updatedNodes.length) ||
        (state.hasOwnProperty('updatedNodeIds') && state.updatedNodeIds.length)
      ) {
        // We need to reset these ASAP to avoid strange happenings
        setTimeout(function () {
          _this.setState({
            updateAllNodes: false,
            updatedNodes: [],
            updatedNodeIds: []
          })
        }, 10)
      }
      state.updateAllNodes = state.hasOwnProperty('updateAllNodes')
        ? state.updateAllNodes
        : false
      state.activeNodeOnly = state.hasOwnProperty('activeNodeOnly')
        ? state.activeNodeOnly
        : false

      var node, emptyNode, tab, startEditing, updateEditor, user, org

      if (userId) {
        user = this.user(userId)
      }
      org = this.org()
      tab = this.tab()

      var orgNodes = nodes
      if (org) {
        // XXX: (perf) It sucks that we have to do this on EVERY setState when an org is active...
        orgNodes = this.orgNodes(org, nodes)
      }

      const listNodes = orgNodes.filter(n => {
        return !n.inbox
      })
      if (!listNodes.length) {
        var commonParams = this.getCommonParams()
        var createEmptyNode = false
        if (org) {
          if (org.default_access_level >= constants.WRITE_ACCESS) {
            createEmptyNode = true
          } else {
            var isOwner = Org.isOwner(org, user, commonParams)
            if (isOwner) {
              createEmptyNode = true
            }
          }
        } else if (user) {
          createEmptyNode = true
        } else if (anonShareId) {
          var anonShare = this.state.entities.shares.find(s => {
            return s.id === anonShareId
          })
          if (anonShare && anonShare.access_level >= constants.WRITE_ACCESS) {
            createEmptyNode = true
          }
        }
        if (createEmptyNode) {
          console.log('NO NODES, creating empty...') // eslint-disable-line no-console
          console.log('(state was:)', state) // eslint-disable-line no-console
          console.log('(this.state was:)', this.state) // eslint-disable-line no-console
          window.localStorage.setItem(
            'emptyNodesKeys',
            JSON.stringify(_.keys(state))
          )
          emptyNode = Node.new({}, commonParams)

          // emptyNode.title = '(AUTO-added in setAppState)';
          emptyNode.title = ''
          if (org) {
            emptyNode.org_id = org.id
            emptyNode.actual_user_id = emptyNode.user_id
            emptyNode.user_id = null
          }
          nodes = nodes.concat([emptyNode])
          state.entities = state.entities || this.state.entities
          state.entities.nodes = nodes
          this.state.undo.add(
            {
              undo: [{ obj: 'Node', cmd: 'remove', args: [emptyNode.id] }],
              redo: [
                { obj: 'Node', cmd: 'add', args: [emptyNode] },
                {
                  obj: 'Node',
                  cmd: 'focus',
                  args: [emptyNode.id],
                  persist: false
                }
              ]
            },
            true
          )
        }
      }

      if (tab) {
        window._app.tab_states[tab.id] = window._app.tab_states[tab.id] || {}
      }
      if (editTab) {
        window._app.tab_states[editTab.id] =
          window._app.tab_states[editTab.id] || {}
      }

      // if (((state.entities && state.entities.users) && user && prevUser && user.tab_id !== prevUser.tab_id) || (orgId !== this.state.orgId)) {
      //   tabId = user.tab_id;
      //   var orgTabId = Misc.orgTabId(org, user);
      //   if (orgTabId) {
      //     tabId = orgTabId;
      //   }
      //   tab = user ? _.xfind(tabs, function(t) { return t.id === tabId; }) : tabs[0];

      //   // Just make sure SOME tab is selected if the chosen one wasn't found
      //   if (!tab) {
      //     tab = org ? _.xfind(tabs, function(t) { return t.org_id === org.id; }) : _.xfind(tabs, function(t) { return !t.org_id; });
      //     tabId = tab.id;
      //   }

      //   state.tabId = tab.id;

      //   if (tab.share_id) {
      //     share = _.xfind(consumedShares, function(s) { return s.id === tab.share_id; });
      //   } else {
      //     share = null;
      //   }

      //   state.share = share;
      //   // var currentTabId = user ? this.state.user.tab_id : tab.id;
      //   var currentTabId = tabId;
      //   window._app.tab_states[currentTabId] = window._app.tab_states[currentTabId] || {};
      //   if (editNodeId && editorCompanion !== 'lists') {
      //     // console.log('settings tab state for ' + currentTabId + ' to:', window._app.editor.getValue());
      //     window._app.tab_states[currentTabId].editorCursorPosition = window._app.editor.getCursorPosition();
      //     window._app.tab_states[currentTabId].editorValue = window._app.editor.getValue();
      //     // console.log('NOW:', window._app.tab_states['88c92b5f-581c-4e82-9127-ddf04da2bc72']);
      //   }

      //   window._app.tab_states[currentTabId].scrollPos = $(document).scrollTop();
      // }

      // Actively change the root node
      if (rootWasSet) {
        state.floater = null
        // if (user) {
        //   if (!tab) {
        //     tabId = org ? Misc.orgTabId(org, user): user.tab_id;
        //     tab = Tab.get(tabId, tabs);
        //   }
        // } else {
        //   tab = tabs[0];
        // }

        if (
          editorCompanion !== 'lists' &&
          (editNodeId ||
            (state.hasOwnProperty('tabId') && state.tabId !== this.state.tabId))
        ) {
          if (
            state.tabId &&
            window._app.tab_states[state.tabId] &&
            window._app.tab_states[state.tabId].editNode
          ) {
            // console.log('huh?', window._app.tab_states[tab.id].editNode);
            state.editNodeId = window._app.tab_states[tab.id].editNode
            // startEditing = true;
            updateEditor = true
            params.skipRemoteParse = true
          } else {
            if (editorCompanion !== 'lists') {
              state.editNodeId = null
            }
          }
        }

        // Save focus for this rootNode when navigating to a different root
        var selectionRoot = this.rootNodeId() || '-root-'
        if (window._app.activeNode) {
          var li = $(Misc.nodeEl(window._app.activeNode))
          if (li.length) {
            var div = li.find(
              '> .indented > .indent-wrapper > .node-content > div > div > div[contenteditable]'
            )
            if (div.length) {
              this.rootSelections[selectionRoot] = [
                window._app.activeNode,
                Text.saveSelection(div.get(0)),
                $(window).scrollTop()
              ]
            }
          }
        }

        node = Node.get(this.rootNodeId(), nodes)
        rootNode = node
        if (tab && !/^share-/.test(tab.id)) {
          var tabNodeId = node ? node.id : null
          if (tab.node_id !== tabNodeId) {
            // var redoCommands = [{obj: 'Tab', cmd: 'modify', args: [tab.id, {node_id: tabNodeId}]}];
            // this.addToStack(redoCommands);
            // console.log('!!! Should have updated tab node in setAppState (see commented code above this log line)');
            // tab.node_id = (node ? node.id : null);
          }
        }

        window._app.prevActive = window._app.activeNode
        window._app.activeNode = null
        // state.tabId = tab.id;
        // if (tab) {
        //   state.entities = state.entities || this.state.entities;
        //   state.entities.tabs = _.xreject(tabs, function(t) { return t.id === tab.id; }).concat([tab]);
        // }
        state.prevRootId = _this.rootNodeId()
        state.searchstring = null
        state.activateSearch = false
        state.deActivateSearch = true
        // if (node) {
        //   var resolvedNode = Node.getResolved(node, nodes);
        //   // Parse content of editor instead of saved body, since we're currently editing this very node
        //   if (resolvedNode && editNodeId && resolvedNode.id === editNodeId && editorCompanion === 'lists') {
        //     params.body = window._app.editor.getValue();
        //     parsedBody = Misc.parseMarkdown(params.body);
        //     state.parsedBody = parsedBody;
        //     // state.previewBody = parsedBody;
        //   } else {
        //     if (resolvedNode.body) {
        //       params.body = resolvedNode.body;
        //       parsedBody = Misc.parseMarkdown(resolvedNode.body);
        //       state.parsedBody = parsedBody;
        //       state.previewBody = parsedBody;
        //     } else {
        //       state.parsedBody = '';
        //       state.previewBody = '';
        //     }
        //   }
        // } else {
        //   state.parsedBody = '';
        //   state.previewBody = '';
        // }
        // No change, keep existing root node (if any)
      } else if (this.rootNodeId()) {
        node = Node.get(this.rootNodeId(), nodes)
        rootNode = node
      }

      // if (!rootNode && (share && share.node && share.node.body)) {
      //   parsedBody = Misc.parseMarkdown(share.node.body);
      //   state.parsedBody = parsedBody;
      //   state.previewBody = parsedBody;
      // }

      if (state.hasOwnProperty('parsedBody')) {
        if (!editNodeId) {
          setTimeout(function () {
            $('#toc').toc({
              updateLocation: false,
              container: $('#note-body'),
              scrollOffset: -40,
              scrollTimeout: 10,
              minHeadings: 3,
              activeElement: $('#toc-container')
            })
          }, 0)
        }
        // if (state.parsedBody && state.parsedBody !== '' && !params.skipRemoteParse) {
        //   var parseNode = rootNode || share.node;
        //   Misc.jx(_this, 'parse', {text: params.body, node_id: parseNode.id}, function(res) {
        //     if (res.body.html) {
        //       _this.setAppState({parsedBody: res.body.html}, {skipRemoteParse: true, keepUrl: true});
        //       setTimeout(function() {
        //         Misc.fixGists();
        //       }, 0);
        //     }
        //   }.bind(_this));
        // }
      }

      if (rootNode && !rootWasSet) {
        state.rootIsBoard = rootNode.mode === 'board'
      }

      if (_.keys(state).length) {
        if (tab) {
          window._app.tab_states[tab.id] = window._app.tab_states[tab.id] || {}
        }

        const isFilterEmpty = Misc.isFilterEmpty(filter)
        if (isFilterEmpty !== this.state.isFilterEmpty) {
          state.isFilterEmpty = isFilterEmpty
          state.updatedNodes = (state.updatedNodes || []).concat(
            this.state.filteredNodes
          )
          // console.log(state.updatedNodes)
        }
        if (
          (state.rootIsBoard || isFilterEmpty) &&
          (!state.hasOwnProperty('searchstring') ||
            !state.searchstring ||
            state.searchstring === '' ||
            /^\s*>\s*$/.test(state.searchstring))
        ) {
          state.displayNodes = nodes
          state.filteredNodes = []
        } else if (state.searchstring) {
          let searchResult = _this.search(searchstring, nodes, rootNode)
          state.filteredNodes = searchResult[0]
          state.displayNodes = searchResult[1]
        } else if (!isFilterEmpty) {
          let filterResult = _this.filter(filter, nodes, rootNode)
          state.filteredNodes = filterResult[0]
          state.displayNodes = filterResult[1]
          state.updatedNodes = (state.updatedNodes || []).concat(
            state.filteredNodes
          )
        }

        state.updatedNodeIds = state.updatedNodes
          ? state.updatedNodes.filter(Boolean).map(n => {
            return typeof n === 'string' ? n : n.id
          })
          : []

        if (this.state._debug) {
          console.log('SETTING app state:', state) // eslint-disable-line no-console
        }

        if (!params.firstLoad) {
          state.urlRootNodeId = null
        }

        this.setState(state, function () {
          if (!params.keepUrl) {
            var currPath = window.location.pathname + window.location.search
            node = Node.get(_this.rootNodeId(), _this.state.entities.nodes)
            var newPath = _this.nodeLink(node, userId, shareId)
            if (currPath !== newPath) {
              history.pushState({ node: node, tab: tab }, '', newPath)
            }
          }

          if (startEditing) {
            // console.log('EDITORVALUE:', window._app.tab_states[tab.id].editorValue);
            if (tab) {
              _this.toggleEditing({
                force: true,
                skipSetState: true,
                customValue: window._app.tab_states[tab.id].editorValue,
                customScrollPos: window._app.tab_states[tab.id].scrollPos
              })
            } else {
              _this.toggleEditing({
                force: true,
                skipSetState: true,
                customValue: _this.editNode().body
              })
            }
          } else {
            if (tab && updateEditor) {
              // console.log('saved value for tab:', window._app.tab_states[tab.id].editorValue);
              _this.updateEditor({
                body: window._app.tab_states[tab.id].editorValue,
                position: window._app.tab_states[tab.id].scrollPos
              })
            }
            if (tab && window._app.tab_states[tab.id].scrollPos) {
              // console.log('HAVE SCROLL POS:', window._app.tab_states[tab.id].scrollPos);
              window.scrollTo(0, window._app.tab_states[tab.id].scrollPos)
            } else {
              if (rootWasSet) {
                window.scrollTo(0, 0)
              }
            }

            if (params.firstLoad && state.loaded) {
              _this.startTour('main')
            }
            // Use a 0 timeout to make sure the scrolling is done
            setTimeout(function () {
              Misc.fixGists()
              if (rootWasSet || tabWasSet || orgWasSet || params.firstLoad) {
                var focusNodeId = _this.rootNodeId() || '-root-'
                var savedSelection = _this.rootSelections[focusNodeId]

                var li = savedSelection ? $(Misc.nodeEl(savedSelection[0])) : []
                if (!li.length) {
                  li = $('#nodes li.node:first')
                }
                var selection = savedSelection
                  ? savedSelection[1]
                  : { start: 0, end: 0, htmlPos: 0 }
                // console.log('li', li);
                // console.log('selection', selection);
                // console.log('rootNode.body', rootNode.body);
                // console.log('li', li);
                // console.log('li.visible', li.visible({partial: true}));
                // if (li.length && (!rootNode || (!selection || (rootNode.body && li.visible({partial: true}))))) {
                // if (li.length && li.visible({partial: true})) {
                let liFocusWasSet = false
                if (li.length) {
                  const liTopOffset = li.offset().top
                  if (
                    liTopOffset + li.outerHeight() <
                    document.documentElement.clientHeight
                  ) {
                    liFocusWasSet = true
                    Node.focus(
                      li.data('node-id'),
                      selection,
                      _this.getCommonParams()
                    )
                  }
                }
                if (!liFocusWasSet) {
                  $('#body-container .node-heading > span').focus()
                  // console.log('LI NOT VISIBLE, NOT FOCUSING');
                }
                if (savedSelection && savedSelection.length === 3) {
                  window.scrollTo(0, savedSelection[2])
                } else {
                  window.scrollTo(0, 0)
                }
              }
            }, 0)
          }

          if (typeof callback === 'function') {
            callback()
          }
        })
      } else {
        if (typeof callback === 'function') {
          callback()
        }
      }
    }
  },

  headingForUserOrOrg: function () {
    var heading
    var user = this.user()
    if (user) {
      const share = this.share()
      if (share) {
        const shareNode = Node.get(share.node_id, this.state.entities.nodes)
        if (shareNode) {
          heading = shareNode.title
        }
      } else {
        var org = this.org()
        heading = org ? this.orgUser().heading || org.name : user.heading
      }
    } else {
      const anonShare = this.anonShare()
      if (anonShare) {
        heading = anonShare.node.title
      }
    }

    return heading
  },

  setLastUserAction: function () {
    this.lastUserAction = new Date()
  },

  setNodeSelection: function (selection) {
    if (selection) {
      if (
        selection.nodeIds &&
        selection.nodeIds.length &&
        typeof selection.nodeIds[0] === 'object'
      ) {
        selection.nodeIds = selection.nodeIds.map(n => {
          return n.id
        })
      }
    }
    this.setState({
      nodeSelection: selection,
      sidebarStack: this.pushSidebar(['nodes'], true)
    })
  },

  // setPanel: function(name, type, data, title, activeSection, ev) {
  setPanel: function (name, opts, callback, ev) {
    if (name) {
      console.log('Setting PANEL to ' + name) // eslint-disable-line no-console
      const dataObj = {
        name: name, // Panel name
        opts: opts // data object
      }
      this.setState({ floater: null, panel: dataObj }, () => {
        if (typeof callback === 'function') {
          callback()
        }
      })

      // if (type !== 'page') {
      //   $('body').addClass('modal-open');
      // }
    } else {
      this.setState({ panel: null }, () => {
        $('body').removeClass('modal-open')
        if (typeof callback === 'function') {
          callback()
        }
      })
      // $('body').removeClass('modal-open');
    }

    if (typeof ev === 'object' && ev.preventDefault) {
      ev.preventDefault()
    }
  },

  usersForNode: function (node) {
    // eslint-disable-line no-unused-vars
    var orgId = this.orgId()
    if (orgId) {
      var org = this.org()
      const users = Org.users(org, this.getCommonParams())
      const userIds = users.map(u => {
        return u.id
      })
      return this.state.entities.users.filter(u => {
        return userIds.indexOf(u.id) !== -1
      })
    } else {
      return this.state.entities.users
      // TODO: Return only non-org users
      // return _.xfilter(this.state.entities.users);
    }
  },

  embed: function (share, callback) {
    var topNodes = this.state.entities.nodes.filter(n => {
      return (
        (n.parent_id === null || typeof n.parent_id === 'undefined') && !n.inbox
      )
    })
    var next = topNodes.find(top => {
      return !top.prev_id
    })
    var last
    var finder = (next, top) => {
      return top.prev_id === next.id
    }
    while (next) {
      last = { ...next }
      last.settings = { ...next.settings }
      next = topNodes.find(finder.bind(this, next))
    }

    var newNode = Node.new(
      {
        title: share.title,
        parent_id: null,
        prev_id: last.id,
        remote_id: share.node_id,
        share_id: share.id,
        user_id: this.state.userId
      },
      this.getCommonParams()
    )
    // this.state.undo.add({
    //   undo: [{obj: 'Node', cmd: 'remove',  args: [newNode.id]}],
    //   redo: [{obj: 'Node', cmd: 'add',  args: [newNode]}],
    // }, true);
    Node.add(newNode, this.getCommonParams(), () => {
      if (typeof callback === 'function') {
        callback(newNode)
      }
    })
  },

  restoreFocus: function () {
    var li = $('#nodes li.node:first')
    if (li.length) {
      li.find('[contenteditable="true"]').focus()
      this.setActiveNodeEl(li)
    }
  },

  setCommentNode: function (nodeOrId, opts, callback) {
    if (typeof opts === 'function') {
      callback = opts
      opts = {}
    }
    opts = opts || {}

    var oldValue
    var oldNode
    var node
    var id
    var newState
    var updatedNodes = []
    const rootNode = this.rootNode()
    if (rootNode) {
      updatedNodes.push(rootNode)
    }
    oldValue = this.state.commentNodeId
    if (oldValue) {
      updatedNodes.push(oldValue)
    }

    const doIt = () => {
      node = Node.get(nodeOrId, this.state.entities.nodes)
      updatedNodes.push(node)
      if (oldValue) {
        oldNode = Node.get(oldValue, this.state.entities.nodes)
        updatedNodes.push(oldNode)
      }
      this.ajax('get_comments', { body: { node_id: id } }, () => {
        newState = {
          // commentNodeId: id,
          highlightComment: opts.highlight,
          updatedNodes: updatedNodes,
          sidebarStack: this.pushSidebar(['comments', { nodeId: id }], true)
        }
        this.setAppState(newState, () => {
          if (typeof callback === 'function') {
            callback()
          }
        })
      })
    }

    const nullState = {
      commentNodeId: null,
      highlightComment: null,
      updatedNodes: updatedNodes
    }
    if (nodeOrId || opts.force) {
      id = typeof nodeOrId === 'object' ? nodeOrId.id : nodeOrId
      if (id === oldValue && !opts.force) {
        this.setAppState(nullState)
      } else {
        if (opts.orgId && opts.orgId !== this.state.orgId) {
          this.setOrg(opts.orgId, () => {
            doIt()
          })
        } else {
          doIt()
        }
      }
    } else {
      this.setAppState(nullState)
    }
  },

  setLikesNode: function (nodeOrId, opts, callback) {
    if (typeof opts === 'function') {
      callback = opts
      opts = {}
    }
    opts = opts || {}

    var oldValue
    var oldNode
    var node
    var id
    var newState
    var updatedNodes = []
    const rootNode = this.rootNode()
    if (rootNode) {
      updatedNodes.push(rootNode)
    }
    oldValue = this.state.likesNodeId
    if (oldValue) {
      updatedNodes.push(oldValue)
    }

    const doIt = () => {
      node = Node.get(nodeOrId, this.state.entities.nodes)
      updatedNodes.push(node)
      if (oldValue) {
        oldNode = Node.get(oldValue, this.state.entities.nodes)
        updatedNodes.push(oldNode)
      }
      this.ajax('get_likes', { body: { node_id: id } }, () => {
        newState = {
          likesNodeId: id,
          updatedNodes: updatedNodes,
          sidebarStack: this.pushSidebar(['likes', { nodeId: id }], true)
        }
        this.setAppState(newState, () => {
          if (typeof callback === 'function') {
            callback()
          }
        })
      })
    }

    const nullState = {
      likesNodeId: null,
      updatedNodes: updatedNodes
    }
    if (nodeOrId || opts.force) {
      id = typeof nodeOrId === 'object' ? nodeOrId.id : nodeOrId
      if (id === oldValue && !opts.force) {
        this.setAppState(nullState)
      } else {
        if (opts.orgId && opts.orgId !== this.state.orgId) {
          this.setOrg(opts.orgId, () => {
            doIt()
          })
        } else {
          doIt()
        }
      }
    } else {
      this.setAppState(nullState)
    }
  },

  setSummaryNode: function (nodeOrId, opts, callback) {
    if (typeof opts === 'function') {
      callback = opts
      opts = {}
    }
    opts = opts || {}

    var oldValue
    var oldNode
    var node
    var id
    var newState
    var updatedNodes = []
    const rootNode = this.rootNode()
    if (rootNode) {
      updatedNodes.push(rootNode)
    }
    oldValue = this.state.summaryNodeId
    if (oldValue) {
      updatedNodes.push(oldValue)
    }

    const doIt = () => {
      node = Node.get(nodeOrId, this.state.entities.nodes)
      updatedNodes.push(node)
      if (oldValue) {
        oldNode = Node.get(oldValue, this.state.entities.nodes)
        updatedNodes.push(oldNode)
      }
      const data = { nodeId: id }
      if (opts.mode) {
        data.mode = opts.mode
      }
      newState = {
        summaryNodeId: id,
        updatedNodes: updatedNodes,
        sidebarStack: this.pushSidebar(['summary', data], true)
      }
      if (opts.hasOwnProperty('activeNodeId')) {
        newState.activeNodeId = opts.activeNodeId
      }
      this.setAppState(newState, () => {
        if (typeof callback === 'function') {
          callback()
        }
      })
      // });
    }

    const nullState = { summaryNodeId: null, updatedNodes: updatedNodes }
    if (nodeOrId || opts.force) {
      id = typeof nodeOrId === 'object' ? nodeOrId.id : nodeOrId
      if (id === oldValue && !opts.force) {
        this.setAppState(nullState)
      } else {
        if (opts.orgId && opts.orgId !== this.state.orgId) {
          this.setOrg(opts.orgId, () => {
            doIt()
          })
        } else {
          doIt()
        }
      }
    } else {
      this.setAppState(nullState)
    }
  },

  setOrg: function (orgOrId, callback) {
    var org = Org.get(orgOrId, this.state.entities.orgs)
    var orgId = org ? org.id : null
    var user = this.user()

    var doWithData = () => {
      // var nodes = orgId ? _this.state.orgsData[orgId].nodes : _this.state.userNodes;
      // var nodes = _this.state.entities.nodes;
      var changes = { org_id: orgId }

      const commonParams = this.getCommonParams()
      commonParams.stateParams = { orgWasSet: true }
      commonParams.updatedState = Object.assign({}, commonParams.updatedState)
      commonParams.updatedState.updateAllNodes = true
      commonParams._skipUndo = true

      User.modify(user.id, changes, commonParams, () => {
        this.resetTransferDataTimer(0)
        this.restoreFocus()
        if (typeof callback === 'function') {
          callback()
        }
      })
    }

    if (
      (orgId &&
        (!this.state.orgsData[orgId] ||
          !this.state.orgsData[orgId].haveNodes)) ||
      (!orgId && !this.state.haveUserNodes)
    ) {
      this.setState({
        loading: true
      })
      this.ajax('get_org_or_user_data', { body: { org_id: orgId } }, res => {
        var newState = {
          loading: false,
          usePersonalFileBrowser: false
        }
        if (orgId) {
          var newOrgsData = { ...this.state.orgsData }
          newOrgsData[orgId] = newOrgsData[orgId] || {}
          newOrgsData[orgId].haveNodes = true
          newState.orgsData = newOrgsData
        } else {
          newState.haveUserNodes = true
        }
        newState.entities = Object.assign({}, this.state.entities)
        for (let key in res.entities) {
          if (key !== 'nodes') {
            newState.entities[key] = res.entities[key]
          }
        }
        var decoratedNodes = this.decorateNodesWithAccessLevel(
          this.state.entities,
          res.entities.nodes,
          org,
          user
        )
        newState.entities.nodes = Misc.addOnce(
          this.state.entities.nodes,
          decoratedNodes
        )
        this.setAppState(newState, () => {
          doWithData()
        })
      })
    } else {
      if (!orgId || orgId !== this.orgId()) {
        doWithData()
      }
    }
  },

  setRoot: function (nodeOrId, tabOrId, callback, ev, isBack) {
    clearTimeout(this.openContextTimer)
    if (ev && ev.preventDefault) {
      ev.preventDefault()
    }
    if (callback && callback.preventDefault) {
      callback.preventDefault()
      callback = null
    }
    if (tabOrId && tabOrId.preventDefault) {
      tabOrId.preventDefault()
      tabOrId = null
    }
    if (typeof tabOrId === 'function') {
      callback = tabOrId
      tabOrId = null
    }

    let node
    let nodeId = nodeOrId
      ? typeof nodeOrId === 'string'
        ? nodeOrId
        : nodeOrId.id
      : null
    if (nodeId) {
      node = Node.get(nodeId, this.state.entities.nodes)
      if (node) {
        nodeId = node.id
      } else {
        // TODO: Show an alert to the user (that the node was not found) instead of just going to the tab root
        nodeId = null
      }
    }
    const initialCurrentTabId = this.tabId()
    const initialTab = Tab.get(
      tabOrId || initialCurrentTabId,
      this.state.entities.tabs
    )
    const initialTabId = initialTab ? initialTab.id : null

    const internalCb = () => {
      // Get tab again here, since we may have created a new or switched tab in the meantime
      const tab = this.tab()
      const tabId = tab ? tab.id : null
      if (
        (tab && tab.virtual) ||
        !node ||
        (!node.org_id && node.user_id === tab.user_id) ||
        (node.org_id && node.org_id === tab.org_id)
      ) {
        var newHistory = [[nodeId, tabId]]
          .concat(this.state.history)
          .slice(0, 100)
        const newState = {
          filter: null,
          history: newHistory,
          updateAllNodes: true,
          hasMoved: false,
          addingData: null,
          rootIsBoard: !!(node && node.mode === 'board')
        }
        const commonParams = this.getCommonParams()
        commonParams.updatedState = newState
        commonParams.stateParams = { rootWasSet: true, keepUrl: isBack }
        Tab.modify(tabId, { node_id: nodeId }, commonParams, callback)
      } else {
        console.warn(
          "OH NO! Tried to set the current tab's node_id to a node that belongs to another org"
        ) // eslint-disable-line no-console
        console.log('node', node) // eslint-disable-line no-console
        console.log('tab', tab) // eslint-disable-line no-console
      }
    }

    let createTab = false

    if (initialTab && initialTab.root_id) {
      if (node) {
        const path = Node.getPath(node, this.state.entities.nodes)
        const pathIds = path.map(p => {
          return p.id
        })
        if (
          pathIds.indexOf(initialTab.root_id) === -1 &&
          node.id !== initialTab.root_id
        ) {
          createTab = true
        }
      } else {
        createTab = true
      }
    }

    if (createTab) {
      this.addTab({ node_id: nodeId }, function () {
        internalCb()
      })
    } else {
      if (!initialTabId || initialTabId === initialCurrentTabId) {
        internalCb()
      } else {
        this.setTab(
          initialTabId,
          function () {
            internalCb()
          },
          false,
          true
        )
      }
    }
  },

  setUser: function (newUser, newToken, callback) {
    this.setState({ user: newUser, authToken: newToken }, function () {
      if (this.state.authToken) {
        this.loadInitialData(false, callback)
      }
    })
  },

  hasCompletedTour: function (name, tours) {
    tours = tours || this.state.completedTours
    var completed = tours
      .filter(tour => tour.name === name && tour.platform === 'web')
      .map(t => t.name)
    return completed.indexOf(name) !== -1
  },

  setupIntercom: function (user) {
    var _this = this
    if (!this.state._dev && window.Intercom) {
      user = user || this.user()
      window.Intercom('boot', {
        widget: {
          activator: '#support-chat-launcher'
        },
        app_id: 'pmxixnvq',
        // The current logged in user's ID
        user_id: user.id,
        // The current logged in user's hash
        user_hash: user.intercom_hash,
        // The current logged in user's full name
        name: user.first_name + ' ' + user.last_name,
        // The current logged in user's email address.
        email: user.email,
        // The current logged in user's sign-up date as a Unix timestamp.
        created_at: Math.round(new Date(user.created_at).getTime() / 1000)
      })
      setTimeout(function () {
        window.Intercom('reattach_activator')
      }, 1000)
      setInterval(function () {
        var now = new Date()
        if (
          user &&
          _this.lastUserAction &&
          now - _this.lastUserAction < 10000
        ) {
          window.Intercom('update')
        }
      }, 10 * 1000)
    }
  },

  requestNotificationPermission: function (grantedCb, deniedCb) {
    if ('Notification' in window) {
      // XXX: Is there any way to DRY this up a bit?
      if (Notification.permission === 'granted') {
        if (typeof grantedCb === 'function') {
          grantedCb()
        }
      } else if (Notification.permission === 'denied') {
        if (typeof deniedCb === 'function') {
          deniedCb()
        }
      } else {
        Notification.requestPermission(function (permission) {
          if (permission === 'granted') {
            if (typeof grantedCb === 'function') {
              grantedCb()
            }
          }
          if (permission === 'denied') {
            if (typeof deniedCb === 'function') {
              deniedCb()
            }
          }
        })
      }
    }
  },

  executePaletteCommand: function (name) {
    CommandPalette.palette(this)[name][1]()
  },

  browserNotify: function (title, body) {
    this.requestNotificationPermission(function () {
      var options = {
        body: body
        // icon: theIcon
      }
      // var n = new Notification(title, options);
      return new Notification(title, options)
    })
  },

  search: function (str, nodes, rootNode) {
    var _this = this
    nodes = nodes || this.state.entities.nodes
    rootNode = rootNode || this.rootNode()
    var tabRoot
    var tab = this.tab()
    if (tab && tab.pinned && tab.root_id) {
      tabRoot = Node.get(tab.root_id, this.state.entities.nodes)
    }

    var childrenOnly = Boolean(
      (rootNode && /^\s*>/.test(str)) || (!/^\s*</.test(str) && tabRoot)
    )

    // this.setState({searchstring: str});
    var actualRoot
    if (rootNode && /^\s*>/.test(str)) {
      actualRoot = rootNode
    } else if (!/^\s*</.test(str) && tabRoot) {
      actualRoot = tabRoot
    }

    var displayNodes = []
    var searchResults = Node.search(str, nodes, rootNode, tab)

    if (searchResults.length) {
      displayNodes = [...searchResults]
      searchResults.forEach(node => {
        var parentId = node.parent_id || node.remote_id
        while (parentId) {
          var parent = Node.get(parentId, _this.state.entities.nodes)

          if (
            parent &&
            (!childrenOnly || !actualRoot || parent.id !== actualRoot.id)
          ) {
            if (displayNodes.indexOf(parent) === -1) {
              displayNodes.push(parent)
            }

            parentId = parent.parent_id
          } else {
            break
          }
        }
      })
    }
    window.Intercom && window.Intercom('trackEvent', 'used-search') // jshint ignore:line

    return [searchResults, displayNodes]
  },

  filter: function (filter, nodes, rootNode) {
    nodes = nodes || this.state.entities.nodes
    rootNode = rootNode || this.rootNode()
    var childrenOnly = true

    var displayNodes = []
    var filterResults = Node.filter(this, filter, nodes, rootNode)

    if (filterResults.length) {
      displayNodes = Node.displayNodes(
        filterResults,
        this.state.entities.nodes,
        childrenOnly,
        rootNode
      )
    }
    window.Intercom && window.Intercom('trackEvent', 'used-filter') // jshint ignore:line

    return [filterResults, displayNodes]
  },

  setActiveNodeEl: function (el) {
    $('li.node').removeClass('active')
    el.addClass('active')
    window._app.activeNode = el.data('node-id')
  },

  wrapCommand: function (command, callback) {
    this.stashFocus()
    command(function () {
      this.applyStashedFocus()
      callback()
    })
  },

  stashFocus: function () {
    var li
    if (window._app.activeNode) {
      window._app.focusNodeId = window._app.activeNode
      // var focusNode = $('li.node .title:focus');
      li = $(Misc.nodeEl(window._app.activeNode))
      if (li.length) {
        var node = Node.get(window._app.activeNode, this.state.entities.nodes)
        if (node.type !== 'bookmark') {
          var selection = Text.getSelection(li.get(0))
          // window._app.focusNodeId = li.data('node-id');
          window._app.focusSelection = selection
        }
      }
    }
  },

  applyStashedFocus: function () {
    // focusId = focusId || window._app.focusNodeId;
    if (window._app.focusNodeId) {
      var li = $(Misc.nodeEl(window._app.focusNodeId))
      if (li.length) {
        this.setActiveNodeEl(li)
        var node = Node.get(window._app.focusNodeId, this.state.entities.nodes)
        if (!node || (node && node.type !== 'bookmark')) {
          if (
            window._app.focusSelection &&
            window._app.focusSelection.hasOwnProperty('start') &&
            window._app.focusSelection.hasOwnProperty('end')
          ) {
            var div = li.find('.title:first')
            if (div.length) {
              Text.setSelection(
                div.get(0),
                window._app.focusSelection.start,
                window._app.focusSelection.end
              )
            }
          }
        }
      }
    }
    window._app.focusNodeId = null
    window._app.focusSelection = null
  },

  pubUrl: function (node) {
    node = node || this.rootNode()
    if (node) {
      var user = this.user()
      var org = node.org_id ? this.org(node.org_id) : null
      var hasSlug = false
      var portStr = ''
      var prefix = 'https://'
      var domain = 'notebase.io'
      if (
        window.location.port &&
        window.location.port !== 80 &&
        window.location.port !== 443
      ) {
        // Looks like a dev machine, set url accordingly
        prefix = 'http://'
        portStr = ':' + window.location.port
        domain = 'notebase.dev'
      }

      var usernameStr = this.state.share
        ? this.state.share.username
        : org
          ? org.identifier
          : user
            ? user.username
            : '--error--'
      var url =
        prefix + usernameStr + '.' + domain + portStr + '/' + node.short_id
      if (node.type === 'site') {
        if (node.settings.custom_domain) {
          url = 'http://' + node.settings.custom_domain
        } else {
          url = prefix + node.settings.sub_domain + '.' + domain
        }

        url += portStr + '/'
      } else {
        var site = Node.site(node, this.state.entities.nodes)
        if (site) {
          if (site.settings.custom_domain) {
            url = 'http://' + site.settings.custom_domain + portStr
          } else {
            url = prefix + site.settings.sub_domain + '.' + domain + portStr
          }
        }

        if (node.slugs) {
          var slug = Node.slug(node, site)
          if (slug) {
            url += '/' + slug.slug
            hasSlug = true
          }
        }

        if (!hasSlug) {
          url += '/' + node.short_id
        }
      }

      return url
    }
  },

  toggleHelp: function (ev) {
    var _this = this
    this.setState({ expandHelp: !this.state.expandHelp }, function () {
      var user = _this.user()
      if (user) {
        var newSettings = { ...user.settings }
        newSettings.expand_help = _this.state.expandHelp
        _this.saveUserSettings(newSettings, _this.getCommonParams())
      }
    })
    window.Intercom && window.Intercom('trackEvent', 'toggled-help')
    if (ev && ev.preventDefault) {
      ev.preventDefault()
    }
  },

  toggleMarkdownHelp: function (ev) {
    var _this = this
    this.setState(
      { expandMarkdownHelp: !this.state.expandMarkdownHelp },
      function () {
        var user = _this.user()
        if (user) {
          var newSettings = { ...user.settings }
          newSettings.expand_markdown_help = _this.state.expandMarkdownHelp
          _this.saveUserSettings(newSettings, _this.getCommonParams())
        }
      }
    )
    window.Intercom && window.Intercom('trackEvent', 'toggled-markdown-help')
    if (ev && ev.preventDefault) {
      ev.preventDefault()
    }
  },

  toggleDueList: function (ev) {
    var _this = this
    var newState = { expandDueList: !this.state.expandDueList }
    if (newState.expandDueList) {
      newState.expandKeyHelp = false
    }

    this.setState(newState, function () {
      var user = _this.user()
      if (user) {
        var newSettings = { ...user.settings }
        newSettings.expand_due_list = _this.state.expandDueList
        if (newState.hasOwnProperty('expandKeyHelp')) {
          newSettings.expand_key_help = false
        }

        _this.saveUserSettings(newSettings, _this.getCommonParams())
      }
    })
    if (ev && ev.preventDefault) {
      ev.preventDefault()
    }
  },

  toggleSidebar: function (ev) {
    var _this = this
    var newState = { sidebarStack: [] }
    if (!this.state.sidebarStack.length) {
      newState.sidebarOpen = !this.state.sidebarOpen
    }
    this.setState(newState, function () {
      var user = _this.user()
      if (user) {
        var newSettings = { ...user.settings }
        newSettings.show_sidebar = _this.state.sidebarOpen
        _this.saveUserSettings(newSettings, _this.getCommonParams())
      }
    })
    if (ev && ev.preventDefault) {
      ev.preventDefault()
    }
  },

  toggleChildren: function (nodeOrId, callback) {
    var node = Node.get(nodeOrId, this.state.entities.nodes)
    var tab = this.tab()
    var idx = tab.expanded_nodes ? tab.expanded_nodes.indexOf(node.id) : -1
    var command = idx === -1 ? 'expand' : 'collapse'
    Tab[command](tab.id, node.id, this.getCommonParams(), callback)
  },

  toggleBoardChildren: function (nodeOrId, callback) {
    var node = Node.get(nodeOrId, this.state.entities.nodes)
    var tab = this.tab()
    var idx = tab.collapsed_board_nodes
      ? tab.collapsed_board_nodes.indexOf(node.id)
      : -1
    var command = idx === -1 ? 'boardCollapse' : 'boardExpand'
    Tab[command](tab.id, node.id, this.getCommonParams(), callback)
  },

  toggleDescription: function (nodeOrId, forceAdd) {
    var node = Node.get(nodeOrId, this.state.entities.nodes)
    var li = $(Misc.nodeEl(node))
    var focusField = $(':focus')

    if (!forceAdd && focusField.hasClass('description')) {
      Node.flushTextUndo(this.getCommonParams(), () => {
        this.setAppState(
          { descriptionNode: null, updatedNodes: [node] },
          () => {
            setTimeout(function () {
              li = $(Misc.nodeEl(node))
              window._app.original_title = node.title || ''
              li.find('.title[contenteditable]:first').focus()
            }, 0)
          }
        )
      })
    } else if (
      forceAdd ||
      node.type === 'bookmark' ||
      focusField.hasClass('title')
    ) {
      clearTimeout(window._app.titleUndoTimeout)
      Node.flushTextUndo(this.getCommonParams(), () => {
        this.setAppState(
          { descriptionNode: node.id, updatedNodes: [node] },
          () => {
            setTimeout(function () {
              li = $(Misc.nodeEl(node))
              window._app.original_description = node.description || ''
              li.find('.description[contenteditable]:first').focus()
            }, 0)
          }
        )
      })
    }
  },

  getCurrentNode: function (el) {
    var nodeId
    el = el || this.getCurrentNodeEl()
    if (el.length) {
      nodeId = el.data('node-id')
      return Node.get(nodeId, this.state.entities.nodes)
    } else {
      return this.rootNode()
    }
  },

  getActiveElement: function () {
    var focusNode = document.activeElement
    if (focusNode && focusNode.nodeName !== 'BODY') {
      return focusNode
    }
  },

  getCurrentNodeEl: function (el) {
    var focusNode = el || this.getActiveElement()
    if (focusNode) {
      el = focusNode.closest('li.node')
    }
    if (!el && window._app.activeNode) {
      el = $(Misc.nodeEl(window._app.activeNode))
    }
    return el
  },

  handleBackButton: function (ev) {
    if (this.state.editNodeId) {
      this.toggleEditing()
      ev.preventDefault()
    } else {
      var wasSet = false
      if (ev.state && ev.state.hasOwnProperty('node') && ev.state.node) {
        var node = Node.get(ev.state.node.id, this.state.entities.nodes)
        if (node) {
          this.setRoot(node, ev.state.tab, ev, true)
          wasSet = true
        }
      }

      if (!wasSet) {
        this.setRoot(null, null, null, true)
      }
    }
  },

  beforeUnload: function () {
    this.socket = null
    this.setState({ unloading: true })
  },

  // shift (keycode 16) was tapped 2 times in a row
  // handleKeyPresses_16_2: function(ev) {
  handleKeyPresses_16_2: function (ev) {
    if (this.featureEnabled('rich_titles')) {
      var li = $(ev.target).closest('li.node')
      if (li.length) {
        var nodeId = li.data('node-id')
        if (nodeId) {
          Node.flushTextUndo({}, this.getCommonParams(), () => {
            this.setAppState({
              rawTitleNodeId:
                this.state.rawTitleNodeId === nodeId ? null : nodeId,
              updatedNodes: [Node.get(nodeId, this.state.entities.nodes)]
            })
          })
        }
      }

      ev.preventDefault()
    }
  },

  // shift (keycode 16) was tapped 3 times in a row
  handleKeyPresses_16_3: function (ev) {
    if (this.featureEnabled('due_nodes')) {
      var rootNode = this.rootNode()
      if (rootNode) {
        this.setAppState({ dueNode: rootNode.id, updatedNodes: [rootNode] })
      }

      ev.preventDefault()
    }
  },

  // cmd (keycode 91) was tapped 2 times in a row
  // handleKeyPresses_91_2: function(ev) {
  handleKeyPresses_91_2: function () {
    // var li = $(ev.target).closest('li.node');
    // if (li.length && this.state.hasMoved) {
    //   this.setRoot(li.data('node-id'));
    // } else {
    //   this.setRoot(this.state.prevRootId);
    // }
    // if (this.featureEnabled('assign_to')) {
    //   var user = this.user();
    //   if (user && user.features.orgs) {
    //     var _this = this;
    //     var node, nodeId;
    //     var li = $(ev.target).closest('li.node');
    //     if (li.length) {
    //       nodeId = li.data('node-id');
    //     } else {
    //       var assigner = $(ev.target).closest('.assign__input');
    //       if (assigner.length) {
    //         nodeId = this.state.assignNode;
    //       }
    //     }
    //     node = Node.get(nodeId, this.state.entities.nodes);
    //     if (node) {
    //       var params = this.getCommonParams();
    //       var newValue = (this.state.assignNode === node.id) ? null : node.id;
    //       var titleUndoData = {undoCommands: [], redoCommands: []};
    //       if (newValue) {
    //         clearTimeout(window._app.titleUndoTimeout);
    //         var div = li.find('> .indented > .indent-wrapper > .node-content > div > div > div.title[contenteditable]');
    //         Text.saveSelection(div.get(0));
    //         titleUndoData = Node.setTextUndo(node.id, 'title', div.text(), params, true);
    //       }
    //       this.state.undo.add({undo: titleUndoData.undoCommands,
    //                            redo: titleUndoData.redoCommands}, true);
    //       Misc.executeCommands(titleUndoData.redoCommands, params, function(newParams) { // eslint-disable-line no-unused-vars
    //         _this.setAppState({assignNode: newValue, dueNode: null, updatedNodes: [node]}, () => {
    //           if (newValue) {
    //             $('#assign-input').focus();
    //           } else {
    //             Node.focus(node.id, Text.savedSelection, params);
    //           }
    //         });
    //       });
    //     }
    //     ev.preventDefault();
    //   }
    // }
  },

  // ctrl (keycode 17) was tapped 2 times in a row
  // handleKeyPresses_17_2: function(ev) {
  //   var node, nodeId, li, opts = {};
  //   var focusNode = $('li.node .title:focus');
  //   if (focusNode) {
  //     li = focusNode.closest('li.node');
  //   }
  //   if (li.length) {
  //     nodeId = li.data('node-id');
  //     node = Node.get(nodeId, this.state.entities.nodes);

  //     if (node) {
  //       opts.src = node;
  //       opts.nodes = _.xfilter(this.state.entities.nodes, function(n) { return n.links_to === node.id; });
  //     }
  //   }

  //   if ((opts.nodes && opts.nodes.length) || this.state.switcherOpen) {
  //     this.toggleSwitcher(ev, 'backlinks', opts);
  //   }
  //   ev.preventDefault();
  // },

  startTour: function (name, skipCheck) {
    // TODO: Remove the check for user when we have made the tour work reliably for non-users?
    if (skipCheck || (this.state.userId && !this.hasCompletedTour(name))) {
      this.setState({ tourActive: true }, function () {
        window._app.tours[name].start()
      })
    }
  },

  endTour: function (name, opts) {
    var _this = this
    opts = opts || {}
    this.setState({ tourActive: false }, function () {
      var completion = { name: name, platform: 'web' }
      if (opts.hasOwnProperty('end_step')) {
        completion.end_step = opts.end_step
      }
      if (opts.hasOwnProperty('finished')) {
        completion.finished = opts.finished
      }
      Misc.jx(_this, 'complete_tour', completion, function (res) {
        // eslint-disable-line no-unused-vars
        if (!opts.skipHide) {
          window._app.tours[name].hide()
        }
      })
    })
    if (name === 'main') {
      setTimeout(function () {
        _this.setupIntercom()
      }, 5 * 1000)
    }
  },

  toggleContextMenu: function (nodeOrId) {
    var _this = this
    var nodeId = typeof nodeOrId === 'object' ? nodeOrId.id : nodeOrId
    if (this.state.floater && this.state.floater.type === 'context-menu') {
      _this.setState({ floater: null })
    } else {
      var li = $('.current-node[data-rootnode-id="' + nodeId + '"]')
      if (!li.length) {
        // In most cases this is what will happen - the .current-node stuff is for boards
        li = $(Misc.nodeEl(nodeId))
      }
      if (li.length) {
        var aside = li.find('aside:first, #context-icon-container')
        if (aside.length) {
          // console.log('aside', aside)
          // var bullet = aside.find('em, #context-icon')
          var icon = aside.find('.icon, em, #context-icon')
          // aside.addClass('open')
          _this.setState({
            floater: {
              type: 'context-menu',
              element: icon.get(0),
              placement: 'left-start',
              data: { nodeId }
            }
          })
        }
      }
    }
  },

  toggleFloater: function (evOrEl, type, data, cb) {
    if (typeof data === 'function') {
      cb = data
      data = null
    }
    const floater = this.state.floater || this.state.oldFloater
    const isSame =
      floater &&
      floater.type === type &&
      ((!floater.data && !data) ||
        (floater.data &&
          data &&
          (floater.data === data ||
            (typeof floater.data === 'object' &&
              typeof data === 'object' &&
              stableStringify(floater.data) === stableStringify(data)))))

    if (isSame) {
      this.setState({ floater: null }, cb)
    } else {
      const element = this.getFloaterElement(evOrEl)
      this.setState({ floater: { type, data, element } }, () => {
        // this.summonFloater(evOrEl)
        if (typeof cb === 'function') {
          cb()
        }
      })
    }
  },

  getFloaterElement: function (evOrEl) {
    let el
    if (evOrEl) {
      if (typeof evOrEl === 'string') {
        // Assume selector
        el = document.querySelector(evOrEl)
      } else if (evOrEl.hasOwnProperty('originalEvent')) {
        // JavaScript Event, use target
        el = evOrEl.target
      } else if (evOrEl instanceof Element) {
        // DOM element
        el = evOrEl
      } else if (evOrEl.jquery) {
        // jQuery element
        el = evOrEl.get(0)
      }
    }
    return el
  },

  summonFloater: function (evOrEl) {
    let el
    if (evOrEl) {
      if (typeof evOrEl === 'string') {
        // Assume selector
        el = document.querySelector(evOrEl)
      } else if (evOrEl.hasOwnProperty('originalEvent')) {
        // JavaScript Event, use target
        el = evOrEl.target
      } else if (evOrEl instanceof Element) {
        // DOM element
        el = evOrEl
      } else if (evOrEl.jquery) {
        // jQuery element
        el = evOrEl
      }
    }
    if (el) {
      // Misc.positionFloater(this.state.floater, el)
    } else {
      // eslint-disable-next-line no-console
      console.warn(
        'Tried to summon floater, but could not find an element to move to',
        evOrEl,
        this.state.floater
      )
    }
  },

  // orgGroups: function(user, org) {
  //   var groups = [], groupUser;
  //   org.groups.forEach(function(g) {
  //     groupUser = _.xfind(g.users, function(u) { return u.id === user.id; });
  //     if (groupUser) {
  //       groups.push(g);
  //     }
  //   });
  //   return groups;
  // },

  // accessLevelForOrgNode: function(node, org, user, groups, isOwner) {
  //   if (isOwner) {
  //     return constants.OWNER_ACCESS;
  //   }

  //   var nodePerms = org.node_permissions;
  //   var groupPerms = org.group_permissions;

  //   if (!(nodePerms.length || groupPerms.length)) {
  //     return org.default_access_level;
  //   }

  //   // If we reach this point, we need to do some (time-consuming) checking
  //   // ...
  //   // ...

  //   // Fall back to org default
  //   return org.default_access_level;
  // },

  isOwner: function (org, user) {
    return Org.isOwner(org, user, this.getCommonParams())
    // if (user && org) {
    //   var ownersGroup = _.xfind(org.groups, function(g) { return g.is_owners; });
    //   if (ownersGroup) {
    //     return _.xfind(ownersGroup.users, function(u) { return u.id === user.id; });
    //   }
    // }
    // return false;
  },

  accessLevelForOrgNodes: function (nodes, org, user, params) {
    var seenIds = []

    var isOwner = Org.isOwner(org, user, params)
    // var showOrgPermissions = Boolean(isOwner && user && user.settings.indicators && user.settings.indicators.indexOf('org_permissions') !== -1);
    var showOrgPermissions = Boolean(isOwner)

    var defaultAccess = org.default_access_level

    if (!isOwner || showOrgPermissions) {
      // Get necessary stuff first
      var nodePermissions = Org.nodePermissions(org, params)
      var nodePermissionIds = nodePermissions.map(p => p.node_id)
      var permissionNodes = nodes.filter(
        n => nodePermissionIds.indexOf(n.id) !== -1
      )

      var userPermissions = Org.userPermissions(org, params).filter(
        p => p.user_id === user.id
      )
      var userPermissionIds = userPermissions.map(p => p.node_id)
      var userPermissionNodes = nodes.filter(
        n => userPermissionIds.indexOf(n.id) !== -1
      )

      var groupPermissions = Org.groupPermissions(org, params)
      var groupPermissionIds = groupPermissions.map(p => p.node_id)
      var groupPermissionNodes = nodes.filter(
        n => groupPermissionIds.indexOf(n.id) !== -1
      )

      if (showOrgPermissions) {
        nodePermissions.forEach(function (p) {
          var node = Node.get(p.node_id, nodes)
          if (node) {
            node._ = node._ || {}
            node._.nodePerm = p.access_level
          }
        })
        groupPermissions.forEach(function (p) {
          var node = Node.get(p.node_id, nodes)
          if (node) {
            node._ = node._ || {}
            node._.groupPerms = node._groupPerms || []
            node._.groupPerms.push(p.access_level)
          }
        })
      }

      var subtreesToConsider = _.uniq(
        _.sortBy(
          permissionNodes
            .concat(groupPermissionNodes)
            .concat(userPermissionNodes),
          function (n) {
            return n.path.split('.').length
          }
        )
      )

      // Ready to do some filtering!
      var subtreeNodes,
        nodePerm,
        userPerm,
        groupPerms,
        groupPermAccesses,
        highestGroupPerm,
        actualPerm,
        pathRe
      subtreesToConsider.forEach(function (subtreeNode) {
        nodePerm = nodePermissions.find(p => p.node_id === subtreeNode.id)
        userPerm = userPermissions.find(p => p.node_id === subtreeNode.id)
        // Use user permission if it exists, otherwise look at node perm and group perms
        if (userPerm) {
          actualPerm = userPerm.access_level
        } else {
          groupPerms = groupPermissions.filter(
            p => p.node_id === subtreeNode.id
          )
          groupPermAccesses = groupPerms.map(p => p.access_level)
          highestGroupPerm = Math.max.apply(null, groupPermAccesses)
          actualPerm = groupPerms.length
            ? highestGroupPerm
            : nodePerm.access_level
        }

        pathRe = new RegExp(subtreeNode.id.replace(/-/g, '_'))
        subtreeNodes = nodes.filter(n => pathRe.test(n.path))

        subtreeNodes.forEach(function (sNode) {
          seenIds.push(sNode.id)
          sNode._ = sNode._ || {}
          sNode._.access = actualPerm
          if (showOrgPermissions && nodePerm) {
            sNode._.regularAccess = nodePerm.access_level
          }
        })
      })

      nodes.filter(n => seenIds.indexOf(n.id) === -1).forEach(function (n) {
        n._ = n._ || {}
        n._.access = defaultAccess
        if (showOrgPermissions) {
          n._.regularAccess = defaultAccess
        }
      })
    }

    if (isOwner) {
      nodes.forEach(function (n) {
        n._ = n._ || {}
        n._.access = constants.OWNER_ACCESS
        if (showOrgPermissions) {
          n._.defaultAccess = defaultAccess
        }
      })
    }

    return nodes
  },

  decorateNodesWithAccessLevel: function (entities, nodes, org, user) {
    console.log('incoming nodes:', nodes.length)
    var orgs = entities.orgs
    // var shares = shareIds.map(function(id) { return _.xfind(entities.shares, function(s) { return s.id === id; }); });
    var shares = entities.shares.filter(s => {
      return (
        (org && s.org_id === org.id) || (!org && user && s.user_id === user.id)
      )
    })
    var _this = this
    var t0 = new Date().getTime()
    var seenIds = []
    var decoratedNodes = []

    if (user) {
      // Loop through all shares, finding nodes
      shares.forEach(function (share) {
        var node = Node.get(share.node_id, nodes)
        if (node) {
          nodes
            .filter(n =>
              new RegExp(node.id.replace(/-/g, '_') + '.').test(n.path)
            )
            .forEach(function (n) {
              n._ = n._ || {}
              n._.access = share.hasOwnProperty('user_access_level')
                ? share.user_access_level
                : share.access_level
              decoratedNodes.push(n)
              seenIds.push(n.id)
            })
        }
      })

      var orgNodes
      // Loop through all orgs and apply permissions
      orgs.forEach(function (o) {
        orgNodes = nodes.filter(n => n.org_id === o.id)
        decoratedNodes = decoratedNodes.concat(
          _this.accessLevelForOrgNodes(orgNodes, o, user, {
            entities: entities
          })
        )
      })

      // Set OWNER_ACCESS for whatever is left
      nodes
        .filter(n => !n.org_id && seenIds.indexOf(n.id) === -1)
        .forEach(function (n) {
          n._ = n._ || {}
          n._.access = constants.OWNER_ACCESS
          decoratedNodes.push(n)
        })
    } else {
      // Assume read-only anonymous share if no user
      nodes.forEach(function (n) {
        n._ = n._ || {}
        n._.access = constants.READ_ACCESS
        decoratedNodes.push(n)
      })
    }

    var t1 = new Date().getTime()
    console.log('decorating ' + decoratedNodes.length + ' nodes took', t1 - t0) // eslint-disable-line no-console
    return decoratedNodes
  },

  accessLevelForNode: function (node) {
    return (
      (node._ && node._.hasOwnProperty('access') && node._.access) ||
      constants.NO_ACCESS
    )
  },

  isListView: function () {
    const tabId = this.tabId()
    if (tabId) {
      var tab = this.tab(tabId)
      return tab && tab.settings && tab.settings.view_mode === 'list'
    }
  },

  commandPalette: function () {
    var commands = CommandPalette.palette(this)
    return Object.keys(commands).map(function (key) {
      return { title: commands[key][0], id: key }
    })
  },

  featureEnabled: function (name, user) {
    const enabledForAll = [
      'board',
      'meetings',
      'projects',
      'todos',
      'trips',
      'events',
      'sidebar',
      'due_nodes',
      'assign_to',
      'permissions',
      'people',
      'companies',
      'publications'
    ]
    // rich_titles
    // sites
    if (enabledForAll.indexOf(name) !== -1) {
      return true
    } else {
      user = user || this.user()
      return Boolean(user && user.features && user.features[name] === 2)
    }
  },

  htmlToMarkdown: function (html) {
    // XXX: The .replace is a temporary fix for the following issue: https://github.com/domchristie/to-markdown/issues/81
    return toMarkdown(html).replace(/(\b\d+)\\\.(\s+)/g, '$1.$2')
  },

  shouldComponentTypeUpdate: function (nextProps, type) {
    type = type || 'regular'
    if (type === 'regular') {
      return !nextProps.activeNodeOnly
    }
    return true
  },

  // Replacement methods for data that was previously duplicated in state

  userId: function () {
    return this.state.userId
  },

  user: function (idOrEntity, noDefault) {
    var id = idOrEntity
      ? typeof idOrEntity === 'string'
        ? idOrEntity
        : idOrEntity.id
      : noDefault
        ? null
        : this.userId()
    if (id) {
      return this.state.entities.users.find(function (u) {
        return u.id === id
      })
    }
  },

  orgId: function () {
    return this.state.userId ? this.user().org_id : null
  },

  org: function (idOrEntity) {
    var id = idOrEntity
      ? typeof idOrEntity === 'string'
        ? idOrEntity
        : idOrEntity.id
      : this.orgId()
    if (id) {
      return this.state.entities.orgs.find(function (o) {
        return o.id === id
      })
    }
  },

  tabId: function () {
    const orgId = this.orgId()
    let id
    if (orgId) {
      const user = this.user()
      if (user) {
        const orgUser = this.state.entities.org_users.find(ou => {
          return ou.user_id === user.id && ou.org_id === orgId
        })
        if (orgUser) {
          id = orgUser.tab_id
        }
      }
    } else {
      id = this.userId() ? this.user().tab_id : null
    }
    return id || `share-${this.state.anonShareId}`
  },

  tab: function (idOrEntity) {
    const id = idOrEntity
      ? typeof idOrEntity === 'string'
        ? idOrEntity
        : idOrEntity.id
      : this.tabId()
    let tab
    if (id) {
      if (this.state.entities && this.state.entities.tabs) {
        tab = this.state.entities.tabs.find(function (t) {
          return t.id === id
        })
      }
      if (!tab) {
        const lsTab = window.localStorage.getItem(`tab-${id}`)
        if (lsTab) {
          tab = JSON.parse(lsTab)
        }
      }
    }
    if (!tab) {
      tab = {
        virtual: true,
        id: `share-${this.state.anonShareId}`,
        expanded_nodes: []
      }
    }
    return tab
  },

  rootNodeId: function () {
    const tab = this.tab()
    if (tab) {
      return tab.node_id || tab.root_id || this.state.urlRootNodeId
    } else {
      return this.state.urlRootNodeId
    }
  },

  rootNode: function () {
    var id = this.rootNodeId()
    if (id) {
      return this.state.entities.nodes.find(function (n) {
        return n.id === id
      })
    }
  },

  node: function (idOrEntity) {
    var id = idOrEntity
      ? typeof idOrEntity === 'string'
        ? idOrEntity
        : idOrEntity.id
      : null
    if (id) {
      return this.state.entities.nodes.find(n => n.id === id)
    }
  },

  activeNodeId: function () {
    return this.state.activeNodeId || window._app.activeNode
  },

  activeNode: function () {
    var id = this.activeNodeId()
    if (id) {
      return this.state.entities.nodes.find(function (n) {
        return n.id === id
      })
    }
  },

  editNodeId: function () {
    return this.state.editNodeId
  },

  editNode: function () {
    var id = this.state.editNodeId
    if (id) {
      return this.state.entities.nodes.find(function (n) {
        return n.id === id
      })
    }
  },

  contextNode: function () {
    var id =
      this.state.floater && this.state.floater.data
        ? this.state.floater.data.nodeId
        : null
    if (id) {
      return this.state.entities.nodes.find(function (n) {
        return n.id === id
      })
    }
  },

  bookmarksNode: function () {
    var user = this.user()
    if (user && user.settings && user.settings.bookmarks_node) {
      return Node.get(user.settings.bookmarks_node, this.state.entities.nodes)
    }
  },

  commentNode: function () {
    var id = this.state.commentNodeId
    if (id) {
      return this.state.entities.nodes.find(function (n) {
        return n.id === id
      })
    }
  },

  summaryNode: function () {
    var id = this.state.summaryNodeId
    if (id) {
      return this.state.entities.nodes.find(function (n) {
        return n.id === id
      })
    }
  },

  share: function (idOrEntity) {
    const id = idOrEntity
      ? typeof idOrEntity === 'string'
        ? idOrEntity
        : idOrEntity.id
      : this.state.shareId
    if (id) {
      return this.state.entities.shares.find(e => e.id === id)
    }
  },

  // share: function () {
  //   var id = this.state.shareId
  //   if (id) {
  //     return this.state.entities.shares.find(function (s) {
  //       return s.id === id
  //     })
  //   }
  // },

  shareNode: function () {
    const share = this.share() || this.anonShare()
    if (share) {
      return Node.get(share.node_id, this.state.entities.nodes)
    }
  },

  anonShare: function () {
    var id = this.state.anonShareId
    if (id) {
      return this.state.entities.shares.find(function (s) {
        return s.id === id
      })
    }
  },

  rawTitleNode: function () {
    var id = this.state.rawTitleNodeId
    if (id) {
      return this.state.entities.nodes.find(function (n) {
        return n.id === id
      })
    }
  },

  orgUser: function (userOrId) {
    const orgId = this.orgId()
    if (orgId) {
      let userId
      if (userOrId) {
        userId = typeof userOrId === 'object' ? userOrId.id : userOrId
      } else {
        userId = this.userId()
      }
      if (userId) {
        return this.state.entities.org_users.find(ou => {
          return ou.user_id === userId && ou.org_id === orgId
        })
      }
    }
  },

  customer: function (orgId) {
    if (orgId) {
      return this.state.entities.customers.find(c => {
        return c.org_id === orgId
      })
    } else {
      const user = this.user()
      if (user) {
        return this.state.entities.customers.find(c => {
          return c.user_id === user.id
        })
      }
    }
  },

  sortedOrgs: function () {
    return _.sortBy(this.state.entities.orgs, 'name')
  },

  availableShares: function () {
    const consumedShares = this.consumedShares()
    const consumedIds = consumedShares.map(s => {
      return s.id
    })
    return this.state.entities.shares.filter(s => {
      return s.user_id !== this.state.userId && consumedIds.indexOf(s.id) === -1
    })
  },

  consumedShares: function () {
    const consumedIds = this.state.entities.tabs
      .filter(t => {
        return t.share_id
      })
      .map(t => {
        return t.share_id
      })
      .concat(
        this.state.entities.nodes
          .filter(n => {
            return n.share_id
          })
          .map(n => {
            return n.share_id
          })
      )
    return this.state.entities.shares.filter(s => {
      return consumedIds.indexOf(s.id) !== -1
    })
  },

  allShares: function () {
    return this.availableShares().concat(this.consumedShares())
  },

  setupRichEditing: function () {
    if (this.featureEnabled('rich_titles')) {
      const editor = new MediumEditor('.formatable, .node-heading .heading', {
        placeholder: false,
        disableEditing: true,
        disableReturn: true,
        toolbar: {
          // buttons: ['bold', 'italic', 'anchor'],
          buttons: ['bold', 'italic', 'code']
        },
        extensions: {
          code: new MediumButton({
            label: '<>',
            start: '<code>',
            end: '</code>'
          })
        }
      })
      editor.subscribe('editableInput', (event, editable) => {
        // console.log('orig_title', window._app.original_title);
        // console.log('event', event);
        // console.log('editable', editable);
        // console.log('event.inputType', event.inputType);
        const validEvents = ['formatBold', 'formatItalic']
        if (!event.inputType || validEvents.indexOf(event.inputType) !== -1) {
          const $editable = $(editable)
          const commonParams = this.getCommonParams()
          commonParams._skipFocus = true
          const li = $editable.closest('li.node')
          const nodeId = li.length ? li.data('node-id') : this.rootNodeId()
          Node.modify(
            nodeId,
            { title: this.htmlToMarkdown($editable.html()) },
            commonParams
          )
        }
      })
    }
  },

  validNodeTypes: function () {
    const ret = ['list', 'bookmark']
    if (this.featureEnabled('todos')) {
      ret.push('todo')
    }
    if (this.featureEnabled('meetings')) {
      ret.push('meeting')
    }
    if (this.featureEnabled('projects')) {
      ret.push('project')
    }
    if (this.featureEnabled('people')) {
      ret.push('person')
    }
    if (this.featureEnabled('companies')) {
      ret.push('company')
    }
    if (this.featureEnabled('trips')) {
      ret.push('trip')
    }
    if (this.featureEnabled('events')) {
      ret.push('event')
    }
    if (this.featureEnabled('sites')) {
      ret.push('site')
    }
    if (this.featureEnabled('publications')) {
      ret.push('publication')
    }
    return ret
  },

  nodeTypeText: function (nodeOrType, plural) {
    const type = typeof nodeOrType === 'object' ? nodeOrType.type : nodeOrType
    switch (type) {
      case 'list':
        if (typeof nodeOrType === 'object' && nodeOrType.body) {
          return plural ? 'Documents' : 'Document'
        } else {
          return plural ? 'List Items' : 'List Item'
        }
      case 'site':
        return plural ? 'Sites' : 'Site'
      case 'project':
        return plural ? 'Projects' : 'Project'
      case 'meeting':
        return plural ? 'Meetings' : 'Meeting'
      case 'person':
        return plural ? 'People' : 'Person'
      case 'company':
        return plural ? 'Company' : 'Company'
      case 'bookmark':
        return plural ? 'Bookmarks' : 'Bookmark'
      case 'todo':
        return plural ? 'TODO Lists' : 'TODO List'
      case 'trip':
        return plural ? 'Trips' : 'Trip'
      case 'event':
        return plural ? 'Events' : 'Event'
      case 'publication':
        return plural ? 'Publications' : 'Publication'
    }
  },

  nodeIcon: function (nodeOrType, nameOnly, nameAndType) {
    let type = typeof nodeOrType === 'object' ? nodeOrType.type : nodeOrType
    let icon, iconName
    switch (type) {
      case 'list':
        if (typeof nodeOrType === 'object' && nodeOrType.mode === 'board') {
          iconName = 'navicon fa-rotate-90'
          type = 'board'
        } else if (typeof nodeOrType === 'object' && nodeOrType.body) {
          iconName = 'file-text-o'
          type = 'note'
        } else {
          iconName = 'circle'
        }
        break
      case 'site':
        iconName = 'globe'
        break
      case 'note':
        iconName = 'bomb'
        break
      case 'project':
        iconName = 'tasks'
        break
      case 'meeting':
        iconName = 'briefcase'
        break
      case 'person':
        iconName = 'user'
        break
      case 'company':
        iconName = 'building-o'
        break
      case 'bookmark':
        iconName = 'bookmark-o'
        break
      case 'todo':
        iconName = 'list-ul'
        break
      case 'trip':
        iconName = 'plane'
        break
      case 'event':
        iconName = 'calendar-check-o'
        break
      case 'publication':
        iconName = 'book'
        break
    }

    if (iconName) {
      icon = nameOnly ? (
        nameAndType ? (
          [iconName, type]
        ) : (
          iconName
        )
      ) : (
        <i className={'icon fa fa-fw ' + type + ' fa-' + iconName} />
      )
    }
    return icon
  },

  appUrl: function () {
    // const protocol = window.location.protocol
    // const host = window.location.hostname
    // const portPart =
    //   [80, 443].indexOf(parseInt(window.location.port)) === -1
    //     ? `:${window.location.port}`
    //     : ''
    return window.NOTEBASE_FULL_URL
  },

  pushSidebar: function (sidebar, returnNewStackInsteadOfSettingState) {
    if (typeof sidebar === 'string') {
      sidebar = [sidebar]
    }
    let sidebarStack = this.state.sidebarStack
    const last = sidebarStack[sidebarStack.length - 1]
    if (last && last[0] === sidebar[0]) {
      // replace args part, but keep same sidebar
      const newSidebar = [last[0], sidebar[1]]
      sidebarStack = sidebarStack.slice(0, -1).concat([newSidebar])
    } else {
      // push the new sidebar to stack
      sidebarStack = sidebarStack.concat([sidebar])
    }
    if (returnNewStackInsteadOfSettingState) {
      return sidebarStack
    } else {
      this.setState({ sidebarStack })
    }
  },

  popSidebar: function (returnNewStackInsteadOfSettingState) {
    if (
      typeof returnNewStackInsteadOfSettingState === 'object' &&
      returnNewStackInsteadOfSettingState.preventDefault
    ) {
      returnNewStackInsteadOfSettingState = false
    }
    const sidebarStack = this.state.sidebarStack.slice(0, -1)
    if (returnNewStackInsteadOfSettingState) {
      return sidebarStack
    } else {
      this.setState({ sidebarStack })
    }
  },

  getBgImageAndColorForScope: function (scope, entity) {
    let color, image

    if (scope === 'rootNode') {
      entity = entity || this.rootNode()
    } else if (scope === 'tab') {
      entity = entity || this.tab()
    } else if (scope === 'org') {
      entity = entity || this.orgUser()
    } else if (scope === 'user') {
      entity = entity || this.user()
    } else if (scope === 'global') {
      entity = entity || this.user()
      image = entity && entity.settings && entity.settings.globalBgImage
      color = entity && entity.settings && entity.settings.globalBgImageColor
    }

    if (!image && entity && entity.settings) {
      image = entity.settings.bgImage
      color = entity.settings.bgImageColor
    }
    return image ? { uri: image, color: color, source: scope } : null
  },

  getBgImageAndColorForCurrent: function (rootNode, tab, orgUser, user, path) {
    rootNode = rootNode || this.rootNode()
    tab = tab || this.tab()
    orgUser = orgUser || this.orgUser()
    user = user || this.user()
    let bgImage = rootNode && rootNode.settings && rootNode.settings.bgImage
    let bgColor =
      rootNode && rootNode.settings && rootNode.settings.bgImageColor
    let source = 'rootNode'
    if (!bgImage && path && path.length) {
      let parent
      let i = 0
      while (!bgImage && (parent = path[i])) {
        bgImage = parent.settings && parent.settings.bgImage
        bgColor = parent.settings && parent.settings.bgImageColor
        if (!bgImage && tab && tab.root_id === parent.id) {
          break
        }
        i++
      }
    }
    if (!bgImage) {
      bgImage = tab && tab.settings && tab.settings.bgImage
      bgColor = tab && tab.settings && tab.settings.bgImageColor
      source = 'tab'
    }
    if (!bgImage) {
      if (this.orgId()) {
        bgImage = orgUser && orgUser.settings && orgUser.settings.bgImage
        bgColor = orgUser && orgUser.settings && orgUser.settings.bgImageColor
        source = 'org'
      } else {
        bgImage = user && user.settings && user.settings.bgImage
        bgColor = user && user.settings && user.settings.bgImageColor
        source = 'user'
      }
    }
    if (!bgImage) {
      bgImage = user && user.settings && user.settings.globalBgImage
      bgColor = user && user.settings && user.settings.globalBgImageColor
      source = 'global'
    }
    return bgImage ? { uri: bgImage, color: bgColor, source: source } : null
  },

  getDataTypeIcon: (type, node) => {
    switch (type) {
      case 'location':
        return 'map-marker'
      case 'people':
        return 'user'
      case 'duration':
        return 'clock-o'
      case 'event_at':
        return 'calendar'
      case 'event_end':
        return 'calendar'
      case 'story_points':
        return 'dashboard'
      case 'priority':
        return 'flag'
      case 'job_title':
        return 'briefcase'
      case 'company':
        return 'building-o'
      case 'phones':
        return 'phone'
      case 'emails':
        return 'at'
      case 'url':
        return 'link'
    }
  },

  getDataTypeTitle: (type, node) => {
    switch (type) {
      case 'event_at':
        return 'Date'
      case 'event_end':
        return 'End date'
      case 'story_points':
        return 'Story point estimate'
      case 'job_title':
        return 'Title'
      case 'company':
        return 'Company'
      case 'phones':
        return 'Phones'
      case 'emails':
        return 'Emails'
      case 'url':
        return 'Web address'
      default:
        return type.replace(/^\w/, c => c.toUpperCase()).replace(/_/g, ' ')
    }
  },

  getDataTypeDescription: (type, node) => {
    switch (type) {
      case 'location':
        return 'A geographic location'
      case 'people':
        return 'A list of the people involved'
      case 'duration':
        return 'How long it lasted/will last'
      case 'event_at':
        return 'The date it happened/happens'
      case 'event_end':
        return 'The end date'
      case 'story_points':
        return 'Agile development work estimate'
      case 'priority':
        return 'How important is it?'
      case 'job_title':
        return 'Job title/Position'
      case 'company':
        return 'Company affiliation'
      case 'phones':
        return 'Phone number(s)'
      case 'emails':
        return 'Email address(es)'
      case 'url':
        return 'Homepage or company web site'
    }
  },

  unsetNodeData: function (nodeOrId, type) {
    const node = this.node(nodeOrId)
    const data = Object.assign({}, node.data || {})
    delete data[type]
    Node.modify(nodeOrId, { data }, this.getCommonParams(), () => {
      this.setState({ addingData: null })
    })
  }
}
