import './styles.css'

import React from 'react'
import moment from 'moment'

import Heading from '../sections/heading'
import Node from '../../../js/node'
import Avatar from '../../avatar'

export default class Likes extends React.Component {
  render () {
    // const { app, data, orgNodes, user } = this.props
    const { app, data, orgNodes } = this.props
    const node = Node.get(data.nodeId, orgNodes)
    const likes = app.state.entities.likes.filter(
      e => e.node_id === data.nodeId
    )

    return (
      <div className='body full-height'>
        <div className='panel likes-sidebar'>
          <Heading {...this.props} isMain>
            <h4>
              Likes on{' '}
              <a href='' onClick={() => app.setRoot(node, null, null)}>
                {node.title}
              </a>
            </h4>
          </Heading>
          <div className='likes-list'>
            {likes.map(l => {
              const likeUser = app.user(l.user_id)
              return (
                <div key={l.id}>
                  <Avatar app={app} user={likeUser} size='medium' />
                  <span className='name'>
                    {likeUser.first_name} {likeUser.last_name}
                  </span>
                  <span className='time'>{moment(l.created_at).fromNow()}</span>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}
