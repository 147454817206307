import React from 'react'

import Node from '../../js/node'

import './styles.css'

export default class EmailsPicker extends React.Component {
  state = {
    str: '',
    type: 'work'
  }

  addEmail = email => {
    const { app } = this.props
    const node = app.node(app.state.floater.data.nodeId)
    const data = { ...(node.data || {}), emails: email }
    Node.modify(node.id, { data }, app.getCommonParams(), () => {
      app.setState({ floater: null })
    })
  }

  removeEmail = email => {
    const { app } = this.props
    const node = app.node(app.state.floater.data.nodeId)
    const data = { ...(node.data || {}), emails: email }
    Node.modify(node.id, { data }, app.getCommonParams(), () => {
      app.setState({ floater: null })
    })
  }

  handleInputKeyPress = ev => {
    const { app } = this.props
    const node = app.node(app.state.floater.data.nodeId)
    const data = node.data || {}
    const oldEmails = data.emails || []
    if (ev.key === 'Enter') {
      const emails = [
        ...oldEmails,
        { type: this.state.type, address: this.state.str }
      ]
      Node.modify(
        node.id,
        { data: { ...(node.data || {}), emails } },
        app.getCommonParams(),
        () => {
          app.setState({ floater: null })
        }
      )
      ev.preventDefault()
    }
  }

  handleInputChange = ev => {
    this.setState({ str: ev.target.value })
  }

  render () {
    const { app } = this.props
    const node = app.node(app.state.floater.data.nodeId)
    const emails = (node.data || {}).emails || []
    const types = [['work', 'Work'], ['home', 'Home'], ['other', 'Other']]

    return (
      <div className='emails-picker'>
        <div className='add-address'>
          <div>
            <select
              value={this.state.type}
              onChange={ev => this.setState({ type: ev.target.value })}
            >
              {types.map(type => (
                <option key={type[0]} value={type[0]}>
                  {type[1]}
                </option>
              ))}
            </select>
          </div>
          <div>
            <input
              type='email'
              value={this.state.str}
              onKeyPress={this.handleInputKeyPress}
              onChange={this.handleInputChange}
            />
          </div>
        </div>
        <div className='existing-addresses'>
          {emails.map(email => {
            return (
              <div key={email.address}>
                <div>
                  <select>
                    {types.map(type => (
                      <option key={type[0]}>{type[1]}</option>
                    ))}
                  </select>
                </div>
                <div>{email.address}</div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}
