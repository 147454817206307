import './styles.css'

import React from 'react'
import classnames from 'classnames'
import $ from 'jquery'
import 'jquery-textcomplete'
// import 'jquery-textcomplete/dist/jquery.textcomplete.css';

import CommentList from './comment_list'

import Heading from '../sections/heading'

import Node from '../../../js/node'
import Comment from '../../../js/comment'
import Misc from '../../../js/misc'
import Org from '../../../js/org'

import emojiMappings from '../../../js/emojimappings'

export default class Comments extends React.Component {
  state = {
    body: ''
  }

  handleCommentKeyUp = ev => {
    if (ev.shiftKey && ev.keyCode === 13) {
      this.submitComment()
    }
  }

  handleCommentChange = ev => {
    this.setState({ body: ev.target.value }, function () {
      if (ev.shiftKey && ev.keyCode === 13) {
        this.submitComment()
      }
    })
  }

  lockScroll = dom => {
    if (dom) {
      var list = $(dom).find('.comment-list')
      if (list.length) {
        $(list).scrollLock()
      }
    }
  }

  submitComment = ev => {
    const { app, data, orgNodes } = this.props
    const node = Node.get(data.nodeId, orgNodes)
    const body = this.state.body
    // var newComment = {node_id: this.props.node.id, body: body, node_meta: this.props.node._};
    var newComment = { node_id: node.id, body: body }
    if (body) {
      Comment.add(newComment, app.getCommonParams(), () => {
        var nodeEl = Misc.nodeEl(node.id)
        if (nodeEl) {
          $(nodeEl)
            .find('.comment-indicator')
            .highlight(1000)
        }
      })

      this.setState({ body: '' })
    }
    ev && ev.preventDefault()
  }

  bindMentionAutocomplete = () => {
    var _this = this
    $(this.commentInput).textcomplete(
      [
        // mentions strategy
        {
          match: /(^|\s)@(\w*)$/i,
          search: function (term, callback) {
            var words = Org.users(
              _this.props.app.state.orgId,
              _this.props.app.getCommonParams()
            ).map(function (u) {
              return u.username + ' ' + u.first_name + ' ' + u.last_name
            })
            callback(
              $.map(words, function (word) {
                return word.toLowerCase().indexOf(term.toLowerCase()) !== -1
                  ? word
                  : null
              })
            )
          },
          replace: function (word) {
            var username = word.split(' ')[0]
            return '$1@' + username
          },
          template: function (value, term) {
            // eslint-disable-line no-unused-vars
            var username = value.split(' ')[0]
            var user = Org.users(
              _this.props.app.state.orgId,
              _this.props.app.getCommonParams()
            ).find(u => u.username === username)
            return (
              '<b>' +
              username +
              '</b> <small>' +
              user.first_name +
              ' ' +
              user.last_name +
              '</small>'
            )
          }
        },
        // emoji strategy
        {
          match: /(^|\s):(\w*)$/i,
          search: function (term, callback) {
            var words = Object.keys(emojiMappings)
            callback(
              $.map(words, function (word) {
                return word.toLowerCase().indexOf(term.toLowerCase()) !== -1
                  ? word
                  : null
              })
            )
          },
          replace: function (word) {
            return '$1' + emojiMappings[word] + ' '
          },
          template: function (value, term) {
            // eslint-disable-line no-unused-vars
            return emojiMappings[value] + ' &nbsp;&nbsp;<b>' + value + '</b>'
          }
        }
      ],
      {
        debounce: 1
      }
    )
  }

  componentDidMount () {
    this.lockScroll(this.commentInput)
    this.bindMentionAutocomplete()
  }

  componentDidUpdate () {
    this.lockScroll(this.commentInput)
    this.bindMentionAutocomplete()
  }

  render () {
    const { app, data, orgNodes, user } = this.props
    const node = Node.get(data.nodeId, orgNodes)
    const comments = app.state.entities.comments.filter(
      c => c.node_id === data.nodeId
    )

    var buttonClasses = classnames({
      button: true,
      btn: true,
      primary: this.state.body.length
    })

    return (
      <div className='body full-height'>
        <div id='comments' className='panel'>
          <Heading {...this.props} isMain>
            <h4>
              Comments on{' '}
              <span className='link' onClick={() => app.setRoot(node)}>
                {node.title}
              </span>
            </h4>
          </Heading>
          <CommentList
            app={app}
            nodeId={node.id}
            user={user}
            comments={comments}
          />
          {!user && (
            <div className='notice centered'>
              You must be signed in to comment.
            </div>
          )}
          {user && (
            <div className='comment-form'>
              <form onSubmit={this.submitComment}>
                <div>
                  <textarea
                    ref={commentInput => {
                      this.commentInput = commentInput
                    }}
                    value={this.state.body}
                    onChange={this.handleCommentChange}
                    onKeyUp={this.handleCommentKeyUp}
                    placeholder='Type your comment'
                  />
                </div>
                <div className='comment-send'>
                  <input type='submit' className={buttonClasses} value='Send' />
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    )
  }
}
