import React, { Component } from 'react'
import enhanceWithClickOutside from 'react-click-outside'
import Node from '../../js/node'

const VALID_DEPTHS = [null, 1, 2]

class CardPeekSettings extends Component {
  state = {
    isOpen: false
  }

  handleClickOutside = () => {
    this.setState({ levelsIsOpen: false })
  }

  savePeekSetting = (field, value) => {
    const { app, cardNode, boardNode } = this.props
    const newSettings = { ...(boardNode.settings.peek_settings || {}) }
    let newCardSetting = newSettings[cardNode.id] || {}
    // TODO: Remove the following 'if' block before pushing to prod
    if (typeof newCardSetting === 'number') {
      newCardSetting = { count: newCardSetting }
    }
    newCardSetting[field] = value
    newSettings[cardNode.id] = newCardSetting
    Node.setSetting(
      boardNode,
      'peek_settings',
      newSettings,
      app.getCommonParams()
    )
  }

  depthValueToName = value => {
    if (!value) return 'No'
    const levelStr = value === 1 ? 'level' : 'levels'
    return `${value} ${levelStr}`
  }

  onSelect = value => {
    value = value || null
    if (VALID_DEPTHS.includes(value)) {
      this.savePeekSetting('count', value)
    }
    this.setState({ levelsIsOpen: false })
  }

  saveHideInbetweens = ev => {
    this.savePeekSetting('hide_inbetweens', ev.target.checked || false)
  }

  render () {
    const { cardNode, boardNode } = this.props
    const text = this.props.text || 'Peek inside'
    const boardSettings = boardNode.settings.peek_settings || {}
    const currentPeekSetting = boardSettings[cardNode.id] || {}
    return (
      <div className='kb-settings-container'>
        <div className='kb-settings-item'>
          {text}:{' '}
          <div
            className='current'
            onClick={ev => {
              ev.stopPropagation()
              this.setState({ levelsIsOpen: !this.state.levelsIsOpen })
            }}
          >
            {this.depthValueToName(currentPeekSetting.count)}{' '}
            <i className='fa fa-fw fa-angle-down' />
            {this.state.levelsIsOpen && (
              <div className='dropdown'>
                {VALID_DEPTHS.map(d => (
                  <div key={d} onClick={() => this.onSelect(d)}>
                    {this.depthValueToName(d)}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        {currentPeekSetting.count > 1 && (
          <div className='kb-settings-item'>
            <input
              type='checkbox'
              checked={currentPeekSetting.hide_inbetweens}
              onChange={this.saveHideInbetweens}
            />{' '}
            Hide middle level
          </div>
        )}
      </div>
    )
  }
}

export default enhanceWithClickOutside(CardPeekSettings)
