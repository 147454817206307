import './styles.css'

import React from 'react'
import Heading from '../heading'

class Stats extends React.Component {
  render () {
    var length = this.props.app.state.entities.nodes
      ? this.props.app.state.entities.nodes.length
      : 0
    return (
      <div className='panel'>
        <div id='stats'>
          <Heading {...this.props} name='Debug Info' />
          <div className='debug-line'>Nodes: {length}</div>
        </div>
      </div>
    )
  }
}

module.exports = Stats
