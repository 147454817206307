import React from 'react'

import Heading from '../heading'
import Node from '../../../../js/node'

import './styles.css'
export default class NodeLabels extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      editing: props.mode === 'editLabels'
    }
  }

  toggleLabel = id => {
    const { app, node } = this.props
    const oldLabels = node.settings.labels || []
    const newLabels =
      oldLabels.indexOf(id) === -1
        ? oldLabels.concat([id])
        : oldLabels.filter(l => l !== id)
    Node.modify(
      node,
      { settings: { ...node.settings, labels: newLabels } },
      app.getCommonParams()
    )
  }

  render () {
    const { app, node } = this.props
    const user = app.user()
    const overrides =
      (app.orgId()
        ? app.org().settings.label_overrides
        : user
          ? app.user().settings.label_overrides
          : {}) || {}
    const { labels = [] } = node.settings
    return (
      <div className='panel node-labels'>
        {!this.props.noHeading && <Heading {...this.props} name='Labels' />}
        <div className='labels'>
          {this.state.editing && (
            <div className='editor'>
              <div className='choose'>
                {app.state.defaultLabels.slice(0, 6).map(l => {
                  const override = overrides[l.id] || {}
                  const label = { ...l, ...override }
                  const isActive = (labels || []).indexOf(l.id) !== -1
                  return (
                    <div
                      key={l.id}
                      onClick={() => this.toggleLabel(l.id)}
                      className={isActive ? 'active' : ''}
                      style={{ backgroundColor: label.bg, color: label.text }}
                    >
                      <div className='main'>{label.name}</div>
                      <div>
                        <i className='fa fa-fw fa-check' />
                      </div>
                    </div>
                  )
                })}
              </div>
              <div>
                <button onClick={() => this.setState({ editing: false })}>
                  Done Editing Labels
                </button>
              </div>
            </div>
          )}
          {Boolean(!this.state.editing && labels.length) && (
            <div className='list'>
              {labels.map(id => {
                const defaultLabel = app.state.defaultLabels.find(
                  l => l.id === id
                )
                const override = overrides[id] || {}
                const label = { ...defaultLabel, ...override }

                const isActive = (labels || []).indexOf(id) !== -1
                return (
                  <div
                    key={id}
                    className={isActive ? 'active' : ''}
                    style={{ backgroundColor: label.bg, color: label.text }}
                  >
                    <div className='main'>{label.name || '\xa0'}</div>
                  </div>
                )
              })}
            </div>
          )}
          {Boolean(!this.state.editing && !labels.length) && (
            <div className='no-data'>
              <div>No labels set.</div>
            </div>
          )}
          {Boolean(user && !this.state.editing) && (
            <div>
              <button onClick={() => this.setState({ editing: true })}>
                Edit Labels
              </button>
            </div>
          )}
        </div>
      </div>
    )
  }
}
