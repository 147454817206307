import React from 'react'
import $ from 'jquery'
import _ from 'lodash'

import constants from '../..//lib/constants'

import SelectMenu from '../select_menu'

import Misc from '../../js/misc'

class FileBrowser extends React.Component {
  state = {
    filetype: '',
    page: 1
  }

  setBrowserMode = (usePersonal, ev) => {
    var _this = this
    if (
      (this.props.app.state.usePersonalFileBrowser && !usePersonal) ||
      (!this.props.app.state.usePersonalFileBrowser && usePersonal)
    ) {
      this.setState({ page: 1, filetype: '' }, function () {
        _this.props.app.setFileBrowserMode(usePersonal)
      })
    }
    ev && ev.preventDefault && ev.preventDefault()
  }

  openFile = ev => {
    window.open(
      $(ev.target)
        .closest('a')
        .attr('href'),
      '_blank'
    )
    ev.preventDefault()
    ev.stopPropagation()
  }

  handleFileTypeChange = (ev, fileType) => {
    const { app } = this.props
    this.setState(
      { filetype: fileType.value, page: 1 },
      function () {
        Misc.jx(
          this,
          'browse_files',
          {
            org_id: app.orgId(),
            limit: constants.BROWSER_ITEMS_PER_PAGE,
            fileType: fileType.value
          },
          function (res) {
            this.props.app.setState({ userFiles: res.body })
          }.bind(this)
        )
      }.bind(this)
    )
  }

  handleFilterChange = ev => {
    const { app } = this.props
    var value = ev.target.value
    clearTimeout(this.filterTimeout)
    this.filterTimeout = setTimeout(
      function () {
        this.setState(
          { filter: value, page: 1 },
          function () {
            Misc.jx(
              this,
              'browse_files',
              {
                org_id: app.orgId(),
                limit: constants.BROWSER_ITEMS_PER_PAGE,
                fileType: this.state.filetype,
                filter: value
              },
              function (res) {
                this.props.app.setState({ userFiles: res.body })
              }.bind(this)
            )
          }.bind(this)
        )
      }.bind(this),
      300
    )
  }

  prevPage = ev => {
    const { app } = this.props
    var btn = $(ev.target).closest('.btn')
    if (!btn.hasClass('disabled')) {
      this.setState(
        { page: this.state.page - 1 },
        function () {
          Misc.jx(
            this,
            'browse_files',
            {
              org_id: app.orgId(),
              limit: constants.BROWSER_ITEMS_PER_PAGE,
              fileType: this.state.filetype,
              filter: this.state.filter,
              page: this.state.page
            },
            function (res) {
              this.props.app.setState({ userFiles: res.body })
            }.bind(this)
          )
        }.bind(this)
      )
    }
    ev.preventDefault()
  }

  nextPage = ev => {
    const { app } = this.props
    var btn = $(ev.target).closest('.btn')
    if (!btn.hasClass('disabled')) {
      this.setState(
        { page: this.state.page + 1 },
        function () {
          Misc.jx(
            this,
            'browse_files',
            {
              org_id: app.orgId(),
              limit: constants.BROWSER_ITEMS_PER_PAGE,
              fileType: this.state.filetype,
              filter: this.state.filter,
              page: this.state.page
            },
            function (res) {
              this.props.app.setState({ userFiles: res.body })
            }.bind(this)
          )
        }.bind(this)
      )
    }
    ev.preventDefault()
  }

  render () {
    var fileBrowser
    if (this.props.app.state.browsingFiles) {
      var totalPages = Math.ceil(
        this.props.app.state.userFiles.count / constants.BROWSER_ITEMS_PER_PAGE
      )
      var files = []
      var i = 0
      var file
      var sortedFiles = _.sortBy(
        this.props.app.state.userFiles.files,
        'created_at'
      ).reverse()
      while ((file = sortedFiles[i])) {
        var deleteHandler = this.props.app.deleteFile.bind(this.props.app, file)
        var editHandler = this.props.app.editFile.bind(this.props.app, file)
        var selectHandler = this.props.app.selectFile.bind(this.props.app, file)
        var editContent = (
          <a
            href=''
            onClick={editHandler}
            target='_blank'
            title='Edit this file'
          >
            <span className='octicon octicon-pencil' />
          </a>
        )
        var thumb
        var allowEdit = false
        if (Misc.isImage(file)) {
          // allowEdit = true;

          // thumb = <img src={Misc.fileThumbUrl(file) + '?x' + _.random(100000000)} />;
          thumb = <img src={Misc.fileThumbUrl(file)} />
        } else {
          switch (Misc.fileType(file)) {
            case 'pdf':
              thumb = <span className='octicon octicon-file-pdf' />
              break
            case 'text':
              allowEdit = true
              thumb = <span className='octicon octicon-file-text' />
              break
            case 'code':
              allowEdit = true
              thumb = <span className='octicon octicon-file-code' />
              break
            case 'video':
              thumb = <span className='octicon octicon-file-media' />
              break
            default:
              thumb = <span className='octicon octicon-file-binary' />
          }
        }

        files.push(
          <tr key={file.id} onClick={selectHandler}>
            <td className='thumb'>{thumb}</td>
            <td>{file.filename}</td>
            <td>{file.mimetype}</td>
            <td>{Misc.fileSize(file.size)}</td>
            <td>
              <a
                href={Misc.fileUrl(file)}
                onClick={this.openFile}
                title='Open file in new window'
              >
                <span className='octicon octicon-link-external' />
              </a>
            </td>
            <td>{allowEdit && editContent}</td>
            <td>
              <a
                href=''
                onClick={deleteHandler}
                title='Permanently delete this file'
              >
                <span className='octicon octicon-trashcan' />
              </a>
            </td>
          </tr>
        )
        i++
      }

      var browserClasses = ''
      var tabs
      if (this.props.org) {
        browserClasses += ' with-tabs'
        tabs = (
          <div className='tabs'>
            <div
              onClick={() => this.setBrowserMode(false)}
              className={
                !this.props.app.state.usePersonalFileBrowser && 'active'
              }
            >
              {this.props.org.name} files
            </div>
            <div
              onClick={() => this.props.app.setFileBrowserMode(true)}
              className={
                this.props.app.state.usePersonalFileBrowser && 'active'
              }
            >
              My files
            </div>
          </div>
        )
      }
      fileBrowser = (
        <div id='file-browser' className='browser'>
          <div className={browserClasses}>
            {tabs}
            <div className='header'>
              <div className='close-x'>
                <a href='' onClick={this.props.app.closeFileBrowser}>
                  <span className='octicon octicon-x' />
                </a>
              </div>
              <table>
                <tbody>
                  <tr>
                    <td className='p10'>
                      <SelectMenu
                        items={[{ value: '', name: 'Any' }].concat(
                          constants.FILE_MIME_TYPES
                        )}
                        label='Show file type'
                        title='Show only this filetype'
                        filterTitle='Filter file types'
                        defaultValue={
                          this.props.app.state.browseFilesOptions.fileType || ''
                        }
                        classes='dark flat'
                        onChange={this.handleFileTypeChange}
                      />
                    </td>
                    <td className='pl20'>
                      <input
                        type='text'
                        placeholder='Filter by name...'
                        onChange={this.handleFilterChange}
                      />
                    </td>
                    <td className='pl20'>
                      <small>
                        {this.props.app.state.userFiles.count} files,{' '}
                        {Misc.fileSize(this.props.app.state.userFiles.size)}
                      </small>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='files content'>
              <table>
                <tbody>{files}</tbody>
              </table>
            </div>
            <div className='footer'>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <a
                        href=''
                        className='btn'
                        onClick={this.props.app.launchFP}
                      >
                        Upload files
                      </a>&nbsp;&nbsp;&nbsp;
                      <a
                        href=''
                        className='btn'
                        onClick={this.props.app.newFile}
                      >
                        Create new file
                      </a>
                    </td>
                    <td>
                      <small>
                        Page {this.state.page} / {totalPages}
                      </small>
                      &nbsp;&nbsp; &nbsp;&nbsp;
                      <button
                        className='btn'
                        disabled={this.state.page === 1}
                        onClick={this.prevPage}
                      >
                        <span className='octicon octicon-chevron-left' />
                      </button>&nbsp;&nbsp;&nbsp;
                      <button
                        className='btn'
                        disabled={this.state.page === totalPages}
                        onClick={this.nextPage}
                      >
                        <span className='octicon octicon-chevron-right' />
                      </button>
                    </td>
                    <td className='rj'>
                      <a
                        href=''
                        className='btn'
                        onClick={this.props.app.closeFileBrowser}
                      >
                        Close
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )
    }
    return <div>{fileBrowser}</div>
  }
}

module.exports = FileBrowser
