import './styles.css'

import React from 'react'
import $ from 'jquery'
import S from 'string'
import _ from 'lodash'

import Heading from '../sections/heading'

import TreeNode from '../../tree_node'
import Text from '../../../js/text'
import Misc from '../../../js/misc'

// import classnames from 'classnames';

class Inbox extends React.Component {
  inbox = () => {
    const { app } = this.props
    return _.uniq(app.state.entities.nodes.filter(node => node.inbox))
  }

  moveToNode = target => {
    const { app } = this.props
    var redoCommands = []
    var undoCommands = []
    var items = []
    var inbox = this.inbox()
    if (app.state.selectedInboxItems.length) {
      // Do this to match the order in the inbox when the selected items are moved into place
      inbox.reverse().forEach(function (n) {
        if (app.state.selectedInboxItems.indexOf(n.id) !== -1) {
          items.push(n)
        }
      })
    } else {
      items = inbox.reverse()
    }
    items.forEach(function (node) {
      redoCommands.push({
        obj: 'Node',
        cmd: 'move',
        args: [node.id, target.id, null]
      })
      undoCommands.push({
        obj: 'Node',
        cmd: 'move',
        args: [node.id, 'INBOX', null]
      })
    })
    app.state.undo.add(
      {
        undo: undoCommands,
        redo: redoCommands
      },
      true
    )
    var params = app.getCommonParams()
    params.updatedState = { selectedInboxItems: [], updateAllNodes: true }
    Misc.executeCommands(redoCommands, params, () => {
      // var updatedInbox = this.inbox();
      // if (!updatedInbox.length) {
      //   app.toggleInbox();
      // }
    })
  }

  dragStart = (node, ev) => {
    const { app } = this.props
    var _this = this
    ev.preventDefault()
    window._app.dragStartTimeout = setTimeout(function () {
      app.scrollSmoothly()
      $('#drag-indicator').addClass('active')
      window._app.dragElementType = 'node'
      window._app.nodesEl = $('#nodes')
      window._app.firstNode = $('#nodes li.node:first')
      window._app.lastNode = $('#nodes li.node:last')
      window._app.rightOffset =
        window._app.nodesEl.offset().left + window._app.nodesEl.width()

      var focusEl = $(':focus')
      if (focusEl.length) {
        Text.saveSelection(focusEl.get(0))
      }
      app.setAppState({ floater: null })
      clearTimeout(_this.contextTimer)
      console.log('dragstart') // eslint-disable-line no-console
      // XXX: DIRTY! Make ctrls play nice with react
      // $('.ctrl-container').hide();
      $('#app-wrapper').addClass('drag-in-progress')
      $('[contenteditable]')
        .attr('contenteditable', false)
        .addClass('were-editable')
      // if (this.props.node.type !== 'bookmark') {
      //   var li = $(e.target).closest('li.node');
      //   var div = li.find('> .indented > .indent-wrapper > .node-content > div > div.data[contenteditable]');
      //   console.log(div.get(0));
      //   html = Node.parseHtml(div.html());
      //   Node.setTextUndo(this.props.node.id, html, this.props.undo, this.props.nodes, this.props.replaceNodes);
      //   Text.saveSelection(div.get(0));
      // }
      // app.setAppState({dragged: this.props.node.id});
      window._app.dragged = node
      window._app.draggedOver = null
      var li = $(ev.target).closest('li.node')
      $('li.node').removeClass('dragged')
      $(li).addClass('dragged')
      // e.dataTransfer.effectAllowed = 'move';
      // if (_this.props.node.inbox) {
      //   var dragIcon = document.createElement('img');
      //   dragIcon.src = '/static/bullet.png';
      //   dragIcon.width = 16;
      //   // e.dataTransfer.setDragImage(dragIcon, -10, -10);
      // }

      // Firefox requires dataTransfer data to be set
      // e.dataTransfer.setData('text/html', e.currentTarget);
    }, 200)
  }

  render () {
    const { app } = this.props
    // var buttonClasses = classnames({
    //   button: true,
    //   primary: this.state.body.length,
    // });

    // const inboxItems = _.uniq(app.state.entities.nodes.filter(function(node) { return node.inbox; }));
    const inboxItems = app.state.entities.nodes.filter(node => {
      return node.inbox
    })
    const selectedItems = app.state.selectedInboxItems

    let currButton, bookmarksButton, tip
    const itemsStr = selectedItems.length === 1 ? ' item' : ' items'
    const moveText = selectedItems.length
      ? 'Move ' + selectedItems.length + itemsStr
      : 'Move all'
    const rootNode = app.rootNode()
    const bookmarksNode = app.bookmarksNode()

    if (rootNode && (!bookmarksNode || rootNode.id !== bookmarksNode.id)) {
      currButton = (
        <button onClick={() => this.moveToNode(rootNode)}>
          {moveText} to {'"'}
          {S(rootNode.title).truncate(30).s}
          {'"'}
        </button>
      )
    }

    if (bookmarksNode) {
      bookmarksButton = (
        <button onClick={() => this.moveToNode(bookmarksNode)}>
          {moveText} to {'"'}
          {S(bookmarksNode.title).truncate(30).s}
          {'"'}
        </button>
      )
    } else {
      tip = (
        <div className='tip'>
          <p>
            <strong>Tip:</strong> Select {'"'}Set as Bookmarks node{'"'} in the
            context menu for any node to set a default home for your bookmarks.
          </p>
          <p>
            This adds a button here that lets you move Inbox items to that node
            with one click.
          </p>
        </div>
      )
    }

    let selectedActions
    if (inboxItems.length) {
      selectedActions = (
        <div>
          <div className='actions'>
            {currButton} {bookmarksButton}
          </div>
          {tip}
        </div>
      )
    }

    return (
      <div className='body full-height'>
        <div id='sidebar-inbox' className='panel'>
          <Heading {...this.props} name='Inbox' isMain />
          {Boolean(!inboxItems.length) && (
            <div className='inbox-intro'>
              <p>
                The Inbox contains bookmarks you have saved and not yet moved to
                their final location.
              </p>
              <p>
                If you haven’t done so already, drag the square box below to
                your browser’s bookmarks bar, and click it whenever you visit a
                web page that you want to save for later.
              </p>
              <div className='bookmarklet-container'>
                <a
                  className='bookmarklet'
                  href={
                    'javascript: (function () { var jsCode = document.createElement("script"); jsCode.setAttribute("src", "https://notebase.io/-/post?h=' +
                    this.props.user.bookmarklet_id +
                    '"); document.body.appendChild(jsCode); }());'
                  }
                >
                  Save to Notebase
                </a>
              </div>
            </div>
          )}
          {Boolean(inboxItems.length) && (
            <ul>
              {inboxItems
                .sort((a, b) => {
                  return a.created_at < b.created_at ? 1 : -1
                })
                .map(node => {
                  return (
                    <TreeNode
                      app={app}
                      key={node.id}
                      node={node}
                      nodes={app.state.entities.nodes}
                      displayNodes={app.state.displayNodes}
                      user={this.props.user}
                      tab={this.props.tab}
                      rootNode={this.props.rootNode}
                      setRoot={app.setRoot}
                      tmpVisible={app.state.tmpVisible}
                      pubUrl={app.pubUrl}
                      setAppState={app.setAppState}
                      getCommonParams={app.getCommonParams}
                      setPanel={app.setPanel}
                      panel={app.state.panel}
                      updatedTextNode={app.state.updatedTextNode}
                      dragged={app.state.dragged}
                      draggedOver={app.state.draggedOver}
                      dragIsValid={app.state.dragIsValid}
                      searchstring={app.state.searchstring}
                      undo={app.state.undo}
                      nodeLink={app.nodeLink}
                      descriptionNode={this.props.descriptionNode}
                      selectedInboxItems={this.props.selectedInboxItems}
                      updatedNodes={app.state.updatedNodes}
                      onMouseDown={() => this.dragStart(node)}
                      replaceNodes={app.state.replaceNodes}
                    />
                  )
                })}
            </ul>
          )}
          {selectedActions}
        </div>
      </div>
    )
  }
}

module.exports = Inbox
