var update = require('react-addons-update')

var User = {

  get: function (userOrId, users) {
    users = users || []
    if (typeof userOrId === 'string') {
      return users.find(u => u.id === userOrId)
    } else {
      return userOrId
    }
  },

  customer: function (userOrId, params) {
    var user = this.get(userOrId, params.entities.users)
    if (user && user.customer_id) {
      return params.entities.customers.find(c => c.id === user.customer_id)
    }
  },

  modify: function (userOrId, data, params, callback) {
    const doAction = (actionParams) => {
      const newParams = Object.assign({}, actionParams)
      const users = actionParams.updatedState.entities ? actionParams.updatedState.entities.users : actionParams.state.entities.users
      const user = User.get(userOrId, users)
      const newUser = update(user, {$merge: data})

      const oldData = {}
      for (const key in data) {
        const keys = key.split('.')
        let obj = newUser
        for (let i = 0; i <= keys.length - 1; i++) {
          if (i === keys.length - 1) {
            obj[keys[i]] = data[key]
          }

          oldData[key] = user[key]
          obj = obj[keys[i]]
        }
      }

      const trimmedUsers = users.filter(u => u.id !== newUser.id)
      const newUsers = trimmedUsers.concat([newUser])

      const undoData = {
        undo: [{obj: 'User', cmd: 'modify', args: [user.id, oldData]}],
        redo: [{obj: 'User', cmd: 'modify', args: [user.id, data]}]
      }

      if (!actionParams._isUndoOperation && !actionParams._skipUndoAdd) {
        actionParams.undo.add(undoData, true, actionParams._skipUndo)
      }

      const newEntities = Object.assign({}, (actionParams.updatedState.entities || actionParams.state.entities))
      newEntities.users = newUsers
      const result = {entities: newEntities}

      newParams.updatedState = Object.assign({}, actionParams.updatedState, result)
      if (!actionParams._skipUndo) {
        newParams.undoData = actionParams.undoData || {}
        newParams.undoData.undo = (newParams.undoData.undo || []).concat(undoData.undo)
        newParams.undoData.redo = (newParams.undoData.redo || []).concat(undoData.redo)
      }

      if (actionParams._skipSetState) {
        if (typeof callback === 'function') {
          callback(newParams)
        }
      } else {
        actionParams.setAppState(newParams.updatedState, newParams.stateParams, () => {
          if (typeof callback === 'function') {
            callback(newParams)
          }
        })
      }
    }

    if (params._skipFlushUndo) {
      doAction(params)
    } else {
      params.flushTextUndo(params, ret => {
        doAction(ret)
      })
    }
  },

  seenNotifications: function (newTime, params, callback) {
    const newParams = Object.assign({}, params)
    const users = params.updatedState.entities ? params.updatedState.entities.users : params.state.entities.users
    const user = params.user()
    const actualNewTime = typeof newTime === 'string' ? newTime : newTime.toISOString()
    const newUser = update(user, {$merge: {notifications_seen_until: actualNewTime, updated_at: new Date().toISOString()}})
    const trimmedUsers = users.filter(u => u.id !== newUser.id)
    const newUsers = trimmedUsers.concat([newUser])

    params.undo.add({redo: [{obj: 'User', cmd: 'seenNotifications', args: [newTime]}]}, true)

    const newEntities = Object.assign({}, (params.updatedState.entities || params.state.entities))
    newEntities.users = newUsers
    const result = {entities: newEntities}

    newParams.updatedState = Object.assign({}, params.updatedState, result)

    if (params._skipSetState) {
      if (typeof callback === 'function') {
        callback(newParams)
      }
    } else {
      params.setAppState(newParams.updatedState, newParams.stateParams, () => {
        if (typeof callback === 'function') {
          callback(newParams)
        }
      })
    }
  }

}

module.exports = User
