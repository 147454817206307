import './styles.css'

import React from 'react'
import $ from 'jquery'
import _ from 'lodash'

import constants from '../../..//lib/constants'

import classNames from 'classnames'

import NodeMeta from '../../node_meta'
import NodeTypeData from './node_type_data'
import NodeTypeSpecifics from './node_type_specifics'

import Node from '../../../js/node'
import Keys from '../../../js/keys'
import Misc from '../../../js/misc'

class CurrentNode extends React.Component {
  state = {
    titleText: Node.title(this.props.node),
    descriptionText: Node.description(this.props.node),
    local: false,
    parsedBody: ''
  }

  getKeyParams = () => {
    return _.extend(this.props.getCommonParams(), {
      setTitle: this.setTitle,
      setDescription: this.setDescription
    })
  }

  setTitle = newTitle => {
    this.setState({ titleText: newTitle, local: true })
  }

  setDescription = newDescription => {
    this.setState({ description: newDescription, local: true })
  }

  handleHeadingKeyDown = ev => {
    Keys.handleHeadingKeyDown(ev, this.props.node, this.getKeyParams())
  }

  handleHeadingKeyUp = ev => {
    Keys.handleHeadingKeyUp(ev, this.props.node, this.getKeyParams())
  }

  handleHeadingFocus = ev => {
    var el = $(ev.target)
    var text
    if (this.props.app.featureEnabled('rich_titles')) {
      text = el.html()
    } else {
      text = Node.parseHtml(el.text())
    }
    window._app.original_title = text
    window._app.activeNode = this.props.node.id
    clearTimeout(window._app.titleFocusTimeout)
    this.props.setAppState({
      hasMoved: true,
      activeNodeId: this.props.node.id,
      activeNodeOnly: true
    })
    // window._app.titleFocusTimeout = setTimeout(() => {
    //   this.props.setAppState({activeNodeId: this.props.node.id, activeNodeOnly: true});
    // }, 200);
  }

  handleHeadingBlur = ev => {
    var div = $(ev.target)
    if (!window._app.skipHeadingBlur) {
      Node.flushTextUndo(
        { el: div, node: this.props.node },
        this.props.getCommonParams()
      )
    }
    window._app.skipHeadingBlur = false
  }

  handleDescriptionKeyDown = ev => {
    Keys.handleDescriptionKeyDown(ev, this.props.node, this.getKeyParams())
  }

  handleDescriptionKeyUp = ev => {
    Keys.handleDescriptionKeyUp(ev, this.props.node, this.getKeyParams())
  }

  handleDescriptionFocus = ev => {
    var el = $(ev.target)
    var text = Node.parseHtml(el.text())
    window._app.original_description = text
  }

  handleDescriptionBlur = ev => {
    var div = $(ev.target)
    if (!window._app.skipDescriptionBlur) {
      Node.flushTextUndo(
        { el: div, node: this.props.node },
        this.props.getCommonParams()
      )
    }
    window._app.skipDescriptionBlur = false
  }

  expandOrCollapse = ev => {
    ev.preventDefault()
    if (ev.shiftKey) {
      Node.recursiveToggle(this.props.node, this.props.app.getCommonParams())
    }
  }

  addEmptyChild = () => {
    var newNode = Node.new(
      {
        parent_id: this.props.node.id,
        prev_id: null,
        user_id: this.props.node.user_id,
        org_id: this.props.node.org_id
      },
      this.props.app.getCommonParams()
    )
    Node.add(newNode, this.props.getCommonParams())
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    this.key = 0
    this.setState({ parsedBody: Misc.parseMarkdown(this.props.node.body) })
    // Misc.jx(_this, 'parse', {text: params.body, node_id: parseNode.id}, function(res) {
    //   if (res.body.html) {
    //     _this.setAppState({parsedBody: res.body.html}, {skipRemoteParse: true, keepUrl: true});
    //     setTimeout(function() {
    //       Misc.fixGists();
    //     }, 0);
    //   }
    // }.bind(_this));
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate (nextProps, nextState) {
    if (
      nextProps.node.type !== 'bookmark' &&
      nextProps.node.type !== '_ref' &&
      (nextProps.node.title !== nextState.titleText ||
        nextProps.updatedTextNode !== nextProps.node.id)
    ) {
      this.key++
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps (nextProps) {
    const newState = { local: false }
    if (nextProps.node.body !== this.props.node.body) {
      newState.parsedBody = Misc.parseMarkdown(nextProps.node.body)
    }
    this.setState(newState)
  }

  componentDidMount () {
    var _this = this
    // $('#editor-container').html('<div id="editor"></div>');
    $(document).on('click', '.md-tab-links a', function (ev) {
      ev.preventDefault()
      ev.stopPropagation()
      var tabs = $(this).closest('.md-tab-container')
      tabs.find('.md-tab-links li, .md-tab-data').removeClass('active')
      var name = $(this)
        .attr('href')
        .replace(/^#/, '')
      var a = tabs.find('a[name="' + name + '"]')
      var data = a.closest('.md-tab-data')
      data.addClass('active')
      $(this)
        .closest('li')
        .addClass('active')
    })
    $(document).on('click', '.note-body a', function (ev) {
      var $this = $(this)
      if ($this.hasClass('md-internal-link')) {
        if ($this.hasClass('missing')) {
          alert(
            'This internal link points to an unknown document. Are you sure the link matches the title or slug you want to link to?'
          )
        } else {
          _this.props.app.setRoot($this.data('id'))
        }

        ev.preventDefault()
      } else if ($this.hasClass('md-tab-link')) {
        // passthrough
      } else {
        var match = $this
          .attr('href')
          .match(/https?:\/\/(notebase.io|localhost:3199)\/n\/([^/]+)/)
        if (match && match[2]) {
          _this.props.app.setRoot(match[2])
          ev.preventDefault()
        } else {
          var relMatch = $this.attr('href').match(/(\/n\/)?([\w0-9-_]+)(#.*)?$/)
          if (relMatch && relMatch[2]) {
            _this.props.app.setRoot(relMatch[2])
            ev.preventDefault()
          }
        }
      }
    })
  }

  shouldComponentUpdate (nextProps, nextState) {
    var updatedNodeIds = nextProps.updatedNodeIds || []
    var node = nextProps.node || this.props.node

    if (nextProps.updateAllNodes) {
      return true
    }

    // XXX: Added on 2017-04-06 to make project summaries update after completing a child.
    // This was not here before, may cause trouble - pay attention!
    if (updatedNodeIds.indexOf(node.id) !== -1) {
      return true
    }

    if (
      nextProps.node &&
      this.props.node &&
      nextProps.node.body !== this.props.node.body
    ) {
      return true
    }

    if (
      nextProps.editorCompanion &&
      this.props.editorCompanion &&
      this.props.editorCompanion !== nextProps.editorCompanion
    ) {
      return true
    }

    if (this.props.addingData !== nextProps.addingData) {
      return true
    }

    // Added this on 2015-03-03 to fix missing three dots after deleting the last child node for a root
    if (this.props.nodes.length !== nextProps.nodes.length) {
      // return true;
      // passthrough to default (true)
    } else if (
      updatedNodeIds.indexOf(node.id) !== -1 &&
      nextProps.nodes.length > this.props.nodes.length
    ) {
      // return true;
      // passthrough to default (true)
    } else {
      if (
        nextProps.node === this.props.node &&
        nextProps.resolvedNode === this.props.resolvedNode &&
        nextProps.tmpVisible === this.props.tmpVisible &&
        nextProps.editNodeId === this.props.editNodeId &&
        nextProps.parsedBody === this.props.parsedBody
      ) {
        return false
      }

      if (nextState.local) {
        return false
      }

      if (
        nextProps.node.title === nextState.titleText &&
        nextProps.updatedTextNode &&
        nextProps.updatedTextNode === nextProps.node.id
      ) {
        return false
      }
    }

    return true
  }

  contextIcon = containerClasses => {
    return (
      <div id='context-icon-container'>
        <div id='context-icon'>{this.props.app.nodeIcon(this.props.node)}</div>
      </div>
    )
  }

  render () {
    var _this = this
    var share
    var rootNode = this.props.rootNode
    var children = Node.children(
      this.props.node,
      this.props.app.state.entities.nodes
    )
    var accessLevel = constants.OWNER_ACCESS
    if (this.props.share) {
      share = this.props.share
      accessLevel = share.hasOwnProperty('user_access_level')
        ? share.user_access_level
        : share.access_level
    }
    const isReadOnly = !this.props.user || accessLevel < constants.WRITE_ACCESS
    const hasData = Boolean(
      this.props.addingData || Object.keys(this.props.node.data || {}).length
    )

    var bodyClasses = classNames({
      'note-body': true,
      hidden: this.props.editNodeId && this.props.editorCompanion !== 'lists'
    })
    var containerClasses = classNames({
      'tmp-completed':
        this.props.node.completed &&
        this.props.tmpVisible.indexOf(this.props.node.id) !== -1,
      completed:
        this.props.node.completed &&
        this.props.tmpVisible.indexOf(this.props.node.id) === -1,
      children: children.length,
      'read-only': isReadOnly,
      'has-data': hasData,
      'has-body': this.props.node.body,
      'has-description': this.props.node.description,
      'move-data-button':
        hasData ||
        [].indexOf(this.props.node.type) !== -1 ||
        this.props.node.body
    })
    containerClasses += ' ' + this.props.node.type
    var dots, toc
    var resolvedChildren = Node.children(
      this.props.resolvedNode.id,
      this.props.nodes
    )
    if (!isReadOnly && this.props.resolvedNode && !resolvedChildren.length) {
      dots = (
        <div id='add-child' onClick={this.addEmptyChild}>
          <i className='fa fa-fw fa-plus' />
          <span>Add an item...</span>
        </div>
      )
    }

    if (this.props.resolvedNode.body) {
      toc = (
        <div id='toc-container'>
          <div id='toc' />
        </div>
      )
    }

    // var title = Node.title(this.props.resolvedNode).replace(linkRegex, linkReplacer);
    var urlText = Node.title(this.props.node)
      .replace(Keys.urlRegex, Keys.urlReplacer)
      .replace(/\s$/, '&nbsp;')
      .replace(/;$/, '')
    var title = urlText
      .replace(Keys.linkRegex, Keys.linkReplacer)
      .replace(/\s$/, '&nbsp;')
    var description
    var descriptionText = this.props.node.description
    if (descriptionText) {
      description = descriptionText
        .replace(Keys.linkRegex, Keys.linkReplacer)
        .replace(/\s$/, '&nbsp;')
    }
    if (this.props.tab.share_id) {
      share = this.props.app.state.entities.shares.find(
        s => s.id === _this.props.tab.share_id
      )
      if (share) {
        if (!rootNode) {
          title = share.title
            .replace(Keys.urlRegex, Keys.urlReplacer)
            .replace(/\s$/, '&nbsp;')
            .replace(/;$/, '')
            .replace(Keys.linkRegex, Keys.linkReplacer)
            .replace(/\s$/, '&nbsp;')
        }
      }
    }

    var body =
      this.props.node.format === 'wysiwyg'
        ? this.props.node.body
        : this.state.parsedBody
    var currentNode

    // created at and updated at
    let nodeActivityInfo = <div />
    if (this.props.node.body && this.props.createdAt !== this.props.updatedAt) {
      nodeActivityInfo = (
        <span>
          <span className='created-time'>{this.props.createdAt} </span>
          <span className='updated-time'>
            {' '}
            &mdash; Updated {this.props.updatedAt}
          </span>
        </span>
      )
    }

    switch (this.props.node.type) {
      case 'bookmark':
        title = (
          <a
            href={this.props.node.url}
            target='_blank'
            rel='noopener noreferrer'
          >
            {title}
          </a>
        )
        var embedCode
        var possibleEmbedCode = Misc.parseMarkdownMedia(
          this.props.node.url,
          true
        )

        // console.log('possibleEmbedCode:', possibleEmbedCode);
        if (possibleEmbedCode !== this.props.node.url) {
          embedCode = (
            <div
              key={'emb-' + Math.random()}
              className='embedded'
              dangerouslySetInnerHTML={{ __html: possibleEmbedCode }}
            />
          )
        }
        // console.log(embedCode);
        currentNode = (
          <div>
            {toc}
            <div className='node' data-node-id={this.props.node.id}>
              <h2 className='node-heading'>
                {this.contextIcon(containerClasses)}
                <span onDoubleClick={this.expandOrCollapse}>{title}</span>
              </h2>
              <NodeMeta
                app={this.props.app}
                node={this.props.node}
                user={this.props.user}
                org={this.props.org}
                tab={this.props.tab}
                isReadOnly={isReadOnly}
              />
            </div>
            <div className='heading-url'>{this.props.node.url}</div>
            <div className='heading-description'>
              {this.props.node.description}
            </div>
            <div className='dates'>{nodeActivityInfo}</div>
            {embedCode}
            <div id='note-body' className={bodyClasses} />
            {dots}
          </div>
        )
        break

      default:
        currentNode = (
          <div>
            {toc}
            <div className='node' data-node-id={this.props.node.id}>
              <h2 className='node-heading'>
                {this.contextIcon(containerClasses)}
                <span
                  className='heading'
                  contentEditable='true'
                  onDoubleClick={this.expandOrCollapse}
                  onKeyDown={this.handleHeadingKeyDown}
                  onKeyUp={this.handleHeadingKeyUp}
                  onFocus={this.handleHeadingFocus}
                  onBlur={this.handleHeadingBlur}
                  dangerouslySetInnerHTML={{ __html: title }}
                />
              </h2>
              <NodeMeta
                app={this.props.app}
                node={this.props.node}
                user={this.props.user}
                org={this.props.org}
                tab={this.props.tab}
                isReadOnly={isReadOnly}
              />
            </div>
            {description && (
              <div
                className='description current-node'
                contentEditable='true'
                onKeyDown={this.handleDescriptionKeyDown}
                onKeyUp={this.handleDescriptionKeyUp}
                onFocus={this.handleDescriptionFocus}
                onBlur={this.handleDescriptionBlur}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}
            <div className='dates'>{nodeActivityInfo}</div>
            <NodeTypeData
              app={this.props.app}
              node={this.props.node}
              isReadOnly={isReadOnly}
            />
            <NodeTypeSpecifics
              app={this.props.app}
              node={this.props.node}
              isReadOnly={isReadOnly}
            />
            <div
              id='note-body'
              className={bodyClasses}
              dangerouslySetInnerHTML={{ __html: body }}
            />
            {dots}
          </div>
        )
        break
    }
    return (
      <div id='body-container' className={containerClasses} key={this.key}>
        {currentNode}
      </div>
    )
  }
}

module.exports = CurrentNode
