module.exports = {
  thumbsup: '\u{1F44D}',
  like: '\u2764'
}

var xx = { // eslint-disable-line no-unused-vars
  'grinning face with smiling eyes': '\u{1f601}',
  'face with tears of joy': '\u{1f602}',
  'smiling face with open mouth': '\u{1f603}',
  'smiling face with open mouth and smiling eyes': '\u{1f604}',
  'smiling face with open mouth and cold sweat': '\u{1f605}',
  'smiling face with open mouth and tightly-closed eyes': '\u{1f606}',
  'winking face': '\u{1f609}',
  'smiling face with smiling eyes': '\u{1f60a}',
  'face savouring delicious food': '\u{1f60b}',
  'relieved face': '\u{1f60c}',
  'smiling face with heart-shaped eyes': '\u{1f60d}',
  'smirking face': '\u{1f60f}',
  'unamused face': '\u{1f612}',
  'face with cold sweat': '\u{1f613}',
  'pensive face': '\u{1f614}',
  'confounded face': '\u{1f616}',
  'face throwing a kiss': '\u{1f618}',
  'kissing face with closed eyes': '\u{1f61a}',
  'face with stuck-out tongue and winking eye': '\u{1f61c}',
  'face with stuck-out tongue and tightly-closed eyes': '\u{1f61d}',
  'disappointed face': '\u{1f61e}',
  'angry face': '\u{1f620}',
  'pouting face': '\u{1f621}',
  'crying face': '\u{1f622}',
  'persevering face': '\u{1f623}',
  'face with look of triumph': '\u{1f624}',
  'disappointed but relieved face': '\u{1f625}',
  'fearful face': '\u{1f628}',
  'weary face': '\u{1f629}',
  'sleepy face': '\u{1f62a}',
  'tired face': '\u{1f62b}',
  'loudly crying face': '\u{1f62d}',
  'face with open mouth and cold sweat': '\u{1f630}',
  'face screaming in fear': '\u{1f631}',
  'astonished face': '\u{1f632}',
  'flushed face': '\u{1f633}',
  'dizzy face': '\u{1f635}',
  'face with medical mask': '\u{1f637}',
  'grinning cat face with smiling eyes': '\u{1f638}',
  'cat face with tears of joy': '\u{1f639}',
  'smiling cat face with open mouth': '\u{1f63a}',
  'smiling cat face with heart-shaped eyes': '\u{1f63b}',
  'cat face with wry smile': '\u{1f63c}',
  'kissing cat face with closed eyes': '\u{1f63d}',
  'pouting cat face': '\u{1f63e}',
  'crying cat face': '\u{1f63f}',
  'weary cat face': '\u{1f640}',
  'face with no good gesture': '\u{1f645}',
  'face with ok gesture': '\u{1f646}',
  'person bowing deeply': '\u{1f647}',
  'see-no-evil monkey': '\u{1f648}',
  'hear-no-evil monkey': '\u{1f649}',
  'speak-no-evil monkey': '\u{1f64a}',
  'happy person raising one hand': '\u{1f64b}',
  'person raising both hands in celebration': '\u{1f64c}',
  'person frowning': '\u{1f64d}',
  'person with pouting face': '\u{1f64e}',
  'person with folded hands': '\u{1f64f}',
  'black scissors': '\u{2702}',
  'white heavy check mark': '\u{2705}',
  'airplane': '\u{2708}',
  'envelope': '\u{2709}',
  'raised fist': '\u{270a}',
  'raised hand': '\u{270b}',
  'victory hand': '\u{270c}',
  'pencil': '\u{270f}',
  'black nib': '\u{2712}',
  'heavy check mark': '\u{2714}',
  'heavy multiplication x': '\u{2716}',
  'sparkles': '\u{2728}',
  'eight spoked asterisk': '\u{2733}',
  'eight pointed black star': '\u{2734}',
  'snowflake': '\u{2744}',
  'sparkle': '\u{2747}',
  'cross mark': '\u{274c}',
  'negative squared cross mark': '\u{274e}',
  'black question mark ornament': '\u{2753}',
  'white question mark ornament': '\u{2754}',
  'white exclamation mark ornament': '\u{2755}',
  'heavy exclamation mark symbol': '\u{2757}',
  'heavy black heart': '\u{2764}',
  'heavy plus sign': '\u{2795}',
  'heavy minus sign': '\u{2796}',
  'heavy division sign': '\u{2797}',
  'black rightwards arrow': '\u{27a1}',
  'curly loop': '\u{27b0}',
  'rocket': '\u{1f680}',
  'railway car': '\u{1f683}',
  'high-speed train': '\u{1f684}',
  'high-speed train with bullet nose': '\u{1f685}',
  'metro': '\u{1f687}',
  'station': '\u{1f689}',
  'bus': '\u{1f68c}',
  'bus stop': '\u{1f68f}',
  'ambulance': '\u{1f691}',
  'fire engine': '\u{1f692}',
  'police car': '\u{1f693}',
  'taxi': '\u{1f695}',
  'automobile': '\u{1f697}',
  'recreational vehicle': '\u{1f699}',
  'delivery truck': '\u{1f69a}',
  'ship': '\u{1f6a2}',
  'speedboat': '\u{1f6a4}',
  'horizontal traffic light': '\u{1f6a5}',
  'construction sign': '\u{1f6a7}',
  'police cars revolving light': '\u{1f6a8}',
  'triangular flag on post': '\u{1f6a9}',
  'door': '\u{1f6aa}',
  'no entry sign': '\u{1f6ab}',
  'smoking symbol': '\u{1f6ac}',
  'no smoking symbol': '\u{1f6ad}',
  'bicycle': '\u{1f6b2}',
  'pedestrian': '\u{1f6b6}',
  'mens symbol': '\u{1f6b9}',
  'womens symbol': '\u{1f6ba}',
  'restroom': '\u{1f6bb}',
  'baby symbol': '\u{1f6bc}',
  'toilet': '\u{1f6bd}',
  'water closet': '\u{1f6be}',
  'bath': '\u{1f6c0}',
  'circled latin capital letter m': '\u{24c2}',
  'negative squared latin capital letter a': '\u{1f170}',
  'negative squared latin capital letter b': '\u{1f171}',
  'negative squared latin capital letter o': '\u{1f17e}',
  'negative squared latin capital letter p': '\u{1f17f}',
  'negative squared ab': '\u{1f18e}',
  'squared cl': '\u{1f191}',
  'squared cool': '\u{1f192}',
  'squared free': '\u{1f193}',
  'squared id': '\u{1f194}',
  'squared new': '\u{1f195}',
  'squared ng': '\u{1f196}',
  'squared ok': '\u{1f197}',
  'squared sos': '\u{1f198}',
  'squared up with exclamation mark': '\u{1f199}',
  'squared vs': '\u{1f19a}',
  // 'regional indicator symbol letter d + regional indicator symbol letter e': '\u{1f1e9 u+1f1ea}',
  // 'regional indicator symbol letter g + regional indicator symbol letter b': '\u{1f1ec u+1f1e7}',
  // 'regional indicator symbol letter c + regional indicator symbol letter n': '\u{1f1e8 u+1f1f3}',
  // 'regional indicator symbol letter j + regional indicator symbol letter p': '\u{1f1ef u+1f1f5}',
  // 'regional indicator symbol letter k + regional indicator symbol letter r': '\u{1f1f0 u+1f1f7}',
  // 'regional indicator symbol letter f + regional indicator symbol letter r': '\u{1f1eb u+1f1f7}',
  // 'regional indicator symbol letter e + regional indicator symbol letter s': '\u{1f1ea u+1f1f8}',
  // 'regional indicator symbol letter i + regional indicator symbol letter t': '\u{1f1ee u+1f1f9}',
  // 'regional indicator symbol letter u + regional indicator symbol letter s': '\u{1f1fa u+1f1f8}',
  // 'regional indicator symbol letter r + regional indicator symbol letter u': '\u{1f1f7 u+1f1fa}',
  'squared katakana koko': '\u{1f201}',
  'squared katakana sa': '\u{1f202}',
  'squared cjk unified ideograph-7121': '\u{1f21a}',
  'squared cjk unified ideograph-6307': '\u{1f22f}',
  'squared cjk unified ideograph-7981': '\u{1f232}',
  'squared cjk unified ideograph-7a7a': '\u{1f233}',
  'squared cjk unified ideograph-5408': '\u{1f234}',
  'squared cjk unified ideograph-6e80': '\u{1f235}',
  'squared cjk unified ideograph-6709': '\u{1f236}',
  'squared cjk unified ideograph-6708': '\u{1f237}',
  'squared cjk unified ideograph-7533': '\u{1f238}',
  'squared cjk unified ideograph-5272': '\u{1f239}',
  'squared cjk unified ideograph-55b6': '\u{1f23a}',
  'circled ideograph advantage': '\u{1f250}',
  'circled ideograph accept': '\u{1f251}',
  'copyright sign': '\u{00a9}',
  'registered sign': '\u{00ae}',
  'double exclamation mark': '\u{203c}',
  'exclamation question mark': '\u{2049}',
  // 'digit eight + combining enclosing keycap': '\u{0038 u+20e3}',
  // 'digit nine + combining enclosing keycap': '\u{0039 u+20e3}',
  // 'digit seven + combining enclosing keycap': '\u{0037 u+20e3}',
  // 'digit six + combining enclosing keycap': '\u{0036 u+20e3}',
  // 'digit one + combining enclosing keycap': '\u{0031 u+20e3}',
  // 'digit zero + combining enclosing keycap': '\u{0030 u+20e3}',
  // 'digit two + combining enclosing keycap': '\u{0032 u+20e3}',
  // 'digit three + combining enclosing keycap': '\u{0033 u+20e3}',
  // 'digit five + combining enclosing keycap': '\u{0035 u+20e3}',
  // 'digit four + combining enclosing keycap': '\u{0034 u+20e3}',
  // 'number sign + combining enclosing keycap': '\u{0023 u+20e3}',
  'trade mark sign': '\u{2122}',
  'information source': '\u{2139}',
  'left right arrow': '\u{2194}',
  'up down arrow': '\u{2195}',
  'north west arrow': '\u{2196}',
  'north east arrow': '\u{2197}',
  'south east arrow': '\u{2198}',
  'south west arrow': '\u{2199}',
  'leftwards arrow with hook': '\u{21a9}',
  'rightwards arrow with hook': '\u{21aa}',
  'watch': '\u{231a}',
  'hourglass': '\u{231b}',
  'black right-pointing double triangle': '\u{23e9}',
  'black left-pointing double triangle': '\u{23ea}',
  'black up-pointing double triangle': '\u{23eb}',
  'black down-pointing double triangle': '\u{23ec}',
  'alarm clock': '\u{23f0}',
  'hourglass with flowing sand': '\u{23f3}',
  'black small square': '\u{25aa}',
  'white small square': '\u{25ab}',
  'black right-pointing triangle': '\u{25b6}',
  'black left-pointing triangle': '\u{25c0}',
  'white medium square': '\u{25fb}',
  'black medium square': '\u{25fc}',
  'white medium small square': '\u{25fd}',
  'black medium small square': '\u{25fe}',
  'black sun with rays': '\u{2600}',
  'cloud': '\u{2601}',
  'black telephone': '\u{260e}',
  'ballot box with check': '\u{2611}',
  'umbrella with rain drops': '\u{2614}',
  'hot beverage': '\u{2615}',
  'white up pointing index': '\u{261d}',
  'white smiling face': '\u{263a}',
  'aries': '\u{2648}',
  'taurus': '\u{2649}',
  'gemini': '\u{264a}',
  'cancer': '\u{264b}',
  'leo': '\u{264c}',
  'virgo': '\u{264d}',
  'libra': '\u{264e}',
  'scorpius': '\u{264f}',
  'sagittarius': '\u{2650}',
  'capricorn': '\u{2651}',
  'aquarius': '\u{2652}',
  'pisces': '\u{2653}',
  'black spade suit': '\u{2660}',
  'black club suit': '\u{2663}',
  'black heart suit': '\u{2665}',
  'black diamond suit': '\u{2666}',
  'hot springs': '\u{2668}',
  'black universal recycling symbol': '\u{267b}',
  'wheelchair symbol': '\u{267f}',
  'anchor': '\u{2693}',
  'warning sign': '\u{26a0}',
  'high voltage sign': '\u{26a1}',
  'medium white circle': '\u{26aa}',
  'medium black circle': '\u{26ab}',
  'soccer ball': '\u{26bd}',
  'baseball': '\u{26be}',
  'snowman without snow': '\u{26c4}',
  'sun behind cloud': '\u{26c5}',
  'ophiuchus': '\u{26ce}',
  'no entry': '\u{26d4}',
  'church': '\u{26ea}',
  'fountain': '\u{26f2}',
  'flag in hole': '\u{26f3}',
  'sailboat': '\u{26f5}',
  'tent': '\u{26fa}',
  'fuel pump': '\u{26fd}',
  'arrow pointing rightwards then curving upwards': '\u{2934}',
  'arrow pointing rightwards then curving downwards': '\u{2935}',
  'leftwards black arrow': '\u{2b05}',
  'upwards black arrow': '\u{2b06}',
  'downwards black arrow': '\u{2b07}',
  'black large square': '\u{2b1b}',
  'white large square': '\u{2b1c}',
  'white medium star': '\u{2b50}',
  'heavy large circle': '\u{2b55}',
  'wavy dash': '\u{3030}',
  'part alternation mark': '\u{303d}',
  'circled ideograph congratulation': '\u{3297}',
  'circled ideograph secret': '\u{3299}',
  'mahjong tile red dragon': '\u{1f004}',
  'playing card black joker': '\u{1f0cf}',
  'cyclone': '\u{1f300}',
  'foggy': '\u{1f301}',
  'closed umbrella': '\u{1f302}',
  'night with stars': '\u{1f303}',
  'sunrise over mountains': '\u{1f304}',
  'sunrise': '\u{1f305}',
  'cityscape at dusk': '\u{1f306}',
  'sunset over buildings': '\u{1f307}',
  'rainbow': '\u{1f308}',
  'bridge at night': '\u{1f309}',
  'water wave': '\u{1f30a}',
  'volcano': '\u{1f30b}',
  'milky way': '\u{1f30c}',
  'earth globe asia-australia': '\u{1f30f}',
  'new moon symbol': '\u{1f311}',
  'first quarter moon symbol': '\u{1f313}',
  'waxing gibbous moon symbol': '\u{1f314}',
  'full moon symbol': '\u{1f315}',
  'crescent moon': '\u{1f319}',
  'first quarter moon with face': '\u{1f31b}',
  'glowing star': '\u{1f31f}',
  'shooting star': '\u{1f320}',
  'chestnut': '\u{1f330}',
  'seedling': '\u{1f331}',
  'palm tree': '\u{1f334}',
  'cactus': '\u{1f335}',
  'tulip': '\u{1f337}',
  'cherry blossom': '\u{1f338}',
  'rose': '\u{1f339}',
  'hibiscus': '\u{1f33a}',
  'sunflower': '\u{1f33b}',
  'blossom': '\u{1f33c}',
  'ear of maize': '\u{1f33d}',
  'ear of rice': '\u{1f33e}',
  'herb': '\u{1f33f}',
  'four leaf clover': '\u{1f340}',
  'maple leaf': '\u{1f341}',
  'fallen leaf': '\u{1f342}',
  'leaf fluttering in wind': '\u{1f343}',
  'mushroom': '\u{1f344}',
  'tomato': '\u{1f345}',
  'aubergine': '\u{1f346}',
  'grapes': '\u{1f347}',
  'melon': '\u{1f348}',
  'watermelon': '\u{1f349}',
  'tangerine': '\u{1f34a}',
  'banana': '\u{1f34c}',
  'pineapple': '\u{1f34d}',
  'red apple': '\u{1f34e}',
  'green apple': '\u{1f34f}',
  'peach': '\u{1f351}',
  'cherries': '\u{1f352}',
  'strawberry': '\u{1f353}',
  'hamburger': '\u{1f354}',
  'slice of pizza': '\u{1f355}',
  'meat on bone': '\u{1f356}',
  'poultry leg': '\u{1f357}',
  'rice cracker': '\u{1f358}',
  'rice ball': '\u{1f359}',
  'cooked rice': '\u{1f35a}',
  'curry and rice': '\u{1f35b}',
  'steaming bowl': '\u{1f35c}',
  'spaghetti': '\u{1f35d}',
  'bread': '\u{1f35e}',
  'french fries': '\u{1f35f}',
  'roasted sweet potato': '\u{1f360}',
  'dango': '\u{1f361}',
  'oden': '\u{1f362}',
  'sushi': '\u{1f363}',
  'fried shrimp': '\u{1f364}',
  'fish cake with swirl design': '\u{1f365}',
  'soft ice cream': '\u{1f366}',
  'shaved ice': '\u{1f367}',
  'ice cream': '\u{1f368}',
  'doughnut': '\u{1f369}',
  'cookie': '\u{1f36a}',
  'chocolate bar': '\u{1f36b}',
  'candy': '\u{1f36c}',
  'lollipop': '\u{1f36d}',
  'custard': '\u{1f36e}',
  'honey pot': '\u{1f36f}',
  'shortcake': '\u{1f370}',
  'bento box': '\u{1f371}',
  'pot of food': '\u{1f372}',
  'cooking': '\u{1f373}',
  'fork and knife': '\u{1f374}',
  'teacup without handle': '\u{1f375}',
  'sake bottle and cup': '\u{1f376}',
  'wine glass': '\u{1f377}',
  'cocktail glass': '\u{1f378}',
  'tropical drink': '\u{1f379}',
  'beer mug': '\u{1f37a}',
  'clinking beer mugs': '\u{1f37b}',
  'ribbon': '\u{1f380}',
  'wrapped present': '\u{1f381}',
  'birthday cake': '\u{1f382}',
  'jack-o-lantern': '\u{1f383}',
  'christmas tree': '\u{1f384}',
  'father christmas': '\u{1f385}',
  'fireworks': '\u{1f386}',
  'firework sparkler': '\u{1f387}',
  'balloon': '\u{1f388}',
  'party popper': '\u{1f389}',
  'confetti ball': '\u{1f38a}',
  'tanabata tree': '\u{1f38b}',
  'crossed flags': '\u{1f38c}',
  'pine decoration': '\u{1f38d}',
  'japanese dolls': '\u{1f38e}',
  'carp streamer': '\u{1f38f}',
  'wind chime': '\u{1f390}',
  'moon viewing ceremony': '\u{1f391}',
  'school satchel': '\u{1f392}',
  'graduation cap': '\u{1f393}',
  'carousel horse': '\u{1f3a0}',
  'ferris wheel': '\u{1f3a1}',
  'roller coaster': '\u{1f3a2}',
  'fishing pole and fish': '\u{1f3a3}',
  'microphone': '\u{1f3a4}',
  'movie camera': '\u{1f3a5}',
  'cinema': '\u{1f3a6}',
  'headphone': '\u{1f3a7}',
  'artist palette': '\u{1f3a8}',
  'top hat': '\u{1f3a9}',
  'circus tent': '\u{1f3aa}',
  'ticket': '\u{1f3ab}',
  'clapper board': '\u{1f3ac}',
  'performing arts': '\u{1f3ad}',
  'video game': '\u{1f3ae}',
  'direct hit': '\u{1f3af}',
  'slot machine': '\u{1f3b0}',
  'billiards': '\u{1f3b1}',
  'game die': '\u{1f3b2}',
  'bowling': '\u{1f3b3}',
  'flower playing cards': '\u{1f3b4}',
  'musical note': '\u{1f3b5}',
  'multiple musical notes': '\u{1f3b6}',
  'saxophone': '\u{1f3b7}',
  'guitar': '\u{1f3b8}',
  'musical keyboard': '\u{1f3b9}',
  'trumpet': '\u{1f3ba}',
  'violin': '\u{1f3bb}',
  'musical score': '\u{1f3bc}',
  'running shirt with sash': '\u{1f3bd}',
  'tennis racquet and ball': '\u{1f3be}',
  'ski and ski boot': '\u{1f3bf}',
  'basketball and hoop': '\u{1f3c0}',
  'chequered flag': '\u{1f3c1}',
  'snowboarder': '\u{1f3c2}',
  'runner': '\u{1f3c3}',
  'surfer': '\u{1f3c4}',
  'trophy': '\u{1f3c6}',
  'american football': '\u{1f3c8}',
  'swimmer': '\u{1f3ca}',
  'house building': '\u{1f3e0}',
  'house with garden': '\u{1f3e1}',
  'office building': '\u{1f3e2}',
  'japanese post office': '\u{1f3e3}',
  'hospital': '\u{1f3e5}',
  'bank': '\u{1f3e6}',
  'automated teller machine': '\u{1f3e7}',
  'hotel': '\u{1f3e8}',
  'love hotel': '\u{1f3e9}',
  'convenience store': '\u{1f3ea}',
  'school': '\u{1f3eb}',
  'department store': '\u{1f3ec}',
  'factory': '\u{1f3ed}',
  'izakaya lantern': '\u{1f3ee}',
  'japanese castle': '\u{1f3ef}',
  'european castle': '\u{1f3f0}',
  'snail': '\u{1f40c}',
  'snake': '\u{1f40d}',
  'horse': '\u{1f40e}',
  'sheep': '\u{1f411}',
  'monkey': '\u{1f412}',
  'chicken': '\u{1f414}',
  'boar': '\u{1f417}',
  'elephant': '\u{1f418}',
  'octopus': '\u{1f419}',
  'spiral shell': '\u{1f41a}',
  'bug': '\u{1f41b}',
  'ant': '\u{1f41c}',
  'honeybee': '\u{1f41d}',
  'lady beetle': '\u{1f41e}',
  'fish': '\u{1f41f}',
  'tropical fish': '\u{1f420}',
  'blowfish': '\u{1f421}',
  'turtle': '\u{1f422}',
  'hatching chick': '\u{1f423}',
  'baby chick': '\u{1f424}',
  'front-facing baby chick': '\u{1f425}',
  'bird': '\u{1f426}',
  'penguin': '\u{1f427}',
  'koala': '\u{1f428}',
  'poodle': '\u{1f429}',
  'bactrian camel': '\u{1f42b}',
  'dolphin': '\u{1f42c}',
  'mouse face': '\u{1f42d}',
  'cow face': '\u{1f42e}',
  'tiger face': '\u{1f42f}',
  'rabbit face': '\u{1f430}',
  'cat face': '\u{1f431}',
  'dragon face': '\u{1f432}',
  'spouting whale': '\u{1f433}',
  'horse face': '\u{1f434}',
  'monkey face': '\u{1f435}',
  'dog face': '\u{1f436}',
  'pig face': '\u{1f437}',
  'frog face': '\u{1f438}',
  'hamster face': '\u{1f439}',
  'wolf face': '\u{1f43a}',
  'bear face': '\u{1f43b}',
  'panda face': '\u{1f43c}',
  'pig nose': '\u{1f43d}',
  'paw prints': '\u{1f43e}',
  'eyes': '\u{1f440}',
  'ear': '\u{1f442}',
  'nose': '\u{1f443}',
  'mouth': '\u{1f444}',
  'tongue': '\u{1f445}',
  'white up pointing backhand index': '\u{1f446}',
  'white down pointing backhand index': '\u{1f447}',
  'white left pointing backhand index': '\u{1f448}',
  'white right pointing backhand index': '\u{1f449}',
  'fisted hand sign': '\u{1f44a}',
  'waving hand sign': '\u{1f44b}',
  'ok hand sign': '\u{1f44c}',
  'thumbs up sign': '\u{1f44d}',
  'thumbs down sign': '\u{1f44e}',
  'clapping hands sign': '\u{1f44f}',
  'open hands sign': '\u{1f450}',
  'crown': '\u{1f451}',
  'womans hat': '\u{1f452}',
  'eyeglasses': '\u{1f453}',
  'necktie': '\u{1f454}',
  't-shirt': '\u{1f455}',
  'jeans': '\u{1f456}',
  'dress': '\u{1f457}',
  'kimono': '\u{1f458}',
  'bikini': '\u{1f459}',
  'womans clothes': '\u{1f45a}',
  'purse': '\u{1f45b}',
  'handbag': '\u{1f45c}',
  'pouch': '\u{1f45d}',
  'mans shoe': '\u{1f45e}',
  'athletic shoe': '\u{1f45f}',
  'high-heeled shoe': '\u{1f460}',
  'womans sandal': '\u{1f461}',
  'womans boots': '\u{1f462}',
  'footprints': '\u{1f463}',
  'bust in silhouette': '\u{1f464}',
  'boy': '\u{1f466}',
  'girl': '\u{1f467}',
  'man': '\u{1f468}',
  'woman': '\u{1f469}',
  'family': '\u{1f46a}',
  'man and woman holding hands': '\u{1f46b}',
  'police officer': '\u{1f46e}',
  'woman with bunny ears': '\u{1f46f}',
  'bride with veil': '\u{1f470}',
  'person with blond hair': '\u{1f471}',
  'man with gua pi mao': '\u{1f472}',
  'man with turban': '\u{1f473}',
  'older man': '\u{1f474}',
  'older woman': '\u{1f475}',
  'baby': '\u{1f476}',
  'construction worker': '\u{1f477}',
  'princess': '\u{1f478}',
  'japanese ogre': '\u{1f479}',
  'japanese goblin': '\u{1f47a}',
  'ghost': '\u{1f47b}',
  'baby angel': '\u{1f47c}',
  'extraterrestrial alien': '\u{1f47d}',
  'alien monster': '\u{1f47e}',
  'imp': '\u{1f47f}',
  'skull': '\u{1f480}',
  'information desk person': '\u{1f481}',
  'guardsman': '\u{1f482}',
  'dancer': '\u{1f483}',
  'lipstick': '\u{1f484}',
  'nail polish': '\u{1f485}',
  'face massage': '\u{1f486}',
  'haircut': '\u{1f487}',
  'barber pole': '\u{1f488}',
  'syringe': '\u{1f489}',
  'pill': '\u{1f48a}',
  'kiss mark': '\u{1f48b}',
  'love letter': '\u{1f48c}',
  'ring': '\u{1f48d}',
  'gem stone': '\u{1f48e}',
  'kiss': '\u{1f48f}',
  'bouquet': '\u{1f490}',
  'couple with heart': '\u{1f491}',
  'wedding': '\u{1f492}',
  'beating heart': '\u{1f493}',
  'broken heart': '\u{1f494}',
  'two hearts': '\u{1f495}',
  'sparkling heart': '\u{1f496}',
  'growing heart': '\u{1f497}',
  'heart with arrow': '\u{1f498}',
  'blue heart': '\u{1f499}',
  'green heart': '\u{1f49a}',
  'yellow heart': '\u{1f49b}',
  'purple heart': '\u{1f49c}',
  'heart with ribbon': '\u{1f49d}',
  'revolving hearts': '\u{1f49e}',
  'heart decoration': '\u{1f49f}',
  'diamond shape with a dot inside': '\u{1f4a0}',
  'electric light bulb': '\u{1f4a1}',
  'anger symbol': '\u{1f4a2}',
  'bomb': '\u{1f4a3}',
  'sleeping symbol': '\u{1f4a4}',
  'collision symbol': '\u{1f4a5}',
  'splashing sweat symbol': '\u{1f4a6}',
  'droplet': '\u{1f4a7}',
  'dash symbol': '\u{1f4a8}',
  'pile of poo': '\u{1f4a9}',
  'flexed biceps': '\u{1f4aa}',
  'dizzy symbol': '\u{1f4ab}',
  'speech balloon': '\u{1f4ac}',
  'white flower': '\u{1f4ae}',
  'hundred points symbol': '\u{1f4af}',
  'money bag': '\u{1f4b0}',
  'currency exchange': '\u{1f4b1}',
  'heavy dollar sign': '\u{1f4b2}',
  'credit card': '\u{1f4b3}',
  'banknote with yen sign': '\u{1f4b4}',
  'banknote with dollar sign': '\u{1f4b5}',
  'money with wings': '\u{1f4b8}',
  'chart with upwards trend and yen sign': '\u{1f4b9}',
  'seat': '\u{1f4ba}',
  'personal computer': '\u{1f4bb}',
  'briefcase': '\u{1f4bc}',
  'minidisc': '\u{1f4bd}',
  'floppy disk': '\u{1f4be}',
  'optical disc': '\u{1f4bf}',
  'dvd': '\u{1f4c0}',
  'file folder': '\u{1f4c1}',
  'open file folder': '\u{1f4c2}',
  'page with curl': '\u{1f4c3}',
  'page facing up': '\u{1f4c4}',
  'calendar': '\u{1f4c5}',
  'tear-off calendar': '\u{1f4c6}',
  'card index': '\u{1f4c7}',
  'chart with upwards trend': '\u{1f4c8}',
  'chart with downwards trend': '\u{1f4c9}',
  'bar chart': '\u{1f4ca}',
  'clipboard': '\u{1f4cb}',
  'pushpin': '\u{1f4cc}',
  'round pushpin': '\u{1f4cd}',
  'paperclip': '\u{1f4ce}',
  'straight ruler': '\u{1f4cf}',
  'triangular ruler': '\u{1f4d0}',
  'bookmark tabs': '\u{1f4d1}',
  'ledger': '\u{1f4d2}',
  'notebook': '\u{1f4d3}',
  'notebook with decorative cover': '\u{1f4d4}',
  'closed book': '\u{1f4d5}',
  'open book': '\u{1f4d6}',
  'green book': '\u{1f4d7}',
  'blue book': '\u{1f4d8}',
  'orange book': '\u{1f4d9}',
  'books': '\u{1f4da}',
  'name badge': '\u{1f4db}',
  'scroll': '\u{1f4dc}',
  'memo': '\u{1f4dd}',
  'telephone receiver': '\u{1f4de}',
  'pager': '\u{1f4df}',
  'fax machine': '\u{1f4e0}',
  'satellite antenna': '\u{1f4e1}',
  'public address loudspeaker': '\u{1f4e2}',
  'cheering megaphone': '\u{1f4e3}',
  'outbox tray': '\u{1f4e4}',
  'inbox tray': '\u{1f4e5}',
  'package': '\u{1f4e6}',
  'e-mail symbol': '\u{1f4e7}',
  'incoming envelope': '\u{1f4e8}',
  'envelope with downwards arrow above': '\u{1f4e9}',
  'closed mailbox with lowered flag': '\u{1f4ea}',
  'closed mailbox with raised flag': '\u{1f4eb}',
  'postbox': '\u{1f4ee}',
  'newspaper': '\u{1f4f0}',
  'mobile phone': '\u{1f4f1}',
  'mobile phone with rightwards arrow at left': '\u{1f4f2}',
  'vibration mode': '\u{1f4f3}',
  'mobile phone off': '\u{1f4f4}',
  'antenna with bars': '\u{1f4f6}',
  'camera': '\u{1f4f7}',
  'video camera': '\u{1f4f9}',
  'television': '\u{1f4fa}',
  'radio': '\u{1f4fb}',
  'videocassette': '\u{1f4fc}',
  'clockwise downwards and upwards open circle arrows': '\u{1f503}',
  'speaker with three sound waves': '\u{1f50a}',
  'battery': '\u{1f50b}',
  'electric plug': '\u{1f50c}',
  'left-pointing magnifying glass': '\u{1f50d}',
  'right-pointing magnifying glass': '\u{1f50e}',
  'lock with ink pen': '\u{1f50f}',
  'closed lock with key': '\u{1f510}',
  'key': '\u{1f511}',
  'lock': '\u{1f512}',
  'open lock': '\u{1f513}',
  'bell': '\u{1f514}',
  'bookmark': '\u{1f516}',
  'link symbol': '\u{1f517}',
  'radio button': '\u{1f518}',
  'back with leftwards arrow above': '\u{1f519}',
  'end with leftwards arrow above': '\u{1f51a}',
  'on with exclamation mark with left right arrow above': '\u{1f51b}',
  'soon with rightwards arrow above': '\u{1f51c}',
  'top with upwards arrow above': '\u{1f51d}',
  'no one under eighteen symbol': '\u{1f51e}',
  'keycap ten': '\u{1f51f}',
  'input symbol for latin capital letters': '\u{1f520}',
  'input symbol for latin small letters': '\u{1f521}',
  'input symbol for numbers': '\u{1f522}',
  'input symbol for symbols': '\u{1f523}',
  'input symbol for latin letters': '\u{1f524}',
  'fire': '\u{1f525}',
  'electric torch': '\u{1f526}',
  'wrench': '\u{1f527}',
  'hammer': '\u{1f528}',
  'nut and bolt': '\u{1f529}',
  'hocho': '\u{1f52a}',
  'pistol': '\u{1f52b}',
  'crystal ball': '\u{1f52e}',
  'six pointed star with middle dot': '\u{1f52f}',
  'japanese symbol for beginner': '\u{1f530}',
  'trident emblem': '\u{1f531}',
  'black square button': '\u{1f532}',
  'white square button': '\u{1f533}',
  'large red circle': '\u{1f534}',
  'large blue circle': '\u{1f535}',
  'large orange diamond': '\u{1f536}',
  'large blue diamond': '\u{1f537}',
  'small orange diamond': '\u{1f538}',
  'small blue diamond': '\u{1f539}',
  'up-pointing red triangle': '\u{1f53a}',
  'down-pointing red triangle': '\u{1f53b}',
  'up-pointing small red triangle': '\u{1f53c}',
  'down-pointing small red triangle': '\u{1f53d}',
  'clock face one oclock': '\u{1f550}',
  'clock face two oclock': '\u{1f551}',
  'clock face three oclock': '\u{1f552}',
  'clock face four oclock': '\u{1f553}',
  'clock face five oclock': '\u{1f554}',
  'clock face six oclock': '\u{1f555}',
  'clock face seven oclock': '\u{1f556}',
  'clock face eight oclock': '\u{1f557}',
  'clock face nine oclock': '\u{1f558}',
  'clock face ten oclock': '\u{1f559}',
  'clock face eleven oclock': '\u{1f55a}',
  'clock face twelve oclock': '\u{1f55b}',
  'mount fuji': '\u{1f5fb}',
  'tokyo tower': '\u{1f5fc}',
  'statue of liberty': '\u{1f5fd}',
  'silhouette of japan': '\u{1f5fe}',
  'moyai': '\u{1f5ff}',
  'grinning face': '\u{1f600}',
  'smiling face with halo': '\u{1f607}',
  'smiling face with horns': '\u{1f608}',
  'smiling face with sunglasses': '\u{1f60e}',
  'neutral face': '\u{1f610}',
  'expressionless face': '\u{1f611}',
  'confused face': '\u{1f615}',
  'kissing face': '\u{1f617}',
  'kissing face with smiling eyes': '\u{1f619}',
  'face with stuck-out tongue': '\u{1f61b}',
  'worried face': '\u{1f61f}',
  'frowning face with open mouth': '\u{1f626}',
  'anguished face': '\u{1f627}',
  'grimacing face': '\u{1f62c}',
  'face with open mouth': '\u{1f62e}',
  'hushed face': '\u{1f62f}',
  'sleeping face': '\u{1f634}',
  'face without mouth': '\u{1f636}',
  'helicopter': '\u{1f681}',
  'steam locomotive': '\u{1f682}',
  'train': '\u{1f686}',
  'light rail': '\u{1f688}',
  'tram': '\u{1f68a}',
  'oncoming bus': '\u{1f68d}',
  'trolleybus': '\u{1f68e}',
  'minibus': '\u{1f690}',
  'oncoming police car': '\u{1f694}',
  'oncoming taxi': '\u{1f696}',
  'oncoming automobile': '\u{1f698}',
  'articulated lorry': '\u{1f69b}',
  'tractor': '\u{1f69c}',
  'monorail': '\u{1f69d}',
  'mountain railway': '\u{1f69e}',
  'suspension railway': '\u{1f69f}',
  'mountain cableway': '\u{1f6a0}',
  'aerial tramway': '\u{1f6a1}',
  'rowboat': '\u{1f6a3}',
  'vertical traffic light': '\u{1f6a6}',
  'put litter in its place symbol': '\u{1f6ae}',
  'do not litter symbol': '\u{1f6af}',
  'potable water symbol': '\u{1f6b0}',
  'non-potable water symbol': '\u{1f6b1}',
  'no bicycles': '\u{1f6b3}',
  'bicyclist': '\u{1f6b4}',
  'mountain bicyclist': '\u{1f6b5}',
  'no pedestrians': '\u{1f6b7}',
  'children crossing': '\u{1f6b8}',
  'shower': '\u{1f6bf}',
  'bathtub': '\u{1f6c1}',
  'passport control': '\u{1f6c2}',
  'customs': '\u{1f6c3}',
  'baggage claim': '\u{1f6c4}',
  'left luggage': '\u{1f6c5}',
  'earth globe europe-africa': '\u{1f30d}',
  'earth globe americas': '\u{1f30e}',
  'globe with meridians': '\u{1f310}',
  'waxing crescent moon symbol': '\u{1f312}',
  'waning gibbous moon symbol': '\u{1f316}',
  'last quarter moon symbol': '\u{1f317}',
  'waning crescent moon symbol': '\u{1f318}',
  'new moon with face': '\u{1f31a}',
  'last quarter moon with face': '\u{1f31c}',
  'full moon with face': '\u{1f31d}',
  'sun with face': '\u{1f31e}',
  'evergreen tree': '\u{1f332}',
  'deciduous tree': '\u{1f333}',
  'lemon': '\u{1f34b}',
  'pear': '\u{1f350}',
  'baby bottle': '\u{1f37c}',
  'horse racing': '\u{1f3c7}',
  'rugby football': '\u{1f3c9}',
  'european post office': '\u{1f3e4}',
  'rat': '\u{1f400}',
  'mouse': '\u{1f401}',
  'ox': '\u{1f402}',
  'water buffalo': '\u{1f403}',
  'cow': '\u{1f404}',
  'tiger': '\u{1f405}',
  'leopard': '\u{1f406}',
  'rabbit': '\u{1f407}',
  'cat': '\u{1f408}',
  'dragon': '\u{1f409}',
  'crocodile': '\u{1f40a}',
  'whale': '\u{1f40b}',
  'ram': '\u{1f40f}',
  'goat': '\u{1f410}',
  'rooster': '\u{1f413}',
  'dog': '\u{1f415}',
  'pig': '\u{1f416}',
  'dromedary camel': '\u{1f42a}',
  'busts in silhouette': '\u{1f465}',
  'two men holding hands': '\u{1f46c}',
  'two women holding hands': '\u{1f46d}',
  'thought balloon': '\u{1f4ad}',
  'banknote with euro sign': '\u{1f4b6}',
  'banknote with pound sign': '\u{1f4b7}',
  'open mailbox with raised flag': '\u{1f4ec}',
  'open mailbox with lowered flag': '\u{1f4ed}',
  'postal horn': '\u{1f4ef}',
  'no mobile phones': '\u{1f4f5}',
  'twisted rightwards arrows': '\u{1f500}',
  'clockwise rightwards and leftwards open circle arrows': '\u{1f501}',
  'clockwise rightwards and leftwards open circle arrows with circled one overlay': '\u{1f502}',
  'anticlockwise downwards and upwards open circle arrows': '\u{1f504}',
  'low brightness symbol': '\u{1f505}',
  'high brightness symbol': '\u{1f506}',
  'speaker with cancellation stroke': '\u{1f507}',
  'speaker with one sound wave': '\u{1f509}',
  'bell with cancellation stroke': '\u{1f515}',
  'microscope': '\u{1f52c}',
  'telescope': '\u{1f52d}',
  'clock face one-thirty': '\u{1f55c}',
  'clock face two-thirty': '\u{1f55d}',
  'clock face three-thirty': '\u{1f55e}',
  'clock face four-thirty': '\u{1f55f}',
  'clock face five-thirty': '\u{1f560}',
  'clock face six-thirty': '\u{1f561}',
  'clock face seven-thirty': '\u{1f562}',
  'clock face eight-thirty': '\u{1f563}',
  'clock face nine-thirty': '\u{1f564}',
  'clock face ten-thirty': '\u{1f565}',
  'clock face eleven-thirty': '\u{1f566}',
  'clock face twelve-thirty': '\u{1f567}'
}
