import React from 'react'

import Heading from '../sections/heading'
import Avatar from '../../avatar'
// import Misc from '../../../js/misc';

import './styles.css'

export default class Filters extends React.Component {
  toggleMultiFilter = (key, value) => {
    const { app } = this.props
    const filter = app.state.filter || {}
    let keyFilter = filter[key] || []
    const newKeyFilter =
      keyFilter.indexOf(value) === -1
        ? keyFilter.concat([value])
        : keyFilter.filter(e => e !== value)
    app.setAppState({ filter: { ...filter, [key]: newKeyFilter } })
  }

  toggleExclusiveBoolean = name => {
    const { app } = this.props
    const filter = app.state.filter || {}
    const newFilter = {
      users: filter.users || [],
      labels: filter.labels || []
    }
    app.setAppState({ filter: { ...newFilter, [name]: !filter[name] } })
  }

  toggleCombinedBoolean = name => {
    const { app } = this.props
    const filter = app.state.filter || {}
    app.setAppState({ filter: { ...filter, [name]: !filter[name] } })
  }

  render () {
    const { app } = this.props
    const filter = app.state.filter || {}
    const overrides =
      (app.orgId()
        ? app.org().settings.label_overrides
        : app.userId()
          ? app.user().settings.label_overrides
          : null) || {}
    const dueFilters = [
      ['Due Today', 'isDueToday'],
      ['Due Tomorrow', 'isDueTomorrow'],
      ['Due Next Week', 'isDueNextWeek'],
      ['Due Next Month', 'isDueNextMonth'],
      ['Is Past Due', 'pastDue'],
      ['Has a Due Date/Time', 'hasDue'],
      ['Does Not Have a Due Date/Time', 'hasNoDue']
    ]

    const permissionFilters = [['Has Permissions', 'hasPermissions']]

    return (
      <div className='body full-height filters-container'>
        <Heading {...this.props} name='Filter Items' isMain />
        <div className='filters'>
          <div className='labels'>
            <h3>Labels</h3>
            {app.state.defaultLabels.slice(0, 6).map(l => {
              const override = overrides[l.id] || {}
              const label = { ...l, ...override }
              const isActive = (filter.labels || []).indexOf(l.id) !== -1
              return (
                <div
                  key={l.id}
                  onClick={() => this.toggleMultiFilter('labels', l.id)}
                  className={isActive ? 'active' : ''}
                  style={{ backgroundColor: label.bg, color: label.text }}
                >
                  <div className='main'>{label.name}</div>
                  <div>
                    <i className='fa fa-fw fa-check' />
                  </div>
                </div>
              )
            })}
          </div>
          {app.featureEnabled('assign_to') && (
            <div className='users'>
              <h3>Users</h3>
              {app.state.entities.users.map(u => {
                const isActive = (filter.users || []).indexOf(u.id) !== -1
                return (
                  <div
                    key={u.id}
                    onClick={() => this.toggleMultiFilter('users', u.id)}
                    className={isActive ? 'active' : ''}
                  >
                    <Avatar app={app} user={u} />
                    <div className='main'>
                      {u.first_name} {u.last_name}
                    </div>
                    <div>
                      <i className='fa fa-fw fa-check' />
                    </div>
                  </div>
                )
              })}
            </div>
          )}

          {app.featureEnabled('due_nodes') && (
            <div className='due normal'>
              <h3>Due</h3>
              {dueFilters.map(a => (
                <div
                  key={a[1]}
                  onClick={() => this.toggleExclusiveBoolean(a[1])}
                  className={filter[a[1]] ? 'active' : ''}
                >
                  <div className='icon'>
                    <i className='fa fa-fw fa-clock-o' />
                  </div>
                  <div className='main'>{a[0]}</div>
                  <div>
                    <i className='fa fa-fw fa-check' />
                  </div>
                </div>
              ))}
            </div>
          )}

          {app.featureEnabled('permissions') && (
            <div className='permissions normal'>
              <h3>Permissions</h3>
              {permissionFilters.map(a => (
                <div
                  key={a[1]}
                  onClick={() => this.toggleExclusiveBoolean(a[1])}
                  className={filter[a[1]] ? 'active' : ''}
                >
                  <div className='icon'>
                    <i className='fa fa-fw fa-lock' />
                  </div>
                  <div className='main'>{a[0]}</div>
                  <div>
                    <i className='fa fa-fw fa-check' />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    )
  }
}
