var update = require('react-addons-update')

var OrgUser = {

  modify: function (orgUserOrId, data, params, callback) {
    const doAction = (actionParams) => {
      const newParams = Object.assign({}, actionParams)
      const orgUsers = actionParams.updatedState.entities ? actionParams.updatedState.entities.org_users : actionParams.state.entities.org_users
      var orgUser = OrgUser.get(orgUserOrId, orgUsers)
      var newOrgUser = update(orgUser, {$merge: {updated_at: new Date()}})
      const oldData = {}
      for (var key in data) {
        var keys = key.split('.')
        var obj = newOrgUser
        for (var i = 0; i <= keys.length - 1; i++) {
          if (i === keys.length - 1) {
            obj[keys[i]] = data[key]
          }

          oldData[key] = orgUser[key]
          obj = obj[keys[i]]
        }
      }
      var trimmedOrgUsers = orgUsers.filter(e => e.id !== newOrgUser.id)
      var newOrgUsers = trimmedOrgUsers.concat([newOrgUser])

      const undoData = {
        undo: [{obj: 'OrgUser', cmd: 'modify', args: [orgUser.id, oldData]}],
        redo: [{obj: 'OrgUser', cmd: 'modify', args: [orgUser.id, data]}]
      }

      if (!actionParams._isUndoOperation && !actionParams._skipUndoAdd) {
        actionParams.undo.add(undoData, true)
      }

      const newEntities = Object.assign({}, (actionParams.updatedState.entities || actionParams.state.entities))
      newEntities.org_users = newOrgUsers
      const result = {entities: newEntities}

      newParams.updatedState = Object.assign({}, actionParams.updatedState, result)
      newParams.undoData = actionParams.undoData || {}
      newParams.undoData.undo = (newParams.undoData.undo || []).concat(undoData.undo)
      newParams.undoData.redo = (newParams.undoData.redo || []).concat(undoData.redo)

      if (actionParams._skipSetState) {
        if (typeof callback === 'function') {
          callback(newParams)
        }
      } else {
        actionParams.setAppState(newParams.updatedState, newParams.stateParams, () => {
          if (typeof callback === 'function') {
            callback(newParams)
          }
        })
      }
    }

    if (params._skipFlushUndo) {
      doAction(params)
    } else {
      params.flushTextUndo(params, ret => {
        doAction(ret)
      })
    }
  },

  get: function (orgUserOrId, orgUsers) {
    if (typeof orgUserOrId === 'string') {
      return orgUsers.find(e => e.id === orgUserOrId)
    } else {
      return orgUserOrId
    }
  }

}

module.exports = OrgUser
