import './styles.css'

import React from 'react'

class Site extends React.Component {
  render () {
    return <div>SITE</div>
  }
}

module.exports = Site
