import './styles.css'

import React from 'react'

import SetPlaceholder from '../../set_placeholder'

export default class Priority extends React.Component {
  render () {
    const { app, node } = this.props
    const data = node.data || {}
    const priority = data.priority
      ? app.state.dataPriorities.find(p => p.value === data.priority)
      : null
    return (
      <div
        onClick={ev =>
          app.toggleFloater(ev.target, 'priority-picker', {
            nodeId: node.id
          })
        }
      >
        {priority && (
          <div className='data-type priority actual-data'>
            <div style={{ backgroundColor: priority.color }} />
            <div>{priority.label}</div>
          </div>
        )}
        {!priority && <SetPlaceholder />}
      </div>
    )
  }
}
