import './styles.css'

import React from 'react'
import $ from 'jquery'
import _ from 'lodash'
import onClickOutside from 'react-onclickoutside'

import SingleNotification from '../../single_notification'
import Badge from '../../badge'

import classnames from 'classnames'

// import Misc from '../../../js/misc';
import Notification from '../../../js/notification'
import User from '../../../js/user'

const Notifications = onClickOutside(
  class extends React.Component {
    static displayName = 'Notifications'

    state = {
      open: false,
      floaters: []
    }

    handleClickOutside = () => {
      this.setState({ open: false })
    }

    handleClick = notification => {
      var _this = this
      this.removeFloater(notification, () => {
        if (_this.state.open) {
          _this.toggleOpen()
        }
      })
    }

    toggleOpen = ev => {
      var $target
      if (ev) {
        $target = $(ev.target)
      }
      if (!ev || $target.closest('#notifications').length) {
        var newState = { open: !this.state.open }
        if (!this.state.open) {
          newState.floaters = []
        }
        this.setState(newState, () => {
          if (this.state.open) {
            var lastestNotification = _.sortBy(
              this.props.notifications,
              'created_at'
            )
              .reverse()
              .slice(0, 1)[0]
            var newTime = lastestNotification
              ? lastestNotification.created_at
              : new Date()
            User.seenNotifications(newTime, this.props.app.getCommonParams())
          }
        })
      }
      ev && ev.preventDefault()
    }

    openSettings = () => {
      this.props.app.setPanel('user-settings-page', {
        data: { user: this.props.user },
        title: 'User settings',
        activeSection: 'notifications-section'
      })
    }

    seeAll = () => {
      this.setState({ open: false }, () => {
        this.props.app.setPanel('notifications-page', {
          data: { user: this.props.user },
          title: 'Notifications'
        })
      })
    }

    markAllAsRead = (notification, callback) => {
      Notification.readAll(this.props.app.getCommonParams(), () => {
        this.setState({ floaters: [] }, () => {
          if (typeof callback === 'function') {
            callback()
          }
        })
      })
    }

    markAllAsHidden = callback => {
      Notification.hideAll(this.props.app.getCommonParams(), () => {
        this.setState({ floaters: [] }, () => {
          if (typeof callback === 'function') {
            callback()
          }
        })
        this.toggleOpen()
      })
    }

    removeFloater = (idOrNotification, callback, ev) => {
      var id =
        typeof idOrNotification === 'string'
          ? idOrNotification
          : idOrNotification.id
      var without = _.without(this.state.floaters, id)
      var newState = {}
      if (without.length !== this.state.floaters) {
        newState.floaters = without
      }
      this.setState({ floaters: without }, function () {
        if (typeof callback === 'function') {
          callback()
        }
      })
      ev && ev.stopPropagation()
    }

    notificationImage = notification => {
      var src
      switch (notification.id) {
        case '237b7d1b-3fb9-4c0d-b222-095fb01a6f5a':
          src =
            'https://s3.amazonaws.com/uifaces/faces/twitter/marcogomes/128.jpg'
          break
        case '9faf08a9-951b-434f-b5e1-597a875ce8f8':
          src =
            'https://s3-eu-west-1.amazonaws.com/notebase-uploads/files/XzdclcCRQEWRSaLCrV0t_logo-dark-bg.png'
          break
        case '3d4e2df8-5ad4-4fae-b8d3-922e28d9b85d':
          src = 'https://s3.amazonaws.com/uifaces/faces/twitter/mantia/128.jpg'
          break
        default:
          src =
            'https://s3.amazonaws.com/uifaces/faces/twitter/jonohunt/128.jpg'
      }
      return src
    }

    notificationIcon = notification => {
      var str
      switch (notification.type) {
        case 'comment_mention':
          str = 'comment-o'
          break
        case 'node_mention':
          str = 'at'
          break
        case 'org_request_approved':
          str = 'check'
          break
        case 'added_to_share':
          str = 'share-alt'
          break
        default:
          str = 'info-circle'
      }
      return (
        <i
          className={
            'fa fa-fw fa-' +
            str +
            ' notification-type-' +
            notification.type.replace(/_/g, '-')
          }
        />
      )
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps (nextProps) {
      var _this = this
      var newNotifications = _.difference(
        nextProps.notifications,
        this.props.notifications
      )
      var potentialFloaters = newNotifications.filter(
        n =>
          !n.read_at && n.created_at > _this.props.user.notifications_seen_until
      )
      var floaters = _.union(
        this.state.floaters,
        potentialFloaters.map(f => f.id)
      )
      this.setState({ floaters: floaters })
    }

    componentDidMount () {
      // this.setState({floaters: _.union(this.state.floaters, _.map(this.props.notifications, 'id'))});
    }

    render () {
      var _this = this
      var numberToShow = 5
      var notifications = this.props.notifications.filter(n => !n.hidden_at)
      var unseenCount = notifications.filter(
        n =>
          !n.read_at && n.created_at > _this.props.user.notifications_seen_until
      ).length
      var unreadCount = notifications.filter(n => !n.read_at).length

      var visibleNotifications = _.sortBy(notifications, 'created_at')
        .reverse()
        .slice(0, numberToShow)
      var list = notifications.length
        ? visibleNotifications.map(function (n) {
          return (
            <SingleNotification
              key={'notification-' + n.id}
              app={_this.props.app}
              notification={n}
              onClick={_this.handleClick}
              removeFloater={_this.removeFloater}
            />
          )
        })
        : [
          <div
            key='no-notifications'
            className='single-notification no-notifications'
          >
              You have no notifications.
          </div>
        ]

      var containerClasses = classnames({
        'notifications-container': true,
        'is-empty': !notifications.length
      })
      var container = !this.state.open ? (
        false
      ) : (
        <div className={containerClasses}>
          {Boolean(notifications.length) && (
            <div className='notifications-controls'>
              <span className='link' onClick={this.markAllAsRead}>
                Mark all as Read
              </span>
              {' / '}
              <span className='link' onClick={this.markAllAsHidden}>
                Clear List
              </span>
              {' / '}
              <span className='link' onClick={this.openSettings}>
                Settings
              </span>
            </div>
          )}
          <div className='notifications-list'>{list}</div>
          {Boolean(notifications.length) && (
            <div className='notifications-footer' onClick={this.seeAll}>
              See All
            </div>
          )}
        </div>
      )
      var floaters
      if (
        !this.state.open &&
        this.state.floaters &&
        this.state.floaters.length
      ) {
        var floaterElements = []
        this.state.floaters.slice(0, 3).forEach(id => {
          var floaterNotification = notifications.find(n => n.id === id)
          if (floaterNotification) {
            floaterElements.push(
              <SingleNotification
                key={'notification-floater-' + id}
                app={_this.props.app}
                notification={floaterNotification}
                isFloater
                onClose={() => _this.removeFloater(id)}
                removeFloater={_this.removeFloater}
              />
            )
          }
        })
        floaters = <div id='notification-floaters'>{floaterElements}</div>
      }
      var active = this.state.open || unseenCount
      var outerClasses = classnames({
        active: active
      })

      return (
        <div id='notifications' className={outerClasses} title='Notifications'>
          <div className='notifications-toggle' onClick={this.toggleOpen}>
            <i
              className={
                'toggle-notifications fa fa-fw ' +
                (active ? 'fa-bell' : 'fa-bell-o')
              }
            />
            <Badge count={unseenCount} />
            <Badge count={unreadCount} type='muted' hideIf={unseenCount} />
          </div>
          {container}
          {floaters}
        </div>
      )
    }
  }
)

module.exports = Notifications
