var createReactClass = require('create-react-class')
// window.Perf = require('react-addons-perf');
// import { DragDropContext } from 'react-dnd';
// import HTML5Backend from 'react-dnd-html5-backend';

var methods = Object.assign({},
  require('../lib/app/lifecycle_methods'),
  require('../lib/app/file_methods'),
  require('../lib/app/comm_methods'),
  require('../lib/app/drag_methods'),
  require('../lib/app/editor_methods'),
  require('../lib/app/tab_methods'),
  require('../lib/app/misc_methods')
)

var App = createReactClass(methods)
// var App = React.createClass({
//   render: function() {
//     return <p>hello</p>;
//   },
// });
// module.exports = DragDropContext(HTML5Backend)(App);
module.exports = App
