import React from 'react'
import Project from './project'
import Todo from './todo'
import Bookmark from './bookmark'
import Site from './site'

import './styles.css'

class NodeTypeSpecifics extends React.Component {
  state = {
    adding: null
  }

  getComponent = () => {
    switch (this.props.node.type) {
      case 'project':
        return <Project app={this.props.app} node={this.props.node} />
      case 'todo':
        return <Todo app={this.props.app} node={this.props.node} />
      case 'bookmark':
        return <Bookmark app={this.props.app} node={this.props.node} />
      case 'site':
        return <Site app={this.props.app} node={this.props.node} />
      case 'list':
        return false
    }
  }

  handleDataTypePick = type => {
    const { app } = this.props
    console.log('picking', type)
    app.setState({ addingData: type })
  }

  render () {
    const { node } = this.props
    const component = this.getComponent()
    return <div className={`node-type-specifics ${node.type}`}>{component}</div>
  }
}

module.exports = NodeTypeSpecifics
