import moment from 'moment'
import $ from 'jquery'
import _ from 'lodash'
import S from 'string'
import uuid from 'node-uuid'
import update from 'react-addons-update'
import constants from '..//lib/constants'

import Tab from './tab'
import Text from './text'

var Node = {
  debug: false,

  add: function (node, params, callback) {
    const doAction = actionParams => {
      const newParams = Object.assign({}, actionParams)
      const nodes = actionParams.updatedState.entities
        ? actionParams.updatedState.entities.nodes
        : actionParams.state.entities.nodes
      const updatedNodes = (
        actionParams.updatedState.updatedNodes ||
        actionParams.state.updatedNodes ||
        []
      ).slice()
      let ref
      let parent
      let parentId = null
      const list = nodes.slice()
      node = _.omit(node, ['slugs'])

      node._ = node._ || {}
      if (!node._.hasOwnProperty('access')) {
        node._.access = Node.getAccess(node, params)
      }

      // console.log('BEFORE: LIST IN Node.add:', list.length);
      if (node.parent_id && node.parent_id !== 'INBOX') {
        parent = list.find(n => n.id === node.parent_id)

        // console.log('parent:', parent);
        if (parent.user_id === node.user_id) {
          parentId = parent.id
        } else {
          if (Node.debug) console.log('PARENT BELONGS TO DIFFERENT USER') // eslint-disable-line no-console
          ref = list.find(n => n.remote_id === node.parent_id)
          if (ref) {
            if (Node.debug) console.log('FOUND REF:', ref) // eslint-disable-line no-console
            parentId = ref.id
          }
        }
      }

      if (params.app.featureEnabled('rich_titles')) {
        node.title_rendered = actionParams._parseMarkdown(node.title, {
          inline: true
        })
      }

      const undoData = {
        undo: [{ obj: 'Node', cmd: 'remove', args: [node.id] }],
        redo: [{ obj: 'Node', cmd: 'add', args: [node] }]
      }

      var newNodes
      var next = Node.nodeThatPointsTo(
        node.prev_id,
        parentId,
        node.user_id,
        node.org_id,
        nodes
      )
      if (next && !node.inbox) {
        undoData.undo.push({
          obj: 'Node',
          cmd: 'modify',
          args: [next.id, { prev_id: next.prev_id }]
        })
        undoData.redo.push({
          obj: 'Node',
          cmd: 'modify',
          args: [next.id, { prev_id: node.id }]
        })
        var newNext = update(next, { $merge: { prev_id: node.id } })
        var withoutNext = nodes.filter(n => n.id !== next.id)
        newNodes = withoutNext.concat([node, newNext])
      } else {
        newNodes = nodes.concat([node])
      }

      if (!params._skipFocus) {
        undoData.undo.push({
          obj: 'Node',
          cmd: 'focus',
          args: [node.prev_id || node.parent_id, Text.savedSelection],
          persist: false
        })
        undoData.redo.push({
          obj: 'Node',
          cmd: 'focus',
          args: [node.id, Text.savedSelection],
          persist: false
        })
      }

      if (!actionParams._isUndoOperation && !actionParams._skipUndoAdd) {
        actionParams.undo.add(undoData, true)
        newParams.undoData = { undo: [], redo: [] }
      } else {
        newParams.undoData = actionParams.undoData || {}
        newParams.undoData.undo = (newParams.undoData.undo || []).concat(
          undoData.undo
        )
        newParams.undoData.redo = (newParams.undoData.redo || []).concat(
          undoData.redo
        )
      }

      updatedNodes.push(node)
      const newEntities = Object.assign(
        {},
        actionParams.updatedState.entities || actionParams.state.entities
      )
      newEntities.nodes = newNodes
      var result = {
        entities: newEntities,
        updatedNodes: updatedNodes
      }

      newParams.updatedState = Object.assign(
        {},
        actionParams.updatedState,
        result
      )

      if (actionParams._skipSetState) {
        if (typeof callback === 'function') {
          callback(newParams)
        }
      } else {
        actionParams.setAppState(
          newParams.updatedState,
          newParams.stateParams,
          () => {
            // Set focus
            if (actionParams._skipFocus) {
              callback(newParams)
            } else {
              Node.focus(node.id, null, newParams, () => {
                if (typeof callback === 'function') {
                  callback(newParams)
                }
              })
            }
          }
        )
      }
    }

    if (params._skipFlushUndo) {
      doAction(params)
    } else {
      params.flushTextUndo(params, ret => {
        // console.log('[modify] flush callback firing...');
        doAction(ret)
      })
    }
  },

  replace: function (node, params, callback) {
    const doAction = actionParams => {
      const newParams = Object.assign({}, actionParams)
      const nodes = actionParams.updatedState.entities
        ? actionParams.updatedState.entities.nodes
        : actionParams.state.entities.nodes
      const oldNode = Node.get(node.id, nodes)
      const updatedNodes = (
        actionParams.updatedState.updatedNodes ||
        actionParams.state.updatedNodes ||
        []
      ).slice()
      var withoutNode = nodes.filter(n => n.id !== node.id)
      var newNodes = withoutNode.concat([node])

      // if (params.replaceNodes) {
      //   replaceNodes(newNodes);
      // }
      updatedNodes.push(node)

      const undoData = {
        undo: [{ obj: 'Node', cmd: 'replace', args: [oldNode] }],
        redo: [{ obj: 'Node', cmd: 'replace', args: [node] }]
      }

      if (!params._skipFocus) {
        undoData.undo.push({
          obj: 'Node',
          cmd: 'focus',
          args: [node.id, null],
          persist: false
        })
        undoData.redo.push({
          obj: 'Node',
          cmd: 'focus',
          args: [node.id, null],
          persist: false
        })
      }

      if (!actionParams._isUndoOperation && !actionParams._skipUndoAdd) {
        actionParams.undo.add(undoData, true)
        newParams.undoData = { undo: [], redo: [] }
      } else {
        newParams.undoData = actionParams.undoData || {}
        newParams.undoData.undo = (newParams.undoData.undo || []).concat(
          undoData.undo
        )
        newParams.undoData.redo = (newParams.undoData.redo || []).concat(
          undoData.redo
        )
      }

      const newEntities = Object.assign(
        {},
        actionParams.updatedState.entities || actionParams.state.entities
      )
      newEntities.nodes = newNodes
      var result = {
        entities: newEntities,
        updatedNodes: updatedNodes
      }

      newParams.updatedState = Object.assign(
        {},
        actionParams.updatedState,
        result
      )

      if (actionParams._skipSetState) {
        if (typeof callback === 'function') {
          callback(newParams)
        }
      } else {
        actionParams.setAppState(
          newParams.updatedState,
          newParams.stateParams,
          () => {
            // Set focus
            if (typeof callback === 'function') {
              callback(newParams)
            }
          }
        )
      }
    }

    if (params._skipFlushUndo) {
      doAction(params)
    } else {
      params.flushTextUndo(params, ret => {
        // console.log('[modify] flush callback firing...');
        doAction(ret)
      })
    }
  },

  remove: function (nodeOrId, params, callback) {
    const initialNodes = params.updatedState.entities
      ? params.updatedState.entities.nodes
      : params.state.entities.nodes
    const initialNode = Node.get(
      typeof nodeOrId === 'string' ? nodeOrId : nodeOrId.id,
      initialNodes
    )

    const doAction = actionParams => {
      const newParams = Object.assign({}, actionParams)
      const nodes = actionParams.updatedState.entities
        ? actionParams.updatedState.entities.nodes
        : actionParams.state.entities.nodes
      const updatedNodes = (
        actionParams.updatedState.updatedNodes ||
        actionParams.state.updatedNodes ||
        []
      ).slice()
      var newNodes = nodes.slice()
      var undoCommands = []

      var recursivelyRemove = function (delNode) {
        var doReject = true
        undoCommands.push({ obj: 'Node', cmd: 'add', args: [delNode] })
        var children = Node.children(delNode.id, newNodes)
        children.forEach(child => {
          console.log('RECURSIVE child:', child.title) // eslint-disable-line no-console

          // console.log('newNodes in each loop:', newNodes);
          recursivelyRemove(child)
        })
        if (Node.debug) console.log('REMOVING:', delNode.title) // eslint-disable-line no-console

        if (delNode.id === node.id) {
          var next = Node.nodeThatPointsTo(
            delNode.id,
            delNode.parent_id,
            delNode.user_id,
            delNode.org_id,
            newNodes
          )
          if (next && !delNode.inbox) {
            undoCommands.push({
              obj: 'Node',
              cmd: 'modify',
              args: [next.id, { prev_id: next.prev_id }]
            })
            var newNext = update(next, { $merge: { prev_id: delNode.prev_id } })
            var trimmedNodes = newNodes.filter(
              n => n.id !== delNode.id && n.id !== next.id
            )
            newNodes = trimmedNodes.concat([newNext])
            doReject = false
          }
        }

        if (doReject) {
          newNodes = newNodes.filter(n => n.id !== delNode.id)
        }
      }

      const node = Node.get(nodeOrId, nodes)
      const nextNode = nodes.find(n => {
        return n.prev_id === node.id
      })
      const prevNode = node.prev_id
        ? nodes.find(n => {
          return n.id === node.prev_id
        })
        : null
      const parentNode = node.parent_id
        ? nodes.find(n => {
          return n.id === node.parent_id
        })
        : null

      recursivelyRemove(node)

      const undoData = {
        undo: undoCommands,
        redo: [{ obj: 'Node', cmd: 'remove', args: [node.id] }]
      }

      if (!params._skipFocus) {
        undoData.undo.push({
          obj: 'Node',
          cmd: 'focus',
          args: [node.id, Text.savedSelection],
          persist: false
        })
        undoData.redo.push({
          obj: 'Node',
          cmd: 'focus',
          args: [node.id, Text.savedSelection],
          persist: false
        })
      }

      if (!actionParams._isUndoOperation && !actionParams._skipUndoAdd) {
        actionParams.undo.add(undoData, true)
        newParams.undoData = { undo: [], redo: [] }
      } else {
        newParams.undoData = actionParams.undoData || {}
        newParams.undoData.undo = (newParams.undoData.undo || []).concat(
          undoData.undo
        )
        newParams.undoData.redo = (newParams.undoData.redo || []).concat(
          undoData.redo
        )
      }

      // if (params.replaceNodes) {
      //   replaceNodes(newNodes);
      // }
      updatedNodes.push(node)
      updatedNodes.push(node.parent_id)
      if (nextNode) {
        updatedNodes.push(nextNode.id)
      }
      const newEntities = Object.assign(
        {},
        actionParams.updatedState.entities || actionParams.state.entities
      )
      newEntities.nodes = newNodes
      var result = {
        entities: newEntities,
        updatedNodes: updatedNodes
      }

      newParams.updatedState = Object.assign(
        {},
        actionParams.updatedState,
        result
      )

      if (actionParams._skipSetState) {
        if (typeof callback === 'function') {
          callback(newParams)
        }
      } else {
        actionParams.setAppState(
          newParams.updatedState,
          newParams.stateParams,
          () => {
            // Set focus
            if (actionParams._skipFocus) {
              if (typeof callback === 'function') {
                callback(newParams)
              }
            } else {
              const focusNode = nextNode || prevNode || parentNode
              if (focusNode) {
                Node.focus(focusNode.id, null, newParams, () => {
                  if (typeof callback === 'function') {
                    callback(newParams)
                  }
                })
              } else {
                if (typeof callback === 'function') {
                  callback(newParams)
                }
              }
            }
          }
        )
      }
    }

    if (params._skipFlushUndo) {
      doAction(params)
    } else {
      params.flushTextUndo({ nodeId: initialNode.id }, params, ret => {
        // console.log('[modify] flush callback firing...');
        doAction(ret)
      })
    }
  },

  modify: function (nodeOrId, data, params, callback) {
    // console.log('modify triggered', Text.savedSelection)
    const initialNodes = params.updatedState.entities
      ? params.updatedState.entities.nodes
      : params.state.entities.nodes
    const initialNode = Node.get(
      typeof nodeOrId === 'string' ? nodeOrId : nodeOrId.id,
      initialNodes
    )

    const doAction = actionParams => {
      const newParams = Object.assign({}, actionParams)
      const nodes = actionParams.updatedState.entities
        ? actionParams.updatedState.entities.nodes
        : actionParams.state.entities.nodes
      const updatedNodes = (
        actionParams.updatedState.updatedNodes ||
        actionParams.state.updatedNodes ||
        []
      ).slice()
      const node = Node.get(initialNode.id, nodes)
      const changes = { updated_at: new Date().toISOString() }
      if (actionParams.userId) {
        changes.updated_by = actionParams.userId
      }
      delete data.title_rendered
      var newNode = update(node, { $merge: changes })
      const oldData = {}
      for (var key in data) {
        var keys = key.split('.')
        var obj = newNode
        for (var i = 0; i <= keys.length - 1; i++) {
          if (i === keys.length - 1) {
            obj[keys[i]] = data[key]
          }

          oldData[key] = node[key]
          obj = obj[keys[i]]
        }
      }

      const undoData = {
        undo: [{ obj: 'Node', cmd: 'modify', args: [node.id, oldData] }],
        redo: [{ obj: 'Node', cmd: 'modify', args: [node.id, data] }]
      }

      // if (
      //   data.hasOwnProperty('title') &&
      //   params.app.featureEnabled('rich_titles')
      // ) {
      //   data.title_rendered = actionParams._parseMarkdown(data.title, {
      //     inline: true
      //   })
      // }

      if (!params._skipFocus) {
        undoData.undo.push({
          obj: 'Node',
          cmd: 'focus',
          args: [node.id, Text.savedSelection],
          persist: false
        })
        undoData.redo.push({
          obj: 'Node',
          cmd: 'focus',
          args: [node.id, Text.savedSelection],
          persist: false
        })
      }

      if (!actionParams._isUndoOperation && !actionParams._skipUndoAdd) {
        actionParams.undo.add(undoData, true)
        newParams.undoData = { undo: [], redo: [] }
      } else {
        newParams.undoData = actionParams.undoData || {}
        newParams.undoData.undo = (newParams.undoData.undo || []).concat(
          undoData.undo
        )
        newParams.undoData.redo = (newParams.undoData.redo || []).concat(
          undoData.redo
        )
      }

      var trimmedNodes = nodes.filter(n => n.id !== newNode.id)
      var newNodes = trimmedNodes.concat([newNode])

      // if (params.replaceNodes) {
      //   replaceNodes(newNodes, updatedTextNode);
      // }
      const newEntities = Object.assign(
        {},
        actionParams.updatedState.entities || actionParams.state.entities
      )
      newEntities.nodes = newNodes
      var result = {
        entities: newEntities,
        updatedNodes: updatedNodes.concat(node)
      }

      // if (params.rootNode && params.rootNode.id === newNode.id) {
      //   ret.rootNode = newNode;
      // }

      if (params._textOnly) {
        result.updatedTextNode = newNode.id
      } else {
        result.updatedTextNode = null
      }

      newParams.updatedState = Object.assign(
        {},
        actionParams.updatedState,
        result
      )

      if (actionParams._skipSetState) {
        if (typeof callback === 'function') {
          callback(newParams)
        }
      } else {
        actionParams.setAppState(
          newParams.updatedState,
          newParams.stateParams,
          () => {
            // Set focus
            if (typeof callback === 'function') {
              callback(newParams)
            }
          }
        )
      }
    }

    if (params._skipFlushUndo) {
      doAction(params)
    } else {
      params.flushTextUndo({ nodeId: initialNode.id }, params, ret => {
        // console.log('[modify] flush callback firing...');
        doAction(ret)
      })
    }
  },

  duplicate: function (
    nodeOrId,
    dstParentId,
    dstPrevId,
    idMappings,
    params,
    callback
  ) {
    idMappings = idMappings || {}
    const initialNodes = params.updatedState.entities
      ? params.updatedState.entities.nodes
      : params.state.entities.nodes
    const initialNode = Node.get(
      typeof nodeOrId === 'string' ? nodeOrId : nodeOrId.id,
      initialNodes
    )

    const doAction = actionParams => {
      const newParams = Object.assign({}, actionParams)
      const nodes = actionParams.updatedState.entities
        ? actionParams.updatedState.entities.nodes
        : actionParams.state.entities.nodes
      let updatedNodes = (
        actionParams.updatedState.updatedNodes ||
        actionParams.state.updatedNodes ||
        []
      ).slice()
      const tab = actionParams.tab()
      const expandedNodes = tab ? tab.expanded_nodes || [] : []
      let actualNewNodes = [...nodes]
      const newNodes = []
      const node = Node.get(nodeOrId, nodes)
      const undoCommands = []
      const recursivelyAdd = addNode => {
        const newNode = { ...addNode }
        newNode.settings = { ...addNode.settings }
        newNodes.push(newNode)

        const children = Node.children(addNode.remote_id || addNode.id, nodes)
        children.forEach(child => {
          recursivelyAdd(child)
        })
      }
      recursivelyAdd(node)

      const nodesToExpand = []
      const tweakedNodes = []
      const isDuplicateCmd = Boolean(
        dstParentId === node.parent_id && dstPrevId === node.id
      )
      console.log('isDuplicateCmd', isDuplicateCmd)
      newNodes.forEach(newNode => {
        console.log('newNode.title', newNode.title)
        const oldId = newNode.id
        newNode.id =
          idMappings && idMappings[oldId] ? idMappings[oldId] : uuid.v4()
        idMappings[oldId] = newNode.id
        if (oldId === node.id) {
          newNode.parent_id = dstParentId
          newNode.prev_id = dstPrevId
          // Add "(copy)" only for Duplicate command, not Copy/Paste
          if (isDuplicateCmd) {
            newNode.title += ' (copy)'
          }
          undoCommands.push({ obj: 'Node', cmd: 'remove', args: [newNode.id] })
          const next = Node.nodeThatPointsTo(
            newNode.prev_id,
            newNode.parent_id,
            newNode.user_id,
            newNode.org_id,
            nodes
          )
          if (next) {
            undoCommands.push({
              obj: 'Node',
              cmd: 'modify',
              args: [next.id, { prev_id: next.prev_id }]
            })
            const newNext = update(next, { $merge: { prev_id: newNode.id } })
            const trimmedNodes = actualNewNodes.filter(n => n.id !== next.id)
            actualNewNodes = trimmedNodes.concat([newNext])
          }
        } else {
          const sibling = newNodes.find(n => n.prev_id === oldId)
          if (sibling) {
            sibling.prev_id = newNode.id
          }
        }

        if (tab && expandedNodes.indexOf(oldId) !== -1) {
          nodesToExpand.push(newNode.id)
        }

        const children = newNodes.filter(
          n => n.parent_id === (newNode.remote_id || oldId)
        )
        children.forEach(n => {
          n.parent_id = newNode.id
          n.path = Node.constructPath(n, nodes)
        })

        if (newNode.type === 'site') {
          newNode.type = 'list'
        } else if (newNode.settings.published) {
          newNode.settings.published = false
        }

        delete newNode.short_id
        delete newNode.share_id
        delete newNode.remote_id
        delete newNode.slugs
        tweakedNodes.push(newNode)
      })
      const finalNewNodes = actualNewNodes.concat(tweakedNodes)

      // if (params.replaceNodes) {
      //   replaceNodes(finalNewNodes);
      // }
      if (Node.debug) console.log('expand', nodesToExpand) // eslint-disable-line no-console

      Tab.expand(tab.id, nodesToExpand, newParams, expandParams => {
        const undoData = {
          undo: undoCommands,
          redo: [
            {
              obj: 'Node',
              cmd: 'duplicate',
              args: [node.id, dstParentId, dstPrevId, idMappings]
            }
          ]
        }

        const newestParams = Object.assign({}, expandParams)

        if (!params._skipFocus) {
          undoData.undo.push({
            obj: 'Node',
            cmd: 'focus',
            args: [node.id, Text.savedSelection],
            persist: false
          })
          undoData.redo.push({
            obj: 'Node',
            cmd: 'focus',
            args: [node.id, Text.savedSelection],
            persist: false
          })
        }

        if (!actionParams._isUndoOperation && !actionParams._skipUndoAdd) {
          actionParams.undo.add(undoData, true)
          newestParams.undoData = { undo: [], redo: [] }
        } else {
          newestParams.undoData = actionParams.undoData || {}
          newestParams.undoData.undo = (
            newestParams.undoData.undo || []
          ).concat(undoData.undo)
          newestParams.undoData.redo = (
            newestParams.undoData.redo || []
          ).concat(undoData.redo)
        }

        const newEntities = Object.assign(
          {},
          expandParams.updatedState.entities || expandParams.state.entities
        )
        newEntities.nodes = finalNewNodes

        updatedNodes = updatedNodes
          .concat([node, dstParentId])
          .concat(tweakedNodes)
        const result = {
          entities: newEntities,
          updatedNodes: updatedNodes,
          idMappings: idMappings
        }
        newestParams.updatedState = Object.assign(
          {},
          expandParams.updatedState,
          result
        )

        if (expandParams._skipSetState) {
          if (typeof callback === 'function') {
            callback(newestParams)
          }
        } else {
          expandParams.setAppState(
            newestParams.updatedState,
            newestParams.stateParams,
            () => {
              // Set focus
              if (typeof callback === 'function') {
                callback(newestParams)
              }
            }
          )
        }
      })
    }

    if (params._skipFlushUndo) {
      doAction(params)
    } else {
      params.flushTextUndo({ nodeId: initialNode.id }, params, ret => {
        // console.log('[modify] flush callback firing...');
        doAction(ret)
      })
    }
  },

  move: function (
    nodeOrId,
    newParentId,
    newPreviousSiblingId,
    params,
    callback
  ) {
    const initialNodes = params.updatedState.entities
      ? params.updatedState.entities.nodes
      : params.state.entities.nodes
    const initialNode = Node.get(
      typeof nodeOrId === 'string' ? nodeOrId : nodeOrId.id,
      initialNodes
    )

    const doAction = actionParams => {
      const newParams = Object.assign({}, actionParams)
      const nodes = actionParams.updatedState.entities
        ? actionParams.updatedState.entities.nodes
        : actionParams.state.entities.nodes
      const node = Node.get(initialNode.id, nodes)
      const updatedNodes = (
        actionParams.updatedState.updatedNodes ||
        actionParams.state.updatedNodes ||
        []
      ).slice()
      const localUpdatedNodes = []
      const tab = actionParams.tab()
      const expandedNodes = tab.expanded_nodes || []
      const parent = Node.get(node.parent_id, nodes)
      const oldParentId = node.inbox ? 'INBOX' : node.parent_id
      const oldPrevSibling = Node.get(node.prev_id, nodes)
      const newPrevSibling = Node.get(newPreviousSiblingId, nodes)
      const oldPath = parent ? parent.path : 'ROOT'
      let newPath = 'ROOT'
      const next = Node.nodeThatPointsTo(
        node.id,
        node.parent_id,
        node.user_id,
        node.org_id,
        nodes
      )
      const changes = { updated_at: new Date().toISOString() }
      if (actionParams.userId) {
        changes.updated_by = actionParams.userId
      }
      let newParentIsInbox = false

      if (newParentId === 'INBOX') {
        newParentIsInbox = true
        newParentId = null
        newPreviousSiblingId = null
        // Just making sure
        changes.inbox = true
      } else {
        changes.inbox = false
      }

      let newParent
      if (newParentId) {
        newParent = Node.get(newParentId, nodes)
        if (newParent) {
          newPath = newParent.path
          changes.user_id = newParent.user_id
          changes.org_id = newParent.org_id
          changes._ = _.extend({ access: newParent._.access }, node._)
        }
      }

      changes.prev_id = newPreviousSiblingId
      changes.parent_id = newParent ? newParent.id : newParentId
      const nodeWithChanges = _.extend({ id: node.id }, changes)
      changes.path = Node.constructPath(nodeWithChanges, nodes)
      // TODO: the paths of child nodes must also be fixed

      // if (!node.inbox) {
      if (next) {
        localUpdatedNodes.push(
          update(next, { $merge: { prev_id: node.prev_id } })
        )
      }

      localUpdatedNodes.push(update(node, { $merge: changes }))
      if (!newParentIsInbox) {
        const prev = Node.nodeThatPointsTo(
          newPreviousSiblingId,
          newParentId,
          node.user_id,
          node.org_id,
          nodes
        )
        if (prev) {
          localUpdatedNodes.push(update(prev, { $merge: { prev_id: node.id } }))
        }
      }

      // }
      const updatedIds = localUpdatedNodes.map(n => n.id)
      let trimmedNodes = nodes.filter(
        n => updatedIds.concat([node.id]).indexOf(n.id) === -1
      )

      const subTree = Node.subTree(node, nodes)
      const subTreeIds = subTree.map(n => n.id)
      trimmedNodes = trimmedNodes.filter(n => subTreeIds.indexOf(n.id) === -1)

      const srcIsExpanded = !tab || expandedNodes.indexOf(node.id) !== -1
      const updatedChildren = []
      let modNode
      subTree.forEach(n => {
        if (srcIsExpanded) {
          updatedChildren.push(n)
        }
        modNode = Node.clone(n)
        modNode._ = modNode._ || {}
        if (newParent) {
          modNode.user_id = newParent.user_id
          modNode.org_id = newParent.org_id
          modNode._.access = newParent._.access
        } else {
          modNode._.access = constants.OWNER_ACCESS
        }
        modNode.path = modNode.path.replace(oldPath, newPath)
        trimmedNodes.push(modNode)
      })

      const newNodes = trimmedNodes.concat(localUpdatedNodes)

      // if ((newParent && ((newParent.user_id !== node.user_id) || (newParent.org_id !== node.org_id))) ||
      //     (!newParent && params.org && (!node.org_id || node.org_id !== params.org.id)) ||
      //     (!newParent && params.user && !params.org && (!node.user_id || node.user_id !== params.user.id))) {
      //   // TODO: Update permissions for node and subtree
      // }

      const undoData = {
        undo: [
          {
            obj: 'Node',
            cmd: 'move',
            args: [node.id, oldParentId, node.prev_id]
          }
        ],
        redo: [
          {
            obj: 'Node',
            cmd: 'move',
            args: [node.id, nodeWithChanges.parent_id, nodeWithChanges.prev_id]
          }
        ]
      }

      if (!params._skipFocus) {
        undoData.undo.push({
          obj: 'Node',
          cmd: 'focus',
          args: [node.id, Text.savedSelection],
          persist: false
        })
        undoData.redo.push({
          obj: 'Node',
          cmd: 'focus',
          args: [node.id, Text.savedSelection],
          persist: false
        })
      }

      if (!actionParams._isUndoOperation && !actionParams._skipUndoAdd) {
        actionParams.undo.add(undoData, true)
        newParams.undoData = { undo: [], redo: [] }
      } else {
        newParams.undoData = actionParams.undoData || {}
        newParams.undoData.undo = (newParams.undoData.undo || []).concat(
          undoData.undo
        )
        newParams.undoData.redo = (newParams.undoData.redo || []).concat(
          undoData.redo
        )
        // params.undo.add({undo: [{obj: 'Node', cmd: 'move',  args: [node.id, node.parent_id, node.prev_id]}],
        //                  redo: [{obj: 'Node', cmd: 'move',  args: [node.id, nodeWithChanges.parent_id, nodeWithChanges.prev_id]}]}, true);
      }

      const newEntities = Object.assign(
        {},
        actionParams.updatedState.entities || actionParams.state.entities
      )
      newEntities.nodes = newNodes
      const allUpdatedNodes = updatedNodes
        .concat(localUpdatedNodes)
        .concat(updatedChildren)
        .concat([parent, newParent, newPrevSibling, oldPrevSibling])

      var result = {
        entities: newEntities,
        updatedNodes: allUpdatedNodes
      }
      newParams.updatedState = Object.assign(
        {},
        actionParams.updatedState,
        result
      )

      if (actionParams._skipSetState) {
        if (typeof callback === 'function') {
          callback(newParams)
        }
      } else {
        actionParams.setAppState(
          newParams.updatedState,
          newParams.stateParams,
          () => {
            // Set focus
            if (typeof callback === 'function') {
              callback(newParams)
            }
          }
        )
      }
    }

    if (
      !(
        initialNode.parent_id === newParentId &&
        initialNode.prev_id === newPreviousSiblingId
      )
    ) {
      if (params._skipFlushUndo) {
        doAction(params)
      } else {
        params.flushTextUndo({ nodeId: initialNode.id }, params, ret => {
          // console.log('flush callback firing...');
          doAction(ret)
        })
      }
    } else {
      if (typeof callback === 'function') {
        callback(params)
      }
    }
  },

  /// ///////////////////////////
  // END OF SYNCABLE COMMANDS //
  /// ///////////////////////////

  setSetting: function (nodeOrId, key, value, params, callback) {
    const node = Node.get(nodeOrId, params.entities.nodes)
    Node.modify(
      nodeOrId,
      { settings: { ...node.settings, [key]: value } },
      params,
      callback
    )
  },

  focus: function (id, selection, params, callback) {
    Node.fieldFocus('title', id, selection, params)
    if (typeof callback === 'function') {
      callback(params)
    }
  },

  _bump: function (parentId, node, nodes) {
    var next = Node.nodeThatPointsTo(
      node.prev_id,
      parentId,
      node.user_id,
      node.org_id,
      nodes
    )
    var newNodes
    if (next && !node.inbox) {
      var newNext = update(next, { $merge: { prev_id: node.id } })
      // console.log('newNext', newNext);
      var withoutNext = nodes.filter(n => n.id !== next.id)
      newNodes = withoutNext.concat([node, newNext])
    } else {
      newNodes = nodes.concat([node])
    }

    return newNodes
  },

  flushTextUndo: function (args, params, callback) {
    // console.log('flushing text undo...');
    let el, node, nodeId, field
    if (typeof params === 'function') {
      callback = params
      params = args
    } else if (
      typeof callback === 'undefined' &&
      typeof params === 'undefined'
    ) {
      params = args
    }
    args = args || {}

    el = args.el || params.app.getActiveElement()
    el = el instanceof jQuery ? el.get(0) : el // eslint-disable-line no-undef
    const $el = $(el)
    field = $el.hasClass('title') ? 'title' : field
    field = $el.hasClass('description') ? 'description' : field
    field = $el.hasClass('heading') ? 'title' : field
    nodeId = args.node ? args.node.id : args.nodeId
    if (!nodeId) {
      if (el) {
        const nodeEl = args.nodeEl || params.app.getCurrentNodeEl(el)
        if (nodeEl) {
          node = params.app.getCurrentNode($(nodeEl))
          if (node) {
            nodeId = node.id
          }
        }
      }
    }

    if (field && nodeId && el) {
      if (!params._skipSaveSelection) {
        Text.saveSelection($el.get(0))
      }
      if (params._doBlur) {
        window._app.skipTitleBlur = true
        el.blur()
      }

      // var text
      // if (params.app.featureEnabled('rich_titles')) {
      //   if (params.app.state.rawTitleNodeId === nodeId) {
      //     text = params._parseMarkdown($(el).text(), { inline: true })
      //   } else {
      //     text = $(el).html()
      //   }
      // } else {
      //   text = $(el).text()
      // }
      var text = $(el).text()

      Node.setTextUndo(nodeId, field, text, params, undoParams => {
        window._app.cancelNextUndoTimeout = true
        if (typeof callback === 'function') {
          if (!params._skipFocus) {
            Node.focus(nodeId, Text.savedSelection, undoParams)
          }
          callback(undoParams)
        }
      })
    } else {
      console.log('No nodeId && el in flushTextUndo :(') // eslint-disable-line no-console
      if (typeof callback === 'function') {
        callback(params)
      }
    }
  },

  // setTextUndo: function(nodeId, field, html, params, skipFocus) {
  setTextUndo: function (nodeId, field, html, params, callback) {
    // console.log('params in setTextUndo', params);
    var ret = Object.assign({}, params)
    ret.updatedState = Object.assign({}, params.updatedState)
    field = field || 'title'
    const nodes = params.updatedState.entities
      ? params.updatedState.entities.nodes
      : params.state.entities.nodes
    var node = Node.get(nodeId, nodes)
    // var focusCommand;
    switch (field) {
      case 'description':
        // focusCommand = 'descriptionFocus';
        break
      case 'heading':
        // field = 'title';
        // focusCommand = 'headingFocus';
        break
      default:
      // focusCommand = 'focus';
    }
    var compareTo = window._app['original_' + field]

    // console.log('html', html);
    // console.log('compareTo', compareTo);
    if (!params._isUndoOperation && html !== compareTo) {
      // throw new Error('DOH!');
      if (Node.debug) {
        console.log(
          'ADDING ' + field + ' UNDO: "' + compareTo + '" -> "' + html + '"'
        )
      } // eslint-disable-line no-console

      // reset original state
      window._app['original_' + field] = html
      // 2016-11-08, stian: Removed to fix bug where splitting a node that was previously text-only changed would not update correctly
      // ret.updatedState.updatedTextNode = node.id;

      // var changes = {
      //   [field]: params.app.featureEnabled('rich_titles')
      //     ? params.app.htmlToMarkdown(html)
      //     : html
      // }
      var changes = { [field]: html }
      Node.modify(
        node,
        changes,
        Object.assign({}, ret, {
          _skipFlushUndo: true,
          _textOnly: true
          // _skipFocus: params.skipFocus
        }),
        modifyParams => {
          // if (!skipFocus) {
          //   undoCommands.push({obj: 'Node', cmd: focusCommand,  args: [node.id, compareTo.length], persist: false});
          //   redoCommands.push({obj: 'Node', cmd: focusCommand,  args: [node.id, Text.savedSelection], persist: false});
          // }
          const newModifyParams = Object.assign({}, modifyParams, {
            _textOnly: false
          })
          if (params.hasOwnProperty('_skipFlushUndo')) {
            newModifyParams._skipFlushUndo = params._skipFlushUndo
          } else {
            delete newModifyParams._skipFlushUndo
          }
          if (typeof callback === 'function') {
            callback(newModifyParams)
          }
        }
      )

      // var undoModifyArgs = {}, redoModifyArgs = {};
      // undoModifyArgs[field] = compareTo;
      // redoModifyArgs[field] = html;
      // undoCommands = [{obj: 'Node', cmd: 'modify', args: [node.id, undoModifyArgs]}];
      // redoCommands = [{obj: 'Node', cmd: 'modify', args: [node.id, redoModifyArgs]}];
      // if (!skipFocus) {
      //   undoCommands.push({obj: 'Node', cmd: focusCommand,  args: [node.id, compareTo.length], persist: false});
      //   redoCommands.push({obj: 'Node', cmd: focusCommand,  args: [node.id, Text.savedSelection], persist: false});
      // }

      // // reset original state
      // window._app['original_' + field] = html;
      // ret.state.updatedTextNode = node.id;

      // ret.undoCommands = ret.undoCommands ? ret.undoCommands.concat(undoCommands) : undoCommands;
      // ret.redoCommands = ret.redoCommands ? ret.redoCommands.concat(redoCommands) : redoCommands;
    } else {
      if (typeof callback === 'function') {
        callback(params)
      }
    }

    // return ret;
  },

  descriptionFocus: function (id, selection, params) {
    Node.fieldFocus('description', id, selection, params)
  },

  headingFocus: function (id, selection, params) {
    Node.fieldFocus('heading', id, selection, params)
  },

  fieldFocus: function (field, id, selection, params) {
    // eslint-disable-line no-unused-vars
    setTimeout(function () {
      var contentDiv, div
      if (!selection) {
        selection = { start: 0, end: 0 }
      } else if (typeof selection === 'string') {
        selection = JSON.parse(selection)
      } else if (typeof selection === 'number') {
        selection = { start: selection, end: selection }
      }

      if (field === 'heading') {
        var body = $('#body-container > .node')
        var h2 = $('#body-container .node-heading > span')
        if (Node.debug) console.log('H2', h2) // eslint-disable-line no-console
        // params.setActiveNodeEl(li);

        // if (params && params.setAppState) {
        //   params.setAppState({activeNode: body.data('node-id')});
        // }
        contentDiv = body
        div = h2
      } else {
        const rootNode = params && params.app ? params.app.rootNode() : null
        if (rootNode && rootNode.mode === 'board' && params && params.app) {
          var card = $('.kb-card[data-node-id="' + id + '"]')
          // console.log('board focus', card.data('node-id'));
          params.setAppState({ activeNodeId: card.data('node-id') })
        } else {
          var li = $('li[data-node-id="' + id + '"]')
          // var li = $(Misc.nodeEl(id));

          // if (params && params.setAppState) {
          //   params.setAppState({activeNode: li.data('node-id')});
          // }
          contentDiv = li.find(
            '> .indented > .indent-wrapper > div.node-content'
          )
          div = contentDiv.find(
            '> div > div > div.' + field + '[contenteditable]'
          )

          // if (div.length) {
          // } else {
          //   console.warn('Tried to focus node [' + id + '], but did not find the div');
          // }
        }
      }
      if (div && div.length) {
        // console.log('Focusing', div, selection)
        Text.setSelection(div.get(0), selection.start, selection.end)
      }

      if (contentDiv && contentDiv.length) {
        // $('body').scrollTo(contentDiv, {offsetTop: 80});
      }
    }, 0)
  },

  title: function (node, searchstring) {
    // var title = node.title ? S(node.title).escapeHTML().s : '';
    var title = node.title_rendered || node.title || ''

    // var title = (node && node.title) ? S(node.title).escapeHTML().s : '';
    // return title + '   [' + node.id + ']';
    if (searchstring) {
      var re = new RegExp(
        '(' + searchstring.replace(/^\s*[<>]\s*/, '').trim() + ')',
        'i'
      )
      return title.replace(re, '<em class="search">$1</em>')
    } else {
      return title
    }
  },

  rawTitle: function (node, searchstring) {
    var title = node.title ? S(node.title).escapeHTML().s : ''

    // var title = (node && node.title) ? S(node.title).escapeHTML().s : '';
    // return title + '   [' + node.id + ']';
    if (searchstring) {
      var re = new RegExp(
        '(' + searchstring.replace(/^\s*[<>]\s*/, '').trim() + ')',
        'i'
      )
      return title.replace(re, '<em class="search">$1</em>')
    } else {
      return title
    }
  },

  description: function (node, searchstring) {
    var description = node.description ? S(node.description).escapeHTML().s : ''
    if (searchstring) {
      var re = new RegExp('(' + searchstring + ')', 'i')
      return description.replace(re, '<em class="search">$1</em>')
    } else {
      return description
    }
  },

  getResolved: function (nodeOrId, nodes) {
    if (nodeOrId) {
      if (typeof nodeOrId === 'object' && !nodeOrId.remote_id) {
        return Node.get(nodeOrId, nodes)
      } else {
        var id =
          typeof nodeOrId === 'string'
            ? nodeOrId
            : nodeOrId.remote_id
              ? nodeOrId.remote_id
              : nodeOrId.id
        return Node.get(id, nodes)
      }
    }
  },

  list: function () {
    return Node.rootNodeScope().list
  },

  get: function (nodeOrId, nodes) {
    var t0 = new Date().getTime()
    if (typeof nodeOrId === 'string') {
      var field = constants.UUID_REGEXP.test(nodeOrId) ? 'id' : 'short_id'
      var node = nodes.find(n => n[field] === nodeOrId)
      var t1 = new Date().getTime()
      var diff = t1 - t0
      window._app.nodeGetTimer = window._app.nodeGetTimer
        ? window._app.nodeGetTimer + diff
        : diff
      return node
    } else {
      return nodeOrId
    }
  },

  nodeThatPointsTo: function (nodeOrId, parentId, userId, orgId, nodes) {
    if (nodeOrId) {
      var id = typeof nodeOrId === 'string' ? nodeOrId : nodeOrId.id
      if (parentId) {
        return nodes.find(
          n => n.prev_id === id && n.parent_id === parentId && !n.inbox
        )
      } else {
        return nodes.find(n => n.prev_id === id && !n.parent_id && !n.inbox)
      }
    } else {
      if (parentId) {
        return nodes.find(
          n => !n.prev_id && n.parent_id === parentId && !n.inbox
        )
      } else {
        if (orgId) {
          return nodes.find(
            n => !n.prev_id && !n.parent_id && n.org_id === orgId && !n.inbox
          )
        } else {
          return nodes.find(
            n => !n.prev_id && !n.parent_id && n.user_id === userId && !n.inbox
          )
        }
      }
    }
  },

  getShare: function (nodeOrId, shares, userId, orgId) {
    var id = typeof nodeOrId === 'string' ? nodeOrId : nodeOrId.id
    var share = shares.find(share => share.node_id === id)
    if (
      share &&
      ((orgId && share.org_id === orgId) ||
        (!orgId && userId && share.user_id === userId))
    ) {
      return share
    }
  },

  search: function (str, nodes, rootNode, tab) {
    var searchNodes = nodes
    var useRootAnyway = false
    if (!/^\s*</.test(str)) {
      if (!/^\s*>/.test(str) && tab && tab.root_id && tab.pinned) {
        rootNode = Node.get(tab.root_id, nodes)
        useRootAnyway = true
      }

      if (rootNode && (/^\s*>/.test(str) || useRootAnyway)) {
        searchNodes = Node.subTree(rootNode, nodes)
      }
    }

    str = str.replace(/^\s*[<>]\s*/, '').trim()
    var result = searchNodes.filter(el => {
      return Boolean(
        (el.title && el.title.toLowerCase().indexOf(str.toLowerCase()) > -1) ||
          (el.body && el.body.toLowerCase().indexOf(str.toLowerCase()) > -1) ||
          (el.description &&
            el.description.toLowerCase().indexOf(str.toLowerCase()) > -1)
      )
    })
    return result
  },

  filter: function (app, filter, nodes, rootNode) {
    filter = filter || {}
    var filterNodes = nodes
    if (rootNode) {
      filterNodes = Node.subTree(rootNode, nodes)
    }

    let filteredNodes = filterNodes
    if (filter.users && filter.users.length) {
      filteredNodes = filterNodes.filter(c => {
        const userIds = app.state.entities.node_users
          .filter(e => e.node_id === c.id)
          .map(e => e.assigned_to)
        return !filter.users
          .map(id => userIds.indexOf(id) !== -1)
          .filter(b => !b).length
      })
    }
    if (filter.labels && filter.labels.length) {
      filteredNodes = filterNodes.filter(c => {
        const labelIds = c.settings.labels || []
        return !filter.labels
          .map(id => labelIds.indexOf(id) !== -1)
          .filter(b => !b).length
      })
    }
    if (filter.hasDue) {
      filteredNodes = filteredNodes.filter(c => c.due_at)
    }
    if (filter.hasNoDue) {
      filteredNodes = filteredNodes.filter(c => !c.due_at)
    }
    if (filter.pastDue) {
      filteredNodes = filteredNodes.filter(
        c => c.due_at && moment(c.due_at).isBefore(moment())
      )
    }
    if (filter.isDueToday) {
      filteredNodes = filteredNodes.filter(
        c =>
          c.due_at &&
          moment(c.due_at).format('YYYY-MM-DD') ===
            moment().format('YYYY-MM-DD')
      )
    }
    if (filter.isDueTomorrow) {
      filteredNodes = filteredNodes.filter(
        c =>
          c.due_at &&
          moment(c.due_at).format('YYYY-MM-DD') ===
            moment()
              .add(1, 'd')
              .format('YYYY-MM-DD')
      )
    }
    if (filter && filter.isDueNextWeek) {
      filteredNodes = filteredNodes.filter(c => {
        if (c.due_at) {
          const due = moment(c.due_at)
          return due.isAfter(moment()) && due.isBefore(moment().add(7, 'days'))
        }
      })
    }
    if (filter.isDueNextMonth) {
      filteredNodes = filteredNodes.filter(c => {
        if (c.due_at) {
          const due = moment(c.due_at)
          return due.isAfter(moment()) && due.isBefore(moment().add(1, 'month'))
        }
      })
    }
    if (filter.hasPermissions) {
      filteredNodes = filteredNodes.filter(c => {
        return c._ && c._.hasOwnProperty('nodePerm')
      })
    }
    return filteredNodes
  },

  displayNodes: function (filteredNodes, allNodes, childrenOnly, actualRoot) {
    const displayNodes = [...filteredNodes]
    filteredNodes.forEach(node => {
      var parentId = node.parent_id || node.remote_id
      while (parentId) {
        var parent = Node.get(parentId, allNodes)

        if (
          parent &&
          (!childrenOnly || !actualRoot || parent.id !== actualRoot.id)
        ) {
          if (displayNodes.indexOf(parent) === -1) {
            displayNodes.push(parent)
          }

          parentId = parent.parent_id
        } else {
          break
        }
      }
    })
    return displayNodes
  },

  getPath: function (leaf, nodes, tab) {
    var remote
    var path = []
    if (leaf) {
      // if (!tab || !tab.share_id) {
      remote = nodes.find(n => n.remote_id === leaf.id)
      if (remote) {
        leaf = remote
      }

      // }
      var parent = Node.get(leaf.parent_id, nodes)
      while (parent) {
        remote = nodes.find(n => n.remote_id === parent.id) // jshint ignore:line

        if (remote && (!tab || !tab.share_id)) {
          parent = remote
        }

        path.push(parent)
        parent = Node.get(parent.parent_id, nodes)
      }

      // console.log('PATH for ' + leaf.id, path);
    }

    return path.reverse()
  },

  getClosestRemote: function (leaf, nodes, tab) {
    var remote
    if (leaf) {
      remote = nodes.find(n => n.remote_id === leaf.id)
      remote = remote || nodes.find(n => n.remote_id === leaf.parent_id)
      if (remote) {
        return remote
      }

      var parent = Node.get(leaf.parent_id, nodes)
      while (parent) {
        remote = nodes.find(n => n.remote_id === parent.id) // jshint ignore:line

        if (remote && (!tab || !tab.share_id)) {
          return remote
        }

        parent = Node.get(parent.parent_id, nodes)
      }
    }
  },

  subTree: function (nodeOrId, nodes, includeChildIndicator, callback) {
    var node = Node.get(nodeOrId, nodes)
    var retNodes = []

    // TODO: Switch this to string-based lookup as well
    if (includeChildIndicator) {
      var recurse = function (rNode, first) {
        var parentId = rNode.remote_id || rNode.id
        var children = Node.children(parentId, nodes)
        if (children.length && includeChildIndicator) {
          rNode._hasChildren = true
        }

        var next = children.find(child => !child.prev_id)
        while (next) {
          recurse(next)
          next = children.find(child => child.prev_id === next.id) // jshint ignore:line
        }

        if (!first) {
          retNodes.push(rNode)
        }
      }

      if (node) {
        recurse(node, true)
      }

      return retNodes
    } else {
      if (node.remote_id) {
        node = Node.get(node.remote_id, nodes)
      }
      // var t0 = new Date().getTime();
      var strNodes = nodes.filter(n =>
        new RegExp(node.id.replace(/-/g, '_') + '.').test(n.path)
      )
      var remotes = strNodes.filter(n => n.remote_id)
      remotes.forEach(function (n) {
        var remoteRe = new RegExp(n.remote_id.replace(/-/g, '_') + '.')
        var remoteNodes = nodes.filter(nd => remoteRe.test(nd.path))
        strNodes = strNodes.concat(remoteNodes)
      })
      // var t1 = new Date().getTime();
      // console.log('strNodes:', strNodes.length);
      // strNodes.forEach(function(n) {
      //   // console.log(n.title);
      // });
      // console.log('strNodes time:', t1 - t0 + 'ms');
      if (typeof callback === 'function') {
        callback(strNodes)
      } else {
        return strNodes
      }
    }
  },

  children: function (nodeOrId, nodes) {
    var t0 = new Date().getTime()
    var children
    var id
    if (nodeOrId) {
      id = typeof nodeOrId === 'string' ? nodeOrId : nodeOrId.id
    }

    if (id) {
      children = nodes.filter(n => n.parent_id === id && !n.inbox)
    } else {
      children = nodes.filter(
        n =>
          (n.parent_id === null || typeof n.parent_id === 'undefined') &&
          !n.inbox
      )
    }

    var sorted = []
    var next = children.find(child => !child.prev_id)
    while (next) {
      sorted.push(next)
      next = children.find(child => child.prev_id === next.id) // jshint ignore:line
    }
    var t1 = new Date().getTime()
    var diff = t1 - t0
    window._app.nodeChildrenTimer = window._app.nodeChildrenTimer
      ? window._app.nodeChildrenTimer + diff
      : diff

    return sorted
  },

  completionStatus: function (nodeOrId, nodes) {
    let id
    if (nodeOrId) {
      id = typeof nodeOrId === 'string' ? nodeOrId : nodeOrId.id
    }
    if (id) {
      const node = Node.get(id, nodes)
      if (node && ['todo', 'project'].indexOf(node.type) !== -1) {
        const todos =
          node.type === 'todo'
            ? [node]
            : Node.subTree(id, nodes).filter(n => {
              return n.type === 'todo'
            })
        let todoCount = 0
        let doneCount = 0
        let allTodoItems = []
        let allDoneTodoItems = []
        todos.forEach(todo => {
          const todoItems = Node.subTree(todo, nodes).filter(n => {
            return n.type !== 'todo'
          })
          const doneTodoItems = todoItems.filter(n => {
            return n.completed
          })
          allTodoItems = allTodoItems.concat(todoItems)
          allDoneTodoItems = allDoneTodoItems.concat(doneTodoItems)
          todoCount += todoItems.length
          doneCount += doneTodoItems.length
        })
        return {
          done: doneCount,
          total: todoCount,
          percentage: parseInt(doneCount / todoCount * 100)
        }
      } else {
        return {}
      }
    } else {
      return {}
    }
  },

  element: function (id) {
    return $('.node[data-node-id=' + id + ']')
  },

  getAccess: function (node, params) {
    var access = constants.NO_ACCESS
    const nodes = params.updatedState.entities
      ? params.updatedState.entities.nodes
      : params.state.entities.nodes
    var org = params.org()
    if (org) {
      var isOwner = params.isOwner(org, params.user())
      access = isOwner ? constants.OWNER_ACCESS : org.default_access_level
      // TODO: Take into account node_permissions and group_permissions
    }
    var parent = Node.get(node.parent_id, nodes)
    if (parent) {
      access = parent._.access
    } else {
      var remote = Node.get(node.remote_id, nodes)
      if (remote) {
        access = remote._.access
      } else {
        // Root-level node in personal tree
        access = constants.OWNER_ACCESS
      }
    }
    return access
  },

  new: function (data, params) {
    var time = new Date().toISOString()
    if (typeof data === 'undefined') data = {}
    data.id = uuid.v4()
    data.short_id = params._shortId()
    data.created_at = time
    data.updated_at = time
    data.comment_count = 0
    data.format = 'markdown'
    if (!data._) data._ = {}
    if (!data.settings) data.settings = {}
    if (!data.title) data.title = ''
    if (!data.type) data.type = 'list'
    if (!data.parent_id) data.parent_id = null
    if (!data.prev_id) data.prev_id = null
    data.path = Node.constructPath(data, params.state.entities.nodes)
    if (!data._.hasOwnProperty('access')) {
      data._.access = Node.getAccess(data, params)
    }
    return data
  },

  constructPath: function (data, nodes) {
    var path = data.id
    var parent = Node.get(data.parent_id, nodes)
    var finder = function (parent, n) {
      return n.id === parent.id
    }
    while (parent) {
      if (parent.path) {
        path = parent.path + '.' + path
        break
      } else {
        path = parent.id + '.' + path
      }

      parent = nodes.find(finder.bind(Node, parent))
      if (!parent) {
        path = 'ROOT.' + path
      }
    }

    path = path.replace(/-/g, '_')
    return path
  },

  parseHtml: function (html) {
    if (!html) {
      return ''
    }

    return html
      .replace(/&amp;/gm, '&')
      .replace(/&nbsp;/gm, String.fromCharCode(160))

    // Non-breaking space!
  },

  // Check to see if a given id should really be a remote_id, or the (remote) id of the current root
  resolvedParentId: function (id, nodes, root) {
    if (id) {
      var node = Node.get(id, nodes)
      if (node && node.remote_id) {
        id = node.remote_id
      }
    } else {
      if (root) {
        id = root.remote_id ? root.remote_id : root.id
      }
    }

    return id
  },

  site: function (node, nodes) {
    var parent = node
    while (true) {
      // eslint-disable-line no-constant-condition
      parent = Node.get(parent.parent_id, nodes)
      if (parent) {
        if (parent.type === 'site') {
          break
        }
      } else {
        break
      }
    }

    return parent
  },

  slug: function (node, site) {
    if (node.slugs && node.slugs.length && node.slugs[0] !== null) {
      if (site) {
        return node.slugs.find(
          slug => slug.site_id === site.id && !slug.redirect
        )
      } else {
        return node.slugs.find(slug => !slug.site_id && !slug.redirect)
      }
    }
  },

  // TODO: Make this take current org into account?
  getFromSlug: function (slug, nodes, site) {
    var slugObj = nodes.find(node => {
      if (node.slugs && node.slugs.length && node.slugs[0] !== null) {
        if (site) {
          return node.slugs.find(s => s.slug === slug && s.site_id === site.id)
        } else {
          return node.slugs.find(s => s.slug === slug && !s.site_id)
        }
      }
    })
    if (slugObj) {
      return Node.get(slugObj.node_id, nodes)
    }
  },

  parents: function (nodes) {
    var parents = nodes.filter(n => {
      return n.parent_id === null || typeof n.parent_id === 'undefined'
    })
    var sorted = []
    var next = parents.find(parent => !parent.prev_id)
    while (next) {
      sorted.push(next)
      next = parents.find(parent => parent.prev_id === next.id) // jshint ignore:line
    }

    return sorted
  },

  export: function (src, type, nodes) {
    var i
    if (type === 'dump') {
      return JSON.stringify(nodes)
    } else {
      var str = ''
      var indentStr = '  '

      var recursivelyAdd = function (node, lvl) {
        var prefix = new Array(lvl + 1).join(indentStr)
        if (node.title) {
          str += prefix + '- '
          if (type === 'convert' && node.url) {
            str += '['
          }

          if (type === 'convert') {
            str += node.title.replace(/^(\d+)\.(\s+)/g, '$1\\.$2')
          } else {
            str += node.title
          }

          if (type === 'convert' && node.url) {
            str += '](' + node.url + ')'
          }

          str += '\n'
        }

        if (type === 'full' || type === 'convert') {
          if (node.description) {
            var indentedDesc = ''
            var descLines = node.description.split('\n')
            i = 0
            descLines.forEach(function (line) {
              if (i > 0) {
                indentedDesc += '\n' + prefix
              }

              indentedDesc += line
              i++
            })
            str += type === 'full' ? prefix + '"' : '\n  ' + prefix + '*'
            str += indentedDesc
            str += type === 'full' ? '"\n' : '*\n\n'
          }

          if (node.body) {
            var indentedBody = ''
            var bodyLines = node.body.split('\n')
            i = 0
            bodyLines.forEach(function (line) {
              if (i > 0 && type === 'full') {
                indentedBody += '\n' + prefix
              }

              indentedBody += line
              i++
            })
            str += type === 'full' ? prefix + '"""' : '\n\n'
            str += indentedBody
            str += type === 'full' ? '"""\n' : '\n\n'
          }

          if (node.url && type === 'full') {
            str += prefix + '[' + node.url + ']\n'
          }
        }

        Node.children(node.id, nodes).forEach(child => {
          recursivelyAdd(child, lvl + 1)
        })
      }

      if (src) {
        var node = Node.get(src, nodes)
        if (type === 'convert') {
          Node.children(node.id, nodes).forEach(child => {
            recursivelyAdd(child, 0)
          })
        } else {
          recursivelyAdd(node, 0)
        }
      } else {
        Node.parents(nodes).forEach(parent => {
          recursivelyAdd(parent, 0)
        })
      }

      return str
    }
  },

  lastSibling: function (nodes) {
    var next = nodes.find(n => !n.prev_id)
    var savedNext
    var finder = function (next, n) {
      return n.prev_id === next.id
    }
    while (next) {
      savedNext = next
      next = nodes.find(finder.bind(Node, next))
    }

    return savedNext
  },

  clone: function (node) {
    var modNode = { ...node }
    modNode.settings = { ...node.settings }
    if (node.slugs) {
      modNode.slugs = { ...node.slugs }
    }
    return modNode
  },

  // COMBO COMMANDS

  indentNodeAtCursor: function (
    nodeOrId,
    toId,
    newPreviousSiblingId,
    inParams,
    callback
  ) {
    const params = Object.assign({}, inParams, {
      _skipSetState: true,
      _skipFlushUndo: true
    })
    const nodes = params.updatedState.entities
      ? params.updatedState.entities.nodes
      : params.state.entities.nodes
    const node = Node.get(
      typeof nodeOrId === 'string' ? nodeOrId : nodeOrId.id,
      nodes
    )
    const tab = params.tab()

    const doAction = actionParams => {
      // undoCommands.push({obj: 'Node', cmd: 'focus', args: [node.id, Text.savedSelection], persist: false});
      // redoCommands.push({obj: 'Node', cmd: 'focus', args: [node.id, Text.savedSelection], persist: false});
      actionParams.undo.add(actionParams.undoData, true)
      actionParams.setAppState(
        actionParams.updatedState,
        actionParams.stateParams,
        callback
      )
      // actionParams.setAppState(actionParams.updatedState, () => {
      //   Node.focus(node.id, 0, actionParams, callback);
      // });
    }

    // undoCommands = [{obj: 'Node', cmd: 'move', args: [node.id, node.parent_id, node.prev_id]}];
    // redoCommands = [{obj: 'Node', cmd: 'move', args: [node.id, toId, newPreviousSiblingId]}];
    Node.flushTextUndo({ nodeId: node.id }, params, flushParams => {
      const newFlushParams = Object.assign({}, flushParams, {
        _skipUndoAdd: true
      })
      Node.move(
        node,
        toId,
        newPreviousSiblingId,
        newFlushParams,
        moveParams => {
          // undoCommands.push({obj: 'Tab', cmd: 'collapse', args: [tab.id, toId], persist: Boolean(user)});
          // redoCommands.unshift({obj: 'Tab', cmd: 'expand', args: [tab.id, toId], persist: Boolean(user)});
          Tab.expand(tab, toId, moveParams, expandParams => {
            doAction(expandParams)
          })
        }
      )
    })
  },

  outdentNodeAtCursor: function (
    nodeOrId,
    toId,
    newPreviousSiblingId,
    inParams,
    callback
  ) {
    const params = Object.assign({}, inParams, {
      _skipSetState: true,
      _skipFlushUndo: true
    })
    const nodes = params.updatedState.entities
      ? params.updatedState.entities.nodes
      : params.state.entities.nodes
    const node = Node.get(
      typeof nodeOrId === 'string' ? nodeOrId : nodeOrId.id,
      nodes
    )

    const doAction = actionParams => {
      // undoCommands.push({obj: 'Node', cmd: 'focus', args: [node.id, Text.savedSelection], persist: false});
      // redoCommands.push({obj: 'Node', cmd: 'focus', args: [node.id, Text.savedSelection], persist: false});
      actionParams.undo.add(actionParams.undoData, true)
      actionParams.setAppState(
        actionParams.updatedState,
        actionParams.stateParams,
        callback
      )
    }

    // undoCommands = [{obj: 'Node', cmd: 'move', args: [node.id, node.parent_id, node.prev_id]}];
    // redoCommands = [{obj: 'Node', cmd: 'move', args: [node.id, toId, newPreviousSiblingId]}];
    Node.flushTextUndo({ nodeId: node.id }, params, flushParams => {
      const newFlushParams = Object.assign({}, flushParams, {
        _skipUndoAdd: true
      })
      Node.move(
        node,
        toId,
        newPreviousSiblingId,
        newFlushParams,
        moveParams => {
          doAction(moveParams)
        }
      )
    })
  },

  splitNodeAtCursor: function (
    nodeOrId,
    position,
    pre,
    post,
    inParams,
    callback
  ) {
    const params = Object.assign({}, inParams, {
      _skipSetState: true,
      _skipFocus: true
    })
    const initialNodes = params.updatedState.entities
      ? params.updatedState.entities.nodes
      : params.state.entities.nodes
    const initialNode = Node.get(
      typeof nodeOrId === 'string' ? nodeOrId : nodeOrId.id,
      initialNodes
    )

    const doAction = (actionParams, newNode) => {
      // if (Text.savedSelection && Text.savedSelection.end === 0) {
      if (Text.savedSelection) {
        actionParams.undoData.undo.push({
          obj: 'Node',
          cmd: 'focus',
          args: [initialNode.id, Text.savedSelection],
          persist: false
        })
        actionParams.undoData.redo.push({
          obj: 'Node',
          cmd: 'focus',
          args: [newNode.id, Text.savedSelection],
          persist: false
        })
      }
      actionParams.undo.add(actionParams.undoData, true)
      actionParams.setAppState(
        actionParams.updatedState,
        actionParams.stateParams,
        () => {
          if (Text.savedSelection && Text.savedSelection.end === 0) {
            Node.focus(newNode.id, Text.savedSelection, actionParams, callback)
          } else {
            Node.focus(
              initialNode.id,
              { start: 0, end: 0 },
              actionParams,
              callback
            )
          }
        }
      )
    }

    Node.flushTextUndo({ nodeId: initialNode.id }, params, flushParams => {
      var richTextEnabled = flushParams.app.featureEnabled('rich_titles')
      const newFlushParams = Object.assign({}, flushParams, {
        _skipFlushUndo: true,
        _skipUndoAdd: true
      })
      const nodes = flushParams.updatedState.entities
        ? flushParams.updatedState.entities.nodes
        : flushParams.state.entities.nodes
      const node = Node.get(initialNode.id, nodes)
      let newPre, newPost
      if (richTextEnabled) {
        newPre = flushParams.app.htmlToMarkdown(pre)
        newPost = flushParams.app.htmlToMarkdown(post)
      } else {
        newPre = node.title.substring(0, position)
        newPost = node.title.substring(position).trim()
      }
      const newNode = Node.new(
        {
          parent_id: node.parent_id,
          prev_id: node.prev_id,
          title: newPre,
          user_id: node.user_id,
          org_id: node.org_id
        },
        params
      )
      // console.log('newNode', newNode);
      Node.add(newNode, newFlushParams, addParams => {
        // console.log('did ADD');
        // const modifyArgs = {title: post, prev_id: newNode.id};
        const modifyArgs = { title: newPost }
        Node.modify(node, modifyArgs, addParams, modifyParams => {
          window._app.original_title = post
          // console.log('did MODIFY');
          doAction(modifyParams, newNode)
        })
      })
    })
  },

  mergeNodesAtCursor: function (nodeOrId, inParams, callback) {
    const params = Object.assign({}, inParams, {
      _skipSetState: true,
      _skipFlushUndo: true,
      _skipFocus: true,
      _doBlur: true
    })
    const initialNodes = params.updatedState.entities
      ? params.updatedState.entities.nodes
      : params.state.entities.nodes
    const initialNode = Node.get(
      typeof nodeOrId === 'string' ? nodeOrId : nodeOrId.id,
      initialNodes
    )

    const doAction = (
      actionParams,
      newHtml,
      keepNode,
      nonKeepNode,
      focusStart
    ) => {
      const nodes = actionParams.updatedState.entities
        ? actionParams.updatedState.entities.nodes
        : actionParams.state.entities.nodes
      const node = Node.get(initialNode.id, nodes)
      // XXX: Would be nice if this could be kept outside this module
      window._app.original_title = newHtml
      // if (node.prev_id === otherNode.id) {
      //   if (this.debug)  console.log('remove [' + otherNode.id + ']'); // eslint-disable-line no-console
      //   if (this.debug)  console.log('modify [' + node.id + '] from [' + node.title + '] to [' + newHtml + ']'); // eslint-disable-line no-console
      //   redoCommands = [{obj: 'Node', cmd: 'remove', args: [nonKeepNode.id]},
      //                   {obj: 'Node', cmd: 'modify', args: [keepNode.id, {'title': newHtml}]},
      //                   {obj: 'Node', cmd: 'focus', args: [keepNode.id, {start: focusStart}], persist: false}];
      //   undoCommands = [{obj: 'Node', cmd: 'modify', args: [keepNode.id, {'title': keepNode.title}]},
      //                   {obj: 'Node', cmd: 'add', args: [nonKeepNode]},
      //                   {obj: 'Node', cmd: 'focus', args: [keepNode.id, Text.savedSelection], persist: false}];

      // MERGE WITH SIBLING
      const modifyArgs = { title: newHtml }
      if (keepNode === node) {
        // modifyArgs.prev_id = otherNode.prev_id;
      }

      Node.remove(nonKeepNode, actionParams, removeParams => {
        Node.modify(keepNode, modifyArgs, removeParams, modifyParams => {
          modifyParams.undo.add(modifyParams.undoData, true)
          modifyParams.setAppState(
            modifyParams.updatedState,
            modifyParams.stateParams,
            () => {
              Node.focus(keepNode.id, focusStart)
              if (typeof callback === 'function') {
                callback()
              }
            }
          )
        })
      })

      // MERGE WITH PARENT
      // } else if (node.parent_id === otherNode.id) {
      //   var rootNode = params.rootNode();
      //   if (keepNode === node) {
      //     // if (this.debug)  console.log('xmodify [' + otherNode.id + '] from [' + otherNode.title + '] to [' + newHtml + ']'); // eslint-disable-line no-console
      //     // if (this.debug)  console.log('xremove [' + node.id + '] from [' + otherNode.id + ':' + node.prev_id + ']'); // eslint-disable-line no-console
      //     redoCommands = [{obj: 'Node', cmd: 'modify', args: [nonKeepNode.id, {title: newHtml,
      //                                                                          body: keepNode.body,
      //                                                                          type: keepNode.type,
      //                                                                          settings: keepNode.settings,
      //                                                                         }]},
      //                     {obj: 'Node', cmd: 'remove', args: [keepNode.id]}];
      //     if (!rootNode || (rootNode.id !== nonKeepNode.id)) {
      //       redoCommands.push({obj: 'Node', cmd: 'focus', args: [nonKeepNode.id, {start: focusStart}], persist: false});
      //     }

      //     undoCommands = [{obj: 'Node', cmd: 'add', args: [keepNode]},
      //                     {obj: 'Node', cmd: 'modify', args: [nonKeepNode.id, {'title': nonKeepNode.title}]},
      //                     {obj: 'Node', cmd: 'focus', args: [keepNode.id, Text.savedSelection], persist: false}];
      //   } else {
      //     // if (this.debug)  console.log('xmodify [' + otherNode.id + '] from [' + otherNode.title + '] to [' + newHtml + ']'); // eslint-disable-line no-console
      //     // if (this.debug)  console.log('xremove [' + node.id + '] from [' + otherNode.id + ':' + node.prev_id + ']'); // eslint-disable-line no-console
      //     redoCommands = [{obj: 'Node', cmd: 'modify', args: [keepNode.id, {'title': newHtml}]},
      //                     {obj: 'Node', cmd: 'remove', args: [nonKeepNode.id]}];
      //     if (!rootNode || (rootNode.id !== keepNode.id)) {
      //       redoCommands.push({obj: 'Node', cmd: 'focus', args: [keepNode.id, {start: focusStart}], persist: false});
      //     }

      //     undoCommands = [{obj: 'Node', cmd: 'add', args: [nonKeepNode]},
      //                     {obj: 'Node', cmd: 'modify', args: [keepNode.id, {'title': keepNode.title}]},
      //                     {obj: 'Node', cmd: 'focus', args: [nonKeepNode.id, Text.savedSelection], persist: false}];
      //   }
      // }
    }

    let otherNode
    if (initialNode.prev_id) {
      const prev = Node.get(initialNode.prev_id, initialNodes)
      if (prev) {
        const prevChildren = Node.children(prev.id, initialNodes)
        if (!prevChildren.length) {
          otherNode = prev
        }
      }
      // } else if (initialNode.parent_id) {
      //   parent = Node.get(initialNode.parent_id, initialNodes);
      //   if (parent) {
      //     otherNode = parent;
      //   }
    }

    const nonSimpleTypes = [
      'site',
      'bookmark',
      'project',
      'meeting',
      'person',
      'company'
    ]
    const forbiddenTypes = ['remote']

    if (
      otherNode &&
      (forbiddenTypes.indexOf(initialNode.type) === -1 &&
        forbiddenTypes.indexOf(otherNode.type) === -1)
    ) {
      var keepNode = initialNode
      var nonKeepNode = otherNode
      var doMerge = true

      if (
        otherNode.mode === 'board' ||
        nonSimpleTypes.indexOf(otherNode.type) !== -1
      ) {
        keepNode = otherNode
        nonKeepNode = initialNode
        if (nonSimpleTypes.indexOf(initialNode.type) !== -1) {
          doMerge = false
        }
      }

      if (doMerge) {
        Node.flushTextUndo({ nodeId: initialNode.id }, params, flushParams => {
          const richTextEnabled = flushParams.app.featureEnabled('rich_titles')
          const flushedNodes = flushParams.updatedState.entities
            ? flushParams.updatedState.entities.nodes
            : flushParams.state.entities.nodes
          const flushedNode = Node.get(
            typeof nodeOrId === 'string' ? nodeOrId : nodeOrId.id,
            flushedNodes
          )
          const newTitle = flushedNode.title

          const otherHtml = otherNode.title
          const leftover = newTitle
          const newHtml = (
            otherHtml +
            (richTextEnabled ? ' ' : '') +
            leftover
          ).trim()
          const focusStart = richTextEnabled
            ? $('<div>' + otherNode.title_rendered + '</div>').text().length + 1
            : otherHtml.length

          const newFlushParams = Object.assign({}, flushParams, {
            _doBlur: false,
            _skipUndoAdd: true
          })
          // const newFlushParams = Object.assign({}, flushParams, {_doBlur: false});
          doAction(newFlushParams, newHtml, keepNode, nonKeepNode, focusStart)
        })
      } else {
        if (typeof callback === 'function') {
          callback(inParams)
        }
      }
    } else {
      if (typeof callback === 'function') {
        callback(inParams)
      }
    }
  },

  recursiveToggle: function (nodeOrId, inParams, callback) {
    const params = Object.assign({}, inParams, {})
    const initialNodes = params.updatedState.entities
      ? params.updatedState.entities.nodes
      : params.state.entities.nodes
    const srcNode = Node.get(
      typeof nodeOrId === 'string' ? nodeOrId : nodeOrId.id,
      initialNodes
    )
    const initialNode = srcNode.remote_id
      ? Node.get(srcNode.remote_id, initialNodes)
      : srcNode

    const doAction = actionParams => {
      const nodes = actionParams.updatedState.entities
        ? actionParams.updatedState.entities.nodes
        : actionParams.state.entities.nodes
      const node = Node.get(initialNode.id, nodes)

      var tab = params.tab()
      var currExpanded = tab.expanded_nodes || []
      var idx = currExpanded.indexOf(node.id)
      var command = idx === -1 ? 'expand' : 'collapse'
      var tree = Node.subTree(node, nodes, true)
      var childrenWithChildren = tree.filter(n => {
        return n._hasChildren
      })
      var nodeIds = [node.id].concat(
        childrenWithChildren.map(n => {
          return n.id
        })
      )

      Tab[command](tab.id, nodeIds, params, tabParams => {
        if (typeof callback === 'function') {
          callback(tabParams)
        }
        window.Intercom &&
          window.Intercom('trackEvent', 'used-recursive-toggle')
      })
    }

    Node.flushTextUndo({ nodeId: initialNode.id }, params, flushParams => {
      doAction(flushParams)
    })
  }
}

module.exports = Node
