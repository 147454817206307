require('../../../../node_modules/tether-shepherd/dist/css/shepherd-theme-arrows.css')
var Shepherd = require('tether-shepherd')
// var Misc = require('./misc');

module.exports = {
  initTours: function () {
    window._app = window._app || {}
    window._app.tours = window._app.tours || {}

    window._app.tours.main = new Shepherd.Tour({
      defaults: {
        classes: 'shepherd-theme-arrows',
        scrollTo: false
      }
    })

    window._app.tours.main.addStep('intro', {
      title: 'Welcome to Notebase!',
      text: 'This short tour will show you a few important things before you start using Notebase. <br /><br />It won’t take long, promise.',
      buttons: [
        {
          text: 'No thanks',
          classes: 'shepherd-button-secondary',
          action: window._app.app.endTour.bind(window._app.app, 'main', {end_step: 'intro', finished: false})
        },
        {
          text: 'Show me',
          action: window._app.tours.main.next
        }
      ]
    })

    window._app.tours.main.addStep('first_node', {
      text: 'Lists are fun, Write "Hello world!" and press enter to create your first list element!',
      attachTo: '.level-0 .node-content top',
      when: {
        show: function () {
          // Misc.animateSvgOverlay('#tabs');
        }
      },
      // tetherOptions: {
      //   attachment: 'top center',
      //   targetAttachment: 'top center',
      //   offset: '10px 0',
      // },
      buttons: [
        {
          text: 'Back',
          classes: 'shepherd-button-secondary',
          action: window._app.tours.main.back
        },
        {
          text: 'Next',
          action: window._app.tours.main.next
        }
      ]
    })

    window._app.tours.main.addStep('indent', {
      text: 'You can easily indent your list elements by pressing "tab"',
      attachTo: '.level-0 .node-content top',
      when: {
        show: function () {
          // Misc.animateSvgOverlay('#tabs');
        }
      },
      // tetherOptions: {
      //   attachment: 'top center',
      //   targetAttachment: 'top center',
      //   offset: '10px 0',
      // },
      buttons: [
        {
          text: 'Back',
          classes: 'shepherd-button-secondary',
          action: window._app.tours.main.back
        },
        {
          text: 'Next',
          action: window._app.tours.main.next
        }
      ]
    })

    window._app.tours.main.addStep('zoom', {
      text: 'Press the bullet to zoom into your list item.',
      attachTo: '.level-0 .bullet top',
      when: {
        show: function () {
          // Misc.animateSvgOverlay('#tabs');
        }
      },
      // tetherOptions: {
      //   attachment: 'top center',
      //   targetAttachment: 'top center',
      //   offset: '10px 0',
      // },
      buttons: [
        {
          text: 'Back',
          classes: 'shepherd-button-secondary',
          action: window._app.tours.main.back
        },
        {
          text: 'Next',
          action: window._app.tours.main.next
        }
      ]
    })

    window._app.tours.main.addStep('tabs', {
      text: 'Tabs are a great way to group your stuff. Use a separate tab for each main part <br /> ' +
            'of your Notebase. A tab is like a mini-Notebase, especially if you <em>pin</em> it, <br /> ' +
            'which basically locks it to a root item of your choosing.',
      attachTo: '#tabs .add right',
      when: {
        show: function () {
          // Misc.animateSvgOverlay('#tabs');
        }
      },
      buttons: [
        {
          text: 'Back',
          classes: 'shepherd-button-secondary',
          action: window._app.tours.main.back
        },
        {
          text: 'Next',
          action: window._app.tours.main.next
        }
      ]
    })

    window._app.tours.main.addStep('orgSwitcher', {
      text: 'You have a personal Notebase, but you can also be part of teams. <br /> Press the "+" button to create your first team.',
      attachTo: '#org-switcher right',
      when: {
        show: function () {
          // Misc.animateSvgOverlay('#tabs');
        }
      },
      buttons: [
        {
          text: 'Back',
          classes: 'shepherd-button-secondary',
          action: window._app.tours.main.back
        },
        {
          text: 'Next',
          action: window._app.tours.main.next
        }
      ]
    })

    // window._app.tours.main.addStep('inbox', {
    //   text: 'The bookmark indicator shows you the number of items in you Inbox (it’s currently empty). <br /> ' +
    //         'The Inbox holds any links you have saved from the web and not yet moved to their <br /> ' +
    //         'final location.',
    //   attachTo: '#inbox-indicator bottom',
    //   when: {
    //     show: function() {
    //       // Misc.animateSvgOverlay('#inbox-info', {extraWidth: 10});
    //     },
    //   },
    //   buttons: [
    //     {
    //       text: 'Back',
    //       classes: 'shepherd-button-secondary',
    //       action: window._app.tours.main.back,
    //     },
    //     {
    //       text: 'Next',
    //       action: window._app.tours.main.next,
    //     },
    //   ],
    // });

    // window._app.tours.main.addStep('due-list', {
    //   text: 'Items that have a due date will show up here. By default, only items below the <br /> ' +
    //         'current root item are visible. You can change this behaviour later if you want.',
    //   attachTo: '#due-list',
    //   when: {
    //     show: function() {
    //       // Misc.animateSvgOverlay('#due-list');
    //     },
    //   },
    //   tetherOptions: {
    //     offset: '0 -10px',
    //   },
    //   buttons: [
    //     {
    //       text: 'Back',
    //       classes: 'shepherd-button-secondary',
    //       action: window._app.tours.main.back,
    //     },
    //     {
    //       text: 'Next',
    //       action: window._app.tours.main.next,
    //     },
    //   ],
    // });

    // window._app.tours.main.addStep('control-panel', {
    //   text: 'Go here to toggle some useful stuff, like the TODO List, <br />the File Browser and various help content.',
    //   attachTo: '#control-panel',
    //   when: {
    //     show: function() {
    //       // Misc.animateSvgOverlay('#control-panel');
    //     },
    //   },
    //   tetherOptions: {
    //     attachment: 'bottom left',
    //     targetAttachment: 'top left',
    //     offset: '10px -10px',
    //   },
    //   buttons: [
    //     {
    //       text: 'Back',
    //       classes: 'shepherd-button-secondary',
    //       action: window._app.tours.main.back,
    //     },
    //     {
    //       text: 'Next',
    //       action: window._app.tours.main.next,
    //     },
    //   ],
    // });

    // window._app.tours.main.addStep('intercom', {
    //   text: 'Click this to talk to us in real-time. We love feedback!',
    //   attachTo: '#support-chat-launcher',
    //   when: {
    //     show: function() {
    //       // Misc.animateSvgOverlay('#support-chat-launcher');
    //     },
    //   },
    //   tetherOptions: {
    //     attachment: 'bottom right',
    //     targetAttachment: 'top center',
    //     offset: '10px -10px',
    //   },
    //   buttons: [
    //     {
    //       text: 'Back',
    //       classes: 'shepherd-button-secondary',
    //       action: window._app.tours.main.back,
    //     },
    //     {
    //       text: 'Next',
    //       action: window._app.tours.main.next,
    //     },
    //   ],
    // });

    window._app.tours.main.addStep('done', {
      text: 'That’s it, you’re done - thanks for taking the tour. Enjoy Notebase!',
      when: {
        show: function () {
          // Misc.animateSvgOverlay(null);
        }
      },
      buttons: [
        {
          text: 'Close',
          action: window._app.app.endTour.bind(window._app.app, 'main', {end_step: 'done', finished: true})
        }
      ]
    })
  }
}
