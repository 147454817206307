import React from 'react'
import ReactTooltip from 'react-tooltip'

import Tab from '../tab'

class Tabs extends React.Component {
  addTab = () => {
    this.props.app.addTab()
  }

  render () {
    const org = this.props.org
    const tabs = []
    const allTabs = this.props.app.state.entities.tabs
    let next = org
      ? allTabs.find(tab => tab.org_id === org.id && !tab.prev_id)
      : allTabs.find(tab => !tab.org_id && !tab.prev_id)
    while (next) {
      tabs.push(
        <Tab
          app={this.props.app}
          user={this.props.user}
          org={this.props.org}
          key={next.id}
          tab={next}
        />
      )
      // next = _.xfind(this.props.tabs, function(tab) { return tab.prev_id === next.id; });
      next = this.props.app.state.entities.tabs.find(
        tab => tab.prev_id === next.id
      )
    }
    tabs.push(
      <div
        className='add'
        data-tip
        data-for='new-tab'
        key='add'
        onClick={this.addTab}
        title='Add a new tab'
      >
        <span className='octicon octicon-plus' />
      </div>
    )
    // tabs.push(<div className="spacer" key="spacer"></div>);

    return (
      <div id='tabs'>
        {tabs}
        <ReactTooltip
          id='new-tab'
          type='dark'
          effect='solid'
          place='bottom'
          data-delay-show='1000'
        >
          <span>New tab</span>
        </ReactTooltip>
      </div>
    )
  }
}

module.exports = Tabs
