import './styles.css'

import React from 'react'

class Stripes extends React.Component {
  render () {
    let stripes = []
    for (var i = 1; i <= this.props.level; i++) {
      stripes.push(
        <div key={'stripe-' + i} className={'single-stripe level-' + i} />
      )
    }
    return <div className='stripes'>{stripes}</div>
  }
}

module.exports = Stripes
