import React from 'react'
import classNames from 'classnames'

import Fave from '../../js/fave'
import Misc from '../../js/misc'

import constants from '../..//lib/constants'

import './styles.css'

export default class NodeIndicators extends React.Component {
  toggleFave = () => {
    const { app, node } = this.props
    var meFave = app.state.entities.faves.find(e => {
      return e.node_id === node.id
    })
    if (meFave) {
      Fave.remove(meFave.id, app.getCommonParams())
    } else {
      var newFave = { node_id: node.id, org_id: app.orgId() }
      Fave.add(newFave, app.getCommonParams())
    }
  }

  accessName = access => {
    switch (access) {
      case constants.NO_ACCESS:
        return 'no-access'
      case constants.READ_ACCESS:
        return 'read-access'
      case constants.WRITE_ACCESS:
        return 'write-access'
      case constants.ADMIN_ACCESS:
        return 'admin-access'
      case constants.OWNER_ACCESS:
        return 'owner-access'
    }
  }

  openPerms = () => {
    const { app, node } = this.props
    app.setPanel('permissions-overlay', {
      data: { node: node },
      title: 'Permissions for "' + Misc.truncate(node.title, 30) + '"'
    })
  }

  render () {
    const { app, node } = this.props

    const nodePerms = (
      <div
        className={`nodeperms ${
          node._.hasOwnProperty('nodePerm') ? 'active' : ''
        } ${this.accessName(node._.nodePerm)}`}
        onClick={this.openPerms}
      >
        <i className='fa fa-fw fa-lock' />
      </div>
    )

    const nodeIsFave = app.state.entities.faves.find(e => e.node_id === node.id)
    const faveStar = nodeIsFave ? 'star' : 'star-o'
    const faveClasses = classNames({
      'fave-indicator': true,
      'is-fave': Boolean(nodeIsFave)
    })
    const fave = (
      <span className={faveClasses}>
        <i className={`fa fa-fw fa-${faveStar}`} onClick={this.toggleFave} />
      </span>
    )

    // <div className='perms-container'>
    //   <div className={'perms ' + accessName} />
    //   {userPerms}
    //   {nodePerms}
    //   {groupPerms}
    // </div>

    return (
      <div className='node-indicators'>
        <div className='perms-container'>{nodePerms}</div>
        {fave}
      </div>
    )
  }
}
