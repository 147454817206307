import './styles.css'

import React from 'react'
import $ from 'jquery'

import classNames from 'classnames'

class SelectMenu extends React.Component {
  constructor (props, context) {
    super(props, context)
    var _this = this

    this.state = {
      selectedItem: props.items.find(i => i.value === _this.props.defaultValue),
      open: false,
      filter: ''
    }
  }

  toggle = () => {
    this.setState({ open: !this.state.open }, function () {
      if (this.state.open) {
        $(this.select)
          .find('input.js-filterable-field')
          .focus()
      }
    })
  }

  filter = ev => {
    var menu = $(this.select)
    this.setState({ filter: ev.target.value }, function () {
      menu.find('.select-menu-item:first').addClass('navigation-focus')
    })
  }

  focusItem = item => {
    var menu = $(this.select)
    if (!item || !item.length) {
      item = menu.find('.select-menu-item:first')
    }
    menu.find('.select-menu-item').removeClass('navigation-focus')
    item.addClass('navigation-focus')
  }

  focusNextItem = item => {
    if (item && item.length) {
      var next = item.next('.select-menu-item')
      if (next.length) {
        this.focusItem(next)
      }
    } else {
      this.focusItem()
    }
  }

  focusPrevItem = item => {
    if (item && item.length) {
      var prev = item.prev('.select-menu-item')
      if (prev.length) {
        this.focusItem(prev)
      }
    } else {
      this.focusItem()
    }
  }

  selectItem = item => {
    this.setState({ selectedItem: item, filter: '' }, function () {
      if (this.props.onChange) {
        this.props.onChange({ target: this.input }, item)
      }
      // $(this.refs.input).val(item.value).change();
      this.toggle()
    })
  }

  handleChange = () => {
    // console.log('dMMIT');
    // if (this.props.onChange) {
    //   this.props.onChange(ev, this.state.selectedItem);
    // }
  }

  componentDidMount () {
    var _this = this
    $(document).on('keyup', function (ev) {
      if (_this.select) {
        var menu = $(_this.select)
        var openMenu = $(ev.target).closest('.select-menu.active')
        if (openMenu.length && openMenu.get(0) === menu.get(0)) {
          var item = menu.find('.select-menu-item.navigation-focus')
          switch (ev.keyCode) {
            case 38: // up
              _this.focusPrevItem(item)
              break
            case 40: // down
              _this.focusNextItem(item)
              break
            case 27: // esc
              _this.toggle()
              break
            case 13: // enter
              var dataItem =
                _this.props.items[parseInt(item.data('item-index'))]
              _this.selectItem(dataItem)
              break
          }
        }
      }
    })

    $(document).on('mouseenter', '.select-menu-item', function (ev) {
      if (_this.select) {
        var menu = $(_this.select)
        var openMenu = $(ev.target).closest('.select-menu.active')
        if (openMenu.length && openMenu.get(0) === menu.get(0)) {
          _this.focusItem($(this))
        }
      }
    })

    $(document).on('click', function (ev) {
      var menu = $(ev.target).closest('.select-menu.active')
      if (_this.state && _this.state.open && !menu.length) {
        _this.toggle()
      }
    })
  }

  render () {
    var _this = this
    var selectId = this.props.id || ''
    var selectClasses = classNames({
      active: this.state.open,
      'select-menu': true,
      flat: true,
      'js-menu-container': true,
      'js-select-menu': true
    })
    if (this.props.classes) {
      selectClasses += ' ' + this.props.classes
    }
    var buttonClasses = classNames({
      selected: this.state.open,
      minibutton: true,
      'select-menu-button': true,
      'js-menu-target': true
    })
    var content
    if (this.state.open) {
      var re
      if (this.state.filter !== null) {
        re = new RegExp(this.state.filter, 'gi')
      }
      var items = []
      var i = 0
      this.props.items.forEach(item => {
        var itemClasses = classNames({
          selected:
            _this.state.selectedItem &&
            _this.state.selectedItem.value === item.value,
          'select-menu-item': true,
          'js-navigation-item': true
        })
        if (!re || (re && re.test(item.name))) {
          var selectHandler = _this.selectItem.bind(_this, item)
          items.push(
            <div
              key={'item-' + i}
              data-item-index={i}
              className={itemClasses}
              onClick={selectHandler}
            >
              <span className='select-menu-item-icon octicon octicon-check' />
              <div className='select-menu-item-text js-select-button-text'>
                {item.name}
              </div>
            </div>
          )
        }
        i++
      })

      var filter
      if (this.props.filterTitle || this.props.filter) {
        filter = (
          <div className='select-menu-filters'>
            <div className='select-menu-text-filter'>
              <input
                type='text'
                id='text-filter-field'
                className='js-filterable-field js-navigation-enable'
                placeholder={
                  this.props.filterTitle
                    ? this.props.filterTitle
                    : 'Filter items'
                }
                onChange={this.filter}
              />
            </div>
          </div>
        )
      }
      content = (
        <div className='select-menu-modal-holder js-menu-content js-navigation-container'>
          <div className='select-menu-modal'>
            <div className='select-menu-header'>
              <span className='select-menu-title'>
                {this.props.title
                  ? this.props.title
                  : 'Select an item from the list'}
              </span>
              <span
                className='octicon octicon-x js-menu-close'
                role='button'
                ariaLabel='Close'
                onClick={this.toggle}
              />
            </div>

            {filter}

            <div className='select-menu-list'>
              {/*
              <div className="select-menu-clear-item select-menu-item js-navigation-item">
                <span className="select-menu-item-icon octicon octicon-x"></span>
                <div className="select-menu-item-text">Clear item</div>
              </div>
              */}

              <div>{items}</div>
            </div>

            {/*
            <div className="select-menu-footer">
              <span className="octicon octicon-gear"></span>&nbsp;
              <a href="#">Extra options</a>
            </div>
            */}

            <div className='select-menu-loading-overlay'>Loading…</div>
          </div>
        </div>
      )
    }
    var icon
    if (this.props.icon) {
      icon = <span className={'octicon octicon-' + this.props.icon} />
    }
    return (
      <div
        className={selectClasses}
        ref={select => {
          this.select = select
        }}
      >
        <input
          type='hidden'
          ref={input => {
            this.input = input
          }}
          data-name={this.props.dataName}
          data-obj={this.props.dataObj}
          id={selectId}
          value={this.state.selectedItem ? this.state.selectedItem.value : null}
          onChange={this.handleChange}
        />
        <span className={buttonClasses} onClick={this.toggle}>
          {icon} <i>{this.props.label ? this.props.label : 'Select'}:</i>&nbsp;
          <span className='js-select-button'>
            {this.state.selectedItem ? this.state.selectedItem.name : 'None'}
          </span>&nbsp;
        </span>
        {content}
      </div>
    )
  }
}

module.exports = SelectMenu
