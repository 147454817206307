import './styles.css'

import React from 'react'
import _ from 'lodash'
import Cookies from 'cookies-js'
import $ from 'jquery'

import Avatar from '../../avatar'

class Header extends React.Component {
  state = {
    dropdownOpen: false
  }

  componentDidMount () {
    const _this = this
    $(document).bind('click.profileDropdown', function (ev) {
      const list = $(ev.target).closest('#user-profile')
      if (!list.length) {
        if (_this.state.dropdownOpen) {
          _this.setState({ dropdownOpen: false })
        }
      }
    })
  }

  componentWillUnmount () {
    $(document).unbind('click.profileDropdown')
  }

  goToUrl = (url, target) => {
    window.open(url, target)
  }

  toggleDropdown = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen })
  }

  logout = () => {
    window.sessionStorage.removeItem('authToken')
    window.localStorage.removeItem('authToken')
    Cookies.expire('authToken')
    this.props.app.disconnectSocket()

    // this.props.setUser(null);
    this.props.app.authenticated = false
    var freshState = this.props.app.getInitialState()
    this.props.app.setState(
      _.extend(freshState, { loaded: true, loading: false })
    )
    history.pushState({}, '', '/')
  }

  invite = () => {
    this.props.app.setPanel('invite-beta-overlay', {
      data: { user: this.props.user },
      title: 'Invite your friends',
      activeSection: 'send'
    })
  }

  toggleSettings = () => {
    this.props.app.setPanel('user-settings-page', {
      data: { user: this.props.user },
      title: 'User settings',
      activeSection: 'settings-section'
    })
  }

  toggleProfile = () => {
    this.props.app.setPanel('user-settings-page', {
      data: { user: this.props.user },
      title: 'User settings',
      activeSection: 'profile-section'
    })
  }

  toggleUpgrade = () => {
    this.props.app.setPanel('user-settings-page', {
      data: { user: this.props.user },
      title: 'User settings',
      activeSection: 'plans-section'
    })
  }

  toggleShares = () => {
    this.props.app.setPanel('shares-overlay', {
      data: { user: this.props.user },
      title: 'Shared with me'
    })
  }

  toggleFeedback = () => {
    this.props.app.setPanel('feedback-overlay', {
      data: { user: this.props.user },
      title: 'Feedback'
    })
  }

  toggleOrgs = () => {
    this.props.app.setPanel('organizations-page', {
      data: { user: this.props.user },
      title: 'Manage teams',
      activeSection: 'settings-section'
    })
  }

  toggleKeyHelp = ev => {
    this.props.app.setPanel('user-settings-page', {
      data: { user: this.props.user },
      title: 'User settings',
      activeSection: 'keyboard-section'
    })

    window.Intercom && window.Intercom('trackEvent', 'toggled-key-help')
    if (ev && ev.preventDefault) {
      ev.preventDefault()
    }
  }

  openAdmin = () => {
    this.props.app.setPanel(null)
    this.goToUrl('/admin', '_blank')
  }

  render () {
    let content = <div />

    if (this.props.user) {
      let admin
      if (
        this.props.user &&
        this.props.user.user_level &&
        this.props.user.user_level === 1000
      ) {
        admin = <div onClick={this.openAdmin}>Admin</div>
      }

      var invite
      // eslint-disable-next-line no-constant-condition
      if (false && this.props.user.invites) {
        invite = <div onClick={this.invite}>Invite your friends</div>
      }
      content = (
        <div id='user-profile'>
          <div className='profile' onClick={this.toggleDropdown}>
            <div className='name'>
              {this.props.user.first_name}{' '}
              <i className='fa fa-fw fa-angle-down' />
            </div>
            <Avatar app={this.props.app} user={this.props.user} size='medium' />
          </div>
          <div
            className={`${this.state.dropdownOpen ? 'active' : null} dropdown`}
          >
            {/* <li onClick={this.toggleUpgrade}><i className="fa fa-fw fa-arrow-circle-o-up"></i>Upgrade to pro</li> */}
            <div className='section one'>Help & Support</div>
            <div
              onClick={() => {
                this.goToUrl.bind(this, 'https://help.notebase.io/', '_blank')
              }}
            >
              Help
            </div>
            <div onClick={this.toggleKeyHelp}>Keyboard Shortcuts</div>
            <div onClick={this.toggleFeedback}>Give us feedback</div>
            <div className='section two'>Team / Misc</div>
            {invite}
            {true && <div onClick={this.toggleOrgs}>Manage teams</div>}
            {/* <div onClick={this.toggleShares}>Shared with me</div> */}
            <div onClick={this.props.app.browseFiles}>File Browser</div>
            {/*
            <li onClick={this.findBookmarks}>Bookmarks</li>
            */}
            {/* <li onClick={this.props.app.toggleHelp}>Video Tutorials</li> */}
            <div className='section three'>Account</div>
            <div onClick={this.toggleProfile}>Profile</div>
            <div onClick={this.toggleSettings}>Settings</div>
            {admin}
            <div onClick={this.logout}>Logout</div>
          </div>
        </div>
      )
    }

    return content
  }
}

module.exports = Header
