import React from 'react'

class ManageGroupsSection extends React.Component {
  componentDidMount () {}

  render () {
    var content = <div />

    if (this.props) {
      content = (
        <div className='form'>
          <div className='row'>
            <label>Groups</label>
            <p>
              How complicated should groups be? Could it start out being just a
              way to sort member into a group, and then you can give group
              permissions on sertain nodes? And thats it?
            </p>

            <ul>
              <li>Name</li>
              <li>Memebers</li>
              <li>Group permissions (?)</li>
            </ul>
          </div>
        </div>
      )
    }

    return content
  }
}

module.exports = ManageGroupsSection
