import React from 'react'
import createReactClass from 'create-react-class'
import Misc from '../../../../../js/misc'

const ProfileSection = createReactClass({
  displayName: 'ProfileSection',

  getInitialState: function () {
    var state = {
      error: null,
      first_name: this.props.user.first_name,
      last_name: this.props.user.last_name,
      email: this.props.user.email,
      username: this.props.user.username
    }
    return state
  },

  UNSAFE_componentWillMount: function () {
    this.handleFormFieldChange = Misc.handleFormFieldChange.bind(this, this)
    this.errorFor = Misc.errorFor.bind(this, this)
  },

  saveProfile: function () {
    this.setState({ error: null }, () => {
      var data = {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        username: this.state.username
      }
      this.props.app.ajax('update_user', { body: data })
    })
  },

  pickProfileImage: function () {
    this.props.app.browseFiles({}, file => {
      if (file) {
        const url = Misc.fileUrl(file)
        const data = { avatar: url }
        this.props.app.ajax('update_user', { body: data })
      }
    })
  },

  render: function () {
    var content = <div />

    if (this.props) {
      content = (
        <div className='form'>
          <div className='row'>
            <label>Profile picture</label>
            {!this.props.user.avatar && (
              <div className='small-description'>
                * Currently getting your Profile picture from gravatar.
              </div>
            )}
            <div className='row profile-picture-container'>
              <img src={this.props.user.avatar || this.props.user.gravatar} />
            </div>
            <button onClick={this.pickProfileImage}>
              Upload or select profile picture
            </button>
          </div>

          <div className='row'>
            <label>First name:</label>
            <input
              type='text'
              data-name='first_name'
              value={this.state.first_name}
              placeholder='Jane'
              onChange={this.handleFormFieldChange}
            />
            {this.errorFor('firstName')}
          </div>

          <div className='row'>
            <label>Last name:</label>
            <input
              type='text'
              data-name='last_name'
              value={this.state.last_name}
              placeholder='Doe'
              onChange={this.handleFormFieldChange}
            />
            {this.errorFor('lastName')}
          </div>

          <div className='row'>
            <label>Username:</label>
            <input
              type='text'
              data-name='username'
              value={this.state.username}
              placeholder='jane'
              onChange={this.handleFormFieldChange}
            />
            {this.errorFor('username', this.state.error)}
          </div>

          <div className='row'>
            <label>Email:</label>
            <input
              type='text'
              data-name='email'
              value={this.state.email}
              placeholder='jane@doe.com'
              onChange={this.handleFormFieldChange}
            />
            {this.errorFor('email')}
          </div>

          <div className='row'>
            <input type='submit' value='Save' onClick={this.saveProfile} />
          </div>
        </div>
      )
    }

    return content
  }
})

module.exports = ProfileSection
