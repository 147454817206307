import './styles.css'

import React from 'react'
import _ from 'lodash'

import Badge from '../../badge'

class InboxIndicator extends React.Component {
  render () {
    var unseenCount = 0 // TODO: Implement a green badge for unseen Inbox items?
    var inboxItems = _.uniq(
      this.props.app.state.entities.nodes.filter(node => node.inbox)
    )

    return (
      <div id='inbox-indicator' title='Toggle Inbox'>
        <div className='inbox-toggle' onClick={this.props.app.toggleInbox}>
          <i
            className={
              'toggle-inbox fa fa-fw ' +
              (inboxItems.length ? 'fa-bookmark' : 'fa-bookmark-o')
            }
          />
          <Badge count={unseenCount} />
          <Badge count={inboxItems.length} type='muted' hideIf={unseenCount} />
        </div>
      </div>
    )
  }
}

module.exports = InboxIndicator
